import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

export interface UseShowOrdersCountProps {
  showOrdersCount: boolean;
}

export default function useHasOrderStatus(): UseShowOrdersCountProps {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const [showOrdersCount, setShowOrdersCount] = useState(true);

  useEffect(() => {
    if (!settings) return;

    if (typeof settings.mm_order_show_orders_count !== 'undefined') {
      setShowOrdersCount(settings.mm_order_show_orders_count);
    } else {
      setShowOrdersCount(true);
    }
  }, [settings]);

  return { showOrdersCount };
}
