import styled, { css } from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const Container = Modal
  ? styled(Modal)`
      .container {
        padding: 0;

        .content {
          height: 100%;
        }
      }
    `
  : styled.div<ModalProps>``;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 24px 16px 16px;

    display: flex;
    flex-direction: column;

    text-align: left;

    .promo-image {
      width: 100%;
      margin: 0 0 24px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .new-tag {
      margin: 0 0 8px;
      padding: 2px 8px;
      width: fit-content;

      border-radius: 500px;
      border: 1px dashed ${theme.brandColors.primary};

      > p {
        font-weight: 600;
        font-size: 0.857rem;
        line-height: 100%;
        color: ${theme.brandColors.primary};
      }
    }

    .title {
      margin: 0 0 8px;

      font-weight: 800;
      font-size: 1.125rem;
      line-height: 130%;
      color: ${theme.colors.textColor};
    }

    .desc {
      margin: 0 0 24px;

      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: ${theme.colors.gray500};
    }
  `};
`;
