import { useCallback } from 'react';

import useHandlePaymentTexts from '../useHandlePaymentTexts';

interface FormatCreditCardProps {
  card: string;
  flag?: string;
  isCreditCardNumberHidden?: boolean;
}

export interface UseFormatCreditCardProps {
  capitalize: (word?: string) => string | undefined;
  hideCreditCard: (creditCard: string, isCreditCardNumberHidden?: boolean) => string;
  formatCreditCard: ({ card, flag, isCreditCardNumberHidden }: FormatCreditCardProps) => string;
}

export default function useFormatCreditCard(): UseFormatCreditCardProps {
  const { handlePaymentTexts } = useHandlePaymentTexts();

  const hideCreditCard = useCallback((creditCard: string, isCreditCardNumberHidden?: boolean) => {
    if (isCreditCardNumberHidden) {
      return creditCard;
    }

    return `**** ${creditCard.substr(creditCard.length - 4)}`;
  }, []);

  const capitalize = useCallback((word?: string) => {
    if (!word) return word;

    const firstLetter = word.charAt(0).toUpperCase();
    const restOfLetters = word.slice(1);

    return `${firstLetter}${restOfLetters}`;
  }, []);

  const formatCreditCard = useCallback(
    ({ card, flag, isCreditCardNumberHidden }: FormatCreditCardProps) => {
      return `${handlePaymentTexts(capitalize(flag) || '')} - ${hideCreditCard(card, isCreditCardNumberHidden)}`;
    },
    [capitalize, handlePaymentTexts, hideCreditCard]
  );

  return { capitalize, hideCreditCard, formatCreditCard };
}
