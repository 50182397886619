import { ParsedOperationHoursList } from '@goomerdev/goomer-toolbox/src/interfaces';
import {
  SchedulingConfig,
  SchedulingAvailableHours,
  SchedulingConfigTypeEnum
} from '@goomerdev/goomer-toolbox/src/enums';
import {
  addDays,
  subDays,
  isAfter,
  addHours,
  isBefore,
  setHours,
  endOfHour,
  isSameDay,
  setMinutes,
  addMinutes,
  isSameHour,
  startOfDay,
  subMinutes,
  addMilliseconds,
  differenceInHours,
  differenceInMinutes,
  formatDistanceToNowStrict
} from 'date-fns';

import { handleAcrossTheWeek } from '..';

interface GetAvailableHoursProps {
  day: Date;
  isNextWeek?: boolean;
  is24hrsOperation?: boolean;
  deliveryScheduling?: SchedulingConfig;
  operatingTime?: ParsedOperationHoursList[];
}

const HOUR = 60;
const MINUTES_BASED = 30;

export default function getAvailableHours({
  day,
  operatingTime,
  isNextWeek = false,
  deliveryScheduling,
  is24hrsOperation = false
}: GetAvailableHoursProps): SchedulingAvailableHours[] {
  const isToday = isNextWeek ? false : isSameDay(day, new Date());
  const isNextDay = !isToday ? isSameDay(subDays(addHours(day, 1), 1), new Date()) : false;

  const isNextWeekSameDay = isSameDay(day, new Date());

  const parsedDeliveryScheduling = ((): SchedulingConfig | undefined => {
    if (isToday) return deliveryScheduling;

    if (is24hrsOperation && !isToday) {
      const minValueInNextDayFromNow = ((): number => {
        const requiredMinTime = deliveryScheduling?.min.value;

        if (!requiredMinTime || !isNextDay) return 0;

        const distanceToNextDayInHours = formatDistanceToNowStrict(addDays(startOfDay(new Date()), 1)).replace(
          /\D/g,
          ''
        );

        const diff = Number(requiredMinTime) - Number(distanceToNextDayInHours);

        return diff > 0 ? diff : 0;
      })();

      return {
        ...deliveryScheduling,
        min: {
          value: minValueInNextDayFromNow,
          type: deliveryScheduling?.min.type || SchedulingConfigTypeEnum.days
        }
      } as SchedulingConfig;
    }

    return deliveryScheduling;
  })();

  const isSchedulingMinTypeHourBased =
    !!parsedDeliveryScheduling && parsedDeliveryScheduling.min.type === SchedulingConfigTypeEnum.hours;

  const isSchedulingMaxTypeHourBased =
    !!parsedDeliveryScheduling && parsedDeliveryScheduling.max.type === SchedulingConfigTypeEnum.hours;

  const STATIC_MIN_TIME_REQUIRED = 1;

  const minHoursRequired = ((): number => {
    if (is24hrsOperation && isNextWeek && isNextWeekSameDay) return 0;

    if (!!parsedDeliveryScheduling && isSchedulingMinTypeHourBased) return parsedDeliveryScheduling.min.value;

    if (is24hrsOperation) return 0;

    return STATIC_MIN_TIME_REQUIRED;
  })();

  const schedulingPeriodList: ParsedOperationHoursList[] | undefined = operatingTime?.filter((time) => {
    if (day.getDay() >= time.from && day.getDay() <= time.to) return true;

    if (time.from > time.to) {
      return handleAcrossTheWeek({ to: time.to, from: time.from, weekday: day.getDay() });
    }

    return false;
  });

  if (schedulingPeriodList?.length === 0) {
    return [];
  }

  const availableHourList: SchedulingAvailableHours[] = [];

  ((): void =>
    schedulingPeriodList?.forEach((period) => {
      const [openTimeHour, openTimeMinutes] = period.open.split(':');
      const [closeTimeHour, closeTimeMinutes] = period.close.split(':');

      const currentDate = ((): Date => {
        if (!isToday) return startOfDay(new Date(day));

        if (new Date().getHours() < Number(openTimeHour)) {
          return new Date(new Date().setMinutes(0));
        }

        return new Date();
      })();

      const hoursToAddOnDate = ((): number => {
        if (Number(openTimeHour) < currentDate.getHours()) {
          return minHoursRequired;
        }

        return Number(openTimeHour) - currentDate.getHours() + minHoursRequired;
      })();

      const minHoursRequiredWhenIsToday = is24hrsOperation ? 0 : STATIC_MIN_TIME_REQUIRED;

      const dateWithoutHoursBeforeEstablishmentOpen = isToday
        ? addHours(currentDate, hoursToAddOnDate)
        : addHours(currentDate, Number(openTimeHour) + minHoursRequiredWhenIsToday);

      const parsedCloseTimeHour = ((): number => {
        if (is24hrsOperation) return Number(closeTimeHour) + 0.5;

        return Number(closeTimeHour);
      })();

      const differenceBetweenNowAndCloseTime =
        (parsedCloseTimeHour - dateWithoutHoursBeforeEstablishmentOpen.getHours()) * 2;

      for (let increment = differenceBetweenNowAndCloseTime; increment >= 0; increment -= 1) {
        let currentIterationHour = addMinutes(dateWithoutHoursBeforeEstablishmentOpen, increment * MINUTES_BASED);

        if (isToday) {
          if (currentIterationHour.getMinutes() > 30) {
            currentIterationHour = addMilliseconds(endOfHour(currentIterationHour), 1);
          }

          if (currentIterationHour.getMinutes() !== 0) {
            currentIterationHour.setMinutes(30);
          }
        }

        const closeEstablishmentDate = setMinutes(
          setHours(currentIterationHour, Number(closeTimeHour)),
          Number(closeTimeMinutes)
        );

        const openEstablishmentDate = setMinutes(
          setHours(currentIterationHour, Number(openTimeHour)),
          Number(openTimeMinutes)
        );

        const isOnRequiredTimeRange = ((): boolean => {
          const parsedCurrentHour = ((): Date => {
            if (isSchedulingMaxTypeHourBased) {
              return new Date();
            }

            if (new Date().getMinutes() < 30 && new Date().getMinutes() > 0) {
              return currentDate;
            }

            return subMinutes(currentDate, 1);
          })();

          const isOnMinTimeRange =
            differenceInMinutes(currentIterationHour, parsedCurrentHour) > minHoursRequired * HOUR;

          const isOnMinOpenTimeRange =
            differenceInMinutes(addMinutes(currentIterationHour, 1), openEstablishmentDate) > minHoursRequired * HOUR;

          if (!isSchedulingMaxTypeHourBased) return isOnMinTimeRange && isOnMinOpenTimeRange;

          const deliverySchedulingMaxRange = parsedDeliveryScheduling ? parsedDeliveryScheduling.max.value : 0;

          const isOnMaxTimeRange =
            differenceInHours(currentIterationHour, isSchedulingMaxTypeHourBased ? new Date() : currentDate) <
            deliverySchedulingMaxRange;

          if (isToday) {
            return isOnMinOpenTimeRange && isOnMaxTimeRange;
          }

          return isOnMinTimeRange && isOnMaxTimeRange;
        })();

        const isValidSameHours = ((): boolean => {
          if (is24hrsOperation) return true;

          if (!isSameHour(subMinutes(currentIterationHour, 1), closeEstablishmentDate)) {
            return true;
          }

          return false;
        })();

        const isBeforeCloseEstablishment = ((): boolean => {
          if (is24hrsOperation) return true;

          const padCloseTimeMinutes = closeTimeMinutes.padStart(2, '0');

          if (padCloseTimeMinutes === '30' || padCloseTimeMinutes === '00') {
            return isBefore(currentIterationHour, closeEstablishmentDate);
          }

          if (increment === differenceBetweenNowAndCloseTime) {
            if (Number(closeTimeMinutes) > 30) {
              return isBefore(currentIterationHour, setMinutes(closeEstablishmentDate, 30));
            }

            return isBefore(currentIterationHour, setMinutes(closeEstablishmentDate, 0));
          }

          return isBefore(currentIterationHour, closeEstablishmentDate);
        })();

        const isCurrentIterationHourOnOperationTimeRange =
          isSameDay(currentIterationHour, dateWithoutHoursBeforeEstablishmentOpen) &&
          isBeforeCloseEstablishment &&
          isValidSameHours &&
          isAfter(currentIterationHour, openEstablishmentDate) &&
          isOnRequiredTimeRange;

        if (isCurrentIterationHourOnOperationTimeRange || (is24hrsOperation && isOnRequiredTimeRange)) {
          if (
            (isOnRequiredTimeRange && isSameDay(currentIterationHour, dateWithoutHoursBeforeEstablishmentOpen)) ||
            (is24hrsOperation && !isToday)
          ) {
            availableHourList.push({
              info: {
                hour: currentIterationHour.getHours(),
                minutes: currentIterationHour.getMinutes()
              },
              label: `${String(currentIterationHour.getHours()).padStart(2, '0')}:${String(
                currentIterationHour.getMinutes()
              ).padStart(2, '0')}`
            });
          }
        }
      }
    }))();

  const sortedAvailableHourList: SchedulingAvailableHours[] = availableHourList.sort((prev, next) => {
    const prevParsedLabel = Number(prev.label.split(':').join(''));
    const nextParsedLabel = Number(next.label.split(':').join(''));

    if (prevParsedLabel < nextParsedLabel) return -1;
    if (prevParsedLabel > nextParsedLabel) return 1;

    return 0;
  });

  return sortedAvailableHourList;
}
