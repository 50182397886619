import styled, { css } from 'styled-components';
import { motion, MotionProps } from 'framer-motion';

export const Content = styled.div`
  .go-back {
    z-index: 1002;
  }

  .search-input-wrapper {
    margin-left: 10px;
  }

  .content {
    max-height: calc(100% - 50px);
  }
`;

export const AddressInfoWrapper = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid #dde2eb;
`;

interface SearchContainerProps extends MotionProps {
  shouldHidePaddingTop: boolean;
}

export const SearchContainer = styled(motion.div).attrs((props: SearchContainerProps) => ({
  ...props,
  exit: { opacity: 0 },
  animate: { opacity: 1 },
  initial: { opacity: 0 }
}))`
  ${({ theme, color, shouldHidePaddingTop }) => css`
    position: relative;

    width: 100%;
    padding-top: ${shouldHidePaddingTop ? 0 : '48px'};

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    background-color: ${color || theme.colors.backgroundColor};

    > div {
      width: 100%;
    }

    > h1 {
      font-size: 48px;
      color: ${({ theme }) => theme.colors.backgroundColor};
    }

    > .message {
      width: 100%;

      margin-top: 2rem;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      > p {
        margin: 20px;

        display: flex;
        align-items: center;

        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        font-style: normal;
        text-align: center;
        color: ${({ theme }) => theme.colors.mediumGray};
      }
    }

    .results {
      width: 100%;

      margin-top: 2rem;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      > div {
        width: 100%;
      }

      .address-item {
        cursor: pointer;

        > div {
          > div {
            transition: 0.3s;
            background-color: ${({ theme }) => theme.colors.backgroundColor};

            &:hover {
              transition: 0.3s;
              background-color: ${({ theme }) => theme.colors.lightestGray};
            }
          }
        }
      }
    }
  `};
`;
