import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 0;
    margin-top: 20px;
    margin-bottom: auto;

    display: flex;
    flex-direction: column;

    .action {
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      text-decoration: underline;
      color: ${theme.colors.primary};
    }
  `}
`;

export const CardList = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    background-color: ${theme.colors.backgroundColor};
  `};
`;

export const Item = styled.div`
  ${({ theme }) => css`
    padding: 16px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    background-color: ${theme.colors.backgroundColor};

    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.gray300};
    }

    > h3 {
      width: 100%;

      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: ${theme.colors.gray500};
    }

    > .icon {
      width: fit-content;
      margin: 1px 6px 0 0;

      svg {
        width: 1rem;

        path {
          fill: ${theme.colors.textColor};
        }
      }
    }

    > .info {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > p {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: ${theme.colors.gray600};

        &:not(:last-of-type) {
          margin-bottom: 2px;
        }

        &.card {
          font-weight: 700;
          font-size: 14px;
          line-height: 130%;
          color: ${theme.colors.gray600};
        }

        &.document {
          color: ${theme.colors.gray500};
        }
      }
    }

    > .delete {
      height: 100%;
      width: fit-content;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  `};
`;

export const AddNewCard = styled.div`
  margin: 24px 0 0;

  display: flex;
  justify-content: center;
`;
