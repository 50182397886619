import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { differenceInMilliseconds } from 'date-fns';

import { IApplicationState } from '~/redux-tools/store';
import useUserAuthenticationStatus from '~/hooks/useUserAuthenticationStatus';

import { UseLoyaltyProgramHooksProps } from '..';
import useLoyaltyDisplayMenuInfo from './useLoyaltyDisplayMenuInfo';

export type UseLoyaltyDiscountProps = Pick<UseLoyaltyProgramHooksProps, 'loyaltyDiscountValueUnlocked'>;

const ONE_HOUR_IN_MILLISECONDS = 3600000;

export default function useLoyaltyDiscount(): UseLoyaltyDiscountProps {
  const { customerLoyaltyProgram } = useSelector((state: IApplicationState) => state.user.data);
  const { loyaltyProgram: establishmentLoyaltyProgram } = useSelector(
    (state: IApplicationState) => state.establishment
  );

  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const { shouldDisplayLoyaltyProgramMenuInfo } = useLoyaltyDisplayMenuInfo();

  const orderIntervalHoursInMilliseconds: number = useMemo(
    () => (establishmentLoyaltyProgram?.orderIntervalHours || 1) * ONE_HOUR_IN_MILLISECONDS,
    [establishmentLoyaltyProgram]
  );

  const loyaltyDiscountValueUnlocked: number = useMemo(() => {
    if (!shouldDisplayLoyaltyProgramMenuInfo) return 0;

    const isLoyaltyDiscountUnblocked =
      !!customerLoyaltyProgram?.program_fulfilled_at &&
      differenceInMilliseconds(new Date(), new Date(customerLoyaltyProgram?.program_fulfilled_at)) >=
        orderIntervalHoursInMilliseconds;

    const hasMinimumOrder: boolean =
      establishmentLoyaltyProgram?.ordersForFulfilling === customerLoyaltyProgram?.total_valid_orders;

    const rewardAlreadyGranted = !!customerLoyaltyProgram?.reward_granted_at;

    const isAbleToReceiveRewardDiscount =
      isUserAuthenticated &&
      hasMinimumOrder &&
      !rewardAlreadyGranted &&
      isLoyaltyDiscountUnblocked &&
      establishmentLoyaltyProgram?.rewardDiscount;

    const discountValue: number =
      isAbleToReceiveRewardDiscount && !!establishmentLoyaltyProgram ? establishmentLoyaltyProgram.rewardDiscount : 0;

    return discountValue;
  }, [
    isUserAuthenticated,
    customerLoyaltyProgram,
    establishmentLoyaltyProgram,
    orderIntervalHoursInMilliseconds,
    shouldDisplayLoyaltyProgramMenuInfo
  ]);

  return { loyaltyDiscountValueUnlocked };
}
