import { ParsedOperationHoursList } from '@goomerdev/goomer-toolbox/src/interfaces';

export default function getSchedulingWeekdaysInNumber(operatingTime: ParsedOperationHoursList[]): number[][] {
  if (operatingTime.length === 0) return [];

  return operatingTime.map((weekday) => {
    let { from } = weekday;
    const { to } = weekday;

    const listOfAvailableWeekdaysList = [from];

    if (from === to) {
      return listOfAvailableWeekdaysList;
    }

    if (from < to) {
      while (from < to) {
        from += 1;

        listOfAvailableWeekdaysList.push(from);
      }
    } else {
      let fixedTo = 6;

      const getNextDay = (): void => {
        if (from === 6) {
          from = -1;
          fixedTo = to;
        }

        from += 1;
      };

      getNextDay();
      while (from <= fixedTo) {
        listOfAvailableWeekdaysList.push(from);

        getNextDay();
      }
    }

    return listOfAvailableWeekdaysList;
  });
}
