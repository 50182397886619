import { all, put, takeLatest } from 'redux-saga/effects';

import { cancelWaitingPaymentOrder } from '~/services';

import { NupayStateActionTypes } from '.';
import { cancelNupayOrderError, cancelNupayOrderRequest } from './actions';

type CancelOrderRequest = ReturnType<typeof cancelNupayOrderRequest>;

export function* cancelOrder({ payload }: CancelOrderRequest) {
  const { orderId, paymentDate, callback } = payload;

  if (!orderId || !paymentDate) {
    return;
  }

  try {
    yield cancelWaitingPaymentOrder({ orderId, paymentDate });

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(cancelNupayOrderError((error as Error)?.message));
  }
}

export default all([takeLatest(NupayStateActionTypes.CANCEL_NUPAY_ORDER_REQUEST, cancelOrder)]);
