import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { checkPaymentPatterns } from '~/utils';
import { PaymentMethod } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { editPaymentPreferences, removePaymentPreference, removeUserCard } from '~/redux-tools/store/user/actions';

interface UseCheckPaymentMethodProps {
  payments: PaymentMethod[];
}

export default function useCheckPaymentWay(): UseCheckPaymentMethodProps {
  const dispatch = useDispatch();

  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { cards, paymentPreference, authenticationToken } = useSelector((state: IApplicationState) => state.user.data);

  const [isPaymentChecked, setIsPaymentChecked] = useState(false);
  const [userPaymentPreference, setUserPaymentPreference] = useState<PaymentMethod[]>([]);

  const paymentMethods = useMemo(
    () => [
      {
        flags: [],
        name: PaymentCategoryEnum.cash,
        enabled: settings?.mm_payment_cash_enabled
      },
      {
        name: PaymentCategoryEnum.credit,
        flags: settings?.mm_payment_credit_flags,
        enabled: settings?.mm_payment_credit_enabled
      },
      {
        name: PaymentCategoryEnum.debit,
        flags: settings?.mm_payment_debit_flags,
        enabled: settings?.mm_payment_debit_enabled
      },
      {
        name: PaymentCategoryEnum.voucher,
        flags: settings?.mm_payment_voucher_flags,
        enabled: settings?.mm_payment_voucher_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.pix,
        enabled: settings?.mm_payment_pix_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.integratedPix,
        enabled: settings?.mm_payment_tuna_pix_checkout_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.mPagoCheckout,
        enabled: settings?.mm_payment_mpago_checkout_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.mPagoLink,
        enabled: settings?.mm_payment_mpago_link_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.vrPagueLink,
        enabled: settings?.mm_payment_vrpague_link_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.mPagoQr,
        enabled: settings?.mm_payment_mpago_qrcode_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.tunaCheckout,
        enabled: settings?.mm_payment_tuna_credit_card_checkout_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.tunaCheckout,
        enabled: settings?.mm_payment_qrcode_tuna_credit_card_checkout_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.nupay,
        enabled: settings?.mm_payment_nupay_checkout_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.googlePay,
        enabled: settings?.mm_payment_tuna_credit_card_checkout_enabled
      },
      {
        flags: [],
        name: PaymentCategoryEnum.applePay,
        enabled: settings?.mm_payment_tuna_credit_card_checkout_enabled
      }
    ],
    [settings]
  );

  const [persistedSettings, setPersistedSettings] = useState(paymentMethods);

  useEffect(() => {
    if (JSON.stringify(paymentMethods) !== JSON.stringify(persistedSettings)) {
      setPersistedSettings(paymentMethods);

      setIsPaymentChecked(false);
    }
  }, [paymentMethods, persistedSettings]);

  const hasPaymentFlags = useCallback((category) => {
    return (
      category === PaymentCategoryEnum.credit ||
      category === PaymentCategoryEnum.debit ||
      category === PaymentCategoryEnum.voucher
    );
  }, []);

  useEffect(() => {
    if (!paymentPreference || paymentPreference.length === 0 || isPaymentChecked || !settings) {
      return;
    }

    const handleWithDisabledPayments = () => {
      const enabledPaymentCategories = paymentPreference?.filter((userPayment) => {
        const paymentStatus = paymentMethods.find((payment) => payment.name === userPayment.category);

        return paymentStatus?.enabled;
      });

      const validPaymentPreferences = enabledPaymentCategories?.filter((userPayment) => {
        if (!hasPaymentFlags(userPayment.category) || !userPayment.flag) return true;

        const paymentStatus = paymentMethods.find((payment) => {
          return (
            !!userPayment.flag &&
            !!payment &&
            !!payment.flags &&
            payment.flags?.some((flag) => flag.name === userPayment.flag && !!flag.checked)
          );
        });

        return !!paymentStatus;
      });

      dispatch(editPaymentPreferences(validPaymentPreferences));
      setUserPaymentPreference(validPaymentPreferences);

      setIsPaymentChecked(true);
    };

    handleWithDisabledPayments();
  }, [dispatch, hasPaymentFlags, isPaymentChecked, paymentMethods, paymentPreference, settings]);

  useEffect(() => {
    const checkIfPaymentMethodsIsOnRightPatterns = () => {
      function checkPaymentMethods() {
        if (!paymentPreference) {
          dispatch(removePaymentPreference());
        }

        const isTheCorrectPattern = checkPaymentPatterns.isAllPaymentMethodsOnPattern(paymentPreference);

        if (!isTheCorrectPattern) {
          dispatch(removePaymentPreference());
        }
      }

      function checkCreditCards() {
        if (cards?.length === 0) return;

        const isTheCorrectPattern = checkPaymentPatterns.isAllCreditCardsOnPattern(cards);

        if (!isTheCorrectPattern && !!cards) {
          cards.forEach((card) => {
            dispatch(removeUserCard(card.number, authenticationToken));
          });
        }
      }

      checkPaymentMethods();
      checkCreditCards();
    };

    checkIfPaymentMethodsIsOnRightPatterns();
  }, [dispatch, paymentPreference, cards, authenticationToken]);

  return { payments: userPaymentPreference || [] };
}
