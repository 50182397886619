import { LocalOrder, TabInfo } from '~/interfaces/general';

export interface localOrderState extends LocalOrder {
  readonly qrCodeInfo?: TabInfo;
  readonly isLoadingInfo: boolean;
}

export const LocalOrdersActionTypes = {
  ADD_ORDER: '@@localOrders/ADD_ORDER',
  ADD_CHECK_IN: '@@localOrders/ADD_CHECK_IN',
  CLEAN_CHECK_IN: '@@localOrders/CLEAN_CHECK_IN',
  SET_IS_OPERATOR_MODE: '@@localOrders/SET_IS_OPERATOR_MODE',

  GET_TAB_INFO: '@@localOrders/GET_TAB_INFO',
  ADD_TAB_INFO: '@@localOrders/ADD_TAB_INFO',
  CLEAN_TAB_INFO: '@@localOrders/CLEAN_TAB_INFO'
};
