import { CheckIn } from '~/interfaces/general';

interface FetchCheckInInfo {
  hash: string;
  storeId?: number;
}

export default async function fetchCheckInInfo({ hash, storeId }: FetchCheckInInfo): Promise<CheckIn> {
  const API_URL = process.env.NEXT_PUBLIC_QRCODE;

  const response = await fetch(`${API_URL}${hash}/validate`);
  const responseJSON = await response.json();

  if (!response.ok) {
    throw new Error(responseJSON.message || 'Something is wrong with request');
  }

  if (Number(responseJSON.store_id) !== Number(storeId)) {
    throw new Error('Store ID mismatch');
  }

  return responseJSON;
}
