import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setIsGoogleMapsApiLoaded } from '~/redux-tools/store/geolocation/actions';

import { useLoadGoogleMapsApi } from 'hooks';

const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '';
const LIBRARIES = 'geocoding';

interface GeocoderParams {
  address: string;
  afterError: (errorMessage: string) => void;
  afterSuccess: (geocoderResult: google.maps.GeocoderResult[] | null) => void;
}

export default function useGoogleMaps() {
  const dispatch = useDispatch();
  const isApiLoaded = useLoadGoogleMapsApi(GOOGLE_MAPS_API_KEY, LIBRARIES);

  const geocodingLibrary: Promise<google.maps.GeocodingLibrary | null> = useMemo(async () => {
    if (isApiLoaded) {
      return (await google.maps.importLibrary(LIBRARIES)) as google.maps.GeocodingLibrary;
    }

    return null;
  }, [isApiLoaded]);

  const getGeocoderResults = useCallback(
    async ({ address, afterError, afterSuccess }: GeocoderParams) => {
      if (!isApiLoaded) {
        afterError('API do Google Maps ainda não foi carregada.');

        return;
      }

      try {
        const geocodingLibraryResponse = await geocodingLibrary;

        if (geocodingLibraryResponse) {
          const geocoder = new geocodingLibraryResponse.Geocoder();

          geocoder.geocode({ address }, (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
              afterError('Ocorreu um erro ao buscar informações do seu endereço. Por favor, tente novamente.');

              dispatch(setIsGoogleMapsApiLoaded(false));

              return;
            }

            dispatch(setIsGoogleMapsApiLoaded(true));

            afterSuccess(results);
          });
        }
      } catch (error) {
        console.error(error);

        afterError('Erro ao carregar a biblioteca de geocodificação.');
      }
    },
    [dispatch, geocodingLibrary, isApiLoaded]
  );

  return { getGeocoderResults };
}
