import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from '@goomerdev/goomer-toolbox/src/hooks';

import { IApplicationState } from '~/redux-tools/store';
import { fetchEstablishmentRequest } from '~/redux-tools/store/establishment/actions';
import { LanguageCodeEnum, LocalStorageEnum, ProductType, QueryParam } from '~/interfaces/enums';

import { useCheckProvider, useSlug } from '..';

interface UseFetchEstablishmentRequestProps {
  productType?: ProductType;
}

export default function useFetchEstablishmentRequest({ productType }: UseFetchEstablishmentRequestProps): {
  handleEstablishmentRequest: (currentLanguage?: LanguageCodeEnum) => void;
} {
  const dispatch = useDispatch();

  const [retryCount, setRetryCount] = useState<number>(0);
  const [isInfoUpdated, setIsInfoUpdated] = useState<boolean>(false);

  const { settings, loyaltyProgram } = useSelector((state: IApplicationState) => state.establishment);

  const slug = useSlug();
  const { provider, isAbrahao } = useCheckProvider();
  const { localStorageValue: selectedLanguage } = useLocalStorage({ key: LocalStorageEnum.selectedLanguage });

  const handleEstablishmentRequest = useCallback(
    (currentLanguage?: LanguageCodeEnum) => {
      const urlParams = new URLSearchParams(window.location.search);
      const isAbrahaoParam = urlParams.get(QueryParam.isAbrahao) || undefined;
      const isAbrahaoQRCodeRequest = window.location.pathname.includes('qrcode') && isAbrahaoParam === 'true';

      dispatch(
        fetchEstablishmentRequest({
          slug: slug || '',
          provider: productType || provider,
          isAbrahao: isAbrahaoQRCodeRequest || isAbrahao,
          selectedLanguage: currentLanguage || selectedLanguage,
          afterAction: (hasError) => hasError && setRetryCount(retryCount + 1)
        })
      );
    },
    [dispatch, isAbrahao, productType, provider, retryCount, selectedLanguage, slug]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isRefresh = urlParams.get(QueryParam.refresh) === 'true';

    if (isInfoUpdated || isRefresh) {
      return;
    }

    const isSettingsEmpty = slug && !settings;

    const isLoyaltyProgramEmpty = !!settings?.mm_feature_loyalty_program_enabled && !loyaltyProgram;

    if (isSettingsEmpty || (isLoyaltyProgramEmpty && !isInfoUpdated)) {
      handleEstablishmentRequest();
      setIsInfoUpdated(true);
    }
  }, [slug, settings, provider, dispatch, productType, isInfoUpdated, loyaltyProgram, handleEstablishmentRequest]);

  return { handleEstablishmentRequest };
}
