import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: fit-content;

    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-right: 0.25rem;

      line-height: 150%;
      font-size: 0.75rem;
      white-space: nowrap;
      color: ${theme.colors.gray500};
    }

    svg {
      width: auto;
      height: 1rem;

      path {
        fill: ${theme.colors.gray500};
      }
    }
  `}
`;
