import Image from 'next/image';
import { RiArrowLeftLine } from 'react-icons/ri';

import { useEstablishmentStatus } from '~/hooks';
import { StoreStatus, StoreCategory } from '~/components';
import { EstablishmentStatusEnum } from '~/interfaces/enums';

import * as S from './styles';

export interface SplashHeaderProps {
  isQrCode?: boolean;
  hideContent?: boolean;
  backAction?: () => void;
  establishment: {
    name?: string;
    logo?: string;
    phrase?: string;
    banner?: string;
    category?: string;
    status?: EstablishmentStatusEnum;
  };
}

const SplashHeader: (props: SplashHeaderProps) => JSX.Element = ({
  backAction,
  establishment,
  isQrCode = false,
  hideContent = false
}) => {
  const { status, prettyStatus } = useEstablishmentStatus({ isQrCode });

  return (
    <S.Container logoUrl={establishment?.logo} bannerUrl={establishment.banner} hideContent={hideContent}>
      {!!backAction && (
        <S.Back hasBanner={!!establishment.banner}>
          <RiArrowLeftLine size={24} onClick={backAction} />
        </S.Back>
      )}

      {!!establishment.banner && (
        <div className="banner">
          <div className="banner-filter" />
        </div>
      )}

      {(!!establishment?.logo || !hideContent) && (
        <>
          <div className="logo-wrapper">
            {!!establishment?.logo && (
              <Image
                layout="fill"
                objectFit="cover"
                src={establishment?.logo}
                loader={({ src, width }): string => `${src}?w=${width}`}
              />
            )}
          </div>

          <div className="content">
            {!hideContent && (
              <div>
                <strong>{establishment?.name}</strong>

                <p>{establishment.phrase}</p>

                <div className="info-wrap">
                  {establishment.category && <StoreCategory category={establishment.category} />}

                  <StoreStatus status={status} text={prettyStatus} />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </S.Container>
  );
};

export default SplashHeader;
