import styled, { css } from 'styled-components';

export const Container = styled.div<{ isSimpleCard?: boolean }>`
  ${({ theme, isSimpleCard }) => css`
    width: 100%;
    padding: 0.625rem;
    border-radius: 5px;
    border: 1px dashed ${theme.colors.warning};

    ${!isSimpleCard &&
    css`
      margin-top: 0.5rem;
    `}

    display: flex;
    align-items: center;

    p {
      text-align: left;
      font-weight: 500;
      line-height: 140%;
      font-size: 0.625rem;
      color: ${theme.colors.gray500};

      strong {
        font-weight: 800;
        font-size: 0.625rem;
      }
    }
  `}
`;

export const SvgWrapper = styled.div`
  ${({ theme }) => css`
    height: 1.875rem;
    min-width: 1.875rem;
    margin-right: 0.5rem;
    border-radius: 0.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.warning};
  `}
`;
