import styled, { css } from 'styled-components';

import * as AlertStyle from '../Alert/styles';

export const Container = styled.div`
  padding-bottom: 1rem;

  ${AlertStyle.Container} {
    margin: 12px 16px;
  }
`;

export const CheckOrderList = styled.ul<{ $noActions: boolean }>`
  width: 100%;
  padding: 0 1rem;

  .head {
    padding-bottom: 6px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 12px;
      font-weight: bold;
      line-height: 150%;

      @media screen and (min-width: 720px) {
        font-size: 16px;
      }
    }
  }

  .item {
    display: inline-block;
  }

  .price {
    margin-right: ${({ $noActions }) => ($noActions ? '0' : '16px')};

    display: inline-block;

    text-align: right;

    @media screen and (min-width: 720px) {
      width: 38.5%;
    }
  }

  .discount {
    margin-top: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .item,
    .price {
      font-weight: 500;
      line-height: 150%;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.darkGray};
    }
  }

  .foot {
    padding-top: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: 500;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.mediumGray};
    }

    .price {
      margin-right: 0;
    }

    &.-coupon {
      .item {
        color: ${({ theme }) => theme.colors.success};
      }

      .price {
        color: ${({ theme }) => theme.colors.success};
      }
    }

    & + li {
      padding-top: 0.5rem;
    }

    &:last-child {
      span {
        font-weight: 600;
        line-height: 150%;
        font-size: 1.125rem;
        color: ${({ theme }) => theme.colors.textColor};
      }
    }
  }
`;

export const CheckOrderItemContainer = styled.li<{ $fullDivider?: boolean }>`
  ${({ theme, $fullDivider }) => css`
    margin-top: 1rem;
    padding: 1.5rem 1.03rem;

    border-radius: 1rem;
    border: 1px solid ${theme.colors.gray300};

    .product {
      position: relative;

      gap: 0.5rem;
      display: flex;
      text-align: left;
      align-items: flex-start;
      justify-content: space-between;

      .price {
        white-space: nowrap;
      }

      .action {
        right: -0.5rem;
        position: absolute;

        width: 1.25rem;

        display: inline-flex;
        justify-content: center;
      }
    }

    .combo-item {
      margin-top: 0.5rem;
    }

    .product-name {
      font-weight: 600;
      line-height: 140%;
      font-size: 0.875rem;
      color: ${theme.colors.mediumGray};
    }

    .optionals {
      margin-top: 0.25rem;
      padding-left: 1.5rem;

      list-style: disc;

      > div {
        > div + div {
          margin-top: 0.25rem;
        }
      }

      .price {
        text-wrap: nowrap;
      }

      .item {
        text-align: start;
      }

      .flow {
        margin-top: 0.25rem;

        & + li {
          margin-top: 0.25rem;
        }
      }

      li {
        position: relative;

        gap: 0.5rem;
        display: flex;
        text-align: left;
        align-items: flex-start;

        color: ${theme.colors.mediumGray};

        > span {
          font-weight: 500;
          line-height: 140%;
          font-size: 0.875rem;

          color: ${theme.colors.mediumGray};
        }

        & + li {
          margin-top: 0.25rem;
        }

        &::before {
          position: absolute;
          top: 50%;
          left: -1rem;
          transform: translateY(-50%);

          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;

          display: block;

          content: '';
          background: ${theme.colors.mediumGray};
        }
      }
    }

    .comment {
      width: 100%;
      text-align: left;
      margin: 0.5rem 0 0 0.5rem;

      display: flex;
      gap: 0.2rem;

      > span,
      p {
        font-weight: 500;
        line-height: 150%;
        font-size: 0.875rem;
        color: ${theme.colors.mediumGray};
      }
    }

    ${!!$fullDivider &&
    css`
      margin: 1rem 0rem;

      text-align: left;
    `}
  `}
`;

export const ModalContent = styled.div`
  padding: 1rem 1rem 0;

  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ItemTitle = styled.span`
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.18rem;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const ItemPrice = styled.span`
  margin-top: 0.5rem;
  font-size: 0.87rem;
  font-weight: 600;
  line-height: 1.05rem;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const ActionWrapper = styled.div`
  margin-top: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Divider = styled.hr`
  margin: 1.2rem 0;

  border: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const EditItemButton = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 4px;

  display: inline-flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.brandColors.primary};
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;

export const ErrorMessageWrapper = styled.div`
  padding: 16px;
  padding-top: 6px;

  text-align: center;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.error};
  }
`;

export const MinimumDeliveryPrice = styled.p`
  font-size: 0.75rem !important;
  color: ${({ theme }) => theme.colors.textColor} !important;

  > strong {
    font-size: 0.75rem !important;

    color: ${({ theme }) => theme.colors.textColor} !important;
  }
`;
