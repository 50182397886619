import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  padding: 0.5rem 1rem 0;
`;

export const RegularText = styled.p`
  ${({ theme }) => css`
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    font-size: 0.875rem;
    color: ${theme.colors.gray500};
  `}
`;

export const ImageWrapper = styled.div`
  margin: 1rem 0;

  img {
    max-width: 6.25rem;
    max-height: 4.375rem;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;
