export default function hidePartOfDocumentNumber(document: number | string) {
  const convertedDocumentToString = String(document);
  const isCPF = convertedDocumentToString.length <= 14;

  const initialNumber = convertedDocumentToString.slice(0, isCPF ? 4 : 3);
  const restOfNumber = convertedDocumentToString.slice(4);

  const mustShowPart = restOfNumber.split('-')[1];

  const quantityHiddenNumber = isCPF ? '******' : '**********';

  const hiddenNumber = `${quantityHiddenNumber}-${mustShowPart}`;

  return `${initialNumber}${hiddenNumber}`;
}
