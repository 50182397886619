import styled, { css } from 'styled-components';

export const Container = styled.div<{ $isHidden: boolean }>`
  ${({ theme, $isHidden }) => css`
    width: 100%;
    padding: 0.75rem;
    padding-left: 1rem;

    gap: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
    background-color: #000; //Exception -> pure black on both light and dark mode

    svg {
      path {
        fill: ${theme.isDarkMode ? theme.colors.pureTextColor : theme.colors.backgroundColor};
      }
    }

    ${$isHidden &&
    css`
      display: none;
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .info {
      display: flex;
      flex-direction: column;

      p {
        margin: 0;

        font-weight: 500;
        line-height: 150%;
        font-size: 0.875rem;
        color: ${theme.isDarkMode ? theme.colors.pureTextColor : theme.colors.backgroundColor};

        > strong {
          font-weight: 700;
          line-height: 150%;
          font-size: 0.875rem;
          color: ${theme.isDarkMode ? theme.colors.pureTextColor : theme.colors.backgroundColor};
        }
      }
    }
  `}
`;

export const LogoContainer = styled.div<{ logoUrl?: string }>`
  ${({ theme, logoUrl }) => css`
    min-width: 2rem;
    min-height: 2rem;
    border-radius: 0.5rem;

    background-size: cover;
    background-position: center;
    background-image: url(${logoUrl});
    background-color: ${theme.brandColors.primary};
  `}
`;
