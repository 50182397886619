import styled, { css, DefaultTheme } from 'styled-components';

const containerModifiers = {
  withBanner: (hasLogo: boolean) => css`
    .logo-wrapper {
      top: -100px;
    }

    .content {
      margin-top: -8.25rem;
      padding-top: ${hasLogo ? 60 : 24}px;
    }
  `,
  withLogo: (theme: DefaultTheme) => css`
    .logo-wrapper {
      top: 28px;

      img {
        border-radius: 12px;
        border: 1px solid ${theme.colors.gray200} !important;

        background-color: ${theme.brandColors.primary};
      }
    }

    .content {
      margin-top: -16px;
    }
  `,
  hideContent: (hasBanner: boolean) => css`
    .content {
      padding-top: 0.5rem;
      margin-top: ${!hasBanner ? '-0.25rem' : '-8.25rem'};
    }
  `
};

export const Container = styled.div<{ logoUrl?: string; bannerUrl?: string; hideContent?: boolean }>`
  ${({ theme, logoUrl, bannerUrl, hideContent }) => css`
    height: fit-content;

    background: ${theme.colors.backgroundColor};

    .banner {
      margin: auto;
      height: 8.75rem;

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(${bannerUrl});

      .banner-filter {
        width: 100%;
        height: 100%;

        opacity: 0.4;
        background-color: #000;
      }
    }

    .logo-wrapper {
      position: relative;
      z-index: 21;
      top: 40px;

      width: 7rem;
      height: 7rem;
      margin: auto;

      text-align: center;
    }

    .content {
      z-index: 20;
      position: relative;

      width: 100%;
      margin: -140px auto 0px;
      padding: 65px 24px 24px;
      border-radius: 8px 8px 0 0;
      background-color: ${theme.colors.backgroundColor};

      > div {
        margin: auto;
        max-width: 800px;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        > strong {
          max-width: 300px;

          display: block;

          font-weight: 800;
          overflow: hidden;
          font-size: 1.5rem;
          line-height: 150%;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        > p {
          margin: 8px 0 20px;

          font-weight: 500;
          line-height: 16px;
          text-align: center;
          font-size: 0.875rem;
          color: ${theme.colors.gray500};
        }

        .info-wrap {
          width: 100%;
          height: fit-content;

          gap: 0.5rem;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
        }
      }
    }

    ${!!logoUrl && containerModifiers.withLogo(theme)}
    ${!!bannerUrl && containerModifiers.withBanner(!!logoUrl)}
    ${!!hideContent && containerModifiers.hideContent(!!bannerUrl)}
  `}
`;

export const Back = styled.div<{ hasBanner: boolean }>`
  ${({ theme, hasBanner }) => css`
    position: absolute;
    top: 1rem;
    left: 0.75rem;

    z-index: 1;

    svg {
      path {
        fill: ${hasBanner ? '#fff' : theme.isDarkMode ? theme.colors.pureTextColor : theme.colors.textColor};
      }
    }
  `}
`;
