import React from 'react';

const SmartphoneMoneyCreditIcon: React.FC = () => (
  <svg width="67" height="54" viewBox="0 0 67 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.8171 46.2858C56.6208 46.2858 67.0002 35.9243 67.0002 23.1429C67.0002 10.3614 56.6208 0 43.8171 0C31.0135 0 20.634 10.3614 20.634 23.1429C20.634 35.9243 31.0135 46.2858 43.8171 46.2858Z"
      fill="#E8EAED"
    />
    <path
      d="M4.68673 21.6467C5.21527 21.6467 5.64373 21.2193 5.64373 20.6921C5.64373 20.1649 5.21527 19.7375 4.68673 19.7375C4.1582 19.7375 3.72974 20.1649 3.72974 20.6921C3.72974 21.2193 4.1582 21.6467 4.68673 21.6467Z"
      fill="#30333C"
    />
    <path
      d="M5.07105 6.78644C4.60385 6.78644 4.22583 6.40937 4.22583 5.94335V3.41408C4.22583 2.94806 4.60385 2.57098 5.07105 2.57098C5.53824 2.57098 5.91626 2.94806 5.91626 3.41408V5.94335C5.91626 6.40937 5.53824 6.78644 5.07105 6.78644Z"
      fill="#7F8F9F"
    />
    <path
      d="M5.07105 12.6883C4.60385 12.6883 4.22583 12.3112 4.22583 11.8452V9.31593C4.22583 8.84991 4.60385 8.47284 5.07105 8.47284C5.53824 8.47284 5.91626 8.84991 5.91626 9.31593V11.8452C5.91626 12.3112 5.53824 12.6883 5.07105 12.6883Z"
      fill="#7F8F9F"
    />
    <path
      d="M3.38087 8.47241H0.845217C0.378023 8.47241 0 8.09533 0 7.62932C0 7.1633 0.378023 6.78622 0.845217 6.78622H3.38087C3.84806 6.78622 4.22608 7.1633 4.22608 7.62932C4.22608 8.09533 3.84806 8.47241 3.38087 8.47241Z"
      fill="#7F8F9F"
    />
    <path
      d="M9.29737 8.4725H6.76172C6.29453 8.4725 5.9165 8.09543 5.9165 7.62941C5.9165 7.16339 6.29453 6.78632 6.76172 6.78632H9.29737C9.76456 6.78632 10.1426 7.16339 10.1426 7.62941C10.1426 8.09543 9.76456 8.4725 9.29737 8.4725Z"
      fill="#7F8F9F"
    />
    <path
      d="M10.9871 53.1565V15.0349C10.9871 12.8074 12.7971 11.002 15.0302 11.002H47.5146C49.7474 11.002 51.5574 12.8074 51.5574 15.0349V53.1565"
      fill="white"
    />
    <path
      d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
      fill="#30333C"
    />
    <path
      d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
      fill="#30333C"
    />
    <path d="M22 11H40V12C40 13.6569 38.6569 15 37 15H25C23.3431 15 22 13.6569 22 12V11Z" fill="#30333C" />
    <path d="M53.5361 25.335H51.8457V29.5504H53.5361V25.335Z" fill="#30333C" />
    <path
      d="M51.8452 30.3933H53.5356C54.0028 30.3933 54.3809 30.0163 54.3809 29.5502V25.3348C54.3809 24.8688 54.0028 24.4917 53.5356 24.4917H51.8452C51.378 24.4917 51 24.8688 51 25.3348V29.5502C51 30.0163 51.378 30.3933 51.8452 30.3933Z"
      fill="#30333C"
    />
    <path
      d="M51.8452 37.1385H53.5356C54.0028 37.1385 54.3809 36.7614 54.3809 36.2954V32.0799C54.3809 31.6139 54.0028 31.2368 53.5356 31.2368H51.8452C51.378 31.2368 51 31.6139 51 32.0799V36.2954C51 36.7614 51.378 37.1385 51.8452 37.1385Z"
      fill="#30333C"
    />
    <path
      opacity="0.2"
      d="M31 54C38.1797 54 44 48.1797 44 41C44 33.8203 38.1797 28 31 28C23.8203 28 18 33.8203 18 41C18 48.1797 23.8203 54 31 54Z"
      fill="#7F8F9F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.1494 40.2H32.8851C33.6655 40.2 34.4139 40.5371 34.9657 41.1373C35.5176 41.7374 35.8276 42.5513 35.8276 43.4C35.8276 44.2487 35.5176 45.0626 34.9657 45.6627C34.4139 46.2629 33.6655 46.6 32.8851 46.6H32.1494V48.2C32.1494 48.4122 32.0719 48.6157 31.934 48.7657C31.796 48.9157 31.6089 49 31.4138 49C31.2187 49 31.0316 48.9157 30.8936 48.7657C30.7557 48.6157 30.6782 48.4122 30.6782 48.2V46.6H29.2069C28.6216 46.6 28.0603 46.3471 27.6464 45.8971C27.2325 45.447 27 44.8365 27 44.2C27 43.9878 27.0775 43.7843 27.2155 43.6343C27.3534 43.4843 27.5405 43.4 27.7356 43.4C27.9307 43.4 28.1178 43.4843 28.2558 43.6343C28.3938 43.7843 28.4713 43.9878 28.4713 44.2C28.4713 44.4122 28.5488 44.6157 28.6867 44.7657C28.8247 44.9157 29.0118 45 29.2069 45H30.6782V41.8H29.9425C29.1621 41.8 28.4137 41.4629 27.8618 40.8627C27.31 40.2626 27 39.4487 27 38.6C27 37.7513 27.31 36.9374 27.8618 36.3373C28.4137 35.7371 29.1621 35.4 29.9425 35.4H30.6782V33.8C30.6782 33.5878 30.7557 33.3843 30.8936 33.2343C31.0316 33.0843 31.2187 33 31.4138 33C31.6089 33 31.796 33.0843 31.934 33.2343C32.0719 33.3843 32.1494 33.5878 32.1494 33.8V35.4H33.6207C34.206 35.4 34.7673 35.6529 35.1812 36.1029C35.5951 36.553 35.8276 37.1635 35.8276 37.8C35.8276 38.0122 35.7501 38.2157 35.6121 38.3657C35.4742 38.5157 35.2871 38.6 35.0919 38.6C34.8969 38.6 34.7097 38.5157 34.5718 38.3657C34.4338 38.2157 34.3563 38.0122 34.3563 37.8C34.3563 37.5878 34.2788 37.3843 34.1409 37.2343C34.0029 37.0843 33.8158 37 33.6207 37H32.1494V40.2ZM29.9425 40.2H30.6782V37H29.9425C29.5523 37 29.1781 37.1686 28.9022 37.4686C28.6263 37.7687 28.4713 38.1757 28.4713 38.6C28.4713 39.0243 28.6263 39.4313 28.9022 39.7314C29.1781 40.0314 29.5523 40.2 29.9425 40.2ZM32.1494 45H32.8851C33.2753 45 33.6495 44.8314 33.9254 44.5314C34.2013 44.2313 34.3563 43.8243 34.3563 43.4C34.3563 42.9757 34.2013 42.5687 33.9254 42.2686C33.6495 41.9686 33.2753 41.8 32.8851 41.8H32.1494V45Z"
      fill="#485460"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7776 30.1909C25.9154 28.7624 28.4288 28 31 28C32.7072 28 34.3977 28.3363 35.9749 28.9896C37.5521 29.6429 38.9852 30.6004 40.1924 31.8076C41.3996 33.0148 42.3571 34.4479 43.0104 36.0251C43.6637 37.6023 44 39.2928 44 41C44 43.5712 43.2376 46.0846 41.8091 48.2224C40.3806 50.3602 38.3503 52.0265 35.9749 53.0104C33.5994 53.9944 30.9856 54.2518 28.4638 53.7502C25.9421 53.2486 23.6257 52.0105 21.8076 50.1924C19.9895 48.3743 18.7514 46.0579 18.2498 43.5362C17.7482 41.0144 18.0056 38.4006 18.9896 36.0251C19.9735 33.6497 21.6398 31.6194 23.7776 30.1909ZM25.2221 49.6473C26.9323 50.79 28.9431 51.4 31 51.4C33.7583 51.4 36.4035 50.3043 38.3539 48.3539C40.3043 46.4035 41.4 43.7582 41.4 41C41.4 38.9431 40.7901 36.9323 39.6473 35.2221C38.5045 33.5118 36.8803 32.1788 34.9799 31.3917C33.0796 30.6045 30.9885 30.3985 28.9711 30.7998C26.9537 31.2011 25.1006 32.1916 23.6461 33.6461C22.1916 35.1006 21.2011 36.9537 20.7998 38.9711C20.3986 40.9885 20.6045 43.0796 21.3917 44.9799C22.1788 46.8803 23.5118 48.5045 25.2221 49.6473Z"
      fill="#485460"
    />
  </svg>
);

export default SmartphoneMoneyCreditIcon;
