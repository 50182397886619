import React from 'react';

const IPhoneCamIcon = () => {
  return (
    <svg width="67" height="54" viewBox="0 0 67 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.8171 46.2858C56.6208 46.2858 67.0002 35.9243 67.0002 23.1429C67.0002 10.3614 56.6208 0 43.8171 0C31.0135 0 20.634 10.3614 20.634 23.1429C20.634 35.9243 31.0135 46.2858 43.8171 46.2858Z"
        fill="#E8EAED"
      />
      <path
        d="M4.68673 21.6467C5.21527 21.6467 5.64373 21.2193 5.64373 20.6921C5.64373 20.1649 5.21527 19.7375 4.68673 19.7375C4.1582 19.7375 3.72974 20.1649 3.72974 20.6921C3.72974 21.2193 4.1582 21.6467 4.68673 21.6467Z"
        fill="#30333C"
      />
      <path
        d="M5.07105 6.7865C4.60385 6.7865 4.22583 6.40943 4.22583 5.94341V3.41414C4.22583 2.94812 4.60385 2.57104 5.07105 2.57104C5.53824 2.57104 5.91626 2.94812 5.91626 3.41414V5.94341C5.91626 6.40943 5.53824 6.7865 5.07105 6.7865Z"
        fill="#7F8F9F"
      />
      <path
        d="M5.07105 12.6884C4.60385 12.6884 4.22583 12.3113 4.22583 11.8453V9.31599C4.22583 8.84997 4.60385 8.4729 5.07105 8.4729C5.53824 8.4729 5.91626 8.84997 5.91626 9.31599V11.8453C5.91626 12.3113 5.53824 12.6884 5.07105 12.6884Z"
        fill="#7F8F9F"
      />
      <path
        d="M3.38087 8.47256H0.845217C0.378023 8.47256 0 8.09549 0 7.62947C0 7.16345 0.378023 6.78638 0.845217 6.78638H3.38087C3.84806 6.78638 4.22608 7.16345 4.22608 7.62947C4.22608 8.09549 3.84806 8.47256 3.38087 8.47256Z"
        fill="#7F8F9F"
      />
      <path
        d="M9.29737 8.47256H6.76172C6.29453 8.47256 5.9165 8.09549 5.9165 7.62947C5.9165 7.16345 6.29453 6.78638 6.76172 6.78638H9.29737C9.76456 6.78638 10.1426 7.16345 10.1426 7.62947C10.1426 8.09549 9.76456 8.47256 9.29737 8.47256Z"
        fill="#7F8F9F"
      />
      <path
        d="M10.9871 53.1565V15.0349C10.9871 12.8074 12.7971 11.002 15.0302 11.002H47.5146C49.7474 11.002 51.5574 12.8074 51.5574 15.0349V53.1565"
        fill="white"
      />
      <path
        d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
        fill="#30333C"
      />
      <path d="M9.29688 25.335H10.9873V29.5504H9.29688V25.335Z" fill="#30333C" />
      <path
        d="M10.9878 30.3933H9.29737C8.83017 30.3933 8.45215 30.0163 8.45215 29.5502V25.3348C8.45215 24.8688 8.83017 24.4917 9.29737 24.4917H10.9878C11.455 24.4917 11.833 24.8688 11.833 25.3348V29.5502C11.833 30.0163 11.455 30.3933 10.9878 30.3933Z"
        fill="#30333C"
      />
      <path d="M9.29688 32.0796H10.9873V36.295H9.29688V32.0796Z" fill="#30333C" />
      <path
        d="M10.9878 37.1385H9.29737C8.83017 37.1385 8.45215 36.7614 8.45215 36.2954V32.0799C8.45215 31.6139 8.83017 31.2368 9.29737 31.2368H10.9878C11.455 31.2368 11.833 31.6139 11.833 32.0799V36.2954C11.833 36.7614 11.455 37.1385 10.9878 37.1385Z"
        fill="#30333C"
      />
      <path
        d="M20.2844 32.923C17.0168 32.923 14.3679 30.2808 14.3679 27.0214V20.2766C14.3679 17.0172 17.0168 14.375 20.2844 14.375C23.552 14.375 26.201 17.0172 26.201 20.2766V27.0214C26.201 30.2808 23.552 32.923 20.2844 32.923Z"
        fill="#C6CDD4"
      />
      <path
        d="M20.2852 33.7659C16.5567 33.7659 13.5234 30.7403 13.5234 27.0212V20.2765C13.5234 16.5574 16.5567 13.5317 20.2852 13.5317C24.0136 13.5317 27.0469 16.5574 27.0469 20.2765V27.0212C27.0469 30.7403 24.0136 33.7659 20.2852 33.7659ZM20.2852 15.2179C17.4888 15.2179 15.2139 17.4871 15.2139 20.2765V27.0212C15.2139 29.8106 17.4888 32.0797 20.2852 32.0797C23.0816 32.0797 25.3565 29.8106 25.3565 27.0212V20.2765C25.3565 17.4871 23.0816 15.2179 20.2852 15.2179Z"
        fill="#30333C"
      />
      <path
        d="M20.2844 30.3932C22.1516 30.3932 23.6653 28.8833 23.6653 27.0208C23.6653 25.1583 22.1516 23.6484 20.2844 23.6484C18.4172 23.6484 16.9036 25.1583 16.9036 27.0208C16.9036 28.8833 18.4172 30.3932 20.2844 30.3932Z"
        fill="#485460"
      />
      <path
        d="M20.2852 31.2366C17.9551 31.2366 16.0591 29.3453 16.0591 27.0211C16.0591 24.6969 17.9551 22.8057 20.2852 22.8057C22.6152 22.8057 24.5112 24.6969 24.5112 27.0211C24.5112 29.3453 22.6152 31.2366 20.2852 31.2366ZM20.2852 24.4918C18.887 24.4918 17.7495 25.6264 17.7495 27.0211C17.7495 28.4158 18.887 29.5504 20.2852 29.5504C21.6834 29.5504 22.8208 28.4158 22.8208 27.0211C22.8208 25.6264 21.6834 24.4918 20.2852 24.4918Z"
        fill="#30333C"
      />
      <path
        d="M20.2844 23.6481C22.1516 23.6481 23.6653 22.1382 23.6653 20.2757C23.6653 18.4132 22.1516 16.9033 20.2844 16.9033C18.4172 16.9033 16.9036 18.4132 16.9036 20.2757C16.9036 22.1382 18.4172 23.6481 20.2844 23.6481Z"
        fill="#485460"
      />
      <path
        d="M20.2852 24.4915C17.9551 24.4915 16.0591 22.6002 16.0591 20.276C16.0591 17.9518 17.9551 16.0605 20.2852 16.0605C22.6152 16.0605 24.5112 17.9518 24.5112 20.276C24.5112 22.6002 22.6152 24.4915 20.2852 24.4915ZM20.2852 17.7467C18.887 17.7467 17.7495 18.8813 17.7495 20.276C17.7495 21.6707 18.887 22.8053 20.2852 22.8053C21.6834 22.8053 22.8208 21.6707 22.8208 20.276C22.8208 18.8813 21.6834 17.7467 20.2852 17.7467Z"
        fill="#30333C"
      />
      <path
        d="M31.273 46.4124C33.607 46.4124 35.499 44.525 35.499 42.1969C35.499 39.8688 33.607 37.9814 31.273 37.9814C28.939 37.9814 27.0469 39.8688 27.0469 42.1969C27.0469 44.525 28.939 46.4124 31.273 46.4124Z"
        fill="#C6CDD4"
      />
    </svg>
  );
};

export default IPhoneCamIcon;
