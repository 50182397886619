import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-left: 0.8rem;

  display: flex;
  align-items: center;
`;

export const DiscountTag = styled.div`
  ${({ theme }) => css`
    height: 1.06rem;
    padding: 0 0.125rem 0.125rem 0px;

    position: relative;
    display: inline-block;

    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.06rem;
    border-top-right-radius: 0.18rem;
    border-bottom-right-radius: 0.18rem;
    background: ${theme.colors.success};
    color: ${theme.colors.backgroundColor};

    :before {
      content: '';
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      left: -0.75rem;
      border-color: transparent ${theme.colors.success} transparent transparent;
      border-style: solid;
      border-width: 0.5625rem 0.7875rem 0.55rem 0;
    }
  `}
`;
