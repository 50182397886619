import { all } from 'redux-saga/effects';

import user from './user/sagas';
import nupay from './nupay/sagas';
import myTab from './myTab/sagas';
import menus from './menus/sagas';
import thirky from './thirky/sagas';
import waiter from './waiter/sagas';
import review from './review/sagas';
import pix from './pixPayment/sagas';
import coupons from './coupons/sagas';
import errorLog from './errorLog/sagas';
import localOrder from './localOrders/sagas';
import establishment from './establishment/sagas';

export default function* rootSaga(): Generator {
  return yield all([
    pix,
    user,
    menus,
    myTab,
    nupay,
    review,
    thirky,
    waiter,
    coupons,
    errorLog,
    localOrder,
    establishment
  ]);
}
