import { MountedOrderIntegratedPix, IntegratedPixTabPayment } from '~/interfaces/general';

export interface IPixPaymentState {
  readonly email?: string;
  readonly loading: boolean;
  readonly sendEmail?: boolean;
  readonly orderTime?: Date | string;
  readonly order: MountedOrderIntegratedPix;
  readonly tabPayment?: IntegratedPixTabPayment;
}

export const PixPaymentActionTypes = {
  SEND_EMAIL_REQUEST: '@@pixPayment/SEND_EMAIL_REQUEST',
  SEND_EMAIL_ERROR: '@@pixPayment/SEND_EMAIL_ERROR',
  SEND_EMAIL_SUCCESS: '@@pixPayment/SEND_EMAIL_SUCCESS',
  CLEAN_ALL_PIX: '@@pixPayment/CLEAN_ALL_PIX',
  ADD_PIX_ORDER: '@@pixPayment/ADD_PIX_ORDER',
  CLEAN_PIX_ORDER: '@@pixPayment/CLEAN_PIX_ORDER',
  ADD_ORDER_TIME: '@@pixPayment/ADD_ORDER_TIME',
  CLEAN_ORDER_TIME: '@@pixPayment/CLEAN_ORDER_TIME',
  CANCEL_PIX_ORDER_REQUEST: '@@pixPayment/CANCEL_PIX_ORDER_REQUEST',
  CANCEL_PIX_ORDER_SUCCESS: '@@pixPayment/CANCEL_PIX_ORDER_SUCCESS',
  CANCEL_PIX_ORDER_ERROR: '@@pixPayment/CANCEL_PIX_ORDER_ERROR',
  ADD_PIX_TAB_PAYMENT: '@@pixPayment/ADD_PIX_TAB_PAYMENT',
  CLEAN_PIX_TAB_PAYMENT: '@@pixPayment/CLEAN_PIX_TAB_PAYMENT'
};
