import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: none;
  margin: 0 auto;
  max-width: 800px;

  display: flex;
  flex-direction: column;
`;

export const ListHeader = styled.header`
  padding: 0.571rem 1rem;

  gap: 0.571rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListName = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.688rem;
`;

export const HeaderAnchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.brandColors.primary};

  cursor: pointer;
`;

export const ProductListWrapper = styled.div`
  padding: 0.571rem 0 1.5rem;

  gap: 0.5rem;
  display: flex;
  user-select: none;
  overflow-x: scroll;
  align-items: center;

  > div:first-child {
    margin-left: 1rem;
  }

  > div:last-child {
    margin-right: 1rem;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const AllProductsButton = styled.button`
  ${({ theme }) => css`
    border: none;
    width: 10.85rem;
    min-width: 10.85rem;

    gap: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    background-color: transparent;
    color: ${theme.brandColors.primary};
  `}
`;

export const IconWrapper = styled.div`
  width: 2.85rem;
  height: 2.85rem;
  padding: 0.5rem;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.brandColors.primary};

  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    path {
      fill: ${({ theme }) => theme.brandColors.primary};
    }
  }
`;

export const ButtonText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.brandColors.primary};
`;
