/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
import { Children, cloneElement, isValidElement, useCallback, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

import { darkModeStyleList } from './darkModeStyleList';

interface MapProps extends google.maps.MapOptions {
  style: Record<string, string>;
  onIdle?: (map: google.maps.Map) => void;
  onClick?: (event: google.maps.MapMouseEvent, map: google.maps.Map) => void;
}

const Map: React.FC<MapProps> = ({ style, onIdle, onClick, children, ...options }) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const isFirstRender = useRef(true);
  const ref = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<google.maps.Map>();

  const handleOnClick: (event: google.maps.MapMouseEvent) => void = useCallback(
    (event) => {
      const latLng = event.latLng;

      if (latLng && map) {
        onClick?.(event, map);
      }
    },
    [map, onClick]
  );

  const handleOnIdle: (map: google.maps.Map) => void = useCallback(
    (map) => {
      onIdle?.(map);
    },
    [onIdle]
  );

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          styles: theme.isDarkMode ? darkModeStyleList : []
        })
      );
    }
  }, [map, ref, setMap, theme.isDarkMode]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onClick) {
        map.addListener('click', handleOnClick);
      }

      if (onIdle) {
        map.addListener('idle', () => handleOnIdle(map));
      }
    }
  }, [handleOnClick, handleOnIdle, map, onClick, onIdle]);

  useEffect(() => {
    options.zoom && map?.setZoom(options.zoom);
  }, [map, options.zoom]);

  useEffect(() => {
    options.center && map?.setCenter(options.center);
  }, [map, options.center]);

  useEffect(() => {
    if (map && isFirstRender.current) {
      map.setOptions(options);

      isFirstRender.current = false;
    }
  }, [map, options]);

  return (
    <>
      <div ref={ref} style={style} />

      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // @ts-ignore-error set the map prop on the child component
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map;
