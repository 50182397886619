import styled, { css } from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const Container = Modal
  ? styled(Modal)`
      ${({ theme }) => css`
        .container {
          position: relative;

          height: 100vh;
          padding-bottom: 76px;

          background: ${theme.colors.gray200};

          > .title {
            border-bottom: 1px solid ${theme.colors.gray300};

            background: ${theme.colors.backgroundColor};
          }

          .content {
            display: flex;
            flex-direction: column;

            background: ${theme.colors.gray200};

            h2 {
              text-align: initial !important;
            }
          }
        }
      `}
    `
  : styled.div<ModalProps>``;

export const Content = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;
    padding: 50px 0;
    min-height: 100%;
    margin-bottom: 50px;

    display: flex;
    align-items: center;
    flex-direction: column;

    background: ${theme.colors.gray300};
  `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 800px;

  .summary {
    margin-bottom: 0;
  }

  .waves {
    margin-bottom: 8px;
  }
`;

export const TitleWrapper = styled.div`
  margin: 8px 0 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SectionTitle = styled.div`
  margin: 0;

  display: flex;
  align-items: center;

  > h2 {
    margin-left: 8px;

    font-size: 1rem;
    font-weight: 800;
    line-height: 100%;
  }
`;

export const Border = styled.span`
  ${({ theme }) => css`
    ::before {
      content: '';
      top: 0;
      position: absolute;

      width: 1px;
      height: 32px;

      background: ${theme.colors.gray300};
    }
  `}
`;

export const RecentPayment = styled.div`
  ${({ theme }) => css`
    margin: 12px 0 0;

    display: flex;
    flex-direction: column;

    .text {
      margin: 0 0 12px 0;

      font-weight: 500;
      font-size: 1rem;
      line-height: 150%;
      text-align: left;
      color: ${theme.colors.gray500};
    }
  `}
`;

export const Footer = styled.div`
  bottom: 0;
  left: 50%;
  position: fixed;
  transform: translate(-50%, 0);

  width: 100%;
  max-width: 800px;
  min-height: 30px;
  padding: 1rem;

  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;
