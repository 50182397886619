import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useTranslator } from '~/hooks';

import { Container } from './styles';

interface ImageModalProps {
  desc?: string;
  name?: string;
  image?: string;
  isShown: boolean;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ onClose, isShown, desc = '', name = '', image = '' }) => {
  const { getTranslation } = useTranslator();

  return (
    <Container $isVisible={isShown}>
      <div className="image-header">
        <LazyLoadImage src="/assets/close.svg" width={17} height={16} onClick={(): void => onClose()} />
      </div>

      <div className="image">
        <LazyLoadImage alt={getTranslation('label.imageOf', { name })} src={image} />
      </div>

      <div className="info">
        <p className="name">{name}</p>

        {desc
          ?.trim()
          .split(/\r\n|\n/g)
          .map((line, key) => (
            <p className="description" key={`product-detail-${key}`}>
              {line}
            </p>
          ))}
      </div>
    </Container>
  );
};

export default ImageModal;
