import React from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { Button } from '~/components';
import { useTranslator } from '~/hooks';
import { BannerPizzaIcon } from '~/assets';
import { IApplicationState } from '~/redux-tools/store';
import { QueryParam, WelcomeEnum } from '~/interfaces/enums';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';

import * as S from './styles';

interface RegistrationCompletedProps {
  welcome: WelcomeEnum;
  previousPage?: string;
  isRegisteredUser: boolean;
}

const RegistrationCompleted: React.FC<RegistrationCompletedProps> = ({
  welcome,
  previousPage,
  isRegisteredUser = false
}) => {
  const history = useRouter();

  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const { getTranslation } = useTranslator();

  const gaTrackSignInDone = (): void => {
    GoogleAnalytics.trackEvent(gaEvents.signInEnter, {
      // eslint-disable-next-line camelcase
      establishment_id: settings?.id
    });

    if (previousPage) {
      history.push(`/${previousPage}`);
      return;
    }

    history.push(`/menu?${QueryParam.welcome}=${welcome}`);
  };

  return (
    <S.Container>
      <S.BannerWrapper>
        <BannerPizzaIcon />
      </S.BannerWrapper>

      <S.ContentWrapper>
        <S.Content>
          <h2>
            {isRegisteredUser ? getTranslation('login.loginSuccess') : getTranslation('login.registrationCompleted')}
          </h2>

          <p>{getTranslation('login.info')}</p>
        </S.Content>

        <Button isGhost={false} isGoomerColor onClick={gaTrackSignInDone}>
          {getTranslation('general.backToMenu')}
        </Button>
      </S.ContentWrapper>
    </S.Container>
  );
};

export default RegistrationCompleted;
