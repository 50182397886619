import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { PaymentTypeEnum } from '~/interfaces/enums';
import { ICard, PaymentMethod } from '~/interfaces/general';

const allowedPaymentTypes = Object.values(PaymentTypeEnum);
const allowedPaymentCategories = Object.values(PaymentCategoryEnum);

export function isAllCreditCardsOnPattern(cards?: ICard[]) {
  if (!cards || cards.length === 0) return true;

  const oldPatternPayments = cards.filter((card) => {
    if (!card || card.flagDescription === undefined) return false;

    const cardCategory = card.flagDescription;

    const isCategoryInvalid = !allowedPaymentCategories.includes(cardCategory);

    return isCategoryInvalid;
  });

  if (oldPatternPayments.length > 0) return false;

  return true;
}

export interface IsAllPaymentMethodsOnPatternProps {
  currentPaymentMethods: PaymentMethod[];
}

export function isAllPaymentMethodsOnPattern(currentPaymentMethods?: PaymentMethod[]): boolean {
  if (!currentPaymentMethods || currentPaymentMethods.length === 0) return true;

  const oldPatternPayments = currentPaymentMethods.filter((method) => {
    const methodCategory: PaymentCategoryEnum | undefined = method.category;
    const methodType = method.type as PaymentTypeEnum;

    const isCategoryInvalid = methodCategory ? !allowedPaymentCategories.includes(methodCategory) : true;
    const isTypeInvalid = methodType ? !allowedPaymentTypes.includes(methodType) : true;

    return isCategoryInvalid || isTypeInvalid;
  });

  if (!!oldPatternPayments && oldPatternPayments.length > 0) return false;

  return true;
}
