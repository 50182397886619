import styled from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const StyledModal =
  !!Modal &&
  styled(Modal).attrs((props: ModalProps) => props)`
    .content {
      z-index: 2000;
      height: inherit;
      overflow: hidden;
      position: relative;
    }
  `;

export const Container = styled.div`
  height: 100%;

  .close-icon {
    top: 0;
    right: 0;
    position: absolute;

    margin: 1rem;

    cursor: pointer;
  }
`;
