import styled, { css } from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';
import { Error as InputError } from '~/components/pages/CardEntry/FormInput/styles';

interface ContainerProps extends ModalProps {
  openRight: boolean;
}

export const Container = Modal
  ? styled(Modal).attrs((props: ContainerProps) => props)`
      ${({ theme, isShow, openRight }) => css`
        opacity: 1;

        .container {
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

          background: ${theme.colors.backgroundColor} !important;

          ${openRight &&
          css`
            bottom: 0;
            right: -150%;

            ${isShow &&
            css`
              right: 0;

              transition: 0.5s;
            `}
          `}
        }

        .title {
          position: relative;

          display: flex;
          align-items: center;

          font-weight: 800;
          line-height: 120%;
          text-align: center;
          font-size: 1.125rem;
          color: ${theme.colors.textColor};

          button {
            position: absolute;
            right: 0;
            top: 0;

            width: 50px;
            height: 20px;

            font-size: 12px;
          }

          svg {
            cursor: pointer;
          }
        }

        .content {
          max-height: none;
          padding: 0 16px;
        }

        .group {
          & + .group {
            margin-top: 24px;
          }

          .field {
            position: relative;

            label {
              position: absolute;
              left: 16px;
              top: -0;

              font-size: 12px;
              font-weight: 600;
              line-height: 150%;
              text-align: left;
              color: ${theme.colors.darkGray};
            }

            input {
              margin: 0;
              width: 100%;
            }
          }

          &.-cash {
            margin-top: 1.5rem;

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > div {
              margin: 0;
              min-height: unset;
            }

            .title {
              margin-bottom: 0.5rem;

              font-size: 1rem;
              font-weight: 800;
              line-height: 150%;
              color: ${theme.colors.textColor};
            }

            small {
              margin-bottom: 1rem;

              font-weight: 500;
              line-height: 150%;
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors.mediumGray};
            }

            .field {
              padding: 0;
              width: 100%;
              margin-top: 0;

              > div {
                width: 100%;
                margin: 0;
              }
            }
          }

          &.-cpf {
            input {
              width: 100%;
              margin: 0.25rem 0 1rem;
            }
          }
        }
      `};
    `
  : styled.div<ContainerProps>``;

export const Content = styled.div<{ isContentVisible: boolean }>`
  height: initial;

  opacity: 1;
  transition: 0.5s;

  ${({ isContentVisible }) =>
    !isContentVisible &&
    css`
      display: none;

      opacity: 0;
      transition: 0.5s;
    `}
`;

export const Top = styled.div`
  width: 100%;
  margin: 0 0 1.5rem;

  display: flex;
  flex-direction: column;

  > p {
    margin: 0.5rem 0 0 2rem;

    text-align: left;
    font-weight: 500;
    line-height: 150%;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray500};

    span {
      cursor: pointer;
      font-weight: 500;
      line-height: 150%;
      font-size: 0.875rem;
      text-decoration: underline;
      color: ${({ theme }) => theme.brandColors.primary};
    }
  }

  > div {
    margin-top: 1rem;
  }
`;

export const Error = styled(InputError)`
  left: 16px;

  text-align: left;
`;

export const ButtonWrapper = styled.div`
  margin: 1rem 0 0;
`;
