import styled, { css, DefaultTheme } from 'styled-components';

import { ButtonResume } from '~/components';
import { ButtonResumeProps } from '~/components/Button/ButtonResume';

const modifiers = {
  isInsidePaymentWays: (theme: DefaultTheme) => css`
    margin-top: 0.5rem;

    .left {
      > svg {
        path {
          fill: ${theme.colors.darkGray};
        }
      }

      .info {
        .description {
          color: ${theme.colors.textColor};

          &.-secondary {
            color: ${theme.colors.mediumGray};
          }
        }
      }
    }
  `
};

interface ContainerProps extends ButtonResumeProps {
  isInsidePaymentWays: boolean;
}

export const Container = styled(ButtonResume).attrs((props: ContainerProps) => props)`
  ${({ theme, isInsidePaymentWays }) => css`
    ${isInsidePaymentWays && modifiers.isInsidePaymentWays(theme)}
  `}
`;

export const PaymentWrapper = styled.div<{ $hasPaymentLogo?: boolean }>`
  ${({ $hasPaymentLogo }) => css`
    margin-left: ${$hasPaymentLogo ? '0' : '1.5rem'};
  `}
`;

export const DescriptionWrapper = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;

  > div {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
  }
`;
