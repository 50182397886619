import styled from 'styled-components';
import Image, { ImageProps } from 'next/image';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const Container = Modal
  ? styled(Modal).attrs((props: ModalProps) => props)`
      .container {
        padding-bottom: 0;

        background: ${({ theme }) => theme.colors.lightestGray};

        > .title {
          background: ${({ theme }) => theme.colors.backgroundColor};
        }

        > .go-back {
          display: none;
        }

        .content {
          padding: 16px;

          display: flex;
          flex-direction: column;
          align-items: center;

          .loader {
            margin-top: 46px;
          }

          .text {
            margin: 0;
            margin-top: 38px;

            display: block;

            color: ${({ theme }) => theme.colors.textColor};

            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            line-height: 150%;
            text-align: center;
          }

          .desc {
            margin: 0;
            margin-top: 16px;

            display: flex;
            align-items: center;

            color: ${({ theme }) => theme.colors.mediumGray};

            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            line-height: 150%;
            text-align: center;
          }
        }
      }

      .container {
        overflow: unset;
      }
    `
  : styled.div<ModalProps>``;

export const Loader = styled(Image).attrs((props: ImageProps) => props)`
  path {
    fill: ${({ theme }) => theme.brandColors.primary};
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  animation: spin 1.5s linear infinite;
`;
