import React, { useEffect, useState, useMemo } from 'react';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RiCloseLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { QrCodeReader } from '~/components';
import { IApplicationState } from '~/redux-tools/store';
import { CameraPermissionErrorEnum, LocalOrdersEnum, OrderModeEnum } from '~/interfaces/enums';

import * as S from './styles';

interface QrCodeScannerModalProps {
  onClose: () => void;
  onConfirm?: (readResult: string) => void;
}

const QrCodeScannerModal = ({ onClose, onConfirm }: QrCodeScannerModalProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { mode: localOrder } = useSelector((state: IApplicationState) => state.localOrders);

  const { getTranslation } = useTranslator();

  const [isCameraAllowed, setIsCameraAllowed] = useState<boolean | CameraPermissionErrorEnum>(false);

  const qrCodeMode = useMemo(() => {
    if (settings?.mm_order_mode == OrderModeEnum.Tab) {
      return LocalOrdersEnum.guestCheck;
    }

    return localOrder as LocalOrdersEnum;
  }, [localOrder, settings?.mm_order_mode]);

  useEffect(() => {
    if (typeof window !== 'undefined' && !!settings?.is_abrahao) {
      let stream: MediaStream | null = null;

      window.navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((streamFromMediaDevices) => {
          stream = streamFromMediaDevices;
          setIsCameraAllowed(true);
        })
        .catch((error: Error) => {
          setIsCameraAllowed(error.name as CameraPermissionErrorEnum);
        })
        .finally(() => {
          if (stream) {
            stream.getTracks().forEach((track) => track.stop());
          }
        });
    }
  });

  useEffect(() => {
    if (settings?.is_abrahao) {
      if (typeof isCameraAllowed === 'string') {
        const cameraErrorTypes = {
          [CameraPermissionErrorEnum.NotAllowed]: getTranslation('qrCodeScanner.notAllowed'),
          [CameraPermissionErrorEnum.TrackStart]: getTranslation('qrCodeScanner.trackStart'),
          [CameraPermissionErrorEnum.NotReadable]: getTranslation('qrCodeScanner.notReadable'),
          [CameraPermissionErrorEnum.Default]: getTranslation('qrCodeScanner.errorAccessingCamera'),
          [CameraPermissionErrorEnum.NotFound]: getTranslation('qrCodeScanner.notFound'),
          [CameraPermissionErrorEnum.DevicesNotFound]: getTranslation('qrCodeScanner.devicesNotFound')
        };

        console.error(cameraErrorTypes[isCameraAllowed]);
        toast.info(getTranslation('qrCodeScanner.allowAccessToCamera'));
        onClose();
      }
    }
  }, [getTranslation, isCameraAllowed, onClose, settings?.is_abrahao, settings?.mm_order_mode]);

  if (!isCameraAllowed || typeof isCameraAllowed === 'string') return <></>;

  return (
    <S.StyledModal onClose={onClose} isShow isPageLike hideCloseButton isWithoutPadding ignoreOverflow>
      <S.Container id="qr-code-scanner-modal">
        <QrCodeReader onClose={onClose} onConfirm={onConfirm} qrCodeMode={qrCodeMode} />

        <RiCloseLine
          size={40}
          className="close-icon"
          onClick={(): void => onClose()}
          color={theme.colors.backgroundColor}
        />
      </S.Container>
    </S.StyledModal>
  );
};

export default QrCodeScannerModal;
