import React, { useCallback, useEffect, useState } from 'react';

import { isAfter } from 'date-fns';

import { useTranslator } from '~/hooks';
import getFormattedBirthdayApi from '~/utils/getFormattedBirthdayApi';

import InputDate from '../InputDate';
import { ErrorMessage } from '../Input/styles';

interface InputBirthDateProps {
  required?: boolean;
  isDisabled?: boolean;
  defaultValue?: string;
  errorMessage?: string;
  isGoomerColor?: boolean;
  isBirthDateAfterToday: (isAfterToday: boolean) => void;
  onChange: (dateValue: string, isValid: boolean) => void;
}

const InputBirthDate: React.FC<InputBirthDateProps> = ({
  onChange,
  errorMessage,
  required = false,
  defaultValue = '',
  isDisabled = false,
  isBirthDateAfterToday,
  isGoomerColor = false,
  ...rest
}) => {
  const { getTranslation } = useTranslator();

  const [selectedBirthday, setSelectedBirthday] = useState(defaultValue);
  const [isAfterToday, setIsAfterToday] = useState(false);

  useEffect(() => {
    const formatedBirthday = getFormattedBirthdayApi(selectedBirthday, '/', '-');

    const isBirthdayAfterToday = formatedBirthday ? isAfter(new Date(formatedBirthday), new Date()) : false;

    setIsAfterToday(isBirthdayAfterToday);
    isBirthDateAfterToday(isBirthdayAfterToday);
  }, [isBirthDateAfterToday, selectedBirthday]);

  const handleChange = useCallback(
    (dateValue: string, isValid: boolean) => {
      setSelectedBirthday(dateValue);

      onChange(dateValue, isValid);
    },
    [onChange]
  );

  return (
    <>
      <InputDate
        onChange={handleChange}
        required={required}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        errorMessage={errorMessage}
        isGoomerColor={isGoomerColor}
        {...rest}
      />

      {isAfterToday && <ErrorMessage>{getTranslation('general.invalidBirthdayDate')}</ErrorMessage>}
    </>
  );
};

export default InputBirthDate;
