import Sockette, { SocketteOptions } from 'sockette';

import { SocketteEvent } from '~/hooks/usePaymentSocket';

interface WebsocketProps {
  socketMode: string;
  code: number | string;
  storeID: number | string;
  handleEvent: (event: MessageEvent) => any;
  handleOpenConnection: (event: SocketteEvent) => any;
  handleCloseConnection: (event: CloseEvent) => any;
  handleConnectionError: (event: Event) => any;
}

const SOCKET_URL = process.env.NEXT_PUBLIC_PAYMENT_SOCKET_URL || '';
const ATTEMPTS = Number(process.env.NEXT_PUBLIC_SOCKET_RECONNECT_ATTEMPTS) || 1;
const TIMEOUT = Number(process.env.NEXT_PUBLIC_SOCKET_RECONNECT_TIMEOUT) || 5;

export function websocket({
  socketMode,
  storeID,
  code,
  handleEvent,
  handleOpenConnection,
  handleCloseConnection,
  handleConnectionError
}: WebsocketProps): Sockette {
  const url = `${SOCKET_URL}?sourceIdentifier=${storeID}::${socketMode}::${code}`;

  const config = {
    timeout: Number(`${TIMEOUT}e3`),
    maxAttempts: ATTEMPTS,
    onopen: handleOpenConnection,
    onmessage: handleEvent,
    onclose: handleCloseConnection,
    onerror: handleConnectionError
  } as SocketteOptions;

  const websocketConnection = new Sockette(url, config);

  return websocketConnection;
}
