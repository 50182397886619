import { OperationHours } from '@goomerdev/goomer-toolbox/src/interfaces';

import { IDeliveryAverageTime, ICardFlags, IEstablishmentSettings, KeyValue } from '~/interfaces/general';

export type KeyValueTypes = Record<
  string,
  ICardFlags[] | IDeliveryAverageTime | OperationHours | boolean | number | string
>;

export interface IEstablishmentSettingsFromApi {
  name: string;
  address: string;
  menu_url: string;
  mm_enabled: string;
  mm_timezone: string;
  mm_currency: string;
  mm_logo_url: string;
  mm_logo_key: string;
  description: string;
  mm_hours_mode: string;
  mm_main_color: string;
  mm_always_open: string;
  mm_fixed_hours: string;
  mm_delivery_fee: string;
  mm_operating_hours: string;
  mm_onsite_menu_url: string;
  mm_secondary_color: string;
  mm_delivery_enabled: string;
  mm_takeaway_enabled: string;
  mm_order_show_codes: string;
  mm_facebook_pixel_id: string;
  mm_takeaway_discount: string;
  mm_order_show_prices: string;
  mm_payment_debit_flags: string;
  mm_receipt_ask_for_cpf: string;
  mm_payment_credit_flags: string;
  mm_payment_cash_enabled: string;
  mm_delivery_fee_enabled: string;
  mm_payment_voucher_flags: string;
  mm_payment_debit_enabled: string;
  mm_delivery_zone_options: string;
  mm_free_delivery_enabled: string;
  mm_delivery_average_time: string;
  mm_whatsapp_phone_number: string;
  mm_splash_screen_message: string;
  mm_payment_credit_enabled: string;
  mm_delivery_minimum_value: string;
  mm_whatsapp_order_enabled: string;
  mm_order_show_observations: string;
  mm_payment_voucher_enabled: string;
  mm_takeaway_discount_enabled: string;
  mm_payment_mpago_link_enabled: string;
  mm_free_delivery_minimum_value: string;
  mm_payment_mpago_qrcode_enabled: string;
  mm_delivery_minimum_value_enabled: string;
}

const isObjectInString = (value: string) => {
  const haveObjectBrackets = value.match(/^\{|\}$/g);
  const haveArrayOfObjectBrackets = value.match(/^\[\{|\}\]$/g);
  const haveJustAnArray = value.match(/^\[|\]$/g);

  if (haveObjectBrackets !== null) {
    return haveObjectBrackets.length === 2;
  }

  if (haveArrayOfObjectBrackets !== null) {
    return haveArrayOfObjectBrackets.length === 2;
  }

  if (haveJustAnArray !== null) {
    return haveJustAnArray.length === 2;
  }

  return false;
};

const isTrueSet = (value: string) => value === 'true';
const isFalseSet = (value: string) => value === 'false';
const isBooleanInString = (value: string) => isTrueSet(value) || isFalseSet(value);

const SETTINGS_BLACKLIST = ['mm_splash_screen_message', 'name', 'mm_takeaway_time'];

const convertSettingsData = (data: KeyValue) => {
  const serializedData: KeyValueTypes = {};

  if (!data) return undefined;

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const element = data[key];

      const shouldConvertToBoolean = isBooleanInString(element);

      if (shouldConvertToBoolean && !SETTINGS_BLACKLIST.includes(key)) {
        serializedData[key] = isTrueSet(element);
      } else if (typeof element === 'string' && isObjectInString(element) && !SETTINGS_BLACKLIST.includes(key)) {
        serializedData[key] = JSON.parse(element);
      } else {
        serializedData[key] = element;
      }
    }
  }

  return serializedData as unknown as IEstablishmentSettings;
};

export { convertSettingsData as default, isTrueSet, isFalseSet, isBooleanInString, isObjectInString };
