import { useState, useEffect, useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { useMenuMenus } from '~/hooks/useMenu/hooks';
import { useDesktopHorizontallScroll } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { getFilteredListByAvailableHours } from '~/utils';

import MenuIcon from './MenuIcon';

import * as S from './styles';

const CUSTOM_ICON_KEYWORD = 'uploads';
const MENU_SCROLL_CLASSNAME = 'menu-slider';

const MenuList = (): JSX.Element => {
  const { menus: menuList } = useSelector((state: IApplicationState) => state.menus);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { getFilteredMenuListByAvailableHours } = getFilteredListByAvailableHours;

  const isAvailableHoursEnabled = useMemo(
    () => !!settings?.mm_feature_schedule_by_group_and_product_enabled,
    [settings?.mm_feature_schedule_by_group_and_product_enabled]
  );

  const availableMenuList = getFilteredMenuListByAvailableHours(menuList, isAvailableHoursEnabled);

  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const { selectedMenu, setSelectedMenu } = useMenuMenus();
  useDesktopHorizontallScroll({ scrollClass: `.${MENU_SCROLL_CLASSNAME}` });

  useEffect(() => {
    if (!isScrolled) {
      window.onscroll = (): void => {
        setIsScrolled(true);
      };
    }
  }, [isScrolled]);

  const isCustomIcon = useCallback((iconUrl: string) => iconUrl.includes(CUSTOM_ICON_KEYWORD), []);

  if (!selectedMenu) return <></>;

  return (
    <S.Container $isScrolled={isScrolled} data-test="scrollable-menus">
      <S.MenuListWrapper $isMobile={isMobile} className={MENU_SCROLL_CLASSNAME}>
        {availableMenuList?.map((menu) => (
          <S.Menu
            key={menu.id}
            $isCurrentMenuActive={selectedMenu.id === menu.id}
            onClick={(): void => {
              const groupListElement = document.getElementById('group-list');
              scrollTo({ behavior: 'smooth', top: groupListElement?.offsetTop ? groupListElement?.offsetTop - 77 : 0 });

              setSelectedMenu(menu);
            }}
          >
            {menu.icon_url && (
              <MenuIcon
                alt={menu.name}
                iconUrl={menu.icon_url}
                isCustomIcon={isCustomIcon(menu.icon_url)}
                isCurrentMenuActive={selectedMenu.id === menu.id}
              />
            )}

            <S.MenuTitle $isCurrentMenuActive={selectedMenu.id === menu.id}>{menu.name}</S.MenuTitle>
          </S.Menu>
        ))}
      </S.MenuListWrapper>
    </S.Container>
  );
};

export default MenuList;
