import { getTermIntoString } from '@goomerdev/goomer-toolbox/src/utils';

export function isDelivery(type?: string) {
  if (!type) return false;

  if (getTermIntoString({ word: type, term: 'delivery' })) return true;
  if (getTermIntoString({ word: type, term: 'entrega' })) return true;
  if (getTermIntoString({ word: type, term: 'mm_delivery_enabled' })) return true;

  return false;
}

export function isTakeaway(type?: string) {
  if (!type) return false;

  if (getTermIntoString({ word: type, term: 'takeaway' })) return true;
  if (getTermIntoString({ word: type, term: 'retirada' })) return true;
  if (getTermIntoString({ word: type, term: 'mm_takeaway_enabled' })) return true;

  return false;
}

export function isOnSite(type: string) {
  if (getTermIntoString({ word: type, term: 'onsite' })) return true;

  return false;
}

export function isTable(type: string) {
  if (getTermIntoString({ word: type, term: 'mm_table' })) return true;
  if (getTermIntoString({ word: type, term: 'table' })) return true;
  if (getTermIntoString({ word: type, term: 'mesa' })) return true;

  return false;
}

export function isKiosk(type: string) {
  if (getTermIntoString({ word: type, term: 'mm_kiosk' })) return true;
  if (getTermIntoString({ word: type, term: 'kiosk' })) return true;
  if (getTermIntoString({ word: type, term: 'balcão' })) return true;

  return false;
}

export function isGuestCheck(type: string) {
  if (getTermIntoString({ word: type, term: 'mm_tab' })) return true;
  if (getTermIntoString({ word: type, term: 'tab' })) return true;
  if (getTermIntoString({ word: type, term: 'comanda' })) return true;

  return false;
}
