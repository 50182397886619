import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 1.87rem;
    padding: 0 0.75rem;
    border-radius: 1000px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    background-color: ${theme.isDarkMode ? theme.colors.lightGray : theme.colors.gray100};
  `}
`;
