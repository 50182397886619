import * as S from './styles';

export interface LoyaltyInfoCardProps {
  title?: string;
  iconTop?: boolean;
  isDarkText?: boolean;
  icon?: React.ReactNode;
  text?: React.ReactNode;
}

const LoyaltyInfoCard = ({ icon, text, title, isDarkText, iconTop = false }: LoyaltyInfoCardProps): JSX.Element => {
  return (
    <S.Content $iconTop={iconTop}>
      {icon && <S.SvgWrapper>{icon}</S.SvgWrapper>}

      <S.SectionWrapper>
        {title && <S.SectionTitle>{title}</S.SectionTitle>}

        {text && (
          <S.SectionDescription isDarkText={isDarkText} hasTitle={!!title}>
            {text}
          </S.SectionDescription>
        )}
      </S.SectionWrapper>
    </S.Content>
  );
};

export default LoyaltyInfoCard;
