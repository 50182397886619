import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isEventCodeSpecialChar, removeNoNumbers } from '~/utils';

import * as S from './styles';

export interface InputProps {
  id?: string;
  name: string;
  info?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  'data-test'?: string;
  placeholder?: string;
  defaultValue?: string;
  isUpperCase?: boolean;
  type?: 'number' | 'text';
  onChange: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
}

const Input = ({
  info,
  error,
  onChange,
  maxLength,
  type = 'text',
  required = false,
  defaultValue = '',
  isUpperCase = false,
  ...rest
}: InputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dataTestValue = { ...rest }['data-test'];

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.onkeydown = (event) => {
        if (isEventCodeSpecialChar(event.code) && type === 'number') {
          event.preventDefault();
        }
      };
    }
  }, [type]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = type === 'number' ? removeNoNumbers(e.target.value) : e.target.value;

      const pureValue = isUpperCase ? newValue.toUpperCase() : newValue;

      if (!!maxLength && pureValue.length > maxLength) {
        return e.preventDefault();
      }

      setValue(pureValue);
      return onChange(pureValue);
    },
    [isUpperCase, maxLength, onChange, type]
  );

  return (
    <S.Container>
      <S.Input
        {...rest}
        type={type}
        error={error}
        value={value}
        ref={inputRef}
        required={required}
        onChange={handleChange}
      />

      {!!info && <S.Info>{info}</S.Info>}

      <S.ErrorMessage data-test={`${dataTestValue}-error`} showError={!!error}>
        {error}
      </S.ErrorMessage>
    </S.Container>
  );
};

export default React.memo(Input);
