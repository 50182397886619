import { toast } from 'react-toastify';

interface GetPaymentLinkProps {
  price: number;
  storeId: string;
  getTranslation: (key: string) => string;
  setIsLoading: (status: boolean) => void;
  onError: (error: Error, extra?: string) => void;
}

export default async function getPaymentLink({
  price,
  storeId,
  onError,
  setIsLoading,
  getTranslation
}: GetPaymentLinkProps): Promise<string | undefined> {
  const apiPath = `${process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL || ''}/payments/mercado-pago/link`;

  setIsLoading(true);

  try {
    const response = await fetch(apiPath, {
      method: 'POST',
      body: JSON.stringify({
        storeId,
        price
      })
    });

    const responseJson: { url: string } = await response.json();

    return responseJson.url.replace('https://', '');
  } catch (error) {
    toast.error(getTranslation('errorMessage.errorToGenerateLink'));

    onError(error as Error, 'Falhou ao gerar link de pagamento do Mercado Pago');

    return undefined;
  } finally {
    setIsLoading(false);
  }
}
