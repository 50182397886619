import React, { createContext, useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import validateDate from '~/utils/validateDate';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { Button, NewWaves, GoomerExperience } from '~/components';
import { useGetErrorMessage, useTranslator, useUserAuthenticationStatus } from '~/hooks';
import { validateName, validatePhone, validateEmail, sanitizeOnlyLettersField } from '~/utils';
import { EmailVerificationStatusEnum, LoginOptionEnum, TunaSessionStorageEnum, WelcomeEnum } from '~/interfaces/enums';
import {
  ErrorInfo,
  PhoneNumber,
  FirebaseData,
  EmailCodeAPIRequest,
  EmailCodeVerifyResponse,
  HandleRegistrationSubmit
} from '~/interfaces/general';
import {
  authenticateUser,
  verifyEmailCodeRequest,
  fetchCustomerInfoRequest,
  getRegisteredUserRequest,
  sendGoogleRegistrationRequest,
  generateEmailVerificationCodeRequest
} from '~/redux-tools/store/user/actions';

import { SplashHeader } from '../pages/Splash';
import { SendEmail, VerifyEmailCode, SendCustomerInformation } from './components';

import * as S from './styles';

export enum AccountVerificationEnum {
  SendEmail = 'sendEmail',
  VerifyEmailCode = 'verifyEmailCode',
  SendCustomerInformation = 'sendCustomerInformation'
}

export interface EmailVerificationProps {
  resend?: boolean;
}

export interface AccountVerificationContextProps {
  userName: string;
  userEmail: string;
  isNewUser: boolean;
  isLoading: boolean;
  emailRetries: number;
  userBirthday: string;
  countdownTime: number;
  resetCountdown: () => void;
  isCountdownRunning: boolean;
  isCountdownExpired: boolean;
  userPhoneNumber: PhoneNumber;
  emailVerificationCode: string;
  isBirthdayAfterToday: boolean;
  maskedEmail: string | undefined;
  phoneIsAlreadyRegistered: boolean;
  selectedLoginOption: LoginOptionEnum;
  isAbleToProceedWithoutSignUp: boolean;
  handleContinueWithoutSignUp: () => void;
  setUserName: (userName: string) => void;
  setCountdownTime: (time: number) => void;
  setUserEmail: (userEmail: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setEmailRetries: (emailRetries: number) => void;
  setUserBirthday: (userBirthday: string) => void;
  setIsCountdownRunning: (status: boolean) => void;
  setEmailVerificationCode: (code: string) => void;
  validationCodeStatus: EmailVerificationStatusEnum;
  setCountdownIsExpired: (isExpired: boolean) => void;
  setIsAbleToProceedWithoutSignUp: (status: boolean) => void;
  setUserPhoneNumber: (userPhoneNumber: PhoneNumber) => void;
  setIsBirthdayAfterToday: (isBirthdayAfterToday: boolean) => void;
  setValidationCodeStatus: (status: EmailVerificationStatusEnum) => void;
  generateEmailVerificationCode: (props?: EmailVerificationProps) => void;
}

export const AccountVerificationContext = createContext<AccountVerificationContextProps>(
  {} as AccountVerificationContextProps
);

export interface AccountVerificationProps {
  onClose: () => void;
  shouldDisplay?: boolean;
  firebaseData?: FirebaseData;
  selectedLoginOption: LoginOptionEnum;
  onConfirm?: (props?: HandleRegistrationSubmit) => void;
}

const INITIAL_COUNTDOWN_SECONDS = 15;

const AccountVerification: (props: AccountVerificationProps) => JSX.Element = ({
  onClose,
  onConfirm,
  firebaseData,
  selectedLoginOption,
  shouldDisplay = false
}: AccountVerificationProps) => {
  const dispatch = useDispatch();

  const history = useRouter();
  const isFirstRender = useRef(true);
  const { getTranslation } = useTranslator();

  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const [userEmail, setUserEmail] = useState<string>(firebaseData?.email || '');
  const [maskedEmail, setMaskedEmail] = useState<string | undefined>(undefined);

  const [userName, setUserName] = useState<string>(sanitizeOnlyLettersField(firebaseData?.displayName || ''));
  const [userBirthday, setUserBirthday] = useState<string>('');
  const [isBirthdayAfterToday, setIsBirthdayAfterToday] = useState<boolean>(false);

  const [userPhoneNumber, setUserPhoneNumber] = useState<PhoneNumber>({
    pure: '',
    masked: '',
    withDDI: ''
  });
  const [previusUserPhone, setPreviusUserPhone] = useState<string>('');
  const [phoneIsAlreadyRegistered, setPhoneIsAlreadyRegistered] = useState<boolean>(false);

  const [emailVerificationCode, setEmailVerificationCode] = useState<string>('');
  const [lastEmailVerificationCode, setLastEmailVerificationCode] = useState<string>('');
  const [emailVerificationId, setEmailVerificationId] = useState<string>('');
  const [emailRetries, setEmailRetries] = useState<number>(0);
  const [alreadyEnteredValidEmail, setAlreadyEnteredValidEmail] = useState<boolean>(false);

  const [countdownTime, setCountdownTime] = useState(INITIAL_COUNTDOWN_SECONDS);
  const [isCountdownExpired, setCountdownIsExpired] = useState<boolean>(false);
  const [isCountdownRunning, setIsCountdownRunning] = useState<boolean>(false);
  const [isAbleToProceedWithoutSignUp, setIsAbleToProceedWithoutSignUp] = useState<boolean>(false);

  const [lastSentInfo, setLastSentInfo] = useState<EmailCodeAPIRequest>({ email: '' });
  const [validationCodeStatus, setValidationCodeStatus] = useState<EmailVerificationStatusEnum>(
    EmailVerificationStatusEnum.default
  );

  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [goomerToken, setGoomerToken] = useState<string>('');
  const [isAbleToAuthenticate, setIsAbleToAuthenticate] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<AccountVerificationEnum>(AccountVerificationEnum.SendEmail);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getErrorMessage } = useGetErrorMessage();
  const { isUserAuthenticated } = useUserAuthenticationStatus();

  const resetCountdown = useCallback(() => {
    setCountdownIsExpired(false);
    setCountdownTime(INITIAL_COUNTDOWN_SECONDS);
  }, []);

  const clearCountdown = useCallback(() => {
    setCountdownIsExpired(true);
    setCountdownTime(0);
    setIsCountdownRunning(false);
  }, []);

  const sendGoogleRegistration = useCallback(() => {
    setIsLoading(true);

    const afterAction = (goomerToken?: string, errorCode?: string): void => {
      setIsLoading(false);

      if (goomerToken) {
        setGoomerToken(goomerToken);
        setIsAbleToAuthenticate(true);

        return;
      }

      const customError = getErrorMessage(errorCode || '');
      toast.error(customError ? customError.message : getTranslation('accountVerification.googleLoginErrorMessage'));

      return onClose();
    };

    const addErrorLog = (): void => {
      setIsLoading(false);
    };

    dispatch(
      sendGoogleRegistrationRequest({
        name: userName,
        birthday: userBirthday,
        phone: userPhoneNumber.withDDI || '',
        googleToken: firebaseData?.idToken || '',
        functions: {
          addErrorLog,
          afterAction
        }
      })
    );
  }, [
    onClose,
    dispatch,
    userName,
    userBirthday,
    getTranslation,
    getErrorMessage,
    firebaseData?.idToken,
    userPhoneNumber.withDDI
  ]);

  const generateEmailVerificationCode = useCallback(
    (props?: EmailVerificationProps): void => {
      const info = {
        email: userEmail
      };

      if (info.email !== lastSentInfo.email || props?.resend) {
        setIsLoading(true);

        const afterAction = (emailVerificationId?: string, errorCode?: string): void => {
          setIsLoading(false);

          if (emailVerificationId) {
            setLastSentInfo(info);
            setEmailVerificationId(emailVerificationId);

            resetCountdown();
            setIsCountdownRunning(true);
            setCurrentStep(AccountVerificationEnum.VerifyEmailCode);

            return;
          }

          clearCountdown();

          const customError = getErrorMessage(errorCode || '');

          toast.error(customError ? customError.message : getTranslation('accountVerification.sendCodeErrorMessage'));

          return;
        };

        const addErrorLog = (): void => {
          setIsLoading(false);
        };

        dispatch(
          generateEmailVerificationCodeRequest({
            email: userEmail,
            resend: props?.resend || false,
            functions: {
              addErrorLog,
              afterAction
            }
          })
        );

        return;
      }

      return setCurrentStep(AccountVerificationEnum.VerifyEmailCode);
    },
    [userEmail, lastSentInfo.email, dispatch, clearCountdown, getErrorMessage, getTranslation, resetCountdown]
  );

  const handleOnClose = useCallback(() => {
    if (isLoading || validationCodeStatus === EmailVerificationStatusEnum.success) return;

    if (currentStep === AccountVerificationEnum.SendEmail) {
      if (selectedLoginOption === LoginOptionEnum.email) {
        GoogleAnalytics.trackEvent(gaEvents.signInEmailOut, {
          // eslint-disable-next-line camelcase
          establishment_id: settings?.id
        });
      }

      resetCountdown();
      return onClose();
    }

    if (currentStep === AccountVerificationEnum.SendCustomerInformation) {
      if (selectedLoginOption === LoginOptionEnum.email) {
        return setCurrentStep(AccountVerificationEnum.SendEmail);
      }

      return onClose();
    }

    if (currentStep === AccountVerificationEnum.VerifyEmailCode) {
      if (isNewUser) {
        return setCurrentStep(AccountVerificationEnum.SendCustomerInformation);
      }

      return setCurrentStep(AccountVerificationEnum.SendEmail);
    }

    return onClose();
  }, [
    onClose,
    isLoading,
    isNewUser,
    currentStep,
    settings?.id,
    resetCountdown,
    selectedLoginOption,
    validationCodeStatus
  ]);

  const validateStep: () => boolean = useCallback(() => {
    const sendEmail = () => {
      if (selectedLoginOption === LoginOptionEnum.google) return true;

      if (!validateEmail(userEmail)) return false;

      if (!alreadyEnteredValidEmail && selectedLoginOption === LoginOptionEnum.email) {
        setAlreadyEnteredValidEmail(true);

        GoogleAnalytics.trackEvent(gaEvents.signInEmailFill, {
          // eslint-disable-next-line camelcase
          establishment_id: settings?.id
        });
      }

      return true;
    };

    const sendCustomerInformation = (): boolean => {
      const afterAction = (
        isNewUser?: boolean,
        phoneIsNotLinkedToAnotherAccount?: boolean,
        maskedEmail?: string
      ): void => {
        setIsLoading(false);
        setMaskedEmail(maskedEmail);

        if (!isNewUser && !phoneIsNotLinkedToAnotherAccount) {
          setPhoneIsAlreadyRegistered(true);
          return;
        }

        setPhoneIsAlreadyRegistered(false);

        return;
      };

      const addErrorLog = (): void => {
        setIsLoading(false);
      };

      if (!validateName({ value: userName })) return false;

      if (!validatePhone({ onlyMobile: true, phoneNumber: userPhoneNumber.pure })) return false;

      if (userBirthday && (!validateDate(userBirthday) || isBirthdayAfterToday)) return false;

      if (previusUserPhone !== userPhoneNumber.pure) {
        setIsLoading(true);
        setPreviusUserPhone(userPhoneNumber.pure);

        dispatch(
          getRegisteredUserRequest({
            getTranslation,
            mode: LoginOptionEnum.phone,
            param: userPhoneNumber.withDDI,
            functions: {
              addErrorLog,
              afterAction
            }
          })
        );
      }

      return !phoneIsAlreadyRegistered;
    };

    const verifyEmailCode = (): boolean => {
      if (validationCodeStatus === EmailVerificationStatusEnum.success) {
        return true;
      }

      return false;
    };

    const steps = {
      [AccountVerificationEnum.SendEmail]: sendEmail,
      [AccountVerificationEnum.VerifyEmailCode]: verifyEmailCode,
      [AccountVerificationEnum.SendCustomerInformation]: sendCustomerInformation
    };

    return steps[currentStep]();
  }, [
    dispatch,
    userName,
    userEmail,
    currentStep,
    settings?.id,
    userBirthday,
    getTranslation,
    previusUserPhone,
    selectedLoginOption,
    userPhoneNumber.pure,
    isBirthdayAfterToday,
    validationCodeStatus,
    userPhoneNumber.withDDI,
    phoneIsAlreadyRegistered,
    alreadyEnteredValidEmail
  ]);

  const handleContinue = useCallback(() => {
    const sendEmail = (): void => {
      setIsLoading(true);
      setIsNewUser(false);

      const afterAction = (isNewUser?: boolean, isAnAnonymousUser?: boolean) => {
        setIsLoading(false);
        setIsNewUser(isNewUser || false);

        if (isNewUser || isAnAnonymousUser) {
          return setCurrentStep(AccountVerificationEnum.SendCustomerInformation);
        }

        if (selectedLoginOption === LoginOptionEnum.email) {
          return generateEmailVerificationCode();
        }

        if (selectedLoginOption === LoginOptionEnum.google) {
          return sendGoogleRegistration();
        }
      };

      const addErrorLog = (log: ErrorInfo) => {
        setIsLoading(false);

        if (selectedLoginOption === LoginOptionEnum.google) {
          onClose();
        }
      };

      if (selectedLoginOption === LoginOptionEnum.email) {
        GoogleAnalytics.trackEvent(gaEvents.signInEmailContinue, {
          // eslint-disable-next-line camelcase
          establishment_id: settings?.id
        });
      }

      const paramByLoginOption: Record<string, string> = {
        [LoginOptionEnum.email]: userEmail,
        [LoginOptionEnum.google]: firebaseData?.idToken || ''
      };

      dispatch(
        getRegisteredUserRequest({
          getTranslation,
          mode: selectedLoginOption,
          param: paramByLoginOption[selectedLoginOption],
          functions: {
            addErrorLog,
            afterAction
          }
        })
      );
    };

    const sendCustomerInformation = (): void => {
      if (selectedLoginOption === LoginOptionEnum.email) {
        generateEmailVerificationCode();
        return;
      }

      if (selectedLoginOption === LoginOptionEnum.google) {
        sendGoogleRegistration();
        return;
      }
    };

    const verifyEmailCode = () => {
      const activeElement = document.activeElement as HTMLInputElement;

      if (document && !!activeElement && !!activeElement?.blur) {
        activeElement?.blur();
      }

      GoogleAnalytics.trackEvent(gaEvents.ggoSignupConfirmCod, {
        storeId: settings?.id,
        phpId: settings?.store_code
      });

      setIsAbleToAuthenticate(true);
    };

    const steps = {
      [AccountVerificationEnum.SendEmail]: sendEmail,
      [AccountVerificationEnum.VerifyEmailCode]: verifyEmailCode,
      [AccountVerificationEnum.SendCustomerInformation]: sendCustomerInformation
    };

    if (isUserAuthenticated && !shouldDisplay) return;

    return validateStep() && steps[currentStep]();
  }, [
    onClose,
    dispatch,
    userEmail,
    currentStep,
    validateStep,
    settings?.id,
    shouldDisplay,
    getTranslation,
    isUserAuthenticated,
    selectedLoginOption,
    settings?.store_code,
    firebaseData?.idToken,
    sendGoogleRegistration,
    generateEmailVerificationCode
  ]);

  const handleContinueWithoutSignUp = useCallback(() => {
    if (onConfirm) {
      GoogleAnalytics.trackEvent(gaEvents.ggoSignupContinueWithoutVerification);
      onConfirm({ continueWithoutSignUp: true });
    }

    history.push('/menu');
  }, [history, onConfirm]);

  useEffect(() => {
    if (isFirstRender.current && selectedLoginOption === LoginOptionEnum.google) {
      handleContinue();
    }

    isFirstRender.current = false;
  }, [firebaseData, handleContinue, selectedLoginOption, userEmail]);

  useEffect(() => {
    if (emailRetries >= 1) {
      setIsAbleToProceedWithoutSignUp(true);
    }
  }, [isCountdownRunning, emailRetries]);

  useEffect(() => {
    const afterAction: (response: EmailCodeVerifyResponse) => void = (response) => {
      setIsLoading(false);

      if (response.goomerToken) {
        setGoomerToken(response.goomerToken);
        return setValidationCodeStatus(EmailVerificationStatusEnum.success);
      }

      return setValidationCodeStatus(EmailVerificationStatusEnum.error);
    };

    const addErrorLog: (log: ErrorInfo) => void = (log) => {
      setIsLoading(false);
    };

    if (emailVerificationCode.length === 5 && lastEmailVerificationCode !== emailVerificationCode) {
      setIsLoading(true);

      GoogleAnalytics.trackEvent(gaEvents.signInEmailCodeDone, {
        establishment_id: settings?.id
      });

      dispatch(
        verifyEmailCodeRequest({
          isNewUser,
          name: userName || '',
          birthday: userBirthday,
          token: emailVerificationId,
          code: emailVerificationCode,
          phone: userPhoneNumber.withDDI || '',
          functions: {
            addErrorLog,
            afterAction
          }
        })
      );

      setLastEmailVerificationCode(emailVerificationCode);
    }
  }, [
    dispatch,
    userName,
    isNewUser,
    userBirthday,
    settings?.id,
    emailVerificationId,
    emailVerificationCode,
    userPhoneNumber.withDDI,
    lastEmailVerificationCode
  ]);

  useEffect(() => {
    if (validationCodeStatus === EmailVerificationStatusEnum.success) {
      handleContinue();
    }
  }, [handleContinue, emailVerificationCode, validationCodeStatus]);

  const clearTunaSession = useCallback(() => {
    sessionStorage.removeItem(TunaSessionStorageEnum.TokenizatorSessionData);
  }, []);

  useEffect(() => {
    if (isAbleToAuthenticate) {
      const userInfo = {
        email: userEmail,
        token: goomerToken,
        isRegisteredUser: !isNewUser,
        loginOptionType: selectedLoginOption
      };

      dispatch(authenticateUser(userInfo));

      dispatch(
        fetchCustomerInfoRequest({
          token: goomerToken,
          storeId: settings?.id || 0
        })
      );

      onClose();
      clearTunaSession();

      if (onConfirm) {
        return onConfirm({
          token: goomerToken,
          welcome: WelcomeEnum.first,
          isRegisteredUser: !isNewUser
        });
      }
    }
  }, [
    onClose,
    dispatch,
    onConfirm,
    userEmail,
    isNewUser,
    goomerToken,
    settings?.id,
    handleContinue,
    clearTunaSession,
    selectedLoginOption,
    isAbleToAuthenticate,
    validationCodeStatus,
    emailVerificationCode
  ]);

  const footerText = useMemo(() => {
    const steps = {
      [AccountVerificationEnum.VerifyEmailCode]: () => '',
      [AccountVerificationEnum.SendEmail]: () => getTranslation('general.continue'),
      [AccountVerificationEnum.SendCustomerInformation]: () => getTranslation('general.continue')
    };

    return steps[currentStep]();
  }, [currentStep, getTranslation]);

  const render = useMemo(
    () => ({
      sendEmail: <SendEmail />,
      verifyEmailCode: <VerifyEmailCode />,
      sendCustomerInformation: <SendCustomerInformation />
    }),
    []
  );

  return (
    <AccountVerificationContext.Provider
      value={{
        userName,
        isNewUser,
        userEmail,
        isLoading,
        maskedEmail,
        setUserName,
        emailRetries,
        setUserEmail,
        userBirthday,
        setIsLoading,
        countdownTime,
        resetCountdown,
        userPhoneNumber,
        setEmailRetries,
        setUserBirthday,
        setCountdownTime,
        isCountdownRunning,
        isCountdownExpired,
        setUserPhoneNumber,
        selectedLoginOption,
        validationCodeStatus,
        isBirthdayAfterToday,
        setIsCountdownRunning,
        setCountdownIsExpired,
        emailVerificationCode,
        setValidationCodeStatus,
        setIsBirthdayAfterToday,
        phoneIsAlreadyRegistered,
        setEmailVerificationCode,
        handleContinueWithoutSignUp,
        isAbleToProceedWithoutSignUp,
        generateEmailVerificationCode,
        setIsAbleToProceedWithoutSignUp
      }}
    >
      <S.StyledModal isPageLike isShow={shouldDisplay} onClose={handleOnClose}>
        <S.ContainerWrapper>
          <S.ContentWrapper>
            <SplashHeader
              hideContent
              backAction={handleOnClose}
              establishment={{
                logo: settings?.mm_logo_url,
                banner: settings?.mm_banner_url
              }}
            />

            <S.FormWrapper>
              {render[currentStep]}

              {!!footerText && (
                <Button
                  isGoomerColor
                  isGhost={false}
                  onClick={handleContinue}
                  style={{ marginTop: '1rem' }}
                  isDisabled={!validateStep() || isLoading}
                >
                  {footerText}
                </Button>
              )}
            </S.FormWrapper>

            <S.WavesWrapper>
              <NewWaves />
            </S.WavesWrapper>
          </S.ContentWrapper>

          <S.GoomerWrapper>
            <GoomerExperience />
          </S.GoomerWrapper>
        </S.ContainerWrapper>
      </S.StyledModal>
    </AccountVerificationContext.Provider>
  );
};

export default AccountVerification;
