import { useState, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';

import AngleDown from '~/assets/AngleDownIcon';

import * as S from './styles';

interface AccordionProps {
  title: string;
  content?: string;
}

const Accordion: React.FC<AccordionProps> = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const expandStyles = useMemo(
    () => ({
      collapsed: { height: 0, opacity: 0 },
      expanded: { opacity: 1, height: 'auto' }
    }),
    []
  );

  return (
    <S.Container
      onClick={(): void => {
        if (content) setIsExpanded(!isExpanded);
      }}
    >
      <S.Header isExpanded={isExpanded}>
        <p className="title">{title}</p>

        {content && <AngleDown />}
      </S.Header>

      <AnimatePresence initial={false}>
        {isExpanded && (
          <S.HideContent
            exit="collapsed"
            animate="expanded"
            initial="collapsed"
            variants={expandStyles}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <p className="text">{content}</p>
          </S.HideContent>
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default Accordion;
