import React from 'react';

const ProfileIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9707 8.56746C11.7589 7.95074 12.3342 7.10504 12.6166 6.14801C12.899 5.19097 12.8744 4.1702 12.5463 3.22771C12.2182 2.28521 11.6028 1.46786 10.7859 0.889368C9.96892 0.310873 8.99097 0 7.98808 0C6.98519 0 6.00723 0.310873 5.19027 0.889368C4.37331 1.46786 3.75797 2.28521 3.42985 3.22771C3.10173 4.1702 3.07716 5.19097 3.35954 6.14801C3.64193 7.10504 4.21723 7.95074 5.00542 8.56746C3.65485 9.10558 2.47642 9.99811 1.59578 11.1499C0.715138 12.3017 0.165288 13.6696 0.0048493 15.1077C-0.00676409 15.2127 0.00253114 15.3189 0.0322041 15.4204C0.061877 15.5218 0.111346 15.6164 0.177788 15.6988C0.311973 15.8652 0.507144 15.9718 0.720365 15.9952C0.933586 16.0185 1.14739 15.9566 1.31475 15.8232C1.4821 15.6897 1.5893 15.4956 1.61275 15.2836C1.78929 13.7206 2.53865 12.2772 3.71767 11.229C4.89669 10.1807 6.4227 9.60128 8.00416 9.60128C9.58561 9.60128 11.1116 10.1807 12.2906 11.229C13.4697 12.2772 14.219 13.7206 14.3956 15.2836C14.4174 15.48 14.5117 15.6615 14.6601 15.7929C14.8086 15.9243 15.0008 15.9964 15.1995 15.9952H15.2879C15.4987 15.9711 15.6913 15.8651 15.8238 15.7003C15.9563 15.5356 16.018 15.3254 15.9954 15.1157C15.8342 13.6735 15.2814 12.3021 14.3962 11.1485C13.5111 9.99497 12.3269 9.10273 10.9707 8.56746ZM7.98808 7.99978C7.35205 7.99978 6.73031 7.81222 6.20147 7.4608C5.67264 7.10938 5.26046 6.6099 5.01706 6.02551C4.77367 5.44112 4.70998 4.79808 4.83406 4.1777C4.95815 3.55732 5.26442 2.98746 5.71416 2.54019C6.1639 2.09292 6.7369 1.78833 7.3607 1.66492C7.98451 1.54152 8.6311 1.60486 9.21871 1.84692C9.80632 2.08898 10.3086 2.49889 10.6619 3.02483C11.0153 3.55076 11.2039 4.16909 11.2039 4.80163C11.2039 5.64983 10.8651 6.4633 10.262 7.06307C9.65891 7.66284 8.84096 7.99978 7.98808 7.99978Z"
        fill="#7F8F9F"
      />
    </svg>
  );
};

export default ProfileIcon;
