import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Button } from '~/components';
import { fetchCityList } from '~/services';
import { CityInfo } from '~/interfaces/general';
import { removeSpecialCharacters } from '~/utils';
import { useAddress, useTranslator } from '~/hooks';
import { AddressPageModeEnum } from '~/interfaces/enums';
import { AddressContext } from '~/components/Address/AddressList';

import { AddressFormContext } from '../..';

import * as S from './styles';

const CITY_SEARCH_RESULT_LIMIT = 2;

const FormFields = (): JSX.Element => {
  const {
    state,
    street,
    setState,
    cityName,
    reference,
    setStreet,
    complement,
    setCityName,
    neighborhood,
    setReference,
    streetNumber,
    setComplement,
    setNeighborhood,
    setStreetNumber
  } = useContext(AddressFormContext);

  const [cityList, setCityList] = useState<CityInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [citySearch, setCitySearch] = useState<string | undefined>(cityName);

  const { getTranslation } = useTranslator();
  const { selectedView, isGoomerColor } = useContext(AddressContext);
  const { handleSave, hasInitialFieldsFilled, hasAllRequiredFieldsFilled } = useAddress();

  const isNoCepForm = useMemo(() => selectedView === AddressPageModeEnum.noCepForm, [selectedView]);

  const [isCitySelected, setIsCitySelected] = useState<boolean>(hasInitialFieldsFilled || !isNoCepForm ? true : false);

  const updateCityList = useCallback(
    async (value: string) => {
      setCitySearch(value);
      setCityName(value);

      if (value.length >= 3) {
        try {
          setIsLoading(true);
          const cityList = await fetchCityList({ search: value, resultLimit: CITY_SEARCH_RESULT_LIMIT });

          const matchingCity = cityList.find(({ name }) => name.toLowerCase() === value.toLowerCase());

          if (matchingCity) {
            setState(matchingCity.state);
          }

          return setCityList(cityList);
        } finally {
          setIsLoading(false);
        }
      }

      return setCityList([]);
    },
    [setCityName, setState]
  );

  const handleSelectCity = useCallback(
    (item: CityInfo) => {
      setIsCitySelected(true);
      setCitySearch(item.name);
      setCityName(item.name);
      setState(item.state);

      setCityList([]);
    },
    [setCityName, setState]
  );

  const handleClickSaveButton = useCallback(() => {
    setIsLoading(true);

    handleSave(() => setIsLoading(false));
  }, [handleSave]);

  if (!hasInitialFieldsFilled) {
    return <></>;
  }

  return (
    <S.Content>
      <S.FormWrapper hasSmallPadding={isNoCepForm}>
        {(isNoCepForm || selectedView === AddressPageModeEnum.noStreetByCepForm) && (
          <S.FieldWrapper>
            <label className="required">{`${getTranslation('address.streetNameLabel')}*`}</label>
            <input
              value={street}
              maxLength={100}
              data-test="street-name-input"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                setStreet(removeSpecialCharacters(event.target.value))
              }
            />
          </S.FieldWrapper>
        )}

        <S.FieldWrapper hasRightPadding size="small">
          <label className="required">{`${getTranslation('general.number')}*`}</label>
          <input
            maxLength={9}
            placeholder="000"
            inputMode="numeric"
            value={streetNumber}
            data-test="street-number-input"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setStreetNumber(removeSpecialCharacters(event.target.value).trim())
            }
          />
        </S.FieldWrapper>

        <S.FieldWrapper size="medium">
          <label className="required">{getTranslation('address.complement')}</label>
          <input
            maxLength={25}
            value={complement}
            data-test="complement-input"
            placeholder={`${getTranslation('address.complementPlaceholder')}...`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setComplement(removeSpecialCharacters(event.target.value))
            }
          />
        </S.FieldWrapper>

        <S.FieldWrapper>
          <label className="required">{`${getTranslation('address.referencePoint')}?`}</label>
          <input
            maxLength={50}
            value={reference}
            data-test="reference-point-input"
            placeholder={`${getTranslation('general.typeHere')}...`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setReference(removeSpecialCharacters(event.target.value))
            }
          />
        </S.FieldWrapper>

        {(isNoCepForm || selectedView === AddressPageModeEnum.noStreetByCepForm) && (
          <S.FieldWrapper>
            <label className="required">{`${getTranslation('address.neighborhood')}*`}</label>
            <input
              maxLength={50}
              value={neighborhood}
              data-test="neighborhood-input"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setNeighborhood(removeSpecialCharacters(event.target.value));
              }}
            />
          </S.FieldWrapper>
        )}

        {isNoCepForm && (
          <>
            <S.FieldWrapper hasRightPadding size="medium">
              <label className="required">{`${getTranslation('address.city')}*`}</label>
              <S.DebounceInput
                type="text"
                debounceTimeout={300}
                data-test="city-input"
                value={citySearch || cityName}
                placeholder="Digite o nome da cidade"
                onChange={async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
                  updateCityList(event.target.value);
                  setIsCitySelected(false);
                }}
              />

              {cityList.length > 0 && (
                <div className="city-list">
                  {cityList.map((item, index) => (
                    <S.CityOption data-test="city-option" key={index} onClick={(): void => handleSelectCity(item)}>
                      {`${item?.name} / ${item?.state}`}
                    </S.CityOption>
                  ))}
                </div>
              )}
            </S.FieldWrapper>

            <S.FieldWrapper size="small">
              <label className="required">{`${getTranslation('address.state')}`}</label>
              <input
                disabled
                maxLength={2}
                value={state}
                data-test="state-input"
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  setState(removeSpecialCharacters(event.target.value))
                }
              />
            </S.FieldWrapper>
          </>
        )}
      </S.FormWrapper>

      <S.Footer>
        <Button
          isGhost={false}
          isLoading={isLoading}
          data-test="confirm-address"
          isGoomerColor={isGoomerColor}
          onClick={(): void => handleClickSaveButton()}
          disabled={isLoading || !hasAllRequiredFieldsFilled || !isCitySelected}
        >
          {getTranslation('address.saveAddress')}
        </Button>
      </S.Footer>
    </S.Content>
  );
};

export default FormFields;
