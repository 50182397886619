import { useState, useMemo, useEffect } from 'react';

import { validateName } from '~/utils';
import { useTranslator } from '~/hooks';
import InputName from '~/components/Input/InputName';
import { OptionSpacing } from '~/pages/localOrder/styles';
import OptionHeader from '~/components/Option/OptionHeader';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';

import * as S from './styles';

interface OrderNameProps {
  name: string;
  isNameValid: boolean;
  isRequired?: boolean;
  setName: (name: string) => void;
  setIsNameValid: (status: boolean) => void;
}

const OrderName: (props: OrderNameProps) => JSX.Element = ({
  name,
  setName,
  isNameValid,
  setIsNameValid,
  isRequired = true
}) => {
  const [isEventSent, setIsEventSent] = useState<boolean>(false);

  const isDone = useMemo(() => isNameValid && validateName({ value: name }), [isNameValid, name]);

  const { getTranslation } = useTranslator();

  useEffect(() => {
    if (isDone && !isEventSent) {
      setIsEventSent(true);
      GoogleAnalytics.trackEvent(gaEvents.checkoutNameFilled);
    }
  }, [isEventSent, isDone]);

  const handleNameChange = (name: string, isValid: boolean): void => {
    setName(name);
    setIsNameValid(isValid);
  };

  return (
    <S.Container>
      <OptionHeader
        isForm
        isDone={isDone}
        subtitle={false}
        isRequired={isRequired}
        title={getTranslation('general.firstNameAndLastName')}
      />

      <OptionSpacing>
        <InputName
          required={false}
          defaultValue={name}
          data-test="input-name"
          placeholder={getTranslation('user.inputNamePlaceholder')}
          onChange={(value: string, isValid: boolean): void => handleNameChange(value, isValid)}
        />
      </OptionSpacing>
    </S.Container>
  );
};

export default OrderName;
