import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';
import { EstablishmentStatusEnum, PrettyEstablishmentStatusEnum } from '~/interfaces/enums';

export interface UseEstablishmentStatusReturn {
  isAbleToOrder: boolean;
  status: EstablishmentStatusEnum;
  prettyStatus: PrettyEstablishmentStatusEnum;
}

export interface UseEstablishmentStatusProps {
  isQrCode?: boolean;
}

export default function useEstablishmentStatus({ isQrCode = false }): UseEstablishmentStatusReturn {
  const { hasSchedulingList } = useSelector((state: IApplicationState) => state.scheduling);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { mode, hash } = useSelector((state: IApplicationState) => state.localOrders);

  const isLocalOrder = useMemo(() => (!!mode && !!hash) || isQrCode, [mode, hash, isQrCode]);

  const establishment = useSelector((state: IApplicationState) => state.establishment);

  const isEstablishmentOpen = establishment?.open ? establishment.open?.isOpen : false;

  const isTemporarilyClosed = settings?.mm_temporarily_closed;
  const isSchedulingEnabled = settings?.mm_order_scheduling_enabled;
  const isOnlyScheduling = settings?.mm_order_scheduling_only;
  const isGoomerGoManuallyClose = settings?.mm_store_closed;
  const isInStoreManuallyClose = settings?.mm_in_store_store_closed;

  const currentStatus = useMemo(() => {
    if (isTemporarilyClosed) return EstablishmentStatusEnum.closed;
    if (!isLocalOrder && isGoomerGoManuallyClose) return EstablishmentStatusEnum.closed;
    if (isLocalOrder && isInStoreManuallyClose) return EstablishmentStatusEnum.closed;

    if (!isSchedulingEnabled || isLocalOrder)
      return isEstablishmentOpen ? EstablishmentStatusEnum.open : EstablishmentStatusEnum.closed;

    if (isEstablishmentOpen && !isOnlyScheduling) return EstablishmentStatusEnum.open;

    return hasSchedulingList ? EstablishmentStatusEnum.scheduling : EstablishmentStatusEnum.closed;
  }, [
    isTemporarilyClosed,
    isLocalOrder,
    isGoomerGoManuallyClose,
    isInStoreManuallyClose,
    isSchedulingEnabled,
    isEstablishmentOpen,
    isOnlyScheduling,
    hasSchedulingList
  ]);

  const statusText = useMemo(() => {
    switch (currentStatus) {
      case EstablishmentStatusEnum.open:
        return PrettyEstablishmentStatusEnum.open;

      case EstablishmentStatusEnum.closed:
        return PrettyEstablishmentStatusEnum.closed;

      case EstablishmentStatusEnum.scheduling:
        return PrettyEstablishmentStatusEnum.scheduling;

      default:
        return PrettyEstablishmentStatusEnum.closed;
    }
  }, [currentStatus]);

  const isAbleToOrder = useMemo(() => {
    if (isTemporarilyClosed) return false;
    if (!isLocalOrder && isGoomerGoManuallyClose) return false;
    if (isLocalOrder && isInStoreManuallyClose) return false;

    if (!isSchedulingEnabled || isLocalOrder) return isEstablishmentOpen;

    if (isEstablishmentOpen && !isOnlyScheduling) return true;

    return hasSchedulingList;
  }, [
    isLocalOrder,
    isOnlyScheduling,
    hasSchedulingList,
    isSchedulingEnabled,
    isEstablishmentOpen,
    isTemporarilyClosed,
    isInStoreManuallyClose,
    isGoomerGoManuallyClose
  ]);

  return { isAbleToOrder, status: currentStatus, prettyStatus: statusText };
}
