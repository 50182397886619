import { Reducer } from 'redux';

import { ISchedulingState, SchedulingTypes } from './types';

const initialState: ISchedulingState = {
  selectedList: [],
  deliveryList: [],
  takeawayList: [],
  hasSchedulingList: false
};

const ScrollReducer: Reducer<ISchedulingState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SchedulingTypes.ADD_SELECTED_LIST:
      return {
        ...state,
        selectedList: payload
      };

    case SchedulingTypes.ADD_DELIVERY_LIST:
      return {
        ...state,
        deliveryList: payload
      };

    case SchedulingTypes.ADD_TAKEAWAY_LIST:
      return {
        ...state,
        takeawayList: payload
      };

    case SchedulingTypes.SET_HAS_SCHEDULING_LIST:
      return {
        ...state,
        hasSchedulingList: payload
      };

    default:
      return state;
  }
};

export default ScrollReducer;
