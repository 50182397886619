import React, { useCallback, useContext, useState } from 'react';

import { useSelector } from 'react-redux';
import { GoPencil } from 'react-icons/go';
import { FiTrash2 } from 'react-icons/fi';
import { RiMapPinLine } from 'react-icons/ri';
import LoaderSpinner from 'react-loader-spinner';
import { ActionAnimations, ISwipeActionProps, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';

import { mountAddress } from '~/utils';
import { Option, Button } from '~/components';
import { Address } from '~/interfaces/general';
import { useAddress, useTranslator } from '~/hooks';
import { NoAddressFound, LoaderIcon } from '~/assets';
import { IApplicationState } from '~/redux-tools/store';
import { AddressPageModeEnum } from '~/interfaces/enums';
import SwipeActionItems from '~/components/SwipeActionItems';

import { AddressContext } from '../..';

import * as S from './styles';

const AddressList = (): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const isUserDataLoading = useSelector((state: IApplicationState) => state.user.loading);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deletingAddressId, setDeletingAddressId] = useState<string | undefined>();

  const { getTranslation } = useTranslator();
  const { addressList, handleDelete, handleSelectAddressOnCart } = useAddress();
  const { onClose, isGoomerColor, closeButtonText, setSelectedView, selectedAddress, setSelectedAddress } =
    useContext(AddressContext);

  const swipeLeftOptions: (address?: Address) => ISwipeActionProps = useCallback(
    (address) => {
      const isDeletingThisAddress = deletingAddressId === address?.id;

      return {
        actionAnimation: ActionAnimations.REMOVE,
        action: (): void => {
          if (!address || isUserDataLoading) return;

          handleDelete(address);
          setDeletingAddressId(address.id);
        },
        content: (
          <SwipeActionItems
            side="right"
            color={theme.colors.error}
            label={isDeletingThisAddress ? getTranslation('general.removing') : getTranslation('general.remove')}
            icon={
              isDeletingThisAddress ? (
                <LoaderIcon width={32} height={32} aria-label={getTranslation('general.loadingImage')} />
              ) : (
                <FiTrash2 />
              )
            }
          />
        )
      };
    },
    [deletingAddressId, getTranslation, handleDelete, isUserDataLoading, theme.colors.error]
  );

  const swipeRightOptions: (address?: Address) => ISwipeActionProps = useCallback(
    (address) => {
      return {
        content: (
          <SwipeActionItems
            side="left"
            icon={<GoPencil />}
            color={theme.colors.success}
            label={getTranslation('general.edit')}
          />
        ),
        action: (): void => {
          setSelectedAddress(address);

          if (address && !address.cep) {
            setSelectedView(AddressPageModeEnum.noCepForm);
            return;
          }

          setSelectedView(AddressPageModeEnum.form);
        }
      };
    },
    [getTranslation, setSelectedAddress, setSelectedView, theme.colors.success]
  );

  const renderAddressOption: (address: Address, index: number) => JSX.Element = useCallback(
    (address, index = 0) => {
      const isSelectedAddress: boolean = selectedAddress?.id === address?.id;

      return (
        <S.ItemWrapper>
          <S.RadioContainer onClick={(): void => setSelectedAddress(address)}>
            <div className="icon">
              <RiMapPinLine size={20} color={theme.colors.mediumGray} />
            </div>

            <S.AddressInformation>
              {address && (
                <div className="content">
                  <strong>{`${address.street}, ${address.number}`}</strong>

                  <small>
                    {mountAddress({
                      address,
                      settings,
                      summary: true,
                      withCep: true,
                      withStreet: false
                    })}
                  </small>
                </div>
              )}
            </S.AddressInformation>

            <Option
              title=""
              price=""
              id={index}
              isAvailable
              type="radio"
              optionValue=""
              className="option"
              groupName="address"
              isGoomerColor={isGoomerColor}
              radioCheck={isSelectedAddress ? [{ id: index }] : undefined}
            />
          </S.RadioContainer>
        </S.ItemWrapper>
      );
    },
    [isGoomerColor, selectedAddress?.id, setSelectedAddress, settings, theme.colors.mediumGray]
  );

  const renderSwipeableOption: (address: Address, index?: number) => JSX.Element = useCallback(
    (address, index = 0) => {
      return (
        <SwipeableListItem
          key={address?.id || 0}
          swipeLeft={swipeLeftOptions(address)}
          swipeRight={swipeRightOptions(address)}
        >
          {renderAddressOption(address, index)}
        </SwipeableListItem>
      );
    },
    [renderAddressOption, swipeLeftOptions, swipeRightOptions]
  );

  const handleOnConfirm = useCallback(() => {
    const address: Address | undefined = addressList?.find(
      (address) => String(address.id) === String(selectedAddress?.id)
    );

    if (address) {
      const afterAction = (success: boolean): void => {
        setIsLoading(false);
        setSelectedAddress(address);
        setSelectedView(AddressPageModeEnum.list);

        if (success) {
          onClose?.();
        }
      };

      setIsLoading(true);

      handleSelectAddressOnCart(address, afterAction);
    }
  }, [addressList, handleSelectAddressOnCart, onClose, selectedAddress, setSelectedAddress, setSelectedView]);

  return (
    <S.Content $isListEmpty={addressList?.length === 0}>
      {addressList?.length === 0 && (
        <S.NoAddress>
          {isUserDataLoading ? (
            <S.LoaderWrapper isGoomerColor={isGoomerColor}>
              <LoaderSpinner type="Oval" height={40} />
            </S.LoaderWrapper>
          ) : (
            <>
              <div className="image">
                <NoAddressFound />
              </div>

              <h1>{getTranslation('address.noAddressAdded')}</h1>

              <p>{getTranslation('address.addAddressMessage')}</p>
            </>
          )}
        </S.NoAddress>
      )}

      {addressList?.length !== 0 && (
        <>
          <p>{getTranslation('address.editAndRemoveInstructions')}</p>

          <S.SwipeableCustomList>
            {addressList.map((address, index) => renderSwipeableOption(address, index + 1))}
          </S.SwipeableCustomList>
        </>
      )}

      <S.Footer>
        <Button
          data-test="btn-new-address"
          isGoomerColor={isGoomerColor}
          isGhost={addressList?.length > 0}
          onClick={(): void => {
            setSelectedAddress(undefined);
            setSelectedView(AddressPageModeEnum.form);
          }}
        >
          {addressList?.length === 0 ? getTranslation('address.addAddress') : getTranslation('address.addNewAddress')}
        </Button>

        {selectedAddress ? (
          <Button
            isGhost={false}
            disabled={isLoading}
            isLoading={isLoading}
            isGoomerColor={isGoomerColor}
            data-test="btn-confirm-address"
            onClick={(): void => handleOnConfirm()}
          >
            {isLoading ? getTranslation('address.confirmingAddress') : getTranslation('general.confirm')}
          </Button>
        ) : (
          <Button isGoomerColor={isGoomerColor} onClick={(): void => onClose?.()}>
            {closeButtonText || getTranslation('general.back')}
          </Button>
        )}
      </S.Footer>
    </S.Content>
  );
};

export default AddressList;
