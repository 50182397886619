import styled, { css } from 'styled-components';

export const Container = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-wrap: nowrap;
`;

export const IconWrapper = styled.span`
  ${({ theme }) => css`
    max-width: 0.875rem;

    display: flex;
    align-items: center;

    ${!theme.isDarkMode &&
    css`
      opacity: 0.7;
    `}

    svg {
      width: 1rem;
      height: 1rem;
      min-width: 1rem;
    }
  `}
`;
