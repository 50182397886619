import React, { useState, useMemo, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { RiBankCardLine } from 'react-icons/ri';
import ContentLoader from 'react-content-loader';
import { cnpjMask, cpfMask } from '@goomerdev/goomer-toolbox/src/utils';

import CardEntry from '~/pages/cardEntry';
import { ICard } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { DeleteCardModal } from '~/components/Payment/PaymentModal/components';
import { usePaymentMethods, useTranslator, useHandlePaymentTexts, useFormatCreditCard } from '~/hooks';

import * as S from './styles';

const CARD_LIMIT = 5;

const Cards = (): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const [showCardEntry, setShowCardEntry] = useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [cardToDelete, setCardToDelete] = useState<ICard | undefined>();

  const { getTranslation } = useTranslator();
  const { formatCreditCard } = useFormatCreditCard();
  const { handlePaymentTexts } = useHandlePaymentTexts();
  const { isTunaLoading, mercadoPagoCheckout, cardsReversed: cards, tunaCreditCardCheckout } = usePaymentMethods();

  const getDocumentText = useCallback(
    (document: string) => {
      if (!document) {
        return '';
      }

      if (document.length > 11) {
        return `${getTranslation('general.cnpj')}: ${cnpjMask(document)}`;
      }

      return `${getTranslation('general.cpf')}: ${cpfMask(document)}`;
    },
    [getTranslation]
  );

  const openCardEntry = (): void => {
    setShowCardEntry(true);
    GoogleAnalytics.trackEvent(gaEvents.addNewCreditCardLoggedArea);
  };

  const renderSkeleton = useMemo(
    () => (
      <ContentLoader
        speed={2}
        height={88}
        radius={10}
        width="100%"
        title="loader"
        backgroundColor={theme.colors.gray200}
        foregroundColor={theme.colors.gray300}
      >
        <rect x="16" y="16" rx="3" ry="3" width={'calc(100% - 32px)'} height="56" />
      </ContentLoader>
    ),
    [theme.colors.gray200, theme.colors.gray300]
  );

  const hasEstablishmentCardMethodAvailable = useMemo(
    () => mercadoPagoCheckout || tunaCreditCardCheckout,
    [mercadoPagoCheckout, tunaCreditCardCheckout]
  );

  const canSaveCards = useMemo(() => cards && cards.length < CARD_LIMIT, [cards]);

  const renderCardList = useMemo(() => {
    if (isTunaLoading) {
      return renderSkeleton;
    }

    if (!hasEstablishmentCardMethodAvailable) {
      return (
        <S.Item>
          <h3>{getTranslation('payment.methods.noPaymentCreditCard')}</h3>
        </S.Item>
      );
    }

    if (!cards || cards.length === 0) {
      return (
        <S.Item>
          <h3>{getTranslation('payment.methods.noCreditCardRegistered')}</h3>
        </S.Item>
      );
    }

    return (
      <>
        {cards?.map((card, index) => (
          <S.Item key={index}>
            <div className="icon">
              <RiBankCardLine size={16} />
            </div>

            <div className="info">
              <p className="type">
                {getTranslation('payment.cardType', { type: handlePaymentTexts(card.type).toLowerCase() })}
              </p>

              <p className="card sentry-mask">
                {formatCreditCard({
                  card: card.number,
                  flag: card.mpMethodId,
                  isCreditCardNumberHidden: false
                })}
              </p>

              <p className="document sentry-mask">{getDocumentText(card.document)}</p>
            </div>

            <div className="delete">
              <p
                className="action"
                onClick={(): void => {
                  setShowDeleteCardModal(true);
                  setCardToDelete(card);
                }}
              >
                {getTranslation('general.remove')}
              </p>
            </div>
          </S.Item>
        ))}
      </>
    );
  }, [
    cards,
    isTunaLoading,
    getTranslation,
    renderSkeleton,
    getDocumentText,
    formatCreditCard,
    handlePaymentTexts,
    hasEstablishmentCardMethodAvailable
  ]);

  return (
    <S.Container>
      <S.Content>
        <S.CardList>{renderCardList}</S.CardList>

        {!isTunaLoading && hasEstablishmentCardMethodAvailable && canSaveCards && (
          <S.AddNewCard data-test="btn-add-card" onClick={openCardEntry}>
            <p className="action">{getTranslation('payment.addCard')}</p>
          </S.AddNewCard>
        )}
      </S.Content>

      {showCardEntry && (
        <CardEntry
          isNewCard={true}
          isTunaPaymentEnabled={tunaCreditCardCheckout || false}
          isShow={showCardEntry}
          onClose={(): void => {
            setShowCardEntry(false);
          }}
        />
      )}

      {cardToDelete && (
        <DeleteCardModal
          selectedCard={cardToDelete}
          isShow={showDeleteCardModal}
          handleClose={(): void => setShowDeleteCardModal(false)}
        />
      )}
    </S.Container>
  );
};

export default Cards;
