import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { PaymentMethod } from '~/interfaces/general';
import { DeliveryWayEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';

export default function useDeliveryPartner() {
  const { origin } = useSelector((state: IApplicationState) => state.coupons);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { address: cartAdress } = useSelector((state: IApplicationState) => state.cart);

  const isEntregaFacilEnabled: boolean = useMemo(
    () => settings?.mm_entrega_facil_ifood_enabled || false,
    [settings?.mm_entrega_facil_ifood_enabled]
  );

  const hasAvailablePaymentMethod: (paymentOption?: PaymentMethod) => boolean = useCallback((paymentOption) => {
    if (paymentOption?.type === 'paid_on_app') {
      return true;
    }

    if (paymentOption?.type === 'paid_on_delivery') {
      return (
        paymentOption?.category === PaymentCategoryEnum.credit || paymentOption?.category === PaymentCategoryEnum.debit
      );
    }

    return false;
  }, []);

  const shouldAskLatitudeAndLongitude: (props: { paymentOption?: PaymentMethod }) => boolean = useCallback(
    ({ paymentOption }) => {
      const hasCep = !!cartAdress?.cep;
      const isDeliveryWay: boolean = origin === DeliveryWayEnum.delivery;
      const hasLatAndLng: boolean = !!cartAdress?.latitude && !!cartAdress?.longitude;

      const shouldAsk: boolean =
        !!isEntregaFacilEnabled &&
        !!isDeliveryWay &&
        !!hasCep &&
        !hasLatAndLng &&
        !!hasAvailablePaymentMethod(paymentOption);

      return shouldAsk;
    },
    [
      origin,
      cartAdress?.cep,
      cartAdress?.latitude,
      cartAdress?.longitude,
      isEntregaFacilEnabled,
      hasAvailablePaymentMethod
    ]
  );

  return { shouldAskLatitudeAndLongitude };
}
