import { CookieNameEnum } from '~/interfaces/enums';
import { api } from '~/services/api';

import Cookies from '../cookies';

const NEXT_PUBLIC_COOKIES_DOMAIN = process.env.NEXT_PUBLIC_COOKIES_DOMAIN || '';

interface SetAuthenticationTokenProps {
  token: string | undefined;
}

const setHeaderAndCookieAuthToken = ({ token }: SetAuthenticationTokenProps) => {
  const isLocalhost: boolean = typeof window !== 'undefined' && window.location.hostname.includes('localhost');
  const domain: string = isLocalhost ? '.goomer.localhost' : NEXT_PUBLIC_COOKIES_DOMAIN;

  const setCookie: (name: CookieNameEnum, value: string) => void = (name, value) => {
    Cookies.setCookie({
      expirationDays: 90,
      name,
      value,
      domain
    });
  };

  if (token === undefined || token === 'undefined' || token === '') {
    api.defaults.headers.Authorization = undefined;

    Cookies.deleteCookie({ name: CookieNameEnum.userToken, domain });

    return;
  }

  api.defaults.headers.Authorization = `Bearer ${token}`;

  setCookie(CookieNameEnum.userToken, token);
  setCookie(CookieNameEnum.tokenGlobalTag, 'true');
};

export default setHeaderAndCookieAuthToken;
