import { Reducer } from 'redux';

import { IScrollState, ScrollActionTypes } from './types';

const initialState = {
  data: {
    x: 0,
    y: 0
  }
};

const ScrollReducer: Reducer<IScrollState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ScrollActionTypes.SET_SCROLL_POS:
      return {
        ...state,
        data: payload
      };

    default:
      return state;
  }
};

export default ScrollReducer;
