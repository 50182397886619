import currency from 'currency.js';
import { InStoreEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { PaymentReceipt, PaymentReceiptResponse } from '~/interfaces/general';

export interface FormatPaymentProps {
  total: number;
  code: string;
  mode: string;
  remaining: number;
  tableCode?: string;
  payment: PaymentReceiptResponse;
}

const formatPayment = ({ payment, total, mode, code, remaining, tableCode }: FormatPaymentProps): PaymentReceipt => ({
  total,
  remaining,
  mode: payment.method,
  date: payment.created_at,
  partnerUniqueID: payment.partner_unique_id,
  tax: currency(payment.tax_value_cents, { fromCents: true }).value,
  paid: currency(payment.value_cents, { fromCents: true }).value,
  tab: mode === InStoreEnum.guestCheck ? String(code) : '',
  table: mode === InStoreEnum.table ? String(code) : tableCode
});

export default formatPayment;
