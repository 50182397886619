import { all, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import { sendFeeback } from '~/services';

import { ReviewActionTypes } from './types';
import { SendReviewProps } from './actions';
import { FeedbackPayload } from '~/services/sendFeeback';

export function* sendReviewSaga({ payload }: AnyAction): Generator<object, void, any> {
  const { storeId, review, callback } = payload as SendReviewProps;

  try {
    if (!storeId) {
      throw new Error();
    }

    const formatPayload = (): FeedbackPayload => {
      const { name, email, phone, table, observation, rates } = review;

      return {
        name,
        email,
        note: observation,
        table_number: table,
        phone_number: phone,
        rates: rates.reduce((ratesPayload, rate) => Object.assign(ratesPayload, { [rate.id]: rate.rate }), {})
      };
    };

    yield sendFeeback(storeId, formatPayload());
    callback(true);
  } catch (error) {
    callback(false);
  }
}

export default all([takeLatest(ReviewActionTypes.SEND_REVIEW, sendReviewSaga)]);
