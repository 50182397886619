import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';

import { useTranslator } from '~/hooks';
import { validateName, sanitizeOnlyLettersField } from '~/utils';

import { Input, ErrorMessage } from '../Input/styles';

interface InputNameProps {
  required: boolean;
  placeholder?: string;
  defaultValue: string;
  errorMessage?: string;
  isGoomerColor?: boolean;
  allowJustFirstName?: boolean;
  onChange: (value: string, isValid: boolean) => void;
}

const InputName: React.FC<InputNameProps> = ({
  onChange,
  required = false,
  placeholder = '',
  defaultValue = '',
  errorMessage = '',
  isGoomerColor = false,
  allowJustFirstName = false,
  ...rest
}) => {
  const firstUpdate = useRef(true);
  const { getTranslation } = useTranslator();

  const [name, setName] = useState<string>(defaultValue);
  const [withError, setWithError] = useState<string>('');

  const shouldDisplayErrorMessage: boolean = useMemo(
    () => !!withError && (required || name.length > 0),
    [name.length, required, withError]
  );

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback(
    (event) => {
      const { value } = event.target;

      const pureValue: string = sanitizeOnlyLettersField(value.replace(/\d/g, '').replace(/\s\s+/g, ' ')).trimStart();

      const isValid: boolean = validateName({
        value: pureValue,
        allowJustFirstName
      });

      if (isValid) {
        setWithError('');
      }

      setName(pureValue);
      onChange(pureValue, isValid);
    },
    [allowJustFirstName, onChange]
  );

  const handleBlur: () => void = useCallback(
    () => setWithError(validateName({ value: name, allowJustFirstName }) ? '' : 'error'),
    [name, allowJustFirstName]
  );

  useEffect(() => {
    if (firstUpdate.current && defaultValue) {
      handleBlur();
    }

    firstUpdate.current = false;
  }, [handleBlur, defaultValue]);

  return (
    <>
      <Input
        max={255}
        type="text"
        value={name}
        maxLength={255}
        id="input-name"
        inputMode="text"
        autoComplete="off"
        required={required}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        error={required && withError}
        isGoomerColor={isGoomerColor}
        {...rest}
      />
      {shouldDisplayErrorMessage && (
        <ErrorMessage>{errorMessage || getTranslation('general.enterYourNameAndLastName')}</ErrorMessage>
      )}
    </>
  );
};

export default InputName;
