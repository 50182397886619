import { AxiosResponse } from 'axios';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { showToast } from '~/utils';
import { cancelWaitingPaymentOrder } from '~/services';
import sendCustomerEmail from '~/services/sendCustomerEmail';
import { ToastIdEnum, ToastTypeEnum } from '~/interfaces/enums';

import { IApplicationState } from '..';

import { PixPaymentActionTypes } from '.';
import {
  sendEmailError,
  sendEmailSuccess,
  sendEmailRequest,
  cancelPixOrderError,
  cancelPixOrderRequest
} from './actions';

type SendEmailRequest = ReturnType<typeof sendEmailRequest>;
type CancelOrderRequest = ReturnType<typeof cancelPixOrderRequest>;

export function* sendEmail({ payload }: SendEmailRequest) {
  const { email: stateEmail } = yield select((state: IApplicationState) => state.pixPayment);
  const { email: currentEmail, orderId } = payload;

  const isTheSameEmail = !!stateEmail && stateEmail === currentEmail;

  if (isTheSameEmail || !orderId) {
    return;
  }

  try {
    const response: AxiosResponse = yield sendCustomerEmail({ email: currentEmail, orderId });

    if (response.status === 200) {
      showToast({
        toastId: ToastIdEnum.email,
        type: ToastTypeEnum.success,
        text: 'E-mail salvo com sucesso!'
      });
    }

    yield put(
      sendEmailSuccess({
        sendEmail: true,
        email: currentEmail
      })
    );
  } catch (error) {
    yield put(sendEmailError((error as Error)?.message));
  }
}

export function* cancelOrder({ payload }: CancelOrderRequest) {
  const { orderId, paymentDate, callback } = payload;

  if (!orderId || !paymentDate) {
    return;
  }

  try {
    yield cancelWaitingPaymentOrder({ orderId, paymentDate });

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(cancelPixOrderError((error as Error)?.message));
  }
}

export default all([
  takeLatest(PixPaymentActionTypes.SEND_EMAIL_REQUEST, sendEmail),
  takeLatest(PixPaymentActionTypes.CANCEL_PIX_ORDER_REQUEST, cancelOrder)
]);
