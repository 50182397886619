import styled, { css } from 'styled-components';

export const Footer = styled.footer<{ showDynamicInfo?: boolean }>`
  ${({ theme, showDynamicInfo }) => css`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 110;

    width: 100%;
    box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);

    margin: 0 auto;
    max-width: 800px;
    padding: 0.85rem 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: ${theme.colors.backgroundColor};

    @media screen and (min-width: 720px) {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &.closed {
      height: auto;

      align-items: center;
      flex-direction: column;

      h2 {
        font-weight: bold;
        text-align: center;
      }

      > span {
        margin-top: 0.5rem;

        text-align: center;
        color: ${theme.colors.mediumGray};
      }

      button {
        width: 100%;
      }
    }

    ${showDynamicInfo &&
    css`
      border-radius: 1.25rem 1.25rem 0 0;
      padding: 1rem 1rem 0.85rem;

      flex-direction: column;
    `}
  `}
`;

export const AddButton = styled.button<{ isDisabled?: boolean; isFullColor?: boolean }>`
  border: none;
  min-height: 3rem;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;

  gap: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-around;

  cursor: pointer;
  background-color: ${({ theme }) => theme.brandColors.actionBackground};

  ${({ disabled, isDisabled }) =>
    (isDisabled || disabled) &&
    css`
      background-color: ${({ theme }) => theme.colors.gray300};

      span {
        color: ${({ theme }) => theme.colors.gray500};
      }
    `}
`;

export const AddButtonText = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.brandColors.actionText};
`;

export const Loader = styled.div`
  min-width: 3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    path {
      fill: ${({ theme }) => theme.colors.gray500};
    }
  }
`;
