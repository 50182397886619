import React, { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RiBankCard2Line, RiBankCardLine } from 'react-icons/ri';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import CardEntry from '~/pages/cardEntry';
import { PaymentChosen } from '~/components';
import { generateWhatsappLink } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import { PaymentMethod, ICard } from '~/interfaces/general';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { ListHeader, ListItem } from '~/components/Modal/Modal';
import { VRLogo, CheckoutMoneyIcon, CheckoutQrCodeIcon } from '~/assets';
import { PaymentTypeEnum, EditTypeEnum, PaymentFlagEnum } from '~/interfaces/enums';
import { useFormatCreditCard, useHandlePaymentTexts, usePaymentMethods, useTranslator } from '~/hooks';

import { DeleteCardModal } from '../';

import * as S from './styles';

interface MethodsListProps {
  isMyTab?: boolean;
  changePage: (selectedMethod: PaymentMethod) => void;
}

const CARD_LIMIT = 4;

const MethodsList: React.FC<MethodsListProps> = ({ isMyTab, changePage }) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const localOrders = useSelector((state: IApplicationState) => state.localOrders.mode);

  const { getTranslation } = useTranslator();
  const { formatCreditCard } = useFormatCreditCard();
  const { handlePaymentTexts } = useHandlePaymentTexts();
  const {
    cash,
    cards,
    debit,
    credit,
    voucher,
    vrPagueLink,
    isPixEnabled,
    cardsReversed,
    mercadoPagoLink,
    existInAppPayment,
    mercadoPagoQRCode,
    isApplePayEnabled,
    isGooglePayEnabled,
    mercadoPagoCheckout,
    existPaymentOnDelivery,
    tunaCreditCardCheckout,
    handlePaymentTextCategory,
    isNupayCheckout: isNupayEnabled,
    integratedPix: isIntegratedPixEnabled
  } = usePaymentMethods();

  const [showCardEntry, setShowCardEntry] = useState(false);
  const [cardEdit, setCardEdit] = useState<ICard | undefined>(undefined);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isNewCard, setIsNewCard] = useState(false);

  const handleAddNewCard = (): void => {
    setIsNewCard(true);
    setCardEdit(undefined);
    setShowCardEntry(true);
    GoogleAnalytics.trackEvent(gaEvents.addNewCreditCardMethodsList);
  };

  const selectCard = useCallback(
    (card: ICard) => {
      GoogleAnalytics.trackEvent(gaEvents.selectedCreditCard);

      changePage({
        email: card.email,
        token: card.token,
        flag: card.mpMethodId,
        document: card.document,
        cardNumber: card.number,
        mpMethodId: card.mpMethodId,
        type: PaymentTypeEnum.onApp,
        category: card.flagDescription,
        cardDescription: formatCreditCard({
          card: card.number,
          flag: card.mpMethodId,
          isCreditCardNumberHidden: true
        })
      });
    },
    [changePage, formatCreditCard]
  );

  const sendMessage = useCallback(() => {
    const whatsappUrl = generateWhatsappLink({
      phone: settings?.mm_whatsapp_phone_number || '',
      message: getTranslation('payment.choosePaymentHelp')
    });

    window.open(whatsappUrl, '_blank');
  }, [getTranslation, settings?.mm_whatsapp_phone_number]);

  if (!existPaymentOnDelivery && !existInAppPayment) {
    return (
      <S.Container>
        <p className="text">{getTranslation('payment.cannotChoosePayment')}</p>

        <p className="desc">{getTranslation('general.contactStoreForHelp')}</p>

        <S.ChatButton onClick={(): void => sendMessage()}>
          <LazyLoadImage src="/assets/checkout/whatsapp.svg" width={16} height={12} />
          <p>{getTranslation('general.startChat')}</p>
        </S.ChatButton>
      </S.Container>
    );
  }

  const renderButtonToAddCard = (): JSX.Element => {
    if (!cards || cards?.length <= 0) {
      return (
        <ListItem
          dataTest="btn-add-card"
          onClick={handleAddNewCard}
          icon={<RiBankCardLine size={24} />}
          action={<span className="link-like">{getTranslation('payment.addCard')}</span>}
          description={getTranslation('payment.cardType', {
            type: handlePaymentTexts(PaymentCategoryEnum.credit).toLowerCase()
          })}
        />
      );
    }

    if (cards.length <= CARD_LIMIT) {
      return (
        <S.CustomButton data-test="btn-add-card" isSimple onClick={handleAddNewCard}>
          {getTranslation('payment.addNewCard')}
        </S.CustomButton>
      );
    }

    return <></>;
  };

  const renderCardList = (): JSX.Element => {
    if (!mercadoPagoCheckout && !tunaCreditCardCheckout) {
      return <></>;
    }

    return (
      <>
        {cardsReversed?.map((card, index) => (
          <PaymentChosen
            key={index}
            isDeleteAction
            isInsidePaymentWays
            isCashPayment={false}
            cardDocument={card.document}
            editType={EditTypeEnum.text}
            dataTest="btn-transparent-checkout"
            icon={<RiBankCardLine size={24} />}
            onClick={(): void => selectCard(card)}
            editAction={(): void => {
              setShowEditModal(true);
              setCardEdit(card);
            }}
            description={formatCreditCard({
              card: card.number,
              flag: card.mpMethodId,
              isCreditCardNumberHidden: false
            })}
            title={getTranslation('payment.cardType', {
              type: handlePaymentTexts(card.type).toLowerCase()
            })}
          />
        ))}

        {renderButtonToAddCard()}
      </>
    );
  };

  const renderIntegratedPixOption = (): JSX.Element => (
    <ListItem
      dataTest="btn-link-pix-tuna"
      category={getTranslation('payment.methods.pix')}
      action={<img src="/assets/checkout/angle-right.svg" width={6} height={10} />}
      icon={
        <S.ImageWrapper>
          <img src="/assets/checkout/pix.svg" />
        </S.ImageWrapper>
      }
      onClick={(): void => {
        GoogleAnalytics.trackEvent(gaEvents.selectedIntegratedPix);
        changePage({
          type: PaymentTypeEnum.onApp,
          flag: getTranslation('payment.methods.pix'),
          category: PaymentCategoryEnum.integratedPix
        });
      }}
    />
  );

  const renderApplePayOption = (): JSX.Element => (
    <ListItem
      shouldDisplayNewTag
      category={getTranslation('payment.methods.applePay')}
      icon={<img src="/assets/checkout/apple-pay-flag.svg" />}
      action={<img src="/assets/checkout/angle-right.svg" width={6} height={10} />}
      onClick={(): void => {
        GoogleAnalytics.trackEvent(gaEvents.selectedApplePay);

        changePage({
          type: PaymentTypeEnum.onApp,
          flag: PaymentFlagEnum.ApplePay,
          category: PaymentCategoryEnum.applePay
        });
      }}
    />
  );

  const renderGooglePayOption = (): JSX.Element => (
    <ListItem
      shouldDisplayNewTag
      category={getTranslation('payment.methods.googlePay')}
      icon={<img src="/assets/checkout/google-pay-flag.svg" />}
      action={<img src="/assets/checkout/angle-right.svg" width={6} height={10} />}
      onClick={(): void => {
        GoogleAnalytics.trackEvent(gaEvents.selectedGooglePay);

        changePage({
          type: PaymentTypeEnum.onApp,
          flag: PaymentFlagEnum.GooglePay,
          category: PaymentCategoryEnum.googlePay as PaymentCategoryEnum
        });
      }}
    />
  );

  const renderCardEditModal = (): JSX.Element => (
    <>
      {(mercadoPagoCheckout || tunaCreditCardCheckout) && showCardEntry && (
        <CardEntry
          isMyTab={isMyTab}
          isNewCard={isNewCard}
          initialData={cardEdit}
          isShow={showCardEntry}
          isTunaPaymentEnabled={tunaCreditCardCheckout || false}
          onClose={(newCard?: ICard): void => {
            setIsNewCard(false);
            setShowCardEntry(false);

            if (newCard) {
              selectCard(newCard);
            }
          }}
        />
      )}

      {cardEdit && (
        <DeleteCardModal
          isShow={showEditModal}
          selectedCard={cardEdit}
          handleClose={(): void => setShowEditModal(false)}
        />
      )}
    </>
  );

  if (isMyTab) {
    return (
      <S.Container>
        <div className="group">
          <ListHeader title={getTranslation('payment.methods.onApp')} />

          {isGooglePayEnabled && renderGooglePayOption()}

          {isApplePayEnabled && renderApplePayOption()}

          {renderCardList()}

          {isIntegratedPixEnabled && renderIntegratedPixOption()}

          {renderCardEditModal()}
        </div>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <div className="group">
        {existInAppPayment && <ListHeader title={getTranslation('payment.methods.onApp')} />}

        {isGooglePayEnabled && renderGooglePayOption()}

        {isApplePayEnabled && renderApplePayOption()}

        {isNupayEnabled && (
          <ListItem
            shouldDisplayNewTag
            dataTest="btn-nubank"
            category={getTranslation('payment.methods.nupay')}
            action={<img src="/assets/checkout/angle-right.svg" width={6} height={10} />}
            icon={
              <S.ImageWrapper>
                <img src="/assets/checkout/nupay.svg" />
              </S.ImageWrapper>
            }
            onClick={(): void =>
              changePage({
                type: PaymentTypeEnum.onApp,
                category: PaymentCategoryEnum.nupay,
                flag: getTranslation('payment.methods.nupay')
              })
            }
          />
        )}

        {isIntegratedPixEnabled && renderIntegratedPixOption()}

        {renderCardList()}

        {mercadoPagoLink && !localOrders && (
          <ListItem
            dataTest="btn-link-mercadopago"
            description={<LazyLoadImage src="/assets/checkout/mp-logo.svg" width={85} height={23} />}
            action={<LazyLoadImage src="/assets/checkout/payment-ways.svg" width={120} height={15} />}
            onClick={(): void =>
              changePage({
                type: PaymentTypeEnum.onApp,
                category: PaymentCategoryEnum.mPagoLink,
                flag: getTranslation(PaymentFlagEnum.OnlinePaymentLink)
              })
            }
          />
        )}

        {vrPagueLink && !localOrders && (
          <ListItem
            dataTest="btn-link-vrpague"
            icon={<VRLogo width="24" height="24" />}
            description={getTranslation('payment.methods.vrPague')}
            action={<LazyLoadImage src="/assets/checkout/angle-right.svg" width={6} height={10} />}
            onClick={(): void =>
              changePage({
                type: PaymentTypeEnum.onApp,
                category: PaymentCategoryEnum.vrPagueLink,
                flag: getTranslation('payment.methods.vrPagueLink')
              })
            }
          />
        )}

        {isPixEnabled && (
          <ListItem
            dataTest="btn-link-pix"
            category={getTranslation('payment.methods.pix')}
            action={<img src="/assets/checkout/angle-right.svg" width={6} height={10} />}
            icon={
              <S.ImageWrapper>
                <img src="/assets/checkout/pix.svg" />
              </S.ImageWrapper>
            }
            onClick={(): void =>
              changePage({
                type: PaymentTypeEnum.onApp,
                category: PaymentCategoryEnum.pix,
                flag: getTranslation('payment.methods.pix')
              })
            }
          />
        )}
      </div>

      <div className="group">
        {existPaymentOnDelivery && <ListHeader title={handlePaymentTexts(handlePaymentTextCategory)} />}

        {cash && (
          <ListItem
            dataTest="btn-cash"
            icon={<CheckoutMoneyIcon />}
            category={getTranslation('payment.methods.cash')}
            action={<LazyLoadImage src="/assets/checkout/angle-right.svg" width={6} height={10} />}
            onClick={(): void => changePage({ type: handlePaymentTextCategory, category: PaymentCategoryEnum.cash })}
          />
        )}

        {mercadoPagoQRCode && (
          <ListItem
            dataTest="btn-qr-code"
            icon={<CheckoutQrCodeIcon />}
            description={getTranslation('payment.methods.mPagoQr')}
            action={<LazyLoadImage src="/assets/checkout/angle-right.svg" width={6} height={10} />}
            onClick={(): void =>
              changePage({
                type: handlePaymentTextCategory,
                category: PaymentCategoryEnum.mPagoQr,
                flag: getTranslation('general.qrCode')
              })
            }
          />
        )}

        {credit && (
          <ListItem
            dataTest="btn-credit"
            icon={<RiBankCardLine size={24} />}
            description={getTranslation('payment.methods.credit')}
            action={<LazyLoadImage src="/assets/checkout/angle-right.svg" width={6} height={10} />}
            onClick={(): void => changePage({ type: handlePaymentTextCategory, category: PaymentCategoryEnum.credit })}
          />
        )}

        {debit && (
          <ListItem
            dataTest="btn-debit"
            icon={<RiBankCardLine size={24} />}
            description={getTranslation('payment.methods.debit')}
            action={<LazyLoadImage src="/assets/checkout/angle-right.svg" width={6} height={10} />}
            onClick={(): void => changePage({ type: handlePaymentTextCategory, category: PaymentCategoryEnum.debit })}
          />
        )}

        {voucher && (
          <ListItem
            dataTest="btn-voucher"
            icon={<RiBankCard2Line size={24} />}
            description={getTranslation('payment.methods.voucher')}
            action={<LazyLoadImage src="/assets/checkout/angle-right.svg" width={6} height={10} />}
            onClick={(): void => changePage({ type: handlePaymentTextCategory, category: PaymentCategoryEnum.voucher })}
          />
        )}
      </div>

      {renderCardEditModal()}
    </S.Container>
  );
};

export default MethodsList;
