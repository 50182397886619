import React, { useState, useCallback } from 'react';

import { useTranslator } from '~/hooks';

import { Container } from './styles';

interface CommentProps {
  comment?: string;
  onChange: (comment: string) => void;
}

const Comment: React.FC<CommentProps> = ({ comment, onChange }) => {
  const [characters, setCharacters] = useState(0);

  const { getTranslation } = useTranslator();

  const handleTextAreaCharacters = useCallback(
    (phrase: string) => {
      onChange(phrase);

      setCharacters(phrase.length);
    },
    [onChange]
  );

  return (
    <Container>
      <textarea
        maxLength={180}
        defaultValue={comment}
        placeholder={getTranslation('general.observationPlaceholder')}
        onChange={(event): void => handleTextAreaCharacters(event.target.value)}
      />

      <p className="characters-count">{characters}/180</p>

      <span>{getTranslation('general.observationMessage')}</span>
    </Container>
  );
};

export default Comment;
