import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 1px solid ${theme.colors.gray300};

    gap: 1rem;
    display: flex;
    align-items: center;

    background-color: ${theme.colors.backgroundColor};
  `}
`;

export const Logo = styled.div`
  ${({ theme }) => css`
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    min-height: 3rem;
    border-radius: 0.75rem;

    background: ${theme.brandColors.primary};

    > img {
      width: 100%;
      height: 100%;
      border-radius: 0.75rem;

      object-fit: contain;
    }
  `}
`;

export const OrderInfoContent = styled.div`
  ${({ theme }) => css`
    width: 100%;

    gap: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .line {
      gap: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    h3 {
      font-size: 1rem;
      font-weight: 700;
      line-height: 150%;
      color: ${theme.colors.textColor};
    }

    h2 {
      font-weight: 600;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.textColor};
    }

    span {
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1.5rem;
      color: ${theme.colors.gray500};
    }
  `}
`;
