import React from 'react';

import { useRouter } from 'next/router';
import { RiPencilLine } from 'react-icons/ri';

import { phoneMask } from '~/utils';
import { Button } from '~/components';
import { useTranslator } from '~/hooks';
import { QueryParam } from '~/interfaces/enums';

import * as S from './styles';

export interface UserInfoProps {
  name?: string;
  phone?: string;
  displayEdit?: boolean;
  displaySwitchAccountButton?: boolean;
  switchAccountButtonReturnPage?: string;
}

const UserInfo: (props: UserInfoProps) => JSX.Element = ({
  name,
  phone,
  displayEdit = false,
  switchAccountButtonReturnPage,
  displaySwitchAccountButton = false
}) => {
  const history = useRouter();

  const { getTranslation } = useTranslator();

  const handleEditProfile = (): void => {
    history.push(`/profile?${QueryParam.editProfile}=true`);
  };

  const handleSwitchAccountClick = (): void => {
    const previousPage = switchAccountButtonReturnPage || 'menu';

    history.push(`/auth?${QueryParam.previousPage}=${previousPage}`);
  };

  return (
    <S.Container>
      <div className="content">
        <div className="info">
          {name && <p className="name">{name}</p>}
          {phone && <p className="phone">{phoneMask(phone)}</p>}
        </div>

        {displaySwitchAccountButton && (
          <div className="switch-account">
            <Button isSimple onClick={handleSwitchAccountClick}>
              {getTranslation('user.change')}
            </Button>
          </div>
        )}

        {displayEdit && (
          <S.EditWrapper>
            <RiPencilLine size={24} onClick={handleEditProfile} />
          </S.EditWrapper>
        )}
      </div>
    </S.Container>
  );
};

export default UserInfo;
