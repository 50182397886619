import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 80px;

  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: auto;
    height: 130px;
    max-width: 240px;
  }

  > .title {
    width: 100%;
    margin-top: 12px;
    max-width: 290px;

    color: ${({ theme }) => theme.colors.textColor};
    font-size: 20px;
    font-weight: bold;
    line-height: 140%;
    text-align: center;
  }

  > .message {
    width: 100%;
    margin-top: 12px;
    max-width: 240px;

    color: #7f8f9f;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
  }
`;

export const Action = styled.button`
  width: 288px;
  height: 48px;
  margin-top: 20px;
  border: solid 1px ${({ theme }) => theme.brandColors.primary};

  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: none;

  > p {
    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.brandColors.primary};

    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 150%;
    text-align: center;
  }
`;
