import React, { useMemo, useRef } from 'react';

import { useSelector } from 'react-redux';
import { RiSmartphoneLine } from 'react-icons/ri';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { ButtonResume } from '~/components';
import { PaymentMethod } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { CheckoutMoneyIcon, CheckoutQrCodeIcon, LittleMachineIcon } from '~/assets';
import { useFormatCreditCard, useHandlePaymentTexts, useTranslator } from '~/hooks';
import { EditTypeEnum, PaymentFlagEnum, PaymentTypeEnum } from '~/interfaces/enums';

import * as S from './styles';

interface ItemCardProps {
  tip?: string;
  onClick: () => void;
  method: PaymentMethod;
  editAction?: () => void;
}

const ItemCard: React.FC<ItemCardProps> = ({ tip, method, onClick, editAction }) => {
  const pixKeyRef = useRef<HTMLTextAreaElement>(null);

  const pix = useSelector((state: IApplicationState) => state.establishment.settings?.mm_payment_pix_info);

  const { getTranslation } = useTranslator();
  const { formatCreditCard } = useFormatCreditCard();
  const { handlePaymentTexts } = useHandlePaymentTexts();

  const selectedIcon = useMemo(() => {
    if (method?.flag === getTranslation(PaymentFlagEnum.Nubank)) return <></>;

    if (method?.flag === getTranslation(PaymentFlagEnum.MercadoPagoQrCode)) return <CheckoutQrCodeIcon />;

    if (method?.flag === getTranslation(PaymentFlagEnum.OnlinePaymentLink) || method?.type === PaymentTypeEnum.onApp)
      return <RiSmartphoneLine size={20} />;

    if (method?.category === PaymentCategoryEnum.cash) return <CheckoutMoneyIcon />;

    return <LittleMachineIcon />;
  }, [getTranslation, method]);

  const descriptionText = useMemo(() => {
    const { flag, category, mpMethodId, cardNumber, cardDescription } = method;

    const formattedCardDescription = cardNumber
      ? formatCreditCard({
          flag: mpMethodId,
          card: cardNumber,
          isCreditCardNumberHidden: false
        })
      : cardDescription;

    const {
      nupay,
      mPagoQr,
      applePay,
      mPagoLink,
      googlePay,
      vrPagueLink,
      tunaCheckout,
      mPagoCheckout,
      integratedPix,
      pix: manualPix
    } = PaymentCategoryEnum;

    if (category === mPagoCheckout || category === tunaCheckout) {
      return formattedCardDescription;
    }

    const paymentMethodList = [nupay, mPagoQr, mPagoLink, vrPagueLink, integratedPix, googlePay, applePay, manualPix];

    if (category && paymentMethodList.includes(category)) {
      return handlePaymentTexts(category);
    }

    return `${handlePaymentTexts(category || '')} ${cardDescription || flag || ''}`;
  }, [formatCreditCard, handlePaymentTexts, method]);

  const isPix = useMemo(
    () => method.category === PaymentCategoryEnum.pix && pix?.accountName && pix?.key,
    [method.category, pix?.accountName, pix?.key]
  );

  return (
    <S.Container>
      <ButtonResume
        hideAction
        haveContent
        isPix={!!isPix}
        onClick={onClick}
        editAction={editAction}
        editType={EditTypeEnum.text}
      >
        <S.PixInfoWrapper>
          <div>
            <strong className="title">
              <div className="icon-wrapper">{selectedIcon}</div>

              {handlePaymentTexts(method.type || '')}
            </strong>

            <p className="description sentry-mask">{descriptionText}</p>
          </div>

          <p className="action">{getTranslation('general.change')}</p>
        </S.PixInfoWrapper>

        {isPix && (
          <S.MoreInfo className="sentry-mask">
            <S.Divider />

            <S.Field>
              <p>
                <strong>{`${getTranslation('general.name')}:`}</strong> {pix?.accountName}
              </p>
            </S.Field>

            <S.Field>
              <p>
                <strong>{getTranslation('general.key')}:</strong> {pix?.key}
              </p>

              <textarea readOnly ref={pixKeyRef} defaultValue={pix?.key} />
            </S.Field>
          </S.MoreInfo>
        )}
      </ButtonResume>

      {tip && (
        <div className="tip">
          <p>{tip}</p>
        </div>
      )}
    </S.Container>
  );
};

export default ItemCard;
