import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import useSlug from '~/hooks/useSlug';
import { getIsOutdated } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import { IDeliveryAverageTime } from '~/interfaces/general';
import { cleanCart } from '~/redux-tools/store/cart/actions';
import { LocalStorageEnum, QueryParam } from '~/interfaces/enums';
import { fetchMenusRequest } from '~/redux-tools/store/menus/actions';
import { cleanLastOrder } from '~/redux-tools/store/lastOrder/actions';
import { sanitizeUserAddresses } from '~/redux-tools/store/user/actions';
import useUserAuthenticationStatus from '~/hooks/useUserAuthenticationStatus';
import useFetchEstablishmentRequest from '~/hooks/useFetchEstablishmentRequest';

import useIsAppEnabled from '../useIsAppEnabled';
import { useParserFetchMenusResponse } from '..';
import { useMenuRedirects, useMenuPayment, useMenuCoupons, useMenuUrl, useMenuProducts, useMenuMenus } from './hooks';

const LAST_ORDER_TIME_LIMIT_IN_MINUTES = Number(process.env.NEXT_PUBLIC_LAST_ORDER_TIME_LIMIT_IN_MINUTES) || 30;

function useMenu() {
  const dispatch = useDispatch();

  const cart = useSelector((state: IApplicationState) => state.cart);
  const { isMenuListEmpty } = useSelector((state: IApplicationState) => state.menus);
  const lastOrder = useSelector((state: IApplicationState) => state.lastOrder.order);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { mode: localOrder } = useSelector((state: IApplicationState) => state.localOrders);
  const { addresses, name: username } = useSelector((state: IApplicationState) => state.user.data);

  const [showWelcomeFlash, setShowWelcomeFlash] = useState<string>('');
  const [showFeatureModal, setShowFeatureModal] = useState<boolean>(false);

  useMenuMenus();
  useMenuCoupons();
  useMenuPayment();
  const slug = useSlug();
  const menuUrl = useMenuUrl();
  useFetchEstablishmentRequest({});
  const { allProducts } = useMenuProducts();
  const { showBagFlash } = useMenuRedirects();
  const [isGoomerGoEnabled] = useIsAppEnabled();
  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const { parserFetchMenusResponse } = useParserFetchMenusResponse();

  const isFeatureModalHasBeenSeen = useCallback((feature: string) => {
    if (typeof window !== 'undefined') {
      return localStorage?.getItem('feature-banner') === feature;
    }

    return false;
  }, []);

  const formatAverageTime = useCallback((averageTime: IDeliveryAverageTime) => {
    if (typeof averageTime === 'string' && averageTime !== '') {
      if (!isNaN(averageTime)) return `${averageTime}m`;

      return averageTime;
    }

    let range = '';

    if (averageTime.from) {
      range = `${averageTime.from}`;
    }

    if (averageTime.to) {
      range = `${range}-${averageTime.to}`;
    }

    if (!range) return '';

    return `${range}min`.trim();
  }, []);

  const delivery = useMemo(() => {
    if (!settings || !settings?.mm_delivery_enabled) return undefined;

    return {
      delivery_enabled: settings.mm_delivery_enabled,
      delivery_waiting_time: formatAverageTime(settings.mm_delivery_average_time),
      delivery_fee: settings.mm_delivery_fee_enabled ? Number(settings.mm_delivery_fee) : 0,
      delivery_required_amount: settings.mm_delivery_minimum_value_enabled
        ? Number(settings.mm_delivery_minimum_value)
        : 0
    };
  }, [settings, formatAverageTime]);

  const showLastOrder = useMemo(() => {
    if (lastOrder?.order?.payment_type?.category === PaymentCategoryEnum.integratedPix) return false;

    const isOutdatedOrder = getIsOutdated({ date: lastOrder.date, minutes: LAST_ORDER_TIME_LIMIT_IN_MINUTES });

    if (isOutdatedOrder) {
      dispatch(cleanLastOrder());
    }

    if (localOrder) return false;

    const isTheSameEstablishment =
      settings &&
      lastOrder.establishment?.id &&
      [settings.id, settings.store_code].includes(lastOrder.establishment.id);

    return isTheSameEstablishment ? !isOutdatedOrder : false;
  }, [dispatch, lastOrder, localOrder, settings]);

  const hasCartItems = useMemo(() => cart.products.length > 0, [cart.products.length]);

  useEffect(() => {
    const metaThemeColor = typeof document !== 'undefined' && document.querySelector('meta[name=theme-color]');

    if (metaThemeColor && !!settings?.mm_main_color) {
      metaThemeColor.setAttribute('content', settings?.mm_main_color);
    }
  }, [settings?.mm_main_color]);

  useEffect(() => {
    const title = settings?.name;
    const address = settings?.address?.city;

    document.title = `${title ? `${title} - ` : ''} Cardápio e Delivery ${address ? `em ${address}` : ''}`;
  }, [settings?.name, settings?.address?.city]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const isWelcome = urlParams.get(QueryParam.welcome);

    if (isWelcome && !!username) {
      setShowWelcomeFlash(isWelcome);
    }
  }, [username]);

  useEffect(() => {
    if (slug !== undefined && cart.establishment !== '') {
      if (cart.establishment !== slug) {
        dispatch(cleanCart());
      }
    }
  }, [slug, dispatch, cart.establishment]);

  useEffect(() => {
    if (!isUserAuthenticated && addresses && addresses.length > 0 && typeof window !== 'undefined') {
      const sanitizedAddress = addresses.filter((address) => address.id && address.id.length > 0);

      if (sanitizedAddress.length !== addresses.length) {
        dispatch(sanitizeUserAddresses(sanitizedAddress));
      }

      if (localStorage && !localStorage.getItem(LocalStorageEnum.addressValidated)) {
        const oldApiAddresses = addresses.map((address) => {
          if (typeof address.google !== 'boolean' || !address.google) {
            return { ...address, latitude: '', longitude: '' };
          }

          return address;
        });

        localStorage.setItem(LocalStorageEnum.addressValidated, 'true');
        dispatch(sanitizeUserAddresses(oldApiAddresses));
      }
    }
  }, [addresses, dispatch, isUserAuthenticated]);

  useEffect(() => {
    function enableNewFeatureModal(): void {
      const isOrderSchedulingEnabled = settings?.mm_order_scheduling_enabled;

      if (!isOrderSchedulingEnabled) return;

      setShowFeatureModal(true);
    }

    enableNewFeatureModal();
  }, [settings]);

  useEffect(() => {
    if (menuUrl && allProducts.length === 0 && !isMenuListEmpty && isGoomerGoEnabled) {
      dispatch(fetchMenusRequest({ url: menuUrl, parserFetchMenusResponse }));
    }
  }, [allProducts.length, dispatch, isGoomerGoEnabled, isMenuListEmpty, menuUrl, parserFetchMenusResponse]);

  return {
    delivery,
    showBagFlash,
    hasCartItems,
    showLastOrder,
    showWelcomeFlash,
    showFeatureModal,
    setShowFeatureModal,
    isFeatureModalHasBeenSeen
  };
}

export default useMenu;
