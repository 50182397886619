import { api } from '~/services/api';

interface sendCustomerEmailProps {
  email: string;
  orderId: number | string;
}

export default async function sendCustomerEmail({ email, orderId }: sendCustomerEmailProps) {
  const apiPath = `${process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL}/payments/pix/customer-email`;

  try {
    const response = await api.post(apiPath, {
      email,
      order_id: orderId
    });

    return response;
  } catch (error) {
    throw new Error(`Post error: ${error}`);
  }
}
