import { useMemo } from 'react';

import { convertToCurrency } from '~/utils';
import { Price, PricePromotion } from '~/interfaces';
import { FormattedPrice } from '~/interfaces/general';

import usePromotionalPrice from '../usePromotionalPrice';

export interface UseFormattedPriceProps {
  price?: number;
  isCombo?: boolean;
  priceList?: Price[];
  isAvailable: boolean;
}

export default function useFormattedPrice({
  isCombo,
  price = 0,
  isAvailable,
  priceList = []
}: UseFormattedPriceProps): FormattedPrice | undefined {
  const availablePromotionalPriceList = usePromotionalPrice(priceList);

  const formattedPrice: FormattedPrice | undefined = useMemo(() => {
    if (price === 0 && !isAvailable) {
      return;
    }

    if (price && availablePromotionalPriceList.length === 0 && !isCombo) {
      const priceValue: number = price || 0;

      return { price: priceValue, formatted: convertToCurrency(priceValue) };
    }

    if (priceList.length > 1 || availablePromotionalPriceList.length >= 1) {
      const priceValueList = priceList.map((price) => price.price);

      if (isCombo) {
        const sumProductPrice =
          priceList.length > 0 ? priceValueList.reduce((current, value) => current + value) : price || 0;

        if (availablePromotionalPriceList.length >= 1) {
          const promoPriceList = availablePromotionalPriceList.map((promotion) => promotion.promotion?.price || 0);
          const sumPromoPrice = promoPriceList.reduce((current, value) => current + value);
          const discountValue = sumProductPrice - sumPromoPrice;
          const promoPercentage = (discountValue / sumProductPrice) * 100;

          const comboPromotion: PricePromotion = {
            price: sumPromoPrice,
            percentage_flag: promoPercentage,
            available_hours: availablePromotionalPriceList[0].promotion?.available_hours || []
          };

          return {
            basePrice: sumProductPrice,
            promotion: comboPromotion,
            price: comboPromotion.price,
            formatted: convertToCurrency(comboPromotion.price)
          };
        }

        return { price: sumProductPrice, formatted: convertToCurrency(sumProductPrice) };
      }

      const minimumProductPrice = priceList.length > 0 ? Math.min(...priceValueList) : price || 0;

      if (availablePromotionalPriceList.length >= 1) {
        const cheapestPrice = availablePromotionalPriceList.reduce((minimum, current) => {
          const minimumPromoPrice = minimum.promotion?.price || 0;
          const currentPromoPrice = current.promotion?.price || 0;

          return minimumPromoPrice > currentPromoPrice ? current : minimum;
        });

        if (cheapestPrice?.promotion && cheapestPrice?.promotion.price < minimumProductPrice) {
          const { price, promotion } = cheapestPrice;

          return {
            promotion,
            basePrice: price,
            price: promotion.price,
            formatted: convertToCurrency(promotion.price)
          };
        }
      }

      return { price: minimumProductPrice, formatted: convertToCurrency(minimumProductPrice) };
    }

    if (priceList.length === 1) {
      return { price: priceList[0]?.price, formatted: convertToCurrency(priceList[0]?.price) };
    }

    return;
  }, [availablePromotionalPriceList, isAvailable, isCombo, price, priceList]);

  return formattedPrice;
}
