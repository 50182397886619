import { toast } from 'react-toastify';

import { OptionGroup, Product } from '~/interfaces';
import { ProductType } from '~/interfaces/enums';
import { parserFetchProductOptionalsResponse } from '~/utils';

interface FetchProductOptionalsProps {
  slug: string;
  product: Product;
  localOrder: string;
  isNewMenu?: boolean;
  menuVersion?: string;
  provider: ProductType;
  abrahaoStoreId?: string;
  selectedLanguage: string | null;
  getTranslation: (key: string) => string;
  setIsLoadingOptionals: (value: boolean) => void;
  setOptionals: (value: FetchProductOptionalsResponse) => void;
}

export interface FetchProductOptionalsResponse {
  flow_list: OptionGroup[];
  option_groups: OptionGroup[];
}

export default async function fetchProductOptionals({
  slug,
  product,
  provider,
  isNewMenu,
  localOrder,
  setOptionals,
  abrahaoStoreId,
  getTranslation,
  selectedLanguage,
  menuVersion = '',
  setIsLoadingOptionals
}: FetchProductOptionalsProps): Promise<void> {
  const getRequestUrl = (): string => {
    const getAPIPathWithDefaultParams = (apiUrl: URL) => {
      if (provider) {
        apiUrl.searchParams.append('provider', provider);
      }

      return apiUrl.toString();
    };

    if (isNewMenu && abrahaoStoreId) {
      const endpointBaseAbrahao = process.env.NEXT_PUBLIC_API_ABRAHAO || '';
      const apiPath = new URL(
        `${endpointBaseAbrahao}/${abrahaoStoreId}/menu/${product?.id}/optional?product_version=${menuVersion}`
      );

      if (selectedLanguage) {
        apiPath.searchParams.append('lang', selectedLanguage);
      }

      return getAPIPathWithDefaultParams(apiPath);
    }

    const endpointBase = process.env.NEXT_PUBLIC_BASE_URL || '';
    const group = localOrder ? 'localoptiongroups' : 'optiongroups';

    const apiPath = new URL(`${endpointBase}/${slug}/product/${product?.id}/${group}/${product?.version}`);

    return getAPIPathWithDefaultParams(apiPath);
  };

  const requestUrl: string = getRequestUrl();

  const response = await fetch(requestUrl)
    .then(async (response: Response): Promise<FetchProductOptionalsResponse> => {
      if (!response.ok) throw new Error('Falha na requisição');

      //Trocar checagem pela setting que indica novo menu
      const jsonResponse = await response.json();

      if (!jsonResponse?.flow_list) {
        return parserFetchProductOptionalsResponse(jsonResponse);
      }

      return jsonResponse;
    })
    .catch(() => {
      toast.error(getTranslation('errorMessage.errorToGetOptionals'));
    });

  if (!response?.option_groups) {
    setIsLoadingOptionals(false);
    return setOptionals({ flow_list: [], option_groups: [] });
  }

  const optionalList: OptionGroup[] = response.option_groups.map((item: OptionGroup, groupIndex: number) => ({
    ...item,
    options: item.options.map((option, optionIndex: number) => ({
      ...option,
      indexPosition: Number(`${groupIndex}${optionIndex <= 9 ? '0' : ''}${optionIndex}`)
    }))
  }));

  setIsLoadingOptionals(false);

  return setOptionals({ option_groups: optionalList, flow_list: response.flow_list });
}
