import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { CheckoutContext as OrderContext } from '~/pages/order';
import { PaymentMethod, ICardFlags } from '~/interfaces/general';
import { addUserPayment } from '~/redux-tools/store/user/actions';
import { CheckoutContext as LocalOrderContext } from '~/pages/localOrder';
import { CheckoutContext as MyTabContext } from '~/pages/myTabPaymentDetails';

import { MethodsList, OptionsList, SelectedMethod } from './components';

import * as S from './styles';

interface PaymentModalProps {
  isMyTab?: boolean;
  closeModal: () => void;
  isModalVisible: boolean;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ isMyTab, closeModal, isModalVisible }) => {
  const dispatch = useDispatch();

  const localOrder = useSelector((state: IApplicationState) => state.localOrders.mode);

  const { getTranslation } = useTranslator();

  const currentContext = useMemo(() => {
    if (isMyTab) {
      return MyTabContext;
    }

    return localOrder ? LocalOrderContext : OrderContext;
  }, [isMyTab, localOrder]);

  const {
    discount,
    defaultPage,
    discountType,
    setPaymentOption,
    moneyChangeValue,
    moneyChange: hasMoneyChange
  } = useContext(currentContext);

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const totalValue = useSelector((state: IApplicationState) => state.cart.values.total);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const userPaymentMethodPreferenceList = useSelector((state: IApplicationState) => state.user.data.paymentPreference);

  const totalPriceWithDiscount = useMemo(() => {
    if (discount) {
      if (discountType === 'percent') {
        return totalValue * (1 - discount.value);
      }

      return totalValue - discount.value;
    }

    return totalValue;
  }, [discount, totalValue, discountType]);

  const debitFlags = settings?.mm_payment_debit_enabled && settings?.mm_payment_debit_flags;
  const creditFlags = settings?.mm_payment_credit_enabled && settings?.mm_payment_credit_flags;
  const voucherFlags = settings?.mm_payment_voucher_enabled && settings.mm_payment_voucher_flags;

  const [title, setTitle] = useState(getTranslation('general.payment'));
  const [shouldOpenRight, setShouldOpenRight] = useState<boolean>(false);
  const [method, setMethod] = useState<PaymentMethod | undefined>(() =>
    userPaymentMethodPreferenceList && Array.isArray(userPaymentMethodPreferenceList)
      ? userPaymentMethodPreferenceList?.find((payment) => payment.establishment === settings?.name)
      : undefined
  );
  const [page, setPage] = useState(method?.category === PaymentCategoryEnum.cash ? 'selected' : 'list');

  const filterChecked = useCallback((flags?: ICardFlags[]) => {
    if (!flags) return [];
    if (typeof flags === 'string') return [];

    return flags.filter((item) => item.checked);
  }, []);

  const debitFlagList = useMemo(
    () => (debitFlags ? filterChecked(settings?.mm_payment_debit_flags) : []),
    [debitFlags, filterChecked, settings?.mm_payment_debit_flags]
  );

  const creditFlagList = useMemo(
    () => (creditFlags ? filterChecked(settings?.mm_payment_credit_flags) : []),
    [creditFlags, filterChecked, settings?.mm_payment_credit_flags]
  );

  const voucherFlagList = useMemo(
    () => (voucherFlags ? filterChecked(settings?.mm_payment_voucher_flags) : []),
    [filterChecked, settings?.mm_payment_voucher_flags, voucherFlags]
  );

  useEffect(() => {
    if (defaultPage) {
      setPage(defaultPage);
    }
  }, [defaultPage]);

  useEffect(() => {
    if (userPaymentMethodPreferenceList && settings?.name && Array.isArray(userPaymentMethodPreferenceList)) {
      const userEstablishmentPreferences = userPaymentMethodPreferenceList?.find(
        (payment) => payment.establishment === settings?.name
      );

      setMethod(userEstablishmentPreferences);
    }
  }, [userPaymentMethodPreferenceList, settings]);

  const isMoneyChangeValid = useMemo(() => {
    if (hasMoneyChange && moneyChangeValue > 0 && moneyChangeValue <= totalPriceWithDiscount) {
      return false;
    }

    return true;
  }, [hasMoneyChange, moneyChangeValue, totalPriceWithDiscount]);

  const handleCloseModal: () => void = () => {
    if (method?.category !== PaymentCategoryEnum.cash) {
      setPage('list');
      setShouldOpenRight(false);
    }

    if (isMoneyChangeValid) {
      setPage('list');
      closeModal();
    }
  };

  const handlePageChange = useCallback(
    (paymentMethod: PaymentMethod) => {
      const { flag, category } = paymentMethod;

      if (!category && !flag) {
        setPage('list');
        setMethod(undefined);
        setShouldOpenRight(false);
        return setTitle(getTranslation('general.payment'));
      }

      if (!flag) {
        let goTo = 'selected';

        switch (category) {
          case PaymentCategoryEnum.credit:
            if (creditFlagList.length > 0) goTo = 'options';
            break;
          case PaymentCategoryEnum.debit:
            if (debitFlagList.length > 0) goTo = 'options';
            break;
          case PaymentCategoryEnum.voucher:
            if (voucherFlagList.length > 0) goTo = 'options';
            break;
          default:
            goTo = 'selected';
        }

        setMethod(paymentMethod);

        if (category !== PaymentCategoryEnum.cash) {
          setShouldOpenRight(true);
        }

        return setPage(goTo);
      }

      setPage('selected');
      setShouldOpenRight(false);

      return setMethod(paymentMethod);
    },
    [creditFlagList.length, debitFlagList.length, getTranslation, voucherFlagList.length]
  );

  const handleSetPaymentMethod = useCallback(
    (accountDocument?: string) => {
      if (method) {
        let methodToSet = method;

        if (method?.category === PaymentCategoryEnum.nupay && accountDocument) {
          methodToSet = { ...method, document: accountDocument };
        }

        if (method?.category !== PaymentCategoryEnum.cash) {
          setPage('list');
        }

        setPaymentOption(methodToSet);

        dispatch(addUserPayment({ establishment: settings?.name, ...methodToSet }));
      } else if (userPaymentMethodPreferenceList && Array.isArray(userPaymentMethodPreferenceList)) {
        const userEstablishmentPreferences = userPaymentMethodPreferenceList?.find(
          (payment) => payment.establishment === settings?.name
        );

        if (userEstablishmentPreferences) {
          setPaymentOption(userEstablishmentPreferences);
        }
      } else {
        return false;
      }

      return closeModal();
    },
    [method, userPaymentMethodPreferenceList, closeModal, setPaymentOption, dispatch, settings?.name]
  );

  const isSupposedToCloseModal = useMemo(() => {
    if (page === 'list' || method?.category === PaymentCategoryEnum.cash) return true;

    if (method) return false;

    if (userPaymentMethodPreferenceList) return true;

    return false;
  }, [userPaymentMethodPreferenceList, page, method]);

  return (
    <S.Container
      isPageLike
      isContentVisible
      headerTitle={title}
      isShow={isModalVisible}
      buttonSvgColor={theme.colors.pureTextColor}
      onClose={isSupposedToCloseModal ? handleCloseModal : (): void => handlePageChange({})}
    >
      <MethodsList isMyTab={isMyTab} changePage={(selectedMethod): void => handlePageChange(selectedMethod)} />

      <OptionsList
        method={method}
        isContentVisible={page === 'options'}
        goBack={(): void => handlePageChange({})}
        changePage={(selectedMethod): void => handlePageChange(selectedMethod)}
      />

      <SelectedMethod
        method={method}
        isMyTab={isMyTab}
        openRight={shouldOpenRight}
        onConfirm={handleSetPaymentMethod}
        isShow={page === 'selected' && !!method && isModalVisible}
        goBack={(selectedMethod): void => handlePageChange(selectedMethod)}
        title={
          method?.category === PaymentCategoryEnum.nupay
            ? getTranslation('general.paymentConfirm')
            : getTranslation('general.payment')
        }
      />
    </S.Container>
  );
};

export default PaymentModal;
