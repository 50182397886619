import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';

import { useMenuMenus } from '../useMenu/hooks';

export interface UseDesktopHorizontallScrollProps {
  scrollClass: string;
}

export default function useDesktopHorizontallScroll({ scrollClass }: UseDesktopHorizontallScrollProps): void {
  const isFirstRender = useRef(true);
  const { selectedMenu } = useMenuMenus();

  useEffect(() => {
    let isDown = false;
    let startX: number;
    let wasDragged = false;
    let scrollLeft: number;
    const scrollClickMargin = 10;

    if (typeof document !== 'undefined' && selectedMenu && isFirstRender.current && !isMobile) {
      isFirstRender.current = false;

      const slider: any = document.querySelector(scrollClass);

      slider?.addEventListener('mousedown', (e: any) => {
        isDown = true;
        wasDragged = false;
        scrollLeft = slider.scrollLeft;
        startX = e.pageX - slider.offsetLeft;
      });

      slider?.addEventListener('mouseleave', () => {
        isDown = false;
      });

      slider?.addEventListener('mouseup', (e: any) => {
        isDown = false;
      });

      slider?.addEventListener('click', (e: any) => {
        if (wasDragged) {
          e.preventDefault();
          e.stopPropagation();
        }

        wasDragged = false;
      });

      slider?.addEventListener('mousemove', (e: any) => {
        if (!isDown) return;
        e.preventDefault();

        const SCROLL_SPEED = 1;
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * SCROLL_SPEED;
        slider.scrollLeft = scrollLeft - walk;

        const ammountScrolled = startX - x;
        if (ammountScrolled > scrollClickMargin || ammountScrolled < -scrollClickMargin) {
          wasDragged = true;
        }
      });
    }
  }, [scrollClass, selectedMenu]);
}
