/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const BannerContainer = styled.div`
  ${({ theme }) => css`
    padding: 0.625rem;
    border-radius: 0.313rem;

    display: flex;
    flex-direction: column;

    line-height: 140%;
    font-size: 0.75rem;
    background-color: ${theme.isDarkMode ? theme.colors.gray400 : theme.colors.lightestGray};

    cursor: pointer;
  `}
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    font-weight: 800;
    font-size: 0.75rem;
  }

  span {
    font-weight: 500;
    line-height: 140%;
    font-size: 0.75rem;
  }

  svg {
    min-width: 0.875rem;
  }
`;

export const BottomContainer = styled.div`
  margin-top: 0.625rem;

  display: flex;
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 0;

  text-align: left;
`;

export const SvgWrapper = styled.div`
  ${({ theme }) => css`
    height: 1.5rem;
    min-width: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 0.313rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.warning};
  `};
`;

export const ProgramItem = styled.div<{ isDone: boolean; width: number }>`
  width: ${({ width }) => (width ? `${width - 4}px` : '50px')};
  height: 0.25rem;
  border-radius: 3.8rem;

  &:not(:first-child) {
    margin-left: 0.25rem;
  }

  background-color: ${({ theme, isDone }) => (isDone ? theme.colors.warning : theme.colors.placeholderGray)};
`;
