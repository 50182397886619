import React, { useCallback, useContext } from 'react';

import { useTranslator } from '~/hooks';
import { removeNoNumbers } from '~/utils';
import { InputPhone, InputName, InputBirthDate, Terms } from '~/components';
import { AccountVerificationContext } from '~/components/AccountVerification';

import * as S from './styles';

const SendEmail = (): JSX.Element => {
  const {
    userName,
    maskedEmail,
    setUserName,
    userBirthday,
    userPhoneNumber,
    setUserBirthday,
    setUserPhoneNumber,
    setIsBirthdayAfterToday,
    phoneIsAlreadyRegistered
  } = useContext(AccountVerificationContext);
  const { getTranslation } = useTranslator();

  const handlePhoneChange = useCallback(
    (value: string) => {
      setUserPhoneNumber({
        masked: value,
        pure: removeNoNumbers(value),
        withDDI: `55${removeNoNumbers(value)}`
      });
    },
    [setUserPhoneNumber]
  );

  return (
    <S.Container>
      <h4>{getTranslation('accountVerification.completeYourSignUp')}</h4>
      <h5>{getTranslation('accountVerification.signUpInformation')}</h5>

      <S.StyledField>
        <label>{getTranslation('general.firstNameAndLastName')}</label>

        <InputName
          required
          isGoomerColor
          onChange={setUserName}
          defaultValue={userName}
          placeholder={getTranslation('user.inputNamePlaceholder')}
        />
      </S.StyledField>

      <S.StyledField>
        <label>
          {getTranslation('general.birthdayDate')} ({getTranslation('general.optional')})
        </label>

        <InputBirthDate
          isGoomerColor
          onChange={setUserBirthday}
          isBirthDateAfterToday={setIsBirthdayAfterToday}
          defaultValue={userBirthday}
        />
      </S.StyledField>

      <S.StyledField>
        <label>{getTranslation('user.phoneNumber')}</label>

        <InputPhone
          required
          isGoomerColor
          id="input-phone-verification"
          onChange={handlePhoneChange}
          placeholder="(00) 00000-0000"
          defaultValue={userPhoneNumber.masked}
        />

        {phoneIsAlreadyRegistered && (
          <S.ErrorMessage>
            {getTranslation('accountVerification.phoneAlreadyRegistered', {
              validation: maskedEmail
                ? `${getTranslation('accountVerification.toEmail')} ${maskedEmail}`
                : getTranslation('accountVerification.otherRegister')
            })}
          </S.ErrorMessage>
        )}

        <S.Info>{getTranslation('accountVerification.customerPhoneNumberInfo')}</S.Info>
      </S.StyledField>

      <div className="terms">
        <Terms action={getTranslation('terms.termsAction')} />
      </div>
    </S.Container>
  );
};

export default SendEmail;
