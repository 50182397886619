import React, { useRef } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import SearchIcon from '~/assets/SearchIcon';

import { Header, ClearButton, InputWrapper, DebounceInput } from './styles';

interface SearchHeaderProps {
  delay: number;
  term?: string;
  placeholder: string;
  isGoomerColor?: boolean;
  onChange: (value: string) => void;
  shouldDisplayRecentSearches?: boolean;
  closeRecent?: (shouldClose: boolean) => void;
}

const SearchHeader: React.FC<SearchHeaderProps> = ({
  term,
  delay,
  onChange,
  placeholder,
  closeRecent,
  isGoomerColor = false,
  shouldDisplayRecentSearches
}) => {
  const searchInput: any = useRef(null);

  return (
    <Header>
      <InputWrapper
        isGoomerColor={isGoomerColor}
        onFocus={(): void => {
          closeRecent?.(true);
        }}
      >
        <SearchIcon className="search" />

        <DebounceInput
          type="text"
          value={term}
          inputRef={searchInput}
          debounceTimeout={delay}
          data-test="search-input"
          placeholder={placeholder}
          onChange={(event: any): void => {
            onChange(event.target.value);
            closeRecent?.(false);
          }}
        />

        {(shouldDisplayRecentSearches || (term && term.length > 0)) && (
          <ClearButton
            onClick={(): void => {
              onChange('');
              closeRecent?.(false);
            }}
            data-test="btn-close-search"
          >
            <LazyLoadImage width={14} height={14} alt="search icon" className="clear" src="/assets/clear-input.svg" />
          </ClearButton>
        )}
      </InputWrapper>
    </Header>
  );
};

export default SearchHeader;
