import styled, { css } from 'styled-components';

export const Container = styled.div<{ hasMargin: boolean }>`
  ${({ theme, hasMargin }) => css`
    margin: ${hasMargin ? '20px 0' : 0};

    background-color: ${theme.colors.backgroundColor};

    .confirm-button {
      padding: 0 24px 24px;
    }
  `};
`;
