import useLoyaltyAccess from './hooks/useLoyaltyAccess';
import useLoyaltyStatus from './hooks/useLoyaltyStatus';
import useLoyaltyDiscount from './hooks/useLoyaltyDiscount';
import useLoyaltyRemainingAmount from './hooks/useLoyaltyRemainingAmount';
import useLoyaltyDisplayMenuInfo from './hooks/useLoyaltyDisplayMenuInfo';
import useLoyaltyOrderValidation from './hooks/useLoyaltyOrderValidation';
import useLoyaltySelectedChannels from './hooks/useLoyaltySelectedChannels';
import useLoyaltyIntervalBetweenOrders from './hooks/useLoyaltyIntervalBetweenOrders';

export interface UseLoyaltyProgramHooksProps {
  isValidLoyaltyOrder: boolean;
  readableChannelNames: string;
  isLoyaltyProgramEnabled: boolean;
  hasLoyaltyProgramAccess: boolean;
  isCurrentChannelSelected: boolean;
  shouldDisplayValidOrderCard: boolean;
  isLoyaltyTemporarilyBlocked: boolean;
  loyaltyDiscountValueUnlocked: number;
  countdownInSecondsToUnblockLoyalty: number;
  remainingAmountToFulfillTheProgram: number;
  shouldDisplayLoyaltyProgramMenuInfo: boolean;
}

export type UseLoyaltyProgramProps = Omit<UseLoyaltyProgramHooksProps, 'isCurrentChannelSelected'>;

export default function useLoyaltyProgram(): UseLoyaltyProgramProps {
  const { hasLoyaltyProgramAccess } = useLoyaltyAccess();
  const { isLoyaltyProgramEnabled } = useLoyaltyStatus();
  const { readableChannelNames } = useLoyaltySelectedChannels();
  const { loyaltyDiscountValueUnlocked } = useLoyaltyDiscount();
  const { remainingAmountToFulfillTheProgram } = useLoyaltyRemainingAmount();
  const { shouldDisplayLoyaltyProgramMenuInfo } = useLoyaltyDisplayMenuInfo();
  const { isValidLoyaltyOrder, shouldDisplayValidOrderCard } = useLoyaltyOrderValidation();
  const { isLoyaltyTemporarilyBlocked, countdownInSecondsToUnblockLoyalty } = useLoyaltyIntervalBetweenOrders();

  return {
    isValidLoyaltyOrder,
    readableChannelNames,
    isLoyaltyProgramEnabled,
    hasLoyaltyProgramAccess,
    shouldDisplayValidOrderCard,
    isLoyaltyTemporarilyBlocked,
    loyaltyDiscountValueUnlocked,
    countdownInSecondsToUnblockLoyalty,
    remainingAmountToFulfillTheProgram,
    shouldDisplayLoyaltyProgramMenuInfo
  };
}
