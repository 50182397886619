/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translations from './locales';

const i18nConfig: InitOptions = {
  lng: 'pt-BR',
  saveMissing: true,
  fallbackLng: 'pt-BR',
  resources: translations,
  defaultNS: 'translations',
  interpolation: {
    format: function (value: string, format?: string): string {
      if (format === 'uppercase') return value?.toUpperCase();
      if (format === 'lowercase') return value?.toLowerCase();
      if (format === 'capitalizeFirstLetter') return value?.charAt(0).toUpperCase() + value?.substring(1);

      return value;
    }
  }
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);

export default i18n;
