import { RiArrowRightSLine } from 'react-icons/ri';

import { Switch } from '~/components';
import { convertToCurrency } from '~/utils';

import * as S from './styles';

interface PaymentMethodCardProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  price?: number;
  isSelected?: boolean;
  dividedAmount?: number;
  description: React.ReactNode;
  toggle?: {
    checked: boolean;
    disabled: boolean;
    onChange: () => void;
  };
}

const PaymentMethodCard: (props: PaymentMethodCardProps) => JSX.Element = ({
  price,
  toggle,
  isSelected,
  description,
  dividedAmount,
  ...rest
}) => {
  return (
    <S.Container {...rest} className="sentry-mask">
      <p>{description}</p>

      <S.Content>
        {!!dividedAmount && dividedAmount > 1 && <S.AmountTag>{dividedAmount}</S.AmountTag>}

        {!!price && isSelected && <p>{convertToCurrency(price)}</p>}

        {toggle ? (
          <Switch
            aria-label="Interruptor"
            checked={toggle.checked}
            onChange={toggle.onChange}
            disabled={toggle.disabled}
          />
        ) : (
          <RiArrowRightSLine size={20} aria-label="Seta para a direita" />
        )}
      </S.Content>
    </S.Container>
  );
};

export { PaymentMethodCard };
