import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { CityInfo } from '~/interfaces/general';

interface FetchCityListProps {
  search: string;
  resultLimit: number;
}

interface CitySearchResult {
  addresses: {
    id: number;
    name: string;
    state: string;
  }[];
  page: number;
  pages: number;
  total: number;
}

export default async function fetchCityList({ search, resultLimit }: FetchCityListProps): Promise<CityInfo[]> {
  const API_URL = process.env.NEXT_PUBLIC_ADDRESSES;

  try {
    const { data }: AxiosResponse<CitySearchResult> = await axios.get(
      `${API_URL}/cities?search=${search}&limit=${resultLimit}`
    );

    if (data.addresses.length === 0) {
      throw new Error('#15 Erro ao buscar sugestões de cidade');
    }

    return data.addresses;
  } catch (error) {
    toast.error((error as Error)?.message);
  }

  return [];
}
