import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 2rem;

  overflow-x: hidden;

  > div {
    margin: 0;
    padding: 0 1rem;
  }

  input {
    width: 100%;
    margin: 0.25rem 0 0;
  }
`;

export const Info = styled.div`
  ${({ theme }) => css`
    padding: 0 1rem !important;
    margin-top: 0.25rem !important;

    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    font-size: 0.75rem;

    color: ${theme.colors.gray500};
  `};
`;
