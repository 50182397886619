import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Input, Button } from '~/components';
import { DeliveryWayEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import { useGetCouponErrorMessage, useTranslator } from '~/hooks';
import { fetchCouponRequest } from '~/redux-tools/store/coupons/actions';

import * as S from './styles';

export interface ApplyCodeModalProps {
  isShow: boolean;
  onClose: () => void;
}

const ApplyCodeModal = ({ isShow, onClose }: ApplyCodeModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const { error, loading, origin: couponOrigin } = useSelector((state: IApplicationState) => state.coupons);

  const [couponCode, setCouponCode] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);

  const { getTranslation } = useTranslator();
  const { displayErrorMessage } = useGetCouponErrorMessage();

  const handleSearchCoupon = useCallback(() => {
    const afterAction = {
      onClose,
      validateCoupon: true,
      setShowError: () => setShowError(true)
    };

    dispatch(
      fetchCouponRequest({ couponCode, afterAction, origin: DeliveryWayEnum[couponOrigin], displayErrorMessage })
    );
  }, [couponCode, couponOrigin, dispatch, displayErrorMessage, onClose]);

  return (
    <S.Container isSmall isShow={isShow} onClose={onClose} headerTitle={getTranslation('discount.discountCoupon')}>
      <p className="description">{getTranslation('discount.enterCouponDescription')}</p>

      <Input
        isUpperCase
        defaultValue=""
        required={false}
        name="coupon-code"
        data-test="input-coupon-code"
        error={showError ? error : ''}
        placeholder={getTranslation('discount.couponCode')}
        onChange={(value): void => setCouponCode(value as string)}
      />

      <Button
        isGhost={false}
        disabled={loading}
        isLoading={loading}
        onClick={handleSearchCoupon}
        data-test={loading ? 'btn-apply-coupon-disabled' : 'btn-apply-coupon'}
      >
        {loading ? getTranslation('discount.applyingCoupon') : getTranslation('discount.applyCoupon')}
      </Button>
    </S.Container>
  );
};

export default ApplyCodeModal;
