import styled, { css } from 'styled-components';

const containerModifiers = {
  hasAction: () => css`
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  `
};

export const Container = styled.div<{ $hasAction: boolean; $margin?: string }>`
  ${({ theme, $hasAction }) => css`
    margin: 0;

    display: flex;
    align-items: center;

    svg {
      color: ${theme.colors.gray500};
    }

    ${$hasAction && containerModifiers.hasAction()}
  `}
`;

export const TextWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: 0.5rem;

    line-height: 1;
    font-weight: 600;
    font-size: 0.8rem;
    color: ${theme.colors.gray500};
  `}
`;
