import React, { useState, useEffect, useContext, useMemo, useCallback, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getTermIntoString } from '@goomerdev/goomer-toolbox/src/utils';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { CheckoutContext } from '~/pages/order';
import { FeaturesEnum } from '~/interfaces/enums';
import { useMenuCoupons } from '~/hooks/useMenu/hooks';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { setOrigin } from '~/redux-tools/store/coupons/actions';
import { DeliveryWay, IEstablishmentSettings } from '~/interfaces/general';
import { LoyaltyValidOrderConfirmationCard } from '~/components/Loyalty/components';
import { useHasFeatureAccess, useLoyaltyProgram, useTranslator, useUserAuthenticationStatus } from '~/hooks';
import {
  UserInfo,
  OptionHeader,
  PaymentOption,
  CheckoutCoupon,
  LoginCallToAction,
  CheckoutScheduling,
  AddressDeliveryInfo,
  LatLngConfirmationModal
} from '~/components';

import DocumentInput from '../DocumentInput';
import CheckoutOption, { CheckoutOptionProps, CheckoutRadioOptions } from '../CheckoutOption';

import * as S from './styles';

interface NewCheckoutOptionsProps {
  name: string;
  clientPhone: string;
  renderName: () => void;
  renderPhone: () => void;
}

const deliveryOptionsList = ['mm_delivery_enabled', 'mm_takeaway_enabled'];

const NewCheckoutOptions: React.FC<NewCheckoutOptionsProps> = ({ name, renderName, renderPhone, clientPhone }) => {
  const dispatch = useDispatch();

  const firstUpdate = useRef(true);

  const checkoutProps = useContext(CheckoutContext);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const hasCoupons = useSelector((state: IApplicationState) => state.coupons.existCoupons);
  const { deliveryFee, address: userCartAddress } = useSelector((state: IApplicationState) => state.cart);

  const [hasNameOrPhone, setHasNameOrPhone] = useState<boolean>(false);
  const [checkoutOptionList, setCheckoutOptionList] = useState<CheckoutOptionProps[]>();

  const { getTranslation } = useTranslator();
  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const [hasUserSignUpAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.UserSignUp });
  const { isLoyaltyTemporarilyBlocked, shouldDisplayValidOrderCard } = useLoyaltyProgram();
  useMenuCoupons();

  useEffect(() => {
    function mountCheckoutGroups(): void {
      if (settings) {
        const deliveryOptionList = deliveryOptionsList.map((option) => ({
          key: option,
          value: settings[option as keyof IEstablishmentSettings]
        }));

        setCheckoutOptionList([
          {
            type: 'delivery',
            title: getTranslation('general.chooseOption'),
            options: deliveryOptionList as CheckoutRadioOptions[]
          }
        ]);
      }
    }

    mountCheckoutGroups();
  }, [getTranslation, settings]);

  useEffect(() => {
    if (userCartAddress) {
      checkoutProps.setAddressSelected(true);
    }
  }, [userCartAddress, checkoutProps]);

  useEffect(() => {
    if (firstUpdate.current) {
      setHasNameOrPhone(!!name || !!clientPhone);
    }

    firstUpdate.current = false;
  }, [name, clientPhone]);

  const isDelivery = useMemo(() => {
    if (getTermIntoString({ term: 'entrega', word: checkoutProps.deliveryOption || '' })) return true;
    if (getTermIntoString({ term: 'delivery', word: checkoutProps.deliveryOption || '' })) return true;

    return false;
  }, [checkoutProps]);

  const handleDeliveryTypeChange = useCallback(
    (origin: DeliveryWay) => {
      GoogleAnalytics.trackEvent(gaEvents.checkoutDeliveryWaySelected, { deliveryWay: origin });

      dispatch(setOrigin(origin));
    },
    [dispatch]
  );

  const isNupayPayment = useMemo(
    () => checkoutProps.paymentOption?.category === PaymentCategoryEnum.nupay,
    [checkoutProps.paymentOption?.category]
  );

  return (
    <S.Container>
      {!!hasCoupons && settings?.mm_coupon_enabled && !!settings && <CheckoutCoupon />}

      {checkoutOptionList?.map(({ type, title, options }, index) => (
        <CheckoutOption
          key={index}
          type={type}
          index={index}
          title={title}
          options={options}
          action={handleDeliveryTypeChange}
        />
      ))}

      {isDelivery && (
        <S.BottomMargin>
          <OptionHeader
            isRequired
            subtitle={false}
            isDone={!!userCartAddress}
            title={getTranslation('address.whatYourAddress')}
          />

          <S.ItemContainer>
            <AddressDeliveryInfo
              canEdit
              deliveryFee={deliveryFee}
              address={userCartAddress}
              isModalVisible={checkoutProps.showAddressModal}
              setIsModalVisible={(value): void => checkoutProps.setShowAddressModal(value)}
            />
          </S.ItemContainer>
        </S.BottomMargin>
      )}

      {settings?.mm_order_scheduling_enabled && (
        <CheckoutScheduling
          showScheduling={checkoutProps.showSchedulingModal}
          schedule={!!checkoutProps.schedulingDate && checkoutProps.schedulingDate}
          setShowScheduling={(value): void => checkoutProps.setShowSchedulingModal(value)}
          setSchedule={(value): void => checkoutProps?.setSchedulingDate && checkoutProps?.setSchedulingDate(value)}
          removeSchedule={(): void => checkoutProps.setSchedulingDate && checkoutProps?.setSchedulingDate(undefined)}
        />
      )}

      {hasUserSignUpAccess && !isUserAuthenticated && (
        <S.TopMargin>
          <S.ItemContainer>
            <LoginCallToAction />
          </S.ItemContainer>
        </S.TopMargin>
      )}

      {isUserAuthenticated && hasNameOrPhone ? (
        <S.TopMargin>
          <OptionHeader title={getTranslation('user.contactInfo')} isRequired isDone subtitle={false} />

          <UserInfo name={name} phone={clientPhone} displayEdit />
        </S.TopMargin>
      ) : (
        <S.TopMargin>
          {renderName()}
          {renderPhone()}
        </S.TopMargin>
      )}

      {shouldDisplayValidOrderCard && !isLoyaltyTemporarilyBlocked && <LoyaltyValidOrderConfirmationCard />}

      <PaymentOption title={getTranslation('payment.howPay')} isModalOpen={checkoutProps.showPaymentOptionModal} />

      <LatLngConfirmationModal
        paymentOption={checkoutProps.paymentOption}
        isModalOpen={checkoutProps.showLatLngConfirmationModal}
      />

      {(settings?.mm_receipt_ask_for_cpf || settings?.mm_receipt_required_cpf) && !isNupayPayment && <DocumentInput />}
    </S.Container>
  );
};

export default NewCheckoutOptions;
