import { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { getTermIntoString } from '@goomerdev/goomer-toolbox/src/utils';

import { isRequiredField } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import { isDelivery } from '~/utils/orderDeliveryTypes';
import useEstablishmentStatus from '~/hooks/useEstablishmentStatus';
import { PaymentMethod, FieldOptionsEnum } from '~/interfaces/general';
import useCheckoutDeliveryInfo from '~/hooks/useCheckout/hooks/useCheckoutDeliveryInfo';
import { DeliveryPricingEnum, DeliveryWayEnum, LocalOrdersEnum } from '~/interfaces/enums';

interface UseCheckoutValidateFields {
  userInfo: {
    cpf: string;
    name: string;
    clientPhone: string;
  };
  orderInfo: {
    schedulingDate?: Date;
    deliveryOption?: string;
    paymentOption?: PaymentMethod;
  };
  validations: {
    isCpfValid: boolean;
    isNameValid: boolean;
    isClientPhoneValid: boolean;
    isFullAgeTermAccept: boolean;
  };
}

export default function useCheckoutValidateFields({ userInfo, orderInfo, validations }: UseCheckoutValidateFields): {
  isSomeInputInvalid: boolean;
  isDeliveryAndMinimumValueIsNotReached: boolean | 0 | undefined;
} {
  const history = useRouter();

  const cart = useSelector((state: IApplicationState) => state.cart);
  const user = useSelector((state: IApplicationState) => state.user.data);
  const origin = useSelector((state: IApplicationState) => state.coupons.origin);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const isOpen = useSelector((state: IApplicationState) => state.establishment.open.isOpen);
  const { mode, isOperatorMode } = useSelector((state: IApplicationState) => state.localOrders);

  const [isSomeInputInvalid, setIsSomeInputInvalid] = useState<boolean>(true);

  const isTakeaway = useMemo(() => {
    return (
      getTermIntoString({ term: 'retir', word: orderInfo.deliveryOption || '' }) ||
      getTermIntoString({ term: 'takeaway', word: orderInfo.deliveryOption || '' })
    );
  }, [orderInfo.deliveryOption]);

  const { isAbleToOrder } = useEstablishmentStatus({});
  const { deliveryInfo } = useCheckoutDeliveryInfo({
    isTakeaway,
    isInStore: false,
    deliveryOption: orderInfo.deliveryOption
  });

  const isCpfFilled = useMemo(() => userInfo.cpf.replace(/\D/g, '').length === 11, [userInfo.cpf]);

  const isPaymentAndDeliveryOptionSelected = useMemo(() => {
    const { paymentOption, deliveryOption } = orderInfo;

    if (origin === DeliveryWayEnum.onsite) return true;

    return (
      typeof paymentOption === 'object' &&
      paymentOption?.category &&
      paymentOption?.category?.length > 0 &&
      paymentOption?.type &&
      paymentOption?.type?.length > 0 &&
      deliveryOption &&
      deliveryOption.length > 0
    );
  }, [orderInfo, origin]);

  const isDeliveryAndDoesNotHaveAnAddressSelected = useMemo(() => {
    return (
      isDelivery(orderInfo.deliveryOption) &&
      (!cart.address ||
        cart.deliveryFee?.pricing === DeliveryPricingEnum.outside ||
        cart.deliveryFee?.pricing === DeliveryPricingEnum.notFound)
    );
  }, [cart.address, cart.deliveryFee?.pricing, orderInfo.deliveryOption]);

  const isDeliveryAndMinimumValueIsNotReached = useMemo(() => {
    return (
      isDelivery(orderInfo.deliveryOption) &&
      settings?.mm_delivery_minimum_value_enabled &&
      deliveryInfo?.delivery_required_amount &&
      Number(deliveryInfo.delivery_required_amount) > cart.total
    );
  }, [cart.total, deliveryInfo, orderInfo.deliveryOption, settings?.mm_delivery_minimum_value_enabled]);

  const isOnlySchedulingWithoutAvailableHours = useMemo(
    () =>
      origin !== DeliveryWayEnum.onsite &&
      !!settings?.mm_order_scheduling_enabled &&
      (!isOpen || !!settings?.mm_order_scheduling_only) &&
      !orderInfo.schedulingDate,
    [
      isOpen,
      origin,
      orderInfo.schedulingDate,
      settings?.mm_order_scheduling_enabled,
      settings?.mm_order_scheduling_only
    ]
  );

  const isPhoneEnabled: boolean = useMemo(() => {
    if (origin !== DeliveryWayEnum.onsite) return true;

    return (
      mode !== LocalOrdersEnum.guestCheck &&
      isRequiredField(isOperatorMode ? FieldOptionsEnum.optional : settings?.mm_ask_for_phone)
    );
  }, [origin, mode, isOperatorMode, settings?.mm_ask_for_phone]);

  const isNameEnabled: boolean = useMemo(() => {
    if (origin !== DeliveryWayEnum.onsite) return true;

    return isRequiredField(isOperatorMode ? FieldOptionsEnum.optional : settings?.mm_ask_for_name);
  }, [origin, isOperatorMode, settings?.mm_ask_for_name]);

  useEffect(() => {
    if (history.pathname.includes('sendOrder')) {
      return setIsSomeInputInvalid(false);
    }

    const { isCpfValid, isNameValid, isClientPhoneValid, isFullAgeTermAccept } = validations;

    let allCheckoutOptionsChecked = false;

    if (isPaymentAndDeliveryOptionSelected) {
      allCheckoutOptionsChecked = true;
    }

    if ((isOpen || isAbleToOrder) && allCheckoutOptionsChecked) {
      const isCpfEnabled = settings?.mm_receipt_ask_for_cpf;

      const isFullAgeProduct = cart.products.find((product) => product.product.limit_age === true);

      let disableButton = false;

      if (isFullAgeProduct && !isFullAgeTermAccept) {
        disableButton = true;
      }

      if (isOnlySchedulingWithoutAvailableHours) {
        disableButton = true;
      }

      if (isCpfEnabled && isCpfFilled && !isCpfValid) {
        disableButton = true;
      }

      if (isPhoneEnabled && !isClientPhoneValid) {
        disableButton = true;
      }

      if (isNameEnabled && !isNameValid) {
        disableButton = true;
      }

      if (isDeliveryAndDoesNotHaveAnAddressSelected) {
        disableButton = true;
      }

      if (isDeliveryAndMinimumValueIsNotReached) {
        disableButton = true;
      }

      setIsSomeInputInvalid(disableButton);
    } else {
      setIsSomeInputInvalid(true);
    }
  }, [
    cart,
    mode,
    user,
    isOpen,
    settings,
    userInfo,
    orderInfo,
    validations,
    isCpfFilled,
    deliveryInfo,
    isAbleToOrder,
    isNameEnabled,
    isPhoneEnabled,
    history.pathname,
    isPaymentAndDeliveryOptionSelected,
    isOnlySchedulingWithoutAvailableHours,
    isDeliveryAndMinimumValueIsNotReached,
    isDeliveryAndDoesNotHaveAnAddressSelected
  ]);

  return { isSomeInputInvalid, isDeliveryAndMinimumValueIsNotReached };
}
