import { useMemo } from 'react';

import currency from 'currency.js';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { ToastTypeEnum } from '~/interfaces/enums';
import { Alert, CheckboxWithLabel } from '~/components';

import { SummaryListItem } from '../SummaryListItem';
import { PaymentSummaryProgressBar } from '../PaymentSummaryProgressBar';

import * as S from './styles';

export interface SummaryValueListProps {
  mode: string;
  valuePaid: number;
  valueLeft: number;
  isBillPaid: boolean;
  subtotalBill: number;
  serviceCharge?: number;
  serviceChargeEnabled: boolean;
  isCheckedServiceCharge: boolean;
  handleServiceCharge: () => void;
}

const SummaryValueList: (props: SummaryValueListProps) => JSX.Element = ({
  mode,
  valueLeft,
  valuePaid,
  isBillPaid,
  subtotalBill,
  serviceCharge,
  handleServiceCharge,
  serviceChargeEnabled,
  isCheckedServiceCharge
}) => {
  const { getTranslation } = useTranslator();

  const totalBill = useMemo(() => {
    if (!!serviceCharge && isCheckedServiceCharge) {
      return currency(subtotalBill).add(serviceCharge).value;
    }

    return subtotalBill;
  }, [isCheckedServiceCharge, serviceCharge, subtotalBill]);

  const serviceTaxDescription = useMemo(() => {
    if (isBillPaid) {
      return getTranslation('general.serviceTax');
    }

    return (
      <CheckboxWithLabel
        disabled={isBillPaid}
        checked={isCheckedServiceCharge}
        handleChange={handleServiceCharge}
        label={getTranslation('general.serviceTax')}
        aria-label={getTranslation('myTab.checkboxServiceTax')}
      />
    );
  }, [getTranslation, handleServiceCharge, isBillPaid, isCheckedServiceCharge]);

  return (
    <S.Container>
      <S.List>
        <SummaryListItem
          price={convertToCurrency(subtotalBill)}
          description={getTranslation('myTab.subtotalOf', { mode: getTranslation(mode).toLowerCase() })}
        />

        {!!serviceChargeEnabled && !!serviceCharge && (
          <SummaryListItem
            description={serviceTaxDescription}
            isDisabled={!isCheckedServiceCharge}
            price={convertToCurrency(serviceCharge)}
          />
        )}

        <SummaryListItem
          isStrong
          price={convertToCurrency(totalBill)}
          description={getTranslation('myTab.totalOf', { mode: getTranslation(mode).toLowerCase() })}
        />

        {!!valuePaid && !!valueLeft && (
          <PaymentSummaryProgressBar hasSeparation missingValue={valueLeft} valuePaid={valuePaid} />
        )}

        {isBillPaid && (
          <S.PaidAlertContainer>
            <Alert type={ToastTypeEnum.success} message={getTranslation('myTab.reopenAccount')} />
          </S.PaidAlertContainer>
        )}
      </S.List>
    </S.Container>
  );
};

export { SummaryValueList };
