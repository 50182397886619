import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.strong`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const Wrapper = styled.div`
  margin-top: 1rem;

  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
`;

export const RelatedCategory = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  min-height: fit-content;
  border: 1px solid ${({ theme }) => theme.colors.gray300};

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
