import styled, { css } from 'styled-components';

import * as InputStyles from '~/components/Input/Input/styles';

export const Container = styled.div<{ disabled: boolean; isGoomerColor?: boolean; error: string }>`
  ${({ error, theme, disabled, isGoomerColor }) => css`
    input {
      width: 100%;
      height: 40px;
      padding: 0 16px;
      border-radius: 4px;
      border: 1px solid ${theme.colors.gray300};

      display: flex;
      justify-content: space-between;

      cursor: text;
      font-size: 16px;
      font-weight: 500;
      background-color: ${theme.colors.backgroundColor};

      &:focus {
        border: 1px solid
          ${isGoomerColor
            ? theme.colors.primary
            : css`
                ${theme.brandColors.primary}
              `};
      }

      &::placeholder {
        color: ${theme.colors.placeholderGray};
      }

      ${disabled && InputStyles.modifiers.disabled(theme)}
      ${!!error && InputStyles.modifiers.inputError(theme)}
    }
  `}
`;
