import { useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import i18n from '~/i18n';
import { IApplicationState } from '~/redux-tools/store';
import { LanguageCodeEnum, FeaturesEnum, LocalStorageEnum } from '~/interfaces/enums';

import useHasFeatureAccess from '../useHasFeatureAccess';

export default function useLanguageDetector(): void {
  const { settings, loading: isLoading } = useSelector((state: IApplicationState) => state.establishment);

  const isAbrahaoStore = useMemo(() => settings?.is_abrahao, [settings?.is_abrahao]);
  const [hasNewDeliveryAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.NewDeliveryMenu });

  useEffect(() => {
    if (!settings || isLoading) return;

    const defineDefaultLanguage = (): void => {
      i18n.changeLanguage(LanguageCodeEnum.ptBr);
      localStorage.setItem(LocalStorageEnum.selectedLanguage, LanguageCodeEnum.ptBr);
    };

    if (!settings.mm_languages || settings.mm_languages.length === 0) {
      defineDefaultLanguage();

      return;
    }

    if (typeof localStorage !== 'undefined') {
      const localStorageSelectedLanguage = localStorage.getItem(LocalStorageEnum.selectedLanguage);

      const isLocalStorageSelectedLanguageIsEnabledByStore = settings.mm_languages.includes(
        localStorageSelectedLanguage as LanguageCodeEnum
      );

      if (!isLocalStorageSelectedLanguageIsEnabledByStore) {
        defineDefaultLanguage();

        return;
      }

      if (!localStorageSelectedLanguage || localStorageSelectedLanguage === null) {
        const navigatorLanguage: string = window.navigator.language;

        i18n.changeLanguage(navigatorLanguage);
        return;
      }

      i18n.changeLanguage(localStorageSelectedLanguage);
      return;
    }
  }, [hasNewDeliveryAccess, isAbrahaoStore, isLoading, settings]);
}
