import { useMemo } from 'react';

import br from 'date-fns/locale/pt-BR';
import { useSelector } from 'react-redux';
import { RiAlertLine } from 'react-icons/ri';
import { formatDuration, millisecondsToHours, millisecondsToMinutes } from 'date-fns';

import { IApplicationState } from '~/redux-tools/store';
import { useLoyaltyProgram, useTranslator } from '~/hooks';

import * as S from './styles';

export interface LoyaltyWarningPros {
  isSimpleCard?: boolean;
}

const LoyaltyWarning = ({ isSimpleCard }: LoyaltyWarningPros): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();
  const { countdownInSecondsToUnblockLoyalty } = useLoyaltyProgram();

  const countdownInMilliseconds = useMemo(
    () => countdownInSecondsToUnblockLoyalty * 1000,
    [countdownInSecondsToUnblockLoyalty]
  );

  const countdownInHours = useMemo(() => millisecondsToHours(countdownInMilliseconds), [countdownInMilliseconds]);
  const countdownInMinutes = useMemo(
    () => millisecondsToMinutes(countdownInMilliseconds) - countdownInHours * 60,
    [countdownInHours, countdownInMilliseconds]
  );

  const countdownFormatted = useMemo(
    () =>
      formatDuration(
        {
          hours: countdownInHours >= 1 ? countdownInHours : 0,
          minutes: countdownInMinutes < 60 ? countdownInMinutes : 0,
          seconds: countdownInSecondsToUnblockLoyalty < 60 ? countdownInSecondsToUnblockLoyalty : 0
        },
        {
          locale: br,
          delimiter: ` ${getTranslation('general.and')} `
        }
      ),
    [countdownInHours, countdownInMinutes, countdownInSecondsToUnblockLoyalty, getTranslation]
  );

  return (
    <S.Container isSimpleCard={isSimpleCard}>
      <S.SvgWrapper>
        <RiAlertLine color={theme.colors.backgroundColor} size={20} />
      </S.SvgWrapper>

      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: getTranslation('loyalty.nextOrderCanBeMadeAt', { countdown: countdownFormatted })
          }}
        />
      </div>
    </S.Container>
  );
};

export default LoyaltyWarning;
