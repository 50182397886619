import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import { all, takeLatest, put } from 'redux-saga/effects';

import verifyEmailCode from '~/services/verifyEmailCode';
import { CustomerResponse, IUser } from '~/interfaces/general';
import { generateUniqueId, setHeaderAndCookieAuthToken } from '~/utils';
import { getRegisteredUser, generateEmailVerificationCode, sendGoogleRegistrationInformation } from '~/services';
import {
  fetchCustomerInfo,
  updateCustomerInfo,
  addCustomerAddress,
  updateCustomerAddress,
  deleteCustomerAddress
} from '~/services/customerInfo';

import { UserActionTypes } from './types';
import {
  setCustomerInfo,
  verifyEmailCodeError,
  getRegisteredUserError,
  verifyEmailCodeSuccess,
  fetchCustomerInfoError,
  updateCustomerInfoError,
  addCustomerAddressError,
  getRegisteredUserSuccess,
  updateCustomerInfoSuccess,
  addCustomerAddressSuccess,
  updateCustomerAddressError,
  deleteCustomerAddressError,
  sendGoogleRegistrationError,
  updateCustomerAddressSuccess,
  deleteCustomerAddressSuccess,
  sendGoogleRegistrationSuccess,
  generateEmailVerificationCodeError,
  generateEmailVerificationCodeSuccess
} from './actions';

export function* getRegisteredUserRequest({ payload }: AnyAction) {
  const { mode, param, functions, getTranslation } = payload;
  const { addErrorLog, afterAction } = functions;

  try {
    const response: AxiosResponse = yield getRegisteredUser({
      mode,
      param,
      addErrorLog,
      getTranslation
    });

    const isNewUser: boolean = response.status === 404;
    const isAnAnonymousUser = !response?.data?.is_already_registered;
    const maskedEmail: string = response?.data?.masked_email;

    afterAction(isNewUser, isAnAnonymousUser, maskedEmail);

    yield put(getRegisteredUserSuccess());
  } catch (error) {
    yield put(getRegisteredUserError((error as Error)?.message));
  }
}

export function* generateEmailVerificationCodeRequest({ payload }: AnyAction) {
  const { utm, email, resend, functions } = payload;
  const { addErrorLog, afterAction } = functions;

  try {
    const response: AxiosResponse = yield generateEmailVerificationCode({
      utm,
      email,
      resend,
      addErrorLog
    });

    if (response.data.error) {
      yield put(generateEmailVerificationCodeError(response.data.content.code));
      return afterAction(false, response.data.content.code);
    }

    const emailVerificationId = response.data.content.operation_id || '';

    afterAction(emailVerificationId);
    yield put(generateEmailVerificationCodeSuccess());
  } catch (error) {
    afterAction(false, (error as Error & { code: string }).code);
    yield put(generateEmailVerificationCodeError((error as Error)?.message));
  }
}

export function* sendGoogleRegistrationInformationRequest({ payload }: AnyAction) {
  const { name, phone, birthday, functions, googleToken } = payload;
  const { addErrorLog, afterAction } = functions;

  try {
    const response: AxiosResponse = yield sendGoogleRegistrationInformation({
      name,
      phone,
      birthday,
      addErrorLog,
      googleToken
    });

    if (response.data.error) {
      yield put(sendGoogleRegistrationError(response.data.code));
      return afterAction(false, response.data.code);
    }

    afterAction(response.data.token || '');
    yield put(sendGoogleRegistrationSuccess());
  } catch (error) {
    afterAction(false, (error as Error & { code: string }).code);
    yield put(sendGoogleRegistrationError((error as Error)?.message));
  }
}

export function* verifyEmailCodeRequest({ payload }: AnyAction) {
  const { code, name, token, phone, birthday, functions, isNewUser } = payload;
  const { addErrorLog, afterAction } = functions;

  try {
    const response: AxiosResponse = yield verifyEmailCode({
      code,
      name,
      token,
      phone,
      birthday,
      isNewUser,
      addErrorLog
    });

    if (response.data.token) {
      setHeaderAndCookieAuthToken({ token: response.data.token });
    }

    afterAction({
      goomerToken: response.data.token
    });

    yield put(verifyEmailCodeSuccess());
  } catch (error) {
    afterAction({ goomerToken: undefined });
    yield put(verifyEmailCodeError((error as Error)?.message));
  }
}

export function* fetchCustomerInfoRequest({ payload }: AnyAction) {
  const { token, storeId } = payload;

  setHeaderAndCookieAuthToken({ token: token });

  try {
    const { customer } = yield fetchCustomerInfo({
      token,
      storeId
    });

    const response = customer as CustomerResponse;

    const formattedPayload: IUser = {
      name: response.name,
      email: response.email,
      optIn: response.opt_in,
      phone: response.phone_number,
      addresses: response.addresses,
      isFirstOrder: response.is_first_order,
      birthday: response.birthday?.substring(0, 10),
      customerLoyaltyProgram: response.customer_loyalty_program
    };

    if (token && token.length > 0) {
      formattedPayload.isAuthenticated = true;
      formattedPayload.authenticationToken = token;
    }

    yield put(setCustomerInfo(formattedPayload));
  } catch (error) {
    setHeaderAndCookieAuthToken({ token: undefined });

    console.error((error as Error)?.message);
    yield put(fetchCustomerInfoError((error as Error)?.message));
  }
}

export function* updateCustomerInfoRequest({ payload }: AnyAction) {
  const { name, optIn, storeId, birthday, functions } = payload;
  const { addErrorLog, afterAction } = functions;

  try {
    const { customer } = yield updateCustomerInfo({
      name,
      optIn,
      storeId,
      birthday
    });

    const response = customer as CustomerResponse;

    const formattedPayload: IUser = {
      name: response.name,
      email: response.email,
      optIn: response.opt_in,
      phone: response.phone_number,
      birthday: response.birthday?.substring(0, 10)
    };

    afterAction();

    yield put(updateCustomerInfoSuccess(formattedPayload));
  } catch (error) {
    addErrorLog();

    console.error((error as Error)?.message);
    yield put(updateCustomerInfoError((error as Error)?.message));
  }
}

export function* addCustomerAddressRequest({ payload }: AnyAction) {
  const { address, storeId, functions, isUserAuthenticated } = payload;
  const { addErrorLog, afterAction } = functions;

  if (!isUserAuthenticated) {
    address.id = generateUniqueId();

    afterAction(address);

    yield put(addCustomerAddressSuccess(address));
    return;
  }

  try {
    const { customer_address } = yield addCustomerAddress({
      storeId,
      address
    });

    afterAction(customer_address);

    yield put(addCustomerAddressSuccess(customer_address));
  } catch (error) {
    addErrorLog();

    console.error((error as Error)?.message);
    yield put(addCustomerAddressError());
  }
}

export function* updateCustomerAddressRequest({ payload }: AnyAction) {
  const { address, storeId, functions, isUserAuthenticated } = payload;
  const { addErrorLog, afterAction } = functions;

  if (!isUserAuthenticated) {
    afterAction(address);

    yield put(updateCustomerAddressSuccess(address));
    return;
  }

  try {
    const { customer_address } = yield updateCustomerAddress({
      storeId,
      address
    });

    afterAction(customer_address);

    yield put(updateCustomerAddressSuccess(customer_address));
  } catch (error) {
    addErrorLog();

    console.error((error as Error)?.message);
    yield put(updateCustomerAddressError());
  }
}

export function* deleteCustomerAddressRequest({ payload }: AnyAction) {
  const { storeId, functions, addressId, isUserAuthenticated } = payload;
  const { addErrorLog, afterAction } = functions;

  if (!isUserAuthenticated) {
    afterAction();

    yield put(deleteCustomerAddressSuccess(addressId));
    return;
  }

  try {
    yield deleteCustomerAddress({ storeId, addressId });

    afterAction();

    yield put(deleteCustomerAddressSuccess(addressId));
  } catch (error) {
    addErrorLog();

    console.error((error as Error)?.message);
    yield put(deleteCustomerAddressError());
  }
}

export default all([
  takeLatest(UserActionTypes.GET_REGISTERED_USER_REQUEST, getRegisteredUserRequest),
  takeLatest(UserActionTypes.GENERATE_EMAIL_CODE_REQUEST, generateEmailVerificationCodeRequest),
  takeLatest(UserActionTypes.GOOGLE_REGISTRATION_REQUEST, sendGoogleRegistrationInformationRequest),
  takeLatest(UserActionTypes.VERIFY_EMAIL_CODE_REQUEST, verifyEmailCodeRequest),
  takeLatest(UserActionTypes.FETCH_CUSTOMER_INFO_REQUEST, fetchCustomerInfoRequest),
  takeLatest(UserActionTypes.UPDATE_CUSTOMER_INFO_REQUEST, updateCustomerInfoRequest),
  takeLatest(UserActionTypes.ADD_CUSTOMER_ADDRESS_REQUEST, addCustomerAddressRequest),
  takeLatest(UserActionTypes.UPDATE_CUSTOMER_ADDRESS_REQUEST, updateCustomerAddressRequest),
  takeLatest(UserActionTypes.DELETE_CUSTOMER_ADDRESS_REQUEST, deleteCustomerAddressRequest)
]);
