/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const Container = styled.div<{ isFirstOrderDiscount?: boolean }>`
  ${({ theme, isFirstOrderDiscount }) => css`
    width: 100%;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 1px solid ${theme.colors.gray300};

    gap: 0.5rem;
    display: flex;

    cursor: ${isFirstOrderDiscount ? 'auto' : 'pointer'};
    background: ${theme.colors.backgroundColor};

    > svg {
      path {
        fill: ${theme.colors.mediumGray};
      }
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;

    gap: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .link-like {
      font-weight: 600;
      line-height: 150%;
      font-size: 0.875rem;
      text-decoration: underline;
      color: ${theme.brandColors.primary};
    }
  `}
`;

export const IconWrapper = styled.div<{ $isAction?: boolean; $isDelete?: boolean }>`
  ${({ theme, $isAction, $isDelete }) => css`
    margin: auto 0;

    display: flex;

    svg {
      width: 1.25rem;
      height: 1.25rem;

      path {
        fill: ${theme.colors.mediumGray};
      }
    }

    ${($isDelete || $isAction) &&
    css`
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    `}

    ${$isDelete &&
    css`
      svg {
        path {
          fill: ${theme.colors.error};
        }
      }
    `}
  `}
`;
