import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 100%;

    background-color: ${theme.colors.gray200};
  `};
`;

export const Content = styled.div`
  height: calc(100vh - 3.5rem);

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    height: 3.5rem;
    border-bottom: 1px solid ${theme.colors.gray300};

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.backgroundColor};

    span {
      font-weight: 700 !important;
      font-size: 15px !important;
      line-height: 24px !important;
      color: ${theme.colors.textColor};
    }
  `};
`;

export const ArrowWrapper = styled.div`
  top: 0px;
  left: 0px;
  position: absolute;

  width: 3.5rem;
  height: 3.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
