export default function isEventCodeSpecialChar(eventCode: string) {
  return (
    eventCode === 'Comma' ||
    eventCode === 'Period' ||
    eventCode === 'KeyE' ||
    eventCode === 'NumpadDecimal' ||
    eventCode === 'NumpadSubtract' ||
    eventCode === 'NumpadAdd' ||
    eventCode === 'Equal' ||
    eventCode === 'Minus'
  );
}
