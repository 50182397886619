import { useMemo } from 'react';

import { useTranslator } from '~/hooks';

export default function useWeekdays(): {
  weekdaysList: string[];
  weekdayValueList: string[];
  shortWeekdaysList: string[];
} {
  const { getTranslation } = useTranslator();

  // Não traduzir -> valores vem do back-end e são códigos, não usados em exibição
  const weekdayValueList = useMemo(() => ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'], []);

  const weekdaysList = useMemo(
    () => [
      getTranslation('weekdays.sunday').toLowerCase(),
      getTranslation('weekdays.monday').toLowerCase(),
      getTranslation('weekdays.tuesday').toLowerCase(),
      getTranslation('weekdays.wednesday').toLowerCase(),
      getTranslation('weekdays.thursday').toLowerCase(),
      getTranslation('weekdays.friday').toLowerCase(),
      getTranslation('weekdays.saturday').toLowerCase()
    ],
    [getTranslation]
  );

  const shortWeekdaysList = useMemo(
    () => [
      getTranslation('shortWeekdays.sunday'),
      getTranslation('shortWeekdays.monday'),
      getTranslation('shortWeekdays.tuesday'),
      getTranslation('shortWeekdays.wednesday'),
      getTranslation('shortWeekdays.thursday'),
      getTranslation('shortWeekdays.friday'),
      getTranslation('shortWeekdays.saturday')
    ],
    [getTranslation]
  );

  return { weekdaysList, weekdayValueList, shortWeekdaysList };
}
