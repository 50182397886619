import React, { useContext } from 'react';

import { useSelector } from 'react-redux';
import { RiMapPinLine } from 'react-icons/ri';

import { mountAddress } from '~/utils';
import { Address } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';

import { AddressFormContext } from '../..';

import { Container } from './styles';

interface AddressInfoProps {
  address: Address;
  onClick?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const AddressInfo: React.FC<AddressInfoProps> = ({ address, onClick = (): void => {} }) => {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { street } = useContext(AddressFormContext);

  return (
    <Container onClick={(): void => onClick()} data-test="address-item">
      <div className="content">
        <RiMapPinLine size={24} />

        <div className="info">
          <strong>{`${address.street}${address.number ? `, ${address.number}` : ''}`}</strong>

          <small>
            {mountAddress({
              address,
              settings,
              summary: true,
              withCep: true,
              withStreet: !!street
            })}
          </small>
        </div>
      </div>
    </Container>
  );
};

export default AddressInfo;
