export interface GenerateTunaCheckoutUserInfoProps {
  clientName: string;
  clientPhone: string;
  clientEmail?: string;
}

function getPhoneData(clientPhone: string) {
  if (clientPhone.length === 15 || clientPhone.length === 14) {
    return {
      phoneNumber: clientPhone.substring(clientPhone.length - 10, clientPhone.length).replace('-', ''),
      phonePrefix: clientPhone.substring(1, 3)
    };
  }

  return {
    phoneNumber: clientPhone,
    phonePrefix: ''
  };
}

export default function generateTunaCheckoutUserInfo({
  clientName,
  clientPhone,
  clientEmail
}: GenerateTunaCheckoutUserInfoProps) {
  const firstName = clientName.split(' ').shift() || '';
  const lastName = clientName.substring(firstName.length, clientName.length).trim();

  const phoneInfo = getPhoneData(clientPhone);

  return {
    firstName,
    lastName,
    email: clientEmail,
    phone: phoneInfo
  };
}
