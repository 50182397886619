import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import { RiBarcodeBoxLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from '@goomerdev/goomer-toolbox/src/hooks';

import Button from '~/components/Button/Button';
import { changeTableRequest } from '~/services';
import { useTabInfo, useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { QrCodeHashReaderResult } from '~/interfaces/general';
import QrCodeScannerModal from '~/components/QrCodeScannerModal';
import { OrderModeEnum, LocalOrdersEnum } from '~/interfaces/enums';
import { addCheckIn } from '~/redux-tools/store/localOrders/actions';

import * as S from './styles';

interface ChangeOrderClientProps {
  isOnCart?: boolean;
  onScannerClose?: () => void;
  isLoadingTable?: boolean;
  displaySimpleLayout?: boolean;
  setIsLoadingTable?: (value: boolean) => void;
}

const ChangeOrderClient = ({
  isOnCart,
  onScannerClose,
  setIsLoadingTable,
  isLoadingTable = false,
  displaySimpleLayout = false
}: ChangeOrderClientProps): JSX.Element => {
  const dispatch = useDispatch();

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const localOrders = useSelector((state: IApplicationState) => state.localOrders);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const [shouldDisplayQrCodeScannerModal, setShouldDisplayQrCodeScannerModal] = useState<boolean>(false);

  const isAbrahaoStore = useMemo(() => settings?.is_abrahao || false, [settings?.is_abrahao]);

  const tabInfo = useTabInfo();
  const { getTranslation } = useTranslator();
  const { localStorageValue } = useLocalStorage({ key: 'qrCodeHashReader' });

  const qrCodeHashReader: QrCodeHashReaderResult = useMemo(
    () => JSON.parse(localStorageValue || '{}') as QrCodeHashReaderResult,
    [localStorageValue]
  );

  const isQrCodeHashRequired: boolean = useMemo(
    () =>
      !!isAbrahaoStore && settings?.mm_order_mode == OrderModeEnum.Tab && localOrders.mode === LocalOrdersEnum.table,
    [isAbrahaoStore, localOrders.mode, settings?.mm_order_mode]
  );

  const renderText = useMemo(() => {
    if (isQrCodeHashRequired) {
      return `${getTranslation(LocalOrdersEnum.guestCheck)} ${tabInfo?.number || ''}`;
    }

    return `${getTranslation(localOrders.mode)} ${localOrders.number}`;
  }, [getTranslation, isQrCodeHashRequired, localOrders.mode, localOrders.number, tabInfo?.number]);

  const onScannerRead = useCallback(
    async (readResult: string) => {
      if (localOrders.mode !== LocalOrdersEnum.table || settings?.mm_order_mode === OrderModeEnum.Tab) {
        return;
      }

      const onError = () => toast.error(getTranslation('errorMessage.errorGettingTableInfo'));

      const tableCode = readResult.split('/').pop();

      if (!tableCode) {
        onError();
        return;
      }

      setIsLoadingTable?.(true);

      await changeTableRequest(tableCode, readResult, settings?.mm_a_id)
        .then((tableInfo) => {
          const params = {
            ...tableInfo,
            hash: tableCode,
            mode: LocalOrdersEnum.table
          };

          dispatch(addCheckIn({ ...params, date: new Date() }));
        })
        .catch(() => {
          return onError();
        })
        .finally(() => {
          setIsLoadingTable?.(false);
        });

      return;
    },
    [dispatch, getTranslation, localOrders.mode, setIsLoadingTable, settings?.mm_a_id, settings?.mm_order_mode]
  );

  useEffect(() => {
    if (!isOnCart && isQrCodeHashRequired && !qrCodeHashReader?.result) {
      setShouldDisplayQrCodeScannerModal(true);
    }
  }, [isOnCart, isQrCodeHashRequired, qrCodeHashReader?.result]);

  return (
    <>
      <S.Container $displaySimpleLayout={displaySimpleLayout} $isOnCart={isOnCart}>
        <S.TabInfo>
          <RiBarcodeBoxLine size={26} color={theme.colors.gray500} />

          <p>{renderText}</p>
        </S.TabInfo>

        {localOrders.mode === LocalOrdersEnum.table && (
          <div>
            <Button
              isSimple
              isLoading={isLoadingTable}
              isDisabled={isLoadingTable}
              onClick={(): void => setShouldDisplayQrCodeScannerModal(true)}
            >
              {getTranslation('general.change')}
            </Button>
          </div>
        )}
      </S.Container>

      {!!shouldDisplayQrCodeScannerModal && (
        <QrCodeScannerModal
          onConfirm={onScannerRead}
          onClose={(): void => {
            onScannerClose?.();
            setShouldDisplayQrCodeScannerModal(false);
          }}
        />
      )}
    </>
  );
};

export default ChangeOrderClient;
