import React from 'react';

import { useTranslator } from '~/hooks';
import { Button, Modal } from '~/components';

import * as S from './styles';

export interface LoyaltyRulesModalProps {
  dueDate: number;
  isOpen: boolean;
  onClose: () => void;
  channelNames: string;
  description?: string;
  expirationDate: string;
  handleSignIn: () => void;
  isAuthenticated: boolean;
  orderIntervalHours: number;
}

const LoyaltyRulesModal: React.FC<LoyaltyRulesModalProps> = ({
  isOpen,
  onClose,
  dueDate,
  description,
  handleSignIn,
  channelNames,
  expirationDate,
  isAuthenticated,
  orderIntervalHours
}) => {
  const { getTranslation } = useTranslator();

  return (
    <S.Container>
      <Modal
        isPageLike
        isShow={isOpen}
        onClose={onClose}
        isWithoutPadding
        aria-label={getTranslation('general.loyalty')}
        headerTitle={getTranslation('loyalty.loyaltyProgram')}
      >
        <S.Menu>
          {description && <S.SectionTitle>{description}</S.SectionTitle>}

          <S.InfoSection>
            <S.Subtitle>{getTranslation('payment.expiryDate')}</S.Subtitle>

            <p>
              {getTranslation('loyalty.completeUntil')} {expirationDate}
            </p>
          </S.InfoSection>

          <S.RulesWrapper>
            <S.Section>
              <S.Subtitle>{getTranslation('general.rules')}</S.Subtitle>

              <S.RulesList>
                <S.RulesItem>{getTranslation('loyalty.autoDiscount', { days: dueDate })}</S.RulesItem>

                <S.RulesItem>{getTranslation('loyalty.discountNotCumulative')}</S.RulesItem>

                <S.RulesItem>{getTranslation('loyalty.purchasesValidInProgram')}</S.RulesItem>

                <S.RulesItem>{getTranslation('loyalty.useLoyaltyDiscount')}</S.RulesItem>

                <S.RulesItem>{getTranslation('loyalty.onlyValidOrders')}</S.RulesItem>

                <S.RulesItem>
                  {getTranslation('loyalty.onlyValidOrdersInIntervalHours', {
                    orderIntervalHours: orderIntervalHours,
                    hours: orderIntervalHours === 1 ? getTranslation('loyalty.hour') : getTranslation('loyalty.hours')
                  })}
                </S.RulesItem>

                <S.RulesItem>{getTranslation('loyalty.validOrdersBy', { channelNames })}</S.RulesItem>
              </S.RulesList>
            </S.Section>
          </S.RulesWrapper>

          {isAuthenticated ? (
            <S.ButtonsWrapper>
              <Button onClick={onClose} type="button" isGhost>
                {getTranslation('general.gotIt')}
              </Button>
            </S.ButtonsWrapper>
          ) : (
            <S.ButtonsWrapper>
              <Button onClick={handleSignIn} type="button" isGhost={false}>
                {getTranslation('general.enterAndParticipate')}
              </Button>

              <Button onClick={onClose} type="button" isGhost>
                {getTranslation('navigation.returnToMenu')}
              </Button>
            </S.ButtonsWrapper>
          )}
        </S.Menu>
      </Modal>
    </S.Container>
  );
};

export default LoyaltyRulesModal;
