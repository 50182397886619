import { useEffect } from 'react';

interface UseVisibilityObserverProps {
  querySelectorName: string;
  onHidden: (state: boolean) => void;
}

export default function useVisibilityObserver({ onHidden, querySelectorName }: UseVisibilityObserverProps): void {
  useEffect(() => {
    const element = document.querySelector(querySelectorName);
    if (!element) return;

    let isHidden = false;

    const observer = new IntersectionObserver(
      ([entry]) => {
        isHidden = !entry.isIntersecting;
        if (isHidden) {
          onHidden(true);
        } else {
          onHidden(false);
        }
      },
      {
        root: null, // Usa a viewport do navegador como referência
        rootMargin: '0px', // Ajuste para considerar margens extras
        threshold: 0.1 // Considera visível se pelo menos 10% do elemento estiver na tela
      }
    );

    observer.observe(element);

    // Evento de scroll para verificar visibilidade manualmente
    const handleScroll = (): void => {
      const rect = element.getBoundingClientRect();
      const isCurrentlyHidden = rect.bottom < 0 || rect.top > window.innerHeight;

      if (isCurrentlyHidden !== isHidden) {
        isHidden = isCurrentlyHidden;
        onHidden(isHidden);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onHidden, querySelectorName]);
}
