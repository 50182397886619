import { Reducer } from 'redux';

import { MountedOrder } from '~/interfaces/general';

import { OrderStatusActionTypes, IOrderStatusState } from './types';

const initialState = {
  order: {} as MountedOrder,
  inProgress: false
};

const LocalOrdersReducer: Reducer<IOrderStatusState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OrderStatusActionTypes.ADD_ORDER:
      return {
        ...state,
        order: payload,
        inProgress: true
      };

    case OrderStatusActionTypes.FINISH_ORDER:
      return {
        ...state,
        inProgress: false
      };

    case OrderStatusActionTypes.CLEAN_ORDER:
      return {
        ...state,
        order: {} as MountedOrder,
        inProgress: false
      };

    default:
      return state;
  }
};

export default LocalOrdersReducer;
