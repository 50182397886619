export default {
  lightColors: {
    primary300: '#F9F7FF',
    primary400: '#CABFFF',
    primary: '#2B00FF',
    primary600: '#150080',
    primary700: '#0B0040',

    secondary300: '#F6F8FE',
    secondary400: '#C6E0FF',
    secondary: '#1D84FF',
    secondary600: '#0E4280',
    secondary700: '#072140',

    tertiary300: '#F8F7FF',
    tertiary400: '#D6D3FF',
    tertiary: '#5C50FF',
    tertiary600: '#2E2880',
    tertiary700: '#171440',

    quaternary300: '#FFF9F0',
    quaternary400: '#FFEED6',
    quaternary: '#FF9700',
    quaternary600: '#BD7000',
    quaternary700: '#382100',

    auxiliaryChilli300: '#FFF9F7',
    auxiliaryChilli400: '#FDD2C9',
    auxiliaryChilli: '#F74926',
    auxiliaryChilli600: '#7C2513',
    auxiliaryChilli700: '#3E120A',

    auxiliaryCheese300: '#FFFCF7',
    auxiliaryCheese400: '#FFE5BF',
    auxiliaryCheese: '#FF9700',
    auxiliaryCheese600: '#804C00',
    auxiliaryCheese700: '#402600',

    default: '#00BDD8',

    success400: '#BCEDE1',
    success: '#44CCAA',
    success600: '#227E67',
    success700: '#103B31',

    error400: '#FCD4D1',
    error: '#F44336',
    error600: '#C4170B',
    error700: '#821007',

    warning400: '#FFF2C8',
    warning: '#FFD166', // fora do tema
    warning600: '#AA8000',
    warning700: '#4E3B00',

    info400: '#CEF9FF',
    info: '#00BDD8',
    info600: '#008396',
    info700: '#004953',

    backgroundColor: '#fff',
    textColor: '#30333C',
    pureTextColor: '#000000',

    gray100: '#F8F9FA',
    gray200: '#F3F5F7',
    gray300: '#DDE2EB',
    gray400: '#C6CDD4',
    gray500: '#7F8F9F',
    gray600: '#485460',
    gray700: '#30333C',

    placeholderGray: '#c6cdd4',
    lightGray: '#E8EAED',
    lightestGray: '#F3F5F7',
    mediumGray: '#7F8F9F',
    darkGray: '#485460'
  },
  darkColors: {
    primary300: '#150080',
    primary400: '#0B0040',
    primary: '#2B00FF',
    primary700: '#F9F7FF',
    primary600: '#CABFFF',

    secondary300: '#0E4280',
    secondary400: '#072140',
    secondary: '#1D84FF',
    secondary600: '#F6F8FE',
    secondary700: '#C6E0FF',

    tertiary300: '#2E2880',
    tertiary400: '#171440',
    tertiary: '#5C50FF',
    tertiary600: '#F8F7FF',
    tertiary700: '#D6D3FF',

    quaternary300: '#BD7000',
    quaternary400: '#382100',
    quaternary: '#FF9700',
    quaternary600: '#FFF9F0',
    quaternary700: '#FFEED6',

    auxiliaryChilli300: '#7C2513',
    auxiliaryChilli400: '#3E120A',
    auxiliaryChilli: '#F74926',
    auxiliaryChilli600: '#FFF9F7',
    auxiliaryChilli700: '#FDD2C9',

    auxiliaryCheese300: '#804C00',
    auxiliaryCheese400: '#402600',
    auxiliaryCheese: '#FF9700',
    auxiliaryCheese600: '#FFFCF7',
    auxiliaryCheese700: '#FFE5BF',

    default: '#00BDD8',

    success400: '#BCEDE1',
    success: '#44CCAA',
    success600: '#227E67',
    success700: '#103B31',

    error400: '#FCD4D1',
    error: '#F44336',
    error600: '#C4170B',
    error700: '#821007',

    warning400: '#FFF2C8',
    warning: '#FFD166', // fora do tema
    warning600: '#AA8000',
    warning700: '#4E3B00',

    info400: '#CEF9FF',
    info: '#00BDD8',
    info600: '#008396',
    info700: '#004953',

    backgroundColor: '#30333C',
    textColor: '#F6F7F9',
    pureTextColor: '#fff',

    gray100: '#F8F9FA', // fazer
    gray200: '#191C25',
    gray300: '#3D424F',
    gray400: '#3D424F',
    gray500: '#DDE2EB',
    gray600: '#D7DAE0',
    gray700: '#D7DAE0',

    placeholderGray: '#B4B9C5',
    lightGray: '#3D424F',
    lightestGray: '#191C25',
    mediumGray: '#B4B9C5', // discutir
    darkGray: '#B4B9C5'
  },
  brandColors: {
    actionText: '#fff' as string,
    primary: '#2B00FF' as string,
    originalPrimary: '#2B00FF' as string,
    actionBackground: '#2B00FF' as string
  }
} as const;
