import styled, { css } from 'styled-components';

interface BackgroundProps {
  isShow: boolean;
  isSmall: boolean;
}

interface HeaderProps {
  isPageLike: boolean;
}

interface ContainerProps extends BackgroundProps {
  height: string;
  isPageLike: boolean;
  isWithoutPadding: boolean;
  titleBackgroundColor?: string;
  containerBackgroundColor?: string;
  isFooterWithGoomerColor?: boolean;
}

export const Background = styled.div<BackgroundProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -10;

  display: flex;

  opacity: 0;
  transition: 0.3s;
  visibility: hidden;
  text-align: center;
  background-color: rgba(48, 51, 60, 0.7);

  ${({ isShow }) =>
    isShow &&
    css`
      z-index: 1000;

      opacity: 1;
      visibility: visible;
    `}
`;

export const ContainerDrawer = styled.div<ContainerProps>`
  ${({
    theme,
    isShow,
    height,
    isSmall,
    isPageLike,
    isWithoutPadding,
    titleBackgroundColor,
    containerBackgroundColor
  }) => css`
    position: fixed;
    left: 0;
    right: 0;
    bottom: -1000px;

    width: 100%;
    margin: 0 auto;
    max-width: 800px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: ${isSmall ? 'auto' : height};
    padding: ${isWithoutPadding ? 0 : '1.5rem'} 0;

    background-color: ${containerBackgroundColor || theme.colors.backgroundColor};

    transition: 0.5s;
    overflow: ${isPageLike ? 'auto' : 'unset'};

    ${
      isShow &&
      css`
        bottom: 0;
      `
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      line-height: 120%;
    }

    > .go-back {
      position: absolute;
      top: -24px;
      left: 24px;

      width: 62px;
      height: 34px;
      border: none;
      border-radius: 50%;
      border-radius: 100px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.33);

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${theme.colors.backgroundColor};
    }

    .times-btn {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 10;

      width: 15px;
      height: 15px;
      border: none;

      background-color: transparent;

      svg {
        path {
          fill ${theme.colors.textColor}
        }
      }
    }

    > .title {
      padding: 8px 0 20px;

      font-size: 19px;
      font-weight: bold;
      line-height: 23px;
      text-align: center;
      background-color: ${titleBackgroundColor || 'transparent'};
    }

    > footer {
      position: fixed;
      bottom: 0;

      width: 100%;
      height: 70px;
      max-width: 800px;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${theme.colors.backgroundColor};

      > button {
        margin: 0 16px;
      }
    }

    ${
      isPageLike &&
      css`
        height: 100%;

        border-radius: 0;
        padding-top: 0;

        > .title {
          padding: 13px 0 12px;

          font-weight: 800;
          text-align: center;
          font-size: 1.125rem;
          line-height: 1.68rem;
        }

        > .go-back {
          position: absolute;
          top: 7px;
          left: 0px;

          width: 57px;
          height: 34px;
          border: none;
          border-radius: 50%;
          border-radius: 100px;
          box-shadow: none;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      `
    }
  `}
`;

export const ContentDrawer = styled.div<{ isSmall: boolean; isContentFullPage: boolean; isPageLike: boolean }>`
  ${({ isSmall, isPageLike, isContentFullPage }) => css`
    position: relative;

    max-height: ${isSmall ? '70vh' : 'auto'};

    overflow: auto;
    transition: 0.3s;

    ${isContentFullPage &&
    css`
      height: ${isPageLike ? 'calc(100% - 3.5rem)' : '100%'};
    `};
  `}
`;

export const ContainerModal = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);

    max-height: 80vh;
    min-height: 400px;
    border-radius: 5px;
    padding: 24px 16px;
    width: calc(100% - 32px);

    background-color: ${theme.colors.backgroundColor};

    .close-btn {
      position: absolute;
      top: 14px;
      right: 14px;

      width: auto;
      border: none;

      background-color: transparent;

      > span {
        margin: 0;
      }
    }
  `}
`;

export const ContentModal = styled.div``;

export const SmallClose = styled.div`
  top: 0.5rem;
  right: 0.5rem;
  position: absolute;
`;

export const Header = styled.div<{ $isNotPage: boolean }>`
  ${({ theme, $isNotPage }) => css`
    height: 3.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.backgroundColor};
    border-bottom: 1px solid ${theme.colors.lightGray};

    > button {
      left: 0;
      position: absolute;
    }

    > h3 {
      margin: 0;
      padding: 0 2rem;

      font-size: 1rem;
      font-weight: 800;
      line-height: 150%;
    }

    ${$isNotPage &&
    css`
      height: fit-content;
      border-bottom: none;

      > h3 {
        font-size: 1.125rem;
      }
    `}
  `}
`;

export const HeaderWithImage = styled.header<HeaderProps>`
  color: ${({ theme }) => theme.colors.backgroundColor};
  background-color: ${({ theme }) => theme.brandColors.primary};

  h3 {
    color: ${({ theme }) => (theme.isLightBrandColor ? theme.brandColors.primary : theme.colors.backgroundColor)};
  }

  button {
    position: absolute;
    top: 0px;
    left: 0px;

    border: none;
    width: 3.5rem;
    height: 3.5rem;

    background-color: none;
  }

  .title-wrapper {
    width: 100%;
  }

  .top-header {
    min-height: 3.5rem;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ${({ isPageLike }) =>
      isPageLike &&
      css`
        width: 100%;
        padding: 13px;

        display: flex;
        flex-direction: column;
      `}
  }
`;
