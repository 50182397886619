import styled, { css, DefaultTheme } from 'styled-components';

export const PreContainer = styled.div`
  position: relative;
`;

export const Info = styled.p`
  ${({ theme }) => css`
    margin: 0;

    font-size: 0.75rem;
    font-weight: 500;
    line-height: 150%;
    color: ${theme.colors.mediumGray};
  `}
`;

interface TextProps {
  isLinkLike: boolean;
}

const textModifiers = {
  isLinkLike: (theme: DefaultTheme) => css`
    text-decoration: underline;
    color: ${theme.brandColors.primary};
  `
};

export const Text = styled.span<TextProps>`
  ${({ theme, isLinkLike }) =>
    css`
      font-weight: 600;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.textColor};

      ${isLinkLike && textModifiers.isLinkLike(theme)};
    `}
`;

export const ModalContent = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;

  padding: 1rem 1rem 0;
`;
