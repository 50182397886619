import React, { useState, useCallback } from 'react';

import { useRouter } from 'next/router';
import { RiArrowRightSLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { Highlight } from '~/interfaces';
import { FeedbackModalTypeEnum } from '~/interfaces/enums';

import FeedbackModal from '../Modal/FeedbackModal';

import * as S from './styles';

export interface HighlightCardProps extends Highlight {
  id: string;
  isSingle?: boolean;
  isAvailable: boolean;
}

const HighlightCard: ({
  id,
  name,
  image,
  product,
  isSingle,
  description,
  isAvailable
}: HighlightCardProps) => JSX.Element = ({ id, name, image, product, isSingle, isAvailable, description }) => {
  const history = useRouter();

  const [shouldDisplayProductUnavailableModal, setShouldDisplayProductUnavailableModal] = useState(false);

  const { getTranslation } = useTranslator();

  const handleAction = useCallback(() => {
    if (!product?.id) {
      return;
    }

    if (!isAvailable) {
      setShouldDisplayProductUnavailableModal(true);
      return;
    }

    history.push(`/detail/${product.id}`);
  }, [history, isAvailable, product?.id]);

  return (
    <S.Container
      id={id}
      $isSingle={isSingle}
      onClick={handleAction}
      $imageUrl={image?.medium}
      $hasAction={!!product?.id}
    >
      <S.ContentWrapper>
        <S.Info>
          {name && <h3>{name}</h3>}

          {description && <p className="desc">{description}</p>}
        </S.Info>

        {!!product?.id && (
          <S.Action aria-label={getTranslation('label.highlightAction')}>
            <RiArrowRightSLine size={24} />
          </S.Action>
        )}
      </S.ContentWrapper>

      <FeedbackModal
        hideAnimation
        whichTypeOfFeedback={FeedbackModalTypeEnum.Info}
        description={getTranslation('product.currentlyUnavailable')}
        shouldDisplayFeedbackModal={shouldDisplayProductUnavailableModal}
        actions={[{ isGhost: true, text: getTranslation('general.close') }]}
        onClose={(): void => setShouldDisplayProductUnavailableModal(false)}
        title={`${getTranslation('general.product')} ${getTranslation('$t(product.currentlyUnavailable, lowercase)')}`}
      />
    </S.Container>
  );
};

export default HighlightCard;
