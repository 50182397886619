import styled, { css } from 'styled-components';

export const Input = styled.input<{ hasError?: boolean }>`
  ${({ theme, disabled, hasError }) => css`
    height: 40px;
    padding: 0 16px;
    min-width: 200px;
    margin: 12px 16px;
    border-radius: 4px;
    width: calc(100% - 32px);
    border: 1px solid ${theme.colors.placeholderGray};

    display: flex;
    justify-content: space-between;

    cursor: text;
    font-size: 16px;
    font-weight: 500;

    &:focus {
      border: 1px solid ${theme.brandColors.primary};
    }

    &::placeholder {
      color: ${theme.colors.placeholderGray};
    }

    ${disabled &&
    css`
      background-color: ${theme.colors.gray400};
    `}

    ${hasError &&
    css`
      border: 1px solid ${theme.colors.error};
    `}
  `}
`;

export const InputErrorMessage = styled.div<{ showError?: boolean }>`
  ${({ theme, showError = true }) => css`
    margin: -8px 24px 12px;

    display: ${showError ? 'inline-block' : 'none'};

    color: ${theme.colors.error};
  `}
`;
