import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

import useCheckoutCartInfo from './useCheckoutCartInfo';

interface UseCheckoutIsZeroedOrderProps {
  isTakeaway: boolean;
}

export default function useCheckoutIsZeroedOrder({ isTakeaway }: UseCheckoutIsZeroedOrderProps) {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const [isZeroedOrder, setIsZeroedOrder] = useState<boolean>(false);

  const { cartInfo } = useCheckoutCartInfo({
    isTakeaway
  });

  useEffect(() => {
    const { values } = cartInfo();

    const hasFeature = !!settings?.mm_feature_priceless_order_blocking_enabled;
    const isCartOrderZeroed = values?.total === 0;

    setIsZeroedOrder(hasFeature && isCartOrderZeroed);
  }, [cartInfo, settings?.mm_feature_priceless_order_blocking_enabled]);

  return { isZeroedOrder };
}
