import { AxiosResponse } from 'axios';

import { api } from '~/services/api';
import { CheckIn } from '~/interfaces/general';

export default async function changeTableRequest(
  tableCode: string,
  readResult: string,
  aId?: string
): Promise<CheckIn> {
  const tableParam = readResult.includes('cardapio/t') ? `id=${tableCode}` : `code=${tableCode}`;
  const apiPath = `${process.env.NEXT_PUBLIC_API_ABRAHAO}/${aId}/tables?${tableParam}`;

  try {
    const response: AxiosResponse<CheckIn> = await api.get(apiPath);
    return response.data;
  } catch (error) {
    throw new Error(`POST error: ${error}`);
  }
}
