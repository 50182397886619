import React from 'react';

const Sushi = (): JSX.Element => {
  return (
    <svg
      id="sushi-icon"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'scale(1.3)' }}
      viewBox="0 0 40 40"
      version="1.1"
    >
      <path
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        d="M30.313 23.375v3.438a3.75 3.75 0 01-3.75 3.75H13.438a3.75 3.75 0 01-3.75-3.75v-3.438"
      />
      <path
        strokeWidth="2"
        clipRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        d="M20 19.313c4.082-.047 8 1.61 10.809 4.572a1.875 1.875 0 003.25-1.268v-.023C34.063 15.604 27.766 9.937 20 9.937c-7.766 0-14.063 5.667-14.063 12.657v.023a1.875 1.875 0 003.25 1.268A14.669 14.669 0 0120 19.312z"
      />
      <path
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        d="M10.9 22.326l5.012-11.846M19.675 19.313l3.802-8.985M26.886 20.935l3.076-7.274"
      />
    </svg>
  );
};

export default Sushi;
