import * as S from './styles';

export interface SummaryListItemProps {
  isStrong?: boolean;
  isDisabled?: boolean;
  isAttention?: boolean;
  isSucceeded?: boolean;
  price: number | string;
  isDropdownActive?: boolean;
  onDropdownClick?: () => void;
  description: React.ReactNode | string;
}

const SummaryListItem: (props: SummaryListItemProps) => JSX.Element = ({
  price,
  isStrong,
  isDisabled,
  isSucceeded,
  isAttention,
  description
}) => {
  return (
    <S.Container isAttention={isAttention} isSucceeded={isSucceeded} isStrong={isStrong} isDisabled={isDisabled}>
      <p>{description}</p>

      <p>{price}</p>
    </S.Container>
  );
};

export { SummaryListItem };
