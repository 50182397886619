import { useCallback, useEffect, useMemo, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { mobileModel, browserName } from 'react-device-detect';
import { useLocalStorage } from '@goomerdev/goomer-toolbox/src/hooks';
import { addMinutes, differenceInDays, differenceInHours, startOfDay } from 'date-fns';
import { getTermIntoString, scrollToElement } from '@goomerdev/goomer-toolbox/src/utils';
import { PaymentCategoryEnum, SchedulingConfigTypeEnum } from '@goomerdev/goomer-toolbox/src/enums';

import Cookies from '~/utils/cookies';
import { IApplicationState } from '~/redux-tools/store';
import { cleanCart } from '~/redux-tools/store/cart/actions';
import { PixelEventsEnum } from '~/utils/reactPixelTrackEvent';
import { addError } from '~/redux-tools/store/errorLog/actions';
import { getTunaTokenizatorSessionData } from '~/services/index';
import { cleanCoupon } from '~/redux-tools/store/coupons/actions';
import useValidatePhoneNumber from '~/hooks/useValidatePhoneNumber';
import { getPaymentLink, sendOrder, sendPayment } from '~/services';
import { MercadoPago as MercadoPagoSDK } from '~/utils/mercadoPago';
import GoogleAnalytics, { gaErrors, gaEvents } from '~/utils/analytics';
import { isTakeaway as isTakeawayUtils } from '~/utils/orderDeliveryTypes';
import useCheckoutCartInfo from '~/hooks/useCheckout/hooks/useCheckoutCartInfo';
import useCheckoutUserInfo from '~/hooks/useCheckout/hooks/useCheckoutUserInfo';
import { addOrder as addLocalOrder } from '~/redux-tools/store/localOrders/actions';
import { addOrder as addOrderStatus } from '~/redux-tools/store/orderStatus/actions';
import useCheckoutSendWhatsapp from '~/hooks/useCheckout/hooks/useCheckoutSendWhatsapp';
import useCheckoutDeliveryInfo from '~/hooks/useCheckout/hooks/useCheckoutDeliveryInfo';
import { addLastOrder, addLastPocketOrder } from '~/redux-tools/store/lastOrder/actions';
import { addOrder as addTabOrder, addTabPayment } from '~/redux-tools/store/myTab/actions';
import { PocketMountedOrder, PocketOrderOption, PocketOrderOptionGroup } from '~/interfaces';
import { removeCheckoutData, setErrorCheckoutData } from '~/redux-tools/store/checkout/actions';
import { addNupayOrder, addNupayTimeToExpireOrder, cleanNupayOrder } from '~/redux-tools/store/nupay/actions';
import { addOrderTime, addPixOrder, addPixTabPayment, cleanAllPix } from '~/redux-tools/store/pixPayment/actions';
import {
  addUserInfo,
  addUserPayment,
  removeCardTokens,
  fetchCustomerInfoRequest,
  addCustomerAddressRequest
} from '~/redux-tools/store/user/actions';
import {
  useSlug,
  useChatbot,
  useTranslator,
  useWalletToken,
  useDetectAdBlock,
  useSchedulingInfo,
  useDeliveryPartner,
  useGetErrorMessage
} from '~/hooks';
import {
  isOnPwa,
  validateCPF,
  validateCNPJ,
  compareStrings,
  truncateAmount,
  isRequiredField,
  convertToTimezone,
  requiredWarnScroll,
  reactPixelTrackEvent,
  checkPaymentPatterns,
  generateMpCheckoutInfo,
  hidePartOfDocumentNumber,
  generateTunaCheckoutUserInfo
} from '~/utils';
import {
  IUser,
  IOrder,
  Address,
  ErrorInfo,
  OrderPayload,
  CartOptional,
  MountedOrder,
  PaymentMethod,
  PaymentReceipt,
  TabPaymentCart,
  CartOptionalGroup,
  InAppCheckoutInfo,
  TunaPaymentPayload,
  serializeOrderForGA,
  SendOrderDataCookies,
  InAppTunaCheckoutData,
  MountedLocalOrderInfo,
  QrCodeHashReaderResult,
  FingerPrintStorageProps,
  HandleInAppPaymentProps
} from '~/interfaces/general';
import {
  QueryParam,
  ErrorsEnum,
  ProductType,
  OrderWayEnum,
  OrderModeEnum,
  CookieNameEnum,
  DeliveryWayEnum,
  LocalOrdersEnum,
  TunaPaymentMode,
  PaymentTypeEnum,
  LocalStorageEnum,
  FeedbackStatusEnum,
  OperationModesEnum,
  PossibleStatusEnum,
  DeliveryPricingEnum,
  ReverseLocalOrdersEnum,
  PaymentStepProcessEnum,
  CheckoutErrorMessageEnum,
  LocalStorageFingerPrintEnum
} from '~/interfaces/enums';

interface UseCheckoutSubmitProps {
  orderInfo: IOrder;
  newToken?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isClientConfirmed?: boolean;
  cvvModalConfirmed?: boolean;
  isWaitingRevalidation: boolean;
  deliveryMinimumValueError?: boolean;
  userInfo: {
    cpf: string;
    name: string;
    isNameValid: boolean;
    clientPhone: string;
    addressSelected?: boolean;
    isClientPhoneValid: boolean;
  };
  actions: {
    setIsLoading: (isShow: boolean) => void;
    setShowCvvModal: (isShow: boolean) => void;
    setErrorType: (error?: ErrorsEnum) => void;
    setHasPaymentError: (error: boolean) => void;
    setIsOrderSent: (isOrderSent: boolean) => void;
    setShowSignInModal?: (isShow: boolean) => void;
    setShowAddressModal: (isShow: boolean) => void;
    setShowSchedulingModal: (isShow: boolean) => void;
    setIsWaitingRevalidation: (value: boolean) => void;
    setShowClientChangeModal: (isShow: boolean) => void;
    setIsClientConfirmed: (isConfirmed: boolean) => void;
    setPaymentReceipt: (receipt: PaymentReceipt) => void;
    setCvvModalConfirmed: (isConfirmed: boolean) => void;
    setShowPaymentOptionModal: (isShow: boolean) => void;
    setShowPaymentReceiptModal: (isShow: boolean) => void;
    setShowPaymentFeedbackModal: (isShow: boolean) => void;
    setShowProcessingPaymentModal: (isShow: boolean) => void;
    setShowLatLngConfirmationModal: (isShow: boolean) => void;
    setShouldDisplayQrCodeScannerModal: (isShow: boolean) => void;
    setProcessingModalType: (type: PaymentStepProcessEnum) => void;
  };
}

const MP_MIN_TRANSACTION_VALUE = 0.5;
const PIX_MIN_TRANSACTION_VALUE = 20;
const GET_CUSTOMER_INFO_DELAY = 4000;
const TEN_SECONDS_IN_MILLISECONDS = 10000;
const GG_VERSION = process.env.NEXT_PUBLIC_GG_VERSION || '';

let urlLink = '';
let shouldProceed = true;
let inAppCheckoutInfo: InAppCheckoutInfo | undefined;
let inAppTunaCheckoutData: InAppTunaCheckoutData | undefined;

export default function useCheckoutSubmit({
  actions,
  userInfo,
  disabled,
  newToken,
  isLoading,
  orderInfo,
  isClientConfirmed,
  cvvModalConfirmed,
  isWaitingRevalidation,
  deliveryMinimumValueError
}: UseCheckoutSubmitProps) {
  const history = useRouter();
  const dispatch = useDispatch();
  const isAdBlockDetected = useDetectAdBlock();

  const user = useSelector((state: IApplicationState) => state.user.data);
  const checkout = useSelector((state: IApplicationState) => state.checkout);
  const totalOrder = useSelector((state: IApplicationState) => state.cart.values.total);
  const { isGoogleMapsApiLoaded } = useSelector((state: IApplicationState) => state.geolocation);
  const { utm: userUtm, orderFromDashboard } = useSelector((state: IApplicationState) => state.user);
  const { pocketOrder: lastPocketOrder } = useSelector((state: IApplicationState) => state.lastOrder);
  const { open, settings, loyaltyProgram } = useSelector((state: IApplicationState) => state.establishment);
  const { hash, mode, number, isOperatorMode } = useSelector((state: IApplicationState) => state.localOrders);
  const { origin, data: coupon, error: couponError } = useSelector((state: IApplicationState) => state.coupons);
  const { preventPageReload, address: cartAddress, ...cart } = useSelector((state: IApplicationState) => state.cart);
  const address = useSelector((state: IApplicationState) =>
    state.user.data.addresses?.find((userAddress) => userAddress.favorite === true)
  );

  const [orderUuid, setOrderUuid] = useState<string>(uuidv4());
  const [pendentField, setPendentField] = useState<PossibleStatusEnum>(PossibleStatusEnum.name);

  const { localStorageValue } = useLocalStorage({ key: 'qrCodeHashReader' });
  const { localStorageValue: selectedLanguage } = useLocalStorage({ key: LocalStorageEnum.selectedLanguage });

  const isAbrahaoStore: boolean = useMemo(() => !!settings?.is_abrahao, [settings?.is_abrahao]);

  const qrCodeHashReader: QrCodeHashReaderResult = useMemo(
    () => JSON.parse(localStorageValue || '{}') as QrCodeHashReaderResult,
    [localStorageValue]
  );

  const fingerPrintUserId: string | null | undefined =
    typeof localStorage !== 'undefined'
      ? localStorage.getItem(LocalStorageFingerPrintEnum.isCalledFingerPrintScript)
      : undefined;

  const fingerPrintStorage: FingerPrintStorageProps | undefined = fingerPrintUserId
    ? (JSON.parse(fingerPrintUserId) as FingerPrintStorageProps)
    : undefined;

  const isTakeaway: boolean = useMemo(
    () => isTakeawayUtils(orderInfo.deliveryOption || ''),
    [orderInfo.deliveryOption]
  );

  const slug = useSlug();
  const { getTranslation } = useTranslator();
  const { userCreditCard } = useCheckoutUserInfo();
  const { getErrorMessage } = useGetErrorMessage();
  const { isPhoneValid } = useValidatePhoneNumber();
  const { isChatbotOrderStatusEnabled } = useChatbot();
  const { methodType, walletToken } = useWalletToken();
  const { generateSchedulingList } = useSchedulingInfo();
  const { shouldAskLatitudeAndLongitude } = useDeliveryPartner();
  const { cartInfo, cartProducts, numberOfCartItems, productsIdsAndNames } = useCheckoutCartInfo({
    isTakeaway
  });
  const { deliveryWay, deliveryTime, deliveryMode } = useCheckoutDeliveryInfo({
    isTakeaway,
    deliveryOption: orderInfo.deliveryOption,
    isInStore: orderInfo.orderWay === OrderWayEnum.NaLoja
  });

  const currentUser: IUser = useMemo(
    () => ({
      cpf: userInfo.cpf,
      name: userInfo.name,
      phone: userInfo.clientPhone
    }),
    [userInfo.clientPhone, userInfo.cpf, userInfo.name]
  );

  const isMinimumPixFeeEnabled: boolean = useMemo(
    () => settings?.mm_payment_pix_fee_enabled || false,
    [settings?.mm_payment_pix_fee_enabled]
  );

  const internationalPhone = useMemo(() => {
    if (!isPhoneValid(userInfo.clientPhone)) return '';

    if (userInfo.clientPhone) {
      const parsedPhone = userInfo.clientPhone.replace(/\D/g, '');

      const isAlreadyInternationalNumber = parsedPhone.length >= 12;

      return isAlreadyInternationalNumber ? userInfo.clientPhone : `+55${parsedPhone}`;
    }

    return '';
  }, [isPhoneValid, userInfo.clientPhone]);

  const isPaymentWithGooglePay: boolean = useMemo(() => {
    const { paymentOption } = orderInfo;

    if (!paymentOption) return false;

    return paymentOption.type === PaymentTypeEnum.onApp && paymentOption.category === PaymentCategoryEnum.googlePay;
  }, [orderInfo]);

  const isPaymentWithApplePay: boolean = useMemo(() => {
    const { paymentOption } = orderInfo;

    if (!paymentOption) return false;

    return paymentOption.type === PaymentTypeEnum.onApp && paymentOption.category === PaymentCategoryEnum.applePay;
  }, [orderInfo]);

  const isWalletPayment = useMemo(() => {
    return isPaymentWithGooglePay || isPaymentWithApplePay;
  }, [isPaymentWithApplePay, isPaymentWithGooglePay]);

  const orderPayload = useMemo<OrderPayload>(() => {
    const selectedCoupon = isWalletPayment ? checkout.selectedCoupon : coupon.selectedCoupon;

    const isCouponApplied = !couponError && !!selectedCoupon;

    const formattedPaymentFlagToUppercase = orderInfo.paymentOption?.flag?.toUpperCase();

    const formattedPaymentType = {
      ...orderInfo.paymentOption,
      flag: formattedPaymentFlagToUppercase
    };

    return {
      cpf: userInfo.cpf,
      items: cartProducts,
      total_value: cart.total,
      phone: internationalPhone,
      delivery_way: deliveryWay,
      establishment: settings?.name,
      establishment_id: settings?.id,
      payment_type: formattedPaymentType,
      is_scheduled: !!orderInfo.schedulingDate,
      delivery_tax: orderInfo.deliveryRate || 0,
      coupon: isCouponApplied ? selectedCoupon : undefined,
      delivery_fee_double_check: cart?.deliveryFee?.double_check || false,
      store_coupon_code: isCouponApplied ? selectedCoupon?.code : undefined,
      store_coupon_type: isCouponApplied ? selectedCoupon?.type : undefined,
      store_coupon_value: isCouponApplied ? selectedCoupon?.value : undefined,
      payment_money_change: orderInfo.moneyChange ? orderInfo.moneyChangeValue : 0,
      scheduled_to_local_timezone: orderInfo.schedulingDate
        ? new Date(orderInfo.schedulingDate.setSeconds(0))
        : undefined,
      scheduled_to: orderInfo.schedulingDate
        ? convertToTimezone({ timezone: settings?.mm_timezone, initialDate: orderInfo.schedulingDate })
        : undefined
    };
  }, [
    cart.total,
    couponError,
    deliveryWay,
    settings?.id,
    userInfo.cpf,
    cartProducts,
    settings?.name,
    isWalletPayment,
    internationalPhone,
    coupon.selectedCoupon,
    settings?.mm_timezone,
    orderInfo.moneyChange,
    orderInfo.deliveryRate,
    orderInfo.paymentOption,
    checkout.selectedCoupon,
    orderInfo.schedulingDate,
    orderInfo.moneyChangeValue,
    cart?.deliveryFee?.double_check
  ]);

  const { sendToWhatsapp } = useCheckoutSendWhatsapp({
    deliveryWay,
    orderPayload,
    order: orderInfo
  });

  const isAbleToPay: boolean = useMemo(
    () => mode !== LocalOrdersEnum.table && mode !== LocalOrdersEnum.guestCheck,
    [mode]
  );

  const minTimeForScheduling:
    | {
        value: number;
        type: SchedulingConfigTypeEnum;
      }
    | undefined = useMemo(() => {
    if (origin === DeliveryWayEnum.delivery) {
      return settings?.mm_order_scheduling_delivery?.min;
    }

    if (origin === DeliveryWayEnum.takeaway) {
      return settings?.mm_order_scheduling_takeaway?.min;
    }

    return undefined;
  }, [origin, settings?.mm_order_scheduling_delivery?.min, settings?.mm_order_scheduling_takeaway?.min]);

  const isPaymentWithCreditCard: boolean = useMemo(() => {
    const { paymentOption } = orderInfo;

    if (!paymentOption) return false;

    return (
      paymentOption.type === PaymentTypeEnum.onApp &&
      (getTermIntoString({ term: '****', word: paymentOption.flag || paymentOption.cardDescription || '' }) ||
        paymentOption.category === PaymentCategoryEnum.mPagoCheckout ||
        paymentOption.category === PaymentCategoryEnum.tunaCheckout)
    );
  }, [orderInfo]);

  const isQrCodeOrderOrTakeaway = useMemo(
    () => (origin === DeliveryWayEnum.takeaway || mode === LocalOrdersEnum.balcony) && !!settings?.address,
    [mode, origin, settings]
  );

  const isPaymentWithIntegratedPix: boolean = useMemo(() => {
    const { paymentOption } = orderInfo;

    if (!paymentOption) return false;

    return paymentOption.type === PaymentTypeEnum.onApp && paymentOption.category === PaymentCategoryEnum.integratedPix;
  }, [orderInfo]);

  const isPaymentWithCreditCardTuna: boolean = useMemo(() => {
    const { paymentOption } = orderInfo;

    if (!paymentOption) return false;

    return paymentOption.type === PaymentTypeEnum.onApp && paymentOption.category === PaymentCategoryEnum.tunaCheckout;
  }, [orderInfo]);

  const isPaymentWithNuPay: boolean = useMemo(() => {
    const { paymentOption } = orderInfo;

    if (!paymentOption) return false;

    return paymentOption.type === PaymentTypeEnum.onApp && paymentOption.category === PaymentCategoryEnum.nupay;
  }, [orderInfo]);

  const isPaymentLink: boolean = useMemo(() => {
    const { paymentOption } = orderInfo;

    if (!paymentOption) return false;

    if (paymentOption.category === PaymentCategoryEnum.vrPagueLink) return false;

    return (
      paymentOption.type === PaymentTypeEnum.onApp &&
      (getTermIntoString({ term: 'link', word: paymentOption.flag || paymentOption.cardDescription || '' }) ||
        paymentOption.category === PaymentCategoryEnum.mPagoLink)
    );
  }, [orderInfo]);

  const isLocalOrder: boolean = useMemo(() => mode.length > 0, [mode.length]);

  const getCustomerInfo = useCallback(() => {
    setTimeout(() => {
      dispatch(
        fetchCustomerInfoRequest({
          storeId: settings?.id || 0,
          token: user?.authenticationToken || ''
        })
      );
    }, GET_CUSTOMER_INFO_DELAY);
  }, [dispatch, settings?.id, user?.authenticationToken]);

  const handlePaymentError: (error?: ErrorsEnum) => void = useCallback(
    (error?: ErrorsEnum) => {
      MercadoPagoSDK.clearSession();

      actions.setErrorType(error);
      actions.setShowPaymentFeedbackModal(true);
    },
    [actions]
  );

  const gaTrackErrorSendOrder: ({
    url,
    extra,
    error
  }: {
    error: string;
    extra?: string | undefined;
    url?: string | undefined;
  }) => void = useCallback(
    ({ url, extra, error }) => {
      GoogleAnalytics.trackError(gaErrors.orderError, {
        url,
        error_message: error,
        browser: browserName,
        phone_model: mobileModel,
        error_extra_message: extra,
        order_way: orderInfo.orderWay,
        establishment_id: settings?.id,
        value: orderPayload.total_value
      });
    },
    [orderInfo.orderWay, settings?.id, orderPayload.total_value]
  );

  const addErrorLog: (log: ErrorInfo) => void = useCallback(
    (log) => {
      const logEntry = {
        ...log,
        slug,
        code: '#03',
        solution: 'goomer_go',
        storeId: settings?.store_code,
        date: new Date().toISOString()
      };

      if (!log.response) {
        dispatch(addError(logEntry));
      }
    },
    [dispatch, settings, slug]
  );

  const handleValidationActions: () => boolean = useCallback(() => {
    if (isLoading) return false;

    if (history.pathname.includes('sendOrder')) return true;

    requiredWarnScroll({ warningClass: '-warn', selector: '[data-done="false"]' });

    if (origin === DeliveryWayEnum.onsite && !hash) return false;

    if (origin !== DeliveryWayEnum.onsite && !orderInfo.deliveryOption) return false;

    if (pendentField === PossibleStatusEnum.scheduling && origin !== DeliveryWayEnum.onsite) {
      actions.setShowSchedulingModal(true);

      return false;
    }

    if (pendentField === PossibleStatusEnum.address && origin !== DeliveryWayEnum.onsite) {
      actions.setShowAddressModal(true);

      return false;
    }

    if (pendentField === PossibleStatusEnum.name) {
      const inputName = document.getElementById('#input-name');

      if (inputName) {
        inputName.focus();

        return false;
      }
    }

    if (pendentField === PossibleStatusEnum.phone) {
      const inputPhone = document.getElementById('#input-phone');

      if (inputPhone) {
        inputPhone.focus();

        return false;
      }
    }

    if (pendentField === PossibleStatusEnum.guestCheck) {
      const inputTable = document.getElementById('#input-table');

      if (inputTable) {
        inputTable.focus();

        return false;
      }
    }

    if (
      pendentField === PossibleStatusEnum.payment &&
      (origin !== DeliveryWayEnum.onsite || compareStrings(mode, OperationModesEnum.kiosk))
    ) {
      actions.setShowPaymentOptionModal(true);

      return false;
    }

    if (
      pendentField === PossibleStatusEnum.cpf &&
      (origin === DeliveryWayEnum.delivery || origin === DeliveryWayEnum.takeaway)
    ) {
      const inputCpf = document.getElementById('input-cpf-cnpj');

      if (inputCpf) {
        inputCpf.focus();

        return false;
      }
    }

    if (
      isGoogleMapsApiLoaded &&
      shouldAskLatitudeAndLongitude({
        paymentOption: orderInfo.paymentOption
      })
    ) {
      actions.setShowLatLngConfirmationModal(true);

      return false;
    }

    if (deliveryMinimumValueError) {
      toast.error(getTranslation('errorMessage.belowMinimumValue'), {
        autoClose: TEN_SECONDS_IN_MILLISECONDS
      });

      const deliveryMinimumValueNotice = document.querySelector('#delivery-minimum-value');

      scrollToElement({
        isSmooth: true,
        element: deliveryMinimumValueNotice as HTMLInputElement
      });

      return false;
    }

    if (isAbrahaoStore) {
      if (settings?.mm_order_mode == OrderModeEnum.Tab && mode !== LocalOrdersEnum.guestCheck) {
        if (!qrCodeHashReader.result) {
          actions.setIsClientConfirmed(true);
          actions.setShouldDisplayQrCodeScannerModal(true);
          return false;
        }

        if (qrCodeHashReader?.result && !isClientConfirmed) {
          actions.setShowClientChangeModal(true);
          return false;
        }
      }

      if (!isClientConfirmed && settings?.mm_order_mode === OrderModeEnum.Table && mode === LocalOrdersEnum.table) {
        if (lastPocketOrder?.orders) {
          actions.setShowClientChangeModal(true);
          return false;
        }

        actions.setIsClientConfirmed(true);
      }
    }

    return true;
  }, [
    hash,
    mode,
    origin,
    actions,
    isLoading,
    pendentField,
    getTranslation,
    isAbrahaoStore,
    lastPocketOrder,
    history.pathname,
    isClientConfirmed,
    isGoogleMapsApiLoaded,
    orderInfo.paymentOption,
    settings?.mm_order_mode,
    qrCodeHashReader.result,
    orderInfo.deliveryOption,
    deliveryMinimumValueError,
    shouldAskLatitudeAndLongitude
  ]);

  const handleWithOutOfPatternPayments: () => void = useCallback(() => {
    const isPaymentOnPattern: boolean = orderInfo.paymentOption
      ? checkPaymentPatterns.isAllPaymentMethodsOnPattern([orderInfo.paymentOption])
      : false;

    if (!isPaymentOnPattern) {
      const errorMessage = '#16 pagamento fora do padrão';

      gaTrackErrorSendOrder({ url: '', error: errorMessage });

      toast.error(getTranslation('errorMessage.errorToSendOrder'));
    }
  }, [gaTrackErrorSendOrder, getTranslation, orderInfo.paymentOption]);

  const handleWithSchedulingDate: () => void = useCallback(() => {
    const scheduledTo: Date | undefined = orderInfo.schedulingDate
      ? new Date(orderInfo.schedulingDate.setSeconds(0))
      : undefined;

    const now: Date = new Date();

    if (!scheduledTo || !settings?.mm_order_scheduling_delivery?.min || !settings?.mm_order_scheduling_takeaway?.min)
      return;

    if (!minTimeForScheduling) return;

    const schedulingTimeError: () => void = () => {
      const errorMessage = '#17 agendamento com horário indisponível';

      gaTrackErrorSendOrder({ url: '', error: errorMessage });

      toast.error(getTranslation('errorMessage.errorToSendOrderWithSchedule'));

      generateSchedulingList();
      shouldProceed = false;
    };

    if (
      minTimeForScheduling.type === SchedulingConfigTypeEnum.days ||
      minTimeForScheduling.type === SchedulingConfigTypeEnum.runningDays
    ) {
      const nowToSchedulingDiffInHours = differenceInHours(scheduledTo, now);
      const nowToSchedulingDiffInDays = differenceInDays(startOfDay(scheduledTo), startOfDay(now));

      if (nowToSchedulingDiffInDays < minTimeForScheduling.value && nowToSchedulingDiffInHours < 1) {
        schedulingTimeError();
      }
    }

    if (minTimeForScheduling.type === SchedulingConfigTypeEnum.hours) {
      const nowToSchedulingDiff = differenceInHours(scheduledTo, now);

      if (nowToSchedulingDiff < minTimeForScheduling.value) {
        schedulingTimeError();
      }
    }
  }, [
    getTranslation,
    minTimeForScheduling,
    gaTrackErrorSendOrder,
    generateSchedulingList,
    orderInfo.schedulingDate,
    settings?.mm_order_scheduling_delivery?.min,
    settings?.mm_order_scheduling_takeaway?.min
  ]);

  const handleInAppPayment = useCallback(
    async ({ paymentToken, isTabPayment = false }: HandleInAppPaymentProps) => {
      const total = truncateAmount(cart.values.total);

      const isDeliveryFeeAskOption: boolean =
        !!(cart.deliveryFee && 'pricing' in cart.deliveryFee) && cart.deliveryFee.pricing === DeliveryPricingEnum.ask;

      if (!isTakeaway && isDeliveryFeeAskOption) {
        const error: string = isPaymentWithCreditCard
          ? getTranslation('errorMessage.notAllowDirectPayment')
          : getTranslation('errorMessage.notBeGenerated');

        toast.error(error);

        return (shouldProceed = false);
      }

      if (
        cart.values.total < MP_MIN_TRANSACTION_VALUE &&
        !isPaymentWithIntegratedPix &&
        !isPaymentWithCreditCardTuna &&
        !isPaymentWithNuPay &&
        !isWalletPayment
      ) {
        const error = getTranslation('errorMessage.mercadoPagoMinimumValue');

        toast.error(error);

        shouldProceed = false;
      }

      const getAddress = (): { userAddress: Address | undefined; shippingAddress: Address | undefined } => {
        const userAddress: Address | undefined = user?.addresses && user?.addresses[0] ? user.addresses[0] : undefined;

        const shippingAddress: Address | undefined =
          origin === DeliveryWayEnum.delivery ? cartAddress || address || userAddress : undefined;

        return { userAddress, shippingAddress };
      };

      const getBillingAddress = (
        userAddress: Address | undefined,
        shippingAddress: Address | undefined
      ): Address | undefined => {
        let billingAddress: Address | undefined = address || userAddress || shippingAddress;

        if (isQrCodeOrderOrTakeaway || isTabPayment) {
          const storeAddress = { ...settings?.address, zipcode: undefined, cep: settings?.address?.zipcode };
          billingAddress = storeAddress as Address;
        }

        return billingAddress;
      };

      const generateCheckoutData = (
        token: string | undefined,
        tunaSessionData: any,
        billingAddress: Address | undefined,
        paymentMode: TunaPaymentMode,
        shippingAddress: Address | undefined
      ) => {
        return {
          products: cart.products,
          storeId: String(settings?.id),
          storeName: settings?.name || '',
          tokenSession: tunaSessionData.token,
          sessionId: fingerPrintStorage?.fingerPrintUserId || '',
          user: {
            billingAddress,
            shippingAddress,
            customerID: tunaSessionData.customerId,
            ...generateTunaCheckoutUserInfo({
              clientName: userInfo.name,
              clientPhone: userInfo.clientPhone,
              clientEmail: orderInfo?.paymentOption?.email || ''
            })
          },
          payment: {
            token,
            mode: paymentMode,
            subtotal: cart.values.subtotal,
            price: total || cart.values.total,
            data: {
              number: userCreditCard?.number,
              cardHolderName: userCreditCard?.name,
              brandName: orderInfo?.paymentOption?.mpMethodId || '',
              expirationYear: parseInt(userCreditCard?.due?.split('/')[1] || '1'),
              expirationMonth: parseInt(userCreditCard?.due?.split('/')[0] || '1')
            }
          }
        };
      };

      if (isPaymentWithCreditCard) {
        if (!paymentToken || !cvvModalConfirmed) {
          actions.setShowCvvModal(true);
          shouldProceed = false;
          return;
        }

        const { userAddress, shippingAddress } = getAddress();
        const billingAddress = getBillingAddress(userAddress, shippingAddress);

        if (userCreditCard?.flagDescription === PaymentCategoryEnum.tunaCheckout) {
          actions.setProcessingModalType(PaymentStepProcessEnum.paying);
          actions.setShowProcessingPaymentModal(true);

          const tunaSessionData = await getTunaTokenizatorSessionData(user.authenticationToken || newToken || '');

          GoogleAnalytics.trackEvent(gaEvents.pagtoCreditAdblockTrue, {
            isAdBlockDetected,
            establishment_id: settings?.store_code
          });

          return (inAppTunaCheckoutData = generateCheckoutData(
            paymentToken,
            tunaSessionData,
            billingAddress,
            TunaPaymentMode.card,
            shippingAddress
          ));
        } else if (orderInfo.paymentOption?.email) {
          return (inAppCheckoutInfo = {
            storeId: String(settings?.id),
            payment: {
              token: paymentToken,
              price: total || cart.values.total,
              paymentMethod: orderInfo?.paymentOption?.mpMethodId || ''
            },
            user: {
              customerID: undefined,
              billingAddress: undefined,
              ...generateMpCheckoutInfo({
                clientName: userInfo.name,
                clientPhone: userInfo.clientPhone,
                clientEmail: orderInfo?.paymentOption?.email || '',
                address: origin === DeliveryWayEnum.takeaway ? settings?.address : address
              })
            }
          });
        }
      }

      if (isWalletPayment) {
        const { userAddress, shippingAddress } = getAddress();

        const billingAddress = getBillingAddress(userAddress, shippingAddress);

        const tunaSessionData = await getTunaTokenizatorSessionData(user.authenticationToken || newToken || '');

        const googlePayToken = walletToken || '';

        return (inAppTunaCheckoutData = generateCheckoutData(
          googlePayToken,
          tunaSessionData,
          billingAddress,
          TunaPaymentMode.googlePay,
          shippingAddress
        ));
      }

      if (isPaymentLink) {
        if (orderInfo.orderWay === OrderWayEnum.NaLoja) {
          shouldProceed = false;

          toast.error(getTranslation('errorMessage.pleaseChoosePaymentMethod'));
          actions.setShowPaymentOptionModal(true);

          return {};
        }

        const payload = {
          slug,
          storeId: String(settings?.id),
          price: total || cart.values.total
        };

        const onError = (error: Error, extra?: string) => {
          const errorMessage = getTranslation('errorMessage.errorToGenerateMercadoPagoLink');
          const errorDetail = `${errorMessage}, Error: ${error.message}`;

          gaTrackErrorSendOrder({ extra, error: errorDetail });

          addErrorLog({
            response: '',
            payload: JSON.stringify(payload, null, 2),
            request: '/payments/mercado-pago/checkout',
            error: `${errorMessage}.\nError Message: ${error.message}\nError Object: ${JSON.stringify(error)}`
          });
        };

        urlLink =
          (await getPaymentLink({
            ...payload,
            onError,
            getTranslation,
            setIsLoading: actions.setIsLoading
          })) || '';

        if (!urlLink?.includes('www')) {
          shouldProceed = false;
        }

        return { link: urlLink };
      }

      if (isPaymentWithIntegratedPix) {
        if (totalOrder < PIX_MIN_TRANSACTION_VALUE && !isMinimumPixFeeEnabled) {
          const error = getTranslation('errorMessage.minimumPixOrderValue');

          toast.error(error);

          GoogleAnalytics.trackEvent(gaEvents.pixOrderRefusedForTheMinimumPrice);

          shouldProceed = false;
        }

        const { userAddress, shippingAddress } = getAddress();

        const billingAddress = getBillingAddress(userAddress, shippingAddress);

        const tunaSessionData = await getTunaTokenizatorSessionData(user.authenticationToken || newToken || '');

        return (inAppTunaCheckoutData = {
          products: cart.products,
          storeId: String(settings?.id),
          storeName: settings?.name || '',
          tokenSession: tunaSessionData.token,
          sessionId: fingerPrintStorage?.fingerPrintUserId || '',
          payment: {
            data: {},
            mode: TunaPaymentMode.pix,
            subtotal: cart.values.subtotal,
            price: total || cart.values.total
          },
          user: {
            billingAddress,
            shippingAddress,
            customerID: tunaSessionData.customerId,
            ...generateTunaCheckoutUserInfo({
              clientName: userInfo.name,
              clientPhone: userInfo.clientPhone,
              clientEmail: orderInfo?.paymentOption?.email || ''
            })
          }
        });
      }

      if (isPaymentWithNuPay) {
        const { userAddress, shippingAddress } = getAddress();

        const billingAddress = getBillingAddress(userAddress, shippingAddress);

        const userDocument = orderInfo?.paymentOption?.document;

        if (!userDocument) {
          toast.error(getTranslation('errorMessage.checkTheData'));

          return (shouldProceed = false);
        }

        const tunaSessionData = await getTunaTokenizatorSessionData(user.authenticationToken || newToken || '');

        return (inAppTunaCheckoutData = {
          products: cart.products,
          storeId: String(settings?.id),
          storeName: settings?.name || '',
          tokenSession: tunaSessionData.token,
          sessionId: fingerPrintStorage?.fingerPrintUserId || '',
          payment: {
            data: {},
            mode: TunaPaymentMode.nupay,
            subtotal: cart.values.subtotal,
            price: total || cart.values.total
          },
          user: {
            billingAddress,
            shippingAddress,
            document: userDocument,
            customerID: tunaSessionData.customerId,
            ...generateTunaCheckoutUserInfo({
              clientName: userInfo.name,
              clientPhone: userInfo.clientPhone,
              clientEmail: orderInfo?.paymentOption?.email || ''
            })
          }
        });
      }
    },
    [
      slug,
      origin,
      address,
      actions,
      newToken,
      isTakeaway,
      totalOrder,
      cartAddress,
      addErrorLog,
      walletToken,
      settings?.id,
      isPaymentLink,
      cart.products,
      userInfo.name,
      user.addresses,
      settings?.name,
      getTranslation,
      isWalletPayment,
      cart.deliveryFee,
      isAdBlockDetected,
      settings?.address,
      cart.values.total,
      cvvModalConfirmed,
      isPaymentWithNuPay,
      orderInfo.orderWay,
      userCreditCard?.due,
      userCreditCard?.name,
      cart.values.subtotal,
      settings?.store_code,
      userInfo.clientPhone,
      gaTrackErrorSendOrder,
      isMinimumPixFeeEnabled,
      userCreditCard?.number,
      isQrCodeOrderOrTakeaway,
      isPaymentWithCreditCard,
      user.authenticationToken,
      isPaymentWithIntegratedPix,
      isPaymentWithCreditCardTuna,
      orderInfo.paymentOption?.email,
      userCreditCard?.flagDescription,
      orderInfo?.paymentOption?.document,
      orderInfo.paymentOption?.mpMethodId,
      fingerPrintStorage?.fingerPrintUserId
    ]
  );

  const cleanCheckoutCart: () => void = useCallback(() => {
    setTimeout(() => {
      dispatch(cleanCart());
    }, 2000);
  }, [dispatch]);

  const handleNextUserInteraction: ({
    order_id,
    orders_count,
    mpPaymentLink
  }: {
    order_id: number;
    orders_count: number;
    mpPaymentLink: string;
  }) => void = useCallback(
    ({ order_id, orders_count, mpPaymentLink }) => {
      dispatch(cleanCart());

      const hasStoreId = !!settings?.id;
      const hasAuthenticationToken = !!user.authenticationToken;
      if (hasStoreId && hasAuthenticationToken) {
        getCustomerInfo();
      }

      const isValidOrder: boolean = orderInfo.orderWay === OrderWayEnum.GoomerGo && !orderFromDashboard;

      if (!isValidOrder) return;

      const hasOrderStatus = !!settings?.mm_order_status_check_enabled;

      const isNupay = orderInfo.paymentOption?.category === PaymentCategoryEnum.nupay;
      const isCreditTuna = orderInfo.paymentOption?.category === PaymentCategoryEnum.tunaCheckout;
      const isIntegratedPix = orderInfo.paymentOption?.category === PaymentCategoryEnum.integratedPix;

      if (isIntegratedPix) {
        history.push('/payment');
      } else if (isNupay) {
        history.push('/nupay');
      } else if (isWalletPayment && isLocalOrder) {
        history.push(`/feedback?${QueryParam.type}=${FeedbackStatusEnum.orderSuccess}&orderId=${order_id}`);
      } else if (hasOrderStatus) {
        history.push('/order/status');
      } else if (isChatbotOrderStatusEnabled) {
        history.push(`/order/whatsapp?orderID=${order_id}`);
      } else if (isCreditTuna || isWalletPayment) {
        history.push('/payment/confirmed');
      } else {
        sendToWhatsapp({
          order_id,
          currentUser,
          orders_count,
          mpPaymentLink
        });
      }
    },
    [
      history,
      dispatch,
      currentUser,
      isLocalOrder,
      settings?.id,
      sendToWhatsapp,
      isWalletPayment,
      getCustomerInfo,
      orderInfo.orderWay,
      orderFromDashboard,
      user.authenticationToken,
      isChatbotOrderStatusEnabled,
      orderInfo.paymentOption?.category,
      settings?.mm_order_status_check_enabled
    ]
  );

  const handleSendOrder: (token?: string) => Promise<boolean | undefined> = useCallback(
    async (token = '') => {
      let theOrder = orderPayload;

      const whatsappUrl = '';

      shouldProceed = true;
      inAppCheckoutInfo = undefined;
      inAppTunaCheckoutData = undefined;
      const apiFormatMode = ReverseLocalOrdersEnum[mode as LocalOrdersEnum];
      const paymentMethod: PaymentMethod | undefined = orderInfo.paymentOption;

      if (handleValidationActions()) {
        actions.setIsLoading(true);

        if (orderInfo.paymentOption) {
          handleWithOutOfPatternPayments();
        }

        if (orderInfo.orderWay === OrderWayEnum.GoomerGo) {
          handleWithSchedulingDate();
        }

        if (!shouldProceed) {
          actions.setIsLoading(false);
          return;
        }

        const isWithMoneyChangeInvalidValue =
          orderInfo.moneyChange && orderInfo.moneyChangeValue > 0 && orderInfo.moneyChangeValue < cart.values.total;

        if (isWithMoneyChangeInvalidValue) {
          const error = getTranslation('errorMessage.amountOfChange');
          toast.error(error);

          shouldProceed = false;

          actions.setShowPaymentOptionModal(true);
        }

        const isInAppPaymentType =
          !!paymentMethod && paymentMethod.type === PaymentTypeEnum.onApp && settings && settings.id;

        if (isInAppPaymentType && orderInfo.paymentOption?.category !== PaymentCategoryEnum.pix && isAbleToPay) {
          const paymentInfo = await handleInAppPayment({ paymentToken: token });

          const googlePayFlag = methodType;
          const orderFlag = isPaymentWithGooglePay && googlePayFlag ? googlePayFlag : theOrder.payment_type?.flag;

          theOrder = {
            ...theOrder,
            payment_type: {
              ...(theOrder.payment_type as object),
              ...paymentInfo,
              flag: orderFlag
            }
          };
        }

        const isMpQrCodePayment = !!paymentMethod && paymentMethod.category === PaymentCategoryEnum.mPagoQr;

        if (isMpQrCodePayment) {
          if (cart.values.total < MP_MIN_TRANSACTION_VALUE) {
            const error = getTranslation('errorMessage.mercadoPagoMinimumValue');

            toast.error(error);

            shouldProceed = false;
          }
        }

        if (!shouldProceed) {
          actions.setIsLoading(false);
          return;
        }

        const localOrderInfo: MountedLocalOrderInfo | undefined =
          orderInfo.orderWay === OrderWayEnum.NaLoja
            ? {
                hash,
                number,
                operation_mode: apiFormatMode,
                tab_table_code: orderInfo.table
              }
            : undefined;

        const mountedOrder: MountedOrder = {
          version: '2.0',
          order: theOrder,
          date: new Date(),
          cart: cartInfo(),
          inAppCheckoutInfo,
          inAppTunaCheckoutData,
          linkURL: urlLink || '',
          phone: internationalPhone,
          localOrder: localOrderInfo,
          gg_version: GG_VERSION || '',
          address: !isTakeaway ? cartAddress : undefined,
          statusCheckEnabled: settings?.mm_order_status_check_enabled,
          utm: {
            source: userUtm.utmSource,
            medium: userUtm.utmMedium,
            campaign: userUtm.utmCampaign
          },
          subscription_info: {
            ...settings?.subscription_info,
            product_type: orderInfo.orderWay === OrderWayEnum.NaLoja ? ProductType.gnl : ProductType.ggo
          },
          user: {
            cpf: userInfo.cpf,
            name: userInfo.name,
            customerID: undefined,
            phone: internationalPhone,
            address: userInfo.addressSelected,
            customerLoyaltyProgram: user.customerLoyaltyProgram,
            authenticationToken: user.authenticationToken || newToken || ''
          },
          establishment: {
            loyaltyProgram,
            name: settings?.name,
            store_id: settings?.id,
            id: settings?.store_code,
            logo: settings?.mm_logo_url,
            delivery_fee_mode: deliveryMode(),
            delivery_fee: orderInfo.deliveryRate,
            delivery_waiting_time: deliveryTime(),
            mm_payment_pix_info: settings?.mm_payment_pix_info,
            mm_in_store_service_tax: settings?.mm_in_store_service_tax,
            mm_in_store_enable_service_tax: settings?.mm_in_store_enable_service_tax,
            takeaway_discount: settings?.mm_takeaway_discount_enabled
              ? { type: 'percent', value: Number(settings?.mm_takeaway_discount || 0) }
              : undefined
          }
        };
        const tabCode = mode === LocalOrdersEnum.guestCheck ? String(number) : '';

        const formatOptionalTree = (optionalTree: CartOptionalGroup[]): PocketOrderOptionGroup[] => {
          const flatOptionalList: PocketOrderOptionGroup[] = [];

          const formatOptionGroup = (tree: CartOptionalGroup[]): void => {
            let optionFlowList: CartOptionalGroup[][] = [];

            const formatOptional = (option: CartOptional): PocketOrderOption => {
              const { id, name, hash, price, quantity, attributes: attributeList } = option;

              if (attributeList) {
                optionFlowList.push(attributeList);
              }

              return {
                hash,
                name: name,
                id: String(id),
                price: String(price),
                quantity: String(quantity)
              };
            };

            tree.forEach(({ id, options }) => {
              optionFlowList = [];
              const formattedOptionList = options.map((optionItem) => formatOptional(optionItem));

              flatOptionalList.push({ id, options: formattedOptionList });
              optionFlowList.map((group) => formatOptionGroup(group));
            });
          };

          formatOptionGroup(optionalTree);
          return flatOptionalList;
        };

        const cardNumber = () => {
          if (mode === LocalOrdersEnum.guestCheck) {
            return String(number);
          }

          return settings?.mm_order_mode == OrderModeEnum.Tab ? qrCodeHashReader.result : tabCode;
        };

        const pocketMountedOrder: PocketMountedOrder = {
          table_number: mode === LocalOrdersEnum.table ? String(number) : orderInfo.table || '',
          orders: [
            {
              uuid: orderUuid,
              card_number: cardNumber(),
              customer: {
                name: userInfo.name,
                phone: internationalPhone
              },
              items: cart.products.map((cartProduct) => ({
                hash: cartProduct.hash,
                name: cartProduct.product.name,
                observation: cartProduct.comment,
                price_name: cartProduct.customName,
                quantity: String(cartProduct.quantity),
                id: String(cartProduct.selectedPriceId),
                price: String(cartProduct.product.price),
                price_code: String(cartProduct.code || ''),
                product_id: String(cartProduct.product.id),
                attributes: formatOptionalTree(cartProduct.optionalTree || [])
              }))
            }
          ]
        };

        try {
          const isNupay = orderInfo.paymentOption?.category === PaymentCategoryEnum.nupay;
          const isIntegratedPix = orderInfo.paymentOption?.category === PaymentCategoryEnum.integratedPix;

          const isWalletPayment =
            orderInfo.paymentOption?.category === PaymentCategoryEnum.googlePay ||
            orderInfo.paymentOption?.category === PaymentCategoryEnum.applePay;

          const actionParam = isWalletPayment && !walletToken ? 'set-send-order-data-cookies' : '';

          dispatch(cleanAllPix());
          dispatch(cleanNupayOrder());

          actions.setProcessingModalType(PaymentStepProcessEnum.sending);

          const sendOrderData = {
            actionParam,
            addErrorLog,
            selectedLanguage,
            errorAction: handlePaymentError,
            abrahaoStoreId: settings?.mm_a_id,
            setIsLoading: actions.setIsLoading,
            isPocketOrder: settings?.is_abrahao,
            isInAppPayment: isPaymentWithCreditCard,
            mountedOrder: settings?.is_abrahao ? pocketMountedOrder : mountedOrder
          };

          if (process.env.NEXT_PUBLIC_ENV === 'test') {
            if (isWalletPayment && !walletToken) {
              const isLocalhost: boolean =
                typeof window !== 'undefined' && window.location.hostname.includes('localhost');

              const domain: string = isLocalhost ? '.goomer.localhost' : process.env.NEXT_PUBLIC_COOKIES_DOMAIN || '';

              const sendOrderDataCookies: SendOrderDataCookies = {
                values: cart.values,
                establishment: {
                  slug: settings?.slug,
                  name: settings?.name,
                  logo: settings?.mm_logo_url
                },
                user: {
                  name: checkout.name,
                  phone: checkout.clientPhone,
                  cpf: checkout.cpf && hidePartOfDocumentNumber(checkout.cpf)
                }
              };

              Cookies.setCookie({
                domain,
                expirationDays: 90,
                name: CookieNameEnum.checkoutOrderData,
                value: JSON.stringify(sendOrderDataCookies)
              });

              setTimeout(() => {
                window.location.href = 'http://checkout.goomer.localhost:3002/';
              }, 1000);

              return;
            }
          }

          const orderResponse = await sendOrder(sendOrderData);

          if (orderResponse?.cookiesOnly === true) {
            return;
          }

          const [ids, names] = productsIdsAndNames();
          reactPixelTrackEvent(PixelEventsEnum.Purchase, {
            currency: 'BRL',
            content_ids: ids,
            value: cart.total,
            content_names: names,
            num_items: numberOfCartItems(),
            payment_method: paymentMethod ? paymentMethod.category : undefined
          });

          if (!isAbrahaoStore && !orderResponse?.orders_count) {
            throw new Error('#01 ID do Pedido indefinido');
          }

          if (orderResponse) {
            const GAOrderData = {
              ...serializeOrderForGA(orderPayload),
              cart_total: cart.values.total,
              order_way: orderInfo.orderWay,
              order_id: orderResponse?.order_id,
              num_items: cart.products.reduce((totalItems, item) => totalItems + (item.quantity || 1), 0)
            };

            GoogleAnalytics.trackEvent(gaEvents.purchase, GAOrderData);

            if (isOnPwa()) {
              GoogleAnalytics.trackEvent(gaEvents.ggoPwaOrder, GAOrderData);
            }

            if (orderFromDashboard) {
              GoogleAnalytics.trackEvent(gaEvents.orderFromDashboard, GAOrderData);
            }

            if (paymentMethod?.category === PaymentCategoryEnum.googlePay && orderResponse) {
              GoogleAnalytics.trackEvent(gaEvents.googlePaySuccess, {
                ...GAOrderData,
                payment_status: 'success'
              });
            }
          }

          if (userUtm.utmSource === 'crm-whatsapp') {
            GoogleAnalytics.trackEvent(gaEvents.crmWhatsappOrderMenu, {
              customerId: userUtm.utmId,
              establishment_id: settings?.store_code
            });
          }

          if (!isAbrahaoStore && orderResponse) {
            const { order_id, order_uuid, orders_count } = orderResponse;
            dispatch(
              addLastOrder({
                ...mountedOrder,
                orderID: order_id,
                orderUUID: order_uuid,
                orderCount: orders_count
              })
            );
          }

          if (isAbrahaoStore && pocketMountedOrder) {
            dispatch(addLastPocketOrder(pocketMountedOrder));
          }

          if (preventPageReload) {
            window.removeEventListener('beforeunload', preventPageReload);
          }

          if (isPaymentWithCreditCard && userCreditCard) {
            dispatch(removeCardTokens());
          }

          dispatch(addUserInfo(currentUser));

          if (!!user.isAuthenticated && cartAddress && user.addresses?.length === 0) {
            dispatch(
              addCustomerAddressRequest({
                address: cartAddress,
                storeId: settings?.id || 0,
                isUserAuthenticated: !!user.isAuthenticated,
                functions: {
                  afterAction: () => {},
                  addErrorLog: () => toast.error(getTranslation('errorMessage.errorToSaveAddress'))
                }
              })
            );
          }

          if (orderFromDashboard) {
            history.push(
              `/feedback?${QueryParam.type}=${FeedbackStatusEnum.orderFromDashboard}&${QueryParam.orderId}=${orderResponse?.orders_count}`
            );
          }

          if (orderInfo.orderWay === OrderWayEnum.NaLoja) {
            const paymentText = (() => {
              const { paymentOption } = orderInfo;

              if (typeof paymentOption === 'string') return paymentOption;

              if (paymentOption?.category && paymentOption?.flag) {
                return `${paymentOption?.category} ${paymentOption?.flag}`;
              }

              return `${paymentOption?.category}`;
            })();

            const orderFeedback = {
              payment: paymentText,
              id: orderResponse?.orders_count,
              tabTableCode: orderInfo.table || '',
              createdAt: orderResponse?.created_at
            };

            dispatch(addLocalOrder(orderFeedback));

            if (mode !== OperationModesEnum.kiosk) {
              dispatch(addTabOrder(mountedOrder));
            }

            actions.setIsOrderSent(true);

            if (isNupay) {
              dispatch(
                addNupayOrder({
                  ...mountedOrder,
                  orderID: orderResponse?.order_id,
                  nupayPaymentUrl: orderResponse?.nupay_payment_url
                })
              );

              dispatch(
                addOrderStatus({
                  ...mountedOrder,
                  orderID: orderResponse?.order_id,
                  orderUUID: orderResponse?.order_uuid
                })
              );

              dispatch(addNupayTimeToExpireOrder(orderResponse?.nupay_payment_url_expiration_time));

              cleanCheckoutCart();

              return await history.push('/nupay');
            }

            if (orderResponse && isIntegratedPix && origin === DeliveryWayEnum.onsite) {
              const { pix_key, order_id, order_uuid, orders_count, qrcode_pix_key, pix_key_expiration_time } =
                orderResponse;

              const pixOrder = {
                ...mountedOrder,
                pixKey: pix_key,
                orderID: order_id,
                orderUUID: order_uuid,
                orderCount: orders_count,
                qrCodePixKey: qrcode_pix_key
              };

              dispatch(
                addOrderStatus({
                  ...mountedOrder,
                  orderID: order_id,
                  orderUUID: order_uuid,
                  orderCount: orders_count
                })
              );

              dispatch(addOrderTime(pix_key_expiration_time));

              dispatch(addPixOrder(pixOrder));

              cleanCheckoutCart();

              history.push('/payment');
            } else {
              const feedbackOrderId = orderResponse?.orders_count;

              history.push(
                `/feedback?${QueryParam.type}=${
                  isOperatorMode
                    ? FeedbackStatusEnum.operatorOrderSuccess
                    : isAbrahaoStore
                    ? FeedbackStatusEnum.orderSuccessAbrahao
                    : FeedbackStatusEnum.orderSuccess
                }${feedbackOrderId ? `&orderId=${feedbackOrderId}` : ''}`
              );
            }
          }

          if (orderResponse && orderInfo.orderWay === OrderWayEnum.GoomerGo && !orderFromDashboard) {
            const {
              pix_key,
              order_id,
              order_uuid,
              orders_count,
              qrcode_pix_key,
              nupay_payment_url,
              pix_key_expiration_time,
              nupay_payment_url_expiration_time
            } = orderResponse;

            dispatch(
              addOrderStatus({
                ...mountedOrder,
                orderID: order_id,
                orderUUID: order_uuid,
                orderCount: orders_count
              })
            );

            if (typeof orderInfo.paymentOption === 'object') {
              dispatch(addUserPayment({ ...orderInfo.paymentOption, establishment: settings?.name }));
            }

            if (isIntegratedPix) {
              dispatch(
                addPixOrder({
                  ...mountedOrder,
                  pixKey: pix_key,
                  orderID: order_id,
                  orderUUID: order_uuid,
                  orderCount: orders_count,
                  qrCodePixKey: qrcode_pix_key
                })
              );

              dispatch(addOrderTime(pix_key_expiration_time));

              cleanCheckoutCart();
            }

            if (isNupay) {
              dispatch(
                addNupayOrder({
                  ...mountedOrder,
                  orderID: order_id,
                  nupayPaymentUrl: nupay_payment_url
                })
              );

              dispatch(addNupayTimeToExpireOrder(nupay_payment_url_expiration_time));

              cleanCheckoutCart();
            }
          }

          actions.setIsOrderSent(true);

          handleNextUserInteraction({
            mpPaymentLink: urlLink,
            order_id: orderResponse?.order_id,
            orders_count: orderResponse?.orders_count
          });

          setTimeout(() => {
            dispatch(cleanCoupon());
          }, 1000);
        } catch (error) {
          console.error(error);

          actions.setIsLoading(false);

          const customError = getErrorMessage((error as Error)?.message);
          const isTunaCardPayment = userCreditCard?.flagDescription === PaymentCategoryEnum.tunaCheckout;
          const isOrderRejectedByMp = customError?.code === CheckoutErrorMessageEnum.rejected_order_by_mp;

          if (error instanceof Error) {
            if (customError && !isOrderRejectedByMp) {
              toast.error(customError.message);
              return;
            }

            gaTrackErrorSendOrder({ url: whatsappUrl, error: error?.message });
          }

          if (isTunaCardPayment || isOrderRejectedByMp) {
            actions.setCvvModalConfirmed(false);
            return;
          }

          if (String(error).includes(CheckoutErrorMessageEnum.invalid_loyalty_discount)) {
            dispatch(
              fetchCustomerInfoRequest({
                storeId: settings?.id || 0,
                token: user?.authenticationToken || ''
              })
            );
          }

          if (
            error instanceof Error &&
            isAbrahaoStore &&
            !!error.message &&
            !error.message.includes('Integration error')
          ) {
            toast.error(error.message);
            return;
          }

          if (isWalletPayment) {
            dispatch(setErrorCheckoutData(true));
          }

          toast.error(getTranslation('errorMessage.errorToSendOrderCheckData'));

          if (paymentMethod?.category === PaymentCategoryEnum.googlePay) {
            GoogleAnalytics.trackEvent(gaEvents.googlePayError, {
              payment_status: 'error',
              cart_total: cart.values.total
            });
          }

          return;
        } finally {
          if (isWalletPayment) {
            actions.setProcessingModalType(PaymentStepProcessEnum.sendingToCheckout);

            actions.setShowProcessingPaymentModal(true);
          } else {
            actions.setShowProcessingPaymentModal(false);
            actions.setProcessingModalType(PaymentStepProcessEnum.sending);
          }
        }
      }
    },
    [
      mode,
      hash,
      number,
      origin,
      history,
      actions,
      settings,
      newToken,
      cartInfo,
      dispatch,
      orderUuid,
      orderInfo,
      isTakeaway,
      cart.total,
      methodType,
      cartAddress,
      isAbleToPay,
      walletToken,
      currentUser,
      addErrorLog,
      userInfo.cpf,
      deliveryTime,
      orderPayload,
      deliveryMode,
      cart.values,
      checkout.cpf,
      checkout.name,
      cart.products,
      userUtm.utmId,
      userInfo.name,
      isOperatorMode,
      userCreditCard,
      loyaltyProgram,
      getTranslation,
      isAbrahaoStore,
      isWalletPayment,
      getErrorMessage,
      selectedLanguage,
      cleanCheckoutCart,
      userUtm.utmMedium,
      numberOfCartItems,
      userUtm.utmSource,
      preventPageReload,
      orderFromDashboard,
      handlePaymentError,
      handleInAppPayment,
      internationalPhone,
      productsIdsAndNames,
      userUtm.utmCampaign,
      checkout.clientPhone,
      user.isAuthenticated,
      gaTrackErrorSendOrder,
      user.addresses?.length,
      isPaymentWithGooglePay,
      isPaymentWithCreditCard,
      handleValidationActions,
      qrCodeHashReader.result,
      user.authenticationToken,
      handleNextUserInteraction,
      handleWithSchedulingDate,
      userInfo.addressSelected,
      user.customerLoyaltyProgram,
      handleWithOutOfPatternPayments
    ]
  );

  const handlePayment: (paymentValues: TabPaymentCart) => Promise<void> = useCallback(
    async (paymentValues: TabPaymentCart) => {
      actions.setIsLoading(true);
      actions.setHasPaymentError(false);

      shouldProceed = true;
      const token = userCreditCard?.token || '';
      const paymentMethod: PaymentMethod | undefined = orderInfo.paymentOption;

      const paymentInfo = await handleInAppPayment({ isTabPayment: true, paymentToken: token });

      if (!shouldProceed || !paymentInfo || !paymentInfo.payment) return;

      try {
        const { payment, sessionId, tokenSession } = paymentInfo;

        if (!payment || !sessionId || !tokenSession || !paymentInfo.user.billingAddress) {
          toast.error(getTranslation('errorMessage.errorToMakePayment'));
          return;
        }

        const checkMode: ReverseLocalOrdersEnum = ReverseLocalOrdersEnum[mode as LocalOrdersEnum];

        const partnerUniqueID: string = uuidv4().toUpperCase().replace(/-/g, '');

        const { phone, email, lastName, firstName, customerID, billingAddress } = paymentInfo.user;

        const paymentPayload: TunaPaymentPayload = {
          sessionId,
          tokenSession,
          partnerUniqueID,
          payment: {
            data: payment.data,
            token: payment.token,
            mode: payment.mode || ''
          },
          customer: {
            email: email || '',
            documentType: 'CPF',
            id: customerID || '',
            phonePrefix: phone.phonePrefix,
            phoneNumber: phone.phoneNumber,
            billingAddress: billingAddress,
            name: `${firstName} ${lastName}`
          },
          cart: {
            ...paymentValues,
            mode: checkMode,
            code: Number(number),
            sellerID: paymentInfo.storeId,
            deliveryWay: DeliveryWayEnum.onsite,
            sellerName: paymentInfo.storeName || '',
            items: [
              {
                quantity: 1,
                name: 'Pagamento avulso',
                value: paymentValues.total,
                category: 'Serviço de pagamento'
              }
            ]
          }
        };

        const paymentResponse = await sendPayment({ payload: paymentPayload });

        if (payment.mode) {
          actions.setPaymentReceipt({
            partnerUniqueID,
            mode: payment.mode,
            paid: paymentValues.total,
            total: paymentValues.total,
            tax: paymentValues.serviceTax,
            date: new Date().toISOString(),
            table: mode === LocalOrdersEnum.table ? number : '',
            tab: mode === LocalOrdersEnum.guestCheck ? number : ''
          });
        }

        if (paymentMethod) {
          dispatch(
            addTabPayment({
              partnerUniqueID,
              date: new Date(),
              payment: paymentMethod
            })
          );

          if (paymentMethod.category === PaymentCategoryEnum.integratedPix) {
            dispatch(
              addPixTabPayment({
                partnerUniqueID,
                pixKey: paymentResponse.pixKey || '',
                qrCodePixKey: paymentResponse.qrcodePixKey || '',
                expiration: addMinutes(new Date(), 10).toISOString()
              })
            );
          }

          dispatch(removeCheckoutData());
        }
      } catch (error) {
        actions.setHasPaymentError(true);
        actions.setCvvModalConfirmed(false);
        actions.setShowProcessingPaymentModal(false);

        actions.setShowPaymentReceiptModal(true);
        return;
      } finally {
        actions.setIsLoading(false);
      }
    },
    [
      mode,
      number,
      actions,
      dispatch,
      getTranslation,
      handleInAppPayment,
      userCreditCard?.token,
      orderInfo.paymentOption
    ]
  );

  const handleSubmit: () => void = useCallback(() => {
    if (isLoading) return;

    if (disabled) {
      handleValidationActions();
      return;
    }

    handleSendOrder(userCreditCard?.token);
  }, [disabled, handleSendOrder, handleValidationActions, isLoading, userCreditCard?.token]);

  useEffect(() => {
    const handleValidation: () => void = () => {
      const isOnlySchedulingNow: boolean =
        (!!settings?.mm_order_scheduling_enabled && !!settings?.mm_order_scheduling_only) || !open.isOpen;

      const isRequiredCpf = settings?.mm_receipt_required_cpf && !isLocalOrder;

      if (origin !== DeliveryWayEnum.onsite && isOnlySchedulingNow && !orderInfo.schedulingDate) {
        setPendentField(PossibleStatusEnum.scheduling);
        return;
      }

      if (orderInfo.deliveryOption === DeliveryWayEnum.delivery && !userInfo.addressSelected) {
        setPendentField(PossibleStatusEnum.address);
        return;
      }

      if (!isOperatorMode && isRequiredField(settings?.mm_ask_for_name) && !userInfo.isNameValid) {
        setPendentField(PossibleStatusEnum.name);
        return;
      }

      if (!isOperatorMode && !userInfo.isClientPhoneValid && isRequiredField(settings?.mm_ask_for_phone)) {
        setPendentField(PossibleStatusEnum.phone);
        return;
      }

      if (origin === DeliveryWayEnum.onsite && !hash) {
        setPendentField(PossibleStatusEnum.hash);
        return;
      }

      if (isAbleToPay && !orderInfo.paymentOption) {
        setPendentField(PossibleStatusEnum.payment);
        return;
      }

      const removeDotsAndHyphensFromDocument = userInfo.cpf.replace(/\D/g, '');

      const isValidCpf = validateCPF({ emptyStringShouldReturnFalse: true, cpf: removeDotsAndHyphensFromDocument });
      const isValidCnpj = validateCNPJ({ emptyStringShouldReturnFalse: true, cnpj: removeDotsAndHyphensFromDocument });

      const isCpfFilled = removeDotsAndHyphensFromDocument.length > 0 && removeDotsAndHyphensFromDocument.length <= 11;

      if (isRequiredCpf && (isCpfFilled ? !isValidCpf : !isValidCnpj)) {
        setPendentField(PossibleStatusEnum.cpf);
        return;
      }

      if (
        compareStrings(mode, OperationModesEnum.guestCheck) &&
        (!orderInfo.table || orderInfo.table === '' || Number(orderInfo.table) === 0)
      ) {
        setPendentField(PossibleStatusEnum.guestCheck);
        return;
      }

      setPendentField(PossibleStatusEnum.default);
      return;
    };

    handleValidation();
  }, [
    mode,
    hash,
    origin,
    settings,
    userInfo,
    orderInfo,
    isAbleToPay,
    open.isOpen,
    isLocalOrder,
    isOperatorMode,
    isAbrahaoStore
  ]);

  useEffect(() => {
    const generatedUuid = uuidv4();

    setOrderUuid(generatedUuid);
  }, []);

  useEffect(() => {
    if (isWaitingRevalidation) {
      actions.setIsWaitingRevalidation(false);

      handleSubmit();
    }
  }, [actions, handleSubmit, isWaitingRevalidation]);

  return { pendentField, handleSubmit, handlePayment, handleSendOrder, handleNextUserInteraction };
}
