import styled from 'styled-components';

export const ModalWrapper = styled.div`
  padding: 1rem;
`;

export const Title = styled.h1`
  font-weight: 800;
  font-size: 1.12rem;
`;

export const Description = styled.div`
  margin-top: 0.5rem;

  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.mediumGray};

  strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.mediumGray};
  }
`;

export const Example = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.mediumGray};

  strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.mediumGray};
  }
`;

export const Divider = styled.hr`
  margin: 1rem 8.18rem;

  border: 1px solid ${({ theme }) => theme.colors.gray300};
`;

export const Footer = styled.footer`
  margin-top: 1.5rem;
`;
