import React from 'react';

import ContentLoader from 'react-content-loader';

import * as S from './styles';

const Loader: React.FC = () => (
  <S.Container>
    <ContentLoader
      title="loader"
      speed={2}
      width="100%"
      height={120}
      radius={10}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="10" rx="3" ry="3" width="180" height="12" />
      <rect x="calc(100% - 40px)" y="10" rx="3" ry="3" width="40" height="12" />

      <rect x="0" y="35" rx="3" ry="3" width="180" height="12" />
      <rect x="calc(100% - 40px)" y="35" rx="3" ry="3" width="40" height="12" />

      <rect x="0" y="60" rx="3" ry="3" width="180" height="12" />
      <rect x="calc(100% - 40px)" y="60" rx="3" ry="3" width="40" height="12" />

      <rect x="0" y="90" rx="3" ry="3" width="50" height="15" />
      <rect x="calc(100% - 60px)" y="90" rx="3" ry="3" width="60" height="15" />
    </ContentLoader>
  </S.Container>
);

export default Loader;
