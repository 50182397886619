import { ErrorLogEntry } from '~/interfaces/general';

export interface IErrorLogState {
  readonly log: ErrorLogEntry | undefined;
}

export const ErrorLogActionTypes = {
  ADD_ERROR: '@@ErrorLog/ADD_ERROR',
  CLEAN_LOG: '@@ErrorLog/CLEAN_LOG',
  SEND_LOG_REQUEST: '@@ErrorLog/SEND_LOG_REQUEST',
  SEND_LOG_SUCCESS: '@@ErrorLog/SEND_LOG_SUCCESS',
  SEND_LOG_ERROR: '@@ErrorLog/SEND_LOG_ERROR'
};
