import { AxiosResponse } from 'axios';
import { Id, toast } from 'react-toastify';

import { api } from '~/services/api';
import { QRCodeModeEnum } from '~/interfaces/enums';
import { BillAPIResponse } from '~/interfaces/general';

export const OperationModes = {
  [QRCodeModeEnum.table]: 'table',
  [QRCodeModeEnum.balcony]: 'kiosk',
  [QRCodeModeEnum.guestCheck]: 'tab',
  [QRCodeModeEnum.display]: 'display',
  [QRCodeModeEnum.delivery]: 'delivery'
};

export interface BillQueryProps {
  aId?: string;
  tab?: string;
  table?: string;
  language: string;
  callback: (isOk?: boolean) => void;
  getTranslation: (key: string) => string;
}

export default async function closeBill({
  aId,
  tab,
  table,
  language,
  callback,
  getTranslation
}: BillQueryProps): Promise<Id | undefined> {
  if (!aId) {
    return toast.error(getTranslation('errorMessage.storeIdNotFound'));
  }

  try {
    const payload = {
      card: tab || undefined,
      table_number: table || undefined
    };

    const apiPath = `${process.env.NEXT_PUBLIC_API_ABRAHAO || ''}/${aId}/close-bill`;

    const apiUrl = new URL(apiPath);
    apiUrl.searchParams.append('lang', language);

    const response: AxiosResponse<BillAPIResponse> = await api.post(apiUrl.toString(), payload);

    if (response.status !== 200 && response.status !== 204) {
      throw new Error();
    }

    callback(true);
  } catch (error) {
    callback(false);

    console.error((error as Error)?.message);
    toast.error(getTranslation('errorMessage.errorToCloseBill'));
    throw new Error(`Close bill error: ${error}`);
  }
}
