import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { ReadyToPayChangeResponse } from '@google-pay/button-react';
import { getTermIntoString } from '@goomerdev/goomer-toolbox/src/utils';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { ArrowRight, Loader } from '~/assets';
import { IApplicationState } from '~/redux-tools/store';
import { groupCartItems, truncateAmount } from '~/utils';
import { Footer } from '~/components/FloatingFooter/styles';
import { cleanCart } from '~/redux-tools/store/cart/actions';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { addUserInfo } from '~/redux-tools/store/user/actions';
import { IUser, CheckoutContextProps } from '~/interfaces/general';
import * as CheckOrderComponents from '~/components/CheckOrderList';
import { setIsGooglePayReady } from '~/redux-tools/store/payments/actions';
import NewCheckoutOptions from '~/components/pages/Order/NewCheckoutOptions';
import { changeScriptStatus } from '~/redux-tools/store/fingerPrint/actions';
import useCheckoutUserInfo from '~/hooks/useCheckout/hooks/useCheckoutUserInfo';
import { removeMPPaymentMethods } from '~/redux-tools/store/establishment/actions';
import useCheckoutDeliveryInfo from '~/hooks/useCheckout/hooks/useCheckoutDeliveryInfo';
import { FeaturesEnum, DeliveryPricingEnum, PaymentStepProcessEnum, DeliveryWayEnum } from '~/interfaces/enums';
import {
  useSlug,
  useCheckout,
  useScrollTo,
  useTranslator,
  useFingerPrint,
  useHasFeatureAccess,
  useVisibilityObserver,
  useEstablishmentStatus,
  useUserAuthenticationStatus
} from '~/hooks';
import {
  Terms,
  Button,
  ItemEdit,
  NewWaves,
  SummaryFooter,
  FooterBackdrop,
  OrderNameInput,
  HeaderSecondary,
  OrderPhoneInput,
  ConfirmCvvModal,
  PaymentStepModal,
  CardFeedbackModal,
  OrderFullAgeCheckbox,
  GooglePayCheckoutButton
} from '~/components';

import * as S from './styles';

const QUERY_SELECTOR_NAME_OBSERVER = '#order-total-price';

export const CheckoutContext = React.createContext({} as CheckoutContextProps);

const Order: React.FC = () => {
  const slug = useSlug();
  const history = useRouter();
  const newToken = useRef('');
  const dispatch = useDispatch();

  const cart = useSelector((state: IApplicationState) => state.cart);
  const { checkout } = useSelector((state: IApplicationState) => state);
  const user = useSelector((state: IApplicationState) => state.user.data);
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { isScriptLoaded } = useSelector((state: IApplicationState) => state.fingerPrint);
  const isOpen = useSelector((state: IApplicationState) => state.establishment.open.isOpen);
  const { settings, isMPTokenValid } = useSelector((state: IApplicationState) => state.establishment);
  const {
    origin,
    data: coupon,
    error: couponError,
    loading: couponLoading
  } = useSelector((state: IApplicationState) => state.coupons);

  const [isTotalValueElementHidden, setIsTotalValueElementHidden] = useState<boolean>(false);

  useVisibilityObserver({
    querySelectorName: QUERY_SELECTOR_NAME_OBSERVER,
    onHidden: (state: boolean) => setIsTotalValueElementHidden(state)
  });

  const { getTranslation } = useTranslator();
  const { isAbleToOrder } = useEstablishmentStatus({});
  const { userPaymentPreference } = useCheckoutUserInfo();
  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const { states, context, actions } = useCheckout(newToken.current);
  const [hasUserSignUpAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.UserSignUp });

  const { handleSubmit, handleSendOrder } = actions.order;

  const isTakeaway: boolean = useMemo(
    () =>
      getTermIntoString({ term: 'retir', word: states.order.deliveryOption || '' }) ||
      getTermIntoString({ term: 'takeaway', word: states.order.deliveryOption || '' }),
    [states.order.deliveryOption]
  );

  const { deliveryInfo } = useCheckoutDeliveryInfo({
    isTakeaway,
    isInStore: false,
    deliveryOption: states.order.deliveryOption
  });

  const hasOrderStatus: boolean = useMemo(
    () => !!settings?.mm_order_status_check_enabled,
    [settings?.mm_order_status_check_enabled]
  );

  const isIntegratedPixPaymentMethodSelected: boolean = useMemo(
    () => !!user.paymentPreference && user.paymentPreference[0]?.category === PaymentCategoryEnum.integratedPix,
    [user.paymentPreference]
  );

  const takeaway: {
    takeaway_discount: string | false | undefined;
  } = useMemo(() => {
    return {
      takeaway_discount:
        settings?.mm_takeaway_enabled && settings.mm_takeaway_discount_enabled && settings.mm_takeaway_discount
    };
  }, [settings]);

  const isUnableToConfirmCvv: boolean = useMemo(
    () => couponLoading || states.order.cvvValue.length < 3,
    [couponLoading, states.order.cvvValue.length]
  );

  const isDelivery = useCallback(
    (value?: string) =>
      getTermIntoString({ term: 'entrega', word: value || '' }) ||
      getTermIntoString({ term: 'delivery', word: value || '' }),
    []
  );

  useScrollTo({ x: 0, y: 0 });

  useFingerPrint({ isScriptLoaded });

  useEffect(() => {
    dispatch(changeScriptStatus(true));
  }, [dispatch]);

  useEffect(() => {
    if (states.others.currentOrigin !== origin) {
      if (states.others.currentOrigin) {
        actions.order.setSchedulingDate(undefined);
      }

      actions.others.setCurrentOrigin(origin as DeliveryWayEnum);
    }
  }, [actions.order, actions.others, origin, states.others.currentOrigin]);

  useEffect(() => {
    if (!isMPTokenValid) {
      dispatch(removeMPPaymentMethods());
    }
  }, [dispatch, isMPTokenValid]);

  useEffect(() => {
    if (!!userPaymentPreference && userPaymentPreference?.category !== PaymentCategoryEnum.cash) {
      actions.order.setMoneyChangeValue(0);
    }
  }, [actions.order, userPaymentPreference]);

  useEffect(() => {
    if (slug !== undefined && cart.establishment !== '') {
      if (cart.establishment !== slug) {
        dispatch(cleanCart());
        history.push('/menu');
      }
    }
  }, [cart, slug, dispatch, history]);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (!Array.isArray(user.paymentPreference) && firstUpdate.current) {
      const userInfo: IUser = {
        ...user,
        paymentPreference: []
      };

      dispatch(addUserInfo(userInfo));
      firstUpdate.current = false;
    }
  }, [user, dispatch]);

  const isFreeDelivery = useMemo(() => {
    return settings?.mm_free_delivery_enabled && cart.total > Number(settings.mm_free_delivery_minimum_value);
  }, [settings, cart]);

  useEffect(() => {
    if (!user || !isUserAuthenticated) {
      return;
    }

    if (user.name && user.phone) {
      actions.user.setName(user.name);
      actions.user.setClientPhone(user.phone);
      actions.validations.setIsClientPhoneValid(true);
    }
  }, [actions.user, actions.validations, isUserAuthenticated, user]);

  useEffect(() => {
    if (deliveryInfo?.delivery_fee !== undefined && isDelivery(states.order.deliveryOption) && !isFreeDelivery) {
      actions.order.setDeliveryRate(
        deliveryInfo.delivery_fee === DeliveryPricingEnum.free ||
          deliveryInfo.delivery_fee === DeliveryPricingEnum.ask ||
          Number(deliveryInfo.delivery_fee) === 0
          ? 0
          : Number(deliveryInfo.delivery_fee)
      );
    } else {
      actions.order.setDeliveryRate(false);
    }
  }, [deliveryInfo, isFreeDelivery, isDelivery, actions.order, states.order.deliveryOption]);

  const cartItemList = useMemo(() => groupCartItems(cart.products), [cart.products]);

  const takeawayDiscount = useMemo(() => {
    if (takeaway?.takeaway_discount) {
      return {
        label: `${takeaway.takeaway_discount}%`,
        value: truncateAmount(Number(takeaway.takeaway_discount) / 100)
      };
    }

    const MULTIPLE = 0;

    return {
      label: '',
      value: MULTIPLE
    };
  }, [takeaway]);

  const isCouponApplied = useMemo(() => {
    return !couponError && !!coupon.selectedCoupon;
  }, [coupon.selectedCoupon, couponError]);

  const gaTrackAddMore = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.addMoreItems, {
      establishment_id: settings?.store_code
    });
  }, [settings]);

  const gaTrackBackHeader = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.checkoutBackHeader);
  }, []);

  const handleTrackEventWhenSendOrder = (): void => {
    const isTunaCreditSelected =
      userPaymentPreference && userPaymentPreference?.category === PaymentCategoryEnum.tunaCheckout;

    const isIntegratedPixSelected =
      userPaymentPreference && userPaymentPreference?.category === PaymentCategoryEnum.integratedPix;

    const isGooglePaySelected =
      (userPaymentPreference && userPaymentPreference?.category === PaymentCategoryEnum.googlePay) ||
      checkout?.paymentOption?.category === PaymentCategoryEnum.googlePay;

    let event = null;

    if (hasOrderStatus) {
      if (isTunaCreditSelected) {
        event = gaEvents.sendOrderStatusCreditOrder;
      }

      if (isIntegratedPixSelected) {
        event = gaEvents.sendOrderStatusIntegratedPixOrder;
      }

      if (isGooglePaySelected) {
        event = gaEvents.sendOrderStatusGooglePayOrder;
      }
    } else {
      if (isTunaCreditSelected) {
        event = gaEvents.sendWhatsAppCreditOrder;
      }

      if (isIntegratedPixSelected) {
        event = gaEvents.sendWhatsAppIntegratedPixOrder;
      }

      if (isGooglePaySelected) {
        event = gaEvents.sendWhatsAppGooglePayOrder;
      }
    }

    if (event !== null) {
      GoogleAnalytics.trackEvent(event);
    }
  };

  if (!cart || cart.products.length === 0) {
    return (
      <S.Container>
        <HeaderSecondary title={getTranslation('order.myOrder')} />
      </S.Container>
    );
  }

  return (
    <>
      <S.Container>
        <HeaderSecondary title={getTranslation('order.myOrder')} onBackAction={gaTrackBackHeader} />

        <FooterBackdrop />

        <div className="container">
          <CheckOrderComponents.CheckOrder
            isCheckout
            total={cart.total}
            discountType="percent"
            isWithdrawal={isTakeaway}
            discount={takeawayDiscount}
            isFreeDelivery={isFreeDelivery}
            hideMinimumPriceDelivery={false}
            loyaltyDiscount={cart?.values?.loyalty}
            coupon={isCouponApplied ? coupon.selectedCoupon : undefined}
            deliveryFee={(!!deliveryInfo?.delivery_fee && deliveryInfo?.delivery_fee) || 0}
            isLoyaltyDiscountHigherThanSubtotal={states.order.isLoyaltyDiscountHigherThanSubtotal}
          >
            {cartItemList.map((comboItem, index) => (
              <CheckOrderComponents.CheckOrderItem
                key={index}
                getTranslation={getTranslation}
                combo={comboItem.comboId ? comboItem : undefined}
                item={comboItem.productId ? comboItem.items[0] : undefined}
              />
            ))}

            <Link href="/menu">
              <S.AddMoreItems onClick={gaTrackAddMore} data-test="btn-add-more-items">
                <Button>{getTranslation('order.addMoreItem')}</Button>
              </S.AddMoreItems>
            </Link>
          </CheckOrderComponents.CheckOrder>
        </div>

        <NewWaves backgroundColor={theme.colors.gray200} />

        <div className="content-wrap">
          <div className="container">
            <OrderFullAgeCheckbox
              isFullAgeTermAccept={states.validations.isFullAgeTermAccept}
              setIsFullAgeTermAccept={(status) => actions.validations.setIsFullAgeTermAccept(status)}
            />

            <CheckoutContext.Provider value={context}>
              <NewCheckoutOptions
                name={states.user.name}
                clientPhone={states.user.clientPhone}
                renderName={() => (
                  <OrderNameInput
                    name={states.user.name}
                    isNameValid={states.validations.isNameValid}
                    setName={(name) => actions.user.setName(name)}
                    setIsNameValid={(status) => actions.validations.setIsNameValid(status)}
                  />
                )}
                renderPhone={() => (
                  <OrderPhoneInput
                    clientPhone={states.user.clientPhone}
                    isClientPhoneValid={states.validations.isClientPhoneValid}
                    setClientPhone={(phone) => actions.user.setClientPhone(phone)}
                    setIsClientPhoneValid={(status) => actions.validations.setIsClientPhoneValid(status)}
                  />
                )}
              />

              <CardFeedbackModal
                errorType={states.others.errorType}
                isShow={states.modals.showPaymentFeedbackModal}
                onClose={() => actions.modals.setShowPaymentFeedbackModal(false)}
              />

              <PaymentStepModal
                step={states.modals.processingModalType}
                isModalVisible={states.modals.showProcessingPaymentModal}
                onClose={() => actions.modals.setShowProcessingPaymentModal(false)}
              />

              <ConfirmCvvModal
                showCvvModal={states.modals.showCvvModal}
                isUnableToConfirmCvv={isUnableToConfirmCvv}
                onClose={() => {
                  actions.modals.setShowCvvModal(false);
                  actions.others.setIsLoading(false);
                }}
                onConfirm={() => {
                  actions.others.setIsLoading(false);

                  actions.modals.setProcessingModalType(PaymentStepProcessEnum.paying);
                  actions.modals.setShowProcessingPaymentModal(true);
                  actions.modals.setCvvModalConfirmed(true);
                }}
                actions={{
                  handleConfirmAction: handleSendOrder,
                  setCvvValue: actions.order.setCvvValue,
                  setIsLoading: actions.others.setIsLoading,
                  setShowCvvModal: actions.modals.setShowCvvModal,
                  setPaymentOption: actions.order.setPaymentOption,
                  setShowPaymentFeedbackModal: actions.modals.setShowPaymentFeedbackModal
                }}
              />
            </CheckoutContext.Provider>

            {hasUserSignUpAccess && (
              <div className="terms">
                <Terms action={getTranslation('order.sendYourOrder')} />
              </div>
            )}
          </div>
        </div>

        <Footer className={!isOpen && !isAbleToOrder ? 'closed' : ''} showDynamicInfo={isTotalValueElementHidden}>
          <SummaryFooter
            values={cart.values}
            isVisible={isTotalValueElementHidden}
            couponSegment={coupon.selectedCoupon?.segment}
          />

          {!isOpen && !isAbleToOrder && (
            <>
              <h2>{getTranslation('floatingFooter.currentlyClosed')}</h2>
              <span>{getTranslation('floatingFooter.notAcceptingNewOrders')}</span>
            </>
          )}

          <S.ButtonWrapper>
            <Button
              isGhost={false}
              isDisabled={
                couponLoading ||
                states.others.isLoading ||
                states.order.isZeroedOrder ||
                states.modals.showProcessingPaymentModal ||
                states.order.isLoyaltyDiscountHigherThanSubtotal
              }
              onClick={(): void => {
                handleSubmit();
                handleTrackEventWhenSendOrder();
              }}
              data-test="btn-send-order"
              isHidden={states.modals.showProcessingPaymentModal}
            >
              <S.ButtonContent isLoading={states.others.isLoading}>
                {states.others.isLoading && (
                  <span className="loading">
                    <Loader width={32} height={32} />
                    {`${getTranslation('order.sendingOrder')}...`}
                  </span>
                )}

                <div className="content">
                  <span>
                    {isIntegratedPixPaymentMethodSelected
                      ? getTranslation('order.payAndMakeOrder')
                      : getTranslation('splash.placeAnOrder')}
                  </span>

                  <ArrowRight width={15} height={15} />
                </div>
              </S.ButtonContent>
            </Button>
          </S.ButtonWrapper>

          <GooglePayCheckoutButton
            style={{ display: 'none' }}
            totalPrice={String(cart.total)}
            merchantName={cart.establishment}
            onConfirm={(): void => actions.validations.setIsWaitingRevalidation(true)}
            onGooglePayReady={(result: ReadyToPayChangeResponse): void => {
              dispatch(setIsGooglePayReady(result.isReadyToPay));
            }}
          />
        </Footer>

        <ItemEdit item={states.others.editItem} onClose={(): void => actions.others.setEditItem(null)} />
      </S.Container>
    </>
  );
};

export default Order;
