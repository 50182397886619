/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

interface ContainerProps {
  $modalShown?: boolean;
}

export const Container = styled.div<ContainerProps>`
  text-align: left;

  ${({ $modalShown }) =>
    $modalShown &&
    css`
      overflow: hidden;
    `}
`;

export const CustomHeader = styled.header`
  ${({ theme }) => css`
    padding: 1rem;
    border-bottom: 1px solid ${theme.colors.gray300};

    gap: 0.5rem;
    display: flex;
    justify-content: space-between;

    button {
      width: auto;
      height: auto;
    }
  `}
`;

export const CustomHeaderText = styled.h1`
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.687rem;
`;

export const FooterButtons = styled.div`
  margin: 16px;
`;

export const Button = styled.button<{ secondary?: boolean }>`
  width: 100%;
  height: 48px;
  margin-top: 15px;

  border: none;
  border-radius: 5px;

  font-size: 16px;
  font-weight: 500;

  color: ${({ theme }) => theme.colors.backgroundColor};
  background: ${({ theme }) => theme.brandColors.primary};

  ${({ secondary }) =>
    secondary &&
    css`
      font-size: 14px;
      text-decoration: underline;

      background: transparent;
      color: ${({ theme }) => theme.brandColors.primary};
    `}
`;

export const SuggestionsWrapper = styled.ul<{ $isHorizontalLayout: boolean }>`
  ${({ $isHorizontalLayout }) => css`
    padding: 1rem 0.5rem;

    gap: 0.5rem;
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 10.85rem);

    ${$isHorizontalLayout &&
    css`
      grid-template-columns: 1fr;
    `}
  `}
`;
