import { all, put, takeLatest } from 'redux-saga/effects';

import { getTabCodeInfo } from '~/services';
import { TabInfo } from '~/interfaces/general';

import { LocalOrdersActionTypes } from '.';
import { addTabInfo, cleanTabInfo, getTabInfo } from './actions';

type FetchTabInfoRequest = ReturnType<typeof getTabInfo>;

export function* fetchTabInfo({ payload }: FetchTabInfoRequest) {
  const { callback, hash, aId } = payload;
  try {
    const tabInfo = (yield getTabCodeInfo(hash, aId)) as TabInfo;

    yield put(addTabInfo(tabInfo));

    callback(true);
  } catch (error) {
    yield put(cleanTabInfo());

    callback();
  }
}

export default all([takeLatest(LocalOrdersActionTypes.GET_TAB_INFO, fetchTabInfo)]);
