import React from 'react';

const SpainFlagIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <circle id="border" cx="17" cy="17" r="16.5" fill="#D80027" stroke="#DDE2EB" />

      <mask id="mask0_1504_4661" maskUnits="userSpaceOnUse" x="1" y="1" width="32" height="32">
        <circle cx="17" cy="17" r="16" fill="#FFDA44" />
      </mask>

      <g mask="url(#mask0_1504_4661)">
        <path d="M-1.92017 25.4559H35.1198V10.2159H-1.92017V26.4559Z" fill="#FFDA44" />
      </g>
    </svg>
  );
};

export default SpainFlagIcon;
