import { action } from 'typesafe-actions';
import { Scheduling } from '@goomerdev/goomer-toolbox/src/enums';

import { SchedulingTypes } from './types';

export const addSelectedList = (selectedList: Scheduling[]) => action(SchedulingTypes.ADD_SELECTED_LIST, selectedList);

export const addDeliveryList = (deliveryList: Scheduling[]) => action(SchedulingTypes.ADD_DELIVERY_LIST, deliveryList);

export const addTakeawayList = (takeawayList: Scheduling[]) => action(SchedulingTypes.ADD_TAKEAWAY_LIST, takeawayList);

export const setHasSchedulingList = (hasSchedulingList: boolean) =>
  action(SchedulingTypes.SET_HAS_SCHEDULING_LIST, hasSchedulingList);
