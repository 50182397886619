import currency from 'currency.js';

import { TabStatusEnum } from '~/interfaces/enums';
import { formatPayment, truncateAmount } from '~/utils';
import {
  BillItem,
  BillExtra,
  TabDetails,
  CartProduct,
  BillResponse,
  PaymentReceiptResponse
} from '~/interfaces/general';

export interface FormatBillProps {
  code: string;
  mode: string;
  tabName?: string;
  tableCode?: string;
  isAbrahao?: boolean;
  bill?: BillResponse;
  status: TabStatusEnum;
  payments: PaymentReceiptResponse[];
}

const formatTabInfo = ({ code, mode, bill, status, payments, tableCode, isAbrahao }: FormatBillProps): TabDetails => {
  let products: CartProduct[] = [];

  const subtotal = currency(bill?.subtotal || 0, { precision: 2 }).value;
  const service = currency(bill?.service || 0, { precision: 2 }).value;
  const serviceTax = currency(bill?.service_tax || 0, { precision: 2 }).value;
  const total = currency(bill?.total || 0, { precision: 2 }).value;

  if (bill) {
    products = bill.products?.map((item: BillItem) => ({
      tabName: item.tab_name,
      quantity: item.quantity,
      subtotal: truncateAmount(item.price * item.quantity),
      product: {
        id: item.code,
        name: item.name,
        description: '',
        price: item.price
      },
      optionals: item.extras?.map((optional: BillExtra, index: number) => ({
        ...optional,
        id: index,
        indexPosition: index,
        value: optional.price,
        unitPrice: isAbrahao && optional.item_price ? optional.item_price : undefined
      }))
    }));
  }

  let paid = 0;

  payments.forEach((payment: PaymentReceiptResponse) => {
    const paidValue = currency(payment.value_cents, { fromCents: true }).value;
    paid = currency(paid).add(paidValue).value;
  });

  const isBillPaid = !!subtotal && paid >= subtotal;
  const remaining = isBillPaid ? 0 : currency(subtotal).subtract(paid).value;

  const formattedPayments = payments.map((payment: PaymentReceiptResponse) =>
    formatPayment({
      code,
      mode,
      payment,
      remaining,
      tableCode,
      total: subtotal
    })
  );

  return {
    status,
    isBillPaid,
    payments: formattedPayments,
    cart: {
      products,
      values: {
        paid,
        total,
        service,
        subtotal,
        serviceTax
      }
    }
  };
};

export default formatTabInfo;
