import { useCallback } from 'react';

import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { PaymentMethod } from '~/interfaces/general';

import useFormatCreditCard from '../useFormatCreditCard';
import useHandlePaymentTexts from '../useHandlePaymentTexts';

interface GetPaymentMethodDescriptionProps {
  paymentMethod?: PaymentMethod;
}

export interface UseGetPaymentMethodDescriptionProps {
  getPaymentMethodDescription: ({ paymentMethod }: GetPaymentMethodDescriptionProps) => string;
}

export default function useGetPaymentMethodDescription(): UseGetPaymentMethodDescriptionProps {
  const { formatCreditCard } = useFormatCreditCard();
  const { handlePaymentTexts } = useHandlePaymentTexts();

  const getPaymentMethodDescription = useCallback(
    ({ paymentMethod }: GetPaymentMethodDescriptionProps): string => {
      const {
        pix,
        nupay,
        mPagoQr,
        applePay,
        mPagoLink,
        googlePay,
        vrPagueLink,
        tunaCheckout,
        integratedPix,
        mPagoCheckout
      } = PaymentCategoryEnum;

      const flag = paymentMethod?.flag;
      const category = paymentMethod?.category;
      const cardDescription = paymentMethod?.cardDescription;

      const formattedCardDescription = cardDescription
        ? formatCreditCard({
            flag,
            card: cardDescription,
            isCreditCardNumberHidden: false
          })
        : cardDescription;

      if (category === mPagoCheckout) {
        return formattedCardDescription || handlePaymentTexts(paymentMethod?.cardType || '');
      }

      if (!!category && (category === mPagoQr || category === mPagoLink || category === vrPagueLink)) {
        return handlePaymentTexts(category);
      }

      if (category === tunaCheckout) {
        return formattedCardDescription || '';
      }

      const isIntegratedPaymentMethodsList = [pix, nupay, applePay, googlePay, integratedPix];

      if (isIntegratedPaymentMethodsList.includes(category as PaymentCategoryEnum)) {
        return handlePaymentTexts(category || '') || '';
      }

      return `${handlePaymentTexts(category || '')} ${flag || ''}`;
    },
    [formatCreditCard, handlePaymentTexts]
  );

  return { getPaymentMethodDescription };
}
