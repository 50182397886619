import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { CartOptional } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { RequiredGroup } from '~/pages/detail/[productId]';
import { Option, OptionGroup, OptionPricingTypeEnum } from '~/interfaces';

import OptionGroupComponent from '../OptionGroup';

import { Container } from './styles';

export type OptionEditActionType = 'add' | 'remove';
export type OptionListActionType = 'add' | 'edit' | 'remove' | 'toggle';

export interface OptionListOnChangeProps {
  option: CartOptional;
  type: OptionListActionType;
  edit?: OptionEditActionType;
  removeOption?: CartOptional;
  pricingType?: OptionPricingTypeEnum;
}

export interface OptionListProps {
  isViewMode?: boolean;
  flowList: OptionGroup[];
  optionals: OptionGroup[];
  selectedPriceFlowIdList: string[];
  selectedOptionals: CartOptional[];
  handleIsDoneStep: (props: RequiredGroup) => void;
  onChange: (props: OptionListOnChangeProps) => void;
}

const OptionList: React.FC<OptionListProps> = ({
  onChange,
  flowList,
  optionals,
  handleIsDoneStep,
  selectedOptionals,
  isViewMode = false,
  selectedPriceFlowIdList
}) => {
  const haveComments = useSelector(
    (state: IApplicationState) => state.establishment.settings?.mm_order_show_observations
  );

  //** Render optional groups and child flow groups recursively */
  const renderGroup = useCallback(
    (optional: OptionGroup) => {
      const optionFlowList = (option: Option, parentGroupId: string, treeFlowId?: string): JSX.Element[] => {
        const usedFlowList: OptionGroup[] = [];

        //** Adds an id for each option in a flow, with a | separator between each flow step (Ex: 1-11|2-21|3-31) */
        option.flow_ids?.map((flowId) => {
          const flowOptions = flowList.find(({ id }) => id === flowId);

          const parentFlowId = `${parentGroupId}-${option.id}`;

          if (flowOptions) {
            usedFlowList.push({
              ...flowOptions,
              parentFlowId: treeFlowId ? `${treeFlowId}|${parentFlowId}` : parentFlowId
            });
          }
        });

        return usedFlowList.map(renderGroup);
      };

      const parentGroupId = String(optional.id || 0);

      return (
        <>
          <OptionGroupComponent
            {...optional}
            onChange={onChange}
            isViewMode={isViewMode}
            handleIsDoneStep={handleIsDoneStep}
            selectedOptionals={selectedOptionals}
            selectedPriceFlowIdList={selectedPriceFlowIdList}
            key={`${parentGroupId}-${optional.parentFlowId}`}
          />

          {optional.options.map((option) => optionFlowList(option, parentGroupId, optional.parentFlowId))}
        </>
      );
    },
    [flowList, handleIsDoneStep, isViewMode, onChange, selectedOptionals, selectedPriceFlowIdList]
  );

  return <Container haveComments={haveComments}>{optionals.map(renderGroup)}</Container>;
};

export default OptionList;
