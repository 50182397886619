import React, { useCallback, useContext, useRef, useState } from 'react';

import axios from 'axios';
import FadeIn from 'react-fade-in';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useTranslator } from '~/hooks';
import { SearchHeader } from '~/components';
import Modal from '~/components/Modal/Modal';
import { Address } from '~/interfaces/general';
import { formatCep, removeAccents } from '~/utils';
import { AddressContext } from '~/components/Address/AddressList';

import { AddressInfo } from '..';
import { AddressFormContext } from '../..';

import * as S from './styles';

interface NeighborhoodInfo {
  id: number;
  cep?: string;
  name: string;
  neighborhood: string;
  neighborhood_id: number;
}

interface FormSearchProps {
  onClose: () => void;
  shouldDisplay: boolean;
}

const FormSearch = ({ onClose, shouldDisplay }: FormSearchProps): JSX.Element => {
  const containerRef = useRef(null);

  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<NeighborhoodInfo[]>([]);

  const { getTranslation } = useTranslator();
  const { isGoomerColor } = useContext(AddressContext);
  const { state, setCep, cityId, cityName, setStreet, setNeighborhood, setNeighborhoodId } =
    useContext(AddressFormContext);

  const fetchAddresses: (currentPage: number, searchTerm: string) => Promise<void> = useCallback(
    async (currentPage, searchTerm) => {
      if (!cityId) return;

      if (!currentPage) return;

      if (searchTerm.length >= 3) {
        try {
          setIsLoading(true);
          const formatedTerm = removeAccents(searchTerm);

          const { data } = await axios.get(
            `${
              process.env.NEXT_PUBLIC_ADDRESSES ?? ''
            }/cities/${cityId}/address?search=${formatedTerm}&page=${currentPage}&limit=${
              process.env.NEXT_PUBLIC_ADDRESS_LIMIT ?? ''
            }`
          );

          if (data.length === 0) {
            throw new Error(`#15 ${getTranslation('address.fetchCityError')}`);
          }

          setIsLoading(false);
          setPage(currentPage);
          setLastPage(data.pages);

          if (currentPage > 1) {
            return setSearchResults((results) => [...results, ...data.addresses]);
          }

          return setSearchResults(data.addresses);
        } catch (error) {
          toast.error((error as Error)?.message);
        }
      }

      setPage(1);
      setLastPage(1);
      setIsLoading(false);
      setSearchResults([]);

      return;
    },
    [cityId, getTranslation]
  );

  const updateSearch = (newSearchText: string): void => {
    setSearchText(newSearchText);
    fetchAddresses(1, newSearchText);
  };

  const loadMoreResults = useCallback(() => {
    fetchAddresses(page + 1, searchText);
  }, [fetchAddresses, page, searchText]);

  const handleSetAddress = useCallback(
    (item: NeighborhoodInfo) => {
      setCep(item?.cep ? formatCep(item.cep) : undefined);
      setStreet(item.name);
      setNeighborhood(item.neighborhood);
      setNeighborhoodId(item.neighborhood_id);

      onClose();
    },
    [onClose, setCep, setNeighborhood, setNeighborhoodId, setStreet]
  );

  const getFormattedNeighborhoodAddress: (neighborhoodInfo: NeighborhoodInfo) => Address = useCallback(
    (neighborhoodInfo) => {
      return {
        id: '',
        number: '',
        complement: '',
        state: state || '',
        city: cityName || '',
        street: neighborhoodInfo.name,
        neighborhood: neighborhoodInfo.neighborhood,
        cep: formatCep(neighborhoodInfo?.cep || '')
      };
    },
    [cityName, state]
  );

  return (
    <S.Content>
      <Modal isPageLike isContentFullPage isShow={shouldDisplay} onClose={onClose} headerTitle="busca">
        <SearchHeader
          delay={2000}
          term={searchText}
          onChange={updateSearch}
          isGoomerColor={isGoomerColor}
          placeholder={getTranslation('address.streetNameOrZipcode')}
        />

        <S.SearchContainer shouldHidePaddingTop>
          {searchResults.length <= 0 && (
            <div className="message">
              <p>
                {!isLoading && (
                  <>
                    {searchText.length >= 3
                      ? getTranslation('address.neighborhoodNotFound')
                      : getTranslation('address.enterStreetNameOrZipcode')}
                  </>
                )}
              </p>
            </div>
          )}

          {searchResults.length > 0 && (
            <InfiniteScroll
              loader={<></>}
              className="results"
              next={loadMoreResults}
              hasMore={page < lastPage}
              dataLength={searchResults.length}
              scrollableTarget={containerRef.current}
            >
              {searchResults.map((neighborhoodInfo: NeighborhoodInfo, index) => (
                <FadeIn data-test="address-item" className="address-item" key={index} delay={100}>
                  <S.AddressInfoWrapper>
                    <AddressInfo
                      onClick={(): void => handleSetAddress(neighborhoodInfo)}
                      address={getFormattedNeighborhoodAddress(neighborhoodInfo)}
                    />
                  </S.AddressInfoWrapper>
                </FadeIn>
              ))}
            </InfiniteScroll>
          )}
        </S.SearchContainer>
      </Modal>
    </S.Content>
  );
};

export default FormSearch;
