import React, { useCallback, useEffect, useState } from 'react';

import { useTranslator } from '~/hooks';
import { cnpjMask, cpfMask, validateCNPJ, validateCPF } from '~/utils';

import { Input, InputErrorMessage } from './styles';

interface InputCpfOrCnpjProps {
  id?: string;
  dataTest?: string;
  required: boolean;
  validate?: boolean;
  isOnlyCpf?: boolean;
  defaultValue: string;
  onChange: (cpfCnpj: string, isValid: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const InputCpfOrCnpj: React.FC<InputCpfOrCnpjProps> = ({
  id,
  onChange,
  required,
  dataTest,
  defaultValue,
  validate = false,
  isOnlyCpf = false
}) => {
  const [cpfCnpj, setCpfCnpj] = useState<string>(defaultValue);
  const [hasError, setHasError] = useState<boolean>(false);

  const { getTranslation } = useTranslator();

  useEffect(() => {
    if (validate) {
      setHasError(true);
    }
  }, [validate]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const pureValue = value.replace(/\D/g, '');

      const isValid = validateCPF({ cpf: pureValue }) || (!isOnlyCpf && validateCNPJ({ cnpj: pureValue }));
      if (pureValue.length === 11 || hasError) {
        setHasError(!isValid);
      }

      const maskedValue = pureValue.length <= 11 || isOnlyCpf ? cpfMask(pureValue) : cnpjMask(pureValue);

      setCpfCnpj(maskedValue);
      onChange(maskedValue, isValid);
    },
    [isOnlyCpf, hasError, onChange]
  );

  return (
    <>
      <Input
        id={id}
        max={255}
        type="text"
        value={cpfCnpj}
        maxLength={255}
        title="cpf-cnpj"
        inputMode="decimal"
        required={required}
        hasError={hasError}
        data-test={dataTest}
        className="sentry-mask"
        placeholder="000.000.000-00"
        onChangeCapture={handleChange}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): boolean =>
          validateCPF({ cpf: event.target.value }) || validateCNPJ({ cnpj: event.target.value })
        }
      />

      <InputErrorMessage showError={hasError}>
        {getTranslation('general.invalidDocument', {
          document: isOnlyCpf
            ? getTranslation('general.cpf')
            : getTranslation('general.cpf') + ' / ' + getTranslation('general.cnpj')
        })}
        !
      </InputErrorMessage>

      <InputErrorMessage showError={hasError && !cpfCnpj}>
        {getTranslation('general.shouldInsertDocument', {
          document: isOnlyCpf
            ? getTranslation('general.cpf')
            : getTranslation('general.cpf') + ' / ' + getTranslation('general.cnpj')
        })}
      </InputErrorMessage>
    </>
  );
};

export default React.memo(InputCpfOrCnpj);
