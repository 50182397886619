import { useMemo } from 'react';

import currency from 'currency.js';
import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import ProgressBar from '~/components/ProgressBar';
import { IApplicationState } from '~/redux-tools/store';

import { SummaryListItem } from '../SummaryListItem';

import * as S from './styles';

interface PaymentSummaryProgressBarProps {
  valuePaid: number;
  missingValue: number;
  hasSeparation?: boolean;
}

const PaymentSummaryProgressBar: (props: PaymentSummaryProgressBarProps) => JSX.Element = ({
  valuePaid,
  missingValue,
  hasSeparation = false
}) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { getTranslation } = useTranslator();

  const percentagePaid: number = useMemo(() => {
    if (valuePaid) {
      if (missingValue <= 0) {
        return 100;
      }

      const totalBill = currency(valuePaid).add(missingValue).value;

      return currency(valuePaid).divide(totalBill).multiply(100).value;
    }

    return 0;
  }, [missingValue, valuePaid]);

  return (
    <S.ProgressBarWrapper aria-label={getTranslation('receiptInfo.accountSummary')} hasSeparation={hasSeparation}>
      <S.ProgressBarHeaderWrapper>
        <SummaryListItem
          isStrong
          isSucceeded
          price={convertToCurrency(valuePaid)}
          description={`${getTranslation('general.paid')}:`}
        />

        {missingValue > 0 && (
          <SummaryListItem
            isStrong
            isAttention
            price={convertToCurrency(missingValue)}
            description={`${getTranslation('general.stillToBePaid')}:`}
          />
        )}
      </S.ProgressBarHeaderWrapper>

      <ProgressBar
        barHeight={6}
        color={theme.colors.success}
        progressPercentage={percentagePaid}
        aria-label={getTranslation('receiptInfo.progressBar')}
      />
    </S.ProgressBarWrapper>
  );
};

export { PaymentSummaryProgressBar };
