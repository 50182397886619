/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';

export const Container = styled.label`
  position: relative;

  .label {
    display: block;

    font-weight: 600;
    text-align: left;
    line-height: 150%;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.textColor};
  }

  input {
    margin: 0;
    width: 100%;
  }

  big {
    font-size: 1.2em;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.darkGray};
  }

  .input-error {
    margin: 0.25rem 0 0 1rem;
  }

  .input-helper {
    position: absolute;
    top: 0.25rem;
    right: 0.5rem;

    color: ${({ theme }) => theme.colors.mediumGray};
  }
`;

export const InputRow = styled.div`
  min-width: 200px;

  gap: 1rem;
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  > * {
    flex-grow: 1;
  }

  input {
    min-width: auto;
  }
`;
