import React, { useState, useCallback, useMemo, ReactText } from 'react';

import { toast } from 'react-toastify';

import { useTranslator } from '~/hooks';
import CheckIcon from '~/assets/CheckIcon';
import { DiscountTag } from '~/components';
import { convertToCurrency, truncateAmount } from '~/utils';
import { OptionPricingTypeEnum, PricePromotion } from '~/interfaces';

import { OptionEditActionType, OptionListActionType, OptionListOnChangeProps } from '../OptionList';

import * as S from './styles';

export interface RadioCheckProps {
  value?: string;
  quantity?: number;
  id?: number | string;
  elementFlowId?: string;
}

export interface OptionOnChange {
  hash?: string;
  check?: boolean;
  groupName: string;
  optionValue: string;
  value: number | string;
  code?: number | string | null | undefined;
  type?: 'check' | 'decrease' | 'increase' | 'radio';
}

interface CommonProps {
  id: number;
  hash?: string;
  repeat?: number;
  groupName: string;
  isSimple?: boolean;
  comboPrice?: number;
  optionValue: string;
  isAvailable?: boolean;
  indexPosition?: number;
  price: number | string;
  isGoomerColor?: boolean;
  elementId?: string | number;
  radioCheck?: RadioCheckProps[];
  pricingType?: OptionPricingTypeEnum;
  specs?: { min: number; max: number };
  code?: number | string | null | undefined;
  onChangeOptional?: (props: OptionListOnChangeProps) => void;
}

export interface OptionComponentProps extends CommonProps {
  info?: string;
  title: string;
  imageUrl?: string;
  className?: string;
  optionValue: string;
  description?: string;
  isViewMode?: boolean;
  elementFlowId?: string;
  promotion?: PricePromotion;
  isSmallDescription?: boolean;
  type: 'check' | 'number' | 'radio';
  onChange?: (props: OptionOnChange) => void;
}

interface ElemmentProps extends CommonProps {
  onChange?: (props: OptionOnChange) => void;
}

let toastId: ReactText = 0;

export const Radio: React.FC<ElemmentProps> = ({
  id,
  hash,
  code,
  price,
  onChange,
  isSimple,
  groupName,
  elementId,
  comboPrice,
  radioCheck,
  pricingType,
  optionValue,
  indexPosition,
  onChangeOptional,
  isGoomerColor = false
}) => {
  const handleOnChanges = (): void => {
    if (typeof price === 'number' && onChangeOptional) {
      return onChangeOptional({
        type: 'toggle',
        option: {
          id,
          hash,
          code,
          pricingType,
          quantity: 1,
          indexPosition,
          name: optionValue,
          price: comboPrice ? comboPrice : price,
          isComboOptional: typeof comboPrice !== 'undefined'
        }
      });
    }

    if (onChange) {
      return onChange({
        code,
        hash,
        groupName,
        optionValue,
        check: true,
        value: price,
        type: 'radio'
      });
    }
  };

  const handleElementName = (): string => {
    if (isSimple) {
      return optionValue;
    }

    return `${optionValue}-${elementId}`;
  };

  const handleChecked = (): boolean => {
    if (isSimple) {
      return !!radioCheck?.find((item) => item?.value === optionValue);
    }

    return !!radioCheck?.find(({ id, elementFlowId }) => (elementFlowId ? elementFlowId : id) === elementId);
  };

  return (
    <S.RadioC isGoomerColor={isGoomerColor}>
      <input
        type="radio"
        id={String(elementId)}
        checked={handleChecked()}
        name={handleElementName()}
        onChange={handleOnChanges}
      />
      <span className="mark" data-test="btn-optional-radio" />
    </S.RadioC>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const CheckBox: React.FC<ElemmentProps> = React.memo(
  ({
    id,
    hash,
    code,
    price,
    onChange,
    elementId,
    groupName,
    radioCheck,
    comboPrice,
    optionValue,
    isAvailable,
    pricingType,
    indexPosition,
    onChangeOptional,
    isGoomerColor = false
  }) => {
    const { getTranslation } = useTranslator();

    const [checked, setIsChecked] = useState(
      !!radioCheck?.find(({ id, elementFlowId }) => (elementFlowId ? elementFlowId : id) === elementId)
    );

    const isDisabled = useMemo(() => !isAvailable && !checked, [isAvailable, checked]);

    const handleOnChanges: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
      if (isDisabled) {
        if (!toast.isActive(toastId)) {
          toastId = toast.info(getTranslation('general.optionsLimitReached'), { className: 'toast' });
        }

        return;
      }

      const isTargetChecked = event.target.checked;
      setIsChecked(isTargetChecked);

      const onChangeAction = (type: OptionListActionType): void => {
        if (!onChangeOptional) {
          return;
        }

        return onChangeOptional({
          type,
          option: {
            id,
            hash,
            code,
            pricingType,
            quantity: 1,
            indexPosition,
            name: optionValue,
            price: comboPrice ? comboPrice : Number(price),
            isComboOptional: typeof comboPrice !== 'undefined'
          }
        });
      };

      if (typeof price === 'number' && isTargetChecked && onChangeOptional) {
        return onChangeAction('add');
      }

      if (typeof price === 'number' && !isTargetChecked && onChangeOptional) {
        return onChangeAction('remove');
      }

      if (onChange) {
        return onChange({
          hash,
          code,
          groupName,
          optionValue,
          value: price,
          type: 'check',
          check: isTargetChecked
        });
      }
    };

    return (
      <S.CheckboxC isGoomerColor={isGoomerColor}>
        <input
          type="checkbox"
          checked={!!checked}
          id={String(elementId)}
          name={`${name}-${elementId}`}
          onChange={(event): void => handleOnChanges(event)}
        />

        <span className="mark" data-test="btn-optional-check">
          <CheckIcon />
        </span>
      </S.CheckboxC>
    );
  }
);

// eslint-disable-next-line @typescript-eslint/naming-convention
const NumberBtn: React.FC<ElemmentProps> = ({
  id,
  code,
  hash,
  price,
  onChange,
  groupName,
  elementId,
  comboPrice,
  radioCheck,
  optionValue,
  isAvailable,
  pricingType,
  indexPosition,
  onChangeOptional,
  isGoomerColor = false
}) => {
  const numberItem = radioCheck?.find(({ id, elementFlowId }) => (elementFlowId ? elementFlowId : id) === elementId);
  const [selectedQuantity, setSelectedQuantity] = useState<number>(numberItem?.quantity! || 0);

  const { getTranslation } = useTranslator();

  const handleOnChanges: (action: 'decrease' | 'increase') => void = (action) => {
    if (!isAvailable && action === 'increase') {
      if (!toast.isActive(toastId)) {
        toastId = toast.info(getTranslation('general.optionsLimitReached'), { className: 'toast' });
      }

      return;
    }

    const onChangeAction = (type: OptionListActionType, quantity: number, edit?: OptionEditActionType): void => {
      if (typeof price !== 'number' || !onChangeOptional) {
        return;
      }

      return onChangeOptional({
        edit,
        type,
        option: {
          id,
          hash,
          code,
          pricingType,
          indexPosition,
          name: optionValue,
          quantity: quantity,
          price: comboPrice ? comboPrice : price,
          isComboOptional: typeof comboPrice !== 'undefined'
        }
      });
    };

    if (action === 'increase') {
      setSelectedQuantity(selectedQuantity + 1);

      if (selectedQuantity > 0) {
        return onChangeAction('edit', selectedQuantity + 1, 'add');
      }

      return onChangeAction('add', selectedQuantity + 1);
    }

    if (action === 'decrease') {
      setSelectedQuantity(selectedQuantity - 1);

      if (selectedQuantity > 1) {
        return onChangeAction('edit', selectedQuantity - 1, 'remove');
      }

      return onChangeAction('remove', 1);
    }

    if (onChange) {
      return onChange({
        code,
        value: 1,
        groupName,
        optionValue,
        type: 'increase'
      });
    }
  };

  return (
    <S.NumberBtnC>
      <button
        type="button"
        className="btn"
        data-test="btn-optional-minus"
        disabled={selectedQuantity === 0}
        onClick={(): void => handleOnChanges('decrease')}
      >
        <S.TiMinus size="22" isGoomerColor={isGoomerColor} isDisabled={String(selectedQuantity === 0)} />
      </button>

      <span className="value">{selectedQuantity}</span>

      <button
        type="button"
        className="btn"
        data-test="btn-optional-plus"
        onClick={(): void => handleOnChanges('increase')}
      >
        <S.TiPlus size="22" isGoomerColor={isGoomerColor} isDisabled={String(!isAvailable)} />
      </button>
    </S.NumberBtnC>
  );
};

const OptionComponent: React.FC<OptionComponentProps> = ({
  id,
  hash,
  code,
  type,
  info,
  specs,
  price,
  title,
  repeat,
  children,
  imageUrl,
  onChange,
  promotion,
  className,
  groupName,
  radioCheck,
  comboPrice,
  optionValue,
  isAvailable,
  pricingType,
  description,
  elementFlowId,
  onChangeOptional,
  isSimple = false,
  indexPosition = 0,
  isViewMode = false,
  isGoomerColor = false,
  isSmallDescription = false,
  ...rest
}) => {
  const elementId = elementFlowId ? elementFlowId : id;

  const defaultElementProps = {
    id,
    code,
    name,
    hash,
    price,
    onChange,
    elementId,
    groupName,
    radioCheck,
    comboPrice,
    pricingType,
    optionValue,
    indexPosition,
    isGoomerColor,
    onChangeOptional
  };

  const types: Record<string, () => JSX.Element> = {
    radio: () => <Radio {...defaultElementProps} isSimple={isSimple} />,
    check: () => <CheckBox {...defaultElementProps} isAvailable={isAvailable} />,
    number: () => <NumberBtn {...defaultElementProps} specs={specs} repeat={repeat} isAvailable={isAvailable} />
  };

  const handleAdditionalValue = useCallback(() => {
    if (typeof price === 'number' && groupName === 'pricing') {
      return `${convertToCurrency(price)}`;
    }

    if (typeof price === 'number') {
      return `+${convertToCurrency(price)}`;
    }

    return price;
  }, [groupName, price]);

  const handleLineClick = useCallback(() => {
    const theOptionElement = typeof document !== 'undefined' && document.getElementById(String(elementId));

    if (theOptionElement) {
      theOptionElement.click();
    }
  }, [elementId]);

  return (
    <S.Container {...rest} onClick={handleLineClick} className={className}>
      <S.Content>
        {imageUrl && (
          <S.ImageWrapper>
            <S.Image alt="Imagem do opcional" src={imageUrl} />
          </S.ImageWrapper>
        )}

        <S.OptionInfoWrapper>
          <S.OptionName>{title}</S.OptionName>

          {info && <S.OptionInfo className="info">{info}</S.OptionInfo>}

          <S.PriceWrapper>
            {!!price && price !== 0 && (
              <S.OptionPrice $hasPromotion={!!promotion} className={`value ${isSmallDescription ? '-small' : ''}`}>
                <span className="old-value">{handleAdditionalValue()}</span>

                {!!promotion && <DiscountTag percentage={truncateAmount(promotion.percentage_flag)} />}
              </S.OptionPrice>
            )}

            {!!promotion && (
              <S.OptionPrice>
                <span>{convertToCurrency(promotion.price)}</span>
              </S.OptionPrice>
            )}
          </S.PriceWrapper>
        </S.OptionInfoWrapper>
      </S.Content>

      <S.ActionWrapper data-test="wrapper-optional-check">
        {children}

        {!isViewMode && !!types[type] && types[type]()}
      </S.ActionWrapper>
    </S.Container>
  );
};

export default OptionComponent;
