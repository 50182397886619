import React, { useState, useEffect, useMemo, useCallback } from 'react';

import InputMask from 'react-input-mask';

import { useTranslator } from '~/hooks';
import validateDate from '~/utils/validateDate';

import { ErrorMessage } from '../Input/styles';

import * as S from './styles';

interface InputDateProps {
  id?: string;
  required?: boolean;
  isDisabled?: boolean;
  defaultValue?: string;
  errorMessage?: string;
  isGoomerColor?: boolean;
  onChange: (dateValue: string, isValid: boolean) => void;
}

const InputDate: React.FC<InputDateProps> = ({
  onChange,
  errorMessage,
  required = false,
  defaultValue = '',
  id = 'input-date',
  isDisabled = false,
  isGoomerColor = false,
  ...rest
}) => {
  const { getTranslation } = useTranslator();

  const [selectedDate, setSelectedDate] = useState(defaultValue);
  const [withError, setWithError] = useState('');

  const getCleanValue = (value: string): string => value.replace(/\D/g, '');

  const cleanSelectedDate = useMemo(() => getCleanValue(selectedDate), [selectedDate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    const cleanValue = getCleanValue(value);

    const isValid = cleanValue.length === 8 ? validateDate(value) : false;

    setSelectedDate(value);
    onChange(cleanValue.length === 0 ? '' : value, isValid);
  };

  const onBlur = useCallback(
    () => setWithError(cleanSelectedDate.length === 0 || validateDate(selectedDate) ? '' : 'error'),
    [cleanSelectedDate.length, selectedDate]
  );

  useEffect(() => {
    if (cleanSelectedDate.length === 0 || cleanSelectedDate.length === 8) {
      onBlur();
    }
  }, [cleanSelectedDate.length, onBlur]);

  return (
    <S.Container disabled={isDisabled} isGoomerColor={isGoomerColor} error={withError}>
      <InputMask
        id={id}
        max={255}
        type="text"
        onBlur={onBlur}
        maxLength={255}
        mask="99/99/9999"
        autoComplete="off"
        inputMode="decimal"
        required={required}
        disabled={isDisabled}
        onChange={handleChange}
        placeholder="__/__/____"
        defaultValue={defaultValue}
        {...rest}
      />

      {withError && <ErrorMessage>{errorMessage || getTranslation('general.invalidDate')}</ErrorMessage>}
    </S.Container>
  );
};

export default InputDate;
