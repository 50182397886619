import { useMemo } from 'react';

const NEXT_PUBLIC_COOKIES_DOMAIN = process.env.NEXT_PUBLIC_COOKIES_DOMAIN || '';

export default function useHasGoomerDomain() {
  const hasGoomerDomain: boolean = useMemo(() => {
    if (typeof window !== 'undefined') {
      const hostName: string = window.location.hostname || '';
      const isLocalhost: boolean = hostName.includes('localhost');
      const domain: string = isLocalhost ? '.goomer.localhost' : NEXT_PUBLIC_COOKIES_DOMAIN;

      return hostName.includes(domain);
    }

    return true;
  }, []);

  return hasGoomerDomain;
}
