import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { api } from '~/services/api';
import { ErrorInfo } from '~/interfaces/general';
import { LoginOptionEnum } from '~/interfaces/enums';

interface GetRegisteredUserProps {
  param: number | string;
  mode: LoginOptionEnum;
  addErrorLog: (log: ErrorInfo) => void;
  getTranslation: (key: string, interpolation?: Record<string, any>) => string;
}

export default async function getRegisteredUser({
  mode,
  param,
  addErrorLog,
  getTranslation
}: GetRegisteredUserProps): Promise<AxiosResponse> {
  const apiPath = `${process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL}/signup/${param}`;

  const loginOption: Record<string, string> = {
    [LoginOptionEnum.phone]: '$t(general.phone, lowercase)',
    [LoginOptionEnum.email]: '$t(general.email, lowercase)',
    [LoginOptionEnum.google]: '$t(general.google, lowercase)'
  };

  try {
    const response = await api.get(apiPath, {
      params: { mode },
      validateStatus: (status) => status === 200 || status === 404
    });

    return response;
  } catch (error) {
    const loginOptionMode = loginOption[mode];

    addErrorLog({
      code: '#11',
      message: `#11 Erro ao verificar o login via ${loginOptionMode}`,
      error: `${error}`,
      request: apiPath,
      response: '',
      payload: JSON.stringify({ param, mode })
    });

    toast.error(
      getTranslation('errorMessage.errorToCheckYourLogin', { loginOptionMode: getTranslation(loginOptionMode) })
    );

    throw new Error(`GET error: ${error}`);
  }
}
