import styled, { css } from 'styled-components';

import { SectionWrapper, SectionWrapperProps } from '../SectionWrapper';

export const Container = styled(SectionWrapper).attrs((props: SectionWrapperProps) => props)`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);

  .button-container {
    > span {
      width: 100%;
      padding: 0 16px;
    }
  }
`;

export const TotalInfo = styled.div<{ $hideButton?: boolean }>`
  ${({ theme, $hideButton }) => css`
    padding: 0;
    margin: ${$hideButton ? '0' : '0 0 12px'};

    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
      font-weight: 800;
      font-size: 14px;
      line-height: 100%;
      color: ${theme.colors.textColor};
    }
  `}
`;
