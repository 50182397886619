import styled, { css, DefaultTheme } from 'styled-components';

import { SimpleFlashMessageProps } from '.';

const containerModifiers = {
  welcome: (theme: DefaultTheme) => css`
    z-index: 30;

    padding: 16px;
    box-shadow: 0px -8px 10px rgba(48, 51, 60, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background: ${theme.colors.backgroundColor};

    .emoji {
      margin-bottom: 8px;
    }

    .message {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;

      strong {
        font-size: 16px;
        font-weight: 700;
      }
    }
  `,
  bag: (theme: DefaultTheme) => css`
    height: 48px;

    background: ${theme.brandColors.actionBackground};

    .description {
      margin-left: 0.625rem;

      font-size: 1rem;
      font-weight: 600;
      line-height: 150%;

      color: ${theme.brandColors.actionText};

      @media screen and (min-width: 720px) {
        font-size: 21px;
      }
    }

    svg {
      path {
        fill: ${theme.brandColors.actionText};
      }
    }
  `
};

// eslint-disable-next-line @typescript-eslint/no-type-alias
type ContainerProps = Pick<SimpleFlashMessageProps, 'positionFromBottom' | 'type'>;

export const Container = styled.figure<ContainerProps>`
  ${({ type, theme, positionFromBottom }) => css`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    bottom: ${positionFromBottom};

    width: 100%;
    max-width: 800px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.5s;

    &.hide {
      transform: translate(-50%, 100%);

      opacity: 0;
      visibility: 0;
    }

    ${containerModifiers[type](theme)}
  `}
`;
