import { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { differenceInSeconds, differenceInMinutes, addSeconds } from 'date-fns';

import { IApplicationState } from '~/redux-tools/store';

const SIXTY_SECONDS = 60;
const ONE_HOUR = 60 * 60;
const ONE_SECOND_IN_MILLISECONDS = 1000;
const TEN_SECONDS_IN_MILLISECONDS = 10000;

export default function useCountdownDateComparison(time?: Date | string) {
  const dispatch = useDispatch();

  const { order } = useSelector((state: IApplicationState) => state.pixPayment);

  const nowWithTimeMargin = addSeconds(new Date(), 5);

  const [minutes, setMinutes] = useState(time && differenceInMinutes(new Date(time), nowWithTimeMargin));
  const [seconds, setSeconds] = useState(time && differenceInSeconds(new Date(time), nowWithTimeMargin));

  const [countdownExpired, setCountdownExpired] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);

  const formatTime = (time: number) => {
    return time.toString().padStart(2, '0');
  };

  const intervalTimer = useMemo(() => {
    return minutes !== undefined && minutes <= 2 ? ONE_SECOND_IN_MILLISECONDS : TEN_SECONDS_IN_MILLISECONDS;
  }, [minutes]);

  const resetCountdown = () => {
    setCountdownExpired(false);
    setShowCancelButton(false);
  };

  const formattedCountdown = () => {
    if ((!minutes && typeof minutes !== 'number') || (!seconds && typeof seconds !== 'number')) {
      return;
    }

    if (minutes > 0) {
      return `${minutes + 1} minutos`;
    }

    return `${formatTime(seconds)} segundos`;
  };

  useEffect(() => {
    if (time === undefined) {
      return;
    }

    let interval: number | undefined;

    const timer = () => {
      const secondsRemaining = Math.abs(differenceInSeconds(new Date(), new Date(time)));
      const divisorForMinutes = secondsRemaining % ONE_HOUR;
      const divisorForSeconds = divisorForMinutes % SIXTY_SECONDS;

      const seconds = Math.ceil(divisorForSeconds);
      const minutes = Math.abs(Math.floor(divisorForMinutes / SIXTY_SECONDS));

      setMinutes(minutes);
      setSeconds(seconds);

      const orderSendDate = order?.date || '';
      const pixDateExpiration = time;

      const differenceBetweenSendAndExpiration = differenceInSeconds(
        new Date(pixDateExpiration),
        new Date(orderSendDate)
      );

      const timeToShowCancelButton = differenceBetweenSendAndExpiration - SIXTY_SECONDS;

      const isAbleToShowCancelButton = secondsRemaining < timeToShowCancelButton;

      if (isAbleToShowCancelButton) {
        setShowCancelButton(true);
      }
    };

    if (differenceInSeconds(new Date(time), new Date()) > 0) {
      interval = window.setInterval(timer, intervalTimer);
    } else {
      setCountdownExpired(true);

      window.clearInterval(interval);
    }

    return () => window.clearInterval(interval);
  }, [seconds, time, dispatch, showCancelButton, order?.date, intervalTimer]);

  return {
    seconds,
    minutes,
    resetCountdown,
    showCancelButton,
    countdownExpired,
    formattedCountdown,
    setCountdownExpired
  };
}
