import styled from 'styled-components';

export const Content = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: transparent;
`;
