import styled, { css } from 'styled-components';

export const Container = styled.ul`
  padding: 0 1rem;
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.lightestGray};

  > li:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
  }
`;

export const SummaryListItem = styled.li`
  display: flex;
  justify-content: space-between;
`;

export const RegularText = styled.p<{ isDiscount?: boolean }>`
  ${({ theme, isDiscount }) => css`
    padding: 1rem 0;

    font-weight: 500;
    line-height: 1rem;
    font-size: 0.875rem;
    font-family: 'Manrope', sans-serif;

    color: ${theme.colors.gray500};

    ${isDiscount && `color: ${theme.colors.success}`}
  `}
`;

export const BoldText = styled.b`
  padding: 0.75rem 0;

  font-weight: 600;
  line-height: 150%;
  font-size: 1.125rem;
  font-family: 'Manrope', sans-serif;
`;
