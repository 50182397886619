import { AiOutlineCheckCircle } from 'react-icons/ai';

import * as S from './styles';

export interface StatusFooterProps {
  text: string;
  icon?: React.ReactNode;
}

export default function StatusFooter({ icon, text }: StatusFooterProps): JSX.Element {
  return (
    <S.Container>
      {icon ?? <AiOutlineCheckCircle size={20} aria-label="Ícone de verificação" />}

      {text}
    </S.Container>
  );
}
