export function cleanCep(cep: string): string {
  return cep?.replace('.', '').replace('-', '');
}

export function addHyphen(cep: string): string {
  if (cep?.length < 6) return cep;

  return `${cep?.substring(0, 5)}-${cep?.substring(5, 8)}`;
}

export default function formatCep(cep: string): string {
  return `${addHyphen(cleanCep(cep))}`;
}
