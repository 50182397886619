import { useCallback, useEffect, useState } from 'react';

import Sockette from 'sockette';
import { useSelector } from 'react-redux';
import { SocketReadyStateEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { websocket } from '~/services/tabPaymentSocket';
import { IApplicationState } from '~/redux-tools/store';
import { TabPaymentDetailsResponse } from '~/interfaces/general';
import { LocalOrdersEnum, ReverseLocalOrdersEnum } from '~/interfaces/enums';

export type SocketteEvent = Event & {
  target: {
    readyState: number;
  };
};

export interface UsePaymentSocketExports {
  isOnline: boolean;
  isSocketOpen: boolean;
  resetSocket: () => void;
}

export interface UsePaymentSocketProps {
  onEventAction: (eventData: TabPaymentDetailsResponse) => void;
}

export default function usePaymentSocket({ onEventAction }: UsePaymentSocketProps): UsePaymentSocketExports {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { mode: localOrder, number: localNumber } = useSelector((state: IApplicationState) => state.localOrders);

  const [isSocketOpen, setIsSocketOpen] = useState(false);
  const [isSocketResetting, setIsSocketResetting] = useState(false);
  const [isSocketConnecting, setIsSocketConnecting] = useState(false);
  const [wsConnection, setWsConnection] = useState<Sockette | undefined>(undefined);

  const [isOnline, setIsOnline] = useState(typeof navigator !== 'undefined' && navigator.onLine);

  const handleOpenConnection = useCallback((event: SocketteEvent) => {
    const isOpen = event?.target?.readyState === SocketReadyStateEnum.OPEN;

    setIsSocketOpen(isOpen);
    setIsSocketResetting(false);
    setIsSocketConnecting(false);
  }, []);

  const handleEvent = useCallback(
    (event: MessageEvent) => {
      const data: TabPaymentDetailsResponse = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
      onEventAction(data);
    },
    [onEventAction]
  );

  const handleCloseConnection = useCallback(() => {
    setIsSocketOpen(false);
  }, []);

  const handleConnectionError = useCallback(() => {
    setIsSocketConnecting(false);
  }, []);

  const resetSocket = useCallback(() => {
    if (wsConnection) {
      wsConnection.close();
    }

    setIsSocketOpen(false);
    setWsConnection(undefined);
    setIsSocketResetting(true);
  }, [wsConnection]);

  useEffect(() => {
    const onlineListener = () => setIsOnline(true);
    const offlineListener = () => setIsOnline(false);

    const visibilityListener = () => {
      if (document.visibilityState === 'visible') {
        if ((wsConnection || isSocketOpen) && !isSocketResetting) {
          resetSocket();
        }
      }
    };

    window.addEventListener('online', onlineListener);
    window.addEventListener('offline', offlineListener);

    document.addEventListener('visibilitychange', visibilityListener);

    return () => {
      window.removeEventListener('online', onlineListener);
      window.removeEventListener('offline', offlineListener);

      document.removeEventListener('visibilitychange', visibilityListener);
    };
  }, [isOnline, isSocketOpen, isSocketResetting, resetSocket, wsConnection]);

  useEffect(() => {
    if (!isOnline && isSocketOpen && !!wsConnection) {
      wsConnection.close();

      setWsConnection(undefined);
    }

    return () => {
      if (isSocketOpen && !!wsConnection) {
        wsConnection.close();

        setWsConnection(undefined);
      }
    };
  }, [isOnline, isSocketOpen, wsConnection]);

  useEffect(() => {
    if (!settings || !settings.id || !localOrder || isSocketConnecting) return;

    const paymentSocket = () => {
      return websocket({
        code: localNumber,
        handleOpenConnection,
        handleConnectionError,
        handleCloseConnection,
        storeID: settings.id || 0,
        handleEvent: (event: MessageEvent) => handleEvent(event),
        socketMode: ReverseLocalOrdersEnum[localOrder as LocalOrdersEnum]
      });
    };

    if (!isSocketOpen && isOnline) {
      setIsSocketConnecting(true);
      setWsConnection(paymentSocket());
    }
  }, [
    isOnline,
    settings,
    localOrder,
    localNumber,
    handleEvent,
    isSocketOpen,
    isSocketConnecting,
    handleOpenConnection,
    handleCloseConnection,
    handleConnectionError
  ]);

  return { isOnline, resetSocket, isSocketOpen };
}
