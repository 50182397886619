import React, { createContext, useCallback, useState, useMemo } from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import {
  RiMailLine,
  RiEarthLine,
  RiMapPinLine,
  RiBankCardLine,
  RiFileListLine,
  RiContrast2Line,
  RiLogoutBoxRLine
} from 'react-icons/ri';

import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { FeaturesEnum, LoggedAreaMenuItemEnum, QueryParam } from '~/interfaces/enums';
import { CardNavigator, GoomerExperience, HeaderSecondary, Modal } from '~/components';
import { useHasFeatureAccess, useLogout, usePaymentMethods, useTranslator } from '~/hooks';

import { PersonalData, Notifications, Cards, Addresses } from './components';

import * as S from './styles';

export interface ListItem {
  url?: string;
  title: string;
  hidden?: boolean;
  icon: JSX.Element;
  onClick?: () => void;
  description?: string;
}

export interface LoggedAreaContextProps {
  selectedMenu: LoggedAreaMenuItemEnum;
  setSelectedMenu: (selectedMenu: LoggedAreaMenuItemEnum) => void;
}

export const LoggedAreaContext = createContext<LoggedAreaContextProps>({} as LoggedAreaContextProps);

const LoggedArea: React.FC = () => {
  const history = useRouter();

  const urlParams = new URLSearchParams(window.location.search);
  const shouldRedirectToProfile = urlParams.get(QueryParam.editProfile) === 'true';

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { name: username } = useSelector((state: IApplicationState) => state.user.data);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const [selectedMenu, setSelectedMenu] = useState<LoggedAreaMenuItemEnum>(
    shouldRedirectToProfile ? LoggedAreaMenuItemEnum.personalData : LoggedAreaMenuItemEnum.default
  );

  const { handleLogout } = useLogout();
  const { getTranslation } = useTranslator();
  const { mercadoPagoCheckout, tunaCreditCardCheckout } = usePaymentMethods();

  const gaTrackToolbar = useCallback((): void => {
    return GoogleAnalytics.trackEvent(gaEvents.profileSignOut, {
      // eslint-disable-next-line camelcase
      establishment_id: settings?.id
    });
  }, [settings?.id]);

  const redirectToMenu = useCallback(() => {
    history.push('/menu');
  }, [history]);

  const handleGoBack = useCallback(() => {
    if (shouldRedirectToProfile) {
      history.push('/order');
      return;
    }

    redirectToMenu();
  }, [history, redirectToMenu, shouldRedirectToProfile]);

  const handleModalClose = useCallback(() => {
    if (shouldRedirectToProfile) {
      return handleGoBack();
    }

    return setSelectedMenu(LoggedAreaMenuItemEnum.default);
  }, [handleGoBack, shouldRedirectToProfile]);

  const handleLogoutClick = useCallback((): void => {
    handleLogout();
    gaTrackToolbar();

    redirectToMenu();
  }, [gaTrackToolbar, handleLogout, redirectToMenu]);

  const isAbrahaoStore = useMemo(() => settings?.is_abrahao || false, [settings?.is_abrahao]);
  const [hasNewDeliveryAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.NewDeliveryMenu });

  const menuItemList: ListItem[] = useMemo(
    () => [
      {
        icon: <RiFileListLine color={theme.colors.gray500} />,
        title: getTranslation(LoggedAreaMenuItemEnum.personalData),
        onClick: () => setSelectedMenu(LoggedAreaMenuItemEnum.personalData),
        description: getTranslation(`${LoggedAreaMenuItemEnum.personalData}Description`)
      },
      {
        icon: <RiMailLine color={theme.colors.gray500} />,
        title: getTranslation(LoggedAreaMenuItemEnum.notifications),
        onClick: () => setSelectedMenu(LoggedAreaMenuItemEnum.notifications),
        description: getTranslation(`${LoggedAreaMenuItemEnum.notifications}Description`)
      },
      {
        title: getTranslation(LoggedAreaMenuItemEnum.cards),
        icon: <RiBankCardLine color={theme.colors.gray500} />,
        hidden: !mercadoPagoCheckout && !tunaCreditCardCheckout,
        onClick: () => setSelectedMenu(LoggedAreaMenuItemEnum.cards),
        description: getTranslation(`${LoggedAreaMenuItemEnum.cards}Description`)
      },
      {
        icon: <RiMapPinLine color={theme.colors.gray500} />,
        title: getTranslation(LoggedAreaMenuItemEnum.addresses),
        onClick: () => setSelectedMenu(LoggedAreaMenuItemEnum.addresses),
        description: getTranslation(`${LoggedAreaMenuItemEnum.addresses}Description`)
      },
      {
        url: '/appearance',
        hidden: !isAbrahaoStore && !hasNewDeliveryAccess,
        title: getTranslation('morePage.appearance.title'),
        icon: <RiContrast2Line color={theme.colors.gray500} />,
        description: getTranslation('morePage.appearance.description')
      },
      {
        url: '/languages',
        hidden: !isAbrahaoStore && !hasNewDeliveryAccess,
        title: getTranslation('morePage.languages.title'),
        icon: <RiEarthLine color={theme.colors.gray500} />,
        description: getTranslation('morePage.languages.description')
      }
    ],
    [
      getTranslation,
      isAbrahaoStore,
      mercadoPagoCheckout,
      hasNewDeliveryAccess,
      theme.colors.gray500,
      tunaCreditCardCheckout
    ]
  );

  const renderMenu: () => JSX.Element = () => (
    <S.Container>
      <HeaderSecondary title={getTranslation('navigation.profile')} onBackAction={handleGoBack} />

      <S.ContentWrapper>
        <S.Content>
          <S.Greeting>
            <span>{`${getTranslation('general.greeting')},`}</span>

            <h2>{username}</h2>
          </S.Greeting>

          <S.Menu>
            <div className="list">
              {menuItemList
                .filter(({ hidden }) => !hidden)
                .map((menuItem) => (
                  <CardNavigator
                    key={menuItem.title}
                    icon={menuItem.icon}
                    title={menuItem.title}
                    url={menuItem?.url || ''}
                    onClick={menuItem.onClick}
                    description={menuItem.description}
                  />
                ))}
            </div>
          </S.Menu>
        </S.Content>

        <S.Footer>
          <CardNavigator
            onClick={handleLogoutClick}
            title={getTranslation('general.exit')}
            icon={<RiLogoutBoxRLine size={16} color={theme.colors.error} />}
          />

          <S.InfoMessage>
            <span>{getTranslation('loggedArea.message')}</span>
          </S.InfoMessage>

          <GoomerExperience />
        </S.Footer>
      </S.ContentWrapper>
    </S.Container>
  );

  const selectedMenuContent: Record<string, JSX.Element> = {
    [LoggedAreaMenuItemEnum.cards]: <Cards />,
    [LoggedAreaMenuItemEnum.default]: renderMenu(),
    [LoggedAreaMenuItemEnum.addresses]: <Addresses />,
    [LoggedAreaMenuItemEnum.personalData]: <PersonalData />,
    [LoggedAreaMenuItemEnum.notifications]: <Notifications />
  };

  return (
    <LoggedAreaContext.Provider
      value={{
        selectedMenu,
        setSelectedMenu
      }}
    >
      {selectedMenu === LoggedAreaMenuItemEnum.default ? (
        renderMenu()
      ) : (
        <Modal
          isPageLike
          isShow={true}
          ignoreOverflow
          isWithoutPadding
          isContentFullPage
          onClose={handleModalClose}
          headerTitle={getTranslation(selectedMenu)}
          containerBackgroundColor={theme.colors.gray200}
          titleBackgroundColor={theme.colors.backgroundColor}
        >
          {selectedMenuContent[selectedMenu]}
        </Modal>
      )}
    </LoggedAreaContext.Provider>
  );
};

export default LoggedArea;
