import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DeliveryZoneTypeEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';

import useAddressIsNewDashboard from './useAddressIsNewDashboard';

export default function useAddressIsNeighborhoodTax() {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { isNewDashboard } = useAddressIsNewDashboard();

  const isNeighborhoodTax: boolean = useMemo(() => {
    if (isNewDashboard) {
      return (
        !!settings?.mm_delivery_zone_options_neighborhood_enabled &&
        settings?.mm_delivery_zone_type === DeliveryZoneTypeEnum.neighborhood
      );
    }

    return false;
  }, [settings, isNewDashboard]);

  return { isNeighborhoodTax };
}
