import { Thirky } from '~/interfaces/general';

interface GetThirkySearchProps {
  userId: string;
  storeId: number;
}

const THIRKY_KEY = process.env.NEXT_PUBLIC_THIRKY_KEY || '';

export default async function getThirkySearch({ userId, storeId }: GetThirkySearchProps): Promise<Thirky> {
  const apiPath = `https://qrcoud.com/ecd/v2/search/?groupId=${THIRKY_KEY}&placeId=${storeId}&user=${userId}`;

  const response = await fetch(apiPath, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  });

  if (response.status === 200) {
    const responseJson = (await response.json()) as Thirky;

    return responseJson;
  }

  throw new Error('Error on Thirky');
}
