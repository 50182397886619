import media from 'styled-media-query';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  margin: 0;
  width: 100%;
  padding: 0 1rem;
  max-width: 50rem;
  min-height: 3.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  background: ${({ theme }) => theme.colors.backgroundColor};

  ${media.greaterThan('medium')`
    margin: 0 auto;
  `}
`;

export const TitleWrapper = styled.div<{ isSuggestion?: boolean }>`
  width: 100%;
  height: 90%;
  margin-left: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  > h1 {
    margin-right: 35px;

    font-weight: 800;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.textColor};
  }

  ${({ isSuggestion }) =>
    isSuggestion &&
    css`
      display: grid;
      grid-template-columns: 85% 15%;

      > h1 {
        margin-right: 0;
        margin-left: 15%;
      }
    `}
`;

export const BackButton = styled.div`
  transform: rotate(180deg);

  width: 40px;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }
`;

export const Button = styled.button`
  border: none;

  font-size: 12px;
  text-align: right;
  color: #7f8f9f;

  background: none;
`;
