import styled from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const Container = Modal
  ? styled(Modal).attrs((props: ModalProps) => props)`
      z-index: 1003;

      > .container {
        padding-bottom: 0;

        background: ${({ theme }) => theme.colors.lightestGray};

        > .title {
          background: ${({ theme }) => theme.colors.backgroundColor};
        }

        .content {
          overflow: unset;
        }
      }

      .content {
        overflow: unset;
      }
    `
  : styled.div<ModalProps>``;
