import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { PaymentMethod } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { useHasFeatureAccess, useTranslator } from '~/hooks';
import { FeaturesEnum, LocalOrdersEnum, PaymentTypeEnum } from '~/interfaces/enums';

import { Container, Item } from './styles';

interface OptionsListProps {
  goBack: () => void;
  isContentVisible?: boolean;
  method: PaymentMethod | undefined;
  changePage: (selectedMethod: PaymentMethod) => void;
}

const OptionsList: React.FC<OptionsListProps> = ({ method, goBack, changePage, isContentVisible = false }) => {
  const localOrders = useSelector((state: IApplicationState) => state.localOrders.mode);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const [hasQrCodeOnlinePaymentAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.QrCodeOnlinePayment });

  const { getTranslation } = useTranslator();

  const isCounterMode = useMemo(() => localOrders === LocalOrdersEnum.balcony, [localOrders]);

  const debitFlags =
    isCounterMode && hasQrCodeOnlinePaymentAccess
      ? settings?.mm_payment_qrcode_debit_enabled && settings?.mm_payment_qrcode_debit_flags
      : settings?.mm_payment_debit_enabled && settings?.mm_payment_debit_flags;

  const creditFlags =
    isCounterMode && hasQrCodeOnlinePaymentAccess
      ? settings?.mm_payment_qrcode_credit_enabled && settings?.mm_payment_qrcode_credit_flags
      : settings?.mm_payment_credit_enabled && settings?.mm_payment_credit_flags;

  const voucherFlags =
    isCounterMode && hasQrCodeOnlinePaymentAccess
      ? settings?.mm_payment_qrcode_voucher_enabled && settings.mm_payment_qrcode_voucher_flags
      : settings?.mm_payment_voucher_enabled && settings.mm_payment_voucher_flags;

  const handlePaymentTextCategory = useMemo(() => {
    if (localOrders.length > 0) {
      return PaymentTypeEnum.onAttendant;
    }

    return PaymentTypeEnum.onDelivery;
  }, [localOrders]);

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  return (
    <Container
      isSmall
      ignoreOverflow
      onClose={handleGoBack}
      isShow={isContentVisible}
      headerTitle={getTranslation('payment.chooseFlag')}
    >
      {creditFlags &&
        Array.isArray(creditFlags) &&
        method?.category === PaymentCategoryEnum.credit &&
        creditFlags.map(
          ({ name, checked }, index) =>
            checked && (
              <Item
                key={index}
                onClick={(): void =>
                  changePage({
                    flag: name,
                    type: handlePaymentTextCategory,
                    category: PaymentCategoryEnum.credit
                  })
                }
              >
                <p>
                  <span>{getTranslation('payment.methods.credit')} </span>
                  {name}
                </p>
              </Item>
            )
        )}

      {debitFlags &&
        Array.isArray(debitFlags) &&
        method?.category === PaymentCategoryEnum.debit &&
        debitFlags.map(
          ({ name, checked }, index) =>
            checked && (
              <Item
                key={index}
                onClick={(): void =>
                  changePage({
                    flag: name,
                    type: handlePaymentTextCategory,
                    category: PaymentCategoryEnum.debit
                  })
                }
              >
                <p>
                  <span>{getTranslation('payment.methods.debit')} </span>
                  {name}
                </p>
              </Item>
            )
        )}

      {voucherFlags &&
        Array.isArray(voucherFlags) &&
        method?.category === PaymentCategoryEnum.voucher &&
        voucherFlags.map(
          ({ name, checked }, index) =>
            checked && (
              <Item
                key={index}
                onClick={(): void =>
                  changePage({
                    flag: name,
                    type: handlePaymentTextCategory,
                    category: PaymentCategoryEnum.voucher
                  })
                }
              >
                <p>
                  <span>{name}</span>
                </p>
              </Item>
            )
        )}
    </Container>
  );
};

export default OptionsList;
