import * as S from './styles';

interface DiscountTagProps {
  percentage: number;
}

const DiscountTag = ({ percentage }: DiscountTagProps): JSX.Element => {
  return (
    <S.Container>
      <S.DiscountTag>-{percentage}%</S.DiscountTag>
    </S.Container>
  );
};

export default DiscountTag;
