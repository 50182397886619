import { Reducer } from 'redux';

import { IFingerPrintState, FingerPrintActionTypes } from './types';

const initialState = {
  isScriptLoaded: false
};

const FingerPrintReducer: Reducer<IFingerPrintState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FingerPrintActionTypes.CHANGE_SCRIPT_STATUS:
      return {
        ...state,
        isScriptLoaded: payload
      };

    default:
      return state;
  }
};

export default FingerPrintReducer;
