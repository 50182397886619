import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    background: ${theme.colors.gray300};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    margin: 0;
    padding: 24px 16px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    background: ${theme.colors.backgroundColor};

    > .icon {
      display: flex;

      svg {
        height: 14px;
        margin: 0 8px 0 0;
      }
    }

    > p {
      font-size: 14px;
      font-weight: 800;
      line-height: 100%;
      color: ${theme.colors.textColor};

      > span {
        font-size: 14px;
        font-weight: 800;
        line-height: 100%;
        text-transform: capitalize;
      }
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    width: 100%;

    background: ${theme.colors.backgroundColor};

    > .tab {
      margin: 0 0 24px;
      padding: 0 16px;
      border-bottom: 1px solid ${theme.colors.gray300};

      &:last-of-type {
        margin: 0;
        border: none;
      }
    }
  `}
`;

export const TabTitle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 0 8px;

    background: ${theme.colors.backgroundColor};

    > p {
      font-size: 1rem;
      font-weight: bold;
      color: ${theme.colors.textColor};
    }
  `}
`;

export const ListHeader = styled.div`
  ${({ theme }) => css`
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: ${theme.colors.backgroundColor};

    h3 {
      font-size: 12px;
      font-weight: 700;
      color: ${theme.colors.textColor};
    }
  `}
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid ${theme.colors.gray300};

    display: flex;
    align-items: center;
    flex-direction: column;

    .product {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      p,
      strong {
        color: ${theme.colors.gray600};
      }

      strong {
        font-weight: 600;
      }
    }

    .optional {
      width: 100%;
      margin: 4px 0 0;
      padding-left: 8px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      p,
      strong {
        font-size: 0.857rem;
        color: ${theme.colors.gray500};
      }

      strong {
        font-weight: 600;
      }
    }

    :last-child {
      padding-bottom: 12px;
      border-bottom: 1px solid transparent;
    }
  `}
`;
