import { action } from 'typesafe-actions';

import { CheckIn, ILocalOrder, TabInfo } from '~/interfaces/general';

import { LocalOrdersActionTypes } from './types';

export const addCheckIn = (checkIn: CheckIn) => {
  return action(LocalOrdersActionTypes.ADD_CHECK_IN, checkIn);
};

export const addOrder = (order: ILocalOrder) => {
  return action(LocalOrdersActionTypes.ADD_ORDER, order);
};

export const setIsOperatorMode = (params: { isOperatorMode: boolean }) => {
  return action(LocalOrdersActionTypes.SET_IS_OPERATOR_MODE, params.isOperatorMode);
};

export const cleanCheckIn = () => action(LocalOrdersActionTypes.CLEAN_CHECK_IN);

export const getTabInfo = (payload: { callback: (isOk?: boolean) => void; hash: string; aId: string }) => {
  return action(LocalOrdersActionTypes.GET_TAB_INFO, payload);
};

export const addTabInfo = (payload: TabInfo) => {
  return action(LocalOrdersActionTypes.ADD_TAB_INFO, payload);
};

export const cleanTabInfo = () => action(LocalOrdersActionTypes.CLEAN_TAB_INFO);
