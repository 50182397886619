import { RiCalendarLine, RiTimeLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { TextWithIcon } from '~/components';
import { EstablishmentStatusEnum, PrettyEstablishmentStatusEnum } from '~/interfaces/enums';

import * as S from './styles';

export interface StoreStatusProps {
  status: EstablishmentStatusEnum;
  text: PrettyEstablishmentStatusEnum;
}

const StoreStatus: (props: StoreStatusProps) => JSX.Element = ({ text, status }) => {
  const { getTranslation } = useTranslator();

  const renderIcon: () => JSX.Element = () => {
    if (status === EstablishmentStatusEnum.scheduling) {
      return <RiCalendarLine size={20} />;
    }

    return <RiTimeLine size={20} />;
  };

  return (
    <S.Container status={status}>
      <TextWithIcon icon={renderIcon()} text={getTranslation(text)} />
    </S.Container>
  );
};

export default StoreStatus;
