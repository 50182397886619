export const shortWeekdays = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];

const padStartNumber = (minSize: number, number: number) => {
  let res = number.toString();
  while (res.length < minSize) {
    res = `0${res}`;
  }
  return res;
};

export const weekdayIsBetween = (date: Date, start: string, end: string) => {
  const weekday = date.getDay();
  const fStart = shortWeekdays.indexOf(start.toLowerCase().replace('á', 'a'));
  const fEnd = shortWeekdays.indexOf(end.toLowerCase().replace('á', 'a'));

  return (
    (fStart <= fEnd && fStart <= weekday && weekday <= fEnd) ||
    (fStart > fEnd && (fStart <= weekday || weekday <= fEnd))
  );
};

export const hourIsBetween = (hour: Date, start: string, end: string) => {
  const parsedStart = start
    .split(':')
    .map((item) => padStartNumber(2, Number(item)))
    .join(':');

  const parsedEnd = end
    .split(':')
    .map((item) => padStartNumber(2, Number(item)))
    .join(':');

  const fStart = parsedStart.replace(/\D/, '');
  const fEnd = parsedEnd.replace(/\D/, '');
  const fHour = `${padStartNumber(2, hour.getHours())}${padStartNumber(2, hour.getMinutes())}`;

  return (fStart < fEnd && fStart <= fHour && fHour <= fEnd) || (fStart > fEnd && (fStart <= fHour || fHour <= fEnd));
};

export const millisecondsToHours = (milliseconds: number) => (milliseconds / 1000 / 60 / 60).toFixed(1);
