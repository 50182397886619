import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { FeaturesEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import useHasFeatureAccess from '~/hooks/useHasFeatureAccess';

import { UseLoyaltyProgramHooksProps } from '..';

export type UseLoyaltyAccessProps = Pick<UseLoyaltyProgramHooksProps, 'hasLoyaltyProgramAccess'>;

export default function useLoyaltyAccess(): UseLoyaltyAccessProps {
  const localOrders = useSelector((state: IApplicationState) => state.localOrders);
  const { isViewMode } = useSelector((state: IApplicationState) => state.establishment);

  const [hasDeliveryLoyaltyProgramAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.LoyaltyProgram });
  const [hasQrCodeLoyaltyProgramAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.QrCodeLoyaltyProgram });

  const isLocalOrder: boolean = useMemo(() => localOrders.mode.length > 0, [localOrders]);

  const hasLoyaltyProgramAccess: boolean = useMemo(
    () => !isViewMode && (isLocalOrder ? hasQrCodeLoyaltyProgramAccess : hasDeliveryLoyaltyProgramAccess),
    [hasDeliveryLoyaltyProgramAccess, hasQrCodeLoyaltyProgramAccess, isLocalOrder, isViewMode]
  );

  return { hasLoyaltyProgramAccess };
}
