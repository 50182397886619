export default function getFormattedBirthdayApi(
  birthday: string,
  receivedSeparator: string,
  expectedSeparator: string
): string | undefined {
  if (!birthday) {
    return undefined;
  }

  const dateParts = birthday?.split(receivedSeparator);

  const formatedBirthday =
    dateParts?.length === 1
      ? undefined
      : `${dateParts?.[2]}${expectedSeparator}${dateParts?.[1]}${expectedSeparator}${dateParts?.[0]}`;

  return formatedBirthday;
}
