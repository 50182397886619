import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

import { UseLoyaltyProgramHooksProps } from '..';

export type UseLoyaltyRemainingAmountProps = Pick<UseLoyaltyProgramHooksProps, 'remainingAmountToFulfillTheProgram'>;

export default function useLoyaltyRemainingAmount(): UseLoyaltyRemainingAmountProps {
  const { total } = useSelector((state: IApplicationState) => state.cart);
  const { loyaltyProgram: establishmentLoyaltyProgram } = useSelector(
    (state: IApplicationState) => state.establishment
  );

  const remainingAmountToFulfillTheProgram: number = useMemo(() => {
    const remainingAmount: number = (establishmentLoyaltyProgram?.minimumOrderValue || 0) - total;

    if (remainingAmount < 0) return 0;

    return remainingAmount;
  }, [total, establishmentLoyaltyProgram]);

  return { remainingAmountToFulfillTheProgram };
}
