import { useCallback, useEffect, useMemo } from 'react';

import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';
import { QueryParam, SegmentTypeEnum } from '~/interfaces/enums';
import useGetCouponErrorMessage from '~/hooks/useGetCouponErrorMessage';
import useUserAuthenticationStatus from '~/hooks/useUserAuthenticationStatus';
import {
  cleanCoupon,
  fetchCouponRequest,
  clearSelectedCoupon,
  validateCouponRequest
} from '~/redux-tools/store/coupons/actions';

function useMenuCoupons(): void {
  const dispatch = useDispatch();
  const history = useRouter();

  const loyaltyCoupon = useSelector((state: IApplicationState) => state.cart.values.loyalty);
  const { error, origin, data: couponData } = useSelector((state: IApplicationState) => state.coupons);
  const { isFirstOrder: isUserFirstOrder } = useSelector((state: IApplicationState) => state.user.data);
  const { settings, coupons: establishmentCouponsList } = useSelector(
    (state: IApplicationState) => state.establishment
  );

  const { displayErrorMessage } = useGetCouponErrorMessage();
  const { isUserAuthenticated } = useUserAuthenticationStatus();

  const { selectedCoupon } = couponData;

  const firstOrderCoupon = useMemo(
    () => establishmentCouponsList?.find((coupon) => coupon.segment === SegmentTypeEnum.first_order),
    [establishmentCouponsList]
  );

  const shouldApplyOnlyFirstOrderCoupon = useMemo(
    () => !!firstOrderCoupon && firstOrderCoupon?.enabled && !error,
    [error, firstOrderCoupon]
  );

  const hasFirstOrderCouponAvailableToValidate = useMemo(
    () => !!isUserAuthenticated && !!isUserFirstOrder && !!firstOrderCoupon && (firstOrderCoupon?.enabled || false),
    [firstOrderCoupon, isUserAuthenticated, isUserFirstOrder]
  );

  const clearCouponQueryParam = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has(QueryParam.coupon)) {
      queryParams.delete(QueryParam.coupon);
      history.replace({
        search: queryParams.toString()
      });
    }
  }, [history]);

  useEffect(() => {
    if (!!hasFirstOrderCouponAvailableToValidate && !!firstOrderCoupon) {
      const afterAction = {
        afterValidation: clearCouponQueryParam
      };

      dispatch(clearSelectedCoupon());

      dispatch(
        validateCouponRequest({
          origin,
          afterAction,
          displayErrorMessage,
          coupon: firstOrderCoupon
        })
      );
    }
  }, [
    origin,
    dispatch,
    firstOrderCoupon,
    displayErrorMessage,
    clearCouponQueryParam,
    hasFirstOrderCouponAvailableToValidate
  ]);

  useEffect(() => {
    if (shouldApplyOnlyFirstOrderCoupon) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const couponCode = urlParams.get(QueryParam.coupon);

    if (!loyaltyCoupon && !!couponCode && !!settings && settings.mm_coupon_enabled) {
      dispatch(cleanCoupon());
      dispatch(fetchCouponRequest({ origin, couponCode, displayErrorMessage }));
    }
  }, [dispatch, displayErrorMessage, loyaltyCoupon, origin, settings, shouldApplyOnlyFirstOrderCoupon]);

  useEffect(() => {
    if (!isUserAuthenticated && selectedCoupon?.segment === SegmentTypeEnum.first_order) {
      dispatch(clearSelectedCoupon());
    }
  }, [dispatch, isUserAuthenticated, selectedCoupon?.segment]);
}

export default useMenuCoupons;
