import { useCallback, useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { showToast } from '~/utils';
import { Address } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { AddressPageModeEnum } from '~/interfaces/enums';
import { AddressContext } from '~/components/Address/AddressList';
import useUserAuthenticationStatus from '~/hooks/useUserAuthenticationStatus';
import { AddressFormContext } from '~/components/Address/AddressList/components/Form';
import { addCustomerAddressRequest, updateCustomerAddressRequest } from '~/redux-tools/store/user/actions';

export default function useAddresHandleSave() {
  const dispatch = useDispatch();

  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { id, cep, state, street, cityName, reference, complement, neighborhood, streetNumber, neighborhoodId } =
    useContext(AddressFormContext);
  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const { setSelectedView, setSelectedAddress } = useContext(AddressContext);

  const handleSave: (afterSave: () => void) => void = useCallback(
    (afterSave) => {
      const addErrorLog = () => {
        showToast({
          type: 'error',
          closeOnClick: true,
          text: 'Ocorreu um erro ao salvar o endereço. Tente novamente!'
        });

        afterSave?.();
      };

      const afterAction = (address: Address) => {
        showToast({ type: 'success', closeOnClick: true, text: 'Endereço salvo com sucesso!' });

        setSelectedAddress(address);
        setSelectedView(AddressPageModeEnum.list);

        afterSave?.();
      };

      const isEditing = !!id;

      const formatString = (value: string | null | undefined): string => value?.trim() || '';
      const formatReference = (value: string | null | undefined): string | undefined =>
        value ? value.trim() : undefined;

      const formattedAddress: Address = {
        cep: formatString(cep),
        state: formatString(state),
        city: formatString(cityName),
        street: formatString(street),
        neighborhood_id: neighborhoodId,
        number: formatString(streetNumber),
        id: isEditing && id ? id : undefined,
        complement: formatString(complement),
        reference: formatReference(reference),
        neighborhood: formatString(neighborhood)
      };

      if (isEditing) {
        dispatch(
          updateCustomerAddressRequest({
            isUserAuthenticated,
            address: formattedAddress,
            storeId: settings?.id || 0,
            functions: {
              addErrorLog,
              afterAction
            }
          })
        );

        return;
      }

      dispatch(
        addCustomerAddressRequest({
          isUserAuthenticated,
          address: formattedAddress,
          storeId: settings?.id || 0,
          functions: {
            addErrorLog,
            afterAction
          }
        })
      );

      return;
    },
    [
      cep,
      cityName,
      complement,
      dispatch,
      id,
      isUserAuthenticated,
      neighborhood,
      neighborhoodId,
      reference,
      setSelectedAddress,
      setSelectedView,
      settings?.id,
      state,
      street,
      streetNumber
    ]
  );

  return { handleSave };
}
