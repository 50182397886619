import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $hasMultipleImages: boolean }>`
  ${({ $hasMultipleImages }) => css`
    position: relative;

    cursor: pointer;

    > div {
      margin-bottom: 1.12rem;
    }

    .magnifier {
      position: absolute;
      right: 0.38rem;
      bottom: 1.88rem;

      margin: 0;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 5px;

      display: flex;
      align-items: center;
      pointer-events: none;
      justify-content: center;

      background: ${({ theme }) => theme.brandColors.primary};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.backgroundColor};
        }
      }
    }

    ${$hasMultipleImages &&
    css`
      > div {
        margin-bottom: 0;
      }
    `}
  `}
`;

export const Container = styled.div`
  width: calc(100vw - 2rem);

  > img {
    border-radius: 0.5rem;

    object-fit: cover;

    @media screen and (min-width: 720px) {
      height: 18.75rem;
    }
  }

  @media screen and (min-width: 800px) {
    width: calc(800px - 2rem);
  }
`;
