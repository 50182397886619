import { useCallback } from 'react';

import { convertToCurrency } from '~/utils';

import useTranslator from '../useTranslator';

interface UseConvertToCurrencyOrFreePropsReturn {
  convertToCurrencyOrFree: (value: number) => string;
}

export default function useConvertToCurrencyOrFree(): UseConvertToCurrencyOrFreePropsReturn {
  const { getTranslation } = useTranslator();

  const convertToCurrencyOrFree = useCallback(
    (value: number): string => {
      if (!value) return getTranslation('fee.free');
      if (value === 0) return getTranslation('fee.free');

      return convertToCurrency(value);
    },
    [getTranslation]
  );

  return { convertToCurrencyOrFree };
}
