import { ITimezone } from '~/interfaces/general';

interface ConvertToTimezoneProps {
  initialDate: Date;
  timezone?: ITimezone;
}

export default function convertToTimezone({ initialDate, timezone }: ConvertToTimezoneProps) {
  if (!timezone) {
    return initialDate;
  }

  const timezoneOffsetInNumber = parseInt(timezone?.utc || '0', 10);

  const convertedInUTCDate = new Date(
    Date.UTC(
      initialDate.getFullYear(),
      initialDate.getMonth(),
      initialDate.getDate(),
      initialDate.getHours() - timezoneOffsetInNumber,
      initialDate.getMinutes(),
      0
    )
  );

  return convertedInUTCDate;
}
