import { SchedulingConfig } from '@goomerdev/goomer-toolbox/src/enums';
import { ParsedOperationHoursList } from '@goomerdev/goomer-toolbox/src/interfaces';

import { getDateByWeekday, handleAcrossTheWeek } from '..';

interface GetValidWeekdaysProps {
  weekday: number;
  isNextWeek?: boolean;
  deliveryScheduling?: SchedulingConfig;
  parsedOperatingTime: ParsedOperationHoursList[];
}

export default function getValidWeekdays({
  weekday,
  isNextWeek = false,
  deliveryScheduling,
  parsedOperatingTime
}: GetValidWeekdaysProps):
  | {
      date: Date;
      weekday: number;
    }
  | false {
  const operatingTimesOnWeekday = parsedOperatingTime
    .map((schedule) => {
      if (weekday >= schedule.from && weekday <= schedule.to) {
        return { open: schedule.open, close: schedule.close };
      }

      if (schedule.from > schedule.to && handleAcrossTheWeek({ weekday, to: schedule.to, from: schedule.from })) {
        return { open: schedule.open, close: schedule.close };
      }

      return { open: '', close: '' };
    })
    .filter((item) => !!item.open && !!item.close);

  const date = getDateByWeekday({
    weekday,
    isNextWeek,
    operatingTimesOnWeekday,
    configs: deliveryScheduling
  });

  if (!date) return false;

  return { date, weekday };
}
