export default function truncateAmount(amount: number, digits = 2) {
  const pattern = new RegExp(`^-?\\d+(?:\\.\\d{0,${digits}})?`, 'g');

  const result = amount.toString().match(pattern);

  if (result === null) return 0;

  const [stringAmount] = result;
  const parsedAmount = parseFloat(stringAmount);

  return parsedAmount || 0;
}
