import { useCallback } from 'react';

import { OperationHours, ParsedOperationHoursList } from '@goomerdev/goomer-toolbox/src/interfaces';

import { useWeekdays } from '~/hooks';

export default function useOperatingTime(): {
  parseOperatingTime: (params: OperationHours) => ParsedOperationHoursList[];
} {
  const { weekdayValueList } = useWeekdays();

  const parseOperatingTime: (params: OperationHours) => ParsedOperationHoursList[] = useCallback(
    ({ hours }) => {
      if (hours.length === 0) return [];

      return hours.map((item) => {
        const fromIndex = weekdayValueList.indexOf(item.from.toLowerCase());
        const toIndex = weekdayValueList.indexOf(item.to.toLowerCase());

        return { ...item, to: toIndex, from: fromIndex };
      });
    },
    [weekdayValueList]
  );

  return { parseOperatingTime };
}
