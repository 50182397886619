import { MountedNupayOrder } from '~/interfaces/general';

export interface NupayState {
  readonly isNupayLoading: boolean;
  readonly order: MountedNupayOrder;
  readonly timeToExpireNupayOrder?: Date | string;
}

export const NupayStateActionTypes = {
  ADD_NUPAY_ORDER: '@@nupay/ADD_NUPAY_ORDER',
  CLEAN_NUPAY_ORDER: '@@nupay/CLEAN_NUPAY_ORDER',
  CANCEL_NUPAY_ORDER_ERROR: '@@nupay/CANCEL_NUPAY_ORDER_ERROR',
  CANCEL_NUPAY_ORDER_SUCCESS: '@@nupay/CANCEL_NUPAY_ORDER_SUCCESS',
  CANCEL_NUPAY_ORDER_REQUEST: '@@nupay/CANCEL_NUPAY_ORDER_REQUEST',
  ADD_NUPAY_TIME_TO_EXPIRE_ORDER: '@@nupay/ADD_NUPAY_TIME_TO_EXPIRE_ORDER',
  CLEAN_NUPAY_TIME_TO_EXPIRE_ORDER: '@@nupay/CLEAN_NUPAY_TIME_TO_EXPIRE_ORDER'
};
