import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;

  width: 100vw;

  height: 50px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};

  display: flex;
  justify-content: space-around;
  align-items: center;

  background: ${({ theme }) => theme.colors.backgroundColor};

  @media screen and (min-width: 720px) {
    max-width: 800px;
    margin: 0 auto;
  }
`;

export const NavigatorItemWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavigatorItem = styled.a<{ $active: boolean }>`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;

  > .count {
    position: absolute;
    top: 2px;
    left: 55%;

    width: 13px;
    height: 13px;
    border-radius: 13px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${({ theme }) => theme.colors.error};

    > p {
      display: block;

      font-style: normal;
      font-weight: bold;
      font-size: 8px;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.backgroundColor};
    }
  }

  > div {
    width: auto;
    height: 16px;
    margin-bottom: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      height: 16px;
      width: auto;

      fill: ${({ theme, $active }) => ($active ? theme.brandColors.primary : theme.colors.mediumGray)};

      > path {
        fill: ${({ theme, $active }) => ($active ? theme.brandColors.primary : theme.colors.mediumGray)};
      }
    }
  }

  > p {
    display: flex;
    align-items: center;

    color: ${({ theme, $active }) => ($active ? theme.brandColors.primary : theme.colors.mediumGray)};

    font-size: 0.75rem;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
  }
`;
