import { useContext, useEffect, useState, useRef, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { InputCpfOrCnpj } from '~/components';
import { validateCNPJ, validateCPF } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import OptionHeader from '~/components/Option/OptionHeader';
import { CheckoutContext as OrderContext } from '~/pages/order';
import { CheckoutContext as LocalOrderContext } from '~/pages/localOrder';

import * as S from './styles';

const DocumentInput: React.FC = () => {
  const isFirstRender = useRef(true);

  const localOrder = useSelector((state: IApplicationState) => state.localOrders.mode);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const [isValidCpfOrCnpj, setIsValidCpfOrCnpj] = useState<boolean>(false);

  const { getTranslation } = useTranslator();
  const { cpf, setCpf } = useContext(localOrder ? LocalOrderContext : OrderContext);

  const isRequiredCpf = useMemo(() => settings?.mm_receipt_required_cpf || false, [settings?.mm_receipt_required_cpf]);

  useEffect(() => {
    if (isRequiredCpf && cpf && isFirstRender.current) {
      const pureValue = cpf.replace(/\D/g, '');

      const isCpfFilled = pureValue.length > 0 && pureValue.length <= 11;

      const isValid = isCpfFilled ? validateCPF({ cpf: pureValue }) : validateCNPJ({ cnpj: pureValue });

      if (!isValid) {
        isFirstRender.current = false;
        return;
      }

      setIsValidCpfOrCnpj(true);
      isFirstRender.current = false;
    }
  }, [cpf, isRequiredCpf]);

  return (
    <S.Container>
      <OptionHeader
        isForm
        noPadding
        subtitle={false}
        isRequired={isRequiredCpf}
        isDone={isValidCpfOrCnpj && cpf.length > 0}
        title={`${getTranslation('general.cpf')}/${getTranslation('general.cnpj')}`}
      />

      <InputCpfOrCnpj
        defaultValue={cpf}
        id="input-cpf-cnpj"
        required={isRequiredCpf}
        dataTest="input-cpf-cnpj"
        onChange={(cpfCnpjValue: string, isValid: boolean): void => {
          setCpf(cpfCnpjValue, isValid);
          setIsValidCpfOrCnpj(isValid);
        }}
      />
    </S.Container>
  );
};

export default DocumentInput;
