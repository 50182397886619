import { api } from '~/services/api';
import { PocketMountedOrder } from '~/interfaces';
import { CartState } from '~/redux-tools/store/cart';
import { CheckoutErrorMessageEnum, ErrorsEnum, MercadoPagoErrorCodes } from '~/interfaces/enums';
import {
  ErrorInfo,
  CartProduct,
  CartOptional,
  MountedOrder,
  OrderPayload,
  CartProductItem
} from '~/interfaces/general';

export interface SendOrderProps {
  actionParam?: string;
  isPocketOrder?: boolean;
  abrahaoStoreId?: string;
  isInAppPayment?: boolean;
  selectedLanguage?: string | null;
  addErrorLog: (log: ErrorInfo) => void;
  setIsLoading: (status: boolean) => void;
  errorAction: (error?: ErrorsEnum) => void;
  mountedOrder: MountedOrder | PocketMountedOrder | any;
}

interface ResponseErrorCause {
  data: string;
  code: number;
  description: string;
}

const NEXT_PUBLIC_COOKIES_DOMAIN = process.env.NEXT_PUBLIC_COOKIES_DOMAIN || '';

export default async function sendOrder({
  actionParam,
  errorAction,
  addErrorLog,
  mountedOrder,
  setIsLoading,
  isPocketOrder,
  isInAppPayment,
  abrahaoStoreId,
  selectedLanguage
}: SendOrderProps) {
  setIsLoading(true);

  let responseData;

  let apiPath =
    isPocketOrder && abrahaoStoreId
      ? `${process.env.NEXT_PUBLIC_API_ABRAHAO}/${abrahaoStoreId}/order`
      : `${process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL_V2}/orders${actionParam ? `?action=${actionParam}` : ''}`;

  try {
    const hasAuthorizationHeader =
      !!api.defaults.headers.Authorization &&
      api.defaults.headers.Authorization !== 'undefined' &&
      api.defaults.headers.Authorization !== '';

    const headersAuthorization = hasAuthorizationHeader ? api.defaults.headers.Authorization : '';

    interface FormattedCartProductItem extends Omit<CartProductItem, 'price'> {
      value: number;
    }
    interface FormattedCartOptional extends Omit<CartOptional, 'price'> {
      value: number;
    }

    interface FormattedCartProduct extends Omit<CartProduct, 'product' | 'optionals'> {
      product: FormattedCartProductItem;
      optionals?: FormattedCartOptional[];
    }

    interface FormattedCartState extends Omit<CartState, 'products'> {
      products: FormattedCartProduct[];
    }

    interface FormattedOrderPayload extends Omit<OrderPayload, 'items'> {
      items?: FormattedCartProductItem[];
    }

    interface FormattedMountedOrder extends Omit<MountedOrder, 'cart' | 'order'> {
      cart: FormattedCartState;
      order: FormattedOrderPayload;
    }

    let formattedMountedOrder: FormattedMountedOrder | undefined = undefined;

    if (!isPocketOrder) {
      const mountedOrder_ = mountedOrder as MountedOrder;

      formattedMountedOrder = {
        ...mountedOrder_,
        order: {
          ...mountedOrder_.order,
          items: mountedOrder_.order.items?.reduce((previusItem, currentItem) => {
            previusItem.push({
              ...currentItem,
              value: currentItem.price
            });

            return previusItem;
          }, new Array<FormattedCartProductItem>())
        },
        cart: {
          ...mountedOrder_.cart,
          products: mountedOrder_.cart.products.reduce((previusProduct, currentProduct) => {
            previusProduct.push({
              ...currentProduct,
              product: {
                ...currentProduct.product,
                value: currentProduct.product.price
              },
              optionals: currentProduct.optionals?.reduce((previusOptional, currentOptional) => {
                previusOptional.push({
                  ...currentOptional,
                  value: currentOptional.price
                });

                return previusOptional;
              }, new Array<FormattedCartOptional>())
            });

            return previusProduct;
          }, new Array<FormattedCartProduct>())
        }
      };
    }

    if (selectedLanguage) {
      const apiPathWithLanguage = new URL(apiPath);
      apiPathWithLanguage.searchParams.append('lang', selectedLanguage);

      apiPath = apiPathWithLanguage.toString();
    }

    const response = await fetch(apiPath, {
      method: 'POST',
      credentials: actionParam ? 'include' : undefined,
      body: JSON.stringify(isPocketOrder ? mountedOrder : formattedMountedOrder),
      headers: {
        Authorization: headersAuthorization,
        'Content-type': isPocketOrder ? 'application/json;charset=UTF-8' : 'text/plain;charset=UTF-8'
      }
    });

    if (process.env.NEXT_PUBLIC_ENV !== 'test') {
      if (response.status === 204 && actionParam === 'set-send-order-data-cookies') {
        const isLocalhost: boolean = typeof window !== 'undefined' && window.location.hostname.includes('localhost');
        const domain: string = isLocalhost ? '.goomer.localhost:3002' : NEXT_PUBLIC_COOKIES_DOMAIN;
        const protocol: string = isLocalhost ? 'http' : 'https';

        setTimeout(() => {
          window.location.href = `${protocol}://checkout${domain}`;
        }, 1000);

        return { cookiesOnly: true };
      }
    }

    if (isPocketOrder && response.ok && response.status === 204) {
      return;
    }

    const responseJSON = await response.json();

    if (!response.ok) {
      throw new Error(`${response.status}: ${responseJSON.message ?? ''}`);
    }

    responseData = {
      body: responseJSON,
      status: response.status
    };

    if (responseJSON.status === 400 && responseJSON.cause) {
      const isMpEmailError =
        responseJSON.cause.length > 0 &&
        responseJSON.cause.filter((cause: ResponseErrorCause) => cause.code === MercadoPagoErrorCodes.email).length > 0;

      if (isMpEmailError) {
        throw new Error(ErrorsEnum.email);
      } else {
        addErrorLog({
          request: apiPath,
          payload: JSON.stringify(mountedOrder, null, 2),
          response: JSON.stringify(responseData, null, 2),
          error: `Algo deu errado no pedido com Mercado Pago.\nError cause: ${
            responseJSON.cause
          }\nError Object: ${JSON.stringify(responseJSON.cause)}`
        });
      }
    }

    const orderRejectedByMP = responseData?.body?.status === ErrorsEnum.rejected;
    if (orderRejectedByMP) {
      throw new Error(`${response.status}: ${CheckoutErrorMessageEnum.rejected_order_by_mp}`);
    }

    const firstErrorMessage = responseJSON.message?.[0];
    if (firstErrorMessage) {
      const pathList = (firstErrorMessage.path || []) as string[];

      if (firstErrorMessage.name === 'pattern' && pathList?.includes('user') && pathList?.includes('phone')) {
        throw new Error(`${response.status}: ${CheckoutErrorMessageEnum.wrong_phone_pattern}`);
      }
    }

    const isTunaPayment = mountedOrder.inAppTunaCheckoutData;
    if (isTunaPayment) {
      if (responseJSON.message === 'Payment not succeeded') {
        throw new Error(`${responseJSON.message}`);
      }
    } else {
      if (responseJSON.reason || responseJSON.code) {
        throw new Error(`${responseJSON.message}, ${responseJSON.reason || responseJSON.code}`);
      }
    }

    if (responseJSON.message === 'Invalid loyalty program discount') {
      throw new Error(`${response.status}: ${CheckoutErrorMessageEnum.invalid_loyalty_discount}`);
    }

    return responseJSON;
  } catch (error) {
    if (isInAppPayment && errorAction) {
      const isEmailError = String(error).includes(ErrorsEnum.email);
      const isRejectedByMPError = String(error).includes(ErrorsEnum.rejected);

      if (isEmailError) {
        errorAction(ErrorsEnum.email);
      } else if (isRejectedByMPError) {
        errorAction(ErrorsEnum.rejected);
      } else {
        errorAction();
      }
    }

    addErrorLog({
      request: apiPath,
      payload: JSON.stringify(mountedOrder, null, 2),
      response: JSON.stringify(responseData, null, 2),
      error: `Algo deu errado no envio do pedido.\nError Message: ${
        (error as Error)?.message
      }\nError Object: ${JSON.stringify(error)}`
    });

    throw error;
  }
}
