import { useMemo, useEffect, useState, useCallback } from 'react';

import Link from 'next/link';
import { useSelector } from 'react-redux';
import { RiArrowRightSLine } from 'react-icons/ri';

import { ProductCard } from '~/components';
import { Group, Product } from '~/interfaces';
import { IApplicationState } from '~/redux-tools/store';
import { useMenuProducts } from '~/hooks/useMenu/hooks';
import { MenuSessionStorageEnum } from '~/interfaces/enums';
import { useDesktopHorizontallScroll, useTranslator } from '~/hooks';

import * as S from './styles';

const SECONDS_IN_ONE_MINUTE = 60;
const MILLISECONDS_IN_ONE_SECOND = 1000;
const LIMIT_LIST_ITEMS_PER_CATEGORY = 10;
const PRODUCT_SCROLL_CLASSNAME = 'product-list';

export interface ProductCardListProps {
  group: Group;
}

const ProductCardList: React.FC<ProductCardListProps> = ({ group }) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const [productsToShowByHourList, setProductsToShowByHourList] = useState<Product[]>([]);

  const { getTranslation } = useTranslator();
  const { getProductListByGroupId } = useMenuProducts();

  const totalProducts = useMemo(() => productsToShowByHourList.length, [productsToShowByHourList]);

  useDesktopHorizontallScroll({ scrollClass: `.${PRODUCT_SCROLL_CLASSNAME}-${group.id}` });

  const productElementList: JSX.Element[] = useMemo(() => {
    const limitedProductList = productsToShowByHourList.slice(0, LIMIT_LIST_ITEMS_PER_CATEGORY - 1);

    return limitedProductList.map((product) => (
      <ProductCard
        key={product.id}
        product={product}
        categoryId={group.id}
        prices={product.prices}
        data-test="product-card"
        categoryName={group.name}
      />
    ));
  }, [group.id, group.name, productsToShowByHourList]);

  const shouldUpdateSessionStorage = useCallback(() => {
    const infoToSave = {
      id: group.id,
      categoryName: group.name
    };

    sessionStorage.setItem(MenuSessionStorageEnum.lastSelectedCategoryIdSessionData, JSON.stringify(infoToSave));
  }, [group.id, group.name]);

  useEffect(() => {
    const checkProductsAvailability = (): void => {
      const filteredProductList = getProductListByGroupId(group.id);

      setProductsToShowByHourList(filteredProductList);
    };

    checkProductsAvailability();

    const timer: NodeJS.Timeout = setInterval(
      checkProductsAvailability,
      SECONDS_IN_ONE_MINUTE * MILLISECONDS_IN_ONE_SECOND
    );

    return () => {
      clearInterval(timer);
    };
  }, [getProductListByGroupId, group.id]);

  if (productElementList.length === 0) {
    return <></>;
  }

  return (
    <S.Container id={group.id.toString()}>
      <S.ListHeader>
        <S.ListName>{group.name}</S.ListName>

        <Link href={`/category/${group.id}`}>
          <S.HeaderAnchor onClick={shouldUpdateSessionStorage}>
            {getTranslation('general.showAll')}
            <RiArrowRightSLine size={20} />
          </S.HeaderAnchor>
        </Link>
      </S.ListHeader>

      <S.ProductListWrapper className={`${PRODUCT_SCROLL_CLASSNAME}-${group.id}`}>
        {productElementList}

        {totalProducts > LIMIT_LIST_ITEMS_PER_CATEGORY && (
          <Link href={`/category/${group.id}`}>
            <S.AllProductsButton onClick={shouldUpdateSessionStorage}>
              <S.IconWrapper>
                <RiArrowRightSLine size={20} color={theme.brandColors.primary} />
              </S.IconWrapper>

              <S.ButtonText>
                {getTranslation('general.showAll')} <br /> {`${getTranslation('$t(general.product, lowercase)')}s`}
              </S.ButtonText>
            </S.AllProductsButton>
          </Link>
        )}
      </S.ProductListWrapper>
    </S.Container>
  );
};

export default ProductCardList;
