import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Menu } from '~/interfaces';
import { IApplicationState } from '~/redux-tools/store';
import { getFilteredListByAvailableHours } from '~/utils';
import { MenuSessionStorageEnum } from '~/interfaces/enums';
import { selectCurrentMenu } from '~/redux-tools/store/menus/actions';

function useMenuMenus(): {
  menuList: Menu[];
  selectedMenu: Menu | undefined;
  setSelectedMenu: (menu: Menu) => void;
} {
  const dispatch = useDispatch();

  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { selectedMenu, menus: menuList } = useSelector((state: IApplicationState) => state.menus);

  const { getFilteredMenuListByAvailableHours } = getFilteredListByAvailableHours;

  const isAvailableHoursEnabled = useMemo(
    () => !!settings?.mm_feature_schedule_by_group_and_product_enabled,
    [settings?.mm_feature_schedule_by_group_and_product_enabled]
  );

  const availableMenuList = getFilteredMenuListByAvailableHours(menuList, isAvailableHoursEnabled);

  const selectMenu: (menu?: Menu) => void = useCallback(
    (menu) => {
      if (!menu) {
        return;
      }

      dispatch(selectCurrentMenu({ menu }));
    },
    [dispatch]
  );

  const setSelectedMenu: (menu: Menu) => void = useCallback(
    (menu: Menu) => {
      selectMenu(menu);

      sessionStorage.setItem(
        MenuSessionStorageEnum.lastSelectedMenuIdSessionData,
        JSON.stringify({ id: String(menu.id) })
      );
    },
    [selectMenu]
  );

  useEffect(() => {
    if (selectedMenu) {
      return;
    }

    if (typeof window !== 'undefined') {
      const lastSelectedMenuSessionStorage = sessionStorage.getItem(
        MenuSessionStorageEnum.lastSelectedMenuIdSessionData
      );

      const lastSelectedMenuSessionStorageFormatted = lastSelectedMenuSessionStorage
        ? (JSON.parse(lastSelectedMenuSessionStorage) as { id: string })
        : undefined;

      const lastSelectedMenu: Menu | undefined = availableMenuList?.find(
        (menu) => String(menu.id) === String(lastSelectedMenuSessionStorageFormatted?.id)
      );

      selectMenu(lastSelectedMenu || availableMenuList[0]);
      return;
    }

    selectMenu(availableMenuList[0]);
  }, [availableMenuList, selectMenu, selectedMenu]);

  return { menuList, selectedMenu, setSelectedMenu };
}

export default useMenuMenus;
