import { getEstablishmentStatus } from '@goomerdev/goomer-toolbox/src/utils';

import { IEstablishmentSettings } from '~/interfaces/general';

interface IsOpenedProps {
  isLocalOrders: boolean;
  settings?: IEstablishmentSettings;
}

export default function isEstablishmentOpen({ isLocalOrders, settings }: IsOpenedProps) {
  const hoursMode = isLocalOrders ? settings?.mm_in_store_hours_mode : settings?.mm_hours_mode;

  const alwaysOpen = isLocalOrders ? settings?.mm_in_store_always_open : settings?.mm_always_open;

  if (isLocalOrders) {
    if (settings?.mm_in_store_hours_mode === 'fixed') {
      return getEstablishmentStatus({
        hours: settings?.mm_in_store_fixed_hours?.hours || [],
        freemiumStatus: settings?.mm_feature_subscription_freemium_status,
        isAlwaysOpen: hoursMode === 'fixed' && !!alwaysOpen
      });
    }

    return getEstablishmentStatus({
      hours: settings?.mm_in_store_operating_hours?.hours || [],
      freemiumStatus: settings?.mm_feature_subscription_freemium_status,
      isAlwaysOpen: hoursMode === 'fixed' && !!alwaysOpen
    });
  }

  if (settings?.mm_hours_mode === 'fixed') {
    return getEstablishmentStatus({
      hours: settings?.mm_fixed_hours?.hours || [],
      freemiumStatus: settings?.mm_feature_subscription_freemium_status,
      isAlwaysOpen: hoursMode === 'fixed' && !!alwaysOpen
    });
  }

  return getEstablishmentStatus({
    hours: settings?.mm_operating_hours?.hours || [],
    freemiumStatus: settings?.mm_feature_subscription_freemium_status,
    isAlwaysOpen: hoursMode === 'fixed' && !!alwaysOpen
  });
}
