import React, { useState, useEffect, useCallback } from 'react';

import { sanitizeOnlyLettersField } from '~/utils';

import { FormInput } from '../FormInput';

interface InputTextProps {
  name: string;
  dataTest?: string;
  textOnly?: boolean;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

const InputText: React.FC<InputTextProps> = ({
  name,
  dataTest,
  textOnly,
  placeholder,
  defaultValue,
  required = true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (): void => {}
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const cleanValue = textOnly ? sanitizeOnlyLettersField(value) : value;

      setValue(cleanValue);
      onChange(cleanValue);
    },
    [onChange, textOnly]
  );

  return (
    <FormInput
      id={name}
      name={name}
      type="text"
      value={value}
      inputMode="text"
      required={required}
      data-test={dataTest}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};

export default InputText;
