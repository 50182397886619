import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;

  background-color: ${({ theme }) => theme.colors.gray200};
`;

export const DetailBody = styled.div`
  margin: 0 auto;
  max-width: 800px;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 800px;
`;

export const ProductInfo = styled.div`
  width: 100%;
  padding: 1rem;
  max-width: 800px;

  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.colors.backgroundColor};

  .content-css {
    span {
      font-weight: 500;
      line-height: 150%;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.gray500};

      .show-more-less-clickable {
        margin-top: 0.285rem;

        display: block;

        cursor: pointer;
        font-weight: 600;
        text-decoration: underline;
        color: ${({ theme }) => theme.brandColors.primary};
      }
    }
  }
`;

export const ProductHighlightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProductHeader = styled.div`
  margin-bottom: 0.5rem;

  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const ProductName = styled.h2`
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.785rem;
`;

export const PriceWrapper = styled.p`
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
`;

export const PriceText = styled.span`
  margin-right: 0.42rem;

  font-size: 1rem;
  font-weight: 600;
  line-height: 120%;
`;

export const StartingAtText = styled.span`
  font-size: 0.75rem;
`;

export const ContentWrapper = styled.div`
  padding: 0 1rem 4.75rem;
`;

export const FullAgeTag = styled.div`
  border-radius: 0.28rem;
  margin-bottom: 2.28rem;
  padding: 0.285rem 0.5rem;

  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.error};
`;

export const FullAgeTagText = styled.span`
  margin-left: 0.5rem;

  font-weight: 500;
  line-height: 150%;
  font-size: 0.875rem;
  color: ${({ theme }) => (theme.isDarkMode ? theme.colors.textColor : theme.colors.backgroundColor)};
`;
