import React, { useEffect, useMemo, useRef } from 'react';

import lottie from 'lottie-web';

import { Button, Modal } from '~/components';
import InfoAnimation from '~/assets/animations/info.json';
import { FeedbackModalTypeEnum } from '~/interfaces/enums';
import ErrorAnimation from '~/assets/animations/error.json';
import SuccessAnimation from '~/assets/animations/success.json';

import * as S from './styles';

export interface FeedbackAction {
  text: string;
  isGhost?: boolean;
  action?: () => void;
}

interface FeedbackModalProps {
  title: string;
  onClose: () => void;
  description: string;
  hideAnimation?: boolean;
  actions?: FeedbackAction[];
  shouldDisplayFeedbackModal: boolean;
  whichTypeOfFeedback: FeedbackModalTypeEnum;
}

const FeedbackModal = ({
  title,
  onClose,
  actions,
  description,
  hideAnimation,
  whichTypeOfFeedback,
  shouldDisplayFeedbackModal
}: FeedbackModalProps): JSX.Element => {
  const animationContainer = useRef<HTMLDivElement>(null);

  const whichInfoShouldRender = useMemo(() => {
    const feedbackInfoByType = {
      [FeedbackModalTypeEnum.Info]: {
        animation: InfoAnimation,
        className: 'info-animation'
      },
      [FeedbackModalTypeEnum.Error]: {
        animation: ErrorAnimation,
        className: 'error-animation'
      },
      [FeedbackModalTypeEnum.Success]: {
        animation: SuccessAnimation,
        className: 'success-animation'
      }
    };

    return feedbackInfoByType[whichTypeOfFeedback];
  }, [whichTypeOfFeedback]);

  useEffect(() => {
    if (animationContainer.current && shouldDisplayFeedbackModal) {
      const animationConfig = {
        loop: false,
        autoplay: true,
        container: animationContainer.current
      };

      lottie.destroy();

      lottie.loadAnimation({
        ...animationConfig,
        renderer: 'svg',
        animationData: whichInfoShouldRender?.animation
      });
    }
  }, [shouldDisplayFeedbackModal, whichInfoShouldRender?.animation]);

  return (
    <Modal isShow={shouldDisplayFeedbackModal} onClose={onClose} isSmall hideCloseButton>
      <S.ModalWrapper>
        {!hideAnimation && (
          <S.AnimationWrapper>
            <div className={whichInfoShouldRender?.className} ref={animationContainer} />
          </S.AnimationWrapper>
        )}

        <S.Title>{title}</S.Title>

        <S.Subtitle>{description}</S.Subtitle>

        {actions && (
          <S.ButtonWrapper>
            {actions.map(({ text, action, isGhost }, index) => (
              <Button key={index} isGhost={!!isGhost} onClick={action || onClose}>
                {text}
              </Button>
            ))}
          </S.ButtonWrapper>
        )}
      </S.ModalWrapper>
    </Modal>
  );
};

export default FeedbackModal;
