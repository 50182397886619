import React, { createContext, useCallback, useContext, useState } from 'react';

import formatCep from '~/utils/formatCep';
import { AddressPageModeEnum } from '~/interfaces/enums';

import { AddressContext } from '../..';
import { FormFields, FormHeader } from './components';

import * as S from './styles';

export interface AddressFormContextProps {
  id: string | undefined;
  cep: string | undefined;
  state: string | undefined;
  cityId: number | undefined;
  street: string | undefined;
  resetFormFields: () => void;
  cityName: string | undefined;
  setId: (id?: string) => void;
  reference: string | undefined;
  complement: string | undefined;
  setCep: (cep?: string) => void;
  neighborhood: string | undefined;
  streetNumber: string | undefined;
  neighborhoodId: number | undefined;
  setState: (state?: string) => void;
  setCityId: (cityId?: number) => void;
  setStreet: (street?: string) => void;
  setCityName: (cityName?: string) => void;
  setReference: (reference?: string) => void;
  setComplement: (complement?: string) => void;
  setStreetNumber: (streeNumber?: string) => void;
  setNeighborhood: (neighborhood?: string) => void;
  setNeighborhoodId: (neighborhoodId?: number) => void;
}

export const AddressFormContext = createContext<AddressFormContextProps>({} as AddressFormContextProps);

const Form = (): JSX.Element => {
  const { selectedView, selectedAddress } = useContext(AddressContext);

  const [id, setId] = useState<string | undefined>(selectedAddress?.id);
  const [cep, setCep] = useState<string | undefined>(selectedAddress?.cep ? formatCep(selectedAddress.cep) : undefined);
  const [street, setStreet] = useState<string | undefined>(selectedAddress?.street);
  const [streetNumber, setStreetNumber] = useState<string | undefined>(selectedAddress?.number);
  const [complement, setComplement] = useState<string | undefined>(selectedAddress?.complement);
  const [reference, setReference] = useState<string | undefined>(selectedAddress?.reference);
  const [cityId, setCityId] = useState<number | undefined>(undefined);
  const [cityName, setCityName] = useState<string | undefined>(selectedAddress?.city);
  const [state, setState] = useState<string | undefined>(selectedAddress?.state);
  const [neighborhood, setNeighborhood] = useState<string | undefined>(selectedAddress?.neighborhood);
  const [neighborhoodId, setNeighborhoodId] = useState<number | undefined>(selectedAddress?.neighborhood_id);

  const resetFormFields = useCallback(() => {
    setCep(undefined);
    setStreet(undefined);
    setStreetNumber(undefined);
    setComplement(undefined);
    setReference(undefined);
    setCityId(undefined);
    setCityName(undefined);
    setState(undefined);
    setNeighborhood(undefined);
    setNeighborhoodId(undefined);
  }, []);

  return (
    <AddressFormContext.Provider
      value={{
        id,
        cep,
        state,
        setId,
        cityId,
        street,
        setCep,
        setState,
        cityName,
        setCityId,
        reference,
        setStreet,
        complement,
        setCityName,
        neighborhood,
        setReference,
        streetNumber,
        setComplement,
        neighborhoodId,
        resetFormFields,
        setNeighborhood,
        setStreetNumber,
        setNeighborhoodId
      }}
    >
      <S.Content>
        {selectedView !== AddressPageModeEnum.noCepForm && <FormHeader isCepFilled={!!cep} />}

        <FormFields />
      </S.Content>
    </AddressFormContext.Provider>
  );
};

export default Form;
