import styled, { css, DefaultTheme } from 'styled-components';

const listItemModifiers = {
  isSucceeded: (theme: DefaultTheme) => css`
    p {
      color: ${theme.colors.success};
    }
  `,
  isAttention: (theme: DefaultTheme) => css`
    margin: 0;

    p {
      color: ${theme.colors.error};
    }
  `,
  isStrong: (theme: DefaultTheme) => css`
    p {
      font-weight: 600;
      color: ${theme.colors.textColor};
    }
  `,
  isDisabled: (theme: DefaultTheme) => css`
    p {
      text-decoration: line-through;
      color: ${theme.colors.gray400};
    }
  `
};

export const Container = styled.li<{
  isDisabled?: boolean;
  isAttention?: boolean;
  isSucceeded?: boolean;
  isStrong?: boolean;
}>`
  ${({ theme, isStrong, isDisabled, isAttention, isSucceeded }) => css`
    position: relative;

    margin-bottom: 8px;
    padding-right: 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 0.857rem;
      line-height: 14.4px;
      color: ${theme.colors.gray500};
    }

    .chevron {
      position: absolute;
      top: -4px;
      right: -2px;

      svg {
        height: 8px;
        width: 5px;

        path {
          fill: ${theme.colors.gray500};
        }
        transition: 0.3s;
        transform: rotate(90deg);
      }
    }

    ${!!isStrong && listItemModifiers.isStrong(theme)};
    ${!!isDisabled && listItemModifiers.isDisabled(theme)};
    ${!!isAttention && listItemModifiers.isAttention(theme)};
    ${!!isSucceeded && listItemModifiers.isSucceeded(theme)};
  `}
`;
