import React, { useMemo } from 'react';

import { useTranslator } from '~/hooks';

import * as S from './styles';

const INPUT_LENGTH = 3;

interface InputCardCvvProps {
  label?: string;
  resetValue?: boolean;
  placeholder?: string;
  withoutMargin?: boolean;
  errorPosition?: Record<string, number | string>;
  onChange: (value: string, isValid: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const InputCardCvv: React.FC<InputCardCvvProps> = ({
  label,
  onChange,
  withoutMargin,
  errorPosition,
  resetValue = false,
  placeholder = '000',
  ...rest
}) => {
  const { getTranslation } = useTranslator();

  const handleValidate = (value: string): boolean => {
    return !value || value.length >= INPUT_LENGTH;
  };

  const mask = useMemo(() => (value: string) => value.substr(0, 4), []);

  return (
    <S.Container withoutMargin={withoutMargin} className="sentry-mask">
      {!!label && <S.Label htmlFor="cvv">{label}</S.Label>}

      <S.Input
        name="cvv"
        toMask={mask}
        autoComplete="off"
        inputMode="decimal"
        onChange={onChange}
        resetValue={resetValue}
        validate={handleValidate}
        placeholder={placeholder}
        errorPosition={errorPosition}
        errorMessage={getTranslation('payment.invalidSecurityCardCode')}
        {...rest}
      />
    </S.Container>
  );
};

export default React.memo(InputCardCvv);
