import { PocketMountedOrder } from '~/interfaces';
import { MountedOrder } from '~/interfaces/general';

export interface ILastOrderState {
  readonly order: MountedOrder;
  readonly pocketOrder?: PocketMountedOrder;
}

export const LastOrderActionTypes = {
  ADD_LAST_ORDER: '@@lastOrder/ADD_LAST_ORDER',
  CLEAN_LAST_ORDER: '@@lastOrder/CLEAN_LAST_ORDER',
  ADD_LAST_POCKET_ORDER: '@@lastOrder/ADD_LAST_POCKET_ORDER'
};
