import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import FadeIn from 'react-fade-in';
import { toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';
import { DebounceInput } from 'react-debounce-input';
import { fetchAddress } from '@goomerdev/goomer-toolbox/src/services';
import { FetchAddress } from '@goomerdev/goomer-toolbox/src/interfaces';

import { SearchIcon } from '~/assets';
import { fetchCityList } from '~/services';
import { cleanCep } from '~/utils/formatCep';
import { CityInfo } from '~/interfaces/general';
import { useAddress, useTranslator } from '~/hooks';
import { AddressPageModeEnum } from '~/interfaces/enums';
import { AddressContext } from '~/components/Address/AddressList';

import { AddressFormContext } from '../..';
import { AddressInfo, SearchModal } from '..';

import * as S from './styles';

const COMPLETE_CEP_NUMBERS = 9;
const CITY_SEARCH_LIMIT = process.env.NEXT_PUBLIC_CITY_LIMIT || '99';

interface FormProps {
  isCepFilled: boolean;
}

const FormHeader = ({ isCepFilled }: FormProps): JSX.Element => {
  const {
    cep,
    state,
    street,
    setCep,
    setState,
    cityName,
    setCityId,
    setStreet,
    setCityName,
    neighborhood,
    resetFormFields,
    setNeighborhood
  } = useContext(AddressFormContext);
  const { isGoomerColor, setSelectedView } = useContext(AddressContext);

  const [cityList, setCityList] = useState<CityInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previousCep, setPreviousCep] = useState<string | undefined>(cep);
  const [citySearch, setCitySearch] = useState<string | undefined>(undefined);
  const [shouldDisplaySearchModal, setShouldDisplaySearchModal] = useState<boolean>(false);

  const { getTranslation } = useTranslator();
  const { isNeighborhoodTax, hasInitialFieldsFilled } = useAddress();

  const hasCityError = useMemo(
    () => !isLoading && !!citySearch && citySearch.length > 3 && cityList.length === 0 && !cityName,
    [cityName, cityList.length, citySearch, isLoading]
  );

  const maskOptions = {
    maskChar: '',
    mask: '99999-999'
  };

  const updateCityList = useCallback(
    async (value: string) => {
      setCitySearch(value);

      if (value.length >= 3) {
        try {
          setIsLoading(true);
          const cityList = await fetchCityList({ search: value, resultLimit: Number(CITY_SEARCH_LIMIT) });

          return setCityList(cityList);
        } catch (error) {
          setCityList([]);
        } finally {
          setCityId(undefined);
          setCityName(undefined);
          setIsLoading(false);
        }
      } else {
        setCityList([]);
        setCityId(undefined);
        setCityName(undefined);
      }

      return setCityList([]);
    },
    [setCityId, setCityName]
  );

  const searchAddressByCep: () => Promise<void> = useCallback(async () => {
    if (cep) {
      try {
        setIsLoading(true);
        setPreviousCep(cep);

        const zipCode = cleanCep(cep);

        const fetchAddressFallback = (): void => {
          setCityList([]);
          resetFormFields();
          setSelectedView(AddressPageModeEnum.noCepForm);

          toast.info(getTranslation('address.fetchAddressError'));
        };

        const fetchedAddress = await fetchAddress({
          zipCode,
          fallback: fetchAddressFallback,
          handleNotFoundAddress: () => toast.error(getTranslation('address.zipcodeNotFound'))
        });

        const { city, state, street, neighborhood } = fetchedAddress as FetchAddress;

        setCityName(city);
        setState(state);
        setStreet(street || '');
        setNeighborhood(neighborhood);

        if (!street) {
          setSelectedView(AddressPageModeEnum.noStreetByCepForm);
        }
      } catch (error) {
        setCityList([]);
        resetFormFields();
      } finally {
        setIsLoading(false);
      }
    }
  }, [cep, getTranslation, resetFormFields, setCityName, setNeighborhood, setSelectedView, setState, setStreet]);

  useEffect(() => {
    const isCompleteCep = cep ? cep?.length >= COMPLETE_CEP_NUMBERS : false;

    if (!isCompleteCep) {
      resetFormFields();
    }

    if (isCompleteCep && !isNeighborhoodTax) {
      if (!isLoading && cep && cep !== previousCep) {
        searchAddressByCep();
      } else {
        setPreviousCep(cep);
      }
    }
  }, [cep, isLoading, isNeighborhoodTax, previousCep, resetFormFields, searchAddressByCep]);

  return (
    <S.Content>
      {!hasInitialFieldsFilled && (
        <>
          {isNeighborhoodTax && (
            <>
              <S.InputContainer>
                <label>{`${getTranslation('address.city')}*`}</label>

                <S.InputWrapper>
                  <DebounceInput
                    type="text"
                    value={citySearch}
                    debounceTimeout={300}
                    data-test="city-input"
                    placeholder={getTranslation('address.cityPlaceholder')}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => updateCityList(event.target.value)}
                  />
                </S.InputWrapper>

                {cityList.length > 0 && (
                  <div className="city-list">
                    {cityList.map((cityInfo, index) => (
                      <S.CityOption
                        key={index}
                        data-test="city-option"
                        onClick={(): void => {
                          setCityId(cityInfo.id);
                          setCityName(cityInfo.name);
                          setState(cityInfo.state);
                          setCitySearch(`${cityInfo?.name} / ${cityInfo?.state}`);

                          setCityList([]);
                        }}
                      >
                        {`${cityInfo?.name} / ${cityInfo?.state}`}
                      </S.CityOption>
                    ))}
                  </div>
                )}

                {hasCityError && <p className="input-error">{getTranslation('address.cityNotFound')}</p>}
              </S.InputContainer>

              {!!cityName && (
                <FadeIn delay={100}>
                  <S.InputContainer>
                    <label>{`${getTranslation('address.address')}*`}</label>

                    <S.InputWrapper
                      data-test="street-input"
                      isGoomerColor={isGoomerColor}
                      onClick={(): void => {
                        setShouldDisplaySearchModal(true);
                      }}
                    >
                      <p>{getTranslation('address.streetNameOrZipcode')}</p>

                      <SearchIcon />
                    </S.InputWrapper>
                  </S.InputContainer>
                </FadeIn>
              )}
            </>
          )}

          {shouldDisplaySearchModal && (
            <SearchModal
              shouldDisplay={shouldDisplaySearchModal}
              onClose={(): void => setShouldDisplaySearchModal(false)}
            />
          )}
        </>
      )}

      {!isNeighborhoodTax && (
        <S.InputContainer>
          <label>{`${getTranslation('address.zipcode')}*`}</label>

          <S.InputWrapper isGoomerColor={isGoomerColor} data-test="cep-input">
            <ReactInputMask
              {...maskOptions}
              value={cep}
              inputMode="numeric"
              placeholder="00000-000"
              data-test="input-new-cep"
              onChange={(event): void => setCep(event.target.value)}
            />
          </S.InputWrapper>

          {!isCepFilled && (
            <S.NoCepWrapper>
              <span onClick={(): void => setSelectedView(AddressPageModeEnum.noCepForm)}>
                {getTranslation('address.dontHaveZipcode')}
              </span>
            </S.NoCepWrapper>
          )}
        </S.InputContainer>
      )}

      {hasInitialFieldsFilled && (
        <>
          <AddressInfo
            address={{
              number: '',
              cep: cep || '',
              state: state || '',
              street: street || '',
              city: cityName || '',
              neighborhood: neighborhood || ''
            }}
          />
        </>
      )}
    </S.Content>
  );
};

export default FormHeader;
