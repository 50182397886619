interface ValidateCnpjProps {
  cnpj: string;
  emptyStringShouldReturnFalse?: boolean;
}

export default function validateCNPJ({ cnpj, emptyStringShouldReturnFalse = false }: ValidateCnpjProps): boolean {
  if (cnpj === undefined) return false;

  if (cnpj === '' && emptyStringShouldReturnFalse) return false;

  if (cnpj === '') return true;

  if (cnpj.length < 14) return false;

  function isAllSameDigit(value: string) {
    for (let i = 1; i < value.length; i++) {
      if (value[i - 1] !== value[i]) return false;
    }
    return true;
  }

  if (isAllSameDigit(cnpj)) return false;

  // Valida DVs
  let size = cnpj.length - 2;
  let num = cnpj.substring(0, size);
  const dig = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(num.charAt(size - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let res = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (res !== parseInt(dig.charAt(0))) {
    return false;
  }

  size = size + 1;
  num = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(num.charAt(size - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  res = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (res !== parseInt(dig.charAt(1))) {
    return false;
  }

  return true;
}
