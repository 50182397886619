import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';
import { getFilteredListByAvailableHours } from '~/utils';
import { useMenuGroups } from '~/hooks/useMenu/hooks/index';
import { SelectedCategoryInfo, Group, Product } from '~/interfaces';

function useMenuProducts(): {
  allProducts: Product[];
  getProductListByGroupId: (groupId: number) => Product[];
  getCategoryInfoById: (groupId: number) => SelectedCategoryInfo | undefined;
} {
  const { allGroups } = useMenuGroups();
  const { menus: menuList } = useSelector((state: IApplicationState) => state.menus);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { getFilteredProductListByAvailableHours } = getFilteredListByAvailableHours;

  const isAvailableHoursEnabled = useMemo(
    () => !!settings?.mm_feature_schedule_by_group_and_product_enabled,
    [settings?.mm_feature_schedule_by_group_and_product_enabled]
  );

  const getProductWithAvailableHourList = useCallback((group: Group) => {
    return group?.products.map((product) => {
      const { available_hours } = product;
      const availableHourList = available_hours.length > 0 ? available_hours : group?.available_hours;

      return {
        ...product,
        available_hours: availableHourList
      };
    });
  }, []);

  const allProducts: Product[] = useMemo(() => {
    let productList: Product[] = [];

    allGroups.forEach((group) => {
      const productWithAvailableHourList = getProductWithAvailableHourList(group);
      const filteredProductList = getFilteredProductListByAvailableHours(
        productWithAvailableHourList,
        isAvailableHoursEnabled
      );

      productList = productList.concat(filteredProductList);
    });

    return productList;
  }, [allGroups, getProductWithAvailableHourList, getFilteredProductListByAvailableHours, isAvailableHoursEnabled]);

  const getProductListByGroupId: (groupId: number) => Product[] = useCallback(
    (groupId) => {
      const productGroup = allGroups.find((group) => group.id === groupId);

      if (!productGroup) {
        return [];
      }

      const productWithAvailableHourList = getProductWithAvailableHourList(productGroup);

      return getFilteredProductListByAvailableHours(productWithAvailableHourList, isAvailableHoursEnabled);
    },
    [allGroups, getProductWithAvailableHourList, getFilteredProductListByAvailableHours, isAvailableHoursEnabled]
  );

  const getCategoryInfoById: (groupId: number) => SelectedCategoryInfo | undefined = useCallback(
    (groupId) => {
      const groupList: Group[] = [];

      menuList?.forEach((currentMenu) => {
        if (currentMenu.groups) {
          groupList.push(...currentMenu.groups);
        }
      });

      const groupInfo = groupList.find((group) => group.id === groupId);

      if (!groupInfo) {
        return;
      }

      return { id: String(groupInfo.id), categoryName: groupInfo.name };
    },
    [menuList]
  );

  return { allProducts, getCategoryInfoById, getProductListByGroupId };
}

export default useMenuProducts;
