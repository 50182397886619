import React from 'react';
import { RiFileList2Line } from 'react-icons/ri';

import { useTranslator } from '~/hooks';

import * as S from './styles';

export interface ReceiptSectionProps {
  quantity: number;
  onClick: () => void;
}

const ReceiptSection: React.FC<ReceiptSectionProps> = ({ onClick, quantity }) => {
  const { getTranslation } = useTranslator();

  return (
    <S.Container>
      <div className="info">
        <div className="title">
          <div className="icon">
            <RiFileList2Line size={20} />
          </div>

          <p>{getTranslation('general.receipt')}</p>
        </div>

        <div className="quantity">
          {`${String(quantity).padStart(2, '0')} ${
            quantity > 1 ? getTranslation('myTab.availablePlural') : getTranslation('myTab.available')
          }`}
        </div>
      </div>

      <p className="action" onClick={onClick}>
        {getTranslation('general.view')}
      </p>
    </S.Container>
  );
};

export { ReceiptSection };
