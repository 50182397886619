import { Thirky } from '~/interfaces/general';

export interface ThirkyState {
  readonly data?: Thirky;
  readonly hasError: boolean;
  readonly isLoading: boolean;
}

export const ThirkyActionTypes = {
  GET_THIRKY_SEARCH_REQUEST: '@@thirky/GET_THIRKY_SEARCH_REQUEST',
  GET_THIRKY_SEARCH_SUCCESS: '@@thirky/GET_THIRKY_SEARCH_SUCCESS',
  GET_THIRKY_SEARCH_ERROR: '@@thirky/GET_THIRKY_SEARCH_ERROR'
};
