import { IncomingHttpHeaders } from 'http';

import { api } from '~/services/api';
import { ProductType } from '~/interfaces/enums';

import { Chatbot, EstablishmentLoyaltyProgram, ICoupon, KeyValue } from '../interfaces/general';

const NEXT_PUBLIC_API_GOOMERGO_BASE_URL_V2 = String(process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL_V2) || '';

interface FetchEstablishmentInfoProps {
  slug: string;
  mode: string;
  isAbrahao: boolean;
  provider: ProductType;
  headers?: IncomingHttpHeaders;
  selectedLanguage: string | null;
}

export interface FetchEstablishmentInfoResponse {
  settings: KeyValue;
  loyaltyProgram?: EstablishmentLoyaltyProgram | undefined;
  info?: {
    chatbot: Chatbot;
    coupons: ICoupon[];
  };
}

export default async function fetchEstablishmentInfo({
  mode,
  slug,
  headers,
  provider,
  isAbrahao,
  selectedLanguage
}: FetchEstablishmentInfoProps): Promise<FetchEstablishmentInfoResponse> {
  const apiPath = new URL(`${NEXT_PUBLIC_API_GOOMERGO_BASE_URL_V2}/establishments/${slug}/info`);

  apiPath.searchParams.append('mode', mode);
  apiPath.searchParams.append('provider', provider);

  if (isAbrahao) {
    apiPath.searchParams.append('is_abrahao', 'true');
  }

  if (selectedLanguage) {
    apiPath.searchParams.append('lang', selectedLanguage);
  }

  const customHeaders = { ...(headers && { clientOriginHeaders: JSON.stringify(headers) }) };

  const response = await api.get<FetchEstablishmentInfoResponse>(apiPath.toString(), {
    headers: {
      ...customHeaders
    }
  });

  return response.data;
}
