import React from 'react';

const IPhoneBagIcon = () => {
  return (
    <svg width="67" height="54" viewBox="0 0 67 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.8169 46.2858C56.6205 46.2858 66.9999 35.9243 66.9999 23.1429C66.9999 10.3614 56.6205 0 43.8169 0C31.0132 0 20.6338 10.3614 20.6338 23.1429C20.6338 35.9243 31.0132 46.2858 43.8169 46.2858Z"
        fill="#E8EAED"
      />
      <path
        d="M4.68673 21.6467C5.21527 21.6467 5.64373 21.2193 5.64373 20.6921C5.64373 20.1649 5.21527 19.7375 4.68673 19.7375C4.1582 19.7375 3.72974 20.1649 3.72974 20.6921C3.72974 21.2193 4.1582 21.6467 4.68673 21.6467Z"
        fill="#30333C"
      />
      <path
        d="M5.07105 6.7865C4.60385 6.7865 4.22583 6.40943 4.22583 5.94341V3.41414C4.22583 2.94812 4.60385 2.57104 5.07105 2.57104C5.53824 2.57104 5.91626 2.94812 5.91626 3.41414V5.94341C5.91626 6.40943 5.53824 6.7865 5.07105 6.7865Z"
        fill="#7F8F9F"
      />
      <path
        d="M5.07105 12.6884C4.60385 12.6884 4.22583 12.3113 4.22583 11.8453V9.31599C4.22583 8.84997 4.60385 8.4729 5.07105 8.4729C5.53824 8.4729 5.91626 8.84997 5.91626 9.31599V11.8453C5.91626 12.3113 5.53824 12.6884 5.07105 12.6884Z"
        fill="#7F8F9F"
      />
      <path
        d="M3.38087 8.47256H0.845217C0.378023 8.47256 0 8.09549 0 7.62947C0 7.16345 0.378023 6.78638 0.845217 6.78638H3.38087C3.84806 6.78638 4.22608 7.16345 4.22608 7.62947C4.22608 8.09549 3.84806 8.47256 3.38087 8.47256Z"
        fill="#7F8F9F"
      />
      <path
        d="M9.29737 8.47256H6.76172C6.29453 8.47256 5.9165 8.09549 5.9165 7.62947C5.9165 7.16345 6.29453 6.78638 6.76172 6.78638H9.29737C9.76456 6.78638 10.1426 7.16345 10.1426 7.62947C10.1426 8.09549 9.76456 8.47256 9.29737 8.47256Z"
        fill="#7F8F9F"
      />
      <path
        d="M10.9868 53.1565V15.0349C10.9868 12.8074 12.7968 11.002 15.0299 11.002H47.5143C49.7472 11.002 51.5572 12.8074 51.5572 15.0349V53.1565"
        fill="white"
      />
      <path
        d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
        fill="#30333C"
      />
      <path
        d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
        fill="#30333C"
      />
      <path d="M22 11H40V12C40 13.6569 38.6569 15 37 15H25C23.3431 15 22 13.6569 22 12V11Z" fill="#30333C" />
      <path
        d="M41 38.9998L39.0889 51.0514C39.001 51.5948 38.7332 52.0882 38.3328 52.4446C37.9323 52.8011 37.4249 52.9977 36.9 52.9998H25.1C24.5751 52.9977 24.0677 52.8011 23.6672 52.4446C23.2668 52.0882 22.999 51.5948 22.9111 51.0514L21 38.9998H41Z"
        fill="#E8EAED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5969 38.5929C41.9184 38.9692 42.0578 39.467 41.978 39.9546L40.1983 50.8336C40.0527 51.7057 39.6083 52.5051 38.9337 53.0872C38.2582 53.6701 37.396 53.9962 36.4981 53.9997L36.4915 53.9998L25.5019 53.9997C24.604 53.9963 23.7418 53.6701 23.0663 53.0872C22.3917 52.5051 21.9473 51.7057 21.8017 50.8336L21.8008 50.8283L20.022 39.9546C19.9422 39.467 20.0816 38.9692 20.4031 38.5929C20.7247 38.2166 21.196 37.9998 21.6923 37.9998H40.3077C40.804 37.9998 41.2754 38.2166 41.5969 38.5929ZM40.3077 39.684H21.6923L23.4711 50.5576C23.553 51.0479 23.8022 51.4931 24.1749 51.8147C24.5476 52.1363 25.0199 52.3136 25.5085 52.3155H36.4915C36.9801 52.3136 37.4524 52.1363 37.8251 51.8147C38.1978 51.4931 38.447 51.0479 38.5289 50.5576L40.3077 39.684Z"
        fill="#30333C"
      />
      <path
        d="M30 43.9998C30 43.4475 30.4477 42.9998 31 42.9998C31.5523 42.9998 32 43.4475 32 43.9998V47.9998C32 48.552 31.5523 48.9998 31 48.9998C30.4477 48.9998 30 48.552 30 47.9998V43.9998Z"
        fill="#30333C"
      />
      <path
        d="M24.3608 33.8974C24.1615 33.4225 24.3849 32.8761 24.8598 32.6768C25.3346 32.4776 25.8811 32.701 26.0804 33.1759L28.1024 37.9948C28.3016 38.4696 28.0782 39.0161 27.6033 39.2153C27.1285 39.4146 26.582 39.1912 26.3828 38.7163L24.3608 33.8974Z"
        fill="#30333C"
      />
      <path
        d="M37.1024 33.5815C37.3016 33.1066 37.0782 32.5602 36.6033 32.3609C36.1285 32.1617 35.582 32.3851 35.3828 32.8599L33.3608 37.6788C33.1615 38.1537 33.3849 38.7002 33.8598 38.8994C34.3346 39.0987 34.8811 38.8752 35.0804 38.4004L37.1024 33.5815Z"
        fill="#30333C"
      />
      <path
        d="M34 43.9998C34 43.4475 34.4477 42.9998 35 42.9998C35.5523 42.9998 36 43.4475 36 43.9998V47.9998C36 48.552 35.5523 48.9998 35 48.9998C34.4477 48.9998 34 48.552 34 47.9998V43.9998Z"
        fill="#30333C"
      />
      <path
        d="M26 43.9998C26 43.4475 26.4477 42.9998 27 42.9998C27.5523 42.9998 28 43.4475 28 43.9998V47.9998C28 48.552 27.5523 48.9998 27 48.9998C26.4477 48.9998 26 48.552 26 47.9998V43.9998Z"
        fill="#30333C"
      />
      <path d="M53.5361 25.335H51.8457V29.5504H53.5361V25.335Z" fill="#30333C" />
      <path
        d="M51.8452 30.3933H53.5356C54.0028 30.3933 54.3809 30.0163 54.3809 29.5502V25.3348C54.3809 24.8688 54.0028 24.4917 53.5356 24.4917H51.8452C51.378 24.4917 51 24.8688 51 25.3348V29.5502C51 30.0163 51.378 30.3933 51.8452 30.3933Z"
        fill="#30333C"
      />
      <path
        d="M51.8452 37.1385H53.5356C54.0028 37.1385 54.3809 36.7614 54.3809 36.2954V32.0799C54.3809 31.6139 54.0028 31.2368 53.5356 31.2368H51.8452C51.378 31.2368 51 31.6139 51 32.0799V36.2954C51 36.7614 51.378 37.1385 51.8452 37.1385Z"
        fill="#30333C"
      />
    </svg>
  );
};

export default IPhoneBagIcon;
