import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { cnpjMask, cpfMask } from '@goomerdev/goomer-toolbox/src/utils';

import Modal from '~/components/Modal/Modal';
import Button from '~/components/Button/Button';
import { hidePartOfDocumentNumber } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import { ICard, PaymentMethod } from '~/interfaces/general';
import { InputCardCvv } from '~/components/pages/CardEntry';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { CheckoutContext as OrderContext } from '~/pages/order';
import { CheckoutContext as LocalOrderContext } from '~/pages/localOrder';
import { CheckoutContext as MyTabContext } from '~/pages/myTabPaymentDetails';
import { addUserCard, addUserPayment } from '~/redux-tools/store/user/actions';
import useCheckoutUserInfo from '~/hooks/useCheckout/hooks/useCheckoutUserInfo';
import { useGetOnlinePaymentToken, useTranslator, useFormatCreditCard } from '~/hooks';

import * as S from './styles';

export interface ConfirmCvvModalProps {
  isMyTab?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  showCvvModal: boolean;
  isUnableToConfirmCvv: boolean;
  actions: {
    setCvvValue: (value: string) => void;
    setIsLoading: (status: boolean) => void;
    handleConfirmAction: (token: string) => void;
    setShowCvvModal: (status: boolean) => void;
    setPaymentOption: (value: PaymentMethod) => void;
    setShowPaymentFeedbackModal: (status: boolean) => void;
  };
}

const ConfirmCvvModal = ({
  actions,
  onClose,
  onConfirm,
  showCvvModal,
  isMyTab = false,
  isUnableToConfirmCvv
}: ConfirmCvvModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const localOrder = useSelector((state: IApplicationState) => state.localOrders.mode);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const { getTranslation } = useTranslator();
  const { formatCreditCard } = useFormatCreditCard();
  const { getOnlinePaymentToken } = useGetOnlinePaymentToken();

  const currentContext = useMemo(() => {
    if (isMyTab) {
      return MyTabContext;
    }

    return localOrder ? LocalOrderContext : OrderContext;
  }, [isMyTab, localOrder]);

  const checkoutCart = useContext(currentContext);

  const [isCheckingCvv, setIsCheckingCvv] = useState(false);

  const cardDocumentDescription = useMemo(() => {
    const cardDocument = checkoutCart.paymentOption?.document;

    if (!cardDocument) {
      return false;
    }

    if (cardDocument.length > 11) {
      return (
        <S.SmallText>{`${getTranslation('general.cnpj')}: ${hidePartOfDocumentNumber(
          cnpjMask(cardDocument)
        )}`}</S.SmallText>
      );
    }

    return (
      <S.SmallText>{`${getTranslation('general.cpf')}: ${hidePartOfDocumentNumber(
        cpfMask(cardDocument)
      )}`}</S.SmallText>
    );
  }, [checkoutCart.paymentOption?.document, getTranslation]);

  const { userCreditCard, isAuthenticated, authenticationToken, userPaymentPreference } = useCheckoutUserInfo();

  const handleCloseModal = useCallback(() => {
    setIsCheckingCvv(false);
    onClose();
  }, [onClose]);

  const handleCvvConfirm = useCallback(() => {
    onConfirm();
    GoogleAnalytics.trackEvent(gaEvents.confirmCvvAndSendOrder);
  }, [onConfirm]);

  const handleCardTokenRefresh = useCallback(
    async ({ cvv }: { cvv: string }): Promise<void> => {
      if (isCheckingCvv || !cvv || cvv.length < 3 || !userCreditCard) {
        return;
      }

      setIsCheckingCvv(true);

      const cardInfos = {
        ...userCreditCard,
        cvv
      };

      const action = (newCard: ICard): void => {
        const paymentWithToken = { ...userPaymentPreference, token: newCard.token };

        dispatch(
          addUserCard({
            ...newCard,
            cardNumber: newCard.number,
            establishment: settings?.name || ''
          })
        );
        actions.setPaymentOption(paymentWithToken);
        dispatch(addUserPayment(paymentWithToken));

        actions.handleConfirmAction(newCard.token);
      };

      const isTunaPaymentEnabled =
        settings?.mm_payment_tuna_credit_card_checkout_enabled ||
        settings?.mm_payment_qrcode_tuna_credit_card_checkout_enabled ||
        false;

      await getOnlinePaymentToken({
        action,
        card: cardInfos,
        isAuthenticated,
        authenticationToken,
        isTunaPaymentEnabled,
        setIsLoading: actions.setIsLoading,
        errorAction: () => actions.setShowPaymentFeedbackModal(true)
      });

      setIsCheckingCvv(false);
      actions.setShowCvvModal(false);
    },
    [
      actions,
      dispatch,
      isCheckingCvv,
      userCreditCard,
      settings?.name,
      isAuthenticated,
      authenticationToken,
      userPaymentPreference,
      getOnlinePaymentToken,
      settings?.mm_payment_tuna_credit_card_checkout_enabled,
      settings?.mm_payment_qrcode_tuna_credit_card_checkout_enabled
    ]
  );

  const renderCardMask = (): JSX.Element | undefined => {
    if (checkoutCart.paymentOption?.cardDescription) {
      return (
        <S.TagWrapper className="sentry-mask">
          <S.StrongText>
            {formatCreditCard({
              isCreditCardNumberHidden: false,
              flag: checkoutCart.paymentOption.flag,
              card: checkoutCart.paymentOption?.cardDescription
            })}
          </S.StrongText>
        </S.TagWrapper>
      );
    }
  };

  return (
    <Modal isShow={showCvvModal} isSmall onClose={handleCloseModal} hideCloseButton>
      <S.ModalContent onSubmit={handleCardTokenRefresh}>
        <S.StrongText>{getTranslation('securityCardCodeModal.securityCode')}</S.StrongText>

        <S.RegularText>{getTranslation('securityCardCodeModal.insertCodeAgain')}</S.RegularText>

        <S.CardDocumentDescriptionWrapper className="sentry-mask">
          {renderCardMask()}

          {cardDocumentDescription}
        </S.CardDocumentDescriptionWrapper>

        <InputCardCvv
          withoutMargin
          placeholder="000"
          resetValue={!showCvvModal}
          errorPosition={{ top: '-5px', right: '22px' }}
          label={getTranslation('payment.securityCardCode')}
          onChange={(value): void => actions.setCvvValue(value)}
        />

        <Button type="submit" disabled={isUnableToConfirmCvv} isGhost={false} onClick={handleCvvConfirm}>
          {getTranslation('securityCardCodeModal.confirmAndSend')}
        </Button>
      </S.ModalContent>
    </Modal>
  );
};

export default ConfirmCvvModal;
