import React, { useContext, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { ErrorsEnum } from '~/interfaces/enums';
import { Button, NewWaves } from '~/components';
import { DefaultPage } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { useFormatCreditCard, useTranslator } from '~/hooks';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { CheckoutContext as OrderContext } from '~/pages/order';
import { CheckoutContext as LocalOrderContext } from '~/pages/localOrder';

import { CardErrorList } from '../Modal';

import * as S from './styles';

interface CardFeedbackModalProps {
  isShow: boolean;
  onClose: () => void;
  errorType?: ErrorsEnum;
}

const CardFeedbackModal: React.FC<CardFeedbackModalProps> = ({ isShow, onClose, errorType }) => {
  const { colors } = useSelector((state: IApplicationState) => state.theme.theme);
  const localOrder = useSelector((state: IApplicationState) => state.localOrders.mode);

  const { paymentOption, setDefaultPage, setShowPaymentOptionModal } = useContext(
    localOrder ? LocalOrderContext : OrderContext
  );
  const { getTranslation } = useTranslator();
  const { formatCreditCard } = useFormatCreditCard();

  const handleChangePayment = useCallback(
    (type: DefaultPage) => {
      setDefaultPage(type);
      onClose();

      setShowPaymentOptionModal(true);
    },
    [onClose, setShowPaymentOptionModal, setDefaultPage]
  );

  const renderCardMask = (): JSX.Element | undefined => {
    if (paymentOption?.cardDescription) {
      return (
        <S.Tag>
          {formatCreditCard({
            flag: paymentOption?.flag,
            isCreditCardNumberHidden: false,
            card: paymentOption?.cardDescription
          })}
        </S.Tag>
      );
    }
  };

  return (
    <S.Container
      isPageLike
      isShow={isShow}
      onClose={onClose}
      headerTitle={getTranslation('general.paymentNotFinished')}
    >
      <S.TopInfo>
        <h5>{getTranslation('payment.pleaseReviewYourData')}</h5>

        {errorType !== ErrorsEnum.email && renderCardMask()}

        <span className="tips">
          {errorType === ErrorsEnum.email
            ? getTranslation('payment.errors.emailMustBeDifferent')
            : getTranslation('payment.errors.commonErrors')}
        </span>
      </S.TopInfo>

      {errorType !== ErrorsEnum.email && (
        <>
          <NewWaves backgroundColor={colors.gray200} />

          <S.ErrorListWrapper>
            <CardErrorList />
          </S.ErrorListWrapper>
        </>
      )}

      <S.Actions>
        <Button
          isGhost={false}
          onClick={(): void => {
            handleChangePayment('selected');
            GoogleAnalytics.trackEvent(gaEvents.reviewCreditCardDetails);
          }}
        >
          {getTranslation('general.reviewData')}
        </Button>

        <Button
          onClick={(): void => {
            handleChangePayment('list');
            GoogleAnalytics.trackEvent(gaEvents.changePaymentMethod);
          }}
        >
          {getTranslation('general.changePaymentMethod')}
        </Button>
      </S.Actions>
    </S.Container>
  );
};

export default CardFeedbackModal;
