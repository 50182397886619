import React from 'react';

interface InstallPwaIllustrationProps {
  color?: string;
}

const InstallPwaIllustration = ({ color = '#300707', ...rest }: InstallPwaIllustrationProps) => {
  return (
    <svg
      width="100%"
      viewBox="0 0 320 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Ilustração do app na tela inicial"
      {...rest}
    >
      <path d="M0 0H320V208H0V0Z" fill={color} />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0">
        <path d="M0 0H320V208H0V0Z" fill={color} />
      </mask>
      <g mask="url(#mask0)">
        <circle opacity="0.05" cx="159" cy="125" r="117" fill="white" />
        <circle opacity="0.05" cx="160" cy="133" r="160" fill="white" />
        <circle opacity="0.15" cx="159" cy="125" r="81" fill="white" />
        <path
          d="M83.818 53.8717C83.3944 53.687 83.0534 53.3528 82.86 52.9327C82.6665 52.5126 82.6341 52.036 82.7689 51.5935C82.7907 51.5257 82.8045 51.4555 82.8098 51.3845C82.8222 51.1818 82.7689 50.9805 82.6581 50.8104C82.5472 50.6403 82.3845 50.5105 82.1942 50.4402C82.0039 50.37 81.7961 50.3629 81.6014 50.4202C81.4068 50.4774 81.2358 50.5959 81.1136 50.758C81.0716 50.8156 81.0366 50.8779 81.0092 50.9436C80.8247 51.3677 80.4909 51.7091 80.0713 51.9028C79.6517 52.0965 79.1756 52.1289 78.7337 51.9939C78.666 51.9721 78.5959 51.9584 78.5249 51.953C78.3224 51.9406 78.1214 51.9939 77.9515 52.1049C77.7816 52.2159 77.652 52.3787 77.5818 52.5693C77.5116 52.7598 77.5045 52.9679 77.5617 53.1628C77.6189 53.3577 77.7372 53.5289 77.8992 53.6512C77.9567 53.6932 78.0189 53.7283 78.0846 53.7558C78.5082 53.9405 78.8491 54.2747 79.0426 54.6947C79.236 55.1148 79.2684 55.5915 79.1336 56.0339C79.1118 56.1017 79.0981 56.1719 79.0927 56.243C79.0804 56.4457 79.1336 56.647 79.2445 56.8171C79.3553 56.9871 79.518 57.1169 79.7083 57.1872C79.8986 57.2575 80.1065 57.2645 80.3011 57.2073C80.4958 57.1501 80.6668 57.0316 80.7889 56.8694C80.8309 56.8119 80.866 56.7496 80.8934 56.6838C81.0779 56.2597 81.4117 55.9183 81.8312 55.7247C82.2508 55.531 82.7269 55.4985 83.1688 55.6335C83.2366 55.6553 83.3067 55.6691 83.3776 55.6745C83.5801 55.6868 83.7812 55.6335 83.9511 55.5225C84.1209 55.4115 84.2506 55.2487 84.3208 55.0582C84.391 54.8676 84.398 54.6595 84.3408 54.4646C84.2837 54.2698 84.1653 54.0986 84.0034 53.9763C83.9459 53.9342 83.8837 53.8991 83.818 53.8717Z"
          fill="#FFD166"
        />
        <path
          d="M233.879 30.0274C233.147 29.7084 232.558 29.1312 232.224 28.4056C231.89 27.6801 231.834 26.8569 232.067 26.0927C232.105 25.9756 232.128 25.8544 232.138 25.7317C232.159 25.3816 232.067 25.0339 231.875 24.7402C231.684 24.4464 231.403 24.2223 231.074 24.1009C230.746 23.9795 230.387 23.9673 230.051 24.0662C229.714 24.165 229.419 24.3696 229.208 24.6497C229.135 24.7491 229.075 24.8567 229.028 24.9703C228.709 25.7027 228.132 26.2923 227.408 26.6268C226.683 26.9613 225.861 27.0174 225.098 26.7843C224.981 26.7466 224.86 26.7228 224.737 26.7135C224.387 26.6922 224.04 26.7842 223.747 26.9759C223.453 27.1677 223.229 27.4489 223.108 27.778C222.987 28.1071 222.975 28.4665 223.073 28.803C223.172 29.1396 223.377 29.4353 223.656 29.6465C223.756 29.7191 223.863 29.7797 223.976 29.8271C224.708 30.1462 225.297 30.7234 225.631 31.4489C225.965 32.1744 226.021 32.9976 225.788 33.7618C225.751 33.8789 225.727 34.0001 225.718 34.1229C225.696 34.473 225.788 34.8206 225.98 35.1144C226.171 35.4081 226.452 35.6323 226.781 35.7537C227.11 35.875 227.469 35.8872 227.805 35.7884C228.141 35.6895 228.436 35.4849 228.647 35.2048C228.72 35.1054 228.78 34.9978 228.828 34.8843C229.146 34.1518 229.723 33.5622 230.447 33.2277C231.172 32.8932 231.994 32.8371 232.758 33.0702C232.875 33.1079 232.996 33.1317 233.118 33.141C233.468 33.1623 233.815 33.0703 234.109 32.8786C234.402 32.6869 234.626 32.4056 234.747 32.0765C234.868 31.7475 234.88 31.388 234.782 31.0515C234.683 30.7149 234.479 30.4192 234.199 30.208C234.1 30.1354 233.992 30.0748 233.879 30.0274Z"
          fill="#FFD166"
        />
        <path
          d="M79.9212 175.746C79.1896 175.427 78.6007 174.85 78.2666 174.124C77.9325 173.399 77.8765 172.576 78.1093 171.811C78.147 171.694 78.1707 171.573 78.18 171.45C78.2013 171.1 78.1094 170.753 77.9179 170.459C77.7264 170.165 77.4455 169.941 77.1168 169.82C76.7881 169.698 76.4291 169.686 76.093 169.785C75.7568 169.884 75.4614 170.088 75.2505 170.368C75.178 170.468 75.1174 170.575 75.0701 170.689C74.7514 171.421 74.1749 172.011 73.4502 172.346C72.7256 172.68 71.9033 172.736 71.1401 172.503C71.0231 172.465 70.902 172.442 70.7794 172.432C70.4297 172.411 70.0825 172.503 69.7891 172.695C69.4957 172.886 69.2718 173.168 69.1506 173.497C69.0293 173.826 69.0172 174.185 69.1159 174.522C69.2146 174.858 69.419 175.154 69.6988 175.365C69.798 175.438 69.9055 175.498 70.0189 175.546C70.7505 175.865 71.3395 176.442 71.6736 177.168C72.0077 177.893 72.0636 178.716 71.8308 179.481C71.7931 179.598 71.7694 179.719 71.7601 179.842C71.7388 180.192 71.8307 180.539 72.0222 180.833C72.2137 181.127 72.4946 181.351 72.8233 181.472C73.152 181.594 73.511 181.606 73.8472 181.507C74.1833 181.408 74.4787 181.204 74.6896 180.924C74.7622 180.824 74.8227 180.717 74.8701 180.603C75.1887 179.871 75.7652 179.281 76.4899 178.946C77.2146 178.612 78.0368 178.556 78.8001 178.789C78.9171 178.827 79.0381 178.85 79.1607 178.86C79.5104 178.881 79.8577 178.789 80.1511 178.597C80.4444 178.406 80.6683 178.124 80.7896 177.795C80.9108 177.466 80.923 177.107 80.8242 176.77C80.7255 176.434 80.5211 176.138 80.2414 175.927C80.1421 175.854 80.0347 175.794 79.9212 175.746Z"
          fill="#FFD166"
        />
        <path
          d="M259.598 144.504C258.574 144.057 257.749 143.249 257.281 142.233C256.814 141.218 256.735 140.065 257.061 138.995C257.114 138.831 257.147 138.662 257.16 138.49C257.19 138 257.061 137.513 256.793 137.102C256.525 136.691 256.132 136.377 255.672 136.207C255.212 136.037 254.709 136.02 254.238 136.158C253.768 136.297 253.354 136.583 253.059 136.975C252.957 137.114 252.873 137.265 252.806 137.424C252.36 138.449 251.553 139.275 250.539 139.743C249.524 140.211 248.373 140.29 247.304 139.964C247.14 139.911 246.971 139.878 246.799 139.864C246.31 139.835 245.824 139.963 245.413 140.232C245.002 140.5 244.689 140.894 244.519 141.355C244.349 141.815 244.332 142.319 244.47 142.79C244.609 143.261 244.895 143.675 245.286 143.971C245.425 144.072 245.576 144.157 245.735 144.224C246.759 144.67 247.583 145.478 248.051 146.494C248.519 147.51 248.597 148.662 248.271 149.732C248.219 149.896 248.185 150.066 248.172 150.238C248.143 150.728 248.271 151.214 248.539 151.626C248.807 152.037 249.201 152.351 249.661 152.521C250.121 152.691 250.624 152.708 251.094 152.569C251.565 152.431 251.978 152.144 252.274 151.752C252.375 151.613 252.46 151.463 252.526 151.304C252.972 150.278 253.78 149.453 254.794 148.984C255.809 148.516 256.96 148.438 258.028 148.764C258.192 148.817 258.362 148.85 258.533 148.863C259.023 148.893 259.509 148.764 259.92 148.496C260.33 148.227 260.644 147.833 260.814 147.373C260.983 146.912 261 146.409 260.862 145.938C260.724 145.467 260.438 145.052 260.046 144.757C259.907 144.655 259.757 144.57 259.598 144.504Z"
          fill="#FFD166"
        />
        <path
          d="M55.2404 134.074C54.7531 133.861 54.3609 133.477 54.1384 132.994C53.9159 132.51 53.8786 131.962 54.0336 131.453C54.0587 131.375 54.0745 131.294 54.0807 131.213C54.0949 130.98 54.0337 130.748 53.9062 130.552C53.7786 130.357 53.5915 130.207 53.3726 130.127C53.1537 130.046 52.9146 130.038 52.6907 130.104C52.4669 130.169 52.2701 130.306 52.1297 130.492C52.0814 130.558 52.041 130.63 52.0095 130.706C51.7973 131.193 51.4133 131.586 50.9307 131.809C50.4481 132.032 49.9005 132.069 49.3921 131.914C49.3142 131.889 49.2336 131.873 49.1519 131.867C48.919 131.852 48.6878 131.914 48.4924 132.041C48.297 132.169 48.1479 132.356 48.0671 132.576C47.9864 132.795 47.9783 133.034 48.044 133.258C48.1098 133.482 48.2459 133.679 48.4322 133.82C48.4983 133.868 48.5699 133.909 48.6454 133.94C49.1327 134.153 49.5249 134.537 49.7474 135.02C49.9699 135.504 50.0072 136.052 49.8522 136.561C49.8271 136.639 49.8113 136.72 49.8051 136.801C49.7909 137.034 49.8521 137.266 49.9796 137.462C50.1072 137.657 50.2943 137.807 50.5132 137.887C50.7321 137.968 50.9712 137.976 51.1951 137.91C51.4189 137.845 51.6156 137.708 51.7561 137.522C51.8044 137.456 51.8448 137.384 51.8763 137.308C52.0885 136.821 52.4725 136.428 52.9551 136.205C53.4377 135.982 53.9853 135.945 54.4937 136.1C54.5716 136.125 54.6522 136.141 54.7339 136.147C54.9668 136.162 55.198 136.1 55.3934 135.973C55.5888 135.845 55.7379 135.658 55.8187 135.438C55.8994 135.219 55.9075 134.98 55.8418 134.756C55.776 134.532 55.6399 134.335 55.4536 134.194C55.3875 134.146 55.3159 134.105 55.2404 134.074Z"
          fill="white"
        />
        <path
          d="M271.355 86.8968C270.867 86.6843 270.475 86.2999 270.253 85.8167C270.03 85.3335 269.993 84.7853 270.148 84.2763C270.173 84.1983 270.189 84.1176 270.195 84.0359C270.209 83.8027 270.148 83.5711 270.02 83.3755C269.893 83.1799 269.706 83.0306 269.487 82.9498C269.268 82.8689 269.029 82.8608 268.805 82.9266C268.581 82.9925 268.384 83.1288 268.244 83.3153C268.196 83.3815 268.155 83.4531 268.124 83.5288C267.912 84.0166 267.528 84.4093 267.045 84.6321C266.562 84.8548 266.015 84.8921 265.506 84.7369C265.428 84.7118 265.348 84.696 265.266 84.6898C265.033 84.6756 264.802 84.7369 264.607 84.8645C264.411 84.9922 264.262 85.1795 264.181 85.3987C264.101 85.6179 264.093 85.8573 264.158 86.0814C264.224 86.3055 264.36 86.5025 264.546 86.6431C264.613 86.6915 264.684 86.7319 264.76 86.7635C265.247 86.9759 265.639 87.3603 265.862 87.8435C266.084 88.3267 266.121 88.875 265.966 89.3839C265.941 89.4619 265.926 89.5427 265.919 89.6244C265.905 89.8576 265.966 90.0891 266.094 90.2847C266.221 90.4804 266.409 90.6296 266.627 90.7105C266.846 90.7913 267.085 90.7995 267.309 90.7336C267.533 90.6678 267.73 90.5315 267.87 90.345C267.919 90.2788 267.959 90.2071 267.991 90.1315C268.203 89.6437 268.587 89.251 269.069 89.0282C269.552 88.8054 270.1 88.7681 270.608 88.9233C270.686 88.9485 270.766 88.9643 270.848 88.9705C271.081 88.9847 271.312 88.9234 271.508 88.7957C271.703 88.668 271.852 88.4807 271.933 88.2615C272.014 88.0424 272.022 87.803 271.956 87.5789C271.89 87.3547 271.754 87.1578 271.568 87.0171C271.502 86.9688 271.43 86.9284 271.355 86.8968Z"
          fill="white"
        />
        <path
          d="M243.15 194.342C242.942 194.251 242.774 194.087 242.679 193.88C242.584 193.674 242.568 193.44 242.635 193.222C242.645 193.189 242.652 193.154 242.655 193.119C242.661 193.02 242.635 192.921 242.58 192.837C242.526 192.754 242.446 192.69 242.352 192.655C242.259 192.621 242.156 192.617 242.061 192.646C241.965 192.674 241.881 192.732 241.821 192.812C241.8 192.84 241.783 192.87 241.77 192.903C241.679 193.111 241.515 193.279 241.309 193.374C241.103 193.469 240.869 193.485 240.651 193.419C240.618 193.408 240.584 193.402 240.549 193.399C240.449 193.393 240.35 193.419 240.267 193.474C240.184 193.528 240.12 193.608 240.085 193.702C240.051 193.795 240.047 193.898 240.075 193.993C240.104 194.089 240.162 194.173 240.241 194.233C240.27 194.254 240.3 194.271 240.332 194.285C240.541 194.376 240.708 194.54 240.803 194.746C240.898 194.953 240.914 195.187 240.848 195.404C240.837 195.438 240.831 195.472 240.828 195.507C240.822 195.607 240.848 195.706 240.902 195.789C240.957 195.873 241.037 195.937 241.13 195.971C241.224 196.006 241.326 196.009 241.422 195.981C241.517 195.953 241.601 195.895 241.661 195.815C241.682 195.787 241.699 195.756 241.713 195.724C241.803 195.515 241.968 195.348 242.174 195.252C242.38 195.157 242.614 195.141 242.831 195.208C242.864 195.218 242.899 195.225 242.934 195.228C243.033 195.234 243.132 195.208 243.216 195.153C243.299 195.099 243.363 195.019 243.397 194.925C243.432 194.831 243.435 194.729 243.407 194.633C243.379 194.537 243.321 194.453 243.241 194.393C243.213 194.373 243.182 194.355 243.15 194.342Z"
          fill="white"
        />
        <path
          d="M76.8816 100.422C76.6734 100.331 76.5058 100.167 76.4108 99.9601C76.3157 99.7536 76.2998 99.5194 76.366 99.3019C76.3767 99.2686 76.3835 99.2341 76.3861 99.1992C76.3922 99.0996 76.366 99.0006 76.3116 98.917C76.2571 98.8335 76.1771 98.7697 76.0836 98.7351C75.9901 98.7006 75.8879 98.6971 75.7923 98.7253C75.6966 98.7534 75.6125 98.8116 75.5525 98.8913C75.5319 98.9196 75.5147 98.9502 75.5012 98.9825C75.4105 99.1909 75.2465 99.3587 75.0403 99.4539C74.834 99.5491 74.6001 99.565 74.3829 99.4987C74.3496 99.488 74.3151 99.4812 74.2803 99.4786C74.1808 99.4725 74.0819 99.4987 73.9985 99.5532C73.915 99.6078 73.8513 99.6878 73.8168 99.7815C73.7823 99.8751 73.7788 99.9774 73.8069 100.073C73.835 100.169 73.8931 100.253 73.9728 100.313C74.001 100.334 74.0316 100.351 74.0639 100.365C74.272 100.455 74.4396 100.62 74.5347 100.826C74.6298 101.032 74.6457 101.267 74.5794 101.484C74.5687 101.518 74.562 101.552 74.5593 101.587C74.5533 101.687 74.5794 101.785 74.6339 101.869C74.6884 101.953 74.7683 102.016 74.8619 102.051C74.9554 102.086 75.0575 102.089 75.1532 102.061C75.2489 102.033 75.3329 101.975 75.3929 101.895C75.4136 101.867 75.4308 101.836 75.4443 101.804C75.5349 101.595 75.699 101.427 75.9052 101.332C76.1114 101.237 76.3454 101.221 76.5626 101.287C76.5959 101.298 76.6303 101.305 76.6652 101.308C76.7647 101.314 76.8635 101.287 76.947 101.233C77.0305 101.178 77.0942 101.098 77.1287 101.005C77.1632 100.911 77.1667 100.809 77.1386 100.713C77.1105 100.617 77.0523 100.533 76.9727 100.473C76.9445 100.452 76.9139 100.435 76.8816 100.422Z"
          fill="white"
        />
        <path
          d="M237.774 77.6016C237.566 77.5108 237.398 77.3466 237.303 77.1401C237.208 76.9337 237.192 76.6994 237.258 76.482C237.269 76.4486 237.276 76.4142 237.278 76.3792C237.284 76.2796 237.258 76.1807 237.204 76.0971C237.149 76.0135 237.069 75.9497 236.976 75.9152C236.882 75.8806 236.78 75.8772 236.684 75.9053C236.589 75.9334 236.505 75.9917 236.445 76.0714C236.424 76.0996 236.407 76.1303 236.393 76.1626C236.303 76.371 236.139 76.5388 235.932 76.634C235.726 76.7291 235.492 76.7451 235.275 76.6788C235.242 76.668 235.207 76.6613 235.172 76.6586C235.073 76.6526 234.974 76.6787 234.891 76.7333C234.807 76.7878 234.743 76.8679 234.709 76.9615C234.674 77.0552 234.671 77.1574 234.699 77.2532C234.727 77.349 234.785 77.4331 234.865 77.4932C234.893 77.5139 234.924 77.5311 234.956 77.5446C235.164 77.6354 235.332 77.7996 235.427 78.0061C235.522 78.2125 235.538 78.4468 235.472 78.6642C235.461 78.6976 235.454 78.7321 235.451 78.767C235.445 78.8666 235.472 78.9655 235.526 79.0491C235.58 79.1327 235.66 79.1965 235.754 79.231C235.847 79.2656 235.95 79.269 236.045 79.2409C236.141 79.2128 236.225 79.1546 236.285 79.0749C236.306 79.0466 236.323 79.016 236.336 78.9836C236.427 78.7752 236.591 78.6074 236.797 78.5123C237.004 78.4171 237.237 78.4011 237.455 78.4675C237.488 78.4782 237.522 78.4849 237.557 78.4876C237.657 78.4937 237.756 78.4675 237.839 78.4129C237.923 78.3584 237.986 78.2783 238.021 78.1847C238.055 78.0911 238.059 77.9888 238.031 77.893C238.003 77.7973 237.944 77.7131 237.865 77.653C237.837 77.6323 237.806 77.6151 237.774 77.6016Z"
          fill="white"
        />
        <path
          d="M225 59.0145V286.986C225 291.967 221.55 296 217.378 296H105.622C101.37 296 98 291.967 98 286.986V59.0145C98 54.0328 101.45 50 105.622 50H217.298C221.55 50.0791 225 54.1119 225 59.0145Z"
          fill="#E8EAED"
        />
        <path
          d="M105.148 310C99.3129 310 94.5673 304.287 94.5673 297.244V138.849L94.2561 138.771C92.9336 138.536 92 137.127 92 135.484V107.937C92 107.076 92.3112 106.215 92.8558 105.589C93.2447 105.12 93.7893 104.807 94.3339 104.728L94.6451 104.65V98.8591L94.3339 98.7808C93.0114 98.546 92.0778 97.1374 92.0778 95.494V86.3377C92.0778 85.3986 92.389 84.6161 92.9336 83.99C93.3225 83.5204 93.8671 83.2074 94.4117 83.0509L94.7229 82.9726V56.7561C94.7229 49.7129 99.4685 44 105.303 44H218.342C224.177 44 229 49.7129 229 56.7561V297.244C229 304.287 224.254 310 218.342 310H105.148ZM107.482 51.6693C103.203 51.6693 99.7019 55.817 99.7019 60.9821V286.601C99.7019 291.688 103.203 295.913 107.482 295.913H215.775C220.053 295.913 223.554 291.766 223.554 286.601V60.9038C223.554 55.817 220.053 51.5911 215.775 51.5911H182.555V54.5649C182.555 56.4431 181.388 57.6169 179.444 57.6169H144.591C142.646 57.6169 141.479 56.4431 141.479 54.5649V51.5911H107.482V51.6693Z"
          fill="white"
        />
        <path
          d="M129.602 178H114.323C111.938 178 110 176.062 110 173.677V158.323C110 155.938 111.938 154 114.323 154H129.677C132.062 154 134 155.938 134 158.323V173.677C134 176.062 132.062 178 129.602 178Z"
          fill="white"
        />
        <path
          d="M129.602 214H114.323C111.938 214 110 212.062 110 209.677V194.323C110 191.938 111.938 190 114.323 190H129.677C132.062 190 134 191.938 134 194.323V209.677C134 212.062 132.062 214 129.602 214Z"
          fill="white"
        />
        <path
          d="M204.677 178H189.323C186.938 178 185 176.062 185 173.677V158.323C185 155.938 186.938 154 189.323 154H204.677C207.062 154 209 155.938 209 158.323V173.677C209 176.062 207.062 178 204.677 178Z"
          fill="white"
        />
        <path
          d="M204.677 214H189.323C186.938 214 185 212.062 185 209.677V194.323C185 191.938 186.938 190 189.323 190H204.677C207.062 190 209 191.938 209 194.323V209.677C209 212.062 207.062 214 204.677 214Z"
          fill="white"
        />
        <path
          d="M129.602 142H114.323C111.938 142 110 140.062 110 137.677V122.323C110 119.938 111.938 118 114.323 118H129.677C132.062 118 134 119.938 134 122.323V137.677C134 139.988 132.062 142 129.602 142Z"
          fill="white"
        />
        <path
          d="M204.677 142H189.323C186.938 142 185 140.062 185 137.677V122.323C185 119.938 186.938 118 189.323 118H204.677C207.062 118 209 119.938 209 122.323V137.677C209 139.988 207.062 142 204.677 142Z"
          fill="white"
        />
        <path
          d="M165.677 142H150.323C147.938 142 146 140.062 146 137.677V122.323C146 119.938 147.938 118 150.323 118H165.677C168.062 118 170 119.938 170 122.323V137.677C170 139.988 168.062 142 165.677 142Z"
          fill="white"
        />
        <path
          d="M165.677 178H150.323C147.938 178 146 176.062 146 173.677V158.323C146 155.938 147.938 154 150.323 154H165.677C168.062 154 170 155.938 170 158.323V173.677C170 176.062 168.062 178 165.677 178Z"
          fill="white"
        />
        <path
          d="M165.677 214H150.323C147.938 214 146 212.062 146 209.677V194.323C146 191.938 147.938 190 150.323 190H165.677C168.062 190 170 191.938 170 194.323V209.677C170 212.062 168.062 214 165.677 214Z"
          fill="white"
        />
        <g filter="url(#filter0_d)">
          <path
            d="M208.846 106H185.152C182.861 106 181 104.143 181 101.857V78.1429C181 75.8571 182.861 74 185.152 74H208.846C211.137 74 212.998 75.8571 212.998 78.1429V101.786C213.07 104.143 211.208 106 208.846 106Z"
            fill={color}
          />
        </g>
        <path
          d="M192.61 96.9435C192.61 96.9435 192.61 96.9437 192.61 96.9437C192.224 97.1412 191.787 96.7943 191.865 96.3515L192.51 92.6765C192.625 92.0174 192.404 91.3442 191.919 90.8827L189.172 88.2655C188.843 87.9515 189.014 87.3775 189.455 87.3155L193.319 86.7664C193.967 86.6744 194.528 86.2714 194.823 85.6875L196.537 82.2925C196.734 81.9025 197.267 81.9025 197.464 82.2925L199.177 85.6875C199.472 86.2714 200.034 86.6744 200.681 86.7664L204.546 87.3155C204.987 87.3775 205.158 87.9515 204.828 88.2655L202.081 90.8827C201.597 91.3442 201.375 92.0174 201.491 92.6763L202.136 96.3515C202.214 96.7945 201.776 97.1415 201.39 96.9435L197.484 94.9369C197.179 94.7804 196.818 94.7804 196.513 94.937L192.61 96.9433C192.61 96.9433 192.61 96.9435 192.61 96.9435Z"
          fill="#F3F5F7"
        />
        <path
          d="M129.602 102H114.323C111.938 102 110 100.062 110 97.677V82.323C110 79.9379 111.938 78 114.323 78H129.677C132.062 78 134 79.9379 134 82.323V97.677C134 100.062 132.062 102 129.602 102Z"
          fill="white"
        />
        <path
          d="M165.677 102H150.323C147.938 102 146 100.062 146 97.677V82.323C146 79.9379 147.938 78 150.323 78H165.677C168.062 78 170 79.9379 170 82.323V97.677C170 100.062 168.062 102 165.677 102Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="177"
          y="73"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.2 0 0 0 0 0.235294 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default InstallPwaIllustration;
