import React, { useContext, useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { Button, NewWaves } from '~/components';
import { IApplicationState } from '~/redux-tools/store';
import { DefaultPage, PaymentReceipt } from '~/interfaces/general';
import { CheckoutContext as MyTabContext } from '~/pages/myTabPaymentDetails';
import { ModalCloseButtonTypesEnum, TunaPaymentMode } from '~/interfaces/enums';

import { CardErrorList } from '../Modal';
import { ReceiptInfo } from './components';

import * as S from './styles';

export interface PaymentReceiptModalProps {
  mode: string;
  isShow: boolean;
  hasError?: boolean;
  onClose: () => void;
  isPageLike: boolean;
  receipt?: PaymentReceipt;
}

const PaymentReceiptModal: React.FC<PaymentReceiptModalProps> = ({
  mode,
  isShow,
  receipt,
  onClose,
  isPageLike,
  hasError = false
}) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { paymentOption, setDefaultPage, setShowPaymentOptionModal } = useContext(MyTabContext);
  const { getTranslation } = useTranslator();

  const handleChangePayment = useCallback(
    (type: DefaultPage) => {
      setDefaultPage(type);
      onClose();

      setShowPaymentOptionModal(true);
    },
    [onClose, setShowPaymentOptionModal, setDefaultPage]
  );

  const renderFooter = useMemo(() => {
    if (hasError) {
      return (
        <S.Footer>
          {receipt && receipt.mode === TunaPaymentMode.card && (
            <>
              <p className="tip">{getTranslation('paymentReceiptModal.mainReasons')}</p>

              <CardErrorList />
            </>
          )}
        </S.Footer>
      );
    }

    if (!isPageLike) {
      return (
        <S.Footer $isModal>
          <S.Actions $inFooter>
            <Button isGhost={false} onClick={(): void => onClose()}>
              {getTranslation('paymentReceiptModal.backToMyAccount')}
            </Button>
          </S.Actions>
        </S.Footer>
      );
    }

    return (
      <S.Footer>
        <S.WavesWrapper>
          <NewWaves />
        </S.WavesWrapper>
      </S.Footer>
    );
  }, [getTranslation, hasError, isPageLike, onClose, receipt]);

  const headerConfig = useMemo(() => {
    if (isPageLike) {
      return {
        closeButton: ModalCloseButtonTypesEnum.arrow,
        title: getTranslation('paymentReceiptModal.voucher')
      };
    }

    return {
      title: undefined,
      closeButton: ModalCloseButtonTypesEnum.times
    };
  }, [getTranslation, isPageLike]);

  return (
    <S.Container
      isShow={isShow}
      onClose={onClose}
      headerTitle={headerConfig.title}
      isPageLike={isPageLike || hasError}
      closeButton={{ color: theme.colors.textColor, type: headerConfig.closeButton }}
    >
      <S.Content>
        <ReceiptInfo
          mode={mode}
          receipt={receipt}
          isVisible={isShow}
          hasError={hasError}
          payment={paymentOption}
          showProgressBar={!isPageLike}
        />

        {hasError ? (
          <S.Actions>
            <Button isGhost={false} onClick={(): void => handleChangePayment('selected')}>
              {getTranslation('paymentReceiptModal.reviewData')}
            </Button>

            <Button onClick={(): void => onClose()}>{getTranslation('paymentReceiptModal.backToMyAccount')}</Button>
          </S.Actions>
        ) : (
          <p className="tip">
            {getTranslation('paymentReceiptModal.paymentReceipt', {
              text: !isPageLike ? getTranslation('paymentReceiptModal.availableInYourAccount') : ' '
            })}
          </p>
        )}
      </S.Content>

      {renderFooter}
    </S.Container>
  );
};

export default PaymentReceiptModal;
