import {
  Address,
  CartValues,
  CartProduct,
  DeliveryFeeStatic,
  DeliveryFeeDynamic,
  HandlePageReloadProps,
  DeliveryFeeNeighborhood
} from '~/interfaces/general';

export interface CartState {
  readonly total: number;
  readonly lastUpdate?: Date;
  readonly address?: Address;
  readonly values: CartValues;
  readonly order_version: number;
  readonly establishment: string;
  readonly products: CartProduct[];
  readonly hasNewProductOnBag: boolean;
  readonly preventPageReload?: HandlePageReloadProps;
  readonly deliveryFee?: DeliveryFeeDynamic | DeliveryFeeStatic | DeliveryFeeNeighborhood;
}

export const CartActionTypes = {
  CLEAN_CART: '@@cart/CLEAN_CART',
  ADD_PRODUCT: '@@cart/ADD_PRODUCT',
  EDIT_PRODUCT: '@@cart/EDIT_PRODUCT',
  REMOVE_COMBO: '@@cart/REMOVE_COMBO',
  REMOVE_PRODUCT: '@@cart/REMOVE_PRODUCT',
  SET_CART_VALUES: '@@cart/SET_CART_VALUES',
  SET_USER_ADDRESS: '@@cart/SET_USER_ADDRESS',
  SET_DELIVERY_FEE: '@@cart/SET_DELIVERY_FEE',
  SET_ESTABLISHMENT: '@@cart/SET_ESTABLISHMENT',
  SET_ADDRESS_LAT_LNG: '@@cart/SET_ADDRESS_LAT_LNG',
  REMOVE_USER_ADDRESS: '@@cart/REMOVE_USER_ADDRESS',
  REMOVE_DELIVERY_FEE: '@@cart/REMOVE_DELIVERY_FEE',
  SET_NEW_PRODUCT_ON_BAG: '@@cart/SET_NEW_PRODUCT_ON_BAG',
  SET_PREVENT_PAGE_RELOAD: '@@cart/SET_PREVENT_PAGE_RELOAD',
  CHANGE_PRODUCT_QUANTITY: '@@cart/CHANGE_PRODUCT_QUANTITY'
};
