import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';
import { setIsDarkMode } from '~/redux-tools/store/theme/actions';
import { FeaturesEnum, LocalStorageEnum } from '~/interfaces/enums';

import useHasFeatureAccess from '../useHasFeatureAccess';

export default function useThemeDetector(): void {
  const dispatch = useDispatch();

  const { settings, loading: isLoading } = useSelector((state: IApplicationState) => state.establishment);

  const storeSettingTheme = useMemo(() => settings?.mm_theme, [settings?.mm_theme]);
  const isAbrahaoStore = useMemo(() => settings?.is_abrahao, [settings?.is_abrahao]);
  const [hasNewDeliveryAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.NewDeliveryMenu });

  useEffect(() => {
    if (!settings || isLoading) return;

    if ((!isAbrahaoStore && !hasNewDeliveryAccess) || !settings.mm_theme) {
      dispatch(setIsDarkMode(false));
      return;
    }

    if (typeof localStorage !== 'undefined') {
      const localStorageIsDarkMode = localStorage.getItem(LocalStorageEnum.isDarkMode);
      const isSystemDarkMode: boolean = window.matchMedia('(prefers-color-scheme: dark)').matches;

      if (!localStorageIsDarkMode || localStorageIsDarkMode === null) {
        switch (storeSettingTheme) {
          case 'dark':
            dispatch(setIsDarkMode(true));
            break;

          case 'light':
            dispatch(setIsDarkMode(false));
            break;

          case 'system':
            dispatch(setIsDarkMode(isSystemDarkMode));
            break;
        }

        return;
      }

      if (localStorageIsDarkMode === 'system') {
        dispatch(setIsDarkMode(isSystemDarkMode));
        return;
      }

      dispatch(setIsDarkMode(localStorageIsDarkMode === 'dark'));
      return;
    }
  }, [dispatch, hasNewDeliveryAccess, isAbrahaoStore, isLoading, settings, storeSettingTheme]);
}
