import styled, { css } from 'styled-components';

import InputMask from '../InputMask';

const containerModifiers = {
  withoutMargin: () => css`
    label {
      margin: 0;
    }

    input {
      width: 100%;
    }
  `
};

export const Container = styled.div<{ withoutMargin?: boolean }>`
  ${({ withoutMargin }) => css`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${withoutMargin && containerModifiers.withoutMargin()};
  `}
`;

export const Label = styled.label`
  margin: 1rem 0 0.285rem 1rem;

  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
`;

export const Input = styled(InputMask)`
  width: calc(100% - 32px);
`;
