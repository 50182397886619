import { action } from 'typesafe-actions';

import { IReducerAction } from '~/redux-tools/store';

import { ThemeActionTypes, BrandColors } from './types';

export const themeFetchRequest = (storeId: string): IReducerAction<{ storeId: string }> =>
  action(ThemeActionTypes.THEME_FETCH_REQUEST, { storeId });

export const themeFetchSuccess = (theme: BrandColors): IReducerAction<{ theme: BrandColors }> =>
  action(ThemeActionTypes.THEME_FETCH_SUCCESS, { theme });

export const themeFetchError = (message: string): IReducerAction<{ message: string }> =>
  action(ThemeActionTypes.THEME_FETCH_ERROR, { message });

export const setIsDarkMode = (isDarkMode: boolean): IReducerAction<{ isDarkMode: boolean }> =>
  action(ThemeActionTypes.SET_IS_DARK_MODE, { isDarkMode });
