import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const StyledModal =
  !!Modal &&
  styled(Modal).attrs((props: ModalProps) => props)`
    > .container {
      > .content {
        height: calc(100vh - 3.25rem);
      }
    }
  `;

export const Flags = styled.div`
  margin: 1.25rem 0 4px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .by {
    margin-bottom: 0.62rem;

    display: flex;
    align-items: center;

    p {
      margin-right: 0.62rem;

      font-size: 0.75rem;
      font-weight: 500;
      line-height: 150%;

      color: ${({ theme }) => theme.colors.darkGray};
    }
  }
`;

export const FormContainer = styled(Form)`
  margin: auto;
  height: 100%;
  max-width: 800px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: center;

  > * {
    text-align: left;
  }

  .button-container {
    min-height: 1.87rem;
    justify-self: flex-end;
  }
`;

export const Content = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 1.5rem 1rem 4rem;

  background-color: ${({ theme }) => theme.colors.lightestGray};

  .label {
    margin-bottom: 4px;

    font-size: 0.87rem;
    line-height: 1.31rem;
  }
`;

export const ProtectAreaWrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 0 2rem 0;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0.5rem;

      color: ${theme.colors.mediumGray};
    }
  `}
`;

export const ProtectAreaText = styled.div`
  ${({ theme }) => css`
    font-weight: 600;
    line-height: 140%;
    font-size: 0.875rem;
    color: ${theme.colors.gray500};
  `}
`;

export const Footer = styled.div`
  bottom: 0;
  left: 50%;
  position: fixed;
  transform: translate(-50%, 0);

  width: 100%;
  max-width: 800px;
  min-height: 1.87rem;
  padding: 1.5rem 1rem;
`;

export const ModalHelperBody = styled.div`
  height: 250px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .text {
    padding-top: 40px;

    font-size: 1.2em;
    color: ${({ theme }) => theme.colors.mediumGray};
  }

  button {
    width: calc(100% - 32px);
    margin: 1rem;
  }
`;

export const SafeAreaWrapper = styled.div`
  ${({ theme }) => css`
    margin: 0.5rem 0 1.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      margin-right: 0.5rem;

      color: ${theme.colors.gray500};
    }

    > span {
      font-weight: 500;
      line-height: 1rem;
      font-size: 0.75rem;
      font-size: 0.687rem;
      color: ${theme.colors.gray500};
    }
  `}
`;
