import { useCallback, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { useUserAuthenticationStatus } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { QueryParam, WelcomeEnum } from '~/interfaces/enums';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { themeFetchSuccess } from '~/redux-tools/store/theme/actions';
import { handleRedirectWithRetry, isEstablishmentOpen } from '~/utils';
import { cleanCheckIn } from '~/redux-tools/store/localOrders/actions';
import { IEstablishmentSettings, IStoreClosed } from '~/interfaces/general';
import { setEstablishmentSettings } from '~/redux-tools/store/establishment/actions';
import { setOrderFromDashboard, setUTMvalues } from '~/redux-tools/store/user/actions';

import useIsAppEnabled from '../useIsAppEnabled';

const GBOT_SOURCE_UTM = 'gbot';

interface UseSplashProps {
  slug: string;
  isQrCode?: boolean;
  settings: IEstablishmentSettings;
}

function useSplash({ slug, settings, isQrCode = false }: UseSplashProps): {
  isLoading: boolean;
  storeOpen: { isOpen: boolean };
  gaTrackSignInOut: () => void;
  isLocalOrdersEnabled: boolean;
  gaTrackInStoreMenu: () => void;
  gaTrackGoomerGoMenu: () => void;
  gaTrackSignInEnter: (type: string) => void;
} {
  const history = useRouter();
  const dispatch = useDispatch();

  const localOrders = useSelector((state: IApplicationState) => state.localOrders.date);

  const [isLoading, setIsLoading] = useState(true);

  const [isGoomerGoEnabled] = useIsAppEnabled();
  const { isUserAuthenticated } = useUserAuthenticationStatus();

  useEffect(() => {
    const isPageLoading = settings?.id === 0;

    if (!isPageLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1400);
    }
  }, [settings?.id]);

  useEffect(() => {
    if (localOrders === null || history.pathname.includes('qrcode')) return;

    dispatch(cleanCheckIn());
  }, [localOrders, dispatch, history.pathname]);

  useEffect(() => {
    dispatch(setEstablishmentSettings(settings));

    dispatch(
      themeFetchSuccess({
        primary: settings?.mm_main_color,
        originalPrimary: settings?.mm_main_color
      })
    );
  }, [dispatch, settings]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const couponCode = urlParams.get(QueryParam.coupon);

    if (couponCode && !!settings && settings.mm_coupon_enabled) {
      history.push(`/menu?${QueryParam.coupon}=${couponCode}`);
    }

    const utmId = urlParams.get(QueryParam.utmId) || undefined;
    const utmOrigin = urlParams.get(QueryParam.utmOrigin) || undefined;
    const utmSource = urlParams.get(QueryParam.utmSource) || undefined;
    const utmMedium = urlParams.get(QueryParam.utmMedium) || undefined;
    const utmCampaign = urlParams.get(QueryParam.utmCampaign) || undefined;

    const utm = { utmId, utmSource, utmOrigin, utmMedium, utmCampaign };

    dispatch(setUTMvalues(utm));

    if (utmSource === GBOT_SOURCE_UTM && !!settings) {
      // Delay adicionado para que evento seja enviado após load do gtag
      setTimeout(() => {
        GoogleAnalytics.trackEvent(gaEvents.gbotAccess, {
          establishment_id: settings.id
        });
      }, 1000);

      const { pathname } = window.location;
      history.push({ pathname }, undefined, { shallow: true });
    }

    const panelOrder = urlParams.get(QueryParam.panelOrder) || undefined;
    dispatch(setOrderFromDashboard(!!panelOrder));

    if (panelOrder) {
      history.push('/menu');
    }
  }, [dispatch, history, settings]);

  useEffect(() => {
    if (slug === undefined || (!isQrCode && !isGoomerGoEnabled)) {
      handleRedirectWithRetry({
        history,
        errorMenu: '',
        id: settings?.id,
        errorInfo: 'splash screen redirect'
      });
    }
  }, [history, settings?.id, settings?.store_code, slug, isGoomerGoEnabled, isQrCode]);

  useEffect(() => {
    // Just work on https urls
    function hideBrowserTopInfoOnMobile() {
      window.addEventListener('load', () => {
        setTimeout(() => {
          scrollTo(0, 1);
        }, 0);
      });
    }

    hideBrowserTopInfoOnMobile();
  }, []);

  const storeOpen = useMemo(() => {
    const isStoreClosed = (info: IStoreClosed | boolean | undefined) => {
      if (info === undefined) return false;

      if (typeof info === 'object') return info.closed;

      return info;
    };

    let result = isEstablishmentOpen({ settings, isLocalOrders: !!localOrders });
    if (settings) {
      const storeClosed = localOrders
        ? isStoreClosed(settings?.mm_in_store_store_closed)
        : isStoreClosed(settings?.mm_store_closed);

      if (storeClosed || settings?.mm_temporarily_closed) {
        result = {
          isOpen: false
        };
      }
    }

    return result;
  }, [localOrders, settings]);

  const gaTrackGoomerGoMenu = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.goomerGoMenu, {
      establishment_id: settings.id
    });

    const { host, protocol } = window.location;

    window.location.assign(`${protocol}//${host}/menu${isUserAuthenticated ? `?welcome=${WelcomeEnum.return}` : ''}`);
  }, [isUserAuthenticated, settings.id]);

  const gaTrackInStoreMenu = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.goomerInStoreMenu, {
      establishment_id: settings.id
    });

    const { host, protocol } = window.location;

    window.location.assign(`${protocol}//${host}/information?type=splash-local-orders`);
  }, [settings.id]);

  const gaTrackSignInEnter = useCallback(
    (type: string) => {
      GoogleAnalytics.trackEvent(gaEvents.signInEnter, {
        type,
        establishment_id: settings.id
      });

      const { host, protocol } = window.location;

      window.location.assign(`${protocol}//${host}/auth?${QueryParam.loginType}=${type}`);
    },
    [settings.id]
  );

  const gaTrackSignInOut = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.signInOut, {
      establishment_id: settings.id
    });

    const { host, protocol } = window.location;

    window.location.assign(`${protocol}//${host}/menu${isUserAuthenticated ? `?welcome=${WelcomeEnum.return}` : ''}`);
  }, [isUserAuthenticated, settings.id]);

  const isLocalOrdersEnabled = useMemo(() => {
    return settings?.mm_in_store_enabled;
  }, [settings]);

  return {
    isLoading,
    storeOpen,
    gaTrackSignInOut,
    gaTrackInStoreMenu,
    gaTrackSignInEnter,
    gaTrackGoomerGoMenu,
    isLocalOrdersEnabled
  };
}

export default useSplash;
