import { useMemo, useCallback } from 'react';

import { useRouter } from 'next/router';

import { useTranslator } from '~/hooks';
import { Group, Menu } from '~/interfaces';
import { useMenuMenus } from '~/hooks/useMenu/hooks';
import { MenuSessionStorageEnum } from '~/interfaces/enums';

import * as S from './styles';

interface RelatedCategoriesProps {
  title?: string;
  currentCategory?: number;
  categoryFilter?: number[];
  showOnlyCurrentMenu?: boolean;
}

const RelatedCategories = ({
  title,
  currentCategory,
  categoryFilter = [],
  showOnlyCurrentMenu
}: RelatedCategoriesProps): JSX.Element => {
  const history = useRouter();

  const { menuList, selectedMenu } = useMenuMenus();
  const { getTranslation } = useTranslator();

  const goToCategory = useCallback(
    (group: Group): void => {
      const selectedCategory = {
        id: String(group.id),
        categoryName: group.name
      };

      sessionStorage.setItem(
        MenuSessionStorageEnum.lastSelectedCategoryIdSessionData,
        JSON.stringify(selectedCategory)
      );

      history.push(`/category/${group.id}`);
    },
    [history]
  );

  const categoryList = useMemo(() => {
    const actionList: {
      categoryId: number;
      onClick: () => void;
      categoryName: string;
    }[] = [];

    const addGroupsToSuggestions = (currentMenu?: Menu) => {
      if (!currentMenu?.groups) {
        return;
      }

      currentMenu.groups?.forEach((group) => {
        if ((categoryFilter.length > 0 && !categoryFilter.includes(group.id)) || currentCategory === group.id) {
          return;
        }

        actionList.push({
          categoryId: group.id,
          categoryName: group.name,
          onClick: () => goToCategory(group)
        });
      });
    };

    addGroupsToSuggestions(selectedMenu);

    if (!showOnlyCurrentMenu) {
      menuList.forEach((menu: Menu) => {
        if (menu.id === selectedMenu?.id) {
          return;
        }

        addGroupsToSuggestions(menu);
      });
    }

    return actionList;
  }, [selectedMenu, showOnlyCurrentMenu, categoryFilter, currentCategory, goToCategory, menuList]);

  const renderCategories = useCallback(() => {
    const allCategoriesDisplayedList = categoryList.map(({ onClick, categoryName }, index) => {
      return (
        <S.RelatedCategory
          key={index}
          onClick={(): void => {
            onClick();
          }}
        >
          {categoryName}
        </S.RelatedCategory>
      );
    });

    return <>{allCategoriesDisplayedList}</>;
  }, [categoryList]);

  if (categoryList.length === 0) {
    return <></>;
  }

  return (
    <S.Container>
      <S.Title>{title ?? getTranslation('general.categories')}</S.Title>

      <S.Wrapper>{renderCategories()}</S.Wrapper>
    </S.Container>
  );
};

export default RelatedCategories;
