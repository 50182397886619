import styled, { css } from 'styled-components';

export const Menu = styled.div`
  height: 100%;
  padding: 1.5rem 1rem;

  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  .container {
    display: flex;
    flex-direction: column;
  }

  .content {
    flex-grow: 1;
  }
`;

export const Content = styled.div`
  height: 100%;
  padding-bottom: 0;
  padding: 1.5rem 1rem;

  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.div`
  margin: 0;

  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const InfoSection = styled.div`
  ${({ theme }) => css`
    margin: 1.5rem 0 0;

    gap: 0.5rem;
    display: flex;
    flex-direction: column;

    > p {
      margin: 0;

      font-weight: 500;
      text-align: left;
      line-height: 1rem;
      font-size: 0.75rem;
      color: ${theme.colors.gray500};
    }
  `};
`;

export const Subtitle = styled.div`
  margin: 0;

  font-weight: 700;
  text-align: left;
  font-size: 0.87rem;
  line-height: 1.12rem;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const RulesWrapper = styled.div`
  margin: 1.5rem 0 0;
  flex-grow: 1;
`;

export const Section = styled.div`
  text-align: left;

  > strong {
    margin-bottom: 4px;
  }
`;

export const RulesList = styled.ul`
  margin-bottom: 1rem;
  padding: 0 0 0 1.5rem;

  text-align: left;
  list-style: disc;
`;

export const RulesItem = styled.li`
  margin-bottom: 1rem;

  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.13rem;
  color: ${({ theme }) => theme.colors.mediumGray};

  &:first-child {
    margin-top: 0.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;
