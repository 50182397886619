import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid ${theme.colors.gray300};

    display: flex;
    align-items: center;

    background-color: ${theme.colors.backgroundColor};

    > .content {
      margin: 0;
      width: 100%;
      position: relative;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      > svg {
        margin-right: 0.75rem;

        fill: ${theme.colors.mediumGray};
      }

      > .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        > strong,
        small {
          display: flex;
          align-items: center;

          text-align: left;
          font-weight: 700;
          line-height: 150%;
          font-size: 0.875rem;
          color: ${theme.colors.textColor};
        }

        > small {
          font-weight: 600;
          line-height: 150%;
          font-size: 0.875rem;
          color: ${theme.colors.mediumGray};
        }
      }
    }
  `}
`;
