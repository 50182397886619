import { ReferenceId } from '~/interfaces/general';
import { Option, OptionGroup, OptionPricingTypeEnum } from '~/interfaces';
import { FetchProductOptionalsResponse } from '~/services/fetchProductOptionals';

interface OldOption {
  id: number;
  name: string;
  hash?: string;
  price: number;
  quantity?: number;
  code?: ReferenceId;
  indexPosition: number;
}

interface OldOptional {
  id: number;
  max?: number;
  min?: number;
  name: string;
  type?: string;
  repeat?: number;
  options: OldOption[];
}

export interface OldOptionalGroupList {
  option_groups: OldOptional[];
}

export default function parserFetchProductOptionalsResponse(
  oldResponse: OldOptionalGroupList
): FetchProductOptionalsResponse {
  const newResponse: FetchProductOptionalsResponse = {
    flow_list: [],
    option_groups: oldResponse.option_groups.reduce((previousOptionGroup, currentOptionGroup) => {
      previousOptionGroup.push({
        id: currentOptionGroup.id,
        max: currentOptionGroup.max,
        min: currentOptionGroup.min,
        name: currentOptionGroup.name,
        repeat: currentOptionGroup.repeat,
        pricing_type: OptionPricingTypeEnum.Individual,
        required: !!currentOptionGroup.min && currentOptionGroup.min > 0,
        options: currentOptionGroup.options.reduce((previousOption, currentOption) => {
          previousOption.push({
            flow_ids: [],
            image: undefined,
            id: currentOption.id,
            description: undefined,
            code: currentOption.code,
            hash: currentOption.hash,
            name: currentOption.name,
            price: currentOption.price,
            indexPosition: currentOption.indexPosition
          });

          return previousOption;
        }, new Array<Option>())
      });

      return previousOptionGroup;
    }, new Array<OptionGroup>())
  };

  return newResponse;
}
