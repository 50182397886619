import { TunaSessionData } from '~/interfaces/general';
import { TunaSessionStorageEnum } from '~/interfaces/enums';

import Tuna, { Essentials } from '../../node_modules/tuna-gateway/src/essentials';

async function generateTunaTokenizatorSessionData(authenticationToken?: string): Promise<TunaSessionData> {
  const apiPath = `${process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL}/tuna/token`;

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${authenticationToken}`);
  const response = await fetch(apiPath, { method: 'GET', headers: headers });

  if (response.status === 200) {
    const sessionData = await response.json();
    if (sessionData?.id && sessionData.token) {
      const halfHourFromNow = new Date(new Date().getTime() + 30 * 60000);
      return { customerId: sessionData.id, token: sessionData.token, expirationDate: halfHourFromNow.valueOf() };
    }
    throw new Error('Tuna did send sessionId');
  }
  throw new Error('Tuna lambda did not respond well');
}

export async function getTunaTokenizatorSessionData(authenticationToken?: string): Promise<TunaSessionData> {
  const tokenizatorSessionData = sessionStorage.getItem(TunaSessionStorageEnum.TokenizatorSessionData);

  if (tokenizatorSessionData) {
    const tokenizatorSessionDataObj = JSON.parse(tokenizatorSessionData);
    if (tokenizatorSessionDataObj.expirationDate > new Date().valueOf()) {
      return tokenizatorSessionDataObj;
    }
  }
  const sessionData = await generateTunaTokenizatorSessionData(authenticationToken);

  sessionStorage.setItem(TunaSessionStorageEnum.TokenizatorSessionData, JSON.stringify(sessionData));
  return sessionData;
}

export default async function getTuna(authenticationToken?: string): Promise<Essentials> {
  const tokenSession = await getTunaTokenizatorSessionData(authenticationToken);

  return Tuna(tokenSession.token, `${process.env.NEXT_PUBLIC_TUNA_ENVIRONMENT}`);
}

export { Essentials } from '../../node_modules/tuna-gateway/src/essentials';
