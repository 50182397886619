import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { isFirefox, isMobile, isMobileSafari } from 'react-device-detect';

import isOnPwa from '~/utils/isOnPwa';
import { Button } from '~/components';
import { MobilePhoneIcon } from '~/assets';
import { IApplicationState } from '~/redux-tools/store';
import { usePwaInstallPrompt, useTranslator } from '~/hooks';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { ModalCloseButtonTypesEnum } from '~/interfaces/enums';

import * as S from './styles';

export interface InstallPwaModalProps {
  isShow: boolean;
  onClose: () => void;
  isFullPage?: boolean;
}

const PWA_DISABLED_STORELIST = (process.env.NEXT_PUBLIC_PWA_DISABLED_STORE || []) as number[];

const InstallPwaModal = ({ isShow, onClose, isFullPage = false, ...rest }: InstallPwaModalProps): JSX.Element => {
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const isLightTheme = useSelector((state: IApplicationState) => state.theme.theme.isLightBrandColor);

  const { getTranslation } = useTranslator();
  const { isLoading, pwaInstallPrompt } = usePwaInstallPrompt({ onFinish: onClose });

  const checkIsOnPwa = useMemo(() => isOnPwa(), []);

  const handleInstallPwa = useCallback(() => {
    if (pwaInstallPrompt) {
      pwaInstallPrompt.prompt();

      GoogleAnalytics.trackEvent(gaEvents.ggoPwaInstall, {
        // eslint-disable-next-line camelcase
        establishment_id: settings?.store_code
      });
    }
  }, [pwaInstallPrompt, settings]);

  const isUnknownBrowserWithoutInstallPrompt = useMemo(() => {
    const isKnownBrowser = isMobileSafari || isFirefox;

    return !isLoading && !pwaInstallPrompt && !isKnownBrowser;
  }, [pwaInstallPrompt, isLoading]);

  const isPwaDisabled = settings && PWA_DISABLED_STORELIST.includes(settings.store_code);

  const modalConfigs = useMemo(() => {
    return {
      ...rest,
      onClose,
      height: '540px',
      isWithoutPadding: true,
      closeButton: {
        type: ModalCloseButtonTypesEnum.times,
        color: isLightTheme ? '#30333C' : '#FFF'
      }
    };
  }, [isLightTheme, onClose, rest]);

  if (!isMobile || isUnknownBrowserWithoutInstallPrompt || isPwaDisabled) return <></>;

  return (
    <S.Container isPageLike={isFullPage} {...modalConfigs} isShow={isShow && !isLoading && !checkIsOnPwa}>
      <S.Illustration $isFullPage={isFullPage} />

      <S.Content>
        <h2>
          {getTranslation('pwaModal.addOurApp')} <MobilePhoneIcon />
        </h2>

        <p dangerouslySetInnerHTML={{ __html: getTranslation('pwaModal.noDownloadNeeded') }} />

        <S.CustomArrow $show={!isLoading} $hasActions={!!pwaInstallPrompt} />

        <S.Actions show={isShow && !isLoading && !!pwaInstallPrompt} remove={!isLoading && !pwaInstallPrompt}>
          <Button isGhost={false} onClick={handleInstallPwa}>
            {getTranslation('pwaModal.addInHomeScreen')}
          </Button>

          <Button isSimple onClick={onClose}>
            {getTranslation('general.noThanks')}
          </Button>
        </S.Actions>
      </S.Content>
    </S.Container>
  );
};

export default InstallPwaModal;
