import styled, { css } from 'styled-components';

import { RadioC } from '~/components/Option/Option/styles';

export const RadioGroupContainer = styled.div`
  margin: 0;
  padding-bottom: 8rem;

  display: flex;
  flex-direction: column;
`;

interface RadioHourContainerProps {
  $isChecked: boolean;
}

export const RadioHourContainer = styled.label<RadioHourContainerProps>`
  ${({ theme }) => css`
    position: relative;

    width: 100%;
    height: 3.5rem;
    padding: 0 1rem;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    & + & {
      border-top: 1px solid ${theme.colors.lightGray};
    }

    input {
      position: absolute;

      opacity: 0;
      pointer-events: all;
    }
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    margin: 0;

    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    color: ${theme.colors.mediumGray};
  `}
`;

export const CustomRadio = styled(RadioC)``;
