import React, { useState, useContext, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { IApplicationState } from '~/redux-tools/store';
import { cpfMask, hidePartOfDocumentNumber } from '~/utils';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { CheckoutContext as OrderContext } from '~/pages/order';
import { CheckoutContext as LocalOrderContext } from '~/pages/localOrder';
import { usePaymentMethods, useTranslator, useGetPaymentMethodDescription } from '~/hooks';
import { OptionHeader, PaymentModal, PaymentChosen, WalletBanner, Button } from '~/components';

import { Container } from './styles';

interface PaymentOptionProps {
  title: string;
  isModalOpen?: boolean;
}

const PaymentOption: React.FC<PaymentOptionProps> = ({ title, isModalOpen = false }) => {
  const { getTranslation } = useTranslator();
  const { isApplePayEnabled, isGooglePayEnabled } = usePaymentMethods();
  const { getPaymentMethodDescription } = useGetPaymentMethodDescription();

  const user = useSelector((state: IApplicationState) => state.user.data);
  const checkout = useSelector((state: IApplicationState) => state.checkout);
  const localOrder = useSelector((state: IApplicationState) => state.localOrders.mode);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const checkoutCart = useContext(localOrder ? LocalOrderContext : OrderContext);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const isGooglePayPaymentMethodSelected: boolean = useMemo(
    () =>
      (!!user.paymentPreference && user.paymentPreference[0]?.category === PaymentCategoryEnum.googlePay) ||
      checkout.paymentOption?.category === PaymentCategoryEnum.googlePay,
    [checkout.paymentOption?.category, user.paymentPreference]
  );

  const isApplePayPaymentMethodSelected: boolean = useMemo(
    () =>
      (!!user.paymentPreference && user.paymentPreference[0]?.category === PaymentCategoryEnum.applePay) ||
      checkout.paymentOption?.category === PaymentCategoryEnum.applePay,
    [checkout.paymentOption?.category, user.paymentPreference]
  );

  const shouldRenderWalletBanner: JSX.Element | null = useMemo(() => {
    if (
      isApplePayPaymentMethodSelected ||
      isGooglePayPaymentMethodSelected ||
      (!isGooglePayEnabled && !isApplePayEnabled)
    )
      return null;

    if (isGooglePayEnabled) return <WalletBanner method="google" />;

    return <WalletBanner method="apple" />;
  }, [isApplePayEnabled, isApplePayPaymentMethodSelected, isGooglePayEnabled, isGooglePayPaymentMethodSelected]);

  const hasSomePaymentInfo: boolean = useMemo(() => {
    if (Array.isArray(user.paymentPreference) && user.paymentPreference.length > 0) {
      const userPreferencesForCurrentEstablishment = user.paymentPreference.find(
        (payment) => payment.establishment === settings?.name
      );

      if (userPreferencesForCurrentEstablishment?.category) {
        if (
          localOrder &&
          [PaymentCategoryEnum.mPagoLink, PaymentCategoryEnum.vrPagueLink].includes(
            userPreferencesForCurrentEstablishment?.category
          )
        ) {
          return false;
        }

        return true;
      }
    }

    if (checkoutCart.paymentOption?.flag || checkoutCart.paymentOption?.category) return true;

    return false;
  }, [
    localOrder,
    settings?.name,
    user.paymentPreference,
    checkoutCart.paymentOption?.flag,
    checkoutCart.paymentOption?.category
  ]);

  const isThePaymentMethodSelected = useMemo(
    () =>
      !!checkoutCart.paymentOption?.category ||
      !!checkoutCart.paymentOption?.flag ||
      !!checkoutCart.paymentOption?.type,
    [checkoutCart]
  );

  const cpfWithHiddenPart: string | undefined = useMemo(() => {
    if (checkoutCart.paymentOption?.category === PaymentCategoryEnum.nupay)
      return hidePartOfDocumentNumber(cpfMask(checkoutCart.paymentOption?.document));
  }, [checkoutCart.paymentOption?.category, checkoutCart.paymentOption?.document]);

  const openPaymentMethodsModal: () => void = () => {
    setIsModalVisible(true);

    GoogleAnalytics.trackEvent(gaEvents.openPaymentMethods);
  };

  useEffect(() => {
    setIsModalVisible(isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    const userEstablishmentPreferences =
      Array.isArray(user.paymentPreference) &&
      user.paymentPreference?.find((payment) => payment.establishment === settings?.name);

    const hasChangedCategoryAndFlag: boolean =
      !!user.paymentPreference &&
      !!userEstablishmentPreferences &&
      checkoutCart.paymentOption?.flag === '' &&
      String(checkoutCart.paymentOption?.category) === '';

    if (hasChangedCategoryAndFlag) {
      checkoutCart.setPaymentOption(userEstablishmentPreferences || undefined);
    }

    if (!checkout.paymentOption && user.paymentPreference?.length === 0) {
      checkoutCart.setPaymentOption(undefined);
    }
  }, [checkoutCart, user, settings, user.paymentPreference, checkout.paymentOption]);

  useEffect(() => {
    const isAppPaymentMethod = String(checkoutCart.paymentOption?.cardDescription).includes('****');

    if (isAppPaymentMethod && user?.paymentPreference?.length === 0) {
      checkoutCart.setPaymentOption();
    }
  }, [checkoutCart, user]);

  return (
    <>
      <Container>
        <OptionHeader
          isRequired
          title={title}
          subtitle={false}
          name="payment-header"
          isDone={isThePaymentMethodSelected && hasSomePaymentInfo}
        />

        <div className="content">
          {hasSomePaymentInfo ? (
            <PaymentChosen
              id="btn-payment-method"
              accountDocument={cpfWithHiddenPart}
              dataTest="btn-selected-payment-method"
              paymentMethod={checkoutCart.paymentOption}
              onClick={(): void => setIsModalVisible(true)}
              title={checkoutCart.paymentOption?.type || ''}
              isPix={checkoutCart.paymentOption?.category === PaymentCategoryEnum.pix}
              moneyChange={checkoutCart.moneyChange ? checkoutCart.moneyChangeValue : 0}
              isCashPayment={checkoutCart.paymentOption?.category === PaymentCategoryEnum.cash}
              description={getPaymentMethodDescription({ paymentMethod: checkoutCart?.paymentOption })}
            />
          ) : (
            <Button id="btn-payment-method" data-test="btn-payment-method" onClick={openPaymentMethodsModal}>
              {getTranslation('payment.paymentMethods')}
            </Button>
          )}
        </div>
      </Container>

      {shouldRenderWalletBanner}

      <PaymentModal
        isModalVisible={isModalVisible}
        closeModal={(): void => {
          checkoutCart.setShowPaymentOptionModal(false);
          setIsModalVisible(false);
        }}
      />
    </>
  );
};

export default PaymentOption;
