import styled, { css } from 'styled-components';

export const Container = styled.div<{ $isDetailedCard?: boolean }>`
  ${({ $isDetailedCard }) => css`
    ${$isDetailedCard &&
    css`
      margin-top: 0.62rem;
    `}
  `}
`;

export const PriceWithoutDiscount = styled.div`
  opacity: 0.5;

  font-weight: 600;
  font-size: 0.87rem;
  line-height: 1.21rem;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const PriceWithDiscount = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const DiscountWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`;
