import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  background-color: ${({ theme }) => theme.colors.backgroundColor};

  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const Content = styled.div`
  margin: 0 auto 0 0.5rem;
`;

const iconWrapperModifiers = {
  large: () => css`
    > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  `,
  medium: () => css`
    > svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  `
};

export const IconWrapper = styled.div<{ iconSize?: 'medium' | 'large' }>`
  ${({ iconSize = 'medium' }) => css`
    width: 1.25rem;
    height: 1.25rem;

    ${iconSize && iconWrapperModifiers[iconSize]};
  `}
`;

export const Title = styled.h2`
  font-weight: 600;
  text-align: left;
  font-size: 1rem;
  line-height: 1.56rem;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const Description = styled.span`
  font-weight: 500;
  text-align: left;
  font-size: 0.85rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.gray500};
`;
