import { useContext, useMemo } from 'react';

import { AddressPageModeEnum } from '~/interfaces/enums';
import { AddressContext } from '~/components/Address/AddressList';
import { AddressFormContext } from '~/components/Address/AddressList/components/Form';

import useAddressIsNeighborhoodTax from './useAddressIsNeighborhoodTax';

export default function useAddressRequiredFields() {
  const { selectedView } = useContext(AddressContext);
  const { cep, state, street, cityName, neighborhood, streetNumber, neighborhoodId } = useContext(AddressFormContext);

  const { isNeighborhoodTax } = useAddressIsNeighborhoodTax();

  const hasAllRequiredFieldsFilled: boolean = useMemo(() => {
    const commonFields: boolean =
      !!(street && street?.length > 0 && street.trim()) &&
      !!(streetNumber && streetNumber?.length > 0) &&
      !!(neighborhood && neighborhood?.length > 0 && neighborhood.trim()) &&
      !!(cityName && cityName?.length >= 3 && cityName.trim()) &&
      !!(state && state?.length > 0 && state.trim());

    const cepField = !!(cep && cep?.length >= 9);

    if (selectedView === AddressPageModeEnum.noCepForm) {
      return commonFields;
    }

    if (selectedView === AddressPageModeEnum.noStreetByCepForm) {
      return cepField && commonFields;
    }

    if (isNeighborhoodTax) {
      return cepField && commonFields && !!neighborhoodId;
    } else {
      return cepField && commonFields;
    }
  }, [cep, cityName, isNeighborhoodTax, neighborhood, neighborhoodId, selectedView, state, street, streetNumber]);

  return { hasAllRequiredFieldsFilled };
}
