import styled, { css } from 'styled-components';

import Button from '~/components/Button/Button';

export const Container = styled.div`
  position: relative;

  height: 100%;
  padding: 0 16px;
  padding-bottom: 60px;

  opacity: 1;
  transition: 0.5s;
  background: ${({ theme }) => theme.colors.lightestGray};

  @media screen and (max-width: 800px) {
    min-width: 100%;
  }

  .group {
    display: flex;
    flex-direction: column;

    margin: 1.5rem 0 0;

    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }

  > .text {
    margin: 0;
    margin-top: 145px;

    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.textColor};

    font-size: 20px;
    line-height: 140%;
    font-weight: bold;
    font-style: normal;
  }

  > .desc {
    margin: 0;
    margin-top: 8px;
    padding: 0 20px 0 20px;

    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.colors.mediumGray};

    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    line-height: 150%;
    text-align: center;
  }
`;

export const CustomButton = styled(Button)`
  margin: 0.25rem 0 0;

  span {
    font-weight: 600;
    line-height: 140%;
    font-size: 0.875rem;

    color: ${({ theme }) => theme.brandColors.primary};
  }
`;

export const ChatButton = styled(Button)`
  margin-top: 32px;

  border: 1px solid ${({ theme }) => theme.brandColors.primary};

  > svg {
    margin-right: 8px;

    path {
      fill: ${({ theme }) => theme.brandColors.primary};
    }
  }

  > p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    font-style: normal;
    color: ${({ theme }) => theme.brandColors.primary};
  }
`;

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${theme.isDarkMode ? theme.colors.pureTextColor : 'transparent'};
  `}

  > img {
    width: 2.5rem;
    height: 2.5rem;

    padding: 0;
    margin: 0 !important;
  }
`;
