import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { RiEBike2Line } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';

import * as S from './styles';

interface FreeDeliveryProps {
  value: number | string;
}

const FreeDelivery: React.FC<FreeDeliveryProps> = ({ value }) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  const renderCard = useMemo(() => {
    return (
      <S.Content>
        <S.SvgWrapper>
          <RiEBike2Line color={theme.colors.backgroundColor} size={16} />
        </S.SvgWrapper>

        <p
          dangerouslySetInnerHTML={{
            __html: getTranslation('general.freeDeliveryMinimumValue', { value: convertToCurrency(Number(value)) })
          }}
        />
      </S.Content>
    );
  }, [getTranslation, theme.colors.backgroundColor, value]);

  return renderCard;
};

export default FreeDelivery;
