import { isTodayBetween, isCurrentTimeBetween } from '@goomerdev/goomer-toolbox/src/utils';

import { Hour } from '~/interfaces';

function isProductOrCategoryAvailable(hours: Hour): boolean {
  const isAvailableToday = isTodayBetween({ to: hours.to, from: hours.from });
  const isAvailableOnTheCurrentTime = isCurrentTimeBetween({ to: hours.close, from: hours.open });

  return isAvailableToday && isAvailableOnTheCurrentTime;
}

export default isProductOrCategoryAvailable;
