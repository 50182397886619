import { api } from '~/services/api';
import { ErrorInfo } from '~/interfaces/general';
import getFormattedBirthdayApi from '~/utils/getFormattedBirthdayApi';

interface GetRegisteredUserProps {
  isNewUser: boolean;
  name: string;
  phone: string;
  birthday?: string;
  code: string;
  token: string;
  addErrorLog: (log: ErrorInfo) => void;
}

export default async function verifyEmailCode({
  isNewUser,
  name,
  phone,
  birthday,
  code,
  token,
  addErrorLog
}: GetRegisteredUserProps) {
  const apiPath = `${process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL}/signup/confirmation`;

  const formatedBirthday = birthday ? getFormattedBirthdayApi(birthday, '/', '-') : undefined;

  try {
    const response = await api.post(
      apiPath,
      {
        is_new_user: isNewUser,
        id: token,
        name,
        phone_number: phone,
        birthday: formatedBirthday,
        code,
        method: 'code'
      },
      {
        validateStatus: (status) => status === 200 || status === 201 || status === 404
      }
    );

    return response;
  } catch (error) {
    addErrorLog({
      code: '#08',
      message: 'Erro ao verificar código por e-mail.',
      error: `${error}`,
      request: apiPath,
      response: '',
      payload: JSON.stringify({ id: token, code })
    });

    throw new Error(`GET error: ${error}`);
  }
}
