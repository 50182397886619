import { api } from '~/services/api';
import { ErrorInfo } from '~/interfaces/general';
import { setHeaderAndCookieAuthToken } from '~/utils';
import getFormattedBirthdayApi from '~/utils/getFormattedBirthdayApi';

interface SendEmailVerificationCodeProps {
  googleToken: string;
  name?: string;
  phone?: string;
  birthday?: string;
  addErrorLog: (log: ErrorInfo) => void;
}

export default async function sendGoogleRegistrationInformation({
  googleToken,
  name,
  phone,
  birthday,
  addErrorLog
}: SendEmailVerificationCodeProps) {
  const apiPath = `${process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL}/signup/google`;

  const formatedBirthday = getFormattedBirthdayApi(birthday || '', '/', '-');

  try {
    const response = await api.post(apiPath, {
      google_token: googleToken,
      name,
      phone_number: phone,
      birthday: formatedBirthday
    });

    if (response.data.token) {
      setHeaderAndCookieAuthToken({ token: response.data.token });
    }

    return response;
  } catch (error) {
    addErrorLog({
      message: '#25 Erro ao fazer login via Google.',
      error: `${error}`,
      request: apiPath,
      response: '',
      payload: JSON.stringify({
        name,
        phone_number: phone || '',
        birthday: formatedBirthday || ''
      })
    });

    throw new Error(`GET error: ${error}`);
  }
}
