import { isCurrentTimeBetween, isTodayBetween } from '@goomerdev/goomer-toolbox/src/utils';

import { Price } from '~/interfaces';

const usePromotionalPrice = (prices: Price[]) => {
  const filteredPricesWithPromotionalList = prices.filter((price) => !!price?.promotion);

  if (filteredPricesWithPromotionalList.length === 0) {
    return [];
  }

  const promotionalPriceAvailableList = filteredPricesWithPromotionalList.filter((eachPrice) => {
    const { promotion } = eachPrice;

    const hasAvailableHour = promotion?.available_hours.find((eachHour) => {
      const shouldDisplayPromoToday = isTodayBetween({ from: eachHour.from, to: eachHour.to });
      const shouldDisplayPromoHour = isCurrentTimeBetween({ from: eachHour.open, to: eachHour.close });

      if (shouldDisplayPromoToday && shouldDisplayPromoHour) {
        return true;
      }

      return false;
    });

    return !!hasAvailableHour;
  });

  return promotionalPriceAvailableList;
};

export default usePromotionalPrice;
