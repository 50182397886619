import { action } from 'typesafe-actions';

import { ProductType } from '~/interfaces/enums';
import { IOpenSign, IEstablishmentSettings, EstablishmentLoyaltyProgram, ICoupon, Chatbot } from '~/interfaces/general';

import { EstablishmentActionTypes } from './types';

interface FetchEstablishmentRequestProps {
  slug: string;
  isAbrahao: boolean;
  provider: ProductType;
  selectedLanguage: string | null;
  afterAction: (hasError: boolean) => void;
}

export const fetchEstablishmentRequest = ({
  slug,
  provider,
  isAbrahao,
  afterAction,
  selectedLanguage
}: FetchEstablishmentRequestProps): { type: string; payload: FetchEstablishmentRequestProps } =>
  action(EstablishmentActionTypes.FETCH_ESTABLISHMENT_REQUEST, {
    slug,
    provider,
    isAbrahao,
    afterAction,
    selectedLanguage
  });

export const fetchEstablishmentError = (message: string) =>
  action(EstablishmentActionTypes.FETCH_ESTABLISHMENT_ERROR, message);

export const setFetchEstablishment = () => action(EstablishmentActionTypes.FETCH_INFOS);

export const setEstablishmentSettings = (settings: IEstablishmentSettings | undefined) =>
  action(EstablishmentActionTypes.SET_SETTINGS, settings);

export const setEstablishmentOpen = (open: IOpenSign) => action(EstablishmentActionTypes.SET_OPEN_SIGN, open);

export const setViewMode = (isViewMode: boolean) => action(EstablishmentActionTypes.SET_VIEW_MODE, isViewMode);

export const setIsMPTokenValid = (isMPTokenValid: boolean) =>
  action(EstablishmentActionTypes.SET_IS_MP_TOKEN_VALID, isMPTokenValid);

export const removeMPPaymentMethods = () => action(EstablishmentActionTypes.REMOVE_MP_PAYMENT_METHODS);

export const setEstablishmentLoyaltyProgram = (loyaltyProgram: EstablishmentLoyaltyProgram | undefined) =>
  action(EstablishmentActionTypes.SET_LOYALT_PROGRAM, loyaltyProgram);

export const setChatbot = (chatbot?: Chatbot) => action(EstablishmentActionTypes.SET_CHATBOT, chatbot);

export const setEstablishmentCoupons = (coupons?: ICoupon[]) => action(EstablishmentActionTypes.SET_COUPONS, coupons);
