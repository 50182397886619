import { IUser, UTMValues } from '~/interfaces/general';

export interface IUserState {
  readonly data: IUser;
  readonly loading: boolean;
  readonly error: string;
  readonly utm: UTMValues;
  readonly orderFromDashboard: boolean;
}

export const UserActionTypes = {
  ADD_USER: '@@user/ADD_USER',
  ADD_USER_PAYMENT: '@@user/ADD_USER_PAYMENT',
  REMOVE_PAYMENT_PREFERENCE: '@@user/REMOVE_PAYMENT_PREFERENCE',

  ADD_USER_CARD: '@@user/ADD_USER_CARD',
  CLEAR_CARD_DATA: '@@user/CLEAR_CARD_DATA',
  EDIT_PAYMENT_PREFERENCES: '@@user/EDIT_PAYMENT_PREFERENCES',
  REMOVE_USER_CARD: '@@user/REMOVE_USER_CARD',
  REMOVE_CARD_TOKENS: '@@user/REMOVE_CARD_TOKENS',

  GET_REGISTERED_USER_REQUEST: '@@user/GET_REGISTERED_USER_REQUEST',
  GET_REGISTERED_USER_SUCCESS: '@@user/GET_REGISTERED_USER_SUCCESS',
  GET_REGISTERED_USER_ERROR: '@@user/GET_REGISTERED_USER_ERROR',
  GENERATE_EMAIL_CODE_REQUEST: '@@user/GENERATE_EMAIL_CODE_REQUEST',
  GENERATE_EMAIL_CODE_SUCCESS: '@@user/GENERATE_EMAIL_CODE_SUCCESS',
  GENERATE_EMAIL_CODE_ERROR: '@@user/GENERATE_EMAIL_CODE_ERROR',
  VERIFY_EMAIL_CODE_REQUEST: '@@user/VERIFY_EMAIL_CODE_REQUEST',
  VERIFY_EMAIL_CODE_SUCCESS: '@@user/VERIFY_EMAIL_CODE_SUCCESS',
  VERIFY_EMAIL_CODE_ERROR: '@@user/VERIFY_EMAIL_CODE_ERROR',
  GOOGLE_REGISTRATION_REQUEST: '@@user/GOOGLE_REGISTRATION_REQUEST',
  GOOGLE_REGISTRATION_SUCCESS: '@@user/GOOGLE_REGISTRATION_SUCCESS',
  GOOGLE_REGISTRATION_ERROR: '@@user/GOOGLE_REGISTRATION_ERROR',
  AUTHENTICATE_USER: '@@user/AUTHENTICATE_USER',

  FETCH_CUSTOMER_INFO_REQUEST: '@@user/FETCH_CUSTOMER_INFO_REQUEST',
  FETCH_CUSTOMER_INFO_SUCCESS: '@@user/FETCH_CUSTOMER_INFO_SUCCESS',
  FETCH_CUSTOMER_INFO_ERROR: '@@user/FETCH_CUSTOMER_INFO_ERROR',
  UPDATE_CUSTOMER_INFO_REQUEST: '@@user/UPDATE_CUSTOMER_INFO_REQUEST',
  UPDATE_CUSTOMER_INFO_SUCCESS: '@@user/UPDATE_CUSTOMER_INFO_SUCCESS',
  UPDATE_CUSTOMER_INFO_ERROR: '@@user/UPDATE_CUSTOMER_INFO_ERROR',
  SET_CUSTOMER_INFO: '@@user/SET_CUSTOMER_INFO',

  ADD_CUSTOMER_ADDRESS_REQUEST: '@@user/ADD_CUSTOMER_ADDRESS_REQUEST',
  ADD_CUSTOMER_ADDRESS_SUCCESS: '@@user/ADD_CUSTOMER_ADDRESS_SUCCESS',
  ADD_CUSTOMER_ADDRESS_ERROR: '@@user/ADD_CUSTOMER_ADDRESS_ERROR',
  UPDATE_CUSTOMER_ADDRESS_REQUEST: '@@user/UPDATE_CUSTOMER_ADDRESS_REQUEST',
  UPDATE_CUSTOMER_ADDRESS_SUCCESS: '@@user/UPDATE_CUSTOMER_ADDRESS_SUCCESS',
  UPDATE_CUSTOMER_ADDRESS_ERROR: '@@user/UPDATE_CUSTOMER_ADDRESS_ERROR',
  DELETE_CUSTOMER_ADDRESS_REQUEST: '@@user/DELETE_CUSTOMER_ADDRESS_REQUEST',
  DELETE_CUSTOMER_ADDRESS_SUCCESS: '@@user/DELETE_CUSTOMER_ADDRESS_SUCCESS',
  DELETE_CUSTOMER_ADDRESS_ERROR: '@@user/DELETE_CUSTOMER_ADDRESS_ERROR',
  SANITIZE_USER_ADDRESSES: '@@user/SANITIZE_USER_ADDRESSES',

  SET_UTM_VALUES: '@@user/SET_UTM_VALUES',
  CLEAN_USER_INFO: '@@user/CLEAN_USER_INFO',
  SET_ORDER_FROM_DASHBOARD: '@@user/SET_ORDER_FROM_DASHBOARD',
  PERSIST_REHYDRATE: 'persist/REHYDRATE'
};
