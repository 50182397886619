import React, { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { showToast } from '~/utils';
import { useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import GoomerExperience from '~/components/GoomerExperience';
import Option, { OptionOnChange } from '~/components/Option/Option';
import getFormattedBirthdayApi from '~/utils/getFormattedBirthdayApi';
import { updateCustomerInfoRequest } from '~/redux-tools/store/user/actions';

import * as S from './styles';

const Notifications = (): JSX.Element => {
  const dispatch = useDispatch();

  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { name, optIn, birthday } = useSelector((state: IApplicationState) => state.user.data);

  const { getTranslation } = useTranslator();

  const optInRadioCheckList: { id: number }[] = useMemo(() => (optIn ? [{ id: 0 }] : []), [optIn]);

  const handleOnChange = (props: OptionOnChange | React.InputHTMLAttributes<HTMLInputElement>): void => {
    const addErrorLog = (): void => {
      showToast({ type: 'error', closeOnClick: true, text: getTranslation('loggedArea.errorMessage') });
    };

    const afterAction = (): void => {
      showToast({ type: 'success', closeOnClick: true, text: getTranslation('loggedArea.successMessage') });
    };

    const { check: isChecked } = props as OptionOnChange;

    dispatch(
      updateCustomerInfoRequest({
        name: name || '',
        optIn: !!isChecked,
        storeId: settings?.id || 0,
        birthday: getFormattedBirthdayApi(birthday || '', '-', '/'),
        functions: {
          addErrorLog,
          afterAction
        }
      })
    );
  };

  return (
    <S.Container>
      <S.Content>
        <Option
          id={0}
          price=""
          title=""
          isAvailable
          type="check"
          isGoomerColor
          groupName="optIn"
          className="option"
          optionValue="optIn"
          onChange={handleOnChange}
          radioCheck={optInRadioCheckList}
          info={getTranslation('loggedArea.notificationsExplanation')}
        />
      </S.Content>

      <S.GoomerWrapper>
        <GoomerExperience />
      </S.GoomerWrapper>
    </S.Container>
  );
};

export default Notifications;
