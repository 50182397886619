import { action } from 'typesafe-actions';

import { CheckoutActionTypes, CheckoutState } from './types';
import { ICoupon } from '~/interfaces/general';

export const addCheckoutData = (checkoutData: CheckoutState): { type: string; payload: CheckoutState } => {
  return action(CheckoutActionTypes.ADD_CHECKOUT_DATA, checkoutData);
};

export const addCheckoutCoupon = (selectedCoupon?: ICoupon): { type: string; payload?: ICoupon } => {
  return action(CheckoutActionTypes.ADD_CHECKOUT_COUPON, selectedCoupon);
};

export const setErrorCheckoutData = (error: boolean): { type: string; payload: boolean } => {
  return action(CheckoutActionTypes.SET_ERROR_CHECKOUT_DATA, error);
};

export const removeCheckoutData = (): { type: string } => {
  return action(CheckoutActionTypes.REMOVE_CHECKOUT_DATA);
};
