import { OptionalInputProps } from '~/interfaces/general';

export default function handleOptionalsElementType({
  max,
  min,
  repeat,
  required
}: OptionalInputProps): 'number' | 'check' | 'radio' {
  if (repeat) {
    return 'number';
  }

  if (min === 1 && max === 1) {
    if (!required) {
      return 'check';
    }

    return 'radio';
  }

  if (min === 0 && max >= 1) {
    return 'check';
  }

  if (min > 1 && max > 1 && min === max) {
    return 'check';
  }

  if (min > 0 && max > 1 && min !== max) {
    return 'check';
  }

  return 'check';
}
