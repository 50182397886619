/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import hexToRgba from 'hex-to-rgba';
import styled, { css, DefaultTheme } from 'styled-components';

import { ToastTypeEnum } from '~/interfaces/enums';

export const typeModifiers = {
  warning: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.warning400};

    > .icon {
      svg {
        color: ${theme.colors.warning700};
      }
    }

    > p {
      color: ${theme.colors.warning700};
    }
  `,
  ghost: (theme: DefaultTheme) => css`
    padding: 0;
    border: 1px solid transparent;

    background-color: transparent;

    > .icon {
      svg {
        color: ${theme.colors.info};
      }
    }

    > p {
      color: ${theme.colors.gray500};
    }
  `,
  blank: (theme: DefaultTheme) => css`
    border: 1px solid ${hexToRgba(theme.colors.textColor, 0.2)};

    background-color: ${hexToRgba(theme.colors.textColor, 0.05)};

    > p {
      color: ${theme.colors.textColor};
    }

    > .icon {
      svg {
        color: ${theme.colors.textColor};
      }
    }
  `,
  info: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.info};

    > .icon {
      svg {
        color: ${theme.isDarkMode ? theme.colors.backgroundColor : theme.colors.textColor};
      }
    }

    > p {
      color: ${theme.isDarkMode ? theme.colors.backgroundColor : theme.colors.textColor};
    }
  `,
  error: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.error};

    > .icon {
      svg {
        color: ${theme.isDarkMode ? theme.colors.textColor : theme.colors.backgroundColor};
      }
    }

    > p {
      color: ${theme.isDarkMode ? theme.colors.textColor : theme.colors.backgroundColor};
    }
  `,
  success: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.success};

    > .icon {
      svg {
        color: ${theme.isDarkMode ? theme.colors.textColor : theme.colors.backgroundColor};
      }
    }

    > p {
      color: ${theme.isDarkMode ? theme.colors.textColor : theme.colors.backgroundColor};
    }
  `
};

export const Container = styled.div<{ type: ToastTypeEnum }>`
  ${({ type, theme }) => css`
    border-radius: 0.5rem;
    padding: 0.5rem;

    display: flex;
    align-items: center;

    > .icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;

      align-self: baseline;

      svg {
        color: ${theme.colors.backgroundColor};
      }
    }

    span,
    p {
      font-weight: 500;
      text-align: left;
      line-height: 150%;
      font-size: 0.75rem;
      color: ${theme.colors.backgroundColor};

      strong {
        font-weight: 700;
        text-align: left;
        line-height: 150%;
        font-size: 0.75rem;
        color: ${theme.colors.backgroundColor};
      }
    }

    ${typeModifiers[type](theme)};
  `}
`;

export const CustomIcon = styled.div`
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.75rem;
`;
