import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useTranslator } from '~/hooks';
import { Button, Modal } from '~/components';

import * as S from './styles';

interface InfoCvvModalProps {
  isShow: boolean;
  onClose: () => void;
  shouldDisplayImage?: boolean;
}

const InfoCVVModal: React.FC<InfoCvvModalProps> = ({ isShow, onClose, shouldDisplayImage = true }) => {
  const { getTranslation } = useTranslator();

  return (
    <Modal isSmall isShow={isShow} headerTitle={getTranslation('securityCardCodeModal.securityCode')} onClose={onClose}>
      <S.ContentWrapper>
        <S.RegularText>{getTranslation('securityCardCodeModal.insertSecurityCode')}</S.RegularText>

        {shouldDisplayImage && (
          <S.ImageWrapper>
            <LazyLoadImage src="/assets/credit-card-colorful.svg" width="222px" height="156px" />
          </S.ImageWrapper>
        )}

        <Button onClick={onClose} isGhost={false}>
          {getTranslation('general.okayGotIt')}
        </Button>
      </S.ContentWrapper>
    </Modal>
  );
};

export default InfoCVVModal;
