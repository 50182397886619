import { api } from '~/services/api';
import { ErrorInfo } from '~/interfaces/general';

interface SendEmailVerificationCodeProps {
  email: string;
  resend: boolean;
  utm?: {
    source?: string;
    origin?: string;
    medium?: string;
  };
  addErrorLog: (log: ErrorInfo) => void;
}

export default async function generateEmailVerificationCode({
  email,
  utm,
  resend,
  addErrorLog
}: SendEmailVerificationCodeProps) {
  const apiPath = `${process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL}/signup/email`;

  try {
    const response = await api.post(apiPath, {
      mode: 'email',
      origin: resend ? 'resend' : 'signup',
      method: 'code',
      key: email,
      ...utm
    });

    return response;
  } catch (error) {
    addErrorLog({
      code: '#12',
      message: 'Erro ao enviar código por e-mail.',
      error: `${error}`,
      request: apiPath,
      response: '',
      payload: JSON.stringify({
        mode: 'email',
        adultConsent: true,
        origin: resend ? 'resend' : 'signup',
        key: email,
        utmSource: utm?.source,
        utmOrigin: utm?.origin,
        utmMedium: utm?.medium
      })
    });

    throw new Error(`GET error: ${error}`);
  }
}
