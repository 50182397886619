import { createContext, useCallback, useEffect } from 'react';
import { getPerformance } from 'firebase/performance';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuth,
  signOut,
  AuthProvider,
  UserCredential,
  OAuthCredential,
  signInWithPopup,
  GoogleAuthProvider
} from 'firebase/auth';

import firebaseApp from '~/lib/firebase';
import { IApplicationState } from '~/redux-tools/store';
import { cleanUserInfo } from '~/redux-tools/store/user/actions';

import { ModalProvider } from './modal';

const auth = getAuth();
const googleProvider: AuthProvider = new GoogleAuthProvider();

const firebaseMonitoringList = (process.env.NEXT_PUBLIC_FIREBASE_MONITORED_STORES || []) as number[];

export interface AuthContextProps {
  firebaseSignout: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  signInWithGoogle: () => Promise<UserCredential | void>;
  getUserIdToken: (userCredential: UserCredential) => OAuthCredential | null;
}

export const AuthContext = createContext({
  signInWithGoogle: async () => {},
  firebaseSignout: async () => {},
  getUserIdToken: () => null
} as AuthContextProps);

const AppContext: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const getUserIdToken = (userCredential: UserCredential) => {
    return GoogleAuthProvider.credentialFromResult(userCredential);
  };

  const signInWithGoogle = async () => {
    return signInWithPopup(auth, googleProvider);
  };

  const firebaseSignout = useCallback(async () => {
    dispatch(cleanUserInfo());

    return signOut(auth);
  }, [dispatch]);

  useEffect(() => {
    if (!window || !firebaseApp || !settings?.id) {
      return;
    }

    if (firebaseMonitoringList.includes(settings?.id)) {
      getPerformance(firebaseApp);
    }
  }, [settings?.id]);

  return (
    <AuthContext.Provider
      value={{
        signInWithGoogle,
        firebaseSignout,
        getUserIdToken
      }}
    >
      <ModalProvider>{children}</ModalProvider>
    </AuthContext.Provider>
  );
};

export { AppContext };
