import React, { useMemo } from 'react';

import { RiSmartphoneLine } from 'react-icons/ri';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { PaymentMethod } from '~/interfaces/general';
import { cpfMask, cnpjMask, hidePartOfDocumentNumber } from '~/utils';
import { EditTypeEnum, PaymentFlagEnum, PaymentTypeEnum } from '~/interfaces/enums';
import { CheckoutMoneyIcon, CheckoutQrCodeIcon, LittleMachineIcon } from '~/assets';
import { useConvertToCurrencyOrFree, useHandlePaymentTexts, useTranslator } from '~/hooks';

import * as S from './styles';

export interface PaymentChosenProps {
  id?: string;
  cpf?: string;
  title: string;
  dataTest?: string;
  description: string;
  onClick: () => void;
  hideAction?: boolean;
  moneyChange?: number;
  cardDocument?: string;
  icon?: React.ReactNode;
  editType?: EditTypeEnum;
  editAction?: () => void;
  isDeleteAction?: boolean;
  isPix?: boolean | string;
  accountDocument?: string;
  paymentMethod?: PaymentMethod;
  isInsidePaymentWays?: boolean;
  isCashPayment?: boolean | string;
}

const PaymentChosen = ({
  id,
  cpf,
  icon,
  title,
  onClick,
  dataTest,
  editAction,
  moneyChange,
  description,
  cardDocument,
  isPix = false,
  paymentMethod,
  isDeleteAction,
  accountDocument,
  hideAction = false,
  isCashPayment = false,
  isInsidePaymentWays = false,
  editType = EditTypeEnum.icon
}: PaymentChosenProps): JSX.Element => {
  const { getTranslation } = useTranslator();
  const { handlePaymentTexts } = useHandlePaymentTexts();
  const { convertToCurrencyOrFree } = useConvertToCurrencyOrFree();

  const selectedIcon = useMemo(() => {
    if (icon) {
      return icon;
    }

    const isNotRenderIconList: PaymentFlagEnum[] = [PaymentFlagEnum.GooglePay, PaymentFlagEnum.ApplePay];

    if (isNotRenderIconList.includes(paymentMethod?.flag as PaymentFlagEnum)) return <></>;

    if (paymentMethod?.flag === getTranslation(PaymentFlagEnum.MercadoPagoQrCode)) return <CheckoutQrCodeIcon />;

    if (
      paymentMethod?.flag === getTranslation(PaymentFlagEnum.OnlinePaymentLink) ||
      paymentMethod?.type === PaymentTypeEnum.onApp
    )
      return <RiSmartphoneLine size={20} />;

    if (paymentMethod?.category === PaymentCategoryEnum.cash) return <CheckoutMoneyIcon />;

    return <LittleMachineIcon />;
  }, [getTranslation, icon, paymentMethod?.category, paymentMethod?.flag, paymentMethod?.type]);

  const paymentMethodIcon = useMemo(() => {
    const flag = paymentMethod?.flag;
    const category = paymentMethod?.category;

    const flagIconMap: Partial<Record<PaymentFlagEnum | PaymentCategoryEnum, string>> = {
      [PaymentCategoryEnum.nupay]: '/assets/checkout/nupay.svg',
      [PaymentCategoryEnum.integratedPix]: '/assets/checkout/pix.svg',
      [PaymentFlagEnum.ApplePay]: '/assets/checkout/apple-pay-flag.svg',
      [PaymentFlagEnum.GooglePay]: '/assets/checkout/google-pay-flag.svg'
    };

    const flagIconSrc = flagIconMap[flag as PaymentFlagEnum];
    const categoryIconSrc = category ? flagIconMap[category] : undefined;

    const iconToUse = categoryIconSrc || flagIconSrc;

    if (!iconToUse) {
      return;
    }

    return <img src={iconToUse} width={40} height={40} alt={`${flag} flag`} />;
  }, [paymentMethod]);

  const cardDocumentDescription = useMemo(() => {
    if (!cardDocument) {
      return false;
    }

    if (cardDocument.length > 11) {
      return `${getTranslation('general.cnpj')}: ${hidePartOfDocumentNumber(cnpjMask(cardDocument))}`;
    }

    return `${getTranslation('general.cpf')}: ${hidePartOfDocumentNumber(cpfMask(cardDocument))}`;
  }, [cardDocument, getTranslation]);

  return (
    <S.Container
      id={id}
      haveContent
      onClick={onClick}
      editType={editType}
      data-test={dataTest}
      hideAction={hideAction}
      editAction={editAction}
      isDeleteAction={isDeleteAction}
      isInsidePaymentWays={isInsidePaymentWays}
    >
      <strong className="title">
        {selectedIcon && <div className="icon-wrapper">{selectedIcon}</div>}

        {handlePaymentTexts(title || '')}
      </strong>

      <S.PaymentWrapper $hasPaymentLogo={!!paymentMethodIcon}>
        <S.DescriptionWrapper>
          {paymentMethodIcon}

          <div className="sentry-mask">
            <p className="description">{description}</p>

            {!!isCashPayment && !!moneyChange && (
              <p className="description -secondary" data-test="money-change">
                {!!moneyChange &&
                  `${getTranslation('payment.changeFor', { value: convertToCurrencyOrFree(moneyChange) })} `}
              </p>
            )}

            {!!cpf && (
              <p className="description -secondary">
                {getTranslation('payment.documentOnSlip', {
                  document: cpf,
                  documentType: cpf.length > 14 ? getTranslation('general.cnpj') : getTranslation('general.cpf')
                })}
              </p>
            )}

            {!!isPix && <p className="description -info">{getTranslation('payment.paymentReceiptReminder')}</p>}

            {!!cardDocument && <p className="description -secondary">{cardDocumentDescription}</p>}

            {!!accountDocument && (
              <p className="description -secondary">
                {getTranslation('general.cpf')}: {accountDocument}
              </p>
            )}
          </div>
        </S.DescriptionWrapper>
      </S.PaymentWrapper>
    </S.Container>
  );
};

export default PaymentChosen;
