import styled, { css } from 'styled-components';

const containerModifiers = {
  isScrolled: () => css`
    position: sticky;
    top: 0;
    left: 0;
    z-index: 19;
  `,

  isActive: () => css`
    ${({ theme }) => css`
      color: ${theme.brandColors.actionText} !important;
      border: 1px solid ${theme.brandColors.actionBackground};
      background-color: ${theme.brandColors.actionBackground};
    `}
  `
};

export const Container = styled.nav<{ $isScrolled: boolean }>`
  ${({ theme, $isScrolled }) => css`
    position: relative;

    width: 100%;
    margin: auto;
    border: none;
    margin: 0 auto;
    height: 4.78rem;
    max-width: 800px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: initial;

    white-space: nowrap;
    background-color: ${theme.colors.gray200};

    ${$isScrolled && containerModifiers.isScrolled()}
  `}
`;

export const MenuListWrapper = styled.div<{ $isMobile: boolean }>`
  ${({ $isMobile }) => css`
    padding-right: 1rem;

    display: flex;

    cursor: grab;
    overflow-x: hidden;
    scrollbar-width: none;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    ${$isMobile &&
    css`
      padding: 0 1rem;

      overflow-x: scroll;
    `}
  `}
`;

export const Menu = styled.div<{ $isCurrentMenuActive: boolean }>`
  ${({ theme, $isCurrentMenuActive }) => css`
    height: 2.188rem;
    margin: 1rem 0.25rem;
    padding: 0.5rem 1rem;

    gap: 0.28rem;
    display: flex;
    align-items: center;

    border-radius: 1000px;
    background-color: ${theme.colors.gray200};
    border: 1px solid ${theme.colors.gray300};

    cursor: pointer;

    ${$isCurrentMenuActive && containerModifiers.isActive()}
  `}
`;

export const MenuTitle = styled.span<{ $isCurrentMenuActive: boolean }>`
  ${({ theme, $isCurrentMenuActive }) => css`
    min-width: max-content;

    display: inline-block;

    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.42rem;
    color: ${theme.colors.textColor} !important;

    ${$isCurrentMenuActive && containerModifiers.isActive()}
  `}
`;
