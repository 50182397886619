import { toast } from 'react-toastify';
import axios, { AxiosResponse } from 'axios';
import { InStoreEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { api } from '~/services/api';
import { formatTabInfo } from '~/utils';
import { BillAPIResponse, TabDetails } from '~/interfaces/general';
import { APIErrorCodes, QRCodeModeEnum, TabStatusEnum } from '~/interfaces/enums';

export const OperationModes = {
  [QRCodeModeEnum.table]: 'table',
  [QRCodeModeEnum.balcony]: 'kiosk',
  [QRCodeModeEnum.guestCheck]: 'tab',
  [QRCodeModeEnum.display]: 'display',
  [QRCodeModeEnum.delivery]: 'delivery'
};

export interface BillQueryProps {
  aId?: string;
  mode: string;
  tab?: string;
  slug: string;
  table?: string;
  phpStoreId: string;
  isAbrahao?: boolean;
  isStartQuery?: boolean;
  selectedLanguage: string | null;
  getTranslation: (key: string) => string;
}

export default async function getBill({
  aId,
  tab,
  slug,
  mode,
  table,
  isAbrahao,
  phpStoreId,
  isStartQuery,
  getTranslation,
  selectedLanguage
}: BillQueryProps): Promise<
  | TabDetails
  | {
      status: TabStatusEnum;
      cart: { values: {}; products: [] };
    }
> {
  const getApiRequest = () => {
    if (isAbrahao && aId) {
      const payload = {
        card: tab || undefined,
        table_number: table || undefined
      };

      return {
        payload,
        apiPath: `${process.env.NEXT_PUBLIC_API_ABRAHAO || ''}/${aId}/get-bill`
      };
    }

    const payload = {
      tab: tab || undefined,
      table: table || undefined,
      slug: isStartQuery ? slug : undefined,
      operation: OperationModes[mode as QRCodeModeEnum],
      establishment_id: !isStartQuery ? phpStoreId : undefined
    };

    return {
      payload,
      apiPath: `${process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL || ''}${isStartQuery ? '/bills/start-query' : '/bills'}`
    };
  };

  try {
    const callApi = async (): Promise<AxiosResponse<BillAPIResponse>> => {
      const { apiPath, payload } = getApiRequest();
      const apiPathWithLang = new URL(apiPath);

      if (selectedLanguage) {
        apiPathWithLang.searchParams.append('lang', selectedLanguage);
      }

      if (isStartQuery && !isAbrahao) {
        return api.post(apiPath, payload);
      }

      return api.get(apiPathWithLang.toString(), {
        params: payload,
        validateStatus: (status) => status === 200 || (!isAbrahao && status === 404)
      });
    };

    const response = await callApi();

    const { bill, status, payments } = response.data;

    const code = mode === InStoreEnum.table ? table : tab;

    return formatTabInfo({
      mode,
      bill,
      status,
      isAbrahao,
      code: code || '',
      payments: payments || [],
      tableCode: mode === InStoreEnum.guestCheck ? table : undefined
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { data } = error.response;

      if (isStartQuery && data.code === APIErrorCodes.START_BILL_STORE_NOT_INTEGRATED) {
        return {
          status: TabStatusEnum.Local,
          cart: {
            values: {},
            products: []
          }
        };
      }
    }

    console.error((error as Error)?.message);
    toast.error(getTranslation('errorMessage.errorToGetBill'));

    throw new Error(`Start Bill error: ${error}`);
  }
}
