import { useEffect, useMemo, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from '@goomerdev/goomer-toolbox/src/hooks';

import { IApplicationState } from '~/redux-tools/store';
import { getThirkySearchRequest } from '~/redux-tools/store/thirky/actions';

export default function useThirky(): void {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);

  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { data: thirkyData } = useSelector((state: IApplicationState) => state.thirky);
  const { mode: localOrder } = useSelector((state: IApplicationState) => state.localOrders);

  const { localStorageValue, setLocalStorageValue } = useLocalStorage({ key: 'userThirky' });

  const thirkyUserId = useMemo(() => localStorageValue, [localStorageValue]);

  useEffect(() => {
    if (!thirkyUserId) {
      setLocalStorageValue(JSON.stringify(Math.floor(Math.random() * 10000000)));
    }
  }, [setLocalStorageValue, thirkyUserId]);

  useEffect(() => {
    const isAbleToDispatchThirky: boolean = !!localOrder || !!settings?.mm_store_enable_thirky_ads;

    const shouldDispatchThirky: boolean =
      !!isFirstRender.current && !!isAbleToDispatchThirky && !!settings?.id && !!thirkyUserId && !thirkyData;

    if (shouldDispatchThirky) {
      dispatch(getThirkySearchRequest({ userId: thirkyUserId || '' }));

      isFirstRender.current = false;
    }
  }, [dispatch, thirkyUserId, settings?.id, localOrder, thirkyData, settings?.mm_store_enable_thirky_ads]);
}
