import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const BannerWrapper = styled.div`
  height: 8.75rem;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  margin-top: -1rem;
  padding: 1.5rem 1rem;
  border-radius: 0.5rem 0.5rem 0 0;

  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;

export const Content = styled.div`
  flex-grow: 1;
  text-align: center;

  h2 {
    margin-bottom: 0.5rem;

    font-weight: 800;
    font-size: 1.5rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.textColor};
  }

  p {
    margin-bottom: 1.5rem;

    font-weight: 500;
    line-height: 150%;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray500};
  }
`;
