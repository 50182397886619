import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { all, put, takeLatest } from 'redux-saga/effects';

import { WaiterOptionTypeEnum } from '~/interfaces/enums';
import callWaiterRequest from '~/services/callWaiterRequest';
import { CallWaiterOption, CallWaiterRequest } from '~/interfaces/general';

import { WaiterActionTypes } from './types';
import { callWaiterError, callWaiterSuccess, SetCallWaiterProps } from './actions';

export function* callWaiterSaga({ payload }: AnyAction) {
  const { aId, table, callback, optionList } = payload as SetCallWaiterProps;

  try {
    if (!aId) {
      throw new Error();
    }

    const options: CallWaiterOption[] = [];

    optionList.forEach(({ id, type, value }) => {
      if (!value) {
        return;
      }

      if (type === WaiterOptionTypeEnum.Quantity) {
        return options.push({ id: String(id), quantity: Number(value) });
      }

      return options.push({ id: String(id) });
    });

    const request: CallWaiterRequest = {
      options,
      uuid: uuidv4(),
      table_number: String(table)
    };

    yield callWaiterRequest(request, aId);

    yield put(callWaiterSuccess());
    callback(true);
  } catch (error) {
    yield put(callWaiterError());
    callback(false);
  }
}

export default all([takeLatest(WaiterActionTypes.SET_CALL_WAITER, callWaiterSaga)]);
