import React from 'react';

const CheckoutMoneyIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 23.5C5.64855 23.5 0.5 18.3514 0.5 12C0.5 5.64855 5.64855 0.5 12 0.5C18.3514 0.5 23.5 5.64855 23.5 12C23.5 18.3514 18.3514 23.5 12 23.5ZM12 21.2C14.44 21.2 16.78 20.2307 18.5054 18.5054C20.2307 16.78 21.2 14.44 21.2 12C21.2 9.56001 20.2307 7.21995 18.5054 5.49462C16.78 3.76928 14.44 2.8 12 2.8C9.56001 2.8 7.21995 3.76928 5.49462 5.49462C3.76928 7.21995 2.8 9.56001 2.8 12C2.8 14.44 3.76928 16.78 5.49462 18.5054C7.21995 20.2307 9.56001 21.2 12 21.2ZM7.975 14.3H14.3C14.4525 14.3 14.5988 14.2394 14.7066 14.1316C14.8144 14.0238 14.875 13.8775 14.875 13.725C14.875 13.5725 14.8144 13.4262 14.7066 13.3184C14.5988 13.2106 14.4525 13.15 14.3 13.15H9.7C8.9375 13.15 8.20623 12.8471 7.66707 12.3079C7.1279 11.7688 6.825 11.0375 6.825 10.275C6.825 9.5125 7.1279 8.78123 7.66707 8.24207C8.20623 7.7029 8.9375 7.4 9.7 7.4H10.85V5.1H13.15V7.4H16.025V9.7H9.7C9.5475 9.7 9.40125 9.76058 9.29341 9.86841C9.18558 9.97625 9.125 10.1225 9.125 10.275C9.125 10.4275 9.18558 10.5738 9.29341 10.6816C9.40125 10.7894 9.5475 10.85 9.7 10.85H14.3C15.0625 10.85 15.7938 11.1529 16.3329 11.6921C16.8721 12.2312 17.175 12.9625 17.175 13.725C17.175 14.4875 16.8721 15.2188 16.3329 15.7579C15.7938 16.2971 15.0625 16.6 14.3 16.6H13.15V18.9H10.85V16.6H7.975V14.3Z"
      fill="#7F8F9F"
    />
  </svg>
);

export default CheckoutMoneyIcon;
