import React from 'react';

import { Container } from './styles';

interface ListHeaderProps {
  title: string;
}

const ListHeader: React.FC<ListHeaderProps> = ({ title }) => {
  return (
    <Container>
      <h5>{title}</h5>
    </Container>
  );
};

export default ListHeader;
