import React, { useCallback, useContext, useState } from 'react';

import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { Button, EmailVerificationInput } from '~/components';
import { EmailVerificationStatusEnum } from '~/interfaces/enums';
import { EmailCountdown } from '~/components/AccountVerification/components';
import { AccountVerificationContext } from '~/components/AccountVerification';

import * as S from './styles';

const VerifyEmailCode = (): JSX.Element => {
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const {
    isNewUser,
    userEmail,
    isLoading,
    resetCountdown,
    isCountdownExpired,
    validationCodeStatus,
    setEmailVerificationCode,
    handleContinueWithoutSignUp,
    isAbleToProceedWithoutSignUp,
    generateEmailVerificationCode
  } = useContext(AccountVerificationContext);
  const { getTranslation } = useTranslator();

  const [isRegisterLaterButtonLoading, setIsRegisterLaterButtonLoading] = useState<boolean>(false);

  const handleResendVerificationCode = useCallback(() => {
    if (isCountdownExpired) {
      resetCountdown();
      generateEmailVerificationCode({ resend: true });

      GoogleAnalytics.trackEvent(gaEvents.signInEmailCodeResend, {
        // eslint-disable-next-line camelcase
        establishment_id: settings?.id
      });
    }
  }, [isCountdownExpired, resetCountdown, generateEmailVerificationCode, settings?.id]);

  const handleRegisterLaterButtonClick = (): void => {
    GoogleAnalytics.trackEvent(gaEvents.signInEmailCodeOut, {
      // eslint-disable-next-line camelcase
      establishment_id: settings?.id
    });

    setIsRegisterLaterButtonLoading(true);
    handleContinueWithoutSignUp();
  };

  const gaTrackSignInEmailCodeDone = (): void => {
    GoogleAnalytics.trackEvent(gaEvents.signInEmailCodeDone, {
      // eslint-disable-next-line camelcase
      establishment_id: settings?.id
    });
  };

  return (
    <S.Content>
      <h2>{getTranslation('accountVerification.almostThere')}</h2>

      <p>
        {getTranslation('accountVerification.insertSentCode')} <strong>{userEmail}</strong>
      </p>

      <S.EmailVerificationInputWrapper>
        <EmailVerificationInput
          status={validationCodeStatus}
          onChange={(code: string): void => setEmailVerificationCode(code)}
          isDisabled={isLoading || validationCodeStatus === EmailVerificationStatusEnum.success}
        />
      </S.EmailVerificationInputWrapper>

      <S.CountdownWrapper isHide={validationCodeStatus === EmailVerificationStatusEnum.success}>
        <EmailCountdown />
      </S.CountdownWrapper>

      {isCountdownExpired && validationCodeStatus !== EmailVerificationStatusEnum.success && (
        <Button
          isSimple
          isGoomerColor
          data-test="email-retry"
          className="email-retry"
          style={{ marginTop: '1rem' }}
          onClick={handleResendVerificationCode}
        >
          {getTranslation('accountVerification.resendCode')}
        </Button>
      )}

      <S.Actions>
        <Button
          isGoomerColor
          isGhost={false}
          isLoading={isLoading}
          data-test="success-continue"
          onClick={gaTrackSignInEmailCodeDone}
          isDisabled={validationCodeStatus !== EmailVerificationStatusEnum.success}
        >
          {getTranslation('general.continue')}
        </Button>

        {isAbleToProceedWithoutSignUp && (
          <Button
            isGoomerColor
            style={{ marginTop: '6px' }}
            data-test="continue-without-sign-in"
            isLoading={isRegisterLaterButtonLoading}
            onClick={handleRegisterLaterButtonClick}
          >
            {isNewUser ? getTranslation('general.signIn') : getTranslation('general.signUp')}{' '}
            {getTranslation('general.later').toLowerCase()}
          </Button>
        )}
      </S.Actions>
    </S.Content>
  );
};

export default VerifyEmailCode;
