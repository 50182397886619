import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { RiStarLine } from 'react-icons/ri';

import { convertToCurrency } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import { useLoyaltyProgram, useTranslator } from '~/hooks';

import LoyaltyInfoCard, { LoyaltyInfoCardProps } from '../LoyaltyInfoCard';

import * as S from './styles';

interface LoyaltyValidOrderConfirmationCardProps {
  isLocalOrder?: boolean;
}

const LoyaltyValidOrderConfirmationCard = ({
  isLocalOrder = false
}: LoyaltyValidOrderConfirmationCardProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();
  const { isValidLoyaltyOrder, remainingAmountToFulfillTheProgram } = useLoyaltyProgram();

  const loyaltyInfoCardProps: LoyaltyInfoCardProps = useMemo(() => {
    if (!isValidLoyaltyOrder) {
      return {
        isDarkText: true,
        icon: <RiStarLine size={16} color={theme.colors.backgroundColor} />,
        text: (
          <p
            dangerouslySetInnerHTML={{
              __html: getTranslation('loyalty.missingOrderValueToLoyaltyProgram', {
                remainingValue: convertToCurrency(remainingAmountToFulfillTheProgram || 0)
              })
            }}
          />
        )
      };
    }

    return {
      isDarkText: false,
      title: getTranslation('loyalty.orderWillBeCountedInLoyaltyProgram'),
      icon: <RiStarLine size={16} color={theme.colors.backgroundColor} />,
      text: isLocalOrder ? (
        <p>{getTranslation('loyalty.scoreWillBeConfirmedAfterBill')}</p>
      ) : (
        <p>{getTranslation('loyalty.scoreWillBeConfirmedAfterOrder')}</p>
      )
    };
  }, [
    isLocalOrder,
    getTranslation,
    isValidLoyaltyOrder,
    theme.colors.backgroundColor,
    remainingAmountToFulfillTheProgram
  ]);

  return (
    <S.Content>
      <LoyaltyInfoCard iconTop={isValidLoyaltyOrder} {...loyaltyInfoCardProps} />
    </S.Content>
  );
};

export default LoyaltyValidOrderConfirmationCard;
