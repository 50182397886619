import styled, { css } from 'styled-components';

import { ProductHighlightTagTypeEnum } from '~/interfaces/enums';

const containerModifiers = {
  isAbsolute: () => css`
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;

    margin: 0;
    border-radius: 0;
    padding: 0.125rem;

    gap: 0.125rem;
    align-items: center;
    justify-content: center;

    > svg {
      width: 0.75rem;
      height: 0.75rem;
    }

    > span {
      font-weight: 700;
      line-height: 1rem;
      font-size: 0.625rem;
    }
  `,
  isFixedToTop: () => css`
    top: 0;
    left: 0;
    right: 0;
    bottom: initial;
    position: absolute;

    border-radius: 0.5rem 0 0;
  `
};

export const Container = styled.div<{ $isAbsolute?: boolean; $isFixedToTop?: boolean; $backgroundColor?: string }>`
  ${({ $isAbsolute, $isFixedToTop, $backgroundColor }) => css`
    border-radius: 0.25rem;
    margin: 0 auto 0.5rem 0;
    padding: 0.25rem 0.5rem;

    gap: 0.25rem;
    display: flex;
    align-items: center;

    background-color: ${$backgroundColor};

    > svg {
      width: 1rem;
      height: 1rem;
    }

    ${$isAbsolute && containerModifiers.isAbsolute()}
    ${$isFixedToTop && containerModifiers.isFixedToTop()}
  `}
`;

const textModifiers = {
  viewOnly: css`
    color: ${({ theme }) => (theme.isDarkMode ? theme.colors.gray400 : theme.colors.backgroundColor)};
  `,
  recommended: css`
    color: ${({ theme }) => (theme.isDarkMode ? theme.colors.quaternary400 : theme.colors.quaternary700)};
  `,
  unavailable: css`
    color: ${({ theme }) => (theme.isDarkMode ? theme.colors.textColor : theme.colors.gray500)};
  `
};

export const Text = styled.span<{ type?: ProductHighlightTagTypeEnum }>`
  ${({ type }) => css`
    font-weight: 800;
    font-size: 0.875rem;
    line-height: 1.31rem;

    ${type && textModifiers[type]}
  `}
`;
