/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const Container = styled.div<{ shouldDisplay: boolean }>`
  ${({ theme, shouldDisplay }) => css`
    margin-top: 0.5rem;

    display: ${shouldDisplay ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;

    svg {
      width: 1.25rem;
      height: 1.25rem;

      path {
        fill: ${theme.colors.gray500};
      }
    }

    span {
      margin-left: 0.25rem;

      font-weight: 500;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.gray500};

      strong {
        min-width: 2.65rem;

        display: inline-block;

        font-weight: 700;
        line-height: 150%;
        font-size: 0.875rem;
        color: ${theme.colors.textColor};
      }
    }
  `}
`;
