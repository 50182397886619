import styled, { css } from 'styled-components';

export const Container = styled.button`
  ${({ theme }) => css`
    width: 100%;
    padding: 16px;
    margin-bottom: 4px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.gray300};

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    background-color: ${theme.colors.backgroundColor};

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 130%;

      color: ${theme.colors.textColor};
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 14px;
    line-height: 100%;

    color: ${({ theme }) => theme.colors.gray600};
  }
`;

export const AmountTag = styled.span`
  ${({ theme }) => css`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;

    color: ${theme.colors.backgroundColor};
    background-color: ${theme.brandColors.primary};
  `}
`;
