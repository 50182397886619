import React, { useCallback, useEffect, useRef } from 'react';

import Link from 'next/link';
import { RiShoppingCart2Line } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslator } from '~/hooks';
import { useMenuGroups } from '~/hooks/useMenu/hooks';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { PixelEventsEnum } from '~/utils/reactPixelTrackEvent';
import { convertToCurrency, reactPixelTrackEvent } from '~/utils';
import { StoreGaEventsEnum } from '~/utils/analytics/utils/events';
import { setHasNewProductOnBag } from '~/redux-tools/store/cart/actions';

import { Container } from './styles';

interface CartInfoProps {
  sendTo: string;
}

const CartInfo: React.FC<CartInfoProps> = ({ sendTo }) => {
  const dispatch = useDispatch();

  const cart = useSelector((state: IApplicationState) => state.cart);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { selectedCoupon } = useSelector((state: IApplicationState) => state.coupons.data);

  const { hasNewProductOnBag } = cart;

  const flashRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { getTranslation } = useTranslator();
  const { getGroupByProductId } = useMenuGroups();

  useEffect(() => {
    setTimeout(() => {
      flashRef.current?.classList.add('hide');
      contentRef.current?.classList.add('show');
    }, 1500);

    setTimeout(() => {
      dispatch(setHasNewProductOnBag(false));
    }, 2100);
  }, [cart.hasNewProductOnBag, dispatch]);

  const getNumItems = useCallback(() => {
    return cart.products.reduce((numItems, product) => (numItems += product.quantity), 0);
  }, [cart.products]);

  const getCartCount = useCallback(() => {
    let cartCount = 0;
    const addedComboList: string[] = [];

    cart.products.forEach(({ comboId }) => {
      if (comboId && addedComboList.includes(comboId)) {
        return;
      }

      if (comboId) {
        addedComboList.push(comboId);
      }

      cartCount += 1;
    });

    return cartCount;
  }, [cart.products]);

  const getProductsIdsAndNames = useCallback(() => {
    const idList: string[] = [];
    const nameList: string[] = [];

    cart.products.forEach((product) => {
      idList.push(String(product.code) || '');
      nameList.push(String(product.product.name) || '');
    });

    return [idList, nameList];
  }, [cart.products]);

  const gaTrackCheckout = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.initiateCheckout, {
      value: cart.total,
      establishment_id: settings?.store_code,
      currency: settings?.mm_currency?.code || 'BRL',
      content_ids: cart.products.map((product) => product.id),
      content_names: cart.products.map((product) => product.product.name),
      num_items: cart.products.reduce((totalItems, item) => totalItems + (item.quantity || 1), 0)
    });

    GoogleAnalytics.trackEvent(StoreGaEventsEnum.BeginCheckout, {
      value: cart.total,
      coupon: selectedCoupon?.code,
      currency: settings?.mm_currency?.code || 'BRL',
      items: cart.products.map((product, index) => ({
        index,
        price: product.subtotal,
        quantity: product.quantity,
        item_id: product.product.id,
        item_name: product.product.name,
        item_category: product.product.id ? getGroupByProductId(Number(product.product.id)) : undefined
      }))
    });

    const [idList, nameList] = getProductsIdsAndNames();

    reactPixelTrackEvent(PixelEventsEnum.InitiateCheckout, {
      currency: 'BRL',
      value: cart.total,
      content_ids: idList,
      content_names: nameList,
      num_items: getNumItems()
    });
  }, [
    cart.total,
    getNumItems,
    cart.products,
    getGroupByProductId,
    selectedCoupon?.code,
    settings?.store_code,
    getProductsIdsAndNames,
    settings?.mm_currency?.code
  ]);

  if (!cart || cart?.products.length === 0) {
    return <></>;
  }

  const renderCartInfo = (): JSX.Element => (
    <div className={`cart-info ${!hasNewProductOnBag ? 'show' : ''}`} ref={contentRef}>
      <div className="items">
        <RiShoppingCart2Line size={20} />

        <span className="number">{getCartCount()}</span>
      </div>

      <p className="description">{getTranslation('general.myOrder')}</p>

      <strong className="price">{convertToCurrency(cart.total)}</strong>
    </div>
  );

  const renderFlashMessage = (): JSX.Element => (
    <div className={`flash-message ${!hasNewProductOnBag ? 'removed' : ''}`} ref={flashRef}>
      <p className="description">{getTranslation('general.itemAddedToCart')}</p>
    </div>
  );

  return (
    <Link href={sendTo}>
      <Container data-test="btn-cart-bar" onClick={gaTrackCheckout}>
        <>
          {renderFlashMessage()} {renderCartInfo()}
        </>
      </Container>
    </Link>
  );
};

export default CartInfo;
