import React from 'react';

import { Address, IEstablishmentSettings } from '~/interfaces/general';

import formatCep from '../formatCep';

interface MountAddressProps {
  address?: Address;
  settings: IEstablishmentSettings | undefined;
  withCep?: boolean;
  withStreet?: boolean;
  summary?: boolean;
}

export default function mountAddress({
  address,
  settings,
  withCep = false,
  withStreet = true,
  summary = false
}: MountAddressProps): JSX.Element | string {
  const street = typeof address === 'object' && address.street ? address.street : '';
  const city = typeof address === 'object' && address.city ? `, ${address.city}` : '';
  const state = typeof address === 'object' && address.state ? `/${address.state}` : '';
  const number = typeof address === 'object' && address.number ? `, ${address.number}` : '';
  const neighborhood =
    typeof address === 'object' && address.neighborhood
      ? `${address.complement ? ', ' : ''}${address.neighborhood}`
      : '';

  if (settings?.mm_show_short_address && !settings?.mm_takeaway_enabled) {
    return typeof address === 'object' ? `${address.neighborhood}${city}${state}` : '';
  }

  if (withCep) {
    const cep =
      typeof address === 'object' && address.cep ? `${withStreet ? '- ' : ''}CEP: ${formatCep(address.cep)}` : '';
    const complement =
      typeof address === 'object' && address.complement
        ? `${!withStreet || summary ? ', ' : ''}${address.complement}`
        : '';

    const reference = typeof address === 'object' && address.reference ? `, ${address.reference}` : '';

    if (!withStreet) {
      return typeof address === 'object' ? `${cep}${complement}${neighborhood}${city}${state}${reference}` : '';
    }

    if (summary) {
      return typeof address === 'object' ? `${address.neighborhood}${city}${state} - ${address.cep}` : '';
    }

    if (typeof address === 'object') {
      const firstLine = `${street}${number}`;
      const secondLine = `${complement}${neighborhood}${city}${state}${reference}${cep}`;

      return (
        <>
          {firstLine}
          <br />
          {secondLine}
        </>
      );
    }

    return '';
  }

  if (summary) {
    return typeof address === 'object' ? `${address.neighborhood}${city}${state}` : '';
  }

  return typeof address === 'object' ? `${street}${number}${neighborhood}${city}${state}` : '';
}
