import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  border-radius: 2px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1;

  color: ${({ theme }) => theme.colors.backgroundColor};

  &.backgroundLeft {
    align-items: flex-start;
  }

  .content {
    width: 64px;
    height: 100%;
    padding: 4px;
    max-width: 64px;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 300;
    text-align: center;
  }

  span {
    margin-top: 4px;

    display: block;

    font-size: 12px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.backgroundColor};
  }

  svg {
    width: 32px;
    height: 32px;
    stroke: ${({ theme }) => theme.colors.backgroundColor};

    > path {
      stroke: ${({ theme }) => theme.colors.backgroundColor};
    }
  }
`;
