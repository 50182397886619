import styled, { css } from 'styled-components';

export const Container = styled.div<{ $hasError?: boolean; $canEdit?: boolean }>`
  ${({ theme, $hasError, $canEdit }) => css`
    padding: 1rem;
    border-radius: 0.25rem;
    border: 1px solid ${theme.colors.gray300};

    background-color: ${theme.colors.backgroundColor};

    > svg {
      path {
        fill: ${theme.colors.mediumGray};
      }
    }

    ${$canEdit &&
    css`
      cursor: pointer;
    `}

    ${$hasError &&
    css`
      border: 1px solid ${theme.colors.error};
    `}
  `}
`;

export const ActionWrapper = styled.div<{ $hasEdit?: boolean }>`
  ${({ theme, $hasEdit }) => css`
    max-width: 100%;
    margin-left: 0;

    gap: 0.5rem;
    display: grid;
    align-items: center;
    grid-template-columns: ${$hasEdit ? 'auto 1fr auto' : 'auto 1fr'};

    p {
      margin: 0;

      font-weight: 600;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.textColor};

      &.action {
        text-decoration: underline;
        color: ${theme.brandColors.primary};
      }
    }

    small {
      margin: 0;

      font-weight: 600;
      line-height: 150%;
      font-size: 0.75rem;
      color: ${theme.colors.mediumGray};
    }

    .error {
      display: flex;
      flex-direction: column;
    }
  `}
`;
