import styled, { css } from 'styled-components';

export const Container = styled.div<{ $backgroundColor?: string; $marginBottom?: string }>`
  ${({ theme, $marginBottom, $backgroundColor }) => css`
    width: 100%;
    height: 1rem;
    min-height: 1rem;
    margin-bottom: ${$marginBottom || 0};

    background-color: ${$backgroundColor || 'transparent'};

    ${!theme.isDarkMode &&
    css`
      background-image: linear-gradient(${theme.colors.gray400}, ${theme.colors.gray300}, transparent);
    `}
  `};
`;

export const Waves = styled.div<{ backgroundUrl: string }>`
  ${({ backgroundUrl }) => css`
    height: 100%;

    background-position: top;
    background-repeat: repeat-x;
    background-color: transparent;
    background-image: url(${backgroundUrl});
  `}
`;
