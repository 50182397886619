import { all, takeLatest, put, select, fork, ForkEffect } from 'redux-saga/effects';

import { getThirkySearch } from '~/services';
import { Thirky } from '~/interfaces/general';

import { ThirkyActionTypes } from './types';
import { IApplicationState, IReducerAction } from '..';
import { GetThirkySearchRequestProps, getThirkySearchError, getThirkySearchSuccess } from './actions';

function* handleGetThirkySearchRequest({ payload }: IReducerAction<GetThirkySearchRequestProps>): Generator {
  const { userId } = payload;

  const storeId = yield select((state: IApplicationState) => state.establishment.settings?.id);

  try {
    if (storeId) {
      const response = yield getThirkySearch({
        userId,
        storeId: Number(storeId)
      });

      const thirky = response as Thirky;

      yield put(getThirkySearchSuccess(thirky));
    }
  } catch (error) {
    console.error('Thirky error', error);

    yield put(getThirkySearchError());
  }
}

function* watchGetThirkySearch(): Generator<ForkEffect<never>, void> {
  yield takeLatest(ThirkyActionTypes.GET_THIRKY_SEARCH_REQUEST, handleGetThirkySearchRequest);
}

export default all([fork(watchGetThirkySearch)]);
