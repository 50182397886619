import { useEffect } from 'react';

import { persistConfig } from '~/redux-tools/index';
import { localStorage } from '~/utils';

export default function useRefreshStorage() {
  useEffect(() => {
    if (!localStorage) {
      return () => {};
    }

    const reduxPersistState = localStorage.getItem('persist:goomer-mobile');

    if (!reduxPersistState) return () => {};

    const parsedState = JSON.parse(reduxPersistState);

    const removedFromStorageItems = persistConfig.blacklist
      ? persistConfig.blacklist.filter((item) => {
          const existItemOnStorage = parsedState[item];

          if (!existItemOnStorage) return false;

          localStorage?.removeItem(item);

          return true;
        })
      : [];

    if (removedFromStorageItems.length > 0) {
      window.location.reload();
    }
  }, []);
}
