import { useMemo } from 'react';
import { useLocalStorage } from '@goomerdev/goomer-toolbox/src/hooks';

import { useSelector } from 'react-redux';

import { LocalStorageEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import useCheckProvider from '~/hooks/useCheckProvider';

function useMenuUrl(): string | undefined {
  const { localStorageValue: selectedLanguage } = useLocalStorage({ key: LocalStorageEnum.selectedLanguage });

  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { mode, square_ids: squareIds } = useSelector((state: IApplicationState) => state.localOrders);

  const { provider } = useCheckProvider();

  const menuUrl: string | undefined = useMemo(() => {
    const isLocalOrderMode = !!mode;

    const returnUrlWithParams = (apiUrl: URL) => {
      if (provider) {
        apiUrl.searchParams.append('provider', provider);
      }

      return apiUrl.toString();
    };

    if (isLocalOrderMode) {
      if (!settings?.mm_onsite_menu_url) {
        return;
      }

      const menuUrl = new URL(settings.mm_onsite_menu_url);

      if (squareIds) {
        menuUrl.searchParams.append('square_ids', squareIds);
      }

      if (selectedLanguage) {
        menuUrl.searchParams.append('lang', selectedLanguage);
      }

      return returnUrlWithParams(menuUrl);
    }

    if (settings?.menu_url) {
      const menuUrl = new URL(settings?.menu_url);
      return returnUrlWithParams(menuUrl);
    }

    return '';
  }, [mode, provider, squareIds, selectedLanguage, settings?.menu_url, settings?.mm_onsite_menu_url]);

  return menuUrl;
}

export default useMenuUrl;
