import { useState } from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { FaChevronRight } from 'react-icons/fa';
import { RiShoppingBagLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { ICoupon } from '~/interfaces/general';
import { FirstOrderRulesModal } from '~/components';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';

import * as S from './styles';

interface FirstOrderCardProps {
  coupon: ICoupon;
  isSimpleCard?: boolean;
}

const FirstOrderCard = ({ coupon, isSimpleCard }: FirstOrderCardProps): JSX.Element => {
  const history = useRouter();
  const { getTranslation } = useTranslator();

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const [isFirstOrderRulesModalOpen, setIsFirstOrderRulesModalOpen] = useState<boolean>(false);

  const gaTrackEvent = (gaEvent: gaEvents): void => {
    return GoogleAnalytics.trackEvent(gaEvent, {
      // eslint-disable-next-line camelcase
      establishment_id: settings?.id
    });
  };

  const handleChangeModalVisibility = (): void => {
    setIsFirstOrderRulesModalOpen(!isFirstOrderRulesModalOpen);
  };

  const handleSignIn = (): void => {
    gaTrackEvent(gaEvents.discountFirstSignIn);

    history.push('/auth');
  };

  return (
    <>
      <S.Container
        isSimpleCard={isSimpleCard}
        aria-label={getTranslation('firstOrder.firstOrderRules')}
        onClick={(): void => {
          gaTrackEvent(gaEvents.discountFirstInfo);
          handleChangeModalVisibility();
        }}
      >
        <S.SvgWrapper>
          <RiShoppingBagLine color={theme.colors.backgroundColor} size={16} />
        </S.SvgWrapper>

        <div>
          <span>{getTranslation('firstOrder.storeFirstOrder')}</span>{' '}
          <strong>
            {getTranslation('firstOrder.registerToGetDiscount', {
              discountPercentage: coupon.type === 'absolute' ? convertToCurrency(coupon.value) : `${coupon.value}%`
            })}
          </strong>
        </div>
      </S.Container>

      <FirstOrderRulesModal
        coupon={coupon}
        isOpen={isFirstOrderRulesModalOpen}
        handleSignIn={handleSignIn}
        onClose={(): void => {
          handleChangeModalVisibility();
        }}
      />
    </>
  );
};

export default FirstOrderCard;
