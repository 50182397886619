import { WeekdaysShortTextEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { PricePromotion } from '~/interfaces';
import { convertToCurrency, truncateAmount } from '~/utils';

import DiscountTag from './DiscountTag';

import * as S from './styles';

export interface Hour {
  open: string;
  close: string;
  to: WeekdaysShortTextEnum;
  from: WeekdaysShortTextEnum;
}

interface PromotionalPriceProps {
  oldPrice?: number;
  promotional: PricePromotion;
}

const PromotionalPrice = ({ oldPrice, promotional }: PromotionalPriceProps): JSX.Element => {
  return (
    <S.Container data-test="promotional-price-tag">
      <S.DiscountWrapper>
        <S.PriceWithoutDiscount>{convertToCurrency(oldPrice || 0)}</S.PriceWithoutDiscount>

        <DiscountTag percentage={truncateAmount(promotional?.percentage_flag)} />
      </S.DiscountWrapper>

      <S.PriceWithDiscount>{convertToCurrency(promotional?.price)}</S.PriceWithDiscount>
    </S.Container>
  );
};

export default PromotionalPrice;
