import { format } from 'date-fns';
import br from 'date-fns/locale/pt-BR';

export default function formatFullDate(date: Date | string): string {
  if (typeof date === 'string') {
    const newDate = new Date(date);

    return format(newDate, 'dd/MM/yyyy - HH:mm', { locale: br });
  }

  return format(date, 'dd/MM/yyyy - HH:mm', { locale: br });
}
