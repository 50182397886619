import { Highlight, Menu } from '~/interfaces';

export interface MenusState {
  readonly menus: Menu[];
  readonly isLoading: boolean;
  readonly selectedMenu?: Menu;
  readonly highlights: Highlight[];
  readonly isMenuListEmpty: boolean;
}

export const MenuActionTypes = {
  FETCH_MENUS_ERROR: '@@menus/FETCH_MENUS_ERROR',
  FETCH_MENUS_SUCCESS: '@@menus/FETCH_MENUS_SUCCESS',
  FETCH_MENUS_REQUEST: '@@menus/FETCH_MENUS_REQUEST',

  SELECT_CURRENT_MENU: '@@menus/SELECT_CURRENT_MENU'
};
