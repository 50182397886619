import { useMemo } from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { FaReceipt } from 'react-icons/fa';
import { RiEBike2Line, RiShoppingBagLine, RiStarLine } from 'react-icons/ri';

import { Button } from '~/components';
import { convertToCurrency } from '~/utils';
import { SegmentTypeEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import { useLoyaltyProgram, useTranslator } from '~/hooks';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';

import * as S from './styles';

const LoginCallToAction: React.FC = () => {
  const history = useRouter();

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { coupons, settings } = useSelector((state: IApplicationState) => state.establishment);

  const { isLoyaltyProgramEnabled, hasLoyaltyProgramAccess } = useLoyaltyProgram();
  const { getTranslation } = useTranslator();

  const firstOrderCoupon = useMemo(
    () => coupons?.find((coupon) => coupon.segment === SegmentTypeEnum.first_order),
    [coupons]
  );

  const isFreeDeliveryEnabled = settings?.mm_free_delivery_enabled;

  const handleLoginClick = (): void => {
    GoogleAnalytics.trackEvent(gaEvents.signInCheckout, {
      // eslint-disable-next-line camelcase
      establishment_id: settings?.id
    });

    history.push('/auth');
  };

  const info = useMemo(() => {
    if (firstOrderCoupon?.enabled) {
      return {
        color: theme.colors.success,
        icon: <RiShoppingBagLine color={theme.colors.backgroundColor} size={20} />,
        text: getTranslation('callToAction.logInAndGetFirstOrderDiscount', {
          percentageDiscount:
            firstOrderCoupon.type === 'absolute'
              ? convertToCurrency(firstOrderCoupon.value)
              : `${firstOrderCoupon.value}%`
        })
      };
    }

    if (isFreeDeliveryEnabled && !!settings?.mm_free_delivery_minimum_value) {
      return {
        color: theme.colors.gray600,
        icon: <RiEBike2Line color={theme.colors.backgroundColor} size={20} />,
        text: getTranslation('callToAction.logInAndGetFreeDelivery', {
          minimumValue: convertToCurrency(Number(settings.mm_free_delivery_minimum_value))
        })
      };
    }

    if (hasLoyaltyProgramAccess && isLoyaltyProgramEnabled) {
      return {
        color: theme.colors.warning,
        text: getTranslation('callToAction.logInToLoyaltyProgram'),
        icon: <RiStarLine color={theme.colors.backgroundColor} size={20} />
      };
    }

    return {
      color: theme.colors.info,
      text: getTranslation('callToAction.logInAndSaveYourData'),
      icon: <FaReceipt color={theme.colors.backgroundColor} size={20} />
    };
  }, [
    getTranslation,
    theme.colors.info,
    theme.colors.success,
    theme.colors.gray600,
    theme.colors.warning,
    isFreeDeliveryEnabled,
    firstOrderCoupon?.type,
    hasLoyaltyProgramAccess,
    isLoyaltyProgramEnabled,
    firstOrderCoupon?.value,
    firstOrderCoupon?.enabled,
    theme.colors.backgroundColor,
    settings?.mm_free_delivery_minimum_value
  ]);

  return (
    <S.Container>
      <S.Icon $color={info.color}>{info.icon}</S.Icon>

      <S.Title dangerouslySetInnerHTML={{ __html: info.text }} />

      <Button onClick={handleLoginClick}>{getTranslation('splash.logInOrRegister')}</Button>
    </S.Container>
  );
};

export default LoginCallToAction;
