import React, { useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { AddressModal } from '~/components';
import { IApplicationState } from '~/redux-tools/store';
import { DeliveryZoneTypeEnum, LoggedAreaMenuItemEnum } from '~/interfaces/enums';

import { LoggedAreaContext } from '../..';

import * as S from './styles';

const Addresses = (): JSX.Element => {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { getTranslation } = useTranslator();
  const { setSelectedMenu } = useContext(LoggedAreaContext);

  const hasSettings = useMemo(() => !!settings, [settings]);

  const isNeighborhoodTax = useMemo(() => {
    if (hasSettings) {
      return (
        settings?.mm_delivery_zone_options_neighborhood_enabled &&
        settings?.mm_delivery_zone_type === DeliveryZoneTypeEnum.neighborhood
      );
    }

    return false;
  }, [settings, hasSettings]);

  return (
    <S.Container hasMargin={!isNeighborhoodTax}>
      <AddressModal
        isGoomerColor
        isModalVisible
        title={getTranslation('loggedArea.addresses')}
        closeButtonText={getTranslation('loggedArea.back')}
        setIsModalVisible={(): void => setSelectedMenu(LoggedAreaMenuItemEnum.default)}
      />
    </S.Container>
  );
};

export default Addresses;
