import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { OptionHeader } from '~/components';
import { DeliveryWayEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import { ISchedulingState } from '~/redux-tools/store/scheduling';

import { Scheduling, SelectScheduling } from './components';

import * as S from './styles';

interface CheckoutSchedulingProps {
  showScheduling: boolean;
  schedule: Date | boolean;
  removeSchedule: () => void;
  setSchedule: (value?: Date) => void;
  setShowScheduling: (value: boolean) => void;
}

const CheckoutScheduling = ({
  schedule,
  setSchedule,
  showScheduling,
  removeSchedule,
  setShowScheduling
}: CheckoutSchedulingProps): JSX.Element => {
  const scheduling = useSelector((state: IApplicationState) => state.scheduling);
  const origin = useSelector((state: IApplicationState) => state.coupons.origin);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const isOpen = useSelector((state: IApplicationState) => state.establishment.open.isOpen);

  const [storedScheduling, setStoredScheduling] = useState<ISchedulingState>(scheduling);

  const { getTranslation } = useTranslator();

  const isAlwaysOpen = settings?.mm_always_open;
  const isOnlyScheduling = settings?.mm_order_scheduling_only;
  const customOperationTime = settings?.mm_operating_hours || settings?.mm_fixed_hours;

  useEffect(() => {
    if (JSON.stringify(scheduling) !== JSON.stringify(storedScheduling)) {
      if (storedScheduling.hasSchedulingList) {
        setSchedule(undefined);
      }

      setStoredScheduling(scheduling);
    }
  }, [scheduling, setSchedule, storedScheduling]);

  const hasOperationTime = useMemo(() => {
    return !!customOperationTime || isAlwaysOpen;
  }, [customOperationTime, isAlwaysOpen]);

  const hasDeliveryWayScheduling = useMemo(() => {
    if (origin === DeliveryWayEnum.delivery) {
      return scheduling.deliveryList.length > 0;
    }

    if (origin === DeliveryWayEnum.takeaway) {
      return scheduling.takeawayList.length > 0;
    }

    return false;
  }, [origin, scheduling]);

  if (!hasOperationTime || !hasDeliveryWayScheduling) return <></>;

  return (
    <>
      <S.Container>
        <OptionHeader
          subtitle={false}
          isDone={!!schedule}
          name="scheduling-header"
          isRequired={isOnlyScheduling || !isOpen}
          title={getTranslation('scheduling.scheduling')}
        />

        <div className="content">
          <SelectScheduling
            removeSchedule={removeSchedule}
            schedule={!!schedule && schedule}
            toggleShowScheduling={(): void => setShowScheduling(!showScheduling)}
          />
        </div>
      </S.Container>

      <Scheduling
        isShow={showScheduling}
        setSchedule={setSchedule}
        closeScheduling={(): void => setShowScheduling(false)}
      />
    </>
  );
};

export default CheckoutScheduling;
