import { Reducer } from 'redux';

import { IEstablishmentSettings } from '~/interfaces/general';

import { IEstablishmentState, EstablishmentActionTypes } from './types';

const initialState: IEstablishmentState = {
  error: '',
  loading: true,
  isViewMode: false,
  coupons: undefined,
  settings: undefined,
  isMPTokenValid: false,
  open: { isOpen: false },
  loyaltyProgram: undefined
};

const EstablishmentReducer: Reducer<IEstablishmentState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EstablishmentActionTypes.FETCH_ESTABLISHMENT_REQUEST:
      return {
        ...state,
        error: '',
        loading: true
      };

    case EstablishmentActionTypes.FETCH_ESTABLISHMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case EstablishmentActionTypes.FETCH_INFOS:
      return {
        ...state,
        error: '',
        loading: true
      };

    case EstablishmentActionTypes.SET_SETTINGS:
      return {
        ...state,
        error: '',
        loading: false,
        settings: payload
      };

    case EstablishmentActionTypes.SET_LOYALT_PROGRAM:
      return {
        ...state,
        error: '',
        loading: false,
        loyaltyProgram: payload
      };

    case EstablishmentActionTypes.SET_CHATBOT:
      return {
        ...state,
        error: '',
        loading: false,
        chatbot: payload
      };

    case EstablishmentActionTypes.SET_COUPONS:
      return {
        ...state,
        error: '',
        loading: false,
        coupons: payload
      };

    case EstablishmentActionTypes.SET_OPEN_SIGN:
      return {
        ...state,
        error: '',
        open: payload,
        loading: false
      };

    case EstablishmentActionTypes.SET_VIEW_MODE:
      return {
        ...state,
        isViewMode: payload
      };

    case EstablishmentActionTypes.SET_IS_MP_TOKEN_VALID:
      return {
        ...state,
        isMPTokenValid: payload
      };

    case EstablishmentActionTypes.REMOVE_MP_PAYMENT_METHODS:
      const settingsWithoutMPPaymentMethods = {
        ...state.settings,
        mm_payment_mpago_link_enabled: false,
        mm_payment_mpago_qrcode_enabled: false,
        mm_payment_mpago_checkout_enabled: false
      } as IEstablishmentSettings;

      return {
        ...state,
        settings: settingsWithoutMPPaymentMethods
      };

    default:
      return state;
  }
};

export default EstablishmentReducer;
