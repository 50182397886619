interface ValidateCpfProps {
  cpf: string;
  emptyStringShouldReturnFalse?: boolean;
}

export default function validateCPF({ cpf, emptyStringShouldReturnFalse = false }: ValidateCpfProps): boolean {
  if (cpf === undefined) return false;

  if (cpf === '' && emptyStringShouldReturnFalse) return false;

  if (cpf === '') return true;

  if (cpf.length < 11) return false;

  function isAllSameDigit(cpf: string) {
    for (let i = 1; i < cpf.length; i++) {
      if (cpf[i - 1] !== cpf[i]) return false;
    }
    return true;
  }

  if (isAllSameDigit(cpf)) return false;

  let add, rev;
  // Valida 1o digito
  add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}
