import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useConvertStringArray } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';

import { UseLoyaltyProgramHooksProps } from '..';

export type UseLoyaltySelectedChannelsProps = Pick<
  UseLoyaltyProgramHooksProps,
  'isCurrentChannelSelected' | 'readableChannelNames'
>;

export default function useLoyaltySelectedChannels(): UseLoyaltySelectedChannelsProps {
  const { convertStringArrayIntoReadableString } = useConvertStringArray();

  const localOrders = useSelector((state: IApplicationState) => state.localOrders);
  const { loyaltyProgram: establishmentLoyaltyProgram } = useSelector(
    (state: IApplicationState) => state.establishment
  );

  const isLocalOrder: boolean = useMemo(() => localOrders.mode.length > 0, [localOrders]);

  const isCurrentChannelSelected: boolean = useMemo(
    () =>
      !!establishmentLoyaltyProgram?.channels?.find((channel) => {
        const currentChannelId: number = isLocalOrder ? 2 : 1;
        return channel.id === currentChannelId;
      }),

    [establishmentLoyaltyProgram?.channels, isLocalOrder]
  );

  const selectedChannelNameList: string[] = useMemo(
    () => establishmentLoyaltyProgram?.channels?.map((channel) => channel.name) || [],
    [establishmentLoyaltyProgram]
  );

  const readableChannelNames: string = useMemo(
    () => convertStringArrayIntoReadableString(selectedChannelNameList),
    [convertStringArrayIntoReadableString, selectedChannelNameList]
  );

  return { isCurrentChannelSelected, readableChannelNames };
}
