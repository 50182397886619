import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0 1rem;
  margin-bottom: 2rem;

  overflow-x: hidden;
`;

export const OptionWrapper = styled.div`
  ${({ theme }) => css`
    margin: 0.5rem 0 0;

    padding: 0 1rem;
    border-radius: 0.25rem;
    border: 1px solid ${theme.colors.gray300};

    background-color: ${theme.colors.backgroundColor};

    > div {
      border: none;
    }
  `}
`;
