/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const CheckboxContainer = styled.div<{ $isWithoutLabel: boolean }>`
  ${({ theme, $isWithoutLabel }) => css`
    display: flex;
    align-items: center;

    input {
      width: 16px;
      height: 16px;
    }

    label {
      margin-left: 8px;

      font-weight: 500;
      font-size: 0.857rem;
      line-height: 120%;
      color: ${theme.colors.gray500};
    }

    input[type='checkbox'] {
      margin: 0;
      width: 16px;
      height: 16px;
      border-radius: 3px;

      display: grid;
      place-content: center;

      appearance: none;
      -webkit-appearance: none;
      color: ${theme.colors.backgroundColor};
    }

    input[type='checkbox']:checked {
      background-color: ${theme.brandColors.primary};
    }

    input[type='checkbox']:not(:checked) {
      border: 1px solid ${theme.brandColors.primary};
    }

    input[type='checkbox']::before {
      content: '';
      width: 0.26em;
      height: 0.52em;
      margin-bottom: 0.15rem;
      border-color: ${theme.colors.backgroundColor};
      border-right: 2px solid ${theme.colors.backgroundColor};
      border-bottom: 2px solid ${theme.colors.backgroundColor};

      transform: rotate(45deg);
      transform-origin: center;
    }

    input[type='checkbox']:not(:checked)::before {
      transform: scale(0);
    }

    input[type='checkbox']:disabled {
      cursor: not-allowed;
      border-color: ${theme.colors.gray400};
      background-color: ${theme.colors.gray400};
    }

    ${!$isWithoutLabel &&
    css`
      flex: 1;
    `}

    ${$isWithoutLabel &&
    css`
      input[type='checkbox'] {
        width: 1.72rem;
        height: 1.72rem;
        border-radius: 4px;
      }

      input[type='checkbox']:not(:checked) {
        border: 1px solid #dde2eb;
      }
    `}
  `}
`;
