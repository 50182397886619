import React from 'react';

import { Container } from './styled';

interface InputGroupProps {
  label: string;
  helper?: JSX.Element;
}

const InputGroup: React.FC<InputGroupProps> = ({ label, helper, children }) => {
  return (
    <Container>
      <div className="label">{label}</div>

      {children}

      <span className="input-helper">{helper || ''}</span>
    </Container>
  );
};

export default InputGroup;
