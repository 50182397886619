import 'react-phone-number-input/style.css';
import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export default createGlobalStyle`
  :root {
    /* this has to be set to switch between light or dark */
    color-scheme: ${({ theme }) => (theme.isDarkMode ? 'dark' : 'light')};
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    font-size: 16px;
    font-family: 'Manrope', sans-serif;

    outline: 0;
  }

  *:focus {
    outline: 0;
  }

  input, a, p, h1, h2, h3, h4, h5, span, strong, small {
    color: ${({ theme }) => theme.colors.textColor};
  }

  input {
    background-color: transparent;
  }

  html, body, #__next {
    height: 100%;
  }

  body {
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .react-icons {
    vertical-align: middle;
  }

  .Toastify__toast {
    color: ${({ theme }) => theme.colors.backgroundColor};
    background: ${({ theme }) => theme.brandColors.primary};

    &.Toastify__toast--error{
      border-radius: 0;

      color: ${({ theme }) => theme.colors.backgroundColor};
      background: ${({ theme }) => theme.colors.error};
    }

    &.Toastify__toast--info{
      border-radius: 0;

      color: ${({ theme }) => theme.colors.backgroundColor};
      background: ${({ theme }) => theme.colors.info};
    }

    &.Toastify__toast--success{
      border-radius: 0;

      color: ${({ theme }) => theme.colors.backgroundColor};
      background: ${({ theme }) => theme.colors.success};
    }

    &.Toastify__pix {
      padding: 0 8px;
      min-height: 52px;
    }

    .Toastify__toast-body {
      width: 100%;

      div {
        font-size: 18px;
        text-align: center;
        font-weight: bold;
      }
    }

    .Toastify__toast-icon  {
      display: none;
    }

    .Toastify__close-button  {
      svg {
        path {
          fill: ${({ theme }) => theme.colors.backgroundColor};
        }
      }
    }

    .Toastify__progress-bar--error,
    .Toastify__progress-bar--info,
    .Toastify__progress-bar--success {
      background: ${({ theme }) => theme.colors.backgroundColor};
    }
  }

  .overflow {
    overflow: hidden;
  }

  .show-backdrop {
    #footer-backdrop {
      opacity: 40%;

      pointer-events: initial;
    }

    .navigator {
      z-index: 120;
    }
  }

  .PhoneInputCountry {
    margin-right: 12px;

    .PhoneInputCountryIcon {
      width: 24px;
      height: 17px;
      margin-right: 4px;
      box-shadow: none;
    }

    .PhoneInputCountrySelectArrow {
      transform: rotate(180deg);

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent ${({ theme }) => theme.colors.textColor} transparent;

      opacity: 1;
    }
  }
`;
