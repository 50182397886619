/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css, DefaultTheme } from 'styled-components';

const containerModifiers = {
  isDisabled: (theme: DefaultTheme) => css`
    cursor: not-allowed;
    pointer-events: none;

    p,
    svg,
    span {
      color: ${theme.colors.gray500};
    }
  `
};

export const Container = styled.button<{ isDisabled?: boolean }>`
  ${({ theme }) => css`
    border: none;

    background-color: transparent;

    &:disabled {
      ${containerModifiers.isDisabled(theme)}
    }
  `}
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  > svg {
    margin-right: 8px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    font-size: 1rem;
    font-weight: 700;
  }

  > span {
    font-size: 0.785rem;
  }
`;
