const convertParamsToObject = (url: string) => {
  const arr = url.slice(1).split(/&|=/); // remove the "?", "&" and "="
  const params: Record<string, string> = {};

  for (let i = 0; i < arr.length; i += 2) {
    const key = arr[i];
    const value = arr[i + 1];
    params[key] = value; // build the object = { limit: "10", page:"1", status:"APPROVED" }
  }

  return params;
};

export default convertParamsToObject;
