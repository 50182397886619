import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 100%;
    padding: 1.5rem 1rem;

    display: flex;
    align-items: center;
    flex-direction: column;

    .logo {
      border-radius: 0.75rem;
      margin: 0 1.5rem 1.5rem;

      background-color: ${theme.brandColors.primary};
    }

    h2 {
      padding: 0 1rem;
      margin-bottom: 0.5rem;

      font-weight: 600;
      text-align: center;
      font-size: 1.12rem;
      line-height: 1.69rem;
    }

    > span {
      padding: 0 1rem;
      margin-bottom: 1.5rem;

      font-weight: 500;
      line-height: 150%;
      text-align: center;
      font-size: 0.87rem;
      color: ${theme.colors.gray500};

      &.small {
        font-size: 0.75rem;
        margin-bottom: 1rem;
      }
    }

    .google_token {
      margin-bottom: 1rem;
      border-color: #4285f4 !important;

      background-color: #4285f4;

      span {
        color: #fff !important;

        .loader-icon {
          color: #fff !important;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    @media screen and (min-width: 720px) {
      margin: 0 auto;

      height: 100%;
      max-width: 800px;
    }
  `};
`;

export const LoginButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;
`;
