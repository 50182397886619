/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const StyledModal =
  !!Modal &&
  styled(Modal).attrs((props: ModalProps) => props)`
    ${({ theme }) => css`
      > .container {
        padding: 0;

        > .content {
          background-color: ${theme.colors.gray200};
          > strong {
            display: block;

            font-size: 1rem;
            text-align: left;
            line-height: 130%;
            font-weight: bold;
            color: ${theme.colors.textColor};
          }

          input:focus {
            border-color: ${theme.colors.primary};
          }
        }
      }
    `}
  `;

export const ContainerWrapper = styled.div`
  ${({ theme }) => css`
    height: 100dvh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: ${theme.colors.gray200};
  `}
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.gray200};
  `}
`;

export const FormWrapper = styled.div`
  ${({ theme }) => css`
    padding: 1.5rem 1rem;

    display: flex;
    flex-direction: column;
    background: ${theme.colors.backgroundColor};
  `}
`;

export const GoomerWrapper = styled.div`
  padding: 1.5rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 800px;
`;

export const WavesWrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 800px;

    background: ${theme.colors.gray200};
  `}
`;

export const Field = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.25rem;

      text-align: left;
      font-weight: 600;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.textColor};
    }

    input {
      width: 100%;
    }
  `}
`;
