import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Group, Menu } from '~/interfaces';
import { IApplicationState } from '~/redux-tools/store';
import { getFilteredListByAvailableHours } from '~/utils';

function useMenuGroups(): {
  allGroups: Group[];
  allAvailableGroups: Group[];
  currentMenuAvailableGroups: Group[];
  getGroupByProductId: (productId?: number) => Group | undefined;
} {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { selectedMenu, menus: menuList } = useSelector((state: IApplicationState) => state.menus);

  const { getFilteredMenuListByAvailableHours, getFilteredGroupListByAvailableHours } = getFilteredListByAvailableHours;

  const isAvailableHoursEnabled = useMemo(
    () => !!settings?.mm_feature_schedule_by_group_and_product_enabled,
    [settings?.mm_feature_schedule_by_group_and_product_enabled]
  );

  const allAvailableMenus: Menu[] = useMemo(
    () => getFilteredMenuListByAvailableHours(menuList || [], isAvailableHoursEnabled),
    [menuList, getFilteredMenuListByAvailableHours, isAvailableHoursEnabled]
  );

  const allGroups: Group[] = useMemo(() => {
    let groupList: Group[] = [];

    allAvailableMenus?.map((menu) => {
      if (menu.groups) {
        groupList = groupList.concat(menu.groups);
      }
    });

    return groupList;
  }, [allAvailableMenus]);

  const allAvailableGroups: Group[] = useMemo(
    () => getFilteredGroupListByAvailableHours(allGroups, isAvailableHoursEnabled),
    [allGroups, getFilteredGroupListByAvailableHours, isAvailableHoursEnabled]
  );

  const currentMenuAvailableGroups: Group[] = useMemo(
    () => getFilteredGroupListByAvailableHours(selectedMenu?.groups || [], isAvailableHoursEnabled),
    [getFilteredGroupListByAvailableHours, isAvailableHoursEnabled, selectedMenu?.groups]
  );

  const getGroupByProductId: (productId?: number) => Group | undefined = useCallback(
    (productId) =>
      productId ? allGroups.find((group) => !!group.products.find((product) => product.id === productId)) : undefined,
    [allGroups]
  );

  return { allGroups, allAvailableGroups, getGroupByProductId, currentMenuAvailableGroups };
}

export default useMenuGroups;
