import { IOpenSign, IEstablishmentSettings, EstablishmentLoyaltyProgram, ICoupon, Chatbot } from '~/interfaces/general';

export interface IEstablishmentState {
  readonly error: string;
  readonly open: IOpenSign;
  readonly loading: boolean;
  readonly chatbot?: Chatbot;
  readonly isViewMode: boolean;
  readonly coupons?: ICoupon[];
  readonly isMPTokenValid: boolean;
  readonly settings?: IEstablishmentSettings;
  readonly loyaltyProgram?: EstablishmentLoyaltyProgram;
}

export const EstablishmentActionTypes = {
  SET_COUPONS: '@@establishment/SET_COUPONS',
  SET_CHATBOT: '@@establishment/SET_CHATBOT',
  FETCH_INFOS: '@@establishment/FETCH_INFOS',
  SET_SETTINGS: '@@establishment/SET_SETTINGS',
  SET_VIEW_MODE: '@@establishment/SET_VIEW_MODE',
  SET_OPEN_SIGN: '@@establishment/SET_OPEN_SIGN',
  SET_LOYALT_PROGRAM: '@@establishment/SET_LOYALT_PROGRAM',
  SET_IS_MP_TOKEN_VALID: '@@establishment/SET_IS_MP_TOKEN_VALID',
  REMOVE_MP_PAYMENT_METHODS: '@@establishment/REMOVE_MP_PAYMENT_METHODS',
  FETCH_ESTABLISHMENT_ERROR: '@@establishment/FETCH_ESTABLISHMENT_ERROR',
  FETCH_ESTABLISHMENT_REQUEST: '@@establishment/FETCH_ESTABLISHMENT_REQUEST'
};
