import { DeliveryWay, ICoupon } from '~/interfaces/general';

export interface ICouponsStateData {
  selectedCoupon?: ICoupon;
}

export interface ICouponsState {
  readonly data: ICouponsStateData;
  readonly loading: boolean;
  readonly error?: string;
  readonly origin: DeliveryWay;
  readonly existCoupons: boolean;
}

export const CouponsActionTypes = {
  FETCH_COUPON_REQUEST: '@@coupons/FETCH_COUPON_REQUEST',
  FETCH_COUPON_SUCCESS: '@@coupons/FETCH_COUPON_SUCCESS',
  FETCH_COUPON_ERROR: '@@coupons/FETCH_COUPON_ERROR',
  FETCH_EXISTENT_COUPONS_REQUEST: '@@coupons/FETCH_EXISTENT_COUPONS_REQUEST',
  FETCH_EXISTENT_COUPONS_SUCCESS: '@@coupons/FETCH_EXISTENT_COUPONS_SUCCESS',
  FETCH_EXISTENT_COUPONS_ERROR: '@@coupons/FETCH_EXISTENT_COUPONS_ERROR',
  VALIDATE_COUPON_REQUEST: '@@coupons/VALIDATE_COUPON_REQUEST',
  VALIDATE_COUPON_ERROR: '@@coupons/VALIDATE_COUPON_ERROR',
  COUPON_ERROR_UPDATE: '@@coupons/COUPON_ERROR_UPDATE',
  SET_ORIGIN: '@@coupons/SET_ORIGIN',
  CLEAN_COUPON: '@@coupons/CLEAN_COUPON',
  CLEAN_SELECTED_COUPON: '@@coupons/CLEAN_SELECTED_COUPON'
};
