import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';
import { removeCardTokens } from '~/redux-tools/store/user/actions';
import { cleanNupayOrder } from '~/redux-tools/store/nupay/actions';
import { cleanAllPix } from '~/redux-tools/store/pixPayment/actions';
import MercadoPagoInit, { IMercadoPagoSDK } from '~/utils/mercadoPago';
import useCountdownDateComparison from '~/hooks/useCountdownDateComparison';
import { setIsMPTokenValid } from '~/redux-tools/store/establishment/actions';

const MP_GOOMER_TOKEN = process.env.NEXT_PUBLIC_MP_TOKEN;

function useMenuPayment(): void {
  const history = useRouter();
  const dispatch = useDispatch();

  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { timeToExpireNupayOrder } = useSelector((state: IApplicationState) => state.nupay);
  const { orderTime: timeToExpirePixOrder } = useSelector((state: IApplicationState) => state.pixPayment);

  const { countdownExpired: isPixCountdownExpired } = useCountdownDateComparison(timeToExpirePixOrder);
  const { countdownExpired: isNupayCountdownExpired } = useCountdownDateComparison(timeToExpireNupayOrder);

  const [MPState, setMPState] = useState<IMercadoPagoSDK | null>(null);

  useEffect(() => {
    const isIntegratedPixTimerExpired = timeToExpirePixOrder !== undefined && isPixCountdownExpired;

    if (isIntegratedPixTimerExpired) {
      dispatch(cleanAllPix());
    }

    const shouldRedirectToPayment =
      timeToExpirePixOrder !== undefined &&
      !isPixCountdownExpired &&
      settings?.mm_order_status_check_enabled !== undefined &&
      !settings?.mm_order_status_check_enabled;

    if (shouldRedirectToPayment) {
      history.push('/payment');
    }
  }, [dispatch, history, timeToExpirePixOrder, settings?.mm_order_status_check_enabled, isPixCountdownExpired]);

  useEffect(() => {
    const isNupayTimerExpired = timeToExpireNupayOrder !== undefined && isNupayCountdownExpired;

    if (isNupayTimerExpired) {
      dispatch(cleanNupayOrder());
    }

    const shouldRedirectToNupayPayment =
      timeToExpireNupayOrder !== undefined &&
      !isNupayCountdownExpired &&
      settings?.mm_order_status_check_enabled !== undefined &&
      !settings?.mm_order_status_check_enabled;

    if (shouldRedirectToNupayPayment) {
      history.push('/nupay');
    }
  }, [
    history,
    dispatch,
    timeToExpirePixOrder,
    isPixCountdownExpired,
    timeToExpireNupayOrder,
    isNupayCountdownExpired,
    settings?.mm_order_status_check_enabled
  ]);

  useEffect(() => {
    dispatch(removeCardTokens());
  }, [dispatch]);

  useEffect(() => {
    if (!!MPState && MPState !== null) {
      dispatch(setIsMPTokenValid(!!MPState.tokenId));
    }
  }, [MPState, dispatch]);

  useEffect(() => {
    if (MP_GOOMER_TOKEN) {
      const mp = MercadoPagoInit(MP_GOOMER_TOKEN);

      setMPState(mp);
    }
  }, [settings]);
}

export default useMenuPayment;
