import { store } from '~/redux-tools';

import { trackError } from './utils/trackError';
import { gaEvents, gaErrors, StoreGaEventsEnum } from './utils/events';

const GA_GOOMER_ID = process.env.NEXT_PUBLIC_ANALYTICS_ID;
const gtag = typeof window !== 'undefined' ? (window as any).gtag : '';

const setup: (params: { storeGoogleAnalyticsId?: string }) => void = ({ storeGoogleAnalyticsId }) => {
  const params = {
    cookie_flags: 'secure;samesite=none',
    custom_map: {
      dimension6: 'cpf',
      dimension7: 'items',
      dimension1: 'total_value',
      dimension5: 'payment_type',
      dimension4: 'delivery_way',
      dimension2: 'establishment',
      dimension3: 'establishment_id'
    }
  };

  if (gtag) {
    gtag('js', new Date());

    gtag('config', GA_GOOMER_ID, params);

    if (storeGoogleAnalyticsId) {
      gtag('config', storeGoogleAnalyticsId, { send_page_view: false });
    }
  }
};

const goomerGaEventList: string[] = Object.values(gaEvents);
const storeGaEventList: string[] = Object.values(StoreGaEventsEnum);

const isGoomerGaEvent: (trackerName: gaErrors | gaEvents | StoreGaEventsEnum) => boolean = (trackerName) =>
  !!goomerGaEventList.find((goomerGaEvent) => goomerGaEvent === trackerName);

const isStoreGaEvent: (trackerName: gaErrors | gaEvents | StoreGaEventsEnum) => boolean = (trackerName) =>
  !!storeGaEventList.find((storeGaEvent) => storeGaEvent === trackerName);

const trackEvent: (trackerName: gaErrors | gaEvents | StoreGaEventsEnum, params?: object) => void = (
  trackerName,
  params
) => {
  if (gtag) {
    if (isGoomerGaEvent(trackerName)) {
      gtag('event', trackerName, {
        ...params,
        send_to: GA_GOOMER_ID,
        establishment_id: store.getState().establishment.settings?.id
      });
    }

    if (isStoreGaEvent(trackerName) && !!store.getState().establishment.settings?.mm_google_analytics_enabled) {
      gtag('event', trackerName, {
        ...params,
        establishment_id: undefined,
        send_to: String(store.getState().establishment.settings?.mm_google_analytics_id)
      });
    }
  }
};

const GoogleAnalytics = {
  setup,
  trackEvent,
  trackError
};

export default GoogleAnalytics;
export { gaEvents, gaErrors };
