import React from 'react';

import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { Modal, AddressList } from '~/components';
import { IApplicationState } from '~/redux-tools/store';

import * as S from './styles';

interface AddressModalProps {
  title?: string;
  isModalVisible: boolean;
  isGoomerColor?: boolean;
  closeButtonText?: string;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

const AddressModal = ({
  title,
  isModalVisible,
  closeButtonText,
  setIsModalVisible,
  isGoomerColor = false
}: AddressModalProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  return (
    <S.Content>
      <Modal
        isPageLike
        hideCloseButton
        isWithoutPadding
        isContentFullPage
        isShow={isModalVisible}
        onClose={(): void => setIsModalVisible(false)}
        titleBackgroundColor={theme.colors.backgroundColor}
      >
        {isModalVisible && (
          <AddressList
            isGoomerColor={isGoomerColor}
            closeButtonText={closeButtonText}
            onClose={(): void => setIsModalVisible(false)}
            title={title || getTranslation('address.deliveryAddress')}
          />
        )}
      </Modal>
    </S.Content>
  );
};

export default AddressModal;
