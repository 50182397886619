import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showToast } from '~/utils';
import { Address } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { AddressContext } from '~/components/Address/AddressList';
import { removeUserAddressOnCart } from '~/redux-tools/store/cart/actions';
import useUserAuthenticationStatus from '~/hooks/useUserAuthenticationStatus';
import { deleteCustomerAddressRequest } from '~/redux-tools/store/user/actions';

export default function useAddressHandleDelete() {
  const dispatch = useDispatch();

  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const { selectedAddress, setSelectedAddress } = useContext(AddressContext);

  const handleDelete: (address: Address) => void = useCallback(
    (address) => {
      const addErrorLog = () => {
        showToast({ text: 'Ocorreu um erro ao remover endereço. Tente novamente!', type: 'error', closeOnClick: true });
      };

      const afterAction = () => {
        showToast({ text: 'Endereço removido com sucesso!', type: 'success', closeOnClick: true });
      };

      setTimeout(() => {
        if (address.id === selectedAddress?.id) {
          setSelectedAddress(undefined);
          dispatch(removeUserAddressOnCart());
        }

        if (address.id) {
          dispatch(
            deleteCustomerAddressRequest({
              storeId: settings?.id || 0,
              isUserAuthenticated,
              addressId: address.id,
              functions: {
                addErrorLog,
                afterAction
              }
            })
          );
        }
      }, 500);
    },
    [dispatch, isUserAuthenticated, selectedAddress?.id, setSelectedAddress, settings?.id]
  );

  return { handleDelete };
}
