import { ICoordinates } from '~/interfaces/general';

export interface IGeolocationState {
  readonly coordinates: ICoordinates;
  readonly isGoogleMapsApiLoaded: boolean;
}

export const GeolocationActionTypes = {
  ADD_GEOLOCATION: '@@geolocation/ADD_GEOLOCATION',
  SET_IS_GOOGLE_MAPS_API_LOADED: '@@geolocation/SET_IS_GOOGLE_MAPS_API_LOADED'
};
