import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    border-radius: 0.25rem;
    padding: 1.25rem 0.75rem;
    border: 1px dashed ${theme.colors.gray300};

    text-align: center;
    background: ${theme.colors.backgroundColor};
  `}
`;

export const Icon = styled.div<{ $color: string }>`
  ${({ $color }) => css`
    margin: 0 auto;
    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${$color};
  `};
`;

export const Title = styled.p`
  ${({ theme }) => css`
    margin: 0.5rem 0 1rem;

    font-weight: 500;
    line-height: 140%;
    text-align: center;
    font-size: 0.875rem;
    color: ${theme.colors.textColor};

    > strong {
      font-weight: 800;
      font-size: 0.875rem;
    }
  `};
`;
