import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';

const apiEndpoint = process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL;

export const api = axios.create({
  baseURL: apiEndpoint,
  timeout: 10000
});

export default makeUseAxios({
  axios: axios.create({
    baseURL: apiEndpoint,
    timeout: 10000
  })
});
