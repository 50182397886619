export interface HandleScriptProps {
  url: string;
  isScriptLoaded?: boolean;
}

const handleScript = ({ url, isScriptLoaded }: HandleScriptProps) => {
  if (isScriptLoaded) {
    return;
  }

  const script = document.createElement('script');

  script.src = url;
  script.async = true;

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};

export default handleScript;
