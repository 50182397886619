import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { api } from '~/services/api';
import { IApplicationState } from '~/redux-tools/store';

export interface UseUserAuthenticationStatusProps {
  isUserAuthenticated: boolean;
}

export default function useUserAuthenticationStatus(): UseUserAuthenticationStatusProps {
  const { isAuthenticated, authenticationToken } = useSelector((state: IApplicationState) => state.user.data);

  const hasAuthorizationHeader =
    !!api.defaults.headers.Authorization &&
    api.defaults.headers.Authorization !== 'undefined' &&
    api.defaults.headers.Authorization !== '';

  const isUserAuthenticated: boolean = useMemo(
    () => !!isAuthenticated && !!authenticationToken && !!hasAuthorizationHeader,
    [authenticationToken, hasAuthorizationHeader, isAuthenticated]
  );

  return {
    isUserAuthenticated
  };
}
