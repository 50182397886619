import { useEffect, useState } from 'react';

import { LocalStorageModalEnum } from '~/interfaces/enums';

type InstallPromptProps = Event & {
  prompt: () => void;
};

interface UsePwaInstallPromptProps {
  onFinish?: () => void;
}

export default function usePwaInstallPrompt({ onFinish = () => {} }: UsePwaInstallPromptProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [pwaInstallPrompt, setPwaInstallPrompt] = useState<InstallPromptProps | undefined>(undefined);

  useEffect(() => {
    const addInstallPwaEvent = (event: Event) => {
      event?.preventDefault();

      setPwaInstallPrompt(event as InstallPromptProps);
    };

    const afterInstallPwaEvent = () => {
      if (localStorage) {
        localStorage.setItem(LocalStorageModalEnum.pwaBanner, 'viewed');
      }

      onFinish();
    };

    if (window) {
      window.addEventListener('beforeinstallprompt', addInstallPwaEvent);

      window.addEventListener('appinstalled', afterInstallPwaEvent);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', addInstallPwaEvent);
      window.removeEventListener('appinstalled', afterInstallPwaEvent);
    };
  }, [onFinish]);

  return { pwaInstallPrompt, isLoading };
}
