import styled, { css } from 'styled-components';

export const ProgressBarWrapper = styled.section<{ hasSeparation?: boolean }>`
  ${({ theme, hasSeparation }) => css`
    width: 100%;
    padding: 12px 0 0 0;

    display: flex;
    flex-direction: column;

    ${hasSeparation &&
    css`
      margin-top: 16px;
      padding-top: 16px;
      border-top: 1px solid ${theme.colors.gray300};
    `}
  `}
`;

export const ProgressBarHeaderWrapper = styled.div`
  margin-bottom: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  li {
    margin: 0;
    padding: 0;

    p + p {
      margin-left: 4px;
    }
  }
`;
