export default function cardDueValidate(value: string): boolean {
  if (value.length < 4) {
    return false;
  }

  const [dueMonth, dueYear] = value.split('/').map((x) => parseInt(x));
  if (dueMonth <= 0 || dueMonth > 12) {
    return false;
  }

  const today = new Date();

  const currentMonth = today.getMonth() + 1;

  const fullCurrentYear = today.getFullYear().toString();
  const currentYear = Number(fullCurrentYear.substring(fullCurrentYear.length - 2));

  if (dueYear < currentYear) {
    return false;
  }

  if (dueYear === currentYear) {
    if (dueMonth < currentMonth) {
      return false;
    }
  }

  return true;
}
