import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

import { UseLoyaltyProgramHooksProps } from '..';

import useLoyaltyAccess from './useLoyaltyAccess';
import useLoyaltyStatus from './useLoyaltyStatus';
import useLoyaltySelectedChannels from './useLoyaltySelectedChannels';

export type UseLoyaltyDisplayMenuInfoProps = Pick<UseLoyaltyProgramHooksProps, 'shouldDisplayLoyaltyProgramMenuInfo'>;

export default function useLoyaltyDisplayMenuInfo(): UseLoyaltyDisplayMenuInfoProps {
  const { customerLoyaltyProgram } = useSelector((state: IApplicationState) => state.user.data);

  const { hasLoyaltyProgramAccess } = useLoyaltyAccess();
  const { isLoyaltyProgramEnabled } = useLoyaltyStatus();
  const { isCurrentChannelSelected } = useLoyaltySelectedChannels();

  const shouldDisplayLoyaltyProgramMenuInfo: boolean = useMemo(() => {
    const customerIsAlreadyParticipating = !!customerLoyaltyProgram;

    return (
      isCurrentChannelSelected && hasLoyaltyProgramAccess && (isLoyaltyProgramEnabled || customerIsAlreadyParticipating)
    );
  }, [customerLoyaltyProgram, hasLoyaltyProgramAccess, isCurrentChannelSelected, isLoyaltyProgramEnabled]);

  return { shouldDisplayLoyaltyProgramMenuInfo };
}
