import React from 'react';

import { phoneMask } from '~/utils';
import { useTranslator } from '~/hooks';
import { IUser } from '~/interfaces/general';

import * as S from './styles';

export interface OrderInfoProps {
  user: IUser;
}

const OrderInfo = ({ user }: OrderInfoProps) => {
  const { getTranslation } = useTranslator();

  return (
    <S.Container>
      <S.InfoWrapper>
        <S.BoldText>{user?.name}</S.BoldText>
      </S.InfoWrapper>

      <S.InfoWrapper>
        {!!user?.phone && <S.RegularText>{phoneMask(user?.phone)}</S.RegularText>}

        {!!user.cpf && (
          <S.RegularText className="sentry-mask">
            {getTranslation('general.documentInOrder', {
              document: user.cpf.length > 14 ? getTranslation('general.cnpj') : getTranslation('general.cpf')
            })}{' '}
            {user.cpf}
          </S.RegularText>
        )}
      </S.InfoWrapper>
    </S.Container>
  );
};

export default OrderInfo;
