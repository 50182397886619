import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setIsGoogleMapsApiLoaded } from '~/redux-tools/store/geolocation/actions';

const useLoadGoogleMapsApi = (apiKey: string, libraries: string): boolean => {
  const dispatch = useDispatch();

  const [isApiLoaded, setIsApiLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries}`;
    script.async = true;
    script.onload = () => {
      setIsApiLoaded(true);
      dispatch(setIsGoogleMapsApiLoaded(true));
    };

    script.onerror = () => console.error('Erro ao carregar a API do Google Maps');

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [apiKey, dispatch, libraries]);

  return isApiLoaded;
};

export default useLoadGoogleMapsApi;
