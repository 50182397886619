import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setHeaderAndCookieAuthToken } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import { cleanUserInfo } from '~/redux-tools/store/user/actions';
import { LoginOptionEnum, TunaSessionStorageEnum } from '~/interfaces/enums';

import useAuth from '../useAuth';

export default function useLogout() {
  const dispatch = useDispatch();
  const { firebaseSignout } = useAuth();

  const user = useSelector((state: IApplicationState) => state.user.data);

  const handleLogout = useCallback(() => {
    if (user.loginOptionType !== LoginOptionEnum.email) {
      firebaseSignout();
    }

    setHeaderAndCookieAuthToken({ token: undefined });
    sessionStorage.removeItem(TunaSessionStorageEnum.TokenizatorSessionData);
    dispatch(cleanUserInfo());
  }, [dispatch, firebaseSignout, user.loginOptionType]);

  return { handleLogout };
}
