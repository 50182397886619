import React, { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import OptionHeader from '~/components/Option/OptionHeader';

import { ApplyCodeModal, Coupon } from './components';

import * as S from './styles';

const CheckoutCoupon = (): JSX.Element => {
  const { data, error } = useSelector((state: IApplicationState) => state.coupons);
  const loyaltyCoupon = useSelector((state: IApplicationState) => state.cart.values.loyalty);

  const [shouldDisplayApplyCodeModal, setShouldDisplayPaymentOptionModal] = useState<boolean>(false);

  const { getTranslation } = useTranslator();

  const isCouponApplied = useMemo(() => {
    return !!data.selectedCoupon && !error;
  }, [data.selectedCoupon, error]);

  return (
    <S.Container>
      <OptionHeader
        showDone
        subtitle={false}
        isRequired={false}
        isDone={isCouponApplied}
        title={getTranslation('general.coupon')}
      />

      <div className="content">
        <Coupon isLoyaltyCoupon={!!loyaltyCoupon} onClick={(): void => setShouldDisplayPaymentOptionModal(true)} />
      </div>

      <ApplyCodeModal
        isShow={shouldDisplayApplyCodeModal}
        onClose={(): void => setShouldDisplayPaymentOptionModal(false)}
      />
    </S.Container>
  );
};

export default CheckoutCoupon;
