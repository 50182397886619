import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 1.875rem;
`;

export const OptionList = styled.div`
  padding: 0.5rem 1rem 0;

  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const OptionWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 1rem;
    border-radius: 0.25rem;
    border: 1px solid ${theme.colors.gray300};

    background-color: ${theme.colors.backgroundColor};

    > div {
      border: none;

      .mark {
        background: transparent;
        border: 1px solid ${theme.colors.gray300};
      }
    }
  `}
`;

export const DiscountTag = styled.div<{ $isSuccess: boolean }>`
  ${({ theme, $isSuccess }) => css`
    margin: 0;
    padding: 0.25rem;
    border-radius: 0.25rem;

    font-weight: 700;
    line-height: 150%;
    text-align: center;
    font-size: 0.75rem;
    white-space: nowrap;
    color: ${theme.colors.backgroundColor};
    background-color: ${theme.colors.mediumGray};

    ${$isSuccess &&
    css`
      background-color: ${theme.colors.success};
    `}
  `}
`;
