import styled from 'styled-components';

export const ModalWrapper = styled.div`
  margin: 1rem;
`;

export const Title = styled.p`
  margin-top: 0.5rem;

  font-size: 1.125rem;
  font-weight: 800;
  line-height: 130%;
`;

export const Subtitle = styled.p`
  margin-top: 0.5rem;

  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const AnimationWrapper = styled.div`
  display: flex;
  justify-content: center;

  .error-animation {
    width: 7.5rem;
    height: 7.5rem;
  }

  .success-animation {
    width: 7.5rem;
    height: 7.5rem;
  }

  .info-animation {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 1.5rem 0 0;

  display: flex;
  flex-direction: column;

  > button {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
`;
