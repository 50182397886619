import { action } from 'typesafe-actions';

import { OrderStatusActionTypes } from './types';

export const addOrder = (order: any) => {
  return action(OrderStatusActionTypes.ADD_ORDER, order);
};

export const cleanOrder = () => {
  return action(OrderStatusActionTypes.CLEAN_ORDER);
};

export const finishOrder = () => {
  return action(OrderStatusActionTypes.FINISH_ORDER);
};
