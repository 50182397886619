/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  isBold: boolean;
  isForm: boolean;
  noPadding?: boolean;
  isRequiredShown: boolean;
}>`
  ${({ theme, isBold, isForm, noPadding, isRequiredShown }) => css`
    width: 100%;
    min-height: 1.625rem;
    padding: ${noPadding ? 0 : '0 1rem'};

    display: flex;
    align-items: center;
    justify-content: space-between;

    &.-warn {
      .status {
        > span {
          background: ${theme.colors.error};
          color: ${theme.isDarkMode ? theme.colors.textColor : theme.colors.backgroundColor};
        }
      }
    }

    > .info {
      max-width: ${isRequiredShown ? 70 : 100}%;

      .title {
        display: block;

        line-height: 150%;
        font-size: 1.12rem;
        color: ${theme.colors.textColor};
        font-weight: ${isBold ? 800 : 600};
      }

      .tip {
        margin-top: 0.125rem;

        font-weight: 500;
        line-height: 150%;
        font-size: 0.75rem;
        color: ${theme.colors.mediumGray};

        @media screen and (min-width: 720px) {
          font-size: 0.875rem;
        }
      }

      .tip-order {
        margin-top: 0.25rem;

        line-height: 120%;
        font-size: 0.875rem;

        @media screen and (min-width: 360px) {
          position: absolute;
          top: 50%;
          right: 1.25rem;
          transform: translateY(-50%);

          margin-top: 0;
        }
      }
    }

    > .status {
      position: relative;
    }

    ${isForm &&
    css`
      padding: 0;

      > .info {
        .title {
          font-weight: 600;
          line-height: 150%;
          font-size: 0.875rem;
          color: ${theme.colors.textColor};
        }
      }
    `}
  `}
`;

export const Required = styled.span<{ isShow: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  margin-right: 0;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;

  opacity: 1;
  transition: 0.3s;
  font-weight: 600;
  font-size: 0.75rem;
  text-align: center;
  visibility: visible;
  color: ${({ theme }) => (theme.isDarkMode ? theme.colors.textColor : theme.colors.backgroundColor)};
  background: ${({ theme }) => (theme.isDarkMode ? rgba(theme.colors.mediumGray, 0.5) : theme.colors.mediumGray)};

  ${({ isShow }) =>
    !isShow &&
    css`
      right: -1.25rem;

      opacity: 0;
      visibility: hidden;
    `}
`;

export const Done = styled.div<{ isDone: boolean }>`
  width: 1.375rem;
  margin-right: 0;
  height: 1.375rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  transition: 0.3s;
  visibility: visible;
  background: ${({ theme }) => theme.colors.success};

  svg {
    width: 0.875rem;
    height: 0.625rem;
  }

  ${({ isDone }) =>
    !isDone &&
    css`
      margin-right: -1rem;

      opacity: 0;
      visibility: hidden;
    `}
`;
