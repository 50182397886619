import React, { useEffect, useMemo, useRef } from 'react';

import { useGetDayText } from '~/hooks';

import * as S from './styles';

export interface RadioDayProps {
  id: string;
  name: string;
  value: string;
  label: string;
  index?: number;
  checked?: boolean;
  day: number | string;
  setChecked?: (value: Date, index?: number) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const RadioDay = ({ id, day, name, index, label, value, checked, setChecked, ...rest }: RadioDayProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { formatDayText } = useGetDayText();

  const labelText = useMemo(
    () => formatDayText({ isAbbreviated: true, withTodayLabel: true, date: value ? new Date(value) : new Date() }),
    [formatDayText, value]
  );

  useEffect(() => {
    if (inputRef.current && !!checked) {
      inputRef.current.click();
    }
  }, [checked]);

  return (
    <S.RadioDayContainer htmlFor={id}>
      <input
        id={id}
        name={name}
        type="radio"
        value={value}
        ref={inputRef}
        className={label}
        defaultChecked={checked}
        {...rest}
        onChange={(event) => setChecked?.(new Date(event.target.value), index || 0)}
      />

      <div className="border" title={`radio ${label} wrapper`} />

      <S.Label>{labelText}</S.Label>

      <S.Day>{day}</S.Day>
    </S.RadioDayContainer>
  );
};

export interface RadioGroupProps {
  data: RadioDayProps[];
  setChecked: (value: Date, index?: number) => void;
}

const RadioGroup = ({ data, setChecked }: RadioGroupProps): JSX.Element => {
  return (
    <S.RadioGroupContainer data-test="scheduling-day-list" role="radiogroup">
      {data.map(({ id, ...rest }, index) => (
        <RadioDay key={id} id={id} {...rest} index={index} checked={index === 0} setChecked={setChecked} />
      ))}
    </S.RadioGroupContainer>
  );
};

export default RadioGroup;
