import { scrollToElement } from '~/utils';

interface IProps {
  selector: string;
  warningClass: string;
}

export default function requiredWarnScroll({ selector, warningClass }: IProps) {
  const firstRequiredElement: Element | null | undefined =
    typeof document !== 'undefined' ? document.querySelector(`${selector}${'[data-required="true"]'}`) : undefined;

  if (firstRequiredElement) {
    scrollToElement({ element: firstRequiredElement as HTMLElement });

    firstRequiredElement.classList.add(warningClass);
  }
}
