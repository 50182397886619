import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { addMinutes, format } from 'date-fns';
import { Scheduling as SchedulingEnum, SchedulingAvailableHours } from '@goomerdev/goomer-toolbox/src/enums';

import { Button } from '~/components';
import { useTranslator } from '~/hooks';
import { scrollToElement } from '~/utils';
import { DeliveryWay } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { getHoursAndMinutesFromLabel } from '~/components/Checkout/CheckoutScheduling/utils';
import { RadioDayProps } from '~/components/Checkout/CheckoutScheduling/components/RadioDayList';
import { RadioDayList, RadioHourList } from '~/components/Checkout/CheckoutScheduling/components';

import { RadioHourProps } from '../RadioHourList';

import * as S from './styles';

export interface SchedulingProps {
  isShow: boolean;
  closeScheduling: () => void;
  setSchedule: (value: Date) => void;
}

const Scheduling = ({ isShow, setSchedule, closeScheduling }: SchedulingProps): JSX.Element => {
  const origin = useSelector((state: IApplicationState) => state.coupons.origin);
  const { selectedList: schedulingDataList } = useSelector((state: IApplicationState) => state.scheduling);

  const [dayList, setDayList] = useState<RadioDayProps[]>([]);
  const [storedOrigin, setStoredOrigin] = useState<DeliveryWay>(origin);
  const [currentValues, setCurrentValues] = useState<Date | undefined>(undefined);
  const [hourList, setHoursList] = useState<{ date: string; hourList: RadioHourProps[] }[]>([]);
  const [storedSchedulingDataList, setStoredSchedulingDataList] = useState<SchedulingEnum[]>([]);

  const { getTranslation } = useTranslator();

  useEffect(() => {
    if (origin !== storedOrigin) {
      setDayList([]);
      setCurrentValues(undefined);

      setStoredOrigin(origin);
    }
  }, [origin, storedOrigin]);

  useEffect(() => {
    if (!currentValues && dayList.length > 0 && hourList.length > 0) {
      const hourAndMinutes = getHoursAndMinutesFromLabel(hourList[0].hourList[0].value);

      if (hourAndMinutes) {
        const { hour, minutes } = hourAndMinutes;
        setCurrentValues(new Date(new Date(dayList[0].value).setHours(hour, minutes)));
      }
    }
  }, [currentValues, dayList, hourList]);

  const confirmSchedule = useCallback(() => {
    if (currentValues) {
      setSchedule(currentValues);
    }
  }, [currentValues, setSchedule]);

  useEffect(() => {
    if (schedulingDataList) {
      const dayPropsList = schedulingDataList.map((scheduling: SchedulingEnum) => {
        const { date, label } = scheduling;
        const day = format(date, 'd').padStart(2, '0');
        const month = format(date, 'M').padStart(2, '0');

        return {
          label,
          name: 'day',
          value: String(date),
          day: `${day}/${month}`,
          id: `day-${day}-${month}`
        };
      });

      setDayList(dayPropsList);
    }
  }, [dayList.length, schedulingDataList]);

  useEffect(() => {
    if (JSON.stringify(storedSchedulingDataList) === JSON.stringify(schedulingDataList)) {
      return;
    }

    if (schedulingDataList) {
      const hourDataList = schedulingDataList.map((scheduling) => {
        const { date, availableHours: availableHourList } = scheduling;
        const day = format(date, 'd');
        const month = format(date, 'M');

        const availableHoursFormattedList = availableHourList?.map((hour: SchedulingAvailableHours) => ({
          name: 'hour',
          value: hour.label,
          id: `day-${day}-${month}-hour-${hour.label}`,
          label: `${hour.label} ${getTranslation('scheduling.to')} ${format(
            addMinutes(new Date().setHours(hour.info.hour, hour.info.minutes), 30),
            'HH:mm'
          )}`
        }));

        return {
          date: `${day}/${month}`,
          hourList: availableHoursFormattedList || []
        };
      });

      if (hourDataList.length > 0) {
        setHoursList(hourDataList);
      }

      setStoredSchedulingDataList(schedulingDataList);
    }
  }, [getTranslation, hourList.length, schedulingDataList, storedSchedulingDataList]);

  const currentDate = useMemo(() => {
    if (currentValues) {
      return format(new Date(currentValues), 'd/M');
    }

    return format(new Date(), 'd/M');
  }, [currentValues]);

  const selectedDayHourList = useMemo(() => {
    if (hourList.length > 0) {
      const dayHourList = hourList.find((list) => list.date === currentDate);

      if (!dayHourList) return hourList[0].hourList;

      return dayHourList.hourList;
    }

    return [];
  }, [currentDate, hourList]);

  const handleChangeDay = useCallback(
    (value: Date, index?: number) => {
      if (selectedDayHourList.length === 0) return setCurrentValues(value);

      const hourAndMinutes = getHoursAndMinutesFromLabel(hourList[index || 0].hourList[0].value);

      if (hourAndMinutes) {
        const { hour, minutes } = hourAndMinutes;

        return setCurrentValues(new Date(value.setHours(hour, minutes)));
      }

      return setCurrentValues(value);
    },
    [hourList, selectedDayHourList.length]
  );

  const handleConfirm = useCallback(() => {
    confirmSchedule();
    closeScheduling();

    setTimeout(() => {
      scrollToElement({ isSmooth: false, element: '[data-name="scheduling-header"]' });
    }, 0);
  }, [closeScheduling, confirmSchedule]);

  if (!currentValues) return <></>;

  return (
    <S.Container isPageLike isShow={isShow} isContentVisible onClose={closeScheduling} headerTitle="Escolha um horário">
      <RadioDayList data={dayList} setChecked={handleChangeDay} />

      <RadioHourList
        data={selectedDayHourList}
        setChecked={({ hour, minutes }: { hour: number; minutes: number }): void => {
          setCurrentValues((currentDaySelected) => {
            return currentDaySelected ? new Date(currentDaySelected.setHours(hour, minutes)) : undefined;
          });
        }}
      />

      <S.Bottom>
        <span className="info">{`* ${getTranslation('scheduling.approximateTime')}`}</span>

        <Button data-test="btn-confirm-scheduling" isGhost={false} onClick={handleConfirm}>
          {getTranslation('scheduling.confirmTime')}
        </Button>
      </S.Bottom>
    </S.Container>
  );
};

export default Scheduling;
