import { lighten } from 'polished';
import LoaderSpinner from 'react-loader-spinner';
import styled, { css, DefaultTheme } from 'styled-components';

interface TextProps {
  color?: string;
  asLink?: boolean;
  isError?: boolean;
  size?: 'medium' | 'small';
  hasMarginLeftAuto?: boolean;
}

const textModifier = {
  small: (theme: DefaultTheme) => css`
    line-height: 150%;
    font-size: 0.875rem;

    strong {
      font-size: 12px;
      color: ${theme.colors.gray600};
    }
  `,
  medium: (theme: DefaultTheme) => css`
    font-size: 1rem;

    strong {
      font-size: 1rem;
      color: ${theme.colors.gray600};
    }
  `,
  asLink: (theme: DefaultTheme) => css`
    font-weight: 600;
    line-height: 150%;
    font-size: 0.875rem;
    text-decoration: underline;
    color: ${theme.brandColors.primary};
  `,
  isError: (theme: DefaultTheme) => css`
    text-align: left;
    font-weight: 500;
    line-height: 150%;
    font-size: 0.75rem;
    color: ${theme.colors.error};
  `
};

export const Title = styled.h1`
  width: 100%;
  margin: 2rem 0 0.5rem;

  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
`;

export const WavesWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
`;

export const Container = styled.section`
  ${({ theme }) => css`
    padding: 1.5rem 1rem;

    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: ${theme.colors.backgroundColor};

    > button {
      margin-top: 8px;
    }
  `}
`;

export const OrderInfoWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
`;

export const TextWithMarginWrapper = styled.div`
  width: 100%;
  margin-top: 16px;

  > p {
    margin: 0;
  }

  a {
    cursor: pointer;
  }
`;

export const RegularText = styled.p<TextProps>`
  ${({ color, theme, asLink, isError, size = 'small' }) => css`
    width: 100%;

    line-height: 150%;
    text-align: center;
    text-decoration: none;
    color: ${color || theme.colors.gray500};

    ${textModifier[size](theme)};
    ${asLink && textModifier.asLink(theme)};
    ${isError && textModifier.isError(theme)};
  `}
`;

export const StrongText = styled.strong<TextProps>`
  ${({ theme, color, size = 'medium' }) => css`
    font-weight: 700;
    color: ${color || theme.colors.gray500};

    ${textModifier[size](theme)};
  `}
`;

export const PixCodeWrapper = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled = false }) => css`
    width: 100%;
    padding: 0.5rem;
    margin-top: 1.5rem;
    border-radius: 0.25rem;
    border: 1px dashed ${theme.colors.gray400};

    background-color: ${disabled ? theme.colors.gray300 : 'transparent'};
  `}
`;

export const PixCode = styled.textarea<{ isCancelled?: boolean }>`
  ${({ theme, isCancelled }) => css`
    width: 100%;
    border: none;
    resize: none;
    overflow: hidden;

    display: -webkit-box;

    font-size: 16px;
    line-height: 24px;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    color: ${theme.colors.gray600};
    background-color: ${theme.colors.backgroundColor};

    ${isCancelled &&
    css`
      color: ${theme.colors.gray500};
      background-color: ${theme.colors.gray300};
    `}
  `}
`;

export const QRCodeWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 1rem;

    text-align: center;
    background-color: ${theme.colors.backgroundColor};
  `}
`;

export const Countdown = styled.div`
  ${({ theme }) => css`
    padding: 0.75rem;

    gap: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: ${theme.colors.gray500};
      }
    }

    p {
      font-weight: 500;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.gray500};

      strong {
        font-weight: 700;
        line-height: 150%;
        font-size: 0.875rem;
        color: ${theme.colors.textColor};
      }
    }
  `}
`;

export const CancelWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 1.5rem;

    button {
      height: 1.25rem;

      span {
        font-weight: 600;
        line-height: 140%;
        font-size: 0.875rem;
        text-decoration: underline;
        color: ${theme.colors.error};
      }
    }
  `}
`;

export const Loader = styled(LoaderSpinner).attrs(({ theme }) => ({
  height: 20,
  type: 'Oval',
  color: lighten(0.07, theme.brandColors.primary || theme.colors.backgroundColor)
}))`
  margin: 6px auto 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlertWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: 16px;
    width: 100%;
    padding: 8px;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    color: ${theme.colors.backgroundColor};
    background-color: ${theme.colors.info};

    svg {
      min-width: 16px;
      min-height: 16px;
      margin-right: 8px;

      align-self: baseline;
    }
  `}
`;
