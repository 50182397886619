import styled, { css } from 'styled-components';
import { motion, MotionProps } from 'framer-motion';

export const Container = styled(motion.div).attrs((props: MotionProps) => ({
  ...props,
  exit: { opacity: 0 },
  animate: { opacity: 1 },
  initial: { opacity: 0 }
}))`
  width: 100%;

  background: ${({ theme }) => theme.colors.backgroundColor};

  .content-wrap {
    position: relative;

    padding-top: 1rem;
    padding-bottom: 8rem;
    min-height: calc(100vh - 300px);

    background: ${({ theme }) => theme.colors.lightestGray};
  }

  > .container {
    padding: 0.5rem 0 0;

    margin: 0 auto;
    max-width: 800px;
  }
`;

export const AddMoreItems = styled.a`
  margin: 1rem 0 0;

  display: block;
`;

export const OptionContainer = styled.div`
  margin: 0 auto;
  max-width: 800px;
  margin-bottom: 2rem;

  overflow-x: hidden;
`;

export const OptionSpacing = styled.div`
  margin: 0.375rem 1rem 0;
`;

export const TabWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 1.5rem 1rem;
    background: ${theme.colors.backgroundColor};

    display: flex;
    justify-content: space-between;
  `}
`;

export const TotalInfoWrapper = styled.div`
  width: 100%;

  margin-bottom: 1.09rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  .total {
    > span {
      font-weight: 600;
      font-size: 0.87rem;
      line-height: 1.31rem;
    }
  }
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;

  > p {
    font-weight: 600;
    font-size: 0.87rem;
    line-height: 1.31rem;

    color: ${({ theme }) => theme.colors.gray500};
  }
`;

export const SummaryInfoWrapper = styled.div`
  width: 100%;
`;

export const SummaryTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-weight: 800;
    font-size: 1.12rem;
    line-height: 1.68rem;
    color: ${({ theme }) => theme.colors.gray700};
  }

  > svg {
    position: absolute;
    top: 0.31rem;
    right: 0.62rem;
  }
`;

export const SummaryInfo = styled.div`
  width: 100%;
  margin: 1rem 0;

  gap: 0.7rem;
  display: flex;
  flex-direction: column;
`;

export const TotalValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TotalValueText = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.68rem;
`;

export const SummaryEachItem = styled.div<{ isCoupon: boolean }>`
  ${({ theme, isCoupon }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 500;
    font-size: 0.87rem;
    line-height: 1.05rem;
    color: ${theme.colors.gray500};

    ${isCoupon &&
    css`
      color: ${theme.colors.success};
    `}
  `}
`;
