import React from 'react';

const Hamburger = (): JSX.Element => {
  return (
    <svg version="1.1" viewBox="0 0 40 40" style={{ transform: 'scale(1.3)' }} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        xmlns="http://www.w3.org/2000/svg"
        d="M8.109 19.188h23.834c.61 0 1.108-.513 1.053-1.083v-.17C32.94 11.67 28.063 7 21.688 7h-3.38C11.877 7 7 11.784 7 18.049c0 .626.499 1.139 1.109 1.139zm22.67-2.222H9.217c.554-4.499 4.268-7.745 9.09-7.745h3.382c4.822 0 8.535 3.246 9.09 7.745zM8.073 24.875c-.388 0-.775-.227-.941-.568-.277-.511-.111-1.193.442-1.534l2.381-1.364c1.44-.795 3.378-.795 4.818 0l.387.227c.776.455 1.994.455 2.714 0l.332-.17c1.44-.796 3.378-.796 4.817 0 .775.454 1.994.454 2.713 0l.056-.057c1.44-.795 3.377-.795 4.817 0l1.827 1.023c.499.284.72.966.443 1.534a1.125 1.125 0 01-1.495.454l-1.827-1.022c-.775-.455-1.993-.455-2.713 0l-.056.056c-1.44.796-3.377.796-4.817 0-.775-.454-1.993-.454-2.713 0l-.388.171c-1.44.796-3.377.796-4.817 0l-.388-.227c-.775-.455-1.993-.455-2.713 0l-2.38 1.363c-.167.057-.333.114-.5.114zM26.486 33H13.46C9.705 33 7 30.409 7 26.84c0-.634.497-1.152 1.104-1.152h23.792c.607 0 1.104.518 1.104 1.151C32.945 30.41 30.24 33 26.486 33zM9.318 27.99c.552 1.9 2.374 2.764 4.14 2.764h13.028c1.767 0 3.588-.863 4.14-2.763H9.318z"
      />
    </svg>
  );
};

export default Hamburger;
