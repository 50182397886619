import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { OperationHours } from '@goomerdev/goomer-toolbox/src/interfaces';
import {
  HoursModeEnum,
  Scheduling,
  SchedulingConfig,
  SchedulingConfigTypeEnum
} from '@goomerdev/goomer-toolbox/src/enums';
import {
  addDays,
  subDays,
  isSameDay,
  compareAsc,
  differenceInDays,
  eachDayOfInterval,
  differenceInCalendarDays,
  differenceInCalendarWeeks
} from 'date-fns';

import { useWeekdays } from '~/hooks';
import { DeliveryWayEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import {
  addDeliveryList,
  addSelectedList,
  addTakeawayList,
  setHasSchedulingList
} from '~/redux-tools/store/scheduling/actions';
import {
  getValidWeekdays,
  getAvailableHours,
  getSchedulingWeekdaysInNumber
} from '~/components/Checkout/CheckoutScheduling/utils';

import useOperatingTime from '../useOperatingTime';

let data: Scheduling[] = [];

export default function useSchedulingInfo() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const origin = useSelector((state: IApplicationState) => state.coupons.origin);

  const { parseOperatingTime } = useOperatingTime();
  const { weekdaysList, shortWeekdaysList } = useWeekdays();

  const isAlwaysOpen = settings?.mm_always_open;
  const operationHoursMode = settings?.mm_hours_mode;
  const fixedOperatingHours = settings?.mm_fixed_hours;
  const customOperatingHours = settings?.mm_operating_hours;

  const isDeliveryEnabled = settings?.mm_delivery_enabled;
  const isTakeawayEnabled = settings?.mm_takeaway_enabled;
  const isSchedulingEnabled = settings?.mm_order_scheduling_enabled;

  const is24hrsOperation = useMemo(() => {
    return operationHoursMode === HoursModeEnum.fixed && isAlwaysOpen;
  }, [isAlwaysOpen, operationHoursMode]);

  const operatingHours = useMemo<OperationHours | undefined>(() => {
    if (is24hrsOperation) {
      return { hours: [{ to: 'Sab', from: 'Dom', open: '00:00', close: '23:00' }] };
    }

    if (operationHoursMode === HoursModeEnum.fixed) {
      return fixedOperatingHours;
    }

    return customOperatingHours;
  }, [customOperatingHours, fixedOperatingHours, is24hrsOperation, operationHoursMode]);

  const deliveryScheduling = settings?.mm_order_scheduling_delivery;

  const takeawayScheduling = settings?.mm_order_scheduling_takeaway;

  const selectedScheduling = useMemo(() => {
    return origin === DeliveryWayEnum.delivery ? deliveryScheduling : takeawayScheduling;
  }, [origin, deliveryScheduling, takeawayScheduling]);

  const removeArrayDuplicates = useCallback((array: number[]) => Array.from(new Set(array)), []);

  const getSchedulingList = useCallback(
    (operationTime?: SchedulingConfig): Scheduling[] => {
      if (!isSchedulingEnabled || !operatingHours?.hours) return [];

      const today = new Date();
      const weekdayToday = today.getDay();

      const parsedOperatingTime = parseOperatingTime(operatingHours);

      const schedulingWeekdays = getSchedulingWeekdaysInNumber(parsedOperatingTime);
      const flatSchedulingWeekdays = removeArrayDuplicates(schedulingWeekdays.flat());

      const isRunningDays = !!operationTime && operationTime?.max.type === SchedulingConfigTypeEnum.runningDays;

      if (!!operationTime && isRunningDays) {
        const endDate = addDays(today, operationTime?.max.value);
        const availableDates = eachDayOfInterval({ start: today, end: endDate });

        if (!availableDates) {
          return [];
        }

        const validDates = availableDates.filter((dateOption) => {
          const isValidToConsiderNextWeek =
            !!differenceInCalendarWeeks(dateOption, today) && differenceInDays(dateOption, today) > 3;

          const validWeekday = getValidWeekdays({
            parsedOperatingTime,
            weekday: dateOption.getDay(),
            deliveryScheduling: operationTime,
            isNextWeek: isValidToConsiderNextWeek
          });

          return !!validWeekday;
        });

        const list: Scheduling[] = validDates.map((dateOption) => {
          const weekday = dateOption.getDay();

          const availableHours = getAvailableHours({
            day: dateOption,
            deliveryScheduling: operationTime,
            operatingTime: parsedOperatingTime,
            is24hrsOperation: !!is24hrsOperation
          });

          return {
            availableHours,
            number: weekday,
            date: dateOption,
            name: weekdaysList[weekday],
            label: shortWeekdaysList[weekday]
          };
        });

        return list.filter((item) => !!item.availableHours && item.availableHours.length > 0);
      }

      let mountedData = flatSchedulingWeekdays
        .map((weekday) => {
          const validWeekday = getValidWeekdays({
            weekday,
            parsedOperatingTime,
            deliveryScheduling: operationTime
          });

          if (!validWeekday) {
            const date = addDays(new Date(), weekday - weekdayToday);
            return {
              date,
              name: '',
              label: '',
              availableHours: [],
              number: date.getDay()
            };
          }

          let weekdayDate = new Date(validWeekday.date);

          if (weekdayDate.getDay() !== weekday) {
            weekdayDate = subDays(weekdayDate, 1);
          }

          const availableHours = getAvailableHours({
            deliveryScheduling: operationTime,
            operatingTime: parsedOperatingTime,
            is24hrsOperation: !!is24hrsOperation,
            day: new Date(weekdayDate.setHours(new Date().getHours(), new Date().getMinutes())) || new Date()
          });

          return {
            availableHours,
            number: weekday,
            name: weekdaysList[weekday],
            date: weekdayDate || new Date(),
            label: shortWeekdaysList[weekday]
          };
        })
        .sort((prevDay, currentDay) => compareAsc(prevDay.date, currentDay.date));

      if (
        !!operationTime &&
        operationTime?.max.type === SchedulingConfigTypeEnum.days &&
        mountedData.length <= operationTime?.max.value
      ) {
        if (!mountedData[0]) return [];

        const isMinModeHours = operationTime?.min.type === SchedulingConfigTypeEnum.hours;
        const isTodayOnList = isSameDay(mountedData[0].date, new Date());
        const initialLastDay = mountedData[mountedData.length - 1];
        mountedData = mountedData.filter((item) => !!item.name);

        let count = 1;
        let weekCount = 0;
        let revertCount = 6;

        do {
          const lastDayOnList = mountedData.length > 0 ? mountedData[mountedData.length - 1] : initialLastDay;

          if (!lastDayOnList) return [];

          const countedWeekday = lastDayOnList.number + count;
          const weekday = countedWeekday > 6 ? lastDayOnList.number - revertCount : countedWeekday;

          const validWeekday = getValidWeekdays({
            weekday,
            isNextWeek: true,
            parsedOperatingTime,
            deliveryScheduling: operationTime
          });

          const weekdayDate = !!validWeekday && new Date(validWeekday.date);

          const availableHours = weekdayDate
            ? getAvailableHours({
                isNextWeek: true,
                deliveryScheduling: operationTime,
                operatingTime: parsedOperatingTime,
                is24hrsOperation: !!is24hrsOperation,
                day: is24hrsOperation
                  ? weekdayDate
                  : new Date(weekdayDate.setHours(new Date().getHours(), new Date().getMinutes()))
              })
            : [];

          const daysAhead = weekCount * 7;
          const weekDate = addDays(lastDayOnList.date, count + daysAhead);
          const dayDifference = differenceInCalendarDays(weekDate, new Date());
          const hasMinEarlyDays = isMinModeHours || dayDifference > operationTime?.min.value;

          if (flatSchedulingWeekdays.includes(weekday) && hasMinEarlyDays) {
            mountedData.push({
              date: weekDate,
              availableHours,
              number: weekday,
              name: weekdaysList[weekday],
              label: shortWeekdaysList[weekday]
            });

            count = 1;
            weekCount = 0;
            revertCount = 6;
          } else if (count > 6) {
            count = 1;
            weekCount += 1;
            revertCount = 6;
          } else {
            count += 1;
            revertCount -= 1;
          }
        } while ((isTodayOnList ? mountedData.length - 1 : mountedData.length) < operationTime?.max.value);
      }

      setLoading(false);

      if (mountedData.length > 0) {
        const list = (!!mountedData && (mountedData as Scheduling[])) || [];

        const filteredList = list.filter((item) => !!item.availableHours && item.availableHours?.length > 0);

        if (
          !!selectedScheduling &&
          selectedScheduling?.max.type === SchedulingConfigTypeEnum.days &&
          filteredList.length > selectedScheduling?.max.value
        ) {
          const listDiff = filteredList.length - selectedScheduling?.max.value;

          filteredList.splice(-1, listDiff);
        }

        return filteredList;
      }

      return [];
    },
    [
      weekdaysList,
      operatingHours,
      is24hrsOperation,
      shortWeekdaysList,
      parseOperatingTime,
      selectedScheduling,
      isSchedulingEnabled,
      removeArrayDuplicates
    ]
  );

  const generateLists = useCallback(() => {
    const deliverySchedulingList = getSchedulingList(deliveryScheduling);
    const takeawaySchedulingList = getSchedulingList(takeawayScheduling);

    if (origin === DeliveryWayEnum.delivery) {
      data = deliverySchedulingList;
      dispatch(addSelectedList(deliverySchedulingList));
    }

    if (origin === DeliveryWayEnum.takeaway) {
      data = takeawaySchedulingList;
      dispatch(addSelectedList(takeawaySchedulingList));
    }

    dispatch(addDeliveryList(deliverySchedulingList));
    dispatch(addTakeawayList(takeawaySchedulingList));

    const hasDeliveryList = !!isDeliveryEnabled && deliverySchedulingList.length > 0;
    const hasTakeawayList = !!isTakeawayEnabled && takeawaySchedulingList.length > 0;

    dispatch(setHasSchedulingList(hasDeliveryList || hasTakeawayList));
  }, [
    origin,
    dispatch,
    getSchedulingList,
    isDeliveryEnabled,
    isTakeawayEnabled,
    deliveryScheduling,
    takeawayScheduling
  ]);

  useEffect(() => {
    generateLists();

    const ONE_MINUTE = 1000 * 60;

    const updateTheListInSpecificMinutes = setInterval(() => {
      const nowMinutes = new Date().getMinutes();

      if (nowMinutes === 1 || nowMinutes === 31) {
        generateLists();
      }
    }, ONE_MINUTE);

    return () => {
      clearInterval(updateTheListInSpecificMinutes);
    };
  }, [generateLists]);

  return {
    data,
    loading,
    generateSchedulingList: generateLists
  };
}
