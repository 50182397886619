import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import { NewWaves } from '~/components';
import { convertToCurrency } from '~/utils';
import { PaymentReceipt } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';

import * as S from './styles';
import { useTranslator } from '~/hooks';

interface ListReceiptModalProps {
  isOpenModal: boolean;
  receipts: PaymentReceipt[];
  setIsOpenModal: (param: boolean) => void;
  showReceipt: (receipt: PaymentReceipt) => void;
}

const ListReceiptModal: (props: ListReceiptModalProps) => JSX.Element = ({
  receipts,
  showReceipt,
  isOpenModal,
  setIsOpenModal
}) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  return (
    <S.Container
      isPageLike
      isShow={isOpenModal}
      onClose={(): void => setIsOpenModal(false)}
      headerTitle={getTranslation('general.receipt')}
      containerBackgroundColor={theme.colors.gray300}
      titleBackgroundColor={theme.colors.backgroundColor}
    >
      <S.Content>
        <S.List>
          {receipts.map((receipt) => (
            <S.Receipt key={receipt.partnerUniqueID} onClick={(): void => showReceipt(receipt)}>
              <p className="date">
                {receipt.date ? format(new Date(receipt.date), 'HH:mm') : getTranslation('myTab.paymentViaDashboard')}
              </p>

              <p className="value">{convertToCurrency(receipt.paid)}</p>
            </S.Receipt>
          ))}
        </S.List>

        <NewWaves backgroundColor={theme.colors.gray200} />
      </S.Content>
    </S.Container>
  );
};

export { ListReceiptModal };
