import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: auto;
  max-width: 800px;
`;

export const Scroll = styled.div<{ $isMobile: boolean }>`
  ${({ $isMobile }) => css`
    width: 100%;

    gap: 0.5rem;
    display: grid;
    padding: 0 1rem 0.5rem;
    grid-auto-flow: column;

    cursor: grab;
    overflow-x: hidden;
    overflow-y: hidden;
    scrollbar-width: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;

    ${$isMobile &&
    css`
      padding: 0 1rem;

      overflow-x: scroll;

      > div,
      > section {
        scroll-snap-align: center;
      }

      scroll-snap-type: x mandatory;
    `}
  `}
`;

export const Navigation = styled.div`
  width: 100%;
  padding: 0 0.5rem;
  margin: 0.5rem 0 0;

  gap: 0.29rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`;

export const NavigationItem = styled.div<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => css`
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;

    cursor: pointer;
    background-color: ${$isActive ? theme.brandColors.primary : theme.colors.gray300};
  `}
`;
