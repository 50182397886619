import React, { useMemo, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useWindowSize } from '@goomerdev/goomer-toolbox/src/hooks';

import { Product } from '~/interfaces';
import { useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { Modal, ProductCard, Button } from '~/components';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';

import * as S from './styles';

interface SuggestionModalProps {
  closeModal: () => void;
  isModalVisible: boolean;
  acceptSuggestion: (id: number) => void;
  suggestions?: Omit<Product, 'suggestions'>[];
  trackingData: { associatedProduct?: Product };
}

const SuggestionModal: React.FC<SuggestionModalProps> = ({
  closeModal,
  suggestions,
  trackingData,
  isModalVisible,
  acceptSuggestion
}) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { width } = useWindowSize();
  const { getTranslation } = useTranslator();

  const suggestionData = useMemo(
    () => ({
      // eslint-disable-next-line camelcase
      establishment_id: settings?.store_code,
      // eslint-disable-next-line camelcase
      associated_product: trackingData.associatedProduct?.name
    }),
    [settings?.store_code, trackingData.associatedProduct?.name]
  );

  const isHorizontalLayout = useMemo(
    () => (!!suggestions && suggestions.length === 1) || (!!width && width <= 375),
    [suggestions, width]
  );

  const gaTrackAccept = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.acceptedSuggestion, suggestionData);
  }, [suggestionData]);

  const handleAccept = useCallback(
    (productId: number) => {
      gaTrackAccept();
      acceptSuggestion(productId);
    },
    [acceptSuggestion, gaTrackAccept]
  );

  const gaTrackDenied = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.deniedSuggestion, suggestionData);
  }, [suggestionData]);

  const handleDenied = useCallback(() => {
    gaTrackDenied();
    closeModal();
  }, [closeModal, gaTrackDenied]);

  const productCardList = useMemo(() => {
    if (!suggestions || suggestions.length === 0) return null;

    return (
      <>
        <S.SuggestionsWrapper $isHorizontalLayout={isHorizontalLayout}>
          {suggestions.map((suggestion) => (
            <ProductCard
              isSuggestion
              suggestionAddButton
              key={suggestion.id}
              product={suggestion}
              prices={suggestion.prices}
              isHorizontalLayout={isHorizontalLayout}
              suggestionAddButtonAction={(): void => handleAccept(suggestion.id)}
            />
          ))}
        </S.SuggestionsWrapper>
      </>
    );
  }, [handleAccept, isHorizontalLayout, suggestions]);

  return (
    <Modal
      isSmall
      hideCloseButton
      isWithoutPadding
      isShow={isModalVisible}
      onClose={(): void => handleDenied()}
      containerBackgroundColor={theme.isDarkMode ? theme.colors.gray200 : theme.colors.backgroundColor}
      customHeader={
        <S.CustomHeader>
          <S.CustomHeaderText>{getTranslation('suggestion.advantageAndOrderToo')}</S.CustomHeaderText>

          <Button isSimple onClick={(): void => handleDenied()}>
            {getTranslation('general.skip')}
          </Button>
        </S.CustomHeader>
      }
    >
      <S.Container>{productCardList}</S.Container>
    </Modal>
  );
};

export default SuggestionModal;
