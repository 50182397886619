import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from '@goomerdev/goomer-toolbox/src/hooks';

import Modal from '~/components/Modal/Modal';
import { PaymentMethod } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { CheckoutContext as OrderContext } from '~/pages/order';
import { CheckoutContext as LocalOrderContext } from '~/pages/localOrder';
import { useDeliveryPartner, useGoogleMaps, useTranslator } from '~/hooks';
import { setUserAddressLatLngOnCart } from '~/redux-tools/store/cart/actions';

import Map from '../Map';
import Marker from '../Marker';

import * as S from './styles';

interface LatLngConfirmationModalProps {
  isModalOpen?: boolean;
  paymentOption?: PaymentMethod;
}

const DAYS_TO_EXPIRE_GEOCODE = 25;
const ONE_DAY_IN_MILLISECONDS = 86400000;
const DEFAULT_MAP_ZOOM = 18; /** 0 (min) to 18 (max) */
const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '';

const LatLngConfirmationModal: React.FC<LatLngConfirmationModalProps> = ({ paymentOption, isModalOpen = false }) => {
  const dispatch = useDispatch();

  const cartAddress = useSelector((state: IApplicationState) => state.cart.address);
  const localOrder = useSelector((state: IApplicationState) => state.localOrders.mode);

  const [mapZoom, setMapZoom] = useState<number>();
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>();
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral>();
  const [isLoadingFetchGoogleApi, setIsLoadingFetchGoogleApi] = useState<boolean>(false);
  const [hasErrorOnFetchGoogleApi, setHasErrorOnFetchGoogleApi] = useState<boolean>(false);

  const { getTranslation } = useTranslator();
  const { getGeocoderResults } = useGoogleMaps();
  const { shouldAskLatitudeAndLongitude } = useDeliveryPartner();
  const checkoutCart = useContext(localOrder ? LocalOrderContext : OrderContext);
  const { localStorageValue, setLocalStorageValue } = useLocalStorage({ key: 'geocode' });

  const localStorageGeocodeList = useMemo(
    () =>
      JSON.parse(localStorageValue || '[]') as {
        address: string;
        latitude: number;
        longitude: number;
        expireDate: Date;
      }[],
    [localStorageValue]
  );

  const geocodeFilteredList = useMemo(
    () => localStorageGeocodeList.filter((geocode) => new Date(geocode.expireDate) > new Date()),
    [localStorageGeocodeList]
  );

  const formattedAddress: string = useMemo(
    () =>
      cartAddress ? `${cartAddress?.street} ${cartAddress?.number} ${cartAddress?.city} ${cartAddress?.state}` : '',
    [cartAddress]
  );

  const localStorageGeocode = useMemo(
    () => geocodeFilteredList.find((geocode) => geocode.address === formattedAddress),
    [formattedAddress, geocodeFilteredList]
  );

  const setGeocodeOnLocalStorage: (params?: { latitude: number; longitude: number }) => void = useCallback(
    (params) => {
      const newList = geocodeFilteredList;

      const expireDateInMs = new Date().getTime() + DAYS_TO_EXPIRE_GEOCODE * ONE_DAY_IN_MILLISECONDS;

      localStorageGeocode && newList.splice(newList.indexOf(localStorageGeocode), 1);

      if (params) {
        newList.push({
          address: formattedAddress,
          latitude: params.latitude,
          longitude: params.longitude,
          expireDate: new Date(expireDateInMs)
        });
      }

      setLocalStorageValue(JSON.stringify(newList));
    },
    [formattedAddress, geocodeFilteredList, localStorageGeocode, setLocalStorageValue]
  );

  const handleOnClick: (event: google.maps.MapMouseEvent, map: google.maps.Map) => void = useCallback(
    (event, map) => {
      const latLng = event.latLng;

      if (latLng && map) {
        setGeocodeOnLocalStorage({ latitude: latLng.lat(), longitude: latLng.lng() });
        setMarkerPosition({ lat: latLng.lat(), lng: latLng.lng() });
      }
    },
    [setGeocodeOnLocalStorage]
  );

  const handleFetchGoogleApiAddress = useCallback(() => {
    if (!!isLoadingFetchGoogleApi || !GOOGLE_MAPS_API_KEY || !!hasErrorOnFetchGoogleApi) return;

    setIsLoadingFetchGoogleApi(true);

    try {
      let zoom: number = DEFAULT_MAP_ZOOM;
      let center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
      let marker: google.maps.LatLngLiteral = { lat: 0, lng: 0 };

      const afterAll = (): void => {
        setMapZoom(zoom);
        setMapCenter(center);
        setMarkerPosition(marker);

        setIsLoadingFetchGoogleApi(false);
      };

      const afterError = (errorMessage: string): void => {
        toast.error(errorMessage);
        console.error(errorMessage);

        setHasErrorOnFetchGoogleApi(true);
        setGeocodeOnLocalStorage(undefined);
        checkoutCart.setShowLatLngConfirmationModal(false);

        afterAll();
      };

      const afterSuccess = (geocoderResult: google.maps.GeocoderResult[] | null): void => {
        const location = geocoderResult?.[0]?.geometry?.location;
        const latLng: google.maps.LatLngLiteral = {
          lat: location?.lat() || 0,
          lng: location?.lng() || 0
        };

        center = latLng;
        marker = latLng;
        zoom = DEFAULT_MAP_ZOOM;

        setGeocodeOnLocalStorage({ latitude: latLng.lat, longitude: latLng.lng });

        afterAll();
      };

      getGeocoderResults({
        afterError,
        afterSuccess,
        address: formattedAddress
      });
    } catch (error) {
      setIsLoadingFetchGoogleApi(false);

      toast.error(getTranslation('map.errorToGetAddressInfo'));
    }
  }, [
    checkoutCart,
    getTranslation,
    formattedAddress,
    getGeocoderResults,
    isLoadingFetchGoogleApi,
    hasErrorOnFetchGoogleApi,
    setGeocodeOnLocalStorage
  ]);

  const isLocalStorageGeocodeExpired: boolean = useMemo(() => {
    return !localStorageGeocode || new Date(localStorageGeocode.expireDate) < new Date();
  }, [localStorageGeocode]);

  const shouldAskLatAndLng = useMemo(
    () =>
      shouldAskLatitudeAndLongitude({
        paymentOption
      }),
    [paymentOption, shouldAskLatitudeAndLongitude]
  );

  const shouldRender: boolean = useMemo(
    () => !!cartAddress && !!mapZoom && !!mapCenter && !!markerPosition,
    [cartAddress, mapCenter, mapZoom, markerPosition]
  );

  useEffect(() => {
    if (shouldAskLatAndLng) {
      if (isLocalStorageGeocodeExpired) {
        handleFetchGoogleApiAddress();

        return;
      }

      const location: google.maps.LatLngLiteral = {
        lat: localStorageGeocode?.latitude || 0,
        lng: localStorageGeocode?.longitude || 0
      };

      if (location.lat !== markerPosition?.lat || location.lng !== markerPosition?.lng) {
        setMapCenter(location);
        setMarkerPosition(location);
        setMapZoom(DEFAULT_MAP_ZOOM);
      }
    }
  }, [
    shouldAskLatAndLng,
    markerPosition?.lat,
    markerPosition?.lng,
    handleFetchGoogleApiAddress,
    isLocalStorageGeocodeExpired,
    localStorageGeocode?.latitude,
    localStorageGeocode?.longitude
  ]);

  if (!shouldRender) return <></>;

  return (
    <S.Container>
      <Modal
        isShow={isModalOpen}
        footerTitle={getTranslation('map.confirmLocation')}
        headerTitle={getTranslation('map.confirmLocationOnMap')}
        onFooterClick={(): void => {
          const position = {
            latitude: Number(markerPosition?.lat || 0),
            longitude: Number(markerPosition?.lng || 0)
          };

          dispatch(setUserAddressLatLngOnCart(position));

          checkoutCart.setShowLatLngConfirmationModal(false);
        }}
        onClose={(): void => {
          checkoutCart.setShowLatLngConfirmationModal(false);
        }}
      >
        <Map
          style={{}}
          zoom={mapZoom}
          center={mapCenter}
          clickableIcons={false}
          mapTypeControl={false}
          onClick={handleOnClick}
          fullscreenControl={false}
          streetViewControl={false}
        >
          <Marker position={markerPosition} />
        </Map>
      </Modal>
    </S.Container>
  );
};

export default LatLngConfirmationModal;
