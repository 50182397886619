import React, { useCallback } from 'react';

import { useTranslator } from '~/hooks';
import { cardNumberMask } from '~/pages/cardEntry/utils';

import InputMask from '../InputMask';
import { InputWithIconContainer } from './styled';

const INPUT_LENGTH = 15;

interface InputCardNumberProps {
  dataTest?: string;
  required?: boolean;
  initialValue?: string;
  onChange: (value: string, isValid: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const InputCardNumber: React.FC<InputCardNumberProps> = ({ dataTest, onChange, initialValue }) => {
  const { getTranslation } = useTranslator();

  const handleValidate = useCallback((value: string) => {
    const pureValue = value.replace(/\D/g, '');
    return !pureValue || pureValue.length >= INPUT_LENGTH;
  }, []);

  return (
    <InputWithIconContainer className="sentry-mask">
      <InputMask
        required
        name="number"
        inputMode="decimal"
        onChange={onChange}
        data-test={dataTest}
        toMask={cardNumberMask}
        validate={handleValidate}
        defaultValue={initialValue}
        placeholder="0000 0000 0000 0000"
        errorMessage={getTranslation('payment.invalidNumber')}
      />
    </InputWithIconContainer>
  );
};

export default React.memo(InputCardNumber);
