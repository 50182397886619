import React, { useEffect, useState, useCallback, useRef } from 'react';
import { RiMailLine } from 'react-icons/ri';

import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { OAuthCredentialOptions } from 'firebase/auth';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { GoogleButtonIcon } from '~/assets';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { useAuth, useHasGoomerDomain, useTranslator } from '~/hooks';
import { FirebaseData, HandleRegistrationSubmit } from '~/interfaces/general';
import { Button, AccountVerification, RegistrationCompleted } from '~/components';
import { LoginOptionEnum, LoginTypeEnum, QueryParam, WelcomeEnum } from '~/interfaces/enums';

import * as S from './styles';

export interface UserLoginProps {
  title: string;
  logoUrl: string;
  description: string;
  previousPage?: string;
  displayRegistrationCompletedScreen: boolean;
  setDisplayRegistrationCompletedScreen: (status: boolean) => void;
}

const UserLogin: React.FC<UserLoginProps> = ({
  title,
  logoUrl,
  description,
  previousPage,
  setDisplayRegistrationCompletedScreen,
  displayRegistrationCompletedScreen = false
}) => {
  const history = useRouter();

  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const loginByParamLoaded = useRef(false);

  const [isRegisteredUser, setIsRegisteredUser] = useState<boolean>(false);
  const [welcome, setWelcome] = useState<WelcomeEnum | undefined>(undefined);
  const [isGoogleLoginLoading, setIsGoogleLoginLoading] = useState<boolean>(false);
  const [isInstagramUserAgent, setIsInstagramUserAgent] = useState<boolean>(false);
  const [firebaseData, setFirebaseData] = useState<FirebaseData | undefined>(undefined);
  const [displayUserLoginOption, setDisplayUserLoginOption] = useState<LoginOptionEnum | undefined>(undefined);

  const { getTranslation } = useTranslator();
  const hasGoomerDomain: boolean = useHasGoomerDomain();
  const { getUserIdToken, signInWithGoogle } = useAuth();

  const handleGaEvent = useCallback(
    (gaEvent: gaEvents): void => {
      GoogleAnalytics.trackEvent(gaEvent, {
        // eslint-disable-next-line camelcase
        establishment_id: settings?.id
      });
    },
    [settings?.id]
  );

  const handleEmailLoginOptionClick = useCallback((): void => {
    handleGaEvent(gaEvents.signInTypeEmail);
    setDisplayUserLoginOption(LoginOptionEnum.email);
  }, [handleGaEvent]);

  const handleGoogleLoginOptionClick: () => Promise<void> = useCallback(async () => {
    setIsGoogleLoginLoading(true);

    handleGaEvent(gaEvents.signInTypeGoogle);

    await signInWithGoogle()
      .then((userCredential) => {
        if (!userCredential) {
          return setIsGoogleLoginLoading(false);
        }

        const { user } = userCredential;
        const authCredential: OAuthCredentialOptions | null = getUserIdToken(userCredential);

        const hasUserIdToken = !!authCredential?.idToken;

        if (!hasUserIdToken) {
          return setIsGoogleLoginLoading(false);
        }

        handleGaEvent(gaEvents.googleAccount);

        const firebaseData: FirebaseData = {
          email: '',
          uid: user.uid,
          providerId: user.providerId || '',
          displayName: user.displayName || '',
          idToken: authCredential?.idToken || ''
        };

        setFirebaseData(firebaseData);
        setIsGoogleLoginLoading(false);
        setDisplayUserLoginOption(LoginOptionEnum.google);
      })
      .catch(() => {
        setIsGoogleLoginLoading(false);

        if (displayUserLoginOption === LoginOptionEnum.google) {
          toast.error(getTranslation('login.googleError'));

          setDisplayUserLoginOption(undefined);
        }
      });
  }, [displayUserLoginOption, getTranslation, getUserIdToken, handleGaEvent, signInWithGoogle]);

  const handleLeaveThePage = (): void => {
    const page = previousPage || 'menu';
    history.push(`/${page}`);
  };

  useEffect(() => {
    const raw = !!navigator && navigator.userAgent;
    const index = raw.indexOf('Instagram');

    setIsInstagramUserAgent(index !== -1);
  }, []);

  const handleOnConfirm = (props?: HandleRegistrationSubmit): void => {
    setIsRegisteredUser(props?.isRegisteredUser || false);

    setDisplayUserLoginOption(undefined);
    setWelcome(props?.welcome);

    const isContinueWithoutSignUp = !!props?.continueWithoutSignUp;
    setDisplayRegistrationCompletedScreen(!isContinueWithoutSignUp);

    if (isContinueWithoutSignUp) {
      handleLeaveThePage();
    }
  };

  useEffect(() => {
    if (loginByParamLoaded.current) {
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const loginType = queryParams.get(QueryParam.loginType) || '';

    if (loginType === LoginTypeEnum.google) {
      handleGoogleLoginOptionClick();
    }

    if (loginType === LoginTypeEnum.email) {
      handleEmailLoginOptionClick();
    }

    loginByParamLoaded.current = true;
    return;
  }, [handleEmailLoginOptionClick, handleGoogleLoginOptionClick]);

  return (
    <>
      {!displayRegistrationCompletedScreen ? (
        <S.Container id="user-login">
          {logoUrl && (
            <LazyLoadImage
              width={112}
              height={112}
              src={logoUrl}
              className="logo"
              alt={getTranslation('label.storeImage')}
            />
          )}

          <h2>{title}</h2>

          <span>{description}</span>

          <span className="small">{getTranslation('login.select')}:</span>

          {hasGoomerDomain && !isInstagramUserAgent && (
            <Button
              style={{ position: 'relative' }}
              isLoading={isGoogleLoginLoading}
              className={LoginOptionEnum.google}
              onClick={(): void => {
                handleGoogleLoginOptionClick();
              }}
            >
              <div style={{ left: '0.125rem', display: 'flex', position: 'absolute' }}>
                <GoogleButtonIcon />
              </div>

              {getTranslation('login.continueWithGoogle')}
            </Button>
          )}

          <Button
            isGhost={false}
            style={{ position: 'relative' }}
            className={LoginOptionEnum.email}
            onClick={handleEmailLoginOptionClick}
          >
            <RiMailLine size={20} style={{ left: '1rem', position: 'absolute' }} />

            {getTranslation('login.continueWithEmail')}
          </Button>
        </S.Container>
      ) : (
        <RegistrationCompleted
          previousPage={previousPage}
          welcome={welcome || WelcomeEnum.first}
          isRegisteredUser={isRegisteredUser || false}
        />
      )}

      {!!displayUserLoginOption && (
        <AccountVerification
          shouldDisplay
          onConfirm={handleOnConfirm}
          selectedLoginOption={displayUserLoginOption}
          onClose={(): void => setDisplayUserLoginOption(undefined)}
          firebaseData={displayUserLoginOption === LoginOptionEnum.google ? firebaseData : undefined}
        />
      )}
    </>
  );
};

export default UserLogin;
