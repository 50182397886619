import { useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { addDays, compareAsc } from 'date-fns';

import { handleScript } from '~/utils';
import { FingerPrintStorageProps } from '~/interfaces/general';
import { LocalStorageFingerPrintEnum } from '~/interfaces/enums';

interface UseFingerPrintProps {
  isScriptLoaded: boolean;
}

export default function useFingerPrint({ isScriptLoaded }: UseFingerPrintProps) {
  useEffect(() => {
    if (!isScriptLoaded) {
      const hasFingerPrintUserId = localStorage.getItem(LocalStorageFingerPrintEnum.isCalledFingerPrintScript);
      const fingerPrintStorage: FingerPrintStorageProps = !!hasFingerPrintUserId && JSON.parse(hasFingerPrintUserId);

      const dateToExpire = fingerPrintStorage.expireDate;

      const expiredDate = dateToExpire && compareAsc(new Date(dateToExpire), new Date()) !== 1;

      let userId = uuidv4();

      if (!hasFingerPrintUserId || !!expiredDate) {
        localStorage.setItem(
          LocalStorageFingerPrintEnum.isCalledFingerPrintScript,
          JSON.stringify({ fingerPrintUserId: userId, expireDate: addDays(new Date(), 1) })
        );
      } else {
        userId = fingerPrintStorage.fingerPrintUserId;
      }

      handleScript({
        isScriptLoaded,
        url: `https://h.online-metrix.net/fp/tags.js?org_id=${process.env.NEXT_PUBLIC_TUNA_ORG_ID}&session_id=tunaltda${userId}`
      });
    }
  }, [isScriptLoaded]);
}
