import styled, { css } from 'styled-components';

import { Modal } from '~/components';

export const Container =
  !!Modal &&
  styled(Modal)`
    .container {
      background: ${({ theme }) => theme.colors.backgroundColor} !important;
    }
  `;

export const ContentWrapper = styled.div`
  padding: 0 1rem 1.5rem;
`;

export const RegularText = styled.p`
  ${({ theme }) => css`
    margin: 0.5rem 0 1rem;

    line-height: 150%;
    text-align: center;
    font-size: 0.875rem;
    color: ${theme.colors.gray500};
  `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  gap: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
