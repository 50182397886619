import { useCallback, useContext } from 'react';

import ContentLoader from 'react-content-loader';

import { useTranslator } from '~/hooks';
import { InputEmail } from '~/components';
import { LoginOptionEnum } from '~/interfaces/enums';
import { AccountVerificationContext } from '~/components/AccountVerification';

import { Field } from '../../styles';

import * as S from './styles';

const SendEmail = (): JSX.Element => {
  const { getTranslation } = useTranslator();
  const { userEmail, setUserEmail, selectedLoginOption } = useContext(AccountVerificationContext);

  const renderSkeleton = (
    <ContentLoader
      title="loader"
      speed={2}
      width="100%"
      height={330}
      radius={10}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="25%" y="10" rx="3" ry="3" width="50%" height="12" />
      <rect x="20%" y="33" rx="3" ry="3" width="60%" height="12" />
      <rect x="35%" y="50" rx="3" ry="3" width="30%" height="12" />

      <rect x="0" y="95" rx="3" ry="3" width="50%" height="12" />
      <rect x="0" y="113" rx="3" ry="3" width="100%" height="30" />

      <rect x="0" y="168" rx="3" ry="3" width="50%" height="12" />
      <rect x="0" y="185" rx="3" ry="3" width="100%" height="30" />
      <rect x="0" y="223" rx="3" ry="3" width="100%" height="10" />
      <rect x="0" y="237" rx="3" ry="3" width="30%" height="10" />

      <rect x="0" y="272" rx="3" ry="3" width="100%" height="10" />
      <rect x="0" y="286" rx="3" ry="3" width="100%" height="10" />
      <rect x="0" y="300" rx="3" ry="3" width="50%" height="10" />
    </ContentLoader>
  );

  const handleEmailChange = useCallback(
    (value: string) => {
      setUserEmail(value);
    },
    [setUserEmail]
  );

  const renderContent: Record<string, JSX.Element> = {
    [LoginOptionEnum.google]: renderSkeleton,

    [LoginOptionEnum.email]: (
      <>
        <h2>{getTranslation('accountVerification.logInOrRegister')}</h2>

        <p>{getTranslation('accountVerification.insertYourEmail')}</p>

        <Field>
          <InputEmail
            required
            defaultValue={userEmail}
            onChange={handleEmailChange}
            data-test="input-email-verification"
            placeholder={getTranslation('general.email')}
          />
        </Field>
      </>
    )
  };

  return <S.Container>{renderContent[selectedLoginOption]}</S.Container>;
};

export default SendEmail;
