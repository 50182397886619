import { useContext, useMemo } from 'react';

import { AddressPageModeEnum } from '~/interfaces/enums';
import { AddressContext } from '~/components/Address/AddressList';
import { AddressFormContext } from '~/components/Address/AddressList/components/Form';

import useAddressIsNeighborhoodTax from './useAddressIsNeighborhoodTax';

export default function useAddressInitialFormFields() {
  const { selectedView } = useContext(AddressContext);
  const { cep, cityName, state, street, neighborhood, neighborhoodId } = useContext(AddressFormContext);

  const { isNeighborhoodTax } = useAddressIsNeighborhoodTax();

  const hasInitialFieldsFilled: boolean = useMemo(() => {
    if (selectedView === AddressPageModeEnum.noCepForm) {
      return true;
    }

    const commonFields: boolean = !!cep && !!cityName && !!state;

    if (selectedView === AddressPageModeEnum.noStreetByCepForm) {
      return commonFields;
    }

    if (isNeighborhoodTax) {
      return commonFields && !!street && !!neighborhood && !!neighborhoodId;
    } else {
      return commonFields && !!street && !!neighborhood;
    }
  }, [selectedView, isNeighborhoodTax, cep, cityName, state, street, neighborhood, neighborhoodId]);

  return { hasInitialFieldsFilled };
}
