import React from 'react';

const SmartphoneSuccessIcon: React.FC = () => (
  <svg width="67" height="54" viewBox="0 0 67 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.8171 46.2858C56.6208 46.2858 67.0002 35.9243 67.0002 23.1429C67.0002 10.3614 56.6208 0 43.8171 0C31.0135 0 20.634 10.3614 20.634 23.1429C20.634 35.9243 31.0135 46.2858 43.8171 46.2858Z"
      fill="#E8EAED"
    />
    <path
      d="M4.68673 21.6467C5.21527 21.6467 5.64373 21.2193 5.64373 20.6921C5.64373 20.1649 5.21527 19.7375 4.68673 19.7375C4.1582 19.7375 3.72974 20.1649 3.72974 20.6921C3.72974 21.2193 4.1582 21.6467 4.68673 21.6467Z"
      fill="#30333C"
    />
    <path
      d="M5.07105 6.78644C4.60385 6.78644 4.22583 6.40937 4.22583 5.94335V3.41408C4.22583 2.94806 4.60385 2.57098 5.07105 2.57098C5.53824 2.57098 5.91626 2.94806 5.91626 3.41408V5.94335C5.91626 6.40937 5.53824 6.78644 5.07105 6.78644Z"
      fill="#7F8F9F"
    />
    <path
      d="M5.07105 12.6883C4.60385 12.6883 4.22583 12.3112 4.22583 11.8452V9.31593C4.22583 8.84991 4.60385 8.47284 5.07105 8.47284C5.53824 8.47284 5.91626 8.84991 5.91626 9.31593V11.8452C5.91626 12.3112 5.53824 12.6883 5.07105 12.6883Z"
      fill="#7F8F9F"
    />
    <path
      d="M3.38087 8.47241H0.845217C0.378023 8.47241 0 8.09533 0 7.62932C0 7.1633 0.378023 6.78622 0.845217 6.78622H3.38087C3.84806 6.78622 4.22608 7.1633 4.22608 7.62932C4.22608 8.09533 3.84806 8.47241 3.38087 8.47241Z"
      fill="#7F8F9F"
    />
    <path
      d="M9.29737 8.4725H6.76172C6.29453 8.4725 5.9165 8.09543 5.9165 7.62941C5.9165 7.16339 6.29453 6.78632 6.76172 6.78632H9.29737C9.76456 6.78632 10.1426 7.16339 10.1426 7.62941C10.1426 8.09543 9.76456 8.4725 9.29737 8.4725Z"
      fill="#7F8F9F"
    />
    <path
      d="M10.9871 53.1565V15.0349C10.9871 12.8074 12.7971 11.002 15.0302 11.002H47.5146C49.7474 11.002 51.5574 12.8074 51.5574 15.0349V53.1565"
      fill="white"
    />
    <path
      d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
      fill="#30333C"
    />
    <path
      d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
      fill="#30333C"
    />
    <path d="M22 11H40V12C40 13.6569 38.6569 15 37 15H25C23.3431 15 22 13.6569 22 12V11Z" fill="#30333C" />
    <path d="M53.5361 25.335H51.8457V29.5504H53.5361V25.335Z" fill="#30333C" />
    <path
      d="M51.8452 30.3933H53.5356C54.0028 30.3933 54.3809 30.0163 54.3809 29.5502V25.3348C54.3809 24.8688 54.0028 24.4917 53.5356 24.4917H51.8452C51.378 24.4917 51 24.8688 51 25.3348V29.5502C51 30.0163 51.378 30.3933 51.8452 30.3933Z"
      fill="#30333C"
    />
    <path
      d="M51.8452 37.1385H53.5356C54.0028 37.1385 54.3809 36.7614 54.3809 36.2954V32.0799C54.3809 31.6139 54.0028 31.2368 53.5356 31.2368H51.8452C51.378 31.2368 51 31.6139 51 32.0799V36.2954C51 36.7614 51.378 37.1385 51.8452 37.1385Z"
      fill="#30333C"
    />
    <path
      opacity="0.3"
      d="M31 54C38.1797 54 44 48.1797 44 41C44 33.8203 38.1797 28 31 28C23.8203 28 18 33.8203 18 41C18 48.1797 23.8203 54 31 54Z"
      fill="#7F8F9F"
    />
    <path
      d="M31.0001 51.7059C36.9128 51.7059 41.706 46.9127 41.706 41C41.706 35.0873 36.9128 30.2941 31.0001 30.2941C25.0874 30.2941 20.2942 35.0873 20.2942 41C20.2942 46.9127 25.0874 51.7059 31.0001 51.7059Z"
      fill="#485460"
    />
    <path
      d="M29.2328 45.5882C28.9662 45.5882 28.6996 45.4763 28.4863 45.3083L25.2872 42.4525C24.8073 42.0606 24.754 41.2766 25.1273 40.7727C25.5005 40.2687 26.247 40.2127 26.7268 40.6047L29.1795 42.7885L35.2045 36.7411C35.6311 36.2931 36.3775 36.2931 36.8041 36.797C37.2306 37.245 37.2306 38.0289 36.7508 38.4769L29.9793 45.2523C29.766 45.4763 29.4994 45.5882 29.2328 45.5882Z"
      fill="white"
    />
  </svg>
);

export default SmartphoneSuccessIcon;
