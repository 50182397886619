import React, { useState, useCallback, useRef, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { Group } from '~/interfaces';
import { IApplicationState } from '~/redux-tools/store';
import { isThisChromeVersionSupported, scrollTo } from '~/utils';

import * as S from './styles';

interface PositionOnScreen {
  id: number;
  name: string;
  yCoordinate: number;
}

interface MobileCategoryFilterProps {
  groupList: Group[];
}

const MobileCategoryFilter: React.FC<MobileCategoryFilterProps> = ({ groupList }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [yOffset] = useState<number>(52);
  const [compensation] = useState<number>(80);
  const [logoHeaderOffset] = useState<number>(48);
  const [selected, setSelected] = useState<string>('');
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [productScrollHeight, setProductScrollHeight] = useState<number>(0);
  const [positionOnScreenList, setPositionOnScreenList] = useState<PositionOnScreen[]>([]);

  useScrollPosition(
    ({ currPos: currentPosition }) => {
      if (positionOnScreenList && positionOnScreenList.length > 0) {
        const currentPositionY = Math.abs(currentPosition.y);

        const currentAnchor: {
          id: number;
          name: string;
          yCoordinate: number;
        } = positionOnScreenList?.reduce((previousValue, currentValue) => {
          if (currentValue.yCoordinate > currentPositionY) {
            return previousValue;
          }

          return Math.abs(currentValue.yCoordinate - currentPositionY) <
            Math.abs(previousValue.yCoordinate - currentPositionY)
            ? currentValue
            : previousValue;
        });

        setSelected(currentAnchor.id.toString());
      }

      if (currentPosition.y < (logoHeaderOffset + yOffset) * -1) {
        return setIsScrolled(true);
      }

      return setIsScrolled(false);
    },
    [positionOnScreenList],
    undefined,
    false,
    100
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (positionOnScreenList && ref.current && selected) {
        const element =
          typeof document !== 'undefined' && document.querySelectorAll(`div[id='category-${selected}']`)[0];

        if (element instanceof HTMLElement) {
          if (isThisChromeVersionSupported()) {
            ref.current.scrollTo({ left: element.offsetLeft - 84 });
          }
        }
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [selected, positionOnScreenList]);

  const scrollToItemBasedOnUrl = useCallback(() => {
    if (groupList.length === 0) return;

    const group = groupList?.find((group) => group.id && window.location.href.includes(`${group.id.toString()}`));

    if (!group?.id) return;

    setSelected(group.id.toString());

    const element = typeof document !== 'undefined' && document.getElementById(group.id.toString());

    if (!element) return;

    const yCoordinate = element?.offsetTop - yOffset + compensation;

    if (isThisChromeVersionSupported()) {
      scrollTo({ top: yCoordinate });
    }
  }, [compensation, groupList, yOffset]);

  useEffect(() => {
    scrollToItemBasedOnUrl();

    const scrollBody = typeof document !== 'undefined' && document.getElementById('product-scroll');

    if (!groupList || !scrollBody) {
      return;
    }

    if (positionOnScreenList && positionOnScreenList?.length > 0 && scrollBody.scrollHeight === productScrollHeight) {
      return;
    }

    const positionList = groupList.map((group) => {
      let yCoordinate = 0;
      const element = typeof document !== 'undefined' && document.getElementById(group.id.toString());

      if (element) {
        yCoordinate = element?.offsetTop - logoHeaderOffset - yOffset;
      }

      return {
        yCoordinate,
        id: group.id,
        name: group.name
      };
    });

    setPositionOnScreenList(positionList);
    setProductScrollHeight(scrollBody.scrollHeight);
  }, [groupList, logoHeaderOffset, positionOnScreenList, productScrollHeight, scrollToItemBasedOnUrl, yOffset]);

  const handleSelect = (value: string): void => {
    setSelected(value);

    const element = typeof document !== 'undefined' && document.getElementById(value);

    if (positionOnScreenList && element) {
      const group = positionOnScreenList.find((item) => item.id.toString() === value);
      const yCoordinate = group?.yCoordinate ? group.yCoordinate : 0;
      const top = yCoordinate + logoHeaderOffset;

      if (isThisChromeVersionSupported()) {
        scrollTo({ top, behavior: 'smooth' });
      }
    }
  };

  return (
    <S.Container isScrolled={isScrolled} data-test="btn-categories">
      <div ref={ref}>
        {groupList?.map((group) => (
          <S.Category
            key={group.id}
            id={`category-${group.id}`}
            $active={selected === group.id.toString()}
            onClick={(): void => handleSelect(group.id.toString())}
            className={selected === group.id.toString() ? 'active' : ''}
          >
            <div>{group.name}</div>
          </S.Category>
        ))}
      </div>
    </S.Container>
  );
};

export default MobileCategoryFilter;
