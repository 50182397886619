import React from 'react';

const ArrowRight = ({
  width = 29,
  height = 21,
  color = '#fff'
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        d="M27.6996 11.5044L18.7681 20.4718C18.4811 20.7587 18.1224 20.9022 17.7637 20.9022C17.405 20.9022 17.0463 20.7587 16.7594 20.4718C16.1854 19.8979 16.1854 19.0011 16.7594 18.4272L23.2518 11.9348H2.30392C1.51479 11.9348 0.869141 11.2892 0.869141 10.5001C0.869141 9.71092 1.51479 9.06527 2.30392 9.06527H23.2518L16.7235 2.53701C16.1496 1.9631 16.1496 1.06636 16.7235 0.492446C17.2974 -0.0814665 18.1941 -0.0814665 18.7681 0.492446L27.6996 9.45984C28.2735 10.0337 28.2735 10.9664 27.6996 11.5044Z"
      />
    </svg>
  );
};

export default ArrowRight;
