import styled, { css } from 'styled-components';
import { DebounceInput as DebounceInputComponent, DebounceInputProps } from 'react-debounce-input';

export const Header = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    z-index: 100;

    margin: 0;
    width: 100%;
    max-width: 780px;
    padding: 1rem !important;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    background-color: ${theme.isDarkMode ? theme.colors.gray200 : theme.colors.backgroundColor};

    > div {
      margin: 0;
    }
  `}
`;

export const InputWrapper = styled.div<{ isGoomerColor?: boolean }>`
  ${({ theme, isGoomerColor }) => css`
    z-index: 100;

    height: 2.85rem;
    margin-top: 1rem;
    border-radius: 4px;
    width: 100% !important;
    border: 1px solid ${theme.colors.gray300};

    display: flex;
    align-items: center;
    justify-content: flex-start;

    :focus-within {
      border: 1px solid ${theme.brandColors.primary};
    }

    > .search {
      width: 0.85rem;
      height: 0.85rem;
      margin: 0 1rem 0 0.85rem;

      display: block;

      > path {
        fill: ${isGoomerColor
          ? theme.colors.primary
          : css`
              ${theme.brandColors.primary}
            `};
      }
    }
  `};
`;

export const BackButton = styled.div`
  width: 3.42rem;
  height: 3.42rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const ClearButton = styled.div`
  height: 100%;
  width: 2.85rem;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  > img {
    display: block;
  }
`;

export const DebounceInput = styled(DebounceInputComponent).attrs(
  (props: DebounceInputProps<HTMLInputElement, {}>) => props
)`
  margin: 0;
  border: none;
  width: calc(100% - 5.57rem);

  display: flex;
  justify-content: space-between;

  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;

  cursor: text;
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholderGray};
  }
`;
