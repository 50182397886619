import styled from 'styled-components';

import { Modal } from '~/components';
import { ModalProps } from '~/components/Modal/Modal';

export const Container = Modal
  ? styled(Modal)`
      .container {
        padding-bottom: 0;

        .content {
          min-height: calc(100% - 50px);

          display: flex;
          flex-direction: column;
        }
      }
    `
  : styled.div<ModalProps>``;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;

export const Content = styled.div`
  padding: 1.5rem 0 1rem;

  > .description {
    margin-bottom: 1rem;

    line-height: 150%;
    font-size: 0.75rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray500};
  }
`;

export const OrderDetails = styled.div`
  position: relative;

  padding: 0 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .subtext,
  .subtext > span {
    font-weight: 600;
    line-height: 150%;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.mediumGray};
  }

  .order-id {
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.textColor};
  }

  .subtext {
    font-weight: 700;

    > span {
      font-weight: 500;
    }
  }
`;

export const InfoWrapper = styled.div`
  padding: 1rem 1rem 0;
`;

export const PaymentWrapper = styled.div`
  padding: 0 1rem;
`;

export const Address = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;

  text-align: left;
`;

export const SummaryWrapper = styled.div`
  margin-top: -1rem;

  > ul {
    margin: 1rem;
  }

  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const Footer = styled.footer`
  position: relative;

  height: 100%;
  padding: 1.25rem 1rem;

  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;

  background: ${({ theme }) => theme.colors.gray200};

  .question {
    margin-bottom: 0.75rem;

    font-weight: 500;
    line-height: 150%;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.gray500};
  }

  button {
    & + button {
      margin-top: 0.75rem;
    }
  }
`;
