import { api } from '~/services/api';

export default async function fetchApiGoHealth(): Promise<number> {
  const apiPath = `${process.env.NEXT_PUBLIC_API_GOOMERGO_HEALTH}`;

  const response = await api.get(apiPath, {
    validateStatus: () => true
  });

  return response.status;
}
