import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { Group } from '~/interfaces';
import { isThisChromeVersionSupported, scrollTo } from '~/utils';

import * as S from './styles';

interface PositionOnScreenList {
  id: number;
  name: string;
  yCoordinate: number;
}

interface CategoryFilterProps {
  groupList: Group[];
  hasThirkyAds?: boolean;
  thirkyMediaHeight?: number;
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({ groupList, hasThirkyAds = false, thirkyMediaHeight = 0 }) => {
  const categoryTitleSize = 25;

  const [logoHeaderOffset] = useState<number>(48);
  const [selected, setSelected] = useState<string>('');
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [productScrollHeight, setProductScrollHeight] = useState<number>(0);
  const [positionOnScreenList, setPositionOnScreenList] = useState<PositionOnScreenList[]>([]);

  const yOffset = useMemo(() => 125 + (hasThirkyAds ? thirkyMediaHeight : 0), [hasThirkyAds, thirkyMediaHeight]);
  const compensation = useMemo(
    () => 30 + (hasThirkyAds ? thirkyMediaHeight / 2 : 0),
    [hasThirkyAds, thirkyMediaHeight]
  );

  useScrollPosition(({ currPos }) => {
    if (positionOnScreenList.length > 0) {
      const currentPos = Math.abs(currPos.y);
      const findCurrentAnchor = positionOnScreenList.reduce((prev, curr) => {
        if (curr.yCoordinate > currentPos) {
          return prev;
        }

        return Math.abs(curr.yCoordinate - currentPos) < Math.abs(prev.yCoordinate - currentPos) ? curr : prev;
      });

      setSelected(findCurrentAnchor.id.toString());
    }

    if (currPos.y < (logoHeaderOffset + yOffset) * -1) {
      return setIsScrolled(true);
    }

    return setIsScrolled(false);
  });

  const scrollToItem = useCallback(() => {
    if (groupList.length === 0) return;

    const group = groupList?.find((group) => group.id && window.location.href.includes(`${group.id.toString()}`));

    if (!group?.id) return;

    setSelected(group.id.toString());

    const element = typeof document !== 'undefined' && document.getElementById(group.id.toString());

    if (!element) return;

    const yCoordinate = element?.offsetTop - logoHeaderOffset - yOffset - categoryTitleSize + compensation;

    if (isThisChromeVersionSupported()) {
      scrollTo({ top: yCoordinate });
    }
  }, [compensation, groupList, logoHeaderOffset, yOffset]);

  useEffect(() => {
    scrollToItem();

    const scrollBody = typeof document !== 'undefined' && document.getElementById('product-scroll');

    if (!groupList || !scrollBody) {
      return;
    }

    if (positionOnScreenList && positionOnScreenList?.length > 0 && scrollBody.scrollHeight === productScrollHeight) {
      return;
    }

    const positionList = groupList.map((group) => {
      let yCoordinate = 0;
      const element = typeof document !== 'undefined' && document.getElementById(group.id.toString());

      if (element) {
        yCoordinate = element?.offsetTop - logoHeaderOffset - yOffset - categoryTitleSize + compensation;
      }

      return {
        yCoordinate,
        id: group.id,
        name: group.name
      };
    });

    setPositionOnScreenList(positionList);
    setProductScrollHeight(scrollBody.scrollHeight);
  }, [compensation, groupList, logoHeaderOffset, positionOnScreenList, productScrollHeight, scrollToItem, yOffset]);

  const handleSelect: (value: string) => void = (value) => {
    setSelected(value);
    const element = typeof document !== 'undefined' && document.getElementById(value);

    if (positionOnScreenList && element) {
      const group = positionOnScreenList.find((item) => item.id.toString() === value);
      const yCoordinate = group?.yCoordinate ? group.yCoordinate : 0;
      const top = yCoordinate + compensation;
      window.location.hash = value;

      if (isThisChromeVersionSupported()) {
        scrollTo({ top, behavior: 'smooth' });
      }
    }
  };

  if (!groupList || groupList.length === 0) {
    return <></>;
  }

  return (
    <S.ContainerDesktop isScrolled={isScrolled}>
      <select
        id="categories"
        value={selected}
        name="categories"
        data-test="btn-categories"
        onChange={(event): void => handleSelect(event.target.value)}
      >
        {groupList.map((group) => (
          <option value={group.id.toString()} key={group.id}>
            {group.name}
          </option>
        ))}
      </select>
    </S.ContainerDesktop>
  );
};

export default CategoryFilter;
