import React from 'react';

import { LoaderIcon } from '~/assets';

import * as S from './styles';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isGhost?: boolean;
  isDanger?: boolean;
  isHidden?: boolean;
  isSimple?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Icon?: React.ReactNode;
  isGoomerColor?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Icon,
  children,
  isGhost = true,
  isDanger = false,
  isSimple = false,
  isHidden = false,
  isLoading = false,
  isDisabled = false,
  isGoomerColor = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = (): void => {},
  ...rest
}) => {
  const hasIcon = !!Icon;

  return (
    <S.Container
      type="button"
      isGhost={isGhost}
      onClick={onClick}
      hasIcon={hasIcon}
      isDanger={isDanger}
      isSimple={isSimple}
      isHidden={isHidden}
      isLoading={isLoading}
      isDisabled={isDisabled}
      className="button-container"
      isGoomerColor={isGoomerColor}
      {...rest}
    >
      <span>
        {isLoading && (
          <S.IconWrapper className="loader-icon icon">
            <LoaderIcon width={32} height={32} aria-label="Imagem de carregamento" />
          </S.IconWrapper>
        )}

        {hasIcon && !isLoading && (
          <S.IconWrapper className="custom-icon icon" aria-label="Ícone ilustrativo">
            {Icon}
          </S.IconWrapper>
        )}

        {children}
      </span>
    </S.Container>
  );
};

export default Button;
