import { api } from '~/services/api';
import { CancelOrderProps } from '~/interfaces/general';

export default async function cancelWaitingPaymentOrder({ orderId, paymentDate }: CancelOrderProps) {
  const apiPath = `${process.env.NEXT_PUBLIC_API_GOOMERGO_BASE_URL}/payments/pix/abandon`;

  const hasAuthorizationHeader =
    !!api.defaults.headers.Authorization &&
    api.defaults.headers.Authorization !== 'undefined' &&
    api.defaults.headers.Authorization !== '';

  const headersAuthorization = hasAuthorizationHeader ? api.defaults.headers.Authorization : '';

  try {
    const response = await api.post(apiPath, {
      order_id: orderId,
      payment_date: paymentDate,
      headers: {
        Authorization: headersAuthorization
      }
    });

    return response;
  } catch (error) {
    throw new Error(`Post error: ${error}`);
  }
}
