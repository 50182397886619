import { useSelector } from 'react-redux';
import ReactSwitch, { ReactSwitchProps } from 'react-switch';

import { IApplicationState } from '~/redux-tools/store';

interface SwitchProps extends ReactSwitchProps {
  checked: boolean;
  onColor?: string;
}

const Switch: (props: SwitchProps) => JSX.Element = ({ onColor, checked, ...rest }) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  return (
    <ReactSwitch
      width={40}
      height={22}
      checked={checked}
      checkedIcon={false}
      handleDiameter={18}
      uncheckedIcon={false}
      offColor={theme.colors.gray400}
      onColor={
        onColor ||
        (theme.isDarkMode && !theme.isLightBrandColor ? theme.colors.placeholderGray : theme.brandColors.primary)
      }
      {...rest}
    />
  );
};

export default Switch;
