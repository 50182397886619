/* eslint-disable no-case-declarations */
import { Reducer } from 'redux';

import { mountCartOptionalTree } from '~/utils';
import { CartProduct, CartOptional } from '~/interfaces/general';

import { RESET_STORE } from '..';
import { CartState, CartActionTypes } from './types';

const ORDER_VERSION = process.env.NEXT_PUBLIC_ORDER_VERSION || 2;

const initialState = {
  total: 0,
  products: [],
  establishment: '',
  lastUpdate: undefined,
  hasNewProductOnBag: false,
  order_version: Number(ORDER_VERSION),
  values: {
    total: 0,
    coupon: 0,
    loyalty: 0,
    subtotal: 0,
    service_tax: 0,
    delivery_fee: 0,
    takeaway_discount: 0
  }
} as CartState;

interface ChangeProductQuantityPayload {
  productId: string;
  quantity: number;
  comboId: string;
}

interface UpdateProductQuantityProps {
  comboId?: string;
  newQuantity: number;
  productIndex?: number;
  productList: CartProduct[];
}

const CartReducer: Reducer<CartState> = (state = initialState, action) => {
  const { type, payload } = action;

  function sortOptionals(payloadWithOptionals: CartProduct) {
    const priceOptionalList: CartOptional[] = [];
    const productOptionalList: CartOptional[] = [];

    function separatePriceAndProductOptionals(optionalList: CartOptional[]): void {
      optionalList.forEach((optionalItem) => {
        if (optionalItem.elementFlowId?.startsWith(String(payloadWithOptionals.product.id))) {
          const [, ...partList] = optionalItem.elementFlowId.split('|');
          const elementFlowId = partList.join('|');

          priceOptionalList.push({ ...optionalItem, elementFlowId });
          return;
        }

        productOptionalList.push(optionalItem);
      });
    }

    const organizedOptionalList = payloadWithOptionals?.optionals?.sort(
      (previousItem: CartOptional, currentItem: CartOptional) => {
        if (typeof previousItem.indexPosition === 'undefined' || typeof currentItem.indexPosition === 'undefined') {
          return 0;
        }

        if (previousItem.indexPosition > currentItem.indexPosition) return 1;
        if (previousItem.indexPosition < currentItem.indexPosition) return -1;
        return 0;
      }
    );

    separatePriceAndProductOptionals(organizedOptionalList || []);

    const priceOptionalTreeList = mountCartOptionalTree(priceOptionalList);
    const productOptionalTreeList = mountCartOptionalTree(productOptionalList);

    const organizedPayload = {
      ...payloadWithOptionals,
      optionals: organizedOptionalList,
      optionalTree: [...priceOptionalTreeList, ...productOptionalTreeList]
    };

    return organizedPayload;
  }

  switch (type) {
    case CartActionTypes.ADD_PRODUCT:
      const organizedPayload = sortOptionals(payload);

      return {
        ...state,
        lastUpdate: new Date(),
        establishment: state.establishment,
        products: [...state.products, organizedPayload],
        total: state.total + (payload.subtotal as number)
      };

    case CartActionTypes.EDIT_PRODUCT:
      const editedProductList = state.products.map((product) => {
        if (product.id === payload.id) {
          const organizedProduct = sortOptionals(payload);

          return organizedProduct;
        }

        const organizedProduct = sortOptionals(product);

        return organizedProduct;
      });

      const newTotal = editedProductList.reduce((prev: number, next: CartProduct) => prev + next.subtotal, 0);

      return {
        ...state,
        total: newTotal,
        lastUpdate: new Date(),
        products: editedProductList
      };

    case CartActionTypes.REMOVE_PRODUCT:
      if (payload.comboId) {
        const comboProductsList = state.products.filter((product) => product.comboId === payload.comboId);

        const totalWithoutComboValue = comboProductsList.reduce(
          (prev: number, next: CartProduct) => prev - next.subtotal,
          state.total
        );

        return {
          ...state,
          lastUpdate: new Date(),
          total: totalWithoutComboValue,
          establishment: state.establishment,
          products: state.products ? state.products.filter((orderItem) => orderItem.comboId !== payload.comboId) : []
        };
      }

      return {
        ...state,
        lastUpdate: new Date(),
        establishment: state.establishment,
        total: state.total - payload.subtotal,
        products: state.products ? state.products.filter((orderItem) => orderItem.id !== payload.id) : []
      };

    case CartActionTypes.REMOVE_COMBO:
      const currentProductList = state.products || [];
      const comboProductList = currentProductList.filter((product) => product.comboId === payload);

      const totalWithouComboValue = comboProductList.reduce(
        (prev: number, next: CartProduct) => prev - next.subtotal,
        state.total
      );

      return {
        ...state,
        lastUpdate: new Date(),
        total: totalWithouComboValue,
        establishment: state.establishment,
        products: state.products ? state.products.filter((orderItem) => orderItem.comboId !== payload) : []
      };

    case CartActionTypes.SET_ESTABLISHMENT:
      return {
        ...state,
        establishment: payload,
        lastUpdate: new Date()
      };

    case CartActionTypes.SET_USER_ADDRESS:
      return {
        ...state,
        address: payload,
        lastUpdate: new Date()
      };

    case CartActionTypes.REMOVE_USER_ADDRESS:
      return {
        ...state,
        address: undefined,
        lastUpdate: new Date()
      };

    case CartActionTypes.SET_ADDRESS_LAT_LNG:
      return {
        ...state,
        lastUpdate: new Date(),
        address: {
          ...state.address,
          latitude: payload.latitude,
          longitude: payload.longitude
        }
      };

    case CartActionTypes.SET_DELIVERY_FEE:
      return {
        ...state,
        deliveryFee: payload,
        lastUpdate: new Date()
      };

    case CartActionTypes.REMOVE_DELIVERY_FEE:
      return {
        ...state,
        deliveryFee: undefined,
        lastUpdate: new Date()
      };

    case CartActionTypes.SET_NEW_PRODUCT_ON_BAG:
      return {
        ...state,
        lastUpdate: new Date(),
        hasNewProductOnBag: payload
      };

    case CartActionTypes.SET_CART_VALUES:
      return {
        ...state,
        lastUpdate: new Date(),
        values: {
          ...state.values,
          ...payload
        }
      };

    case CartActionTypes.SET_PREVENT_PAGE_RELOAD:
      return {
        ...state,
        lastUpdate: new Date(),
        preventPageReload: payload
      };

    case CartActionTypes.CHANGE_PRODUCT_QUANTITY:
      const { productId, quantity, comboId } = payload as ChangeProductQuantityPayload;

      const { products: productsList } = state;

      const calculateSubtotal = (products: CartProduct[]): number => {
        return products.reduce((total, product) => total + product.subtotal, 0);
      };

      const getUpdatedProduct = (productToUpdate: CartProduct, quantityToUpdate: number): CartProduct => ({
        ...productToUpdate,
        quantity: quantityToUpdate,
        product: {
          ...productToUpdate.product,
          quantity: quantityToUpdate
        },
        subtotal: (productToUpdate.subtotal / productToUpdate.quantity) * quantityToUpdate
      });

      const updateProductQuantity = ({
        comboId,
        newQuantity,
        productList,
        productIndex
      }: UpdateProductQuantityProps): CartProduct[] => {
        if (comboId) {
          return productList.map((product) => {
            if (product.comboId !== comboId) return product;

            return getUpdatedProduct(product, newQuantity);
          });
        }

        const productsToUpdateList = [...productList];
        if (productIndex !== undefined) {
          productsToUpdateList[productIndex] = getUpdatedProduct(productsToUpdateList[productIndex], newQuantity);
        }

        return productsToUpdateList;
      };

      const getUpdatedState = (newProductList: CartProduct[]): CartState => ({
        ...state,
        lastUpdate: new Date(),
        products: newProductList,
        total: calculateSubtotal(newProductList),
        values: {
          ...state.values,
          subtotal: calculateSubtotal(newProductList)
        }
      });

      const productIndex = productsList.findIndex((product) => product.id === productId);

      const updatedProductsList = updateProductQuantity({
        comboId,
        productIndex,
        newQuantity: quantity,
        productList: productsList
      });

      return getUpdatedState(updatedProductsList);

    case RESET_STORE:
    case CartActionTypes.CLEAN_CART:
      return initialState;

    default:
      return state;
  }
};

export default CartReducer;
