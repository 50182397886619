/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';
import { isMobileSafari } from 'react-device-detect';

import Modal, { ModalProps } from '~/components/Modal/Modal';
import { CustomPwaArrowIcon, InstallPwaIllustration } from '~/assets';

export const Container = Modal
  ? styled(Modal)`
      ${({ theme }) => css`
        .container {
          height: 95%;
          max-height: 580px;

          background: ${theme.colors.lightestGray};

          .content {
            height: 100%;
            border-radius: 4px 4px 0 0;
          }
        }

        .times-btn {
          svg {
            path {
              fill: ${theme.isLightBrandColor && !theme.isDarkMode
                ? theme.colors.backgroundColor
                : theme.brandColors.actionText};
            }
          }
        }

        .content {
          height: 100%;
        }
      `}
    `
  : styled.div<ModalProps>``;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 1.5rem 1rem;

    h2 {
      margin-bottom: 0.5rem;

      text-align: left;
      font-weight: bold;
      line-height: 130%;
      line-height: 1.5rem;
      color: ${theme.colors.textColor};
    }

    p {
      text-align: left;
      font-weight: 500;
      line-height: 1.5rem;
      font-size: 0.875rem;
      color: ${theme.colors.darkGray};

      strong {
        font-weight: 700;
      }
    }
  `}
`;

const customArrowModifiers = {
  show: () => css`
    right: 20px;

    margin-top: 0;

    visibility: visible;
    opacity: 1;
  `,
  type: {
    others: () => css`
      right: 8px;
      bottom: 20px;
      transform: rotate(-45deg);
    `,
    safari: () => css`
      left: 50%;
      bottom: 20px;
      transform: translateX(-50%) rotate(-20deg);
    `
  }
};

export const CustomArrow = styled(CustomPwaArrowIcon)`
  ${({ $show, $hasActions }) => css`
    position: absolute;
    top: unset;
    right: -10px;
    bottom: 132px;

    opacity: 0;
    transition: 0.3s;
    visibility: hidden;

    ${!$hasActions ? (isMobileSafari ? customArrowModifiers.type.safari : customArrowModifiers.type.others) : ''}

    ${$show && customArrowModifiers.show}

    @media screen and (max-width: 375px) {
      bottom: 10px;

      width: 25px;
    }

    @media screen and (max-width: 320px) {
      bottom: 140px;
    }
  `}
`;

interface ActionsProps {
  show: boolean;
  remove: boolean;
}

const actionsModifiers = {
  remove: () => css`
    display: none;
  `,
  show: () => css`
    transform: translateY(0);

    opacity: 1;
  `
};

export const Actions = styled.div<ActionsProps>`
  ${({ show, remove }) => css`
    position: fixed;
    bottom: 10px;
    transform: translateY(15px);

    width: calc(100% - 32px);

    opacity: 0;
    transition: 0.5s;

    ${show && actionsModifiers.show}
    ${remove && actionsModifiers.remove}

    button {
      &:last-child {
        margin-top: 12px;
      }
    }
  `}
`;

interface IllustrationProps {
  color?: string;
  $isFullPage: boolean;
}

export const Illustration = styled((props: IllustrationProps) => <InstallPwaIllustration {...props} />).attrs(
  ({ theme, $isFullPage }) => ({
    color: theme.brandColors.primary,
    viewBox: `0 0 320 ${$isFullPage ? 208 : 180}`
  })
)``;
