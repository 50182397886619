import { Reducer } from 'redux';

import { MountedOrderIntegratedPix } from '~/interfaces/general';

import { IPixPaymentState, PixPaymentActionTypes } from './types';

const initialState = {
  loading: false,
  email: undefined,
  sendEmail: undefined,
  orderTime: undefined,
  tabPayment: undefined,
  order: {} as MountedOrderIntegratedPix
};

const PixPaymentReducer: Reducer<IPixPaymentState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PixPaymentActionTypes.ADD_ORDER_TIME:
      return {
        ...state,
        orderTime: payload
      };

    case PixPaymentActionTypes.ADD_PIX_ORDER:
      return {
        ...state,
        order: payload
      };

    case PixPaymentActionTypes.CLEAN_ORDER_TIME:
      return {
        ...state,
        orderTime: undefined
      };

    case PixPaymentActionTypes.CLEAN_PIX_ORDER:
      return {
        ...state,
        order: {} as MountedOrderIntegratedPix
      };

    case PixPaymentActionTypes.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        email: payload.email,
        sendEmail: payload.sendEmail
      };

    case PixPaymentActionTypes.CLEAN_ALL_PIX:
      return initialState;

    case PixPaymentActionTypes.CANCEL_PIX_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case PixPaymentActionTypes.CANCEL_PIX_ORDER_SUCCESS:
      return initialState;

    case PixPaymentActionTypes.CANCEL_PIX_ORDER_ERROR:
      return {
        ...state,
        loading: false
      };

    case PixPaymentActionTypes.ADD_PIX_TAB_PAYMENT:
      return {
        ...state,
        tabPayment: payload
      };

    case PixPaymentActionTypes.CLEAN_PIX_TAB_PAYMENT:
      return {
        ...state,
        tabPayment: undefined
      };

    default:
      return state;
  }
};

export default PixPaymentReducer;
