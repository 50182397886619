import styled, { css, DefaultTheme } from 'styled-components';

const actionButtonModifiers = {
  disabled: (theme: DefaultTheme) => css`
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${theme.colors.gray400};

    svg {
      color: ${theme.isDarkMode ? '#575F72' : theme.colors.gray500};
    }
  `
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionButton = styled.button`
  ${({ theme, disabled }) => css`
    height: 3rem;
    border: none;
    width: 2.5rem;
    border-radius: 0.25rem;
    padding: 0.75rem 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    font-size: 3rem;
    user-select: none;
    color: ${theme.colors.backgroundColor};
    background-color: ${theme.isDarkMode ? theme.colors.pureTextColor : theme.colors.gray600};

    ${disabled && actionButtonModifiers.disabled(theme)}
  `}
`;

export const CounterLabel = styled.span`
  width: 2.5rem;

  font-size: 1rem;
  font-weight: bold;
  text-align: center;
`;
