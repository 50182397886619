import React, { useMemo, useCallback, useRef } from 'react';

import Link from 'next/link';
import { useSelector } from 'react-redux';
import { RiTimeLine } from 'react-icons/ri';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { showToast } from '~/utils';
import { useTranslator } from '~/hooks';
import { ToastTypeEnum } from '~/interfaces/enums';
import { Alert, Button, NewWaves } from '~/components';
import { IApplicationState } from '~/redux-tools/store';
import useCopyToClipboard from '~/hooks/useCopyToClipboard';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';

import * as S from './styles';

export interface PixPaymentProps {
  title: string;
  pixKey: string;
  loading: boolean;
  countdown?: string;
  qrCodePixKey?: string;
  text: React.ReactNode;
  isCancelled?: boolean;
  isTabPayment?: boolean;
  isQrCodeVisible: boolean;
  handleCancel: () => void;
  showCancelButton: boolean;
  shouldDisplayPixImage?: boolean;
  setIsQrCodeVisible: (value: boolean) => void;
}

const PixPayment = ({
  text,
  title,
  pixKey,
  loading,
  countdown,
  handleCancel,
  qrCodePixKey,
  isQrCodeVisible,
  showCancelButton,
  setIsQrCodeVisible,
  isCancelled = false,
  isTabPayment = false,
  shouldDisplayPixImage = true
}: PixPaymentProps): JSX.Element => {
  const pixKeyRef = useRef<HTMLTextAreaElement>(null);

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { getTranslation } = useTranslator();
  const { copyToClipboard } = useCopyToClipboard();

  const isOrderStatusEnabled = useMemo(
    () => settings?.mm_order_status_check_enabled,
    [settings?.mm_order_status_check_enabled]
  );

  const handleCopyPixCode = (): void => {
    let copyEvent = null;

    if (isOrderStatusEnabled) {
      copyEvent = gaEvents.orderStatusCopyPixCode;
    } else {
      copyEvent = gaEvents.whatsAppCopyPixCode;
    }

    GoogleAnalytics.trackEvent(copyEvent);

    copyToClipboard({ showToast: false, inputElement: pixKeyRef.current });
    showToast({ type: 'success', text: getTranslation('payment.pix.codeCopied') });
  };

  const handleShowQrCode = useCallback(() => {
    let qrCodeEvent = null;

    if (!isQrCodeVisible) {
      if (isOrderStatusEnabled) {
        qrCodeEvent = gaEvents.orderStatusShowQrCode;
      } else {
        qrCodeEvent = gaEvents.whatsAppShowQrCode;
      }
    }

    if (qrCodeEvent !== null) {
      GoogleAnalytics.trackEvent(qrCodeEvent);
    }

    setIsQrCodeVisible(!isQrCodeVisible);
  }, [isOrderStatusEnabled, isQrCodeVisible, setIsQrCodeVisible]);

  if (isCancelled) {
    return (
      <S.WavesWrapper>
        <S.Container>
          {!!shouldDisplayPixImage && <LazyLoadImage src="/assets/error.svg" width={100} height={100} />}

          <S.Title>{title}</S.Title>

          <S.RegularText>{text}</S.RegularText>

          {!!pixKey && (
            <S.PixCodeWrapper disabled>
              <S.PixCode disabled rows={1} defaultValue={pixKey} isCancelled={isCancelled} />
            </S.PixCodeWrapper>
          )}

          <S.TextWithMarginWrapper style={{ marginTop: '0.25rem' }}>
            <S.RegularText isError>
              {getTranslation('payment.pix.codeExpired')}. {!isTabPayment && getTranslation('order.tryAgain')}
            </S.RegularText>
          </S.TextWithMarginWrapper>

          <S.TextWithMarginWrapper>
            {isTabPayment ? (
              <Button onClick={handleCancel} isGhost={false}>
                {getTranslation('navigation.returnToMyTab')}
              </Button>
            ) : (
              <Link href={'/menu'}>
                <Button isGhost={false}>{getTranslation('navigation.returnToMenu')}</Button>
              </Link>
            )}
          </S.TextWithMarginWrapper>
        </S.Container>

        <NewWaves />
      </S.WavesWrapper>
    );
  }

  return (
    <S.WavesWrapper>
      <S.Container>
        <LazyLoadImage src="/assets/waiting.svg" width={100} height={100} />

        <S.Title>{title}</S.Title>

        <S.RegularText>{text}</S.RegularText>

        {isQrCodeVisible && !!qrCodePixKey ? (
          <S.QRCodeWrapper>
            <LazyLoadImage alt={getTranslation('payment.qrCodePaymentCode')} src={qrCodePixKey} />
          </S.QRCodeWrapper>
        ) : (
          <>
            <S.PixCodeWrapper onClick={handleCopyPixCode}>
              <S.PixCode readOnly rows={1} ref={pixKeyRef} defaultValue={pixKey} />
            </S.PixCodeWrapper>

            <Button isGhost={false} onClick={handleCopyPixCode}>
              {getTranslation('general.copyCode')}
            </Button>
          </>
        )}

        {!!qrCodePixKey && (
          <Button isSimple onClick={handleShowQrCode}>
            <S.RegularText asLink>
              {isQrCodeVisible ? getTranslation('payment.showPixCopyAndPaste') : getTranslation('payment.showQrCode')}
            </S.RegularText>
          </Button>
        )}

        {!!countdown && (
          <S.Countdown>
            <RiTimeLine size={20} />

            <p>
              {getTranslation('payment.pix.codeExpiresIn')} <strong>{countdown}</strong>
            </p>
          </S.Countdown>
        )}

        {!isTabPayment && (
          <S.TextWithMarginWrapper>
            <Alert type={ToastTypeEnum.ghost} message={getTranslation('payment.autoCancelOrderMessage')} />
          </S.TextWithMarginWrapper>
        )}

        {showCancelButton && (
          <S.CancelWrapper>
            <Button isSimple>
              {loading ? (
                <S.Loader />
              ) : (
                <S.RegularText onClick={handleCancel} color={theme.colors.error}>
                  {isTabPayment ? getTranslation('general.cancelPayment') : getTranslation('order.cancelOrder')}
                </S.RegularText>
              )}
            </Button>
          </S.CancelWrapper>
        )}
      </S.Container>

      <NewWaves />
    </S.WavesWrapper>
  );
};

export default PixPayment;
