import styled, { css } from 'styled-components';
import { DebounceInput as DebounceInputComponent, DebounceInputProps } from 'react-debounce-input';

export const Content = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const FormWrapper = styled.div<{ hasSmallPadding: boolean }>`
  ${({ hasSmallPadding }) => css`
    padding: 1rem;

    ${hasSmallPadding &&
    css`
      padding-top: 8px;
    `}

    display: flex;
    flex-wrap: wrap;
    align-content: start;
  `};
`;

export const Footer = styled.div`
  padding: 1rem;
  margin-top: auto;
`;

const sizes = {
  small: '40%',
  medium: '60%',
  large: '100%'
};

interface FieldWrapperProps {
  hasRightPadding?: boolean;
  size?: 'large' | 'medium' | 'small';
}

export const FieldWrapper = styled.div<FieldWrapperProps>`
  ${({ theme, size = 'large', hasRightPadding }) => css`
    width: ${sizes[size]};
    height: min-content;
    margin-bottom: 20px;

    ${hasRightPadding &&
    css`
      padding-right: 12px;
    `}

    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 4px;

      display: block;

      font-weight: 600;
      text-align: left;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.textColor};
    }

    input {
      width: 100%;
      height: 39px;
      padding: 0 16px;
      border-radius: 4px;
      border: 1px solid ${theme.colors.gray300};
      background-color: ${theme.colors.backgroundColor};

      &:disabled {
        background-color: ${theme.colors.gray200};
      }

      &::placeholder {
        color: ${({ theme }) => theme.colors.placeholderGray};
      }
    }

    > span {
      font-size: 12px;
      color: ${theme.colors.error};
    }

    > .city-list {
      width: 100%;
      max-height: 80px;
      box-shadow: 0px 6px 10px rgba(48, 51, 60, 0.1);

      cursor: pointer;
      transition: 0.3s;
      background-color: ${theme.colors.backgroundColor};

      &:hover {
        opacity: 1;
        pointer-events: all;
      }
    }

    &:focus-within {
      > .city-list {
        opacity: 1;
        transition: 0.3s;
        pointer-events: all;
      }
    }
  `};
`;

export const DebounceInput = styled(DebounceInputComponent).attrs(
  (props: DebounceInputProps<HTMLInputElement, {}>) => props
)`
  width: 100%;
  margin: 0;
  border: none;

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.textColor};

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholderGray};
  }
`;

export const CityOption = styled.p`
  width: 100%;
  padding: 0 16px;
  min-height: 36px;

  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  font-style: normal;
  color: ${({ theme }) => theme.colors.textColor};
  background: ${({ theme }) => theme.colors.backgroundColor};
`;
