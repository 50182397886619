import { addDays, subDays } from 'date-fns';

import { CookieNameEnum } from '~/interfaces/enums';

interface CookiesProps {
  domain?: string;
  name: CookieNameEnum;
}

interface SetCookiesProps extends CookiesProps {
  value: string;
  expirationDays?: number;
}

function getCookie({ name }: CookiesProps): string {
  let cookieValue = '';
  const cookieNameEquals = name + '=';
  const cookieList = (typeof document !== 'undefined' && document.cookie.split(';')) || [];

  cookieList.forEach((cookieItem) => {
    let actualCookie = cookieItem;

    while (actualCookie.startsWith(' ')) {
      actualCookie = actualCookie.substring(1, actualCookie.length);
    }

    if (actualCookie.startsWith(cookieNameEquals)) {
      cookieValue = actualCookie.substring(cookieNameEquals.length, actualCookie.length);
    }
  });

  return cookieValue;
}

function deleteCookie({ name, domain }: CookiesProps): void {
  const cookieValue = `${name}=;`;
  const cookieExpirationDate = `Expires=${subDays(new Date(), 10).toUTCString()};`;
  const cookieDomain = domain ? `Domain=${domain}; ` : '';

  const cookieFormattedString = `${cookieValue} ${cookieExpirationDate} ${cookieDomain}`;

  document.cookie = cookieFormattedString;
}

function setCookie({ name, value, domain, expirationDays }: SetCookiesProps): void {
  const cookieValue = `${name}=${value};`;
  const cookieExpirationDate = expirationDays ? `Expires=${addDays(new Date(), expirationDays).toUTCString()};` : '';
  const cookieDomain = domain ? `Domain=${domain}; ` : '';

  const cookieFormattedString = `${cookieValue} ${cookieExpirationDate} ${cookieDomain}`;

  document.cookie = cookieFormattedString;
}

const Cookies = {
  getCookie,
  setCookie,
  deleteCookie
};

export default Cookies;
