/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';

import { toast } from 'react-toastify';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { Tuna } from '~/services/index';
import { BrazilDocumentType, MercadoPago } from '~/utils/mercadoPago';
import { TunaResponseCode, CreditCardTunaErrorCodes } from '~/interfaces/enums';
import { ICard, CardTypeEnum, ICardForm, TunaBaseResponse } from '~/interfaces/general';

import useTranslator from '../useTranslator';

interface UseGetOnlinePaymentTokenProps {
  card: ICardForm;
  errorAction?: () => void;
  isAuthenticated?: boolean;
  authenticationToken?: string;
  action: (card: ICard) => void;
  isTunaPaymentEnabled: boolean;
  setCvvModalConfirmed?: () => void;
  setIsLoading?: (value: boolean) => void;
}

interface UseGetOnlinePaymentTokenReturnProps {
  getOnlinePaymentToken: (props: UseGetOnlinePaymentTokenProps) => Promise<void>;
}

interface MercadoPagoPaymentMethodsBins {
  name: string;
  type: CardTypeEnum;
  paymentMethodId: string;
  bin: { exclusion_pattern?: string; installments_pattern?: string; pattern: string };
}

interface ErrorCauses {
  code: string;
  description: string;
}

interface ErrorHandling {
  errorAction?: () => void;
  setIsLoading?: (value: boolean) => void;
}

interface HandleCreateTokenErrorsProps {
  cause: ErrorCauses[];
  getTranslation: (path: string) => string;
}

export default function useGetOnlinePaymentToken(): UseGetOnlinePaymentTokenReturnProps {
  const { getTranslation } = useTranslator();

  const getOnlinePaymentToken = useCallback(
    async ({
      card,
      action,
      errorAction,
      setIsLoading,
      isAuthenticated,
      authenticationToken,
      isTunaPaymentEnabled
    }: UseGetOnlinePaymentTokenProps) => {
      const MercadoPagoSDK = MercadoPago;

      const getMercadoPagoPaymentMethods = (): any[] => MercadoPagoSDK?.getPaymentMethods();

      const getMercadoPagoPaymentMethodsBins = (): MercadoPagoPaymentMethodsBins[] =>
        getMercadoPagoPaymentMethods().flatMap((paymentMethod) =>
          paymentMethod.settings.map((setting: any) => ({
            bin: setting.bin,
            name: paymentMethod.name,
            paymentMethodId: paymentMethod.id,
            type: paymentMethod.payment_type_id === 'credit_card' ? CardTypeEnum.credit : CardTypeEnum.debit
          }))
        ) as MercadoPagoPaymentMethodsBins[];

      const findCardType = (card: string): MercadoPagoPaymentMethodsBins | undefined =>
        getMercadoPagoPaymentMethodsBins().find((paymentMethod) => {
          const match = RegExp(paymentMethod.bin.pattern).test(card);

          const exception = paymentMethod.bin.exclusion_pattern
            ? RegExp(paymentMethod.bin.exclusion_pattern).test(card)
            : false;

          return match && !exception;
        });

      const handleErrors = ({ errorAction, setIsLoading }: ErrorHandling): void => {
        if (setIsLoading) {
          setIsLoading(false);
        }

        if (errorAction) {
          errorAction();
        }
      };

      const handleCreateTokenErrors = ({ cause, getTranslation }: HandleCreateTokenErrorsProps): void => {
        const errorMessages: Record<string, string> = {
          [CreditCardTunaErrorCodes.securityCode]: getTranslation('errorMessage.checkCvvCardCode'),
          [CreditCardTunaErrorCodes.cardDataMissing]: getTranslation('errorMessage.checkCardDetails'),
          [CreditCardTunaErrorCodes.invalidExpirationDate]: getTranslation('errorMessage.checkExpiryDate'),
          [CreditCardTunaErrorCodes.invalidCardNumberCode]: getTranslation('errorMessage.checkTheCardNumber'),
          [CreditCardTunaErrorCodes.invalidCardNumberMessage]: getTranslation('errorMessage.checkTheCardNumber')
        };

        const defaultMessage = getTranslation('errorMessage.checkCardDetailsContactStore');
        const message = errorMessages[cause[0]?.code] || defaultMessage;

        toast.error(message);
      };

      const maskCreditCardNumber = (cardNumber: string): string => {
        const number = cardNumber.replace(/\D/g, '') || '';
        const cardSuffix = number.slice(number.length - 4);

        return ` **** ${cardSuffix}`;
      };

      const number = card?.number.replace(/\D/g, '') || '';
      const cvv = card.cvv?.replace(/\D/g, '');
      const document = card.document?.replace(/\D/g, '');

      const { due, name } = card;

      if (isTunaPaymentEnabled) {
        const manageTunaCallError = (response: TunaBaseResponse): void => {
          handleErrors({ errorAction, setIsLoading });

          if (response.message) {
            handleCreateTokenErrors({
              getTranslation,
              cause: [{ code: response.message, description: response.message }]
            });
          }
        };

        const manageTunaCallExceptions = (): void => {
          handleErrors({ errorAction, setIsLoading });
        };

        try {
          const tuna = await Tuna(authenticationToken);

          if (card.token) {
            const bindResponse = await tuna.tokenizator().bind(card.token, cvv);
            if (bindResponse.code === TunaResponseCode.Ok) {
              const newCard: ICard = {
                due,
                name,
                document,
                email: card.email,
                token: card.token,
                number: card.number,
                type: CardTypeEnum.credit,
                mpMethodId: card.mpMethodId,
                flagDescription: PaymentCategoryEnum.tunaCheckout
              };

              action(newCard);
            } else {
              manageTunaCallError(bindResponse);
              return;
            }
          } else {
            const expirationMonth = parseInt(due?.split('/')[0]);
            const expirationYear = parseInt(due?.split('/')[1].padStart(4, '20'));

            const generateResponse = await tuna.tokenizator().generate({
              cvv,
              expirationYear,
              expirationMonth,
              cardNumber: number,
              cardHolderName: name,
              singleUse: !isAuthenticated,
              data: { document, email: card.email }
            });

            if (generateResponse.code === TunaResponseCode.Ok && generateResponse.token) {
              const newCard: ICard = {
                due,
                name,
                document,
                email: card.email,
                type: CardTypeEnum.credit,
                token: generateResponse.token,
                mpMethodId: generateResponse.brand,
                number: maskCreditCardNumber(number),
                flagDescription: PaymentCategoryEnum.tunaCheckout
              };

              action(newCard);
            } else {
              manageTunaCallError(generateResponse);
              return;
            }
          }
        } catch (e) {
          toast.error(getTranslation('cardEntry.invalidData'));
          manageTunaCallExceptions();
        }
      } else {
        const cardType = findCardType(number);

        if (!cardType) {
          handleErrors({ errorAction, setIsLoading });
          return;
        }

        MercadoPagoSDK.createToken(
          {
            securityCode: cvv,
            cardNumber: number,
            docNumber: document,
            cardholderName: name,
            cardExpirationYear: due?.split('/')[1],
            cardExpirationMonth: due?.split('/')[0],
            docType:
              document.length > 11
                ? (getTranslation('general.cnpj') as BrazilDocumentType)
                : (getTranslation('general.cpf') as BrazilDocumentType)
          },
          (status: number, response: any) => {
            if (status !== 200) {
              handleErrors({ errorAction, setIsLoading });

              if (status === 400 && response?.cause?.length > 0) {
                handleCreateTokenErrors({ getTranslation, cause: response.cause });
              }

              if (errorAction) {
                errorAction();
              }
              return;
            }

            const token = response?.id;

            const newCard: ICard = {
              due,
              name,
              token,
              number,
              document,
              email: card.email,
              type: cardType.type,
              mpMethodId: cardType.paymentMethodId,
              flagDescription: PaymentCategoryEnum.mPagoCheckout
            };

            action(newCard);
          }
        );
      }
    },
    [getTranslation]
  );

  return { getOnlinePaymentToken };
}
