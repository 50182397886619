import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { RiCloseLine, RiArrowLeftLine } from 'react-icons/ri';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { useTranslator } from '~/hooks';
import { ItemCard } from '~/components/Modal/Modal';
import { PaymentMethod } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { CheckoutContext as OrderContext } from '~/pages/order';
import { ToastTypeEnum, PaymentFlagEnum } from '~/interfaces/enums';
import { CheckoutContext as LocalOrderContext } from '~/pages/localOrder';
import { convertToCurrency, scrollToElement, validateCPF } from '~/utils';
import { CheckoutContext as MyTabContext } from '~/pages/myTabPaymentDetails';
import { OptionHeader, InputCurrency, Button, InputCpfOrCnpj, Alert } from '~/components';

import { HowItWorks } from '..';

import * as S from './styles';

interface SelectedMethodProps {
  title: string;
  isShow?: boolean;
  isMyTab?: boolean;
  openRight: boolean;
  method?: PaymentMethod;
  onConfirm: (accountDocument: string) => void;
  goBack: (selectedMethod: PaymentMethod) => void;
}

const SelectedMethod: React.FC<SelectedMethodProps> = ({
  title,
  method,
  goBack,
  isMyTab,
  openRight,
  onConfirm,
  isShow = false
}) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const localOrder = useSelector((state: IApplicationState) => state.localOrders.mode);

  const currentContext = useMemo(() => {
    if (isMyTab) {
      return MyTabContext;
    }

    return localOrder ? LocalOrderContext : OrderContext;
  }, [isMyTab, localOrder]);

  const { cpf, discount, discountType, setMoneyChange, moneyChangeValue, setMoneyChangeValue } =
    useContext(currentContext);

  const totalValue = useSelector((state: IApplicationState) => state.cart.values.total);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const userPaymentPreferenceList = useSelector((state: IApplicationState) => state.user.data.paymentPreference);

  const [accountDocument, setAccountDocument] = useState<string>(cpf);
  const [shouldDisplayHelp, setShouldDisplayHelp] = useState<boolean>(false);
  const [isMoneyChangeError, setIsMoneyChangeError] = useState<boolean>(false);
  const [moneyChangeValueState, setMoneyChangeValueState] = useState<number>(moneyChangeValue ? moneyChangeValue : 0);

  const { getTranslation } = useTranslator();

  const paymentMethod = useMemo(() => {
    if (!method && settings?.name && Array.isArray(userPaymentPreferenceList)) {
      const userEstablishmentPreferences = userPaymentPreferenceList?.find(
        (payment) => payment.establishment === settings?.name
      );

      return userEstablishmentPreferences;
    }

    return method;
  }, [method, userPaymentPreferenceList, settings]);

  const methodTip = useMemo(() => {
    if (method?.flag === getTranslation(PaymentFlagEnum.MercadoPagoQrCode))
      return {
        description: getTranslation('payment.scanQrCodeMessage')
      };

    if (method?.category === PaymentCategoryEnum.mPagoLink || method?.category === PaymentCategoryEnum.vrPagueLink)
      return {
        description: getTranslation('payment.paymentLinkViaWhatsappMessage')
      };

    return { description: '' };
  }, [getTranslation, method?.category, method?.flag]);

  const totalPriceWithDiscount = useMemo(() => {
    if (discount) {
      if (discountType === 'percent') {
        return totalValue * (1 - discount.value);
      }

      return totalValue - discount.value;
    }

    return totalValue;
  }, [discount, totalValue, discountType]);

  const isHelpAvailable = useMemo(() => {
    return method?.category === PaymentCategoryEnum.mPagoLink || method?.category === PaymentCategoryEnum.mPagoQr;
  }, [method]);

  const isMoneyChangeValid = useMemo(() => {
    if (moneyChangeValueState === 0) {
      return true;
    }

    return moneyChangeValueState > totalPriceWithDiscount;
  }, [moneyChangeValueState, totalPriceWithDiscount]);

  const isValidAccountDocument = useMemo(() => {
    const pureValue = accountDocument.replace(/\D/g, '');

    return !!accountDocument && validateCPF({ cpf: pureValue });
  }, [accountDocument]);

  const isConfirmDisabled = useMemo(() => {
    if (method?.category === PaymentCategoryEnum.nupay) {
      return !shouldDisplayHelp && !isValidAccountDocument;
    }

    return false;
  }, [shouldDisplayHelp, isValidAccountDocument, method]);

  const isAbleToConfirm = useMemo(() => isMoneyChangeValid, [isMoneyChangeValid]);

  const handleGoBack = useCallback(
    (event = false) => {
      setShouldDisplayHelp(false);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (event?.type === 'click') {
        return goBack({});
      }

      if (isAbleToConfirm) {
        if (
          (method?.category === PaymentCategoryEnum.credit ||
            method?.category === PaymentCategoryEnum.debit ||
            method?.category === PaymentCategoryEnum.voucher) &&
          !!method?.flag
        ) {
          return goBack({ category: method?.category });
        }
        return goBack({});
      }

      return false;
    },
    [isAbleToConfirm, goBack, method]
  );

  const handleConfirm = useCallback(() => {
    setMoneyChangeValue(moneyChangeValueState);

    if (shouldDisplayHelp) {
      return setShouldDisplayHelp(false);
    }

    GoogleAnalytics.trackEvent(gaEvents.checkoutPaymentSelected);

    onConfirm(accountDocument);

    return setTimeout(() => {
      scrollToElement({ isSmooth: false, element: '[data-name="payment-header"]' });
    }, 0);
  }, [accountDocument, moneyChangeValueState, onConfirm, setMoneyChangeValue, shouldDisplayHelp]);

  return (
    <S.Container
      isSmall
      ignoreOverflow
      isShow={isShow}
      openRight={openRight}
      onClose={(event): void => {
        shouldDisplayHelp ? setShouldDisplayHelp(!shouldDisplayHelp) : handleGoBack(event);
      }}
    >
      <h5 className="title">
        {!shouldDisplayHelp && (
          <RiArrowLeftLine
            size={24}
            color={theme.colors.textColor}
            style={{ marginRight: '8px' }}
            onClick={(event): void => {
              shouldDisplayHelp ? setShouldDisplayHelp(!shouldDisplayHelp) : handleGoBack(event);
            }}
          />
        )}

        {shouldDisplayHelp ? getTranslation('general.howDoesItWork') : title}

        {isHelpAvailable ||
          (shouldDisplayHelp && (
            <>
              {!shouldDisplayHelp ? (
                <Button onClick={(): void => setShouldDisplayHelp(!shouldDisplayHelp)} isSimple>
                  {getTranslation('general.help')}
                </Button>
              ) : (
                <RiCloseLine
                  size={24}
                  color={theme.colors.textColor}
                  style={{ marginLeft: 'auto' }}
                  onClick={(event): void => {
                    shouldDisplayHelp ? setShouldDisplayHelp(!shouldDisplayHelp) : handleGoBack(event);
                  }}
                />
              )}
            </>
          ))}
      </h5>

      <HowItWorks method={method} isContentVisible={shouldDisplayHelp} />

      <S.Content isContentVisible={!shouldDisplayHelp}>
        <S.Top>
          {paymentMethod && (
            <>
              {method?.category === PaymentCategoryEnum.nupay && (
                <p>
                  {getTranslation('payment.nuPayBillingMessage')}{' '}
                  <span onClick={(): void => setShouldDisplayHelp(!shouldDisplayHelp)}>
                    {getTranslation('general.howDoesItWork')}
                  </span>
                </p>
              )}

              <ItemCard tip={methodTip.description} method={paymentMethod} onClick={handleGoBack} />
            </>
          )}
        </S.Top>

        {method?.category === PaymentCategoryEnum.cash && (
          <div className="group -cash">
            <OptionHeader isForm title={getTranslation('payment.askForChange')} isRequired isDone isSimple />

            <small>{getTranslation('order.orderTotalValue', { value: convertToCurrency(totalValue) })}</small>

            <div className="field">
              <InputCurrency
                required={false}
                dataTest="input-money-change"
                value={String(moneyChangeValueState)}
                onBlur={(): void => setIsMoneyChangeError(!isMoneyChangeValid)}
                onChange={({ value }): void => {
                  setMoneyChange(true);
                  setMoneyChangeValueState(value);
                }}
              />

              {isMoneyChangeError && <S.Error>{getTranslation('order.minimumValueNotMet')}</S.Error>}
            </div>
          </div>
        )}

        {method?.category === PaymentCategoryEnum.nupay && (
          <div className="group -cpf">
            <OptionHeader isForm title="CPF" isRequired isDone={isValidAccountDocument} />

            <InputCpfOrCnpj
              isOnlyCpf
              required={false}
              dataTest="input-cpf-cnpj"
              defaultValue={accountDocument}
              onChange={(cpfCnpjValue: string): void => setAccountDocument(cpfCnpjValue)}
            />

            <Alert type={ToastTypeEnum.ghost} message={getTranslation('payment.nupay.requirements')} />
          </div>
        )}
      </S.Content>

      <S.ButtonWrapper>
        <Button isGhost={false} isDisabled={isConfirmDisabled} onClick={handleConfirm} data-test="btn-confirm-payment">
          {shouldDisplayHelp ? getTranslation('general.okayGotIt') : getTranslation('general.confirm')}
        </Button>
      </S.ButtonWrapper>
    </S.Container>
  );
};

export default SelectedMethod;
