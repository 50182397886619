import React from 'react';

import { RiCheckboxCircleLine, RiCloseCircleLine, RiInformationLine, RiErrorWarningLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { ToastTypeEnum } from '~/interfaces/enums';

import * as S from './styles';

export interface AlertProps {
  type: ToastTypeEnum;
  message: React.ReactNode;
  customIcon?: React.ReactNode;
}

const Alert = ({ message, customIcon, type = ToastTypeEnum.info }: AlertProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  const icons = {
    [ToastTypeEnum.info]: <RiInformationLine size={24} aria-label={getTranslation('alert.info')} />,
    [ToastTypeEnum.ghost]: <RiInformationLine size={24} aria-label={getTranslation('alert.info')} />,
    [ToastTypeEnum.error]: <RiCloseCircleLine size={24} aria-label={getTranslation('alert.error')} />,
    [ToastTypeEnum.blank]: <RiInformationLine size={24} aria-label={getTranslation('alert.default')} />,
    [ToastTypeEnum.warning]: <RiErrorWarningLine size={24} aria-label={getTranslation('alert.warning')} />,
    [ToastTypeEnum.success]: <RiCheckboxCircleLine size={24} aria-label={getTranslation('alert.success')} />
  };

  return (
    <S.Container type={type}>
      {customIcon ? <S.CustomIcon>{customIcon}</S.CustomIcon> : <div className="icon">{icons[type]}</div>}

      <p>{message}</p>
    </S.Container>
  );
};

export default Alert;
