import React from 'react';

const LoyaltyStar = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1691 10.4291L17.9194 7.74776C18.1408 7.5321 18.2973 7.25879 18.3714 6.95876C18.4455 6.65873 18.4341 6.34395 18.3385 6.05007C18.2429 5.75618 18.067 5.49491 17.8306 5.29582C17.5943 5.09674 17.3069 4.96779 17.001 4.92357L13.2003 4.37174L11.5005 0.922796C11.3637 0.645799 11.1523 0.412576 10.8899 0.2495C10.6276 0.0864244 10.3248 0 10.0159 0C9.70703 0 9.4043 0.0864244 9.14195 0.2495C8.87961 0.412576 8.66812 0.645799 8.53141 0.922796L6.83157 4.37174L3.02985 4.92357C2.7242 4.96814 2.4371 5.09728 2.20097 5.2964C1.96484 5.49553 1.78909 5.75671 1.69357 6.05045C1.59805 6.34419 1.58656 6.65879 1.6604 6.95871C1.73424 7.25864 1.89047 7.53194 2.11145 7.74776L4.86173 10.4291L4.21235 14.215C4.15929 14.5197 4.19274 14.8332 4.30891 15.1198C4.42509 15.4064 4.61932 15.6547 4.86955 15.8365C5.11977 16.0183 5.41596 16.1262 5.72447 16.1481C6.03297 16.17 6.34142 16.1049 6.61478 15.9602L10.0145 14.1727L13.4141 15.9602C13.6525 16.0854 13.9175 16.1513 14.1867 16.1523C14.4277 16.1516 14.6656 16.0984 14.8839 15.9964C15.1022 15.8943 15.2957 15.746 15.4508 15.5616C15.6059 15.3772 15.719 15.1612 15.7822 14.9286C15.8454 14.6961 15.8571 14.4526 15.8166 14.215L15.1691 10.4291ZM6.51525 10.702C6.56082 10.4375 6.54129 10.1659 6.45837 9.91056C6.37544 9.65526 6.23161 9.42399 6.0393 9.23672L3.30971 6.57611L7.08187 6.02822C7.34762 5.98961 7.59998 5.8869 7.81716 5.72896C8.03434 5.57101 8.20981 5.36257 8.32841 5.12164L10.0154 1.70423L11.7025 5.12164C11.8214 5.36249 11.9971 5.57083 12.2144 5.72875C12.4318 5.88667 12.6842 5.98944 12.95 6.02822L16.7212 6.57611L13.9926 9.23672C13.8001 9.42394 13.6562 9.65517 13.5731 9.91046C13.49 10.1657 13.4703 10.4374 13.5156 10.702L14.1601 14.4594L10.7831 12.6857C10.5454 12.5608 10.281 12.4955 10.0125 12.4955C9.744 12.4955 9.47955 12.5608 9.24189 12.6857L5.86882 14.4594L6.51525 10.702Z"
        fill="#485460"
      />
      <path
        d="M19.9228 11.7535C19.881 11.6659 19.8222 11.5875 19.7497 11.5229C19.6773 11.4582 19.5928 11.4087 19.501 11.377L17.6987 10.7493C17.561 10.7025 17.4124 10.6973 17.2718 10.7345C17.1311 10.7717 17.0046 10.8495 16.908 10.9583C16.8114 11.0671 16.749 11.202 16.7288 11.3461C16.7085 11.4902 16.7312 11.637 16.7941 11.7683C16.8359 11.856 16.8949 11.9345 16.9675 11.9991C17.0401 12.0637 17.1249 12.1132 17.2168 12.1447L19.0182 12.7724C19.1561 12.8204 19.3052 12.8264 19.4466 12.7897C19.588 12.753 19.7153 12.6752 19.8126 12.5662C19.9098 12.4572 19.9725 12.3218 19.9928 12.1771C20.0131 12.0325 19.9901 11.8851 19.9267 11.7535H19.9228Z"
        fill="#485460"
      />
      <path
        d="M10.0154 15.8755C9.81951 15.8757 9.63167 15.9537 9.49313 16.0922C9.35459 16.2308 9.27664 16.4186 9.27638 16.6145V18.4799C9.27638 18.6759 9.35424 18.8639 9.49284 19.0025C9.63145 19.1411 9.81943 19.219 10.0154 19.219C10.2114 19.219 10.3994 19.1411 10.538 19.0025C10.6766 18.8639 10.7545 18.6759 10.7545 18.4799V16.6145C10.7545 16.4185 10.6766 16.2305 10.538 16.0919C10.3994 15.9533 10.2114 15.8755 10.0154 15.8755Z"
        fill="#485460"
      />
      <path
        d="M14.232 3.19123C14.3909 3.30594 14.5889 3.35284 14.7824 3.32161C14.9758 3.29038 15.149 3.18358 15.2637 3.02469L16.3142 1.56924C16.4256 1.4102 16.4699 1.2137 16.4376 1.02223C16.4053 0.830755 16.299 0.659691 16.1415 0.546019C15.9841 0.432347 15.7882 0.385201 15.5963 0.414772C15.4044 0.444344 15.2318 0.548257 15.1159 0.704048L14.0655 2.1595C13.951 2.3185 13.9043 2.51638 13.9355 2.70979C13.9667 2.90319 14.0733 3.07632 14.232 3.19123Z"
        fill="#485460"
      />
      <path
        d="M2.29769 10.7493L0.496351 11.377C0.404655 11.4089 0.320141 11.4586 0.247631 11.5231C0.175122 11.5877 0.116039 11.6659 0.0737618 11.7533C0.0314845 11.8407 0.00683899 11.9355 0.00123319 12.0324C-0.00437261 12.1294 0.00917153 12.2264 0.0410912 12.3181C0.0729608 12.4098 0.122603 12.4943 0.187174 12.5668C0.251745 12.6392 0.329979 12.6983 0.417393 12.7405C0.504807 12.7827 0.599686 12.8072 0.696598 12.8127C0.793509 12.8182 0.890549 12.8045 0.982162 12.7724L2.78448 12.1447C2.96949 12.0801 3.12128 11.9447 3.20646 11.7682C3.29165 11.5917 3.30327 11.3887 3.23876 11.2036C3.17395 11.0188 3.0385 10.8672 2.8621 10.782C2.6857 10.6968 2.48273 10.6851 2.29769 10.7493Z"
        fill="#485460"
      />
      <path
        d="M4.63903 3.02467C4.70772 3.11955 4.79792 3.19679 4.90224 3.25006C5.00655 3.30333 5.12201 3.33112 5.23915 3.33114C5.37516 3.33094 5.50849 3.29321 5.62444 3.2221C5.74039 3.15099 5.83447 3.04926 5.89631 2.92812C5.95816 2.80698 5.98537 2.67111 5.97496 2.5355C5.96455 2.39988 5.91691 2.26976 5.83729 2.15948L4.78684 0.704026C4.66923 0.552992 4.49761 0.453481 4.30811 0.426444C4.1186 0.399407 3.926 0.446952 3.77084 0.559071C3.61569 0.67119 3.51009 0.839135 3.47628 1.02755C3.44247 1.21596 3.48308 1.41015 3.58956 1.56922L4.63903 3.02467Z"
        fill="#485460"
      />
    </svg>
  );
};

export default LoyaltyStar;
