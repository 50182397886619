import React from 'react';

interface CloseIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const CloseIcon = ({ width = 12, height = 20, color = '#7F8F9F' }: CloseIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 18L2 10L10 2" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CloseIcon;
