import React, { useEffect, useMemo } from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { formatFullDate } from '~/utils';
import { CheckOrder, Modal } from '~/components';
import { IApplicationState } from '~/redux-tools/store';
import { useHandlePaymentTexts, useShowOrdersCount, useTranslator } from '~/hooks';

import { OrderHeader } from './styles';

interface CartResumeModalProps {
  isShow: boolean;
  onClose: () => void;
}

const CartResumeModal: React.FC<CartResumeModalProps> = ({ isShow, onClose }) => {
  const history = useRouter();

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { cpf, name } = useSelector((state: IApplicationState) => state.user.data);
  const order = useSelector((state: IApplicationState) => state.localOrders.order);
  const { order: lastOrder, cart: lastOrderCart } = useSelector((state: IApplicationState) => state.lastOrder.order);

  const { getTranslation } = useTranslator();
  const { showOrdersCount } = useShowOrdersCount();
  const { handlePaymentTexts } = useHandlePaymentTexts();

  useEffect(() => {
    if (lastOrderCart?.products.length === 0) {
      history.push('/menu');
    }
  }, [lastOrderCart, history]);

  const getPayment = useMemo(() => {
    if (!order) return '';

    const [type, flag] = order.payment.split(' ');

    const formattedFlag = flag ? handlePaymentTexts(flag) : '';
    const formattedType = type ? handlePaymentTexts(type) : '';

    return `${formattedType} ${formattedFlag}`;
  }, [handlePaymentTexts, order]);

  return (
    <Modal
      isSmall
      isGhostFooter
      isShow={isShow}
      onClose={onClose}
      onFooterClick={onClose}
      buttonSvgColor={theme.colors.pureTextColor}
      footerTitle={getTranslation('general.close')}
      headerTitle={getTranslation('general.orderDetails')}
    >
      <OrderHeader>
        <div className="info">
          {showOrdersCount && (
            <strong>
              {getTranslation('general.order')} #{String(order?.id).padStart(4, '0')}
            </strong>
          )}
          {order?.createdAt && <small>{formatFullDate(order?.createdAt)} </small>}
        </div>

        {name && <p className="name">{name}</p>}

        <p className="text">{getPayment}</p>
        {cpf && (
          <p className="text sentry-mask">
            {getTranslation('general.documentInOrder', {
              document: cpf.length > 14 ? getTranslation('general.cnpj') : getTranslation('general.cpf')
            })}{' '}
            {cpf}
          </p>
        )}
      </OrderHeader>

      <CheckOrder.CheckOrder
        hideZeroedOrderMessage
        coupon={lastOrder?.coupon}
        validateCouponError={false}
        total={lastOrderCart?.total}
        loyaltyDiscount={lastOrderCart?.values?.loyalty}
      >
        {lastOrderCart?.products.map((product, index) => (
          <CheckOrder.CheckOrderItem key={index} noActions item={product} getTranslation={getTranslation} />
        ))}
      </CheckOrder.CheckOrder>
    </Modal>
  );
};

export default CartResumeModal;
