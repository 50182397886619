import React, { useEffect, useRef, useMemo } from 'react';

import lottie from 'lottie-web';
import { format } from 'date-fns';
import currency from 'currency.js';

import { convertToCurrency } from '~/utils';
import { useFormatCreditCard, useTranslator } from '~/hooks';
import { PaymentMethod, PaymentReceipt } from '~/interfaces/general';
import ErrorAnimation from '~/assets/checkout/animations/payment-error.json';
import { TableManagerPaymentEnum, TunaPaymentMode } from '~/interfaces/enums';
import SuccessAnimation from '~/assets/checkout/animations/payment-success.json';
import { PaymentSummaryProgressBar } from '~/components/pages/MyTabOnlinePayment';

import * as S from './styles';

export interface ReceiptInfoProps {
  mode: string;
  hasError: boolean;
  isVisible: boolean;
  payment?: PaymentMethod;
  receipt?: PaymentReceipt;
  showProgressBar?: boolean;
}

const ReceiptInfo = ({
  mode,
  receipt,
  payment,
  hasError,
  isVisible,
  showProgressBar
}: ReceiptInfoProps): JSX.Element => {
  const animationContainer = useRef<HTMLDivElement>(null);

  const { getTranslation } = useTranslator();
  const { formatCreditCard } = useFormatCreditCard();

  const totalPaid: number = useMemo(() => {
    if (!receipt?.total) {
      return 0;
    }

    if (!receipt.remaining) {
      return receipt.total;
    }

    return currency(receipt.total).subtract(receipt.remaining).value;
  }, [receipt]);

  const infoTexts: {
    title: string;
    message: JSX.Element;
    animationLabel: string;
  } = useMemo(() => {
    if (hasError || !receipt) {
      return {
        title: getTranslation('receiptInfo.paymentNotMade'),
        message: <p>{getTranslation('payment.pleaseReviewYourData')}</p>,
        animationLabel: getTranslation('receiptInfo.paymentErrorAnimation')
      };
    }

    const renderPaymentDate: () => JSX.Element = () => {
      if (!receipt.date) {
        return <></>;
      }

      return (
        <p>
          {getTranslation('receiptInfo.paymentMadeOn')}
          <span className="date">{format(new Date(receipt.date), 'dd/MM/yyyy - HH:mm')}</span>
        </p>
      );
    };

    return {
      message: renderPaymentDate(),
      title: getTranslation('receiptInfo.successPayment'),
      animationLabel: getTranslation('receiptInfo.paymentSuccessAnimation')
    };
  }, [getTranslation, hasError, receipt]);

  const modeDescription: string = useMemo(() => {
    const descriptions: Record<string, string> = {
      [TunaPaymentMode.card]: getTranslation('payment.creditCard'),
      [TableManagerPaymentEnum.Cash]: getTranslation('payment.money'),
      [TableManagerPaymentEnum.Debit]: getTranslation('payment.debit'),
      [TableManagerPaymentEnum.Credit]: getTranslation('payment.credit'),
      [TableManagerPaymentEnum.Voucher]: getTranslation('payment.mealVoucher'),
      [TunaPaymentMode.pix || TableManagerPaymentEnum.Pix]: getTranslation('payment.methods.pix')
    };

    if (receipt) {
      return descriptions[receipt.mode] || '';
    }

    return '';
  }, [getTranslation, receipt]);

  const renderOverview: JSX.Element = useMemo(() => {
    if (!receipt) {
      return <></>;
    }

    const { tax, tab, paid, total, table, remaining } = receipt;

    return (
      <S.Overview>
        {!!tab && (
          <div className="item">
            <p>{getTranslation('general.tab')}:</p>
            <p>{tab}</p>
          </div>
        )}

        {!!table && (
          <div className="item">
            <p>{getTranslation('general.table')}:</p>
            <p>{table}</p>
          </div>
        )}

        {!!modeDescription && (
          <div className="item">
            <p>{getTranslation('general.type')}:</p>
            <p>{modeDescription}</p>
          </div>
        )}

        <div className="item total">
          <p>
            {getTranslation('receiptInfo.total')} {mode}
          </p>
          <p>{convertToCurrency(Number(total))}</p>
        </div>

        {!!tax && !showProgressBar && (
          <div className="item paid">
            <p>{getTranslation('general.serviceTax')}</p>
            <p>{convertToCurrency(Number(tax))}</p>
          </div>
        )}

        <div className="item paid">
          <p>{getTranslation('receiptInfo.totalPaid')}</p>
          <p>{convertToCurrency(Number(paid))}</p>
        </div>

        {showProgressBar && <PaymentSummaryProgressBar missingValue={remaining || 0} valuePaid={totalPaid} />}
      </S.Overview>
    );
  }, [getTranslation, mode, modeDescription, receipt, showProgressBar, totalPaid]);

  useEffect(() => {
    if (animationContainer.current && isVisible) {
      const animationConfig = {
        loop: false,
        autoplay: true,
        container: animationContainer.current
      };

      lottie.destroy();

      lottie.loadAnimation({
        ...animationConfig,
        renderer: 'svg',
        animationData: hasError ? ErrorAnimation : SuccessAnimation
      });
    }
  }, [hasError, isVisible]);

  return (
    <S.Container>
      <S.Info $hasError={hasError}>
        <div
          className="animation"
          ref={animationContainer}
          aria-label={infoTexts.animationLabel}
          title={getTranslation('receiptInfo.paymentStatusAnimation')}
        />

        <h3>{infoTexts.title}</h3>

        {infoTexts.message}
      </S.Info>

      {hasError && payment && payment?.cardDescription && (
        <S.Tag className="sentry-mask">
          {formatCreditCard({
            flag: payment?.flag,
            card: payment?.cardDescription,
            isCreditCardNumberHidden: false
          })}
        </S.Tag>
      )}

      {!hasError && renderOverview}
    </S.Container>
  );
};

export default ReceiptInfo;
