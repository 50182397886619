import styled, { css } from 'styled-components';

export const Container = styled.div<{ $isOnCart?: boolean; $displaySimpleLayout?: boolean }>`
  ${({ theme, $isOnCart, $displaySimpleLayout }) => css`
    width: 100%;
    padding: 1.5rem;
    background: ${theme.colors.backgroundColor};

    ${$displaySimpleLayout &&
    css`
      border-radius: 4px;
      border: 1px solid ${theme.colors.gray300};
    `}

    ${$isOnCart &&
    css`
      padding: 0;
    `}

    display: flex;
    justify-content: space-between;

    button {
      height: auto !important;
    }
  `}
`;

export const TabInfo = styled.div`
  height: min-content;

  gap: 0.5rem;
  display: flex;
  align-items: center;

  > p {
    font-size: 1rem;
    font-weight: 600;
    line-height: 120%;
  }
`;
