import { FieldOptionsEnum } from '~/interfaces/general';

function isRequiredField(field?: FieldOptionsEnum) {
  if (!field) return true;

  if (field === FieldOptionsEnum.optional) return false;
  if (field === FieldOptionsEnum.noRequired) return false;

  return true;
}

export default isRequiredField;
