import { useTranslator } from '~/hooks';

import * as S from './styles';

interface WalletBannerProps {
  method: 'google' | 'apple';
}

const WalletBanner = ({ method }: WalletBannerProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  const content = {
    apple: {
      description: getTranslation('payment.applePayInfo'),
      iconPath: '/assets/checkout/apple-pay-checkout-banner.svg'
    },
    google: {
      description: getTranslation('payment.googlePayInfo'),
      iconPath: '/assets/checkout/google-pay-checkout-banner.svg'
    }
  };

  const { iconPath, description } = content[method];

  return (
    <S.Container>
      <img src={iconPath} alt={`${method} banner`} />

      <S.Description dangerouslySetInnerHTML={{ __html: description }} />
    </S.Container>
  );
};

export default WalletBanner;
