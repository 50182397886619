import { useSelector } from 'react-redux';
import { RiStarFill, RiForbid2Line, RiEyeFill } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { ProductHighlightTagTypeEnum } from '~/interfaces/enums';

import * as S from './styles';

interface ProductHighlightTagProps {
  isAbsolute?: boolean;
  isFixedToTop?: boolean;
  type: ProductHighlightTagTypeEnum;
}

const ProductHighlightTag = ({ type, isAbsolute, isFixedToTop }: ProductHighlightTagProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  const typeModifiers = {
    viewOnly: {
      description: 'general.viewOnly',
      backgroundColor: theme.isDarkMode ? theme.colors.gray100 : theme.colors.gray500,
      icon: (
        <RiEyeFill
          aria-label={getTranslation('general.viewOnly')}
          color={theme.isDarkMode ? theme.colors.gray400 : theme.colors.backgroundColor}
        />
      )
    },
    recommended: {
      description: 'general.houseSuggestion',
      backgroundColor: theme.isDarkMode ? theme.colors.quaternary700 : theme.colors.quaternary400,
      icon: <RiStarFill aria-label={getTranslation('general.houseSuggestion')} color={theme.colors.quaternary} />
    },
    unavailable: {
      description: 'general.unavailable',
      backgroundColor: theme.isDarkMode ? theme.colors.gray300 : theme.colors.gray100,
      icon: (
        <RiForbid2Line
          aria-label={getTranslation('general.unavailable')}
          color={theme.isDarkMode ? theme.colors.textColor : theme.colors.gray500}
        />
      )
    }
  };

  return (
    <S.Container
      $isAbsolute={isAbsolute}
      $isFixedToTop={isFixedToTop}
      aria-label={typeModifiers[type].description}
      $backgroundColor={typeModifiers[type].backgroundColor}
    >
      {typeModifiers[type].icon}

      <S.Text type={type}>{getTranslation(typeModifiers[type].description)}</S.Text>
    </S.Container>
  );
};

export default ProductHighlightTag;
