/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0 16px;

    .container {
      #content {
        height: 100%;
      }

      footer {
        border-top: 1px solid ${theme.colors.lightGray};
      }
    }

    > p {
      font-weight: 500;
      text-align: left;
      line-height: 150%;
      font-size: 0.75rem;
      color: ${theme.colors.gray500};

      button {
        border: none;

        font-size: 12px;
        font-weight: 500;
        text-align: left;
        line-height: 150%;
        background: transparent;
        text-decoration: underline;
        color: ${theme.isDarkMode ? theme.colors.pureTextColor : theme.brandColors.primary};
      }
    }
  `}
`;

export const TermsTextContainer = styled.div`
  height: 100%;
  position: relative;
  padding-bottom: 80vh;

  @media (max-width: 1025px) {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;
