import currency from 'currency.js';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { CartValues } from '~/interfaces/general';

import * as S from './styles';

interface OrderSummaryProps {
  values: CartValues;
}

const OrderSummary = ({ values }: OrderSummaryProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  const receivedDiscount = values.coupon || values.loyalty || values.takeaway_discount;

  const subtotalWithDiscount = currency(values.subtotal).subtract(receivedDiscount);

  const summaryItemsList = [
    {
      value: values.subtotal,
      label: 'general.subtotal'
    },
    {
      isDiscount: true,
      value: values.coupon,
      label: 'general.coupon'
    },
    {
      isDiscount: true,
      value: values.loyalty,
      label: 'discount.loyaltyDiscount'
    },
    {
      isDiscount: true,
      value: values.takeaway_discount,
      label: 'general.discountForTakeaway'
    },
    {
      isDiscount: false,
      label: 'general.subtotalWithDiscount',
      value: receivedDiscount ? subtotalWithDiscount.value : null
    },
    {
      value: values.service_tax,
      label: 'general.serviceTax'
    },
    {
      value: values.delivery_fee,
      label: 'general.deliveryTax'
    }
  ];

  const renderSummaryItem = ({
    value,
    label,
    isDiscount
  }: {
    label: string;
    isDiscount?: boolean;
    value: number | null | undefined;
  }): JSX.Element | null => {
    if (!value) return null;

    return (
      <S.SummaryListItem>
        <S.RegularText isDiscount={isDiscount}>{getTranslation(label)}</S.RegularText>

        <S.RegularText isDiscount={isDiscount}>{convertToCurrency(value)}</S.RegularText>
      </S.SummaryListItem>
    );
  };

  return (
    <S.Container>
      {summaryItemsList.map(({ label, value, isDiscount }) => renderSummaryItem({ label, value, isDiscount }))}

      <S.SummaryListItem>
        <S.BoldText>{getTranslation('general.total')}</S.BoldText>

        <S.BoldText>{convertToCurrency(values.total)}</S.BoldText>
      </S.SummaryListItem>
    </S.Container>
  );
};

export default OrderSummary;
