import React, { useCallback, useState, useMemo } from 'react';

import currency from 'currency.js';
import FadeIn from 'react-fade-in';
import { useRouter } from 'next/router';
import { RiEBikeLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { Alert, Button, Modal } from '~/components';
import { OptionPricingTypeEnum } from '~/interfaces';
import { IApplicationState } from '~/redux-tools/store';
import { convertToCurrency, truncateAmount } from '~/utils';
import { CartOptional, CartProduct, ICoupon, CartItem } from '~/interfaces/general';
import useCheckoutIsZeroedOrder from '~/hooks/useCheckout/hooks/useCheckoutIsZeroedOrder';
import { DiscountTypeEnum, LocalOrdersEnum, SegmentTypeEnum, ToastTypeEnum } from '~/interfaces/enums';
import { setCartValues, removeProductToCart, changeProductQuantity } from '~/redux-tools/store/cart/actions';

import { ItemQuantity } from './components';

import * as S from './styles';

export interface CheckOrderProps {
  total: number;
  coupon?: ICoupon;
  isMyTab?: boolean;
  noActions?: boolean;
  hideTotal?: boolean;
  isCheckout?: boolean;
  discountType?: string;
  isWithdrawal?: boolean;
  serviceCharges?: number;
  isFreeDelivery?: boolean;
  loyaltyDiscount?: number;
  deliveryFee?: number | string;
  validateCouponError?: boolean;
  hideZeroedOrderMessage?: boolean;
  hideMinimumPriceDelivery?: boolean;
  discount?: { label: string; value: number };
  isLoyaltyDiscountHigherThanSubtotal?: boolean;
}

export const CheckOrder: React.FC<CheckOrderProps> = ({
  total,
  coupon,
  children,
  discount,
  deliveryFee,
  discountType,
  isWithdrawal,
  isFreeDelivery,
  serviceCharges,
  isMyTab = false,
  noActions = false,
  hideTotal = false,
  isCheckout = false,
  loyaltyDiscount = 0,
  validateCouponError = true,
  hideZeroedOrderMessage = false,
  hideMinimumPriceDelivery = true,
  isLoyaltyDiscountHigherThanSubtotal,
  ...rest
}) => {
  const dispatch = useDispatch();

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const cartTotal = useSelector((state: IApplicationState) => state.cart.total);
  const { error: couponError } = useSelector((state: IApplicationState) => state.coupons);
  const settings = useSelector((state: IApplicationState) => state.establishment?.settings);
  const { mode: localOrderMode } = useSelector((state: IApplicationState) => state.localOrders);

  const totalValue = useMemo(() => currency(total).value, [total]);

  const { getTranslation } = useTranslator();

  const {
    mm_delivery_minimum_value,
    mm_delivery_minimum_value_enabled,
    mm_in_store_service_tax: serviceTax,
    mm_in_store_enable_service_tax: isServiceTaxEnabled
  } = (settings && settings) || {};

  const isMinimumValueReached = useMemo(() => {
    if (!mm_delivery_minimum_value_enabled) return true;

    if (mm_delivery_minimum_value) {
      return cartTotal >= Number(mm_delivery_minimum_value);
    }

    return true;
  }, [cartTotal, mm_delivery_minimum_value, mm_delivery_minimum_value_enabled]);

  const showMinimumValueWarn = useMemo(() => {
    return !localOrderMode.length && !hideMinimumPriceDelivery && !isMinimumValueReached;
  }, [hideMinimumPriceDelivery, isMinimumValueReached, localOrderMode.length]);

  const { isZeroedOrder } = useCheckoutIsZeroedOrder({ isTakeaway: isWithdrawal || false });

  const renderErrorMessage: JSX.Element | undefined = useMemo(() => {
    if (isLoyaltyDiscountHigherThanSubtotal) {
      return (
        <S.ErrorMessageWrapper>
          <span>{getTranslation('order.valueSmallerThanLoyalty', { value: convertToCurrency(totalValue) })}</span>
        </S.ErrorMessageWrapper>
      );
    }

    if (!hideZeroedOrderMessage && isZeroedOrder) {
      return (
        <S.ErrorMessageWrapper>
          <span>{getTranslation('order.zeroedOrderMessage', { value: convertToCurrency(totalValue) })}</span>
        </S.ErrorMessageWrapper>
      );
    }

    return undefined;
  }, [getTranslation, hideZeroedOrderMessage, isLoyaltyDiscountHigherThanSubtotal, isZeroedOrder, totalValue]);

  const calculatedDiscount = useCallback(
    (currentValue: number) => {
      if (discount && discountType === 'percent') {
        const { value } = currency(currentValue).multiply(discount.value);

        return {
          pure: value,
          pretty: convertToCurrency(value)
        };
      }

      return {
        pure: discount?.value || 0,
        pretty: discount?.label || ''
      };
    },
    [discount, discountType]
  );

  const totalPriceWithDiscount = useCallback(
    (currentValue: number) => {
      if (discount && discountType === 'percent') {
        const discountValue = currency(currentValue).multiply(discount.value).value;

        const { value } = currency(currentValue).subtract(discountValue);

        return {
          pure: value,
          pretty: convertToCurrency(value)
        };
      }

      if (!discount)
        return {
          pure: 0,
          pretty: ''
        };

      const { value } = currency(currentValue).subtract(discount.value);

      return {
        pure: value,
        pretty: convertToCurrency(truncateAmount(value))
      };
    },
    [discount, discountType]
  );

  const calculatedDeliveryFee = useCallback(
    (currentValue: number) => {
      if (deliveryFee && !isNaN(Number(deliveryFee))) {
        const { value } = currency(currentValue).add(Number(deliveryFee));

        return {
          pure: value,
          pretty: convertToCurrency(value)
        };
      }

      return {
        pure: 0,
        pretty: ''
      };
    },
    [deliveryFee]
  );

  const calculatedServiceCharges = useCallback(
    (currentValue: number) => {
      if (serviceCharges) {
        return serviceCharges;
      }

      if (!serviceTax) return 0;

      return currency((currentValue * serviceTax) / 100).value;
    },
    [serviceTax, serviceCharges]
  );

  const totalPriceWithServiceCharges = useCallback(
    (currentValue: number) => {
      if (!serviceTax && !serviceCharges)
        return {
          pure: 0,
          pretty: ''
        };

      const { value } = currency(currentValue).add(calculatedServiceCharges(currentValue));

      return {
        pure: value,
        pretty: convertToCurrency(value)
      };
    },
    [calculatedServiceCharges, serviceCharges, serviceTax]
  );

  const totalPriceWithLoyaltyCouponDiscount = useCallback(
    (currentValue: number) => {
      if (loyaltyDiscount) {
        const { value } = currency(currentValue).subtract(loyaltyDiscount);

        if (value > 0) {
          return {
            pure: value,
            pretty: convertToCurrency(value)
          };
        }
      }

      return {
        pure: 0,
        pretty: convertToCurrency(0)
      };
    },
    [loyaltyDiscount]
  );

  const calculatedDiscountToWithdrawWithLoyaltyCoupon = useCallback(
    (currentValue: number) => {
      const totalWithLoyaltyDiscount = totalPriceWithLoyaltyCouponDiscount(currentValue).pure;

      if (discount && !!totalWithLoyaltyDiscount) {
        const totalWithLoyaltyDiscountAndWithdraw = currency(totalWithLoyaltyDiscount).multiply(discount?.value).value;

        if (totalWithLoyaltyDiscountAndWithdraw > 0) {
          return {
            pure: totalWithLoyaltyDiscountAndWithdraw,
            pretty: convertToCurrency(totalWithLoyaltyDiscountAndWithdraw)
          };
        }
      }

      return {
        pure: 0,
        pretty: convertToCurrency(0)
      };
    },
    [discount, totalPriceWithLoyaltyCouponDiscount]
  );

  const showTakeawayDiscount = useMemo(() => isWithdrawal && discount && discount?.value > 0, [discount, isWithdrawal]);

  const showDeliveryTax = useMemo(() => {
    const existDeliveryFee = typeof deliveryFee === 'number' && deliveryFee > 0;

    return !isWithdrawal && existDeliveryFee && !isFreeDelivery;
  }, [deliveryFee, isFreeDelivery, isWithdrawal]);

  const hasServiceCharge = useMemo(() => {
    if (serviceCharges) {
      return true;
    }

    if (!localOrderMode.length || localOrderMode === LocalOrdersEnum.balcony || !isServiceTaxEnabled) return false;

    if (serviceTax && serviceTax <= 0) return false;

    return true;
  }, [isServiceTaxEnabled, localOrderMode, serviceCharges, serviceTax]);

  const showCouponDiscount = useMemo(() => !!coupon && !loyaltyDiscount, [coupon, loyaltyDiscount]);

  const showLoyaltyCouponDiscount = useMemo(() => !!loyaltyDiscount, [loyaltyDiscount]);

  const couponDiscount = useMemo(() => {
    const defaultValue = {
      pure: 0,
      pretty: ''
    };

    if (!coupon || (validateCouponError && !!couponError)) return defaultValue;

    if (coupon.type === DiscountTypeEnum.percent) {
      const discountValue = totalValue * truncateAmount(truncateAmount(Number(coupon.value)) / 100);
      const discountMaxValue = Number(coupon.discount_max_value);

      const finalDiscount = discountValue >= discountMaxValue ? discountMaxValue : discountValue;
      const formattedValue = truncateAmount(finalDiscount);

      return {
        pure: formattedValue,
        pretty: convertToCurrency(formattedValue)
      };
    }

    const value = truncateAmount(Number(coupon.value));

    return {
      pure: value,
      pretty: convertToCurrency(value)
    };
  }, [coupon, couponError, totalValue, validateCouponError]);

  const couponDiscountPure = useMemo(
    () => (loyaltyDiscount === 0 ? truncateAmount(couponDiscount.pure) : 0),
    [couponDiscount.pure, loyaltyDiscount]
  );

  const couponSubtotal = useMemo(() => {
    const truncatedSubtotal = truncateAmount(totalValue);
    const truncatedCoupon = truncateAmount(couponDiscount.pure);

    const finalRoundedValue = Number((truncatedSubtotal - truncatedCoupon).toFixed(2));

    return convertToCurrency(finalRoundedValue);
  }, [totalValue, couponDiscount]);

  const couponLabel = useMemo(() => {
    return coupon?.segment === SegmentTypeEnum.first_order
      ? `${getTranslation('general.discountOfType', { type: getTranslation('$t(general.firstOrder, lowercase)') })}`
      : getTranslation('general.coupon');
  }, [coupon?.segment, getTranslation]);

  const subtotalLabel = useMemo(() => {
    return `${getTranslation('general.subtotal')} com ${
      coupon?.segment === SegmentTypeEnum.first_order
        ? getTranslation('general.discount').toLowerCase()
        : getTranslation('general.coupon').toLowerCase()
    }`;
  }, [coupon?.segment, getTranslation]);

  const loyaltyDiscountLabel = useMemo(() => {
    return `${getTranslation('general.discountOfType', { type: getTranslation('general.loyalty') })}`;
  }, [getTranslation]);

  const subtotalLoyaltyDiscountLabel = useMemo(() => {
    return getTranslation('general.subtotalWithLoyaltyDiscount');
  }, [getTranslation]);

  const minimumValueMessage = useMemo(() => {
    return (
      <S.MinimumDeliveryPrice
        dangerouslySetInnerHTML={{
          __html: getTranslation('general.minimumValue', {
            value: convertToCurrency(Number(mm_delivery_minimum_value))
          })
        }}
      />
    );
  }, [getTranslation, mm_delivery_minimum_value]);

  const totalWithChangesInfo = useMemo(() => {
    const defaultValues = { show: true, text: getTranslation('general.totalWithTax') };

    if (showTakeawayDiscount) {
      const value = (): number => {
        if (!totalPriceWithDiscount(totalValue)?.pure) return totalValue;

        if (showCouponDiscount) {
          return totalPriceWithDiscount(currency(totalValue).subtract(truncateAmount(couponDiscount.pure)).value)?.pure;
        }

        if (showLoyaltyCouponDiscount) {
          const totalWithLoyaltyDiscount = totalPriceWithLoyaltyCouponDiscount(totalValue).pure;
          const takeawayDiscount = calculatedDiscount(currency(totalWithLoyaltyDiscount).value).pure;

          return currency(totalWithLoyaltyDiscount).subtract(takeawayDiscount).value;
        }

        return totalPriceWithDiscount(totalValue)?.pure;
      };

      if (isCheckout) {
        dispatch(
          setCartValues({
            delivery_fee: 0,
            coupon: couponDiscountPure,
            total: truncateAmount(value()),
            subtotal: truncateAmount(totalValue),
            takeaway_discount: loyaltyDiscount
              ? calculatedDiscountToWithdrawWithLoyaltyCoupon(totalValue).pure
              : calculatedDiscount(totalValue - truncateAmount(couponDiscount.pure)).pure
          })
        );
      }

      return {
        ...defaultValues,
        text: getTranslation('general.totalWithDiscount'),
        value: convertToCurrency(truncateAmount(value())),
        render: (
          <>
            {showCouponDiscount && (
              <>
                <li className="foot -coupon">
                  <span className="item ">{couponLabel}</span>
                  <span data-test="coupon-discount" className="price">
                    -{couponDiscount.pretty}
                  </span>
                </li>

                <li className="foot -coupon">
                  <span className="item ">{subtotalLabel}</span>
                  <span data-test="coupon-subtotal" className="price">
                    {couponSubtotal}
                  </span>
                </li>
              </>
            )}

            {showLoyaltyCouponDiscount && (
              <>
                <li className="foot -coupon">
                  <span className="item ">{loyaltyDiscountLabel}</span>
                  <span data-test="loyalty-discount" className="price">
                    -{convertToCurrency(loyaltyDiscount)}
                  </span>
                </li>

                <li className="foot -coupon">
                  <span className="item ">{subtotalLoyaltyDiscountLabel}</span>
                  <span data-test="loyalty-discount-subtotal" className="price">
                    {totalPriceWithLoyaltyCouponDiscount(totalValue).pretty}
                  </span>
                </li>
              </>
            )}

            <li className="foot">
              <span className="item">
                {getTranslation('general.discountForTakeaway')} ({discount?.label})
              </span>
              <span data-test="takeaway-discount" className="price">
                -
                {loyaltyDiscount
                  ? calculatedDiscountToWithdrawWithLoyaltyCoupon(totalValue).pretty
                  : calculatedDiscount(totalValue - truncateAmount(couponDiscount.pure)).pretty}
              </span>
            </li>
          </>
        )
      };
    }

    if (showDeliveryTax) {
      const value = (): number => {
        if (!calculatedDeliveryFee(totalValue)?.pure) return totalValue;

        if (showCouponDiscount) {
          return calculatedDeliveryFee(currency(totalValue).subtract(truncateAmount(couponDiscount.pure)).value)?.pure;
        }

        if (showLoyaltyCouponDiscount) {
          const totalWithLoyaltyDiscount = totalPriceWithLoyaltyCouponDiscount(totalValue).pure;

          return calculatedDeliveryFee(currency(totalWithLoyaltyDiscount).value).pure;
        }

        return calculatedDeliveryFee(totalValue)?.pure;
      };

      dispatch(
        setCartValues({
          takeaway_discount: 0,
          coupon: couponDiscountPure,
          total: truncateAmount(value()),
          subtotal: truncateAmount(totalValue),
          delivery_fee: truncateAmount(Number(deliveryFee))
        })
      );

      return {
        ...defaultValues,
        value: convertToCurrency(truncateAmount(value())),
        render: (
          <>
            {showCouponDiscount && (
              <>
                <li className="foot -coupon">
                  <span className="item">
                    <span className="item">{couponLabel}</span>
                  </span>
                  <span data-test="coupon-discount" className="price">
                    -{couponDiscount.pretty}
                  </span>
                </li>

                <li className="foot -coupon">
                  <span>{subtotalLabel}</span>
                  <span data-test="coupon-subtotal">{couponSubtotal}</span>
                </li>
              </>
            )}

            {showLoyaltyCouponDiscount && (
              <>
                <li className="foot -coupon">
                  <span className="item">{loyaltyDiscountLabel}</span>
                  <span data-test="coupon-discount" className="price">
                    -{convertToCurrency(loyaltyDiscount)}
                  </span>
                </li>

                <li className="foot -coupon">
                  <span className="item ">{subtotalLoyaltyDiscountLabel}</span>
                  <span data-test="coupon-subtotal" className="price">
                    {totalPriceWithLoyaltyCouponDiscount(totalValue).pretty}
                  </span>
                </li>
              </>
            )}

            <li className="foot">
              <span className="item">{getTranslation('general.deliveryTax')}</span>
              <span data-test="delivery-tax" className="price">
                +{convertToCurrency(deliveryFee as number)}
              </span>
            </li>
          </>
        )
      };
    }

    if (hasServiceCharge) {
      const value = (): number => {
        if (!totalPriceWithServiceCharges(totalValue).pure) return totalValue;

        if (showCouponDiscount) {
          return totalPriceWithServiceCharges(currency(totalValue).subtract(truncateAmount(couponDiscount.pure)).value)
            .pure;
        }

        return totalPriceWithServiceCharges(totalValue).pure;
      };

      if (isCheckout) {
        dispatch(
          setCartValues({
            coupon: couponDiscountPure,
            total: truncateAmount(value()),
            subtotal: truncateAmount(totalValue),
            service_tax: !localOrderMode.length
              ? 0
              : truncateAmount(
                  calculatedServiceCharges(currency(totalValue).subtract(truncateAmount(couponDiscount.pure)).value)
                )
          })
        );
      }

      return {
        ...defaultValues,
        show: !!showCouponDiscount || !!isMyTab,
        value: convertToCurrency(truncateAmount(value())),
        text: !isMyTab ? getTranslation('general.total') : getTranslation('general.totalWithTax'),
        render: (
          <>
            {showCouponDiscount && (
              <>
                <li className="foot -coupon">
                  <span className="item ">{couponLabel}</span>
                  <span data-test="coupon-discount" className="price">
                    -{couponDiscount.pretty}
                  </span>
                </li>

                <li className="foot -coupon">
                  <span>{subtotalLabel}</span>
                  <span data-test="coupon-subtotal" className="price">
                    {couponSubtotal}
                  </span>
                </li>
              </>
            )}

            {!!isMyTab && !!localOrderMode.length && (
              <li className="foot">
                <span className="item">
                  {getTranslation('general.serviceTax')}
                  {!serviceCharges && ` (${serviceTax ?? ''}%)`}
                </span>

                <span data-test="service-tax" className="price">
                  +
                  {convertToCurrency(
                    calculatedServiceCharges(currency(totalValue).subtract(truncateAmount(couponDiscount.pure)).value)
                  )}
                </span>
              </li>
            )}
          </>
        )
      };
    }

    if (showCouponDiscount) {
      const value = currency(totalValue).subtract(truncateAmount(couponDiscount.pure)).value;

      if (isCheckout) {
        dispatch(
          setCartValues({
            total: value,
            delivery_fee: 0,
            takeaway_discount: 0,
            coupon: couponDiscountPure,
            subtotal: truncateAmount(totalValue)
          })
        );
      }

      return {
        ...defaultValues,
        text: 'Total',
        value: convertToCurrency(truncateAmount(value)),
        render: (
          <li className="foot -coupon">
            <span className="item ">{couponLabel}</span>
            <span data-test="coupon-discount" className="price">
              -{couponDiscount.pretty}
            </span>
          </li>
        )
      };
    }

    if (showLoyaltyCouponDiscount) {
      const value = totalPriceWithLoyaltyCouponDiscount(totalValue);

      if (isCheckout) {
        dispatch(
          setCartValues({
            delivery_fee: 0,
            total: value.pure,
            takeaway_discount: 0,
            coupon: couponDiscountPure,
            subtotal: truncateAmount(totalValue)
          })
        );
      }

      return {
        ...defaultValues,
        text: 'Total',
        value: convertToCurrency(truncateAmount(value.pure)),
        render: (
          <li className="foot -coupon">
            <span className="item ">{loyaltyDiscountLabel}</span>
            <span data-test="loyalty-coupon-discount" className="price">
              -{convertToCurrency(loyaltyDiscount)}
            </span>
          </li>
        )
      };
    }

    if (isCheckout) {
      setTimeout(() => {
        dispatch(
          setCartValues({
            coupon: 0,
            delivery_fee: 0,
            takeaway_discount: 0,
            total: truncateAmount(totalValue),
            subtotal: truncateAmount(totalValue)
          })
        );
      }, 0);
    }

    return { value: 0, text: '', show: false, render: <></> };
  }, [
    isMyTab,
    dispatch,
    isCheckout,
    serviceTax,
    totalValue,
    deliveryFee,
    couponLabel,
    subtotalLabel,
    couponSubtotal,
    getTranslation,
    serviceCharges,
    loyaltyDiscount,
    discount?.label,
    showDeliveryTax,
    hasServiceCharge,
    couponDiscountPure,
    calculatedDiscount,
    showCouponDiscount,
    couponDiscount.pure,
    loyaltyDiscountLabel,
    couponDiscount.pretty,
    showTakeawayDiscount,
    calculatedDeliveryFee,
    localOrderMode.length,
    totalPriceWithDiscount,
    calculatedServiceCharges,
    showLoyaltyCouponDiscount,
    totalPriceWithServiceCharges,
    subtotalLoyaltyDiscountLabel,
    totalPriceWithLoyaltyCouponDiscount,
    calculatedDiscountToWithdrawWithLoyaltyCoupon
  ]);

  return (
    <S.Container>
      <S.CheckOrderList $noActions={noActions} {...rest}>
        {children}

        {!hideTotal && (
          <>
            <li className="foot">
              <span className="item">
                {totalWithChangesInfo.show ? getTranslation('general.subtotal') : getTranslation('general.total')}
              </span>

              <span data-test="order-total-price" id="order-total-price" className="price">
                {convertToCurrency(totalValue)}
              </span>
            </li>

            {totalWithChangesInfo.show && (
              <>
                {totalWithChangesInfo.render}
                <li className="foot -with-discount">
                  <span className="item">{totalWithChangesInfo.text}</span>
                  <span data-test="order-price-with-changes" className="price">
                    {totalWithChangesInfo.value}
                  </span>
                </li>
              </>
            )}
          </>
        )}

        {renderErrorMessage}
      </S.CheckOrderList>

      {showMinimumValueWarn && (
        <Alert
          type={ToastTypeEnum.blank}
          message={minimumValueMessage}
          customIcon={<RiEBikeLine size={18} color={theme.colors.gray500} />}
        />
      )}
    </S.Container>
  );
};

interface CheckOrderItemOptionalProps {
  name: string;
  price: number;
  quantity: number;
  unitPrice?: number;
  productQuantity: number;
  shouldHideOptionalPrices: boolean;
  pricingType?: OptionPricingTypeEnum;
  attributes?: {
    id?: string;
    options: CartOptional[];
  }[];
}

interface ItemRenderProps {
  comment?: string;
  itemName: string;
  editItem: CartProduct;
  customItemName?: string;
  priceId?: string | number;
  itemProduct?: CartProduct;
  comboItemList?: CartProduct[];
}

export const CheckOrderItemOptional: React.FC<CheckOrderItemOptionalProps> = ({
  name,
  price,
  quantity,
  unitPrice,
  attributes,
  pricingType,
  productQuantity,
  shouldHideOptionalPrices
}) => {
  const optionQuantity = useMemo(() => {
    if (!!unitPrice || (pricingType && pricingType !== OptionPricingTypeEnum.Individual)) {
      return quantity;
    }

    return truncateAmount(quantity * productQuantity);
  }, [pricingType, productQuantity, quantity, unitPrice]);

  const optionPrice = useMemo(() => {
    if (unitPrice) return unitPrice;

    return price * optionQuantity;
  }, [optionQuantity, price, unitPrice]);

  return (
    <>
      <li>
        <span className="item">{`${optionQuantity}x ${name}`}</span>

        <span className="price">
          {!shouldHideOptionalPrices && optionPrice > 0 && `(+ ${convertToCurrency(optionPrice)})`}
        </span>
      </li>

      <div className="flow">
        {attributes?.map((attribute) =>
          attribute.options.map((opt: CartOptional, index: number) => (
            <CheckOrderItemOptional
              key={index}
              name={opt.name}
              price={opt.price}
              quantity={opt.quantity}
              unitPrice={opt.unitPrice}
              attributes={opt.attributes}
              pricingType={opt.pricingType}
              productQuantity={productQuantity}
              shouldHideOptionalPrices={shouldHideOptionalPrices}
            />
          ))
        )}
      </div>
    </>
  );
};

interface CheckOrderItemProps {
  combo?: CartItem;
  item?: CartProduct;
  noActions?: boolean;
  fullDivider?: boolean;
  getTranslation: (key: string, data?: Record<string, string | number>) => string;
}

export const CheckOrderItem: React.FC<CheckOrderItemProps> = ({
  item,
  combo,
  fullDivider,
  getTranslation,
  noActions = false
}) => {
  const history = useRouter();
  const dispatch = useDispatch();

  const [shouldDisplayDeleteModal, setShouldDisplayDeleteModal] = useState(false);

  const checkForSpecialPricingType: (optionList: CartOptional[]) => boolean = useCallback((optionList) => {
    return !!optionList.find((option) => {
      if (!!option.pricingType && option.pricingType !== OptionPricingTypeEnum.Individual) return true;

      if (!option.attributes) return false;

      return checkForSpecialPricingType(option.attributes?.flatMap((attributeList) => attributeList.options));
    });
  }, []);

  const handleEditItem = useCallback(
    (editProduct: CartProduct) => {
      history.push(`detail/${editProduct?.product?.id ?? ''}?edit=${editProduct?.id ?? ''}`);
    },
    [history]
  );

  const removeItem = useCallback(() => {
    setShouldDisplayDeleteModal(true);
  }, []);

  const renderDeleteModal = useCallback(
    (editProduct: CartProduct) => (
      <Modal
        isSmall
        isShow={shouldDisplayDeleteModal}
        onClose={(): void => setShouldDisplayDeleteModal(false)}
        headerTitle={`${getTranslation('general.remove')} ${editProduct.product.name}?`}
      >
        <S.ModalContent>
          <Button
            aria-label={getTranslation('general.cancel')}
            onClick={(): void => setShouldDisplayDeleteModal(false)}
          >
            {getTranslation('general.cancel')}
          </Button>

          <Button
            isDanger
            isGhost={false}
            aria-label={getTranslation('general.remove')}
            onClick={(): void => {
              setShouldDisplayDeleteModal(false);
              dispatch(removeProductToCart(editProduct));
            }}
          >
            {`${getTranslation('general.yesRemove')} ${getTranslation('general.product').toLowerCase()}`}
          </Button>
        </S.ModalContent>
      </Modal>
    ),
    [dispatch, getTranslation, shouldDisplayDeleteModal]
  );

  const getOptionalList = useCallback((product: CartProduct) => {
    if (!product.optionalTree) {
      return product.optionals || [];
    }

    return product.optionalTree.flatMap(({ options }) => [...options]);
  }, []);

  const productPrice = useMemo(() => {
    if (item) {
      const itemOptionaList = getOptionalList(item);

      const shouldHideOptionalPrices = checkForSpecialPricingType(itemOptionaList);

      if (shouldHideOptionalPrices) {
        return item?.subtotal > 0 && convertToCurrency(item?.subtotal);
      }

      return item?.product?.price > 0 && convertToCurrency(item?.product?.price * item.quantity);
    }

    if (combo) {
      return combo?.subtotal > 0 && convertToCurrency(combo?.subtotal);
    }
  }, [checkForSpecialPricingType, combo, getOptionalList, item]);

  const renderOptionals = useCallback(
    (product: CartProduct) => {
      const currentProductOptionaList = getOptionalList(product);

      const shouldHideOptionalPrices = checkForSpecialPricingType(currentProductOptionaList);

      if (!currentProductOptionaList || currentProductOptionaList.length === 0) {
        return <></>;
      }

      return (
        <ul className="optionals">
          <FadeIn delay={100}>
            {currentProductOptionaList.map((opt: CartOptional, index: number) => (
              <CheckOrderItemOptional
                key={index}
                name={opt.name}
                price={opt.price}
                quantity={opt.quantity}
                unitPrice={opt.unitPrice}
                attributes={opt.attributes}
                pricingType={opt.pricingType}
                productQuantity={product.quantity}
                shouldHideOptionalPrices={shouldHideOptionalPrices}
              />
            ))}
          </FadeIn>
        </ul>
      );
    },
    [checkForSpecialPricingType, getOptionalList]
  );

  const renderProductPrice = useCallback(
    (priceId?: string | number) => (
      <S.ItemPrice data-test={`product-total-price-${priceId || ''}`}>{productPrice}</S.ItemPrice>
    ),
    [productPrice]
  );

  const handleChangeProductQuantity = useCallback(
    (quantity: number) => {
      dispatch(changeProductQuantity({ quantity, productId: item?.id, comboId: combo?.comboId }));
    },
    [combo, dispatch, item?.id]
  );

  const renderEditAction = useCallback(
    (editProduct: CartProduct) => (
      <S.ActionWrapper>
        <div data-test="btn-edit-item">
          <Button isSimple onClick={(): void => handleEditItem(editProduct)}>
            {getTranslation('general.edit')}
          </Button>
        </div>

        <div>
          <ItemQuantity
            quantity={editProduct.quantity}
            onRemoveItem={(): void => removeItem()}
            onDecrease={(): void => {
              handleChangeProductQuantity(editProduct.quantity - 1);
            }}
            onIncrease={(): void => {
              handleChangeProductQuantity(editProduct.quantity + 1);
            }}
          />
        </div>
      </S.ActionWrapper>
    ),
    [getTranslation, handleChangeProductQuantity, handleEditItem, removeItem]
  );

  const renderItem = useCallback(
    ({ comment, priceId, editItem, itemName, itemProduct, comboItemList, customItemName }: ItemRenderProps) => (
      <S.CheckOrderItemContainer $fullDivider={fullDivider}>
        <FadeIn>
          <S.ItemTitle>
            {itemName}
            {customItemName && <> - {customItemName}</>}
          </S.ItemTitle>

          {renderProductPrice(priceId)}

          {!noActions && renderEditAction(editItem)}

          {((itemProduct?.optionals && itemProduct?.optionals?.length > 0) ||
            (comboItemList?.[0].optionals && comboItemList?.[0].optionals?.length > 0)) && <S.Divider />}

          {comboItemList?.map((comboItem, index) => (
            <div className="combo-item" key={index}>
              <span className="product-name">{comboItem.customName}</span>

              {renderOptionals(comboItem)}
            </div>
          ))}

          {itemProduct && renderOptionals(itemProduct)}

          {comment && (
            <div className="comment">
              <p>{getTranslation('general.observations')}:</p>
              <span>{comment}</span>
            </div>
          )}
        </FadeIn>

        {renderDeleteModal(editItem)}
      </S.CheckOrderItemContainer>
    ),
    [fullDivider, getTranslation, noActions, renderDeleteModal, renderEditAction, renderOptionals, renderProductPrice]
  );

  if (combo) {
    return renderItem({
      comment: combo.comment,
      priceId: combo?.comboId,
      editItem: combo.items[0],
      comboItemList: combo.items,
      itemName: `${combo.quantity}x ${combo.name}`
    });
  }

  if (item) {
    return renderItem({
      editItem: item,
      itemProduct: item,
      comment: item.comment,
      priceId: item?.product?.id,
      customItemName: item.customName,
      itemName: `${item.quantity}x ${item?.product?.name}`
    });
  }

  return <></>;
};
