import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

/** interpolation documentation here https://www.i18next.com/translation-function/interpolation */
export default function useTranslator(): {
  getTranslation: (path: string, interpolation?: Record<string, any>) => string;
} {
  const { t: translation } = useTranslation();

  const getTranslation: (path: string, interpolation?: Record<string, any>) => string = useCallback(
    (path, interpolation) => translation(path, interpolation),
    [translation]
  );

  return { getTranslation };
}
