import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DeliveryPricingEnum, DeliveryWayEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import { getDeliveryTime } from '~/utils';
import { isDelivery } from '~/utils/orderDeliveryTypes';

interface UseCheckoutDeliveryInfo {
  isTakeaway: boolean;
  deliveryOption?: string;
  isInStore: boolean;
}

export default function useCheckoutDeliveryInfo({ isTakeaway, isInStore, deliveryOption }: UseCheckoutDeliveryInfo) {
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const cart = useSelector((state: IApplicationState) => state.cart);

  const deliveryInfo = useMemo(() => {
    if (!settings) {
      return undefined;
    }

    const deliveryFeeValue = (() => {
      if (!cart.deliveryFee) return undefined;

      if ('pricing' in cart.deliveryFee) {
        if (
          !!settings.mm_free_delivery_enabled &&
          !!settings.mm_free_delivery_minimum_value &&
          cart.values.subtotal > Number(settings.mm_free_delivery_minimum_value)
        ) {
          return 0;
        }

        if (cart.deliveryFee.pricing !== DeliveryPricingEnum.ask)
          return Number(cart.deliveryFee.pricing === DeliveryPricingEnum.free ? 0 : cart.deliveryFee.pricing);

        return cart.deliveryFee.pricing;
      }
    })();

    return settings.mm_delivery_enabled
      ? {
          delivery_enabled:
            settings.mm_delivery_enabled &&
            cart.deliveryFee &&
            'pricing' in cart.deliveryFee &&
            cart.deliveryFee?.pricing !== 'outside',
          delivery_fee: deliveryFeeValue,
          delivery_required_amount: settings.mm_delivery_minimum_value_enabled
            ? Number(settings.mm_delivery_minimum_value)
            : 0,
          takeaway_discount:
            settings.mm_takeaway_discount_enabled && settings.mm_takeaway_enabled ? settings.mm_takeaway_discount : 0
        }
      : undefined;
  }, [cart.deliveryFee, cart.values.subtotal, settings]);

  const deliveryMode = useCallback(() => {
    if (deliveryInfo?.delivery_fee === DeliveryPricingEnum.ask) {
      return deliveryInfo.delivery_fee;
    }

    if (deliveryInfo?.delivery_fee === DeliveryPricingEnum.free || Number(deliveryInfo?.delivery_fee) === 0) {
      return DeliveryPricingEnum.free;
    }

    if (Number(deliveryInfo?.delivery_fee) > 0) {
      return DeliveryPricingEnum.fee;
    }

    return DeliveryPricingEnum.ask;
  }, [deliveryInfo]);

  const deliveryTime = useCallback(() => {
    if (isTakeaway) {
      return settings?.mm_takeaway_time || '';
    }

    if (!cart.deliveryFee) {
      return '';
    }

    return getDeliveryTime({ deliveryFee: cart.deliveryFee });
  }, [cart.deliveryFee, isTakeaway, settings]);

  const deliveryWay = useMemo(() => {
    if (!!deliveryOption && isDelivery(deliveryOption)) {
      return DeliveryWayEnum.delivery;
    }

    if (isInStore) {
      return DeliveryWayEnum.onsite;
    }

    return DeliveryWayEnum.takeaway;
  }, [deliveryOption, isInStore]);

  return { deliveryInfo, deliveryMode, deliveryTime, deliveryWay };
}
