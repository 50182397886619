import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  margin-bottom: 1.25rem;
`;

export const TopMargin = styled.div`
  margin-top: 2rem;
`;

export const BottomMargin = styled.div`
  margin-bottom: 2rem;
`;

export const ItemContainer = styled.div`
  padding: 0.5rem 1.25rem 0;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }
`;
