/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import Sockette, { SocketteOptions } from 'sockette';

import { SocketteEvent } from '~/hooks/useOrderSocket';

interface WebsocketProps {
  orderUUID: string;
  orderID: number | string;
  handleEvent: (event: MessageEvent) => any;
  handleOpenConnection: (event: SocketteEvent) => any;
  handleCloseConnection: (event: CloseEvent) => any;
}

const SOCKET_URL = String(process.env.NEXT_PUBLIC_SOCKET_URL) || '';
const ATTEMPTS = Number(process.env.NEXT_PUBLIC_SOCKET_RECONNECT_ATTEMPTS) || 1;
const TIMEOUT = Number(process.env.NEXT_PUBLIC_SOCKET_RECONNECT_TIMEOUT) || 5;

export function websocket({
  orderID,
  orderUUID,
  handleEvent,
  handleOpenConnection,
  handleCloseConnection
}: WebsocketProps): Sockette {
  const url = `${SOCKET_URL}?order-uuid=${orderUUID}&order-id=${orderID}`;

  const config = {
    timeout: Number(`${TIMEOUT}e3`),
    maxAttempts: ATTEMPTS,
    onopen: (event) => handleOpenConnection(event as SocketteEvent),
    onmessage: (event) => handleEvent(event),
    onclose: (event) => handleCloseConnection(event)
  } as SocketteOptions;

  const websocketConnection = new Sockette(url, config);

  return websocketConnection;
}
