/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';

import { Button as ButtonComponent } from '~/components';

export const Content = styled.div`
  padding: 1.15rem;
  padding-bottom: 0;
`;

export const Button = styled(ButtonComponent)`
  text-transform: capitalize;
`;

export const Section = styled.div`
  margin: 2.6rem 0 1.7rem;
`;

export const SectionTitle = styled.p`
  margin-bottom: 0.285rem;

  line-height: 12px;
  font-size: 0.85rem;
`;

export const SectionDescription = styled.strong`
  font-weight: 700;
  line-height: 25.2px;
  font-size: 1.125rem;
`;

export const SectionExpiration = styled.p`
  margin-top: 2rem;

  font-size: 0.85rem;
  line-height: 15.6px;
  color: ${({ theme }) => theme.colors.mediumGray};
`;
