export interface HidePartOfPhoneNumberProps {
  phoneNumber: string;
}

export default function hidePartOfPhoneNumber({ phoneNumber }: HidePartOfPhoneNumberProps) {
  const ddd = phoneNumber.slice(0, 5);
  const number = phoneNumber.slice(5);

  const mustShowPart = number.split('-')[1];

  const hiddenNumber = `****-${mustShowPart}`;

  return `${ddd}${hiddenNumber}`;
}
