import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 0 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${({ theme }) => theme.colors.lightestGray};

  > h5 {
    font-weight: 600;
    line-height: 150%;
    font-size: 1.125rem;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
