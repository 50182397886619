import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;

  border-radius: 0.5rem;
  border: 1px dashed ${({ theme }) => theme.colors.gray300};

  gap: 0.25rem;
  display: flex;
  flex-direction: column;
`;

export const InfoWrapper = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoldText = styled.b`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: 'Manrope', sans-serif;
`;

export const RegularText = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-family: 'Manrope', sans-serif;

  color: ${({ theme }) => theme.colors.gray500};
`;
