import { useState, useContext, createContext, SetStateAction } from 'react';

interface ModalProps {
  modalHasPreviewed: boolean;
  setModalHasPreviewed: (param: SetStateAction<boolean>) => void;
}

const ModalContext = createContext({} as ModalProps);

const ModalProvider: React.FC = ({ children }) => {
  const [modalHasPreviewed, setModalHasPreviewed] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        modalHasPreviewed,
        setModalHasPreviewed
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

function useModalContext(): ModalProps {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModalContext must be used within an ModalProvider');
  }

  return context;
}

export { ModalProvider, useModalContext };
