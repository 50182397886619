/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const Content = styled.div`
  h2 {
    margin-bottom: 0.5rem;

    font-weight: 800;
    font-size: 1.5rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.textColor};
  }

  p {
    margin: 0 auto 1.5rem;

    font-weight: 500;
    line-height: 150%;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray500};

    strong {
      font-weight: 700;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

export const CountdownWrapper = styled.div<{ isHide: boolean }>`
  ${({ isHide }) => css`
    opacity: 1;
    transition: 0.3s;
    visibility: visible;

    ${isHide &&
    css`
      transform: translateY(20px);

      opacity: 0;
      visibility: 0;
      pointer-events: none;
    `}
  `}
`;

export const Actions = styled.footer`
  margin-top: 1.5rem;

  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const EmailVerificationInputWrapper = styled.div`
  margin-bottom: 2rem;
`;
