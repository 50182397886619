import styled, { css } from 'styled-components';

import * as InputCurrency from '~/components/Input/InputCurrency/styles';

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 1rem;
  padding-bottom: 0;

  > button {
    margin-top: 1.125rem;

    align-self: flex-end;
  }
`;

export const StrongText = styled.strong`
  margin-bottom: 2px;

  font-weight: 700;
  line-height: 18.2px;
`;

export const RegularText = styled.p`
  ${({ theme }) => css`
    margin-top: 8px;

    font-size: 1rem;
    line-height: 21px;
    text-align: center;
    color: ${theme.colors.gray500};
  `}
`;

export const TagWrapper = styled.div`
  margin: 16px 0 24px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Tag = styled.span<{ color?: string }>`
  ${({ theme, color }) => css`
    padding: 4px 12px;
    border-radius: 8px;

    font-weight: 700;
    color: ${color || theme.colors.gray600};
    background-color: ${theme.colors.gray200};

    + span {
      margin-top: 8px;
    }
  `}
`;

export const InputCurrencyWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: 4px;

    ${InputCurrency.Container} {
      margin: 0;
      width: 100%;
      border-radius: 8px;

      > div {
        border-radius: 8px 0 0 8px;
        border: 1px solid ${theme.colors.gray300};
      }

      > input {
        width: 100%;
        border-radius: 0 8px 8px 0;
        border: 1px solid ${theme.colors.gray300};
      }
    }
  `}
`;

export const PaymentSummaryWrapper = styled.ul`
  width: 100%;
  margin-top: 16px;
`;

export const PaymentSummaryItem = styled.li<{ isDisabled?: boolean }>`
  ${({ theme, isDisabled }) => css`
    list-style: none;

    display: flex;
    justify-content: space-between;

    label {
      font-size: 1rem;
      line-height: 21px;
      text-align: center;
      color: ${theme.colors.gray500};
    }

    ${isDisabled &&
    css`
      text-decoration: line-through;
      color: ${theme.colors.gray400};

      p,
      label {
        color: ${theme.colors.gray400};
      }
    `}
  `}
`;
