import styled, { css } from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const Container = Modal
  ? styled(Modal)`
      ${({ theme, isPageLike }) => css`
        z-index: 1004;

        .container {
          height: 90vh;
          padding-bottom: 0;

          > .title {
            border-bottom: 1px solid ${theme.colors.gray300};

            background: ${theme.colors.backgroundColor};
          }

          .content {
            display: flex;
            flex-direction: column;

            background: ${theme.colors.backgroundColor};
          }

          ${isPageLike &&
          css`
            position: relative;

            height: 100vh;
            background: ${theme.colors.gray200};
          `}
        }
      `}
    `
  : styled.div<ModalProps>``;

export const Content = styled.div`
  ${({ theme }) => css`
    position: relative;

    padding: 24px 16px 28px;

    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    .tip {
      margin: 0;

      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      text-align: left;
      color: ${theme.colors.gray500};
    }
  `}
`;

export const Footer = styled.div<{ $isModal?: boolean }>`
  ${({ theme, $isModal }) => css`
    flex: 1;

    transition: 0.3s;

    > p {
      margin: 10px 16px 8px;

      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      text-align: left;
      color: ${theme.colors.gray600};
    }

    ${$isModal &&
    css`
      background: ${theme.colors.backgroundColor};
    `}
  `}
`;

export const Actions = styled.div<{ $inFooter?: boolean }>`
  ${({ theme, $inFooter }) => css`
    width: 100%;
    max-width: 800px;
    padding: 24px 0 0;

    background: ${theme.colors.backgroundColor};

    button {
      & + button {
        margin-top: 12px;
      }
    }

    ${$inFooter &&
    css`
      padding: 16px;

      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    `}
  `}
`;

export const WavesWrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 800px;

    background: ${theme.colors.gray200};
  `}
`;
