import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem 1rem 0.75rem;
    box-shadow: 0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.12);

    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: ${theme.colors.backgroundColor};

    cursor: pointer;
    transition: 0.3s;
  `}
`;

export const Header = styled.div<{ isExpanded: boolean }>`
  ${({ theme, isExpanded }) => css`
    width: 100%;
    margin-bottom: ${isExpanded ? '0.75rem' : '0'};

    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: 0.5s;

    .title {
      text-align: left;
      font-weight: 700;
      line-height: 150%;
      font-size: 0.857rem;
      color: ${theme.colors.gray600};
    }

    svg {
      width: 0.75rem;
      height: 0.5rem;

      transition: 0.5s;
      transform: ${isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};

      path {
        fill: ${theme.colors.gray700};
      }
    }
  `}
`;

export const HideContent = styled(motion.div)`
  width: 100%;
  overflow: hidden;

  .text {
    text-align: left;
    font-weight: 500;
    line-height: 150%;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.mediumGray};
  }
`;
