import { CartOptional, CartOptionalGroup } from '~/interfaces/general';

/*
  Mounts an optional tree with options and it's flows using each option elementFlowId.
  elementFlowId format: {optionGroupId}-{optionId}|{optionGroupId}-{optionId}
  Example: 1-12|2-21|3-23
*/
export default function mountCartOptionalTree(cartOptionalList: CartOptional[]) {
  const sortTree = (flowList: CartOptional[]) => {
    let attributeList: CartOptionalGroup[] = [];

    flowList.forEach((optional) => {
      if (!optional.elementFlowId) {
        return;
      }

      const [optionGroupId, ...partList] = optional.elementFlowId.split('-');
      const elementId = partList.join('-');

      let attributeOptionsList: CartOptional[] = [];
      const currentAttributeList = attributeList.find(({ id }) => String(id) === optionGroupId);

      if (currentAttributeList) {
        attributeOptionsList = currentAttributeList.options;
        attributeList = attributeList.filter(({ id }) => id !== optionGroupId);
      }

      attributeList.push({
        id: optionGroupId,
        options: [...attributeOptionsList, { ...optional, elementFlowId: elementId, attributes: [] }]
      });
    }, []);

    attributeList.map((group, index) => {
      const childOptions: Record<string, CartOptional[]> = {};
      let groupOptionList = group.options.filter(({ id, elementFlowId }) => String(id) === elementFlowId);
      const flowOptionList = group.options.filter(({ id, elementFlowId }) => String(id) !== elementFlowId);

      flowOptionList.forEach((option) => {
        if (!option.elementFlowId) {
          return;
        }

        const [parentOptionalId, ...partList] = option.elementFlowId.split('|');
        const elementId = partList.join('|');

        const optionalList = childOptions[parentOptionalId] || [];
        childOptions[parentOptionalId] = [...optionalList, { ...option, elementFlowId: elementId }];
      });

      /** Recursively uses the tree formatting function in each flow group inside an option */
      groupOptionList = groupOptionList.map((group) => ({
        ...group,
        attributes: childOptions[group.id] ? sortTree(childOptions[group.id]) : []
      }));

      attributeList[index] = {
        id: group.id,
        options: groupOptionList
      };
    }, []);

    return attributeList;
  };

  return sortTree(cartOptionalList);
}
