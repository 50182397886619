import { addDays, differenceInHours } from 'date-fns';

interface GetHoursDifferenceProps {
  time: string;
  daysDifference: number;
}

export default function getHoursDifference({ time, daysDifference }: GetHoursDifferenceProps): number {
  if (!time) return 0;

  const [hours, minutes] = time.split(':');

  if (!hours || !minutes) return 0;

  const paddedHours = Number(hours.padStart(2, '0'));
  const paddedMinutes = Number(minutes.padStart(2, '0'));

  const now = new Date();

  const formattedDate = addDays(now.setHours(paddedHours, paddedMinutes), daysDifference);

  return differenceInHours(formattedDate, new Date());
}
