import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 800px;
    padding: 0;
    margin: 0 auto;
    border: none;

    display: flex;
    flex-direction: column;

    > h2 {
      position: relative;

      margin-bottom: 0;
      border: none;
      padding: 1rem 1rem 0.5rem;

      font-weight: 800;
      font-size: 0.87rem;
      line-height: 1.25rem;
      word-break: break-word;
      letter-spacing: 0.01em;
      color: ${theme.colors.textColor};
      background: ${theme.colors.lightestGray};

      @media screen and (min-width: 720px) {
        font-size: 1.31rem;
      }
    }

    .productList {
      > div {
        & + div {
          border-top: 1px solid rgba(0, 0, 0, 0.03);
        }
      }
    }
  `}
`;
