import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    z-index: 3;

    width: 100%;
    margin: 0 auto;
    max-width: 800px;
    height: 4.375rem;
    box-shadow: 0px 2px 4px ${theme.colors.lightestGray};

    background-color: ${theme.colors.lightestGray};

    &:before,
    &:after {
      border-bottom: 2.5rem solid ${theme.colors.lightestGray};

      @media screen and (max-width: 430px) {
        display: none;
      }
    }

    &:before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      height: 0.625rem;

      content: '';
      background-size: 1.25rem 2.5rem;
      background-image: radial-gradient(circle at 10px -15px, transparent 20px, ${theme.colors.lightestGray} 21px);
    }

    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      height: 0.938rem;

      content: '';
      background-size: 2.5rem 2.5rem;
      background-image: radial-gradient(circle at 10px 26px, ${theme.colors.lightestGray} 20px, transparent 21px);
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    z-index: 10;

    padding: 0.625rem;
    border-radius: 0.313rem;
    border: 1px dashed ${theme.colors.darkGray};

    display: flex;
    align-items: center;

    line-height: 140%;
    font-size: 0.75rem;

    p {
      margin-left: 0.375rem;

      text-align: left;
      font-size: 0.75rem;
    }

    strong {
      text-align: center;
      font-size: 0.75rem;
      color: ${theme.colors.darkGray};
    }
  `}
`;

export const SvgWrapper = styled.div`
  ${({ theme }) => css`
    height: 1.5rem;
    min-width: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 0.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.darkGray};
  `};
`;
