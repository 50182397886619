import { Reducer } from 'redux';

import { Menu } from '~/interfaces';

import { MenuActionTypes, MenusState } from './types';

const initialState = {
  menus: [],
  highlights: [],
  isLoading: false,
  isMenuListEmpty: false
};

const MenusReducer: Reducer<MenusState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MenuActionTypes.FETCH_MENUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isMenuListEmpty: false
      };

    case MenuActionTypes.FETCH_MENUS_SUCCESS:
      let currentMenu = state.selectedMenu;

      if (state.selectedMenu) {
        const menuList = payload.menus as Menu[];
        currentMenu = menuList?.find((menuItem: Menu) => menuItem.id === state.selectedMenu?.id);
      }

      return {
        ...state,
        isLoading: false,
        menus: payload.menus,
        selectedMenu: currentMenu,
        highlights: payload.highlights,
        isMenuListEmpty: payload.menus.length === 0
      };

    case MenuActionTypes.FETCH_MENUS_ERROR:
      return {
        ...state,
        isLoading: false,
        isMenuListEmpty: true
      };

    case MenuActionTypes.SELECT_CURRENT_MENU:
      return {
        ...state,
        selectedMenu: payload
      };

    default:
      return state;
  }
};

export default MenusReducer;
