import { useCallback } from 'react';

import {
  APIErrorCodes,
  CouponErrorMessageEnum,
  AbrahaoErrorMessageEnum,
  CheckoutErrorMessageEnum
} from '~/interfaces/enums';

import useTranslator from '../useTranslator';

interface UseGetErrorMessageReturnProps {
  getErrorMessage: (value: number | string) => ErrorProps | undefined;
}

interface ErrorProps {
  message: string;
  code: number | string;
}

export default function useGetErrorMessage(): UseGetErrorMessageReturnProps {
  const { getTranslation } = useTranslator();

  const getErrorMessage = useCallback(
    (value: number | string): ErrorProps | undefined => {
      const errorList: ErrorProps[] = [
        {
          code: CouponErrorMessageEnum.not_enabled,
          message: getTranslation('errorMessage.couponDisabledByStore')
        },
        {
          code: CouponErrorMessageEnum.sold_out,
          message: getTranslation('errorMessage.expiredCoupon')
        },
        {
          code: CouponErrorMessageEnum.not_in_available_period,
          message: getTranslation('errorMessage.expiredCoupon')
        },
        {
          code: CouponErrorMessageEnum.not_in_order_origin,
          message: getTranslation('errorMessage.invalidCoupon')
        },
        {
          code: CouponErrorMessageEnum.not_in_weekdays,
          message: `${getTranslation('couponMessage.invalidCouponWeekday')}.`
        },
        {
          code: CouponErrorMessageEnum.not_in_defined_hours,
          message: getTranslation('errorMessage.invalidCouponHour')
        },
        {
          code: CouponErrorMessageEnum.not_valid_order_value,
          message: getTranslation('errorMessage.invalidCouponForThisOrder')
        },
        {
          code: CouponErrorMessageEnum.not_valid_order_minimum_value,
          message: getTranslation('errorMessage.invalidCouponForThisOrder')
        },
        {
          code: CouponErrorMessageEnum.not_valid_order_minimum_value,
          message: getTranslation('errorMessage.invalidCouponForThisOrder')
        },
        {
          code: APIErrorCodes.STORE_ONLY_ACCEPT_SCHEDULED_ERROR,
          message: getTranslation('errorMessage.storeNotAcceptingWithoutSchedule')
        },
        {
          code: APIErrorCodes.EXCEEDED_EMAIL_REQUESTS,
          message: getTranslation('errorMessage.emailConfirmationExceeded')
        },
        {
          code: APIErrorCodes.EXCEEDED_EMAIL_REQUESTS,
          message: getTranslation('errorMessage.emailConfirmationExceeded')
        },
        {
          code: CheckoutErrorMessageEnum.rejected_order_by_mp,
          message: getTranslation('errorMessage.orderRejectedByMercadoPago')
        },
        {
          code: CheckoutErrorMessageEnum.wrong_phone_pattern,
          message: getTranslation('errorMessage.checkYourPhoneNumber')
        },
        {
          code: AbrahaoErrorMessageEnum.UnableToSendOrderWithClosedBill,
          message: getTranslation('errorMessage.yourBillHasBeenRequested')
        },
        {
          code: AbrahaoErrorMessageEnum.TableNotFound,
          message: getTranslation('errorMessage.tableNumberNotFound')
        },
        {
          code: CheckoutErrorMessageEnum.order_validation_failed,
          message: getTranslation('errorMessage.invalidProductsInOrder')
        }
      ];

      return errorList.find((item) => String(value)?.includes(String(item.code)));
    },
    [getTranslation]
  );

  return { getErrorMessage };
}
