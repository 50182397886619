import { useMemo } from 'react';

import { useTranslator } from '~/hooks';
import CheckIcon from '~/assets/CheckIcon';
import { OptionalInputProps } from '~/interfaces/general';
import useOptionalsTextType from '~/hooks/useOptionalsTextType';

import { Container, Done, Required } from './styles';

interface OptionHeaderProps {
  name?: string;
  title: string;
  isDone: boolean;
  isBold?: boolean;
  isForm?: boolean;
  showDone?: boolean;
  isSimple?: boolean;
  isRequired: boolean;
  noPadding?: boolean;
  showRequired?: boolean;
  specs?: OptionalInputProps;
  subtitle?: boolean | string;
}

const OptionHeader: React.FC<OptionHeaderProps> = ({
  name,
  title,
  isDone,
  isRequired,
  isBold = false,
  isForm = false,
  subtitle = true,
  showDone = false,
  isSimple = false,
  noPadding = false,
  showRequired = false,
  specs = { min: 1, max: 1 }
}) => {
  const { getTranslation } = useTranslator();
  const { handleOptionalsTextType } = useOptionalsTextType();

  const isSubtitleText = useMemo(() => typeof subtitle === 'string', [subtitle]);

  const renderSubtitle = (): JSX.Element => {
    if (isSubtitleText) return <p className="tip">{subtitle}</p>;

    return <p className="tip">{handleOptionalsTextType(specs)}</p>;
  };

  return (
    <Container
      isBold={isBold}
      isForm={isForm}
      data-name={name}
      data-done={isDone}
      noPadding={noPadding}
      data-required={isRequired}
      isRequiredShown={!isDone && isRequired}
    >
      <div className="info">
        <strong className="title">{title}</strong>

        {!!subtitle && !isForm && renderSubtitle()}
      </div>

      {!isSimple && (
        <div className="status">
          <Required data-test="required-label" isShow={(!isDone && isRequired) || showRequired}>
            {getTranslation('general.required')}
          </Required>

          <Done
            data-test="required-label-done"
            isDone={isDone && (isRequired || showDone)}
            aria-label="Campo preenchido"
          >
            <CheckIcon />
          </Done>
        </div>
      )}
    </Container>
  );
};

OptionHeader.defaultProps = {
  isDone: false,
  isRequired: true
};

export default OptionHeader;
