/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

export const ModalContent = styled(Form)`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 1rem;
  padding-bottom: 0;

  > button {
    margin-top: 1.125rem;

    align-self: flex-end;
  }
`;

export const RegularText = styled.p`
  ${({ theme }) => css`
    margin-top: 8px;

    line-height: 21px;
    text-align: center;
    color: ${theme.colors.gray500};
  `}
`;

export const CardDocumentDescriptionWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TagWrapper = styled.span`
  ${({ theme }) => css`
    padding: 2px 8px;
    border-radius: 24px;
    margin-bottom: 4px;

    background-color: ${theme.colors.gray200};
  `}
`;

export const SmallText = styled.small`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const StrongText = styled.strong`
  margin-bottom: 2px;

  font-weight: 700;
  line-height: 18.2px;
`;
