import React from 'react';

import * as S from './styles';
export interface TextWithIconProps {
  text: string;
  onClick?: () => void;
  icon: React.ReactNode;
}

const TextWithIcon: React.FC<TextWithIconProps> = ({ text, icon, onClick }: TextWithIconProps) => {
  return (
    <S.Container $hasAction={!!onClick}>
      {icon}

      <S.TextWrapper onClick={onClick}>{text}</S.TextWrapper>
    </S.Container>
  );
};

export default TextWithIcon;
