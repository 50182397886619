import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { Button } from '~/components';
import { ICard } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { removeUserCard } from '~/redux-tools/store/user/actions';
import { useFormatCreditCard, useHandlePaymentTexts, useTranslator } from '~/hooks';

import * as S from './styles';

interface DeleteCardModalProps {
  isShow: boolean;
  selectedCard: ICard;
  handleClose: () => void;
}

const DeleteCardModal: React.FC<DeleteCardModalProps> = ({ isShow, handleClose, selectedCard }) => {
  const dispatch = useDispatch();

  const { number: card } = selectedCard;

  const { authenticationToken } = useSelector((state: IApplicationState) => state.user.data);

  const { getTranslation } = useTranslator();
  const { handlePaymentTexts } = useHandlePaymentTexts();
  const { formatCreditCard } = useFormatCreditCard();

  const remove = useCallback(() => {
    dispatch(removeUserCard(card, authenticationToken));
    handleClose();
  }, [dispatch, handleClose, card, authenticationToken]);

  const cardDescription = useMemo(() => {
    const cardInfo = formatCreditCard({
      card: selectedCard.number,
      flag: selectedCard.mpMethodId,
      isCreditCardNumberHidden: false
    });

    return `${handlePaymentTexts(PaymentCategoryEnum.credit).toLowerCase()} ${cardInfo}`;
  }, [formatCreditCard, handlePaymentTexts, selectedCard.mpMethodId, selectedCard.number]);

  return (
    <S.Container
      isSmall
      isShow={isShow}
      onClose={handleClose}
      className="sentry-mask"
      headerTitle={`${getTranslation('payment.deleteCard', {
        type: cardDescription
      })}`}
    >
      <S.ContentWrapper>
        <S.RegularText>{getTranslation('payment.cardDeleteWarningMessage')}</S.RegularText>

        <S.ButtonWrapper>
          <Button onClick={handleClose}>{getTranslation('general.cancel')}</Button>

          <Button isDanger onClick={remove} isGhost={false} type="button">
            {getTranslation('general.yesRemove')} {getTranslation('general.card').toLowerCase()}
          </Button>
        </S.ButtonWrapper>
      </S.ContentWrapper>
    </S.Container>
  );
};

export default DeleteCardModal;
