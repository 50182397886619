import React from 'react';

const IPhoneQrIcon = () => {
  return (
    <svg width="67" height="54" viewBox="0 0 67 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.8171 46.2858C56.6208 46.2858 67.0002 35.9243 67.0002 23.1429C67.0002 10.3614 56.6208 0 43.8171 0C31.0135 0 20.634 10.3614 20.634 23.1429C20.634 35.9243 31.0135 46.2858 43.8171 46.2858Z"
        fill="#E8EAED"
      />
      <path
        d="M4.68673 21.6467C5.21527 21.6467 5.64373 21.2193 5.64373 20.6921C5.64373 20.1649 5.21527 19.7375 4.68673 19.7375C4.1582 19.7375 3.72974 20.1649 3.72974 20.6921C3.72974 21.2193 4.1582 21.6467 4.68673 21.6467Z"
        fill="#30333C"
      />
      <path
        d="M5.07105 6.7865C4.60385 6.7865 4.22583 6.40943 4.22583 5.94341V3.41414C4.22583 2.94812 4.60385 2.57104 5.07105 2.57104C5.53824 2.57104 5.91626 2.94812 5.91626 3.41414V5.94341C5.91626 6.40943 5.53824 6.7865 5.07105 6.7865Z"
        fill="#7F8F9F"
      />
      <path
        d="M5.07105 12.6884C4.60385 12.6884 4.22583 12.3113 4.22583 11.8453V9.31599C4.22583 8.84997 4.60385 8.4729 5.07105 8.4729C5.53824 8.4729 5.91626 8.84997 5.91626 9.31599V11.8453C5.91626 12.3113 5.53824 12.6884 5.07105 12.6884Z"
        fill="#7F8F9F"
      />
      <path
        d="M3.38087 8.47256H0.845217C0.378023 8.47256 0 8.09549 0 7.62947C0 7.16345 0.378023 6.78638 0.845217 6.78638H3.38087C3.84806 6.78638 4.22608 7.16345 4.22608 7.62947C4.22608 8.09549 3.84806 8.47256 3.38087 8.47256Z"
        fill="#7F8F9F"
      />
      <path
        d="M9.29737 8.47256H6.76172C6.29453 8.47256 5.9165 8.09549 5.9165 7.62947C5.9165 7.16345 6.29453 6.78638 6.76172 6.78638H9.29737C9.76456 6.78638 10.1426 7.16345 10.1426 7.62947C10.1426 8.09549 9.76456 8.47256 9.29737 8.47256Z"
        fill="#7F8F9F"
      />
      <path
        d="M10.9871 53.1565V15.0349C10.9871 12.8074 12.7971 11.002 15.0302 11.002H47.5146C49.7474 11.002 51.5574 12.8074 51.5574 15.0349V53.1565"
        fill="white"
      />
      <path
        d="M24.1562 33.75H28.0938C28.8704 33.75 29.5 34.3796 29.5 35.1562V39.0938C29.5 39.8704 28.8704 40.5 28.0938 40.5H24.1562C23.3796 40.5 22.75 39.8704 22.75 39.0938V35.1562C22.75 34.3796 23.3796 33.75 24.1562 33.75Z"
        fill="#E8EAED"
      />
      <path
        opacity="0.3"
        d="M24.1562 43.5H28.0938C28.8704 43.5 29.5 44.1296 29.5 44.9062V48.8438C29.5 49.6204 28.8704 50.25 28.0938 50.25H24.1562C23.3796 50.25 22.75 49.6204 22.75 48.8438V44.9062C22.75 44.1296 23.3796 43.5 24.1562 43.5Z"
        fill="#E8EAED"
      />
      <path
        d="M33.9062 33.75H37.8438C38.6204 33.75 39.25 34.3796 39.25 35.1562V39.0938C39.25 39.8704 38.6204 40.5 37.8438 40.5H33.9062C33.1296 40.5 32.5 39.8704 32.5 39.0938V35.1562C32.5 34.3796 33.1296 33.75 33.9062 33.75Z"
        fill="#E8EAED"
      />
      <path
        opacity="0.3"
        d="M32.9688 43.5H35.7812C36.0401 43.5 36.25 43.7099 36.25 43.9688V46.7812C36.25 47.0401 36.0401 47.25 35.7812 47.25H32.9688C32.7099 47.25 32.5 47.0401 32.5 46.7812V43.9688C32.5 43.7099 32.7099 43.5 32.9688 43.5Z"
        fill="#E8EAED"
      />
      <path
        opacity="0.3"
        d="M36.7188 47.25H38.7812C39.0401 47.25 39.25 47.4599 39.25 47.7188V49.7812C39.25 50.0401 39.0401 50.25 38.7812 50.25H36.7188C36.4599 50.25 36.25 50.0401 36.25 49.7812V47.7188C36.25 47.4599 36.4599 47.25 36.7188 47.25Z"
        fill="#E8EAED"
      />
      <path
        d="M42.25 36.75C41.8358 36.75 41.5 36.4142 41.5 36V32.1562C41.5 31.7944 41.2056 31.5 40.8438 31.5H37C36.5858 31.5 36.25 31.1642 36.25 30.75C36.25 30.3358 36.5858 30 37 30H40.8438C42.0327 30 43 30.9673 43 32.1562V36C43 36.4142 42.6642 36.75 42.25 36.75Z"
        fill="black"
      />
      <path
        d="M19.75 36.75C19.3358 36.75 19 36.4142 19 36V32.1562C19 30.9673 19.9673 30 21.1562 30H25C25.4142 30 25.75 30.3358 25.75 30.75C25.75 31.1642 25.4142 31.5 25 31.5H21.1562C20.7944 31.5 20.5 31.7944 20.5 32.1562V36C20.5 36.4142 20.1642 36.75 19.75 36.75Z"
        fill="black"
      />
      <path
        d="M25 54H21.1562C19.9673 54 19 53.0327 19 51.8438V48C19 47.5858 19.3358 47.25 19.75 47.25C20.1642 47.25 20.5 47.5858 20.5 48V51.8438C20.5 52.2056 20.7944 52.5 21.1562 52.5H25C25.4142 52.5 25.75 52.8358 25.75 53.25C25.75 53.6642 25.4142 54 25 54Z"
        fill="black"
      />
      <path
        d="M40.8438 54H37C36.5858 54 36.25 53.6642 36.25 53.25C36.25 52.8358 36.5858 52.5 37 52.5H40.8438C41.2056 52.5 41.5 52.2056 41.5 51.8438V48C41.5 47.5858 41.8358 47.25 42.25 47.25C42.6642 47.25 43 47.5858 43 48V51.8438C43 53.0327 42.0327 54 40.8438 54Z"
        fill="black"
      />
      <path
        d="M28.0938 41.25H24.1562C22.9673 41.25 22 40.2827 22 39.0938V35.1562C22 33.9673 22.9673 33 24.1562 33H28.0938C29.2827 33 30.25 33.9673 30.25 35.1562V39.0938C30.25 40.2827 29.2827 41.25 28.0938 41.25ZM24.1562 34.5C23.7944 34.5 23.5 34.7944 23.5 35.1562V39.0938C23.5 39.4556 23.7944 39.75 24.1562 39.75H28.0938C28.4556 39.75 28.75 39.4556 28.75 39.0938V35.1562C28.75 34.7944 28.4556 34.5 28.0938 34.5H24.1562Z"
        fill="black"
      />
      <path
        d="M28.0938 51H24.1562C22.9673 51 22 50.0327 22 48.8438V44.9062C22 43.7173 22.9673 42.75 24.1562 42.75H28.0938C29.2827 42.75 30.25 43.7173 30.25 44.9062V48.8438C30.25 50.0327 29.2827 51 28.0938 51ZM24.1562 44.25C23.7944 44.25 23.5 44.5444 23.5 44.9062V48.8438C23.5 49.2056 23.7944 49.5 24.1562 49.5H28.0938C28.4556 49.5 28.75 49.2056 28.75 48.8438V44.9062C28.75 44.5444 28.4556 44.25 28.0938 44.25H24.1562Z"
        fill="black"
      />
      <path
        d="M37.8438 41.25H33.9062C32.7173 41.25 31.75 40.2827 31.75 39.0938V35.1562C31.75 33.9673 32.7173 33 33.9062 33H37.8438C39.0327 33 40 33.9673 40 35.1562V39.0938C40 40.2827 39.0327 41.25 37.8438 41.25ZM33.9062 34.5C33.5444 34.5 33.25 34.7944 33.25 35.1562V39.0938C33.25 39.4556 33.5444 39.75 33.9062 39.75H37.8438C38.2056 39.75 38.5 39.4556 38.5 39.0938V35.1562C38.5 34.7944 38.2056 34.5 37.8438 34.5H33.9062Z"
        fill="black"
      />
      <path
        d="M38.7812 46.5H37V43.9688C37 43.2967 36.4533 42.75 35.7812 42.75H32.9688C32.2967 42.75 31.75 43.2967 31.75 43.9688V46.7812C31.75 47.4532 32.2967 48 32.9688 48H35.5V49.7812C35.5 50.4532 36.0467 51 36.7188 51H38.7812C39.4533 51 40 50.4532 40 49.7812V47.7188C40 47.0468 39.4533 46.5 38.7812 46.5ZM33.25 44.25H35.5V46.5H33.25V44.25ZM38.5 49.5H37V48H38.5V49.5Z"
        fill="black"
      />
      <path
        d="M26.5 38.25H25.75C25.3358 38.25 25 37.9142 25 37.5V36.75C25 36.3358 25.3358 36 25.75 36H26.5C26.9142 36 27.25 36.3358 27.25 36.75V37.5C27.25 37.9142 26.9142 38.25 26.5 38.25Z"
        fill="black"
      />
      <path
        d="M36.25 38.25H35.5C35.0858 38.25 34.75 37.9142 34.75 37.5V36.75C34.75 36.3358 35.0858 36 35.5 36H36.25C36.6642 36 37 36.3358 37 36.75V37.5C37 37.9142 36.6642 38.25 36.25 38.25Z"
        fill="black"
      />
      <path
        d="M26.5 48H25.75C25.3358 48 25 47.6642 25 47.25V46.5C25 46.0858 25.3358 45.75 25.75 45.75H26.5C26.9142 45.75 27.25 46.0858 27.25 46.5V47.25C27.25 47.6642 26.9142 48 26.5 48Z"
        fill="black"
      />
      <path
        d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
        fill="#30333C"
      />
      <path
        d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
        fill="#30333C"
      />
      <path d="M22 11H40V12C40 13.6569 38.6569 15 37 15H25C23.3431 15 22 13.6569 22 12V11Z" fill="#30333C" />
      <path d="M53.5361 25.335H51.8457V29.5504H53.5361V25.335Z" fill="#30333C" />
      <path
        d="M51.8452 30.3933H53.5356C54.0028 30.3933 54.3809 30.0163 54.3809 29.5502V25.3348C54.3809 24.8688 54.0028 24.4917 53.5356 24.4917H51.8452C51.378 24.4917 51 24.8688 51 25.3348V29.5502C51 30.0163 51.378 30.3933 51.8452 30.3933Z"
        fill="#30333C"
      />
      <path
        d="M51.8452 37.1385H53.5356C54.0028 37.1385 54.3809 36.7614 54.3809 36.2954V32.0799C54.3809 31.6139 54.0028 31.2368 53.5356 31.2368H51.8452C51.378 31.2368 51 31.6139 51 32.0799V36.2954C51 36.7614 51.378 37.1385 51.8452 37.1385Z"
        fill="#30333C"
      />
    </svg>
  );
};

export default IPhoneQrIcon;
