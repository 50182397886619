import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 3.85rem;

  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.gray200};

  textarea {
    width: 100%;
    margin: 0 auto;
    height: 6.42rem;
    max-width: 800px;
    border-radius: 0.28rem;
    padding: 0.5rem 1rem;
    border: 1px solid ${({ theme }) => theme.colors.gray100};

    resize: none;
    font-weight: 500;
    line-height: 150%;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray500};
    background-color: ${({ theme }) => theme.colors.backgroundColor};

    &::placeholder {
      color: ${({ theme }) => theme.colors.placeholderGray};
    }
  }

  .characters-count {
    width: 100%;
    max-width: 800px;

    margin: 0.21rem auto 0;

    font-weight: 600;
    text-align: right;
    line-height: 150%;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray500};
  }

  span {
    max-width: 800px;
    margin: 0.5rem auto 0;

    font-weight: 600;
    text-align: left;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: ${({ theme }) => (theme.isDarkMode ? theme.colors.placeholderGray : theme.colors.gray500)};
  }
`;
