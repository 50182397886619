/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TiMinus as Minus, TiPlus as Plus } from 'react-icons/ti';

export const Container = styled.div`
  position: relative;

  padding: 1rem 0.25rem 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};

  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
`;

export const Image = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
`;

export const OptionInfoWrapper = styled.div``;

export const OptionName = styled.p`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${({ theme }) => (theme.isDarkMode ? rgba(theme.colors.textColor, 0.8) : theme.colors.textColor)};

  @media screen and (max-width: 720px) {
    max-width: 18.57rem;

    word-break: break-word;
  }

  @media screen and (max-width: 360px) {
    max-width: 11.42rem;

    word-break: break-word;
  }
`;

export const OptionInfo = styled.p`
  margin: 0.25rem 0 0;

  font-weight: 500;
  line-height: 150%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const OptionPrice = styled.div<{ $hasPromotion?: boolean }>`
  ${({ theme, $hasPromotion }) => css`
    margin-right: 0.85rem;

    gap: 0.25rem;
    display: flex;
    flex-direction: row;

    > span {
      font-size: 1rem;
      font-weight: 600;
      text-align: right;
      white-space: nowrap;
      line-height: 1.5rem;
      color: ${theme.isDarkMode ? rgba(theme.colors.textColor, 0.8) : theme.colors.textColor};

      &.-small {
        font-size: 1rem;
        font-weight: 500;
      }

      ${$hasPromotion &&
      css`
        text-decoration: line-through;
        color: ${theme.colors.gray400};

        &.old-value {
          color: ${rgba(theme.colors.textColor, 0.5)};
        }
      `}
    }
  `}
`;

export const ActionWrapper = styled.div`
  margin-left: auto;

  gap: 0.5rem;
  display: flex;
  align-items: center;
`;

export const RadioC = styled.label<{ isGoomerColor?: boolean }>`
  ${({ theme, isGoomerColor }) => css`
    position: relative;

    width: 24px;
    height: 24px;

    display: block;

    cursor: pointer;
    pointer-events: none;

    > input {
      position: absolute;

      width: 0;
      height: 0;

      opacity: 0;
      cursor: pointer;

      &:checked ~ .mark {
        border: 5px solid
          ${isGoomerColor
            ? theme.colors.primary
            : css`
                ${theme.brandColors.actionBackground}
              `};

        background: ${isGoomerColor
          ? theme.colors.backgroundColor
          : css`
              ${theme.brandColors.actionText};
            `};
      }
    }

    > .mark {
      position: absolute;
      top: 0;
      left: 0;

      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.colors.placeholderGray};

      background: ${({ theme }) => theme.colors.lightestGray};
    }
  `}
`;

export const CheckboxC = styled(RadioC)<{ isGoomerColor?: boolean }>`
  ${({ theme, isGoomerColor }) => css`
    .mark {
      border-radius: 3px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 14px;
        height: 10px;

        path {
          fill: ${theme.colors.lightestGray};
          stroke: ${theme.colors.lightestGray};
        }
      }
    }

    > input {
      &:checked ~ .mark {
        border-width: 0;

        background: ${isGoomerColor
          ? theme.colors.primary
          : css`
              ${theme.brandColors.actionBackground}
            `};

        > svg {
          path {
            fill: ${isGoomerColor ? '#fff' : theme.brandColors.actionText};
            stroke: ${isGoomerColor ? '#fff' : theme.brandColors.actionText};
          }
        }
      }
    }
  `}
`;

export const NumberBtnC = styled.div`
  display: flex;
  align-items: center;
  justify-items: space-between;

  .btn {
    padding: 2px;
    border: none;

    background: transparent;
  }

  .value {
    width: 20px;

    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
`;

export const TiMinus = styled(Minus)<{ isDisabled: string; isGoomerColor?: boolean }>`
  ${({ theme, isDisabled, isGoomerColor }) => css`
    color: ${isGoomerColor
      ? theme.colors.primary
      : css`
          ${theme.brandColors.primary};
        `};

    ${isDisabled === 'true' &&
    css`
      color: ${theme.colors.placeholderGray};
    `}
  `}
`;

export const TiPlus = styled(Plus)<{ isDisabled: string; isGoomerColor?: boolean }>`
  ${({ theme, isDisabled, isGoomerColor }) => css`
    color: ${isGoomerColor
      ? theme.colors.primary
      : css`
          ${theme.brandColors.primary};
        `};

    ${isDisabled === 'true' &&
    css`
      color: ${theme.colors.placeholderGray};
    `}
  `}
`;
