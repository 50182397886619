import { Reducer } from 'redux';

import { WaiterActionTypes, WaiterState } from './types';

const initialState: WaiterState = {
  callWaiter: null
};

const WaiterReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case WaiterActionTypes.SET_CALL_WAITER:
      return {
        ...state,
        callWaiter: payload
      };

    default:
      return state;
  }
};

export default WaiterReducer;
