import styled, { css, DefaultTheme } from 'styled-components';

const modifiers = {
  isTicket: (theme: DefaultTheme) => css`
    margin: 0;
    padding: 56px 44px 72px;

    background: ${theme.colors.backgroundColor};
  `
};

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;

  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<{ $isTicket?: boolean }>`
  ${({ theme, $isTicket }) => css`
    padding: 1.5rem 1rem 2rem 1rem;

    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: ${theme.colors.backgroundColor};

    > .title {
      width: 90%;
      margin-top: 2rem;

      font-size: 18px;
      font-weight: 800;
      line-height: 150%;
      text-align: center;
      color: ${theme.colors.textColor};
    }

    > .message {
      width: 90%;
      margin-top: 8px;

      color: ${theme.colors.gray500};
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      text-align: center;
    }

    ${$isTicket && modifiers.isTicket(theme)};
  `}
`;

export const Footer = styled.div`
  margin-top: auto;
  margin-bottom: 1.5rem;

  display: flex;
  justify-content: center;
`;
