import { VisualFlags } from '~/interfaces';
import {
  VeganIcon,
  GlutenIcon,
  Pepper1Icon,
  Pepper2Icon,
  Pepper3Icon,
  VegetarianIcon,
  OfLegalAgeIcon,
  LactoseFreeIcon
} from '~/assets/productTags';

import * as S from './styles';

export interface ProductTagsProps {
  ofLegalAge?: boolean;
  visualFlags: VisualFlags;
}

const ProductTags: React.FC<ProductTagsProps> = ({ ofLegalAge, visualFlags }) => {
  const chiliIconList = [
    { icon: <></>, label: 'Nível de picância 0', isVisible: visualFlags.spiciness_level === 0 },
    { icon: <Pepper1Icon />, label: 'Nível de picância 1', isVisible: visualFlags.spiciness_level === 1 },
    { icon: <Pepper2Icon />, label: 'Nível de picância 2', isVisible: visualFlags.spiciness_level === 2 }, // Change icon to pepper 2
    { icon: <Pepper3Icon />, label: 'Nível de picância 3', isVisible: visualFlags.spiciness_level === 3 } // Change icon to pepper 3
  ];

  const tagList = [
    { icon: <GlutenIcon />, label: 'Produto livre de gluten', isVisible: visualFlags.is_gluten_free },
    { icon: <VeganIcon />, label: 'Produto vegano', isVisible: visualFlags.is_vegan },
    { icon: <VegetarianIcon />, label: 'Produto vegetariano', isVisible: visualFlags.is_vegetarian },
    ...chiliIconList,
    { icon: <LactoseFreeIcon />, label: 'Produto sem lactose', isVisible: visualFlags.is_lactose_free }, // Change icon to milk off
    { isVisible: ofLegalAge, icon: <OfLegalAgeIcon />, label: 'Produto para maiores de 18' }
  ];

  return (
    <S.Container>
      {tagList
        .filter((tag) => tag.isVisible)
        .map((tag) => (
          <S.IconWrapper aria-label={tag.label} key={tag.label}>
            {tag.icon}
          </S.IconWrapper>
        ))}
    </S.Container>
  );
};

export default ProductTags;
