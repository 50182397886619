import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Modal } from '~/components';
import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';

import * as S from './styles';

export interface CompleteProgramModalProps {
  isOpen: boolean;
  onClose: () => void;
  discountValue: number;
  expirationDate: string;
}

const CompleteProgramModal = ({
  isOpen,
  onClose,
  discountValue,
  expirationDate
}: CompleteProgramModalProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  return (
    <Modal onClose={onClose} isShow={isOpen} isSmall hideCloseButton>
      <S.Content>
        <LazyLoadImage src="/assets/loyalty-medal.svg" width={90} height={108} />

        <S.Section>
          <S.SectionTitle>{getTranslation('loyalty.loyaltyProgramConcluded')}</S.SectionTitle>

          <S.SectionDescription>
            {getTranslation('loyalty.discountEarned', { discountValue: convertToCurrency(discountValue) })}
          </S.SectionDescription>

          <S.SectionExpiration>
            {getTranslation('general.validUntil')} {expirationDate}
          </S.SectionExpiration>
        </S.Section>

        <S.Button type="button" onClick={onClose}>
          {getTranslation('splash.seeMenu')}
        </S.Button>
      </S.Content>
    </Modal>
  );
};

export default CompleteProgramModal;
