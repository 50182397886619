import React from 'react';

import { convertToCurrency } from '~/utils';
import { useShowOrdersCount, useTranslator } from '~/hooks';
import { MountedOrderIntegratedPix, MountedNupayOrder } from '~/interfaces/general';

import * as S from './styles';

export interface PaymentOrderInfoProps {
  logoUrl?: string;
  order?: MountedNupayOrder | MountedOrderIntegratedPix;
}

const PaymentOrderInfo: React.FC<PaymentOrderInfoProps> = ({ order, logoUrl }) => {
  const { getTranslation } = useTranslator();
  const { showOrdersCount: shouldDisplayOrdersCount } = useShowOrdersCount();

  return (
    <S.Container>
      {!!logoUrl && (
        <S.Logo>
          <img src={logoUrl} width={30} height={30} />
        </S.Logo>
      )}

      <S.OrderInfoContent>
        <div className="line">
          {!!order?.establishment?.name && <h3>{order?.establishment.name}</h3>}

          {!!order?.orderCount && shouldDisplayOrdersCount && <span>#{String(order.orderCount).padStart(4, '0')}</span>}
        </div>

        {!!order?.cart?.values?.total && (
          <h2>
            {getTranslation('general.total')}: {convertToCurrency(order.cart.values.total)}
          </h2>
        )}
      </S.OrderInfoContent>
    </S.Container>
  );
};

export default PaymentOrderInfo;
