import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IApplicationState } from '~/redux-tools/store';
import { RiAddLine, RiSubtractLine, RiDeleteBinLine } from 'react-icons/ri';

import * as S from './styles';

interface ItemQuantityProps {
  quantity: number;
  onIncrease: () => void;
  onDecrease: () => void;
  onRemoveItem?: () => void;
}

const ItemQuantity = ({ quantity, onDecrease, onIncrease, onRemoveItem }: ItemQuantityProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const isOneQuantity = useMemo(() => quantity === 1, [quantity]);

  const whichInfoAndActionShouldRender = useCallback(() => {
    if (isOneQuantity && onRemoveItem) {
      return {
        action: onRemoveItem,
        icon: <RiDeleteBinLine style={{ color: theme.colors.error }} size={18} />
      };
    }

    return {
      action: onDecrease,
      icon: <RiSubtractLine size={18} />
    };
  }, [isOneQuantity, onDecrease, onRemoveItem, theme.colors.error]);

  const { action, icon } = whichInfoAndActionShouldRender();

  return (
    <S.Container>
      <S.ActionButton changeBackgroundColor={isOneQuantity} isSimple onClick={(): void => action()}>
        {icon}
      </S.ActionButton>
      <S.Quantity>{quantity}</S.Quantity>
      <S.ActionButton isSimple onClick={(): void => onIncrease()}>
        <RiAddLine size={18} />
      </S.ActionButton>
    </S.Container>
  );
};

export default ItemQuantity;
