import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 100%;

  .tip {
    margin: 10px 0 0;

    p {
      font-size: 12px;
      line-height: 150%;
      text-align: left;
      color: ${({ theme }) => theme.colors.mediumGray};
    }
  }

  .pix-tip {
    margin-top: 8px;

    font-size: 12px;
    font-weight: 500;
    text-align: left;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.mediumGray};
  }
`;

export const PixInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .action {
    font-weight: 600;
    line-height: 150%;
    font-size: 0.75rem;
    text-decoration: underline;
    color: ${({ theme }) => theme.brandColors.primary};
  }
`;

export const MoreInfo = styled.div`
  position: relative;
`;

export const Divider = styled.hr`
  ${({ theme }) => css`
    width: 100%;
    margin: 0.5rem 0;
    border: 1px solid ${theme.colors.lightGray};
  `}
`;

export const Field = styled.div<{ isColumn?: boolean }>`
  ${({ theme, isColumn = false }) => css`
    margin-bottom: 0.25rem;

    display: flex;
    align-items: flex-start;
    flex-direction: ${isColumn ? 'column' : 'row'};

    strong,
    span,
    p {
      text-align: left;
      font-weight: 600;
      line-height: 150%;
      font-size: 0.75rem;
    }

    strong {
      color: ${theme.colors.textColor};
    }

    span,
    p {
      width: 100%;

      color: ${theme.colors.gray500};
    }

    textarea {
      position: absolute;
      left: -9999px;

      border: none;

      font-size: 1px;
      resize: none;

      &:disabled {
        background: transparent;
      }
    }
  `}
`;

export const CopyKey = styled.button`
  ${({ theme }) => css`
    position: absolute;
    bottom: 62px;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    border: none;
    padding: 6px;
    padding: 12px 0 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;

    span {
      margin-left: 4px;

      font-size: 12px;
      font-weight: 500;
      color: ${theme.brandColors.primary};
    }

    svg {
      path {
        fill: ${theme.brandColors.primary};
      }
    }
  `}
`;
