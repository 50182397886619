import { action } from 'typesafe-actions';

import { PocketMountedOrder } from '~/interfaces';
import { MountedOrder } from '~/interfaces/general';

import { LastOrderActionTypes } from './types';

export const addLastOrder = (order: MountedOrder) => {
  return action(LastOrderActionTypes.ADD_LAST_ORDER, order);
};

export const addLastPocketOrder = (order: PocketMountedOrder) => {
  return action(LastOrderActionTypes.ADD_LAST_POCKET_ORDER, order);
};

export const cleanLastOrder = () => {
  return action(LastOrderActionTypes.CLEAN_LAST_ORDER);
};
