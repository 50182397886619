import { useCallback } from 'react';
import { Id, toast } from 'react-toastify';

import useTranslator from '../useTranslator';

interface UseCopyToClipboardProps {
  copyText?: string;
  showToast?: boolean;
  inputElement: HTMLInputElement | HTMLTextAreaElement | null;
}

interface UseCopyToClipboardReturnProps {
  copyToClipboard: ({ inputElement, copyText, showToast }: UseCopyToClipboardProps) => void;
}

export default function useCopyToClipboard(): UseCopyToClipboardReturnProps {
  const { getTranslation } = useTranslator();

  const copyToClipboard = useCallback(
    ({ copyText, inputElement, showToast = true }: UseCopyToClipboardProps) => {
      if (!inputElement || !document) return;

      inputElement?.select();
      inputElement?.setSelectionRange(0, 9999);

      document.execCommand('copy');

      if (showToast) {
        toast.info(copyText ?? getTranslation('general.successfullyCopied'));
      }

      return;
    },
    [getTranslation]
  );

  return { copyToClipboard };
}
