import { api } from '~/services/api';
import { ErrorLogEntry } from '~/interfaces/general';
import { isObjectInString } from '~/utils/convertSettingsData';

export default async function sendErrorLog(log: ErrorLogEntry) {
  try {
    const order = !!log.payload && isObjectInString(log.payload) ? { ...JSON.parse(log.payload) } : {};

    const data = {
      order,
      error_info: {
        ...log,
        payload: undefined
      }
    };

    const response = await api.post('/orders-error-save', data);

    return response.data;
  } catch (error) {
    throw new Error((error as Error)?.message);
  }
}
