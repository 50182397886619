import React, { useMemo } from 'react';

import Image from 'next/image';
import FadeIn from 'react-fade-in';
import { useTranslator } from '~/hooks';

import * as S from './styles';

interface OfflineInfoProps {
  goBack?: () => void;
  itMustReloadPage?: boolean;
}

const OfflineInfo = ({ goBack, itMustReloadPage = false }: OfflineInfoProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  const renderAction = useMemo(() => {
    if (itMustReloadPage) {
      return (
        <S.Action onClick={(): void => window.location.reload()} title="action">
          <p>{getTranslation('general.tryAgain')}</p>
        </S.Action>
      );
    }

    return (
      <S.Action onClick={goBack} title="action">
        <p>{getTranslation('general.back')}</p>
      </S.Action>
    );
  }, [getTranslation, goBack, itMustReloadPage]);

  return (
    <FadeIn className="child">
      <S.Container>
        <Image
          width={143}
          height={150}
          layout="fixed"
          src="/assets/offline.svg"
          aria-label="Ilustração de desconexão"
        />

        <p className="title">{getTranslation('offline.youAreNotConnected')}</p>

        <p className="message">{getTranslation('offline.checkYourConnection')}</p>

        {renderAction}
      </S.Container>
    </FadeIn>
  );
};

export default OfflineInfo;
