import styled, { css } from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const Container = Modal
  ? styled(Modal)`
      z-index: 1003;

      ${({ theme }) => css`
        .container {
          position: relative;

          height: 100vh;
          padding-bottom: 0;

          background: ${theme.colors.gray200};

          > .title {
            border-bottom: 1px solid ${theme.colors.gray300};

            background: ${theme.colors.backgroundColor};
          }

          .content {
            display: flex;
            flex-direction: column;

            background: ${theme.colors.backgroundColor};
          }
        }
      `}
    `
  : styled.div<ModalProps>``;

export const Content = styled.div`
  ${({ theme }) => css`
    position: relative;

    padding-bottom: 4px;

    background: ${theme.colors.gray200};
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    width: 100%;

    background: ${theme.colors.backgroundColor};
  `}
`;

export const Receipt = styled.li`
  ${({ theme }) => css`
    padding: 12px 16px;
    border-bottom: 1px solid ${theme.colors.gray300};

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
    transition: 0.3s;
    background: ${theme.colors.backgroundColor};

    p {
      font-weight: 800;
      color: ${theme.colors.gray600};

      &.value {
        color: ${theme.colors.success};
      }
    }

    &:hover {
      transition: 0.3s;
      background: ${theme.colors.gray200};
    }

    :last-child {
      padding-bottom: 12px;
      border-bottom: 1px solid transparent;
    }
  `}
`;
