import React from 'react';

const TableIcon: React.FC = () => (
  <svg className="table-icon" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.31538 5.3892V4.95061L0.920716 4.75931L0.7 4.65232V4.25973L8 0.77564L15.3 4.25973V4.65232L15.0793 4.75931L14.6846 4.95061V5.3892V10.6989C14.6846 10.7069 14.6844 10.7138 14.684 10.7197C14.649 10.7354 14.5821 10.7545 14.4615 10.7545C14.341 10.7545 14.2741 10.7354 14.239 10.7197C14.2387 10.7138 14.2385 10.7069 14.2385 10.6989V6.2642V5.15348L13.2364 5.63271L8.62106 7.8401L8.22308 8.03044V8.47159V13.2443C8.22308 13.2523 8.22285 13.2592 8.22251 13.2652C8.18746 13.2809 8.12053 13.3 8 13.3C7.87948 13.3 7.81254 13.2809 7.77749 13.2652C7.77715 13.2592 7.77692 13.2523 7.77692 13.2443V8.47159V8.03044L7.37895 7.8401L2.76356 5.63271L1.76154 5.15348V6.2642V10.6989C1.76154 10.7069 1.76131 10.7138 1.76097 10.7197C1.72592 10.7354 1.65899 10.7545 1.53846 10.7545C1.41794 10.7545 1.351 10.7354 1.31596 10.7197C1.31561 10.7138 1.31538 10.7069 1.31538 10.6989V5.3892Z"
      stroke="#30333C"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
);

export default TableIcon;
