import { Address } from '~/interfaces/general';

export interface GenerateMpCheckoutInfoReturnV1 {
  firstName: string;
  lastName: string;
  streetName: string;
  streetNumber: string;
  streetZipcode: string;
  phoneNumber: string;
  phonePrefix: string;
}

export interface GenerateMpCheckoutInfoReturnV2 {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: {
    streetName: string;
    streetNumber: string;
    streetZipcode: string;
  };
}

export interface GenerateMpCheckoutInfoProps {
  address?: Address & { zipcode?: string };
  clientName: string;
  clientPhone: string;
  clientEmail?: string;
}

export default function generateMpCheckoutInfo({
  address,
  clientName,
  clientPhone,
  clientEmail
}: GenerateMpCheckoutInfoProps) {
  const firstName = clientName.split(' ').shift() || '';
  const lastName = clientName.substring(firstName.length, clientName.length).trim();

  const phoneInfo = (() => {
    if (clientPhone.length === 15 || clientPhone.length === 14) {
      return {
        phoneNumber: clientPhone.substring(5, clientPhone.length).replace('-', ''),
        phonePrefix: clientPhone.substring(1, 3)
      };
    }

    return {
      phoneNumber: clientPhone,
      phonePrefix: ''
    };
  })();

  return {
    firstName,
    lastName,
    email: clientEmail,
    phone: phoneInfo,
    address: {
      streetName: address?.street || '',
      streetNumber: address?.number || '',
      streetZipcode: address?.cep || address?.zipcode || ''
    }
  };
}
