import { useCallback } from 'react';
import br from 'date-fns/locale/pt-BR';
import { format, isToday } from 'date-fns';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';
import { getTermIntoString, parseTimezone } from '@goomerdev/goomer-toolbox/src/utils';

import { handleCouponDiscount } from '~/utils/orderCalculations';
import { PaymentMethod, WhatsBuilderProps } from '~/interfaces/general';
import { printCpfOnBill, printOrder, printTotal } from '~/utils/whatsappOrderBuilder';
import { convertToCurrency, removeNoNumbers, phoneMask, compareStrings } from '~/utils';
import {
  QueryParam,
  DeliveryWayEnum,
  SegmentTypeEnum,
  PaymentFlagEnum,
  DiscountTypeEnum,
  DeliveryPricingEnum,
  DeliveryZoneTypeEnum
} from '~/interfaces/enums';

import useHandlePaymentTexts from '../useHandlePaymentTexts';
import useTranslator from '../useTranslator';

export interface UseGetWhatsMessageProps {
  getWhatsMessage: (props: WhatsBuilderProps) => string;
}

export default function useGetWhatsMessage(): UseGetWhatsMessageProps {
  const { getTranslation } = useTranslator();
  const { handlePaymentTexts } = useHandlePaymentTexts();

  const getWhatsMessage = useCallback(
    ({
      cart,
      coupon,
      client,
      linkURL,
      orderID,
      schedule,
      settings,
      deliveryWay,
      paymentOption,
      nupayDocument,
      customMessage = '',
      withPWAInfo = true,
      moneyChangeValue = 0,
      isShortMessage = false
    }: WhatsBuilderProps): string => {
      const storeName = String(settings.name);
      const showPrices = Boolean(settings.mm_order_show_prices);
      const showBarcodes = Boolean(settings.mm_order_show_codes);

      const pixInfo = settings.mm_payment_pix_info;
      const slug = settings.slug;

      const showOrdersCount = Boolean(
        typeof settings.mm_order_show_orders_count !== 'undefined' ? settings.mm_order_show_orders_count : true
      );

      const takeawayDiscount =
        (settings?.mm_takeaway_enabled && settings.mm_takeaway_discount_enabled && settings.mm_takeaway_discount) || '';

      const isTakeaway = deliveryWay === DeliveryWayEnum.takeaway;

      const hasDiscount = Number(takeawayDiscount) > 0;
      const discountInfo = {
        label: takeawayDiscount ? `${takeawayDiscount}%` : '',
        value: takeawayDiscount ? Number(takeawayDiscount) / 100 : 0
      };

      const separator = '---------------------------------------\n\n';

      const wasCpfCompletelyTyped = (cpf: string) => cpf.replace(/\D/g, '').length === 11;
      const wasNumberCompletelyTyped = (phone: string) => phone.replace(/\D/g, '').length >= 10;

      const getPreparingTime = () => {
        const hideTime = (() => {
          if (isTakeaway) return false;

          if (settings.mm_delivery_zone_type === DeliveryZoneTypeEnum.static) {
            return (
              !!settings.mm_delivery_zone_options_static &&
              !settings.mm_delivery_zone_options_static.delivery_time_enabled
            );
          }

          return !!cart?.deliveryFee && 'hide_time' in cart.deliveryFee && cart.deliveryFee.hide_time;
        })();

        const handleDeliveryTime = (time: number | { from: number; to: number } | undefined) => {
          if (!cart.deliveryFee) {
            return '';
          }

          const deliveryTimeDisabled =
            'delivery_time_enabled' in cart.deliveryFee && !cart.deliveryFee.delivery_time_enabled;
          if (hideTime || deliveryTimeDisabled) {
            return '';
          }

          if (typeof time === 'number' && cart.deliveryFee?.time) {
            return ` ${removeNoNumbers(String(cart.deliveryFee?.time))} minutos`;
          }

          if (typeof time === 'object') {
            if (time.from === time.to || time.from === 0) {
              return ` ${removeNoNumbers(time.to)} minutos`;
            }

            return ` de ${removeNoNumbers(time.from)} minutos à ${removeNoNumbers(time.to)} minutos`;
          }

          return '';
        };

        if (!!schedule.is_scheduled && !!schedule.scheduled_to) {
          const schedulingText = (() => {
            const day = isToday(schedule.scheduled_to)
              ? 'Hoje'
              : format(new Date(schedule.scheduled_to), 'dd/MM', { locale: br });

            const hours = format(new Date(schedule.scheduled_to), 'HH:mm');

            return `${day}, ${hours}`;
          })();

          if (deliveryWay === DeliveryWayEnum.delivery) {
            return `*Entrega agendada:* ${schedulingText}\n\n`;
          } else {
            return `*Retirada agendada:* ${schedulingText}\n\n`;
          }
        }

        if (deliveryWay === DeliveryWayEnum.delivery && cart.deliveryFee?.time && !hideTime) {
          return `*Tempo de entrega:*${handleDeliveryTime(cart.deliveryFee?.time)}\n\n`;
        }

        if (isTakeaway && !!settings?.mm_takeaway_time && settings?.mm_takeaway_time !== '0' && !hideTime) {
          return `*Tempo de retirada:*${` ${removeNoNumbers(settings?.mm_takeaway_time)} minutos`}\n\n`;
        }

        if (isTakeaway) {
          return '*Pedido para retirada*\n\n';
        }
      };

      const getPaymentText = (paymentOption: PaymentMethod, isOnlinePayment?: boolean, paymentURL?: string) => {
        const isNupay = paymentOption.category === PaymentCategoryEnum.nupay;
        const isPixPayment = paymentOption.category === PaymentCategoryEnum.pix;
        const isGooglePay = paymentOption.category === PaymentCategoryEnum.googlePay;
        const isTunaPayment = paymentOption.category === PaymentCategoryEnum.tunaCheckout;
        const isVRPaguePayment = paymentOption.category === PaymentCategoryEnum.vrPagueLink;
        const isIntegratedPix = paymentOption.category === PaymentCategoryEnum.integratedPix;

        if (
          !!paymentOption.flag &&
          compareStrings(paymentOption.flag, getTranslation(PaymentFlagEnum.OnlinePaymentLink))
        ) {
          const paymentLink = paymentURL ? `\nClique no link para pagar ${paymentURL}` : '';
          return `*${handlePaymentTexts(paymentOption.category || '')}*${paymentLink}`;
        }

        if (isIntegratedPix) {
          return 'Pix';
        }

        if (isNupay) {
          return 'Nubank';
        }

        if (isGooglePay) {
          return 'Google Pay';
        }

        if (isVRPaguePayment) {
          return '\n*Aguarde o envio do link de pagamento VR Pague. Sujeito a confirmação pela loja.';
        }

        if (
          !!paymentOption.flag &&
          compareStrings(paymentOption.flag, getTranslation(PaymentFlagEnum.MercadoPagoQrCode))
        ) {
          return `${paymentOption.flag} ${handlePaymentTexts(paymentOption.category || '')}`;
        }

        if (isOnlinePayment && !isTunaPayment) {
          return '\n*Sujeito a confirmação pela loja';
        }

        if (isPixPayment) {
          return `${paymentOption.flag}\n*Nome da conta Pix:* ${pixInfo.accountName}\n*Chave Pix:* ${pixInfo.key}\n\nCopie a chave e faça o pagamento através do Pix. O restaurante irá conferir o pagamento para liberação do seu pedido.`;
        }

        return `${paymentOption.category ? handlePaymentTexts(paymentOption.category) : ''} ${
          paymentOption.flag ? paymentOption.flag : ''
        }`;
      };

      const getUserOrderOptions = (paymentURL?: string): string => {
        let isCashPayment;
        const message = [];
        const { address } = cart;
        let whatsFormattedAddress = '';

        if (address) {
          whatsFormattedAddress = `${address.street || ''}, ${address.number || ''}${
            address.complement ? ` - ${address.complement}` : ''
          }\n${address.neighborhood || ''}, ${address.city || ''}/${address.state || ''}${
            address.cep ? `\n${address.cep}` : ''
          }${address.reference ? `\n${address.reference}` : ''}`;
        }

        if (deliveryWay === DeliveryWayEnum.delivery) {
          message.push(`${whatsFormattedAddress}\n\n`);
        }

        if (typeof paymentOption === 'string') {
          isCashPayment = getTermIntoString({ word: paymentOption || '', term: PaymentCategoryEnum.cash });
        } else {
          isCashPayment = getTermIntoString({
            term: PaymentCategoryEnum.cash,
            word: paymentOption.category || ''
          });
        }

        if (typeof paymentOption === 'object') {
          const isNupay = paymentOption.category === PaymentCategoryEnum.nupay;
          const isGooglePay = paymentOption.category === PaymentCategoryEnum.googlePay;
          const isTunaPayment = paymentOption.category === PaymentCategoryEnum.tunaCheckout;
          const isIntegratedPix = paymentOption.category === PaymentCategoryEnum.integratedPix;

          const isOnlinePayment =
            paymentOption.flag?.includes('****') || isTunaPayment || isIntegratedPix || isNupay || isGooglePay;

          const paymentText = getPaymentText(paymentOption, isOnlinePayment, paymentURL);

          const paymentLabel = '*Pagamento:*';
          const onlinePaymentLabel =
            isTunaPayment || isIntegratedPix || isNupay || isGooglePay
              ? '*Pagamento Online:*'
              : '*Pagamento online pelo app*';

          const paymentOptionText = `${isOnlinePayment ? onlinePaymentLabel : paymentLabel} ${paymentText}\n`;
          message.push(paymentOptionText);

          if (isNupay && !!nupayDocument && nupayDocument !== undefined) {
            message.push(`${printCpfOnBill(nupayDocument)}\n`);
          }
        }

        if (isCashPayment) {
          message.push(`*Troco para:* ${moneyChangeValue === 0 ? 'Sem Troco' : convertToCurrency(moneyChangeValue)}\n`);
        }

        return message.join('');
      };

      const delivery = () => {
        if (typeof settings === 'undefined') return undefined;

        if (!settings.mm_delivery_enabled) return undefined;

        const delivery_enabled =
          settings.mm_delivery_enabled &&
          cart.deliveryFee &&
          'pricing' in cart.deliveryFee &&
          cart.deliveryFee?.pricing !== DeliveryPricingEnum.outside &&
          cart.deliveryFee?.pricing !== DeliveryPricingEnum.notFound;

        const delivery_fee =
          cart.deliveryFee && 'pricing' in cart.deliveryFee
            ? cart.deliveryFee.pricing === DeliveryPricingEnum.ask
              ? cart.deliveryFee.pricing
              : Number(cart.deliveryFee.pricing === DeliveryPricingEnum.free ? 0 : cart.deliveryFee.pricing)
            : undefined;

        const delivery_required_amount = settings.mm_delivery_minimum_value_enabled
          ? Number(settings.mm_delivery_minimum_value)
          : 0;

        const takeaway_discount = takeawayDiscount ? Number(takeawayDiscount) : 0;

        return {
          delivery_fee,
          delivery_enabled,
          takeaway_discount,
          delivery_required_amount
        };
      };

      // Header
      const mHeader = () => {
        let mOrderID = '';

        if (showOrdersCount) {
          if (!!orderID && orderID !== 'false' && orderID !== 'undefined') {
            mOrderID = ` #${orderID?.padStart(4, '0')}`;
          }
        }

        const FINAL_URL = process.env.NEXT_PUBLIC_FINAL_URL;

        const pwaCallToAction = withPWAInfo
          ? `⭐ *GOSTOU DE PEDIR NO NOSSO APP?* ⭐\nNão precisa baixar nada, adicione o nosso restaurante na tela inicial do seu celular e peça com mais agilidade na próxima vez através do link abaixo:\nhttps://${slug}.${FINAL_URL}/?${QueryParam.utmSource}=whatsapp\n---------------------------------------\nConfira o pedido abaixo:\n`
          : '';

        const parsedCustomMessage = customMessage ? `${customMessage}\n\n` : '';

        const deliveryOrderId = `*Pedido Goomer Delivery${mOrderID}*`;

        const formattedShortMessage = `${parsedCustomMessage}${deliveryOrderId}\n\n`;

        const formattedFullMessage = `${pwaCallToAction}${parsedCustomMessage}${deliveryOrderId} - _${storeName.trim()}_\n${separator}`;

        return isShortMessage ? formattedShortMessage : formattedFullMessage;
      };

      // Product list
      const mProducts = () => `${printOrder(cart.products, showPrices, showBarcodes)}\n\n`;

      let totalWithDeliveryFee = cart.total - (cart.values.loyalty || 0);
      let deliveryFeeMessage = '';

      const deliveryOptions = delivery();

      const isDelivery = deliveryOptions?.delivery_enabled && deliveryOptions.delivery_fee !== undefined && !isTakeaway;

      // Price total
      const mTotal = () => {
        if (isDelivery) {
          const isFreeDelivery = () =>
            settings?.mm_free_delivery_enabled && cart.total > Number(settings.mm_free_delivery_minimum_value);

          const printDeliveryFee = printTotal({
            cart,
            separator,
            showPrices,
            hasCoupon: !!coupon,
            deliveryInfo: deliveryOptions,
            isFreeDelivery: isFreeDelivery() || false
          });

          totalWithDeliveryFee = printDeliveryFee.subtotal;

          deliveryFeeMessage = printDeliveryFee?.taxMessage || '';

          return printDeliveryFee.message;
        }

        const label = hasDiscount || !!coupon ? 'Subtotal' : 'Total';
        const prettyValue = convertToCurrency(cart.total - (cart.values.loyalty || 0));
        const lineBreak = (hasDiscount || !!coupon) && isTakeaway ? '' : `\n${separator}`;

        return `*${label}:* ${prettyValue}\n${lineBreak}`;
      };

      const mCoupon = () => {
        if (!coupon) return '';

        const isPercentage = coupon.type === DiscountTypeEnum.percent;

        const getCouponMessage = (prettyDiscount: string) => {
          const couponSubtotal = convertToCurrency(cart.values.subtotal - cart.values.coupon);

          const couponType = coupon.segment === SegmentTypeEnum.first_order ? 'Desconto Primeiro Pedido' : 'Cupom';

          const couponCodeMessage = coupon.segment !== SegmentTypeEnum.first_order ? `| *${coupon.code}*` : '';

          const subtotalMessage =
            cart.values.delivery_fee > 0 || cart.values.takeaway_discount > 0
              ? `\n*Subtotal ${
                  coupon.segment === SegmentTypeEnum.first_order ? 'Desconto' : 'Cupom'
                }:* ${couponSubtotal}`
              : '';

          return isPercentage
            ? `*${couponType} (${coupon.value}%):* -${prettyDiscount} ${couponCodeMessage}${subtotalMessage}\n${deliveryFeeMessage}`
            : `*${couponType}:* -${prettyDiscount} ${couponCodeMessage}${subtotalMessage}\n${deliveryFeeMessage}`;
        };

        return getCouponMessage(convertToCurrency(cart.values.coupon));
      };

      // Loyalty
      const mLoyalty = () => {
        if (!!cart.values.loyalty && cart.values.loyalty > 0 && !isDelivery) {
          return `*Desconto Fidelidade:* -${convertToCurrency(cart.values.loyalty)}\n`;
        }
      };

      // Takeaway promo
      const mTakeawayPromo = () => {
        if (!hasDiscount || !isTakeaway) return '';

        if (coupon) {
          const discountPercent = discountInfo.label;

          const { discount } = handleCouponDiscount({ coupon, subtotal: totalWithDeliveryFee });
          const prettyValue = convertToCurrency((cart.total - discount) * discountInfo.value);

          return `*Desconto para retirar (${discountPercent}):* -${prettyValue}\n`;
        }

        const discountPercent = discountInfo.label;
        const prettyValue = convertToCurrency((cart.total - (cart.values.loyalty || 0)) * discountInfo.value);

        return `*Desconto para retirar (${discountPercent}):* -${prettyValue}\n`;
      };

      // Price with discount applied
      const mFinalPrice = () => {
        const prettyFinalPrice = convertToCurrency(cart.values.total);

        const message = `*Total com desconto: ${prettyFinalPrice}*\n\n${separator}`;

        if (isDelivery && !!coupon) {
          return message;
        }

        if (hasDiscount && isTakeaway && !!coupon) {
          return message;
        }

        if (coupon) {
          return message;
        }

        if (!hasDiscount || !isTakeaway) return '';

        return message;
      };

      // Client name and phone
      const mName = () => {
        const { name, phone } = client;

        const trimName = name?.trim();
        if (!trimName) return '\n';

        return `*${trimName}*\n${phone && wasNumberCompletelyTyped(phone) ? `${phoneMask(phone)}\n\n` : '\n'}`;
      };

      // Client CPF
      const mCpf = () => {
        const { cpf } = client;

        if (!cpf) return '';
        if (!settings.mm_receipt_ask_for_cpf || !wasCpfCompletelyTyped(cpf)) return '';

        return `${printCpfOnBill(cpf)}\n\n`;
      };

      // Order options
      const mUserOrderOptions = () => getUserOrderOptions(linkURL);

      const mPreparingTime = () => getPreparingTime();

      // Order date
      const mTime = () => {
        const newDate = new Date();
        const currentMinute = String(newDate.getMinutes()).padStart(2, '0');
        if (!settings?.mm_timezone.utc) return `${newDate.getHours()}:${currentMinute}`;

        const theTimezoneTime = parseTimezone({ utc: settings?.mm_timezone.utc });
        const hours = String(theTimezoneTime.getHours()).padStart(2, '0');
        const minutes = String(theTimezoneTime.getMinutes()).padStart(2, '0');

        return `\n_Pedido gerado pelo Goomer Delivery às ${hours}:${minutes}_`;
      };

      const shortMessage = [mHeader(), mName()].join('');

      const message = [
        mHeader(),
        mProducts(),
        mTotal(),
        mLoyalty(),
        mCoupon(),
        mTakeawayPromo(),
        mFinalPrice(),
        mPreparingTime(),
        mName(),
        mCpf(),
        mUserOrderOptions(),
        mTime()
      ].join('');

      return encodeURIComponent(isShortMessage ? shortMessage : message);
    },
    [getTranslation, handlePaymentTexts]
  );

  return { getWhatsMessage };
}
