import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    background: ${theme.colors.gray300};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 0;
    padding: 56px 44px 72px;

    background: ${theme.colors.backgroundColor};

    display: flex;
    align-items: center;
    flex-direction: column;}

    > .title {
      width: 100%;
      margin: 16px 0;
      max-width: 272px;

      font-weight: 700;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: ${theme.colors.textColor};
    }

    > .action {
      margin: 0;
      width: 100%;

      cursor: pointer;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      text-decoration-line: underline;
      color: ${theme.brandColors.primary};
    }
  `}
`;

export const WavesWrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 800px;

    background: ${theme.colors.gray200};
  `}
`;
