import styled, { css, DefaultTheme } from 'styled-components';

import { ButtonProps } from '~/components/Button/Button';

interface ContainerProps
  extends Pick<ButtonProps, 'isDisabled' | 'isGhost' | 'isHidden' | 'isLoading' | 'isSimple' | 'isDanger'> {
  hasIcon: boolean;
  isGoomerColor: boolean;
}

const containerModifiers = {
  hasIcon: () => css`
    span {
      margin: 0 auto;
    }
  `,
  isHidden: () => css`
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  `,
  isSimple: (theme: DefaultTheme, isGoomerColor: boolean) => css`
    border: none;

    background-color: transparent;

    span {
      color: ${isGoomerColor
        ? theme.isDarkMode
          ? theme.colors.textColor
          : theme.colors.primary
        : theme.brandColors.primary};

      text-decoration: underline;
    }

    .loader-icon {
      svg {
        path {
          fill: ${isGoomerColor
            ? theme.isDarkMode
              ? theme.colors.textColor
              : theme.colors.primary
            : theme.brandColors.primary};
        }
      }
    }
  `,
  isGhost: (theme: DefaultTheme, isGoomerColor: boolean) => css`
    background-color: transparent;

    border-color: ${isGoomerColor
      ? theme.isDarkMode
        ? theme.colors.textColor
        : theme.colors.primary
      : theme.brandColors.primary} !important;

    span {
      color: ${isGoomerColor
        ? theme.isDarkMode
          ? theme.colors.textColor
          : theme.colors.primary
        : theme.brandColors.primary};
    }

    .loader-icon {
      svg {
        path {
          fill: ${isGoomerColor
            ? theme.isDarkMode
              ? theme.colors.textColor
              : theme.colors.primary
            : theme.brandColors.primary};
        }
      }
    }
  `,
  isDisabled: (theme: DefaultTheme, isGhost: boolean) => css`
    border-color: ${theme.isDarkMode ? theme.colors.gray300 : theme.colors.gray400};

    background-color: ${isGhost ? 'transparent' : theme.isDarkMode ? theme.colors.gray300 : theme.colors.gray400};

    cursor: not-allowed;
    pointer-events: none;

    span {
      color: ${isGhost && !theme.isDarkMode
        ? theme.colors.gray400
        : theme.isDarkMode
        ? '#575F72'
        : theme.colors.backgroundColor};
    }

    .loader-icon {
      svg {
        path {
          fill: ${isGhost && !theme.isDarkMode
            ? theme.colors.gray400
            : theme.isDarkMode
            ? '#575F72'
            : theme.colors.backgroundColor};
        }
      }
    }
  `,
  isDanger: (theme: DefaultTheme, isGhost: boolean) => css`
    border-color: ${theme.colors.error};

    background-color: ${isGhost ? 'transparent' : theme.colors.error};

    span {
      color: ${isGhost ? theme.colors.error : theme.colors.backgroundColor};
    }

    .loader-icon {
      svg {
        path {
          fill: ${isGhost ? theme.colors.error : theme.colors.backgroundColor};
        }
      }
    }
  `
};

export const Container = styled.button<ContainerProps>`
  ${({ theme, hasIcon, isHidden, isSimple, isDisabled, isGoomerColor, isDanger = false, isGhost = false }) => css`
    width: 100%;
    height: 3rem;
    border-radius: 4px;
    border: 1px solid ${isGoomerColor ? theme.colors.primary : theme.brandColors.actionBackground};

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
    background-color: ${isGoomerColor ? theme.colors.primary : theme.brandColors.actionBackground};

    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 1rem;
      font-weight: 500;
      line-height: 150%;
      text-align: center;
      color: ${isGoomerColor ? '#fff' : theme.brandColors.actionText};

      margin-bottom: 0 !important;

      > svg {
        height: 2rem;
      }
    }

    .loader-icon {
      > svg {
        path {
          fill: ${isGoomerColor ? '#fff' : theme.brandColors.actionText};
        }
      }
    }

    ${isGhost && containerModifiers.isGhost(theme, isGoomerColor)};
    ${isSimple && containerModifiers.isSimple(theme, isGoomerColor)};
    ${isDanger && containerModifiers.isDanger(theme, isGhost)};
    ${isDisabled && containerModifiers.isDisabled(theme, isGhost)};

    &[disabled] {
      ${containerModifiers.isDisabled(theme, isGhost)};
    }

    ${isHidden && containerModifiers.isHidden()};
    ${hasIcon && containerModifiers.hasIcon()};
  `}
`;

export const IconWrapper = styled.div`
  margin-right: 0.5rem;

  display: flex;
`;
