import { isMobile } from 'react-device-detect';

interface IParams {
  message: string;
  phone: string;
}

export default function generateWhatsappLink({ message, phone }: IParams) {
  const formatMessage = (text: string): string => text.split(' ').join('%20');
  if (isMobile) {
    return `https://api.whatsapp.com/send?phone=${phone}&text=%20${formatMessage(message)}`;
  }

  return `https://web.whatsapp.com/send?phone=${phone}&text=%20${formatMessage(message)}`;
}
