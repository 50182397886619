import { action } from 'typesafe-actions';

import { DeliveryWay, ICoupon } from '~/interfaces/general';
import { GetCouponErrorMessageProps } from '~/hooks/useGetCouponErrorMessage';

import { CouponsActionTypes } from './types';

export interface FetchCouponRequestProps {
  couponCode: string;
  origin?: DeliveryWay;
  displayErrorMessage: ({ type, info }: GetCouponErrorMessageProps) => string;
  afterAction?: {
    onClose?: () => void;
    setShowError?: () => void;
  };
}

export interface ValidateCouponRequestProps {
  coupon: ICoupon;
  origin: DeliveryWay;
  displayErrorMessage: ({ type, info }: GetCouponErrorMessageProps) => string;
  afterAction?: {
    afterValidation?: () => void;
  };
}

export const fetchCouponRequest = ({ couponCode, origin, afterAction, displayErrorMessage }: FetchCouponRequestProps) =>
  action(CouponsActionTypes.FETCH_COUPON_REQUEST, { couponCode, origin, afterAction, displayErrorMessage });

export const fetchCouponSuccess = (coupon: ICoupon) => action(CouponsActionTypes.FETCH_COUPON_SUCCESS, { coupon });

export const fetchCouponError = (error: string) => action(CouponsActionTypes.FETCH_COUPON_ERROR, { error });

export const couponErrorUpdate = (error?: string) => action(CouponsActionTypes.COUPON_ERROR_UPDATE, { error });

export const cleanCoupon = () => action(CouponsActionTypes.CLEAN_COUPON);

export const setOrigin = (origin: DeliveryWay) => action(CouponsActionTypes.SET_ORIGIN, { origin });

export const fetchExistentCouponsRequest = () => action(CouponsActionTypes.FETCH_EXISTENT_COUPONS_REQUEST);

export const fetchExistentCouponsSuccess = (existCoupons: boolean) =>
  action(CouponsActionTypes.FETCH_EXISTENT_COUPONS_SUCCESS, existCoupons);

export const fetchExistentCouponsError = (error: string) =>
  action(CouponsActionTypes.FETCH_EXISTENT_COUPONS_ERROR, { error });

export const validateCouponRequest = ({
  coupon,
  origin,
  afterAction,
  displayErrorMessage
}: ValidateCouponRequestProps) =>
  action(CouponsActionTypes.VALIDATE_COUPON_REQUEST, { coupon, origin, afterAction, displayErrorMessage });

export const validateCouponError = (error: string) => action(CouponsActionTypes.VALIDATE_COUPON_ERROR, { error });

export const clearSelectedCoupon = () => action(CouponsActionTypes.CLEAN_SELECTED_COUPON);
