import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SegmentTypeEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import {
  fetchCouponRequest,
  fetchExistentCouponsRequest,
  validateCouponRequest
} from '~/redux-tools/store/coupons/actions';

import useGetCouponErrorMessage from '../useGetCouponErrorMessage';

export default function useCheckCoupon(): void {
  const dispatch = useDispatch();

  const { total, products } = useSelector((state: IApplicationState) => state.cart);
  const { data: couponsData, origin, existCoupons } = useSelector((state: IApplicationState) => state.coupons);
  const { settings, coupons: establishmentCoupons } = useSelector((state: IApplicationState) => state.establishment);
  const loyaltyCoupon = useSelector((state: IApplicationState) => state.cart.values.loyalty);
  const { selectedCoupon } = couponsData;

  const [persistedTotal, setPersistedTotal] = useState(total);
  const [persistedOrigin, setPersistedOrigin] = useState(origin);
  const [hasSettingsUpdate, setHasSettingsUpdate] = useState(false);

  const { displayErrorMessage } = useGetCouponErrorMessage();

  useEffect(() => {
    if (settings) {
      setHasSettingsUpdate(true);
    }
  }, [settings]);

  useEffect(() => {
    setPersistedTotal(total);
  }, [settings, total]);

  useEffect(() => {
    setPersistedOrigin(origin);
  }, [origin]);

  useEffect(() => {
    if (settings?.id) {
      dispatch(fetchExistentCouponsRequest());
    }
  }, [dispatch, settings?.id]);

  useEffect(() => {
    if (total === 0 && products.length === 0) {
      return;
    }

    function handleWithOriginChanges() {
      const isCouponFeatureEnabled = !!settings && settings.mm_coupon_enabled;

      const isSomethingChange = (() => {
        const isOriginChanged = persistedOrigin !== origin;
        const isCartTotalChanged = persistedTotal !== total;

        return isCartTotalChanged || isOriginChanged || hasSettingsUpdate;
      })();

      if (isCouponFeatureEnabled && !loyaltyCoupon && !!selectedCoupon && isSomethingChange) {
        if (selectedCoupon.segment === SegmentTypeEnum.first_order) {
          dispatch(
            validateCouponRequest({
              origin,
              displayErrorMessage,
              coupon: selectedCoupon
            })
          );
        } else {
          dispatch(
            fetchCouponRequest({
              origin,
              displayErrorMessage,
              couponCode: selectedCoupon.code || ''
            })
          );
        }

        setHasSettingsUpdate(false);
      }
    }

    if (existCoupons || (establishmentCoupons && establishmentCoupons.length > 0)) {
      handleWithOriginChanges();
    }
  }, [
    total,
    origin,
    settings,
    dispatch,
    existCoupons,
    loyaltyCoupon,
    persistedTotal,
    selectedCoupon,
    products.length,
    persistedOrigin,
    hasSettingsUpdate,
    displayErrorMessage,
    establishmentCoupons
  ]);
}
