import { useEffect, useRef } from 'react';

import lottie, { AnimationItem } from 'lottie-web';

import GoomerLoading from '~/assets/statusOrder/animations/goomer-loading.json';

import * as S from './styles';

const GoomerLoadingIndicator = (): JSX.Element => {
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let animationInstance: AnimationItem | null = null;

    if (animationContainer.current) {
      animationInstance = lottie.loadAnimation({
        loop: true,
        autoplay: true,
        renderer: 'svg',
        animationData: GoomerLoading,
        container: animationContainer.current
      });
    }

    return (): void => {
      if (animationInstance) {
        animationInstance.destroy();
      }
    };
  }, []);

  return (
    <S.AnimationWrapper>
      <div ref={animationContainer} />
    </S.AnimationWrapper>
  );
};

export default GoomerLoadingIndicator;
