import styled, { css, DefaultTheme } from 'styled-components';

interface ButtonResumeContainerProps {
  isPix: boolean;
  noFill?: boolean;
  haveContent: boolean;
  isDeleteAction?: boolean;
}

const containerModifiers = {
  pix: () => css`
    max-width: 26.25rem;

    background: ${({ theme }) => theme.colors.gray200};
  `,
  withoutContent: (theme: DefaultTheme, noFill?: boolean) => css`
    border: 1px solid ${theme.brandColors.primary};

    .left {
      svg {
        margin-bottom: 0;

        > path {
          fill: ${noFill ? 'none' : theme.brandColors.primary};
        }
      }

      .info {
        .title,
        .description {
          color: ${theme.brandColors.primary};
        }

        .title {
          margin-bottom: 0;

          font-size: 13px;
        }
      }
    }
  `
};

export const Container = styled.button<ButtonResumeContainerProps>`
  ${({ theme, isPix, noFill, haveContent, isDeleteAction }) => css`
    position: relative;

    width: 100%;
    padding: 1rem;
    margin: 0 auto;
    min-height: 60px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: ${({ theme }) => theme.colors.backgroundColor};

    ${isPix && containerModifiers.pix()};

    .left {
      width: 100%;

      display: flex;
      align-items: ${haveContent ? 'flex-start' : 'center'};

      .info {
        width: 100%;

        font-size: 14px;
        line-height: 17px;

        .title {
          margin-bottom: 0.25rem;

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          text-align: left;
          font-weight: 500;
          line-height: 150%;
          font-size: 0.75rem;
          color: ${theme.colors.mediumGray};

          &.-center {
            justify-content: center;
          }

          .icon-wrapper {
            display: flex;
            margin-right: 0.25rem;

            > svg {
              width: 1.25rem;
              height: 1.25rem;

              > path {
                fill: ${noFill ? 'none' : theme.colors.mediumGray};
              }
            }
          }
        }

        .description {
          text-align: left;
          font-weight: 600;
          line-height: 150%;
          font-size: 0.875rem;
          color: ${theme.colors.textColor};

          &.-secondary,
          &.-info {
            margin-top: 0.25rem;

            font-weight: 500;
            line-height: 150%;
            font-size: 0.75rem;
            color: ${theme.colors.mediumGray};
          }

          strong {
            margin-top: -2px;
            margin-bottom: 8px;

            display: block;

            font-weight: 600;
            color: ${theme.colors.darkGray};
          }

          small {
            font-weight: 400;
            color: ${theme.colors.darkGray};
          }

          .tag {
            padding: 3px;
            border-radius: 3px;

            font-size: 12px;
            font-weight: bold;
            color: ${theme.colors.backgroundColor};
            background-color: ${theme.brandColors.primary};
          }
        }
      }
    }

    .edit {
      margin: auto 0;

      font-weight: 600;
      line-height: 150%;
      font-size: 0.75rem;
      text-decoration: underline;
      color: ${({ theme }) => theme.brandColors.primary};

      ${isDeleteAction &&
      css`
        text-decoration: underline;
        color: ${theme.colors.error};
      `}
    }

    .action {
      path {
        fill: ${theme.colors.mediumGray};
      }
    }

    ${!haveContent && containerModifiers.withoutContent(theme, noFill)};
  `}
`;
