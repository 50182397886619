import React, { useState } from 'react';

import { validateEmail } from '~/utils';
import { useTranslator } from '~/hooks';

import { Input, ErrorMessage } from '../Input/Input/styles';

interface InputEmailProps {
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  defaultValue: string;
  isGoomerColor?: boolean;
  onChange: (value: string, isValid: boolean) => void;
}

const InputEmail: React.FC<InputEmailProps> = ({
  required,
  onChange,
  placeholder,
  defaultValue,
  disabled = false,
  isGoomerColor = false,
  ...rest
}) => {
  const [email, setEmail] = useState(defaultValue);
  const [withError, setWithError] = useState('');

  const { getTranslation } = useTranslator();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    const isValid = validateEmail(value);

    if (isValid) {
      setWithError('');
    }

    setEmail(value);
    onChange(value, isValid);
  };

  const handleBlur = (): void => {
    if (required) {
      setWithError(validateEmail(email) ? '' : 'error');
    }
  };

  return (
    <>
      <Input
        max={74}
        type="text"
        value={email}
        maxLength={74}
        id="input-email"
        inputMode="text"
        error={withError}
        required={required}
        onBlur={handleBlur}
        disabled={disabled}
        className="sentry-mask"
        onChange={handleChange}
        placeholder={placeholder}
        isGoomerColor={isGoomerColor}
        {...rest}
      />
      {required && withError && <ErrorMessage>{getTranslation('general.enterYourEmail')}</ErrorMessage>}
    </>
  );
};

export default React.memo(InputEmail);
