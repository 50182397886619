import { action } from 'typesafe-actions';

import { CancelOrderProps, MountedNupayOrder } from '~/interfaces/general';

import { NupayStateActionTypes } from './types';

export const addNupayOrder = (order: MountedNupayOrder) => {
  return action(NupayStateActionTypes.ADD_NUPAY_ORDER, order);
};

export const cleanNupayOrder = () => {
  return action(NupayStateActionTypes.CLEAN_NUPAY_ORDER);
};

export const addNupayTimeToExpireOrder = (time: Date | string) => {
  return action(NupayStateActionTypes.ADD_NUPAY_TIME_TO_EXPIRE_ORDER, time);
};

export const cancelNupayOrderRequest = (data: CancelOrderProps) => {
  return action(NupayStateActionTypes.CANCEL_NUPAY_ORDER_REQUEST, data);
};

export const cancelNupayOrderSuccess = () => {
  return action(NupayStateActionTypes.CANCEL_NUPAY_ORDER_SUCCESS);
};

export const cancelNupayOrderError = (error: string) => {
  return action(NupayStateActionTypes.CANCEL_NUPAY_ORDER_ERROR, error);
};
