import React from 'react';

const SmartphoneMoneyWhatsIcon: React.FC = () => (
  <svg width="67" height="54" viewBox="0 0 67 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.8171 46.2858C56.6208 46.2858 67.0002 35.9243 67.0002 23.1429C67.0002 10.3614 56.6208 0 43.8171 0C31.0135 0 20.634 10.3614 20.634 23.1429C20.634 35.9243 31.0135 46.2858 43.8171 46.2858Z"
      fill="#E8EAED"
    />
    <path
      d="M4.68673 21.6467C5.21527 21.6467 5.64373 21.2193 5.64373 20.6921C5.64373 20.1649 5.21527 19.7375 4.68673 19.7375C4.1582 19.7375 3.72974 20.1649 3.72974 20.6921C3.72974 21.2193 4.1582 21.6467 4.68673 21.6467Z"
      fill="#30333C"
    />
    <path
      d="M5.07105 6.78644C4.60385 6.78644 4.22583 6.40937 4.22583 5.94335V3.41408C4.22583 2.94806 4.60385 2.57098 5.07105 2.57098C5.53824 2.57098 5.91626 2.94806 5.91626 3.41408V5.94335C5.91626 6.40937 5.53824 6.78644 5.07105 6.78644Z"
      fill="#7F8F9F"
    />
    <path
      d="M5.07105 12.6883C4.60385 12.6883 4.22583 12.3112 4.22583 11.8452V9.31593C4.22583 8.84991 4.60385 8.47284 5.07105 8.47284C5.53824 8.47284 5.91626 8.84991 5.91626 9.31593V11.8452C5.91626 12.3112 5.53824 12.6883 5.07105 12.6883Z"
      fill="#7F8F9F"
    />
    <path
      d="M3.38087 8.47238H0.845217C0.378023 8.47238 0 8.0953 0 7.62929C0 7.16327 0.378023 6.78619 0.845217 6.78619H3.38087C3.84806 6.78619 4.22608 7.16327 4.22608 7.62929C4.22608 8.0953 3.84806 8.47238 3.38087 8.47238Z"
      fill="#7F8F9F"
    />
    <path
      d="M9.29737 8.4725H6.76172C6.29453 8.4725 5.9165 8.09543 5.9165 7.62941C5.9165 7.16339 6.29453 6.78632 6.76172 6.78632H9.29737C9.76456 6.78632 10.1426 7.16339 10.1426 7.62941C10.1426 8.09543 9.76456 8.4725 9.29737 8.4725Z"
      fill="#7F8F9F"
    />
    <path
      d="M10.9871 53.1565V15.0349C10.9871 12.8074 12.7971 11.002 15.0302 11.002H47.5146C49.7474 11.002 51.5574 12.8074 51.5574 15.0349V53.1565"
      fill="white"
    />
    <path
      d="M18.4526 53.549L20.2277 47.1031C19.1332 45.2124 18.5574 43.0682 18.5581 40.8859C18.5581 34.0259 24.1691 28.4452 31.0522 28.4452C34.3977 28.4452 37.5322 29.743 39.8907 32.0914C42.2492 34.4399 43.5465 37.5671 43.5465 40.892C43.5465 47.752 37.9356 53.3326 31.0523 53.3326H31.0462C28.9544 53.3326 26.9 52.8074 25.0752 51.8185L18.4526 53.5491V53.549Z"
      fill="#485460"
    />
    <path
      d="M18 53.9999L19.8371 47.3254C18.7075 45.3725 18.1054 43.1537 18.1117 40.8857C18.1117 33.7785 23.9212 28 31.059 28C34.5223 28 37.7745 29.341 40.2139 31.776C42.6593 34.211 44.0062 37.4494 44 40.8918C44 47.999 38.1905 53.7775 31.0526 53.7775H31.0466C28.8846 53.7766 26.7572 53.237 24.8584 52.2077L18 54V53.9999ZM25.1811 49.8716L25.5721 50.1064C27.2232 51.0829 29.1162 51.5959 31.0466 51.602H31.0527C36.9801 51.602 41.809 46.8001 41.809 40.8918C41.809 38.0304 40.6918 35.342 38.6622 33.3149C36.6326 31.2879 33.9264 30.1754 31.0527 30.1754C25.1191 30.1754 20.2903 34.9774 20.2903 40.8857C20.2903 42.9066 20.855 44.878 21.9351 46.5837L22.1896 46.9916L21.1034 50.9407L25.1811 49.8716Z"
      fill="#F8F9FA"
    />
    <path
      d="M27.8193 35.4903C27.5771 34.9526 27.3226 34.9402 27.0931 34.9341C26.9069 34.9279 26.6897 34.9279 26.4724 34.9279C26.2552 34.9279 25.9075 35.0082 25.6097 35.3296C25.3117 35.651 24.48 36.4297 24.48 38.0181C24.48 39.6001 25.6407 41.1328 25.802 41.349C25.9634 41.5654 28.0427 44.9212 31.326 46.2129C34.057 47.2883 34.6157 47.0719 35.2052 47.0163C35.8011 46.9607 37.117 46.2376 37.39 45.4836C37.6569 44.7297 37.6569 44.0869 37.5762 43.951C37.4955 43.8149 37.2783 43.7347 36.9555 43.5739C36.6328 43.4133 35.0439 42.6346 34.746 42.5234C34.448 42.4121 34.2308 42.3627 34.0198 42.684C33.8025 43.0053 33.1819 43.7284 32.9956 43.9448C32.8094 44.1611 32.6171 44.1858 32.2943 44.0251C31.9715 43.8645 30.9288 43.5245 29.6937 42.4245C28.7317 41.5716 28.08 40.5147 27.8937 40.1934C27.7075 39.8721 27.8751 39.6989 28.0365 39.5383C28.1792 39.3961 28.3592 39.1613 28.5207 38.9759C28.682 38.7905 28.7379 38.6546 28.8434 38.4382C28.9489 38.2219 28.8992 38.0366 28.8185 37.8759C28.7379 37.7151 28.1048 36.1268 27.8193 35.4903Z"
      fill="white"
    />
    <path
      d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
      fill="#30333C"
    />
    <path
      d="M51.5582 54.0004C51.091 54.0004 50.713 53.6233 50.713 53.1573V15.0355C50.713 13.2768 49.2784 11.8459 47.5153 11.8459H15.0307C13.2676 11.8459 11.833 13.2768 11.833 15.0355V53.1573C11.833 53.6233 11.455 54.0004 10.9878 54.0004C10.5206 54.0004 10.1426 53.6233 10.1426 53.1573V15.0355C10.1426 12.3473 12.3357 10.1597 15.0307 10.1597H47.5153C50.2103 10.1597 52.4034 12.3473 52.4034 15.0355V53.1573C52.4034 53.6233 52.0254 54.0004 51.5582 54.0004Z"
      fill="#30333C"
    />
    <path d="M22 11H40V12C40 13.6569 38.6569 15 37 15H25C23.3431 15 22 13.6569 22 12V11Z" fill="#30333C" />
    <path d="M53.5361 25.335H51.8457V29.5504H53.5361V25.335Z" fill="#30333C" />
    <path
      d="M51.8452 30.3933H53.5356C54.0028 30.3933 54.3809 30.0163 54.3809 29.5502V25.3348C54.3809 24.8688 54.0028 24.4917 53.5356 24.4917H51.8452C51.378 24.4917 51 24.8688 51 25.3348V29.5502C51 30.0163 51.378 30.3933 51.8452 30.3933Z"
      fill="#30333C"
    />
    <path
      d="M51.8452 37.1385H53.5356C54.0028 37.1385 54.3809 36.7614 54.3809 36.2954V32.0799C54.3809 31.6139 54.0028 31.2368 53.5356 31.2368H51.8452C51.378 31.2368 51 31.6139 51 32.0799V36.2954C51 36.7614 51.378 37.1385 51.8452 37.1385Z"
      fill="#30333C"
    />
  </svg>
);

export default SmartphoneMoneyWhatsIcon;
