import { Group, Hour, Menu, Product } from '~/interfaces';

import isProductOrCategoryAvailable from '../isProductOrCategoryAvailable';

function isItemAvailableByHours(availableHours: Hour[], isFeatureEnabled: boolean): boolean {
  if (availableHours?.length === 0 || !isFeatureEnabled) return true;

  return availableHours?.some((eachHour) => isProductOrCategoryAvailable(eachHour));
}

function getFilteredProductListByAvailableHours(products: Product[], isFeatureEnabled: boolean): Product[] {
  return products?.filter((product) => isItemAvailableByHours(product?.available_hours, isFeatureEnabled));
}

function getFilteredGroupListByAvailableHours(groups: Group[], isFeatureEnabled: boolean): Group[] {
  return groups?.filter((group) => {
    const productWithHoursList = group?.products.filter(({ available_hours }) => available_hours?.length > 0);
    const availableProductsByHourList = getFilteredProductListByAvailableHours(productWithHoursList, isFeatureEnabled);

    if (availableProductsByHourList.length > 0) return true;

    const productWithoutHoursList = group?.products.filter(
      ({ available_hours }) => !available_hours || available_hours?.length === 0
    );

    if (productWithoutHoursList.length === 0) return false;

    return isItemAvailableByHours(group?.available_hours, isFeatureEnabled);
  });
}

function getFilteredMenuListByAvailableHours(menus: Menu[], isFeatureEnabled: boolean): Menu[] {
  const isMenuAvailable = (item: { available_hours: Hour[] }): boolean =>
    isItemAvailableByHours(item?.available_hours, isFeatureEnabled);

  const filteredMenuList = menus?.filter(isMenuAvailable);

  return filteredMenuList?.filter((menu) => {
    const availableGroupList = menu?.groups?.filter(isMenuAvailable);

    const hasValidGroup = availableGroupList?.some((group) => {
      if (!group?.available_hours?.length) return true;

      return isMenuAvailable(group);
    });

    const hasValidProduct = availableGroupList?.some((group) => group?.products?.some(isMenuAvailable));

    return hasValidGroup && hasValidProduct;
  });
}

export default {
  isItemAvailableByHours,
  getFilteredMenuListByAvailableHours,
  getFilteredGroupListByAvailableHours,
  getFilteredProductListByAvailableHours
};
