import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useSlug } from '~/hooks';
import { QueryParam } from '~/interfaces/enums';

const SUBSCRIPTION = process.env.NEXT_PUBLIC_SUBSCRIPTION_URL || '';

interface UseHandleWithLegacyURL {
  isSlugPage?: boolean;
}

export default function useHandleWithLegacyURL({ isSlugPage = false }: UseHandleWithLegacyURL) {
  const history = useRouter();

  const slug = useSlug();

  useEffect(() => {
    async function handleWithLegacyURLs() {
      const { host, search, pathname, protocol } = window.location;

      if (process.env.NEXT_PUBLIC_ENV === 'ninja' && pathname.includes('cadastro')) {
        window.location.assign(SUBSCRIPTION);
        return;
      }

      const hostWithoutWWW = host.replace('www.', '');
      const urlParts = hostWithoutWWW.split('.');

      if ((urlParts.length >= 2 || urlParts.length <= 4) && !isSlugPage) return;

      if (urlParts.length >= 3) return history.push('/404');

      const splitPathname = pathname.split('/');

      const newPathname = (() => {
        if (!!splitPathname && splitPathname.length > 2) {
          const removedSlug = splitPathname.filter((_, index) => index > 1);

          return removedSlug ? `/${removedSlug.join('/')}` : '';
        }

        return '';
      })();

      let extraPath = '';

      if (search.includes(QueryParam.coupon)) {
        extraPath = '/menu';
      }

      const redirectURL = `${protocol}//${slug}.${hostWithoutWWW}${newPathname}${extraPath}${search}`;

      window.location.assign(redirectURL);
    }

    if (slug) {
      handleWithLegacyURLs();
    }
  });
}
