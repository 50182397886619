import React, { useCallback } from 'react';

import { useTranslator } from '~/hooks';
import { cardDueMask, cardDueValidate } from '~/pages/cardEntry/utils';

import InputMask from '../InputMask';

interface InputCardDueProps {
  dataTest?: string;
  defaultValue?: string;
  onChange: (value: string, isValid: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const InputCardDue: React.FC<InputCardDueProps> = ({ dataTest, onChange, defaultValue }) => {
  const { getTranslation } = useTranslator();

  const handleValidate = useCallback((value: string) => !value || cardDueValidate(value), []);

  return (
    <InputMask
      name="due"
      onChange={onChange}
      inputMode="decimal"
      data-test={dataTest}
      toMask={cardDueMask}
      validate={handleValidate}
      defaultValue={defaultValue}
      errorMessage={getTranslation('general.invalidDate')}
      placeholder={getTranslation('payment.expiryDatePlaceholder')}
    />
  );
};

export default React.memo(InputCardDue);
