import { useMemo } from 'react';
import currency from 'currency.js';
import { useSelector } from 'react-redux';

import { TableIcon } from '~/assets';
import { NewWaves } from '~/components';
import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { Empty } from '~/components/pages/MyTab';
import { CartProduct } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';

import * as S from './styles';

export interface ItemListProps {
  desc: string;
  showGroups?: boolean;
  items: CartProduct[];
}

const ItemList = ({ desc, items, showGroups = false }: ItemListProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  const hasItems = !!items.length;

  const groupedItems = useMemo(() => {
    const groupedByTabName: Record<string, CartProduct[]> = {};

    items.forEach((item) => {
      const groupIndex = item.tabName || desc;

      groupedByTabName[groupIndex] = groupedByTabName[groupIndex] || [];
      groupedByTabName[groupIndex].push(item);
    });

    return groupedByTabName;
  }, [desc, items]);

  const renderContent = useMemo(() => {
    if (!hasItems) {
      return <Empty isTicket isOnlinePayment />;
    }

    return (
      <>
        <S.Title>
          <div className="icon" aria-label="ícone do título">
            <TableIcon />
          </div>

          <p>
            {getTranslation('general.consumption')}: <span>{desc}</span>
          </p>
        </S.Title>

        <S.List>
          {Object.keys(groupedItems).map((tabName, index) => (
            <div className="tab" key={index}>
              {showGroups && (
                <S.TabTitle>
                  <p>{tabName}</p>
                </S.TabTitle>
              )}

              <S.ListHeader>
                <h3>{getTranslation('general.items')}</h3> <h3>{getTranslation('general.price')}</h3>
              </S.ListHeader>

              <div className="list">
                {groupedItems[tabName].map((item) => (
                  <S.ListItem key={item.product.id}>
                    <div className="product">
                      <p>{`${item.quantity}x ${item.product.name || ''}`}</p>

                      <strong>{convertToCurrency(item.subtotal)}</strong>
                    </div>

                    {item.optionals?.map((optional, index) => (
                      <div className="optional" key={index}>
                        <p>{`${optional.quantity * item.quantity}x ${optional.name}`}</p>

                        <strong>{convertToCurrency(currency(optional.price).multiply(optional.quantity).value)}</strong>
                      </div>
                    ))}
                  </S.ListItem>
                ))}
              </div>
            </div>
          ))}
        </S.List>
      </>
    );
  }, [desc, getTranslation, groupedItems, hasItems, showGroups]);

  return (
    <S.Container>
      {renderContent}

      <NewWaves backgroundColor={theme.colors.gray200} />
    </S.Container>
  );
};

export { ItemList };
