import styled, { css } from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';
import * as InputStyles from '~/components/Input/Input/styles';

export const Container = Modal
  ? styled(Modal)`
      ${({ theme }) => css`
        .content {
          padding: 0.5rem 1rem 0;

          .description {
            margin: 0 0 1rem;

            font-weight: 500;
            line-height: 150%;
            text-align: center;
            font-size: 0.875rem;
            color: ${theme.colors.mediumGray};
          }

          ${InputStyles.Container} {
            margin: 0 0 1rem 0;
          }
        }
      `}
    `
  : styled.div<ModalProps>``;
