import React, { useContext, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { phoneMask, showToast } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import { LoggedAreaMenuItemEnum } from '~/interfaces/enums';
import getFormattedBirthdayApi from '~/utils/getFormattedBirthdayApi';
import { updateCustomerInfoRequest } from '~/redux-tools/store/user/actions';
import { Button, InputName, InputPhone, InputBirthDate, InputEmail, GoomerExperience } from '~/components';

import { LoggedAreaContext } from '../..';

import * as S from './styles';

const getFormattedBirthday: (birthday: string) => string = (birthday: string) =>
  getFormattedBirthdayApi(birthday, '-', '/') || '';

const PersonalData = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    loading: isLoading,
    data: { name, email, optIn, phone, birthday }
  } = useSelector((state: IApplicationState) => state.user);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const [isValidName, setIsValidName] = useState<boolean>(true);
  const [selectedName, setSelectedName] = useState<string>(name || '');
  const [isValidBirthday, setIsValidBirthday] = useState<boolean>(true);
  const [isBirthdayAfterToday, setIsBirthdayAfterToday] = useState<boolean>(false);
  const [selectedBirthday, setSelectedBirthday] = useState<string>(getFormattedBirthday(birthday || ''));

  const { getTranslation } = useTranslator();
  const { setSelectedMenu } = useContext(LoggedAreaContext);

  const getIsValidForm: () => boolean = () => {
    if (!isValidName) return false;

    if (selectedBirthday.length > 0 && !isValidBirthday) return false;

    if (selectedName === name && selectedBirthday === getFormattedBirthday(birthday || '')) return false;

    if (selectedBirthday && isBirthdayAfterToday) return false;

    return true;
  };

  const handleNameOnChange = (name: string, isValid: boolean): void => {
    setSelectedName(name);
    setIsValidName(isValid);
  };

  const handleBirthdayOnChange = (dateValue: string, isValid: boolean): void => {
    setSelectedBirthday(dateValue);
    setIsValidBirthday(isValid);
  };

  const handleOnSave = (): void => {
    const addErrorLog = (): void => {
      showToast({ type: 'error', closeOnClick: true, text: getTranslation('loggedArea.errorMessage') });
    };

    const afterAction = (): void => {
      showToast({ type: 'success', closeOnClick: true, text: getTranslation('loggedArea.successMessage') });
      setSelectedMenu(LoggedAreaMenuItemEnum.default);
    };

    dispatch(
      updateCustomerInfoRequest({
        name: selectedName,
        optIn: optIn || true,
        birthday: selectedBirthday,
        storeId: settings?.id || 0,
        functions: {
          addErrorLog,
          afterAction
        }
      })
    );
  };

  return (
    <S.Container>
      <S.Content>
        <S.StyledField>
          <label>{getTranslation('general.firstNameAndLastName')}</label>

          <InputName
            required
            isGoomerColor
            defaultValue={selectedName}
            onChange={handleNameOnChange}
            placeholder={getTranslation('user.inputNamePlaceholder')}
          />
        </S.StyledField>

        <S.StyledField>
          <label>{getTranslation('general.email')}</label>

          <InputEmail
            required
            disabled
            isGoomerColor
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChange={(): void => {}}
            defaultValue={email || ''}
          />
        </S.StyledField>

        <S.StyledField>
          <label>{getTranslation('user.phoneNumber')}</label>

          <InputPhone
            required
            isDisabled
            isGoomerColor
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChange={(): void => {}}
            defaultValue={phoneMask(phone || '')}
          />
        </S.StyledField>

        <S.StyledField isWithoutMargin>
          <label>
            {getTranslation('general.birthdayDate')} ({getTranslation('general.optional')})
          </label>

          <InputBirthDate
            isGoomerColor
            onChange={handleBirthdayOnChange}
            isBirthDateAfterToday={setIsBirthdayAfterToday}
            defaultValue={getFormattedBirthday(birthday || '')}
          />
        </S.StyledField>
      </S.Content>

      <S.GoomerWrapper>
        <GoomerExperience />
      </S.GoomerWrapper>

      <S.Footer>
        <Button
          isGoomerColor
          isGhost={false}
          isLoading={isLoading}
          onClick={handleOnSave}
          isDisabled={!getIsValidForm() || isLoading}
        >
          {getTranslation('general.saveChanges')}
        </Button>
      </S.Footer>
    </S.Container>
  );
};

export default PersonalData;
