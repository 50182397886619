import React, { useMemo, useEffect, useState } from 'react';

import FadeIn from 'react-fade-in';

import { Group, Product } from '~/interfaces';
import ProductCard from '~/components/ProductCard';
import { useMenuProducts } from '~/hooks/useMenu/hooks';

import * as S from './styles';

const SECONDS_IN_ONE_MINUTE = 60;
const MILLISECONDS_IN_ONE_SECOND = 1000;

interface GroupProps {
  group: Group;
  noHeader?: boolean;
}

const GroupComponent: React.FC<GroupProps> = ({ group, noHeader }) => {
  const [productsToShowByHourList, setProductsToShowByHourList] = useState<Product[]>([]);

  const { getProductListByGroupId } = useMenuProducts();

  useEffect(() => {
    const checkProductsAvailability = (): void => {
      const filteredProductList = getProductListByGroupId(group.id);

      setProductsToShowByHourList(filteredProductList);
    };

    checkProductsAvailability();

    const timer: NodeJS.Timeout = setInterval(
      checkProductsAvailability,
      SECONDS_IN_ONE_MINUTE * MILLISECONDS_IN_ONE_SECOND
    );

    return () => {
      clearInterval(timer);
    };
  }, [getProductListByGroupId, group.id]);

  const itemList: JSX.Element[] = useMemo(() => {
    return productsToShowByHourList.map((product) => (
      <ProductCard
        key={product.id}
        product={product}
        categoryId={group.id}
        data-test="product-card"
        categoryName={group.name}
      />
    ));
  }, [group, productsToShowByHourList]);

  if (itemList.length === 0) {
    return <></>;
  }

  return (
    <S.Container id={group.id.toString()}>
      {!noHeader && <h2 data-test="product-list">{group.name}</h2>}

      {productsToShowByHourList.length > 200 || itemList.length === 0 ? (
        <div className="productList">{itemList}</div>
      ) : (
        <FadeIn delay={100} className="productList">
          {itemList}
        </FadeIn>
      )}
    </S.Container>
  );
};

export default GroupComponent;
