import { Reducer } from 'redux';

import { ICouponsState, CouponsActionTypes } from './types';

const initialState: ICouponsState = {
  data: {
    selectedCoupon: undefined
  },
  loading: false,
  error: undefined,
  origin: 'delivery',
  existCoupons: false
};

const CouponsReducer: Reducer<ICouponsState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CouponsActionTypes.FETCH_EXISTENT_COUPONS_REQUEST:
    case CouponsActionTypes.FETCH_COUPON_REQUEST:
    case CouponsActionTypes.VALIDATE_COUPON_REQUEST: {
      return { ...state, loading: true };
    }

    case CouponsActionTypes.FETCH_COUPON_SUCCESS: {
      return {
        ...state,
        data: {
          selectedCoupon: payload.coupon
        },
        loading: false
      };
    }

    case CouponsActionTypes.FETCH_EXISTENT_COUPONS_SUCCESS: {
      return {
        ...state,
        existCoupons: payload,
        error: '',
        loading: false
      };
    }

    case CouponsActionTypes.FETCH_COUPON_ERROR:
    case CouponsActionTypes.FETCH_EXISTENT_COUPONS_ERROR:
    case CouponsActionTypes.COUPON_ERROR_UPDATE:
    case CouponsActionTypes.VALIDATE_COUPON_ERROR: {
      return {
        ...state,
        error: payload.error,
        loading: false
      };
    }

    case CouponsActionTypes.SET_ORIGIN:
      return {
        ...state,
        origin: payload.origin
      };

    case CouponsActionTypes.CLEAN_SELECTED_COUPON:
      return {
        ...state,
        data: {
          ...state.data,
          selectedCoupon: undefined
        }
      };

    case CouponsActionTypes.CLEAN_COUPON:
      return {
        ...initialState,
        origin: state.origin,
        existCoupons: state.existCoupons
      };

    default:
      return state;
  }
};

export default CouponsReducer;
