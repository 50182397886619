import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  margin-bottom: 2rem;

  > .content {
    padding: 0.5rem 1rem 0;
  }
`;
