import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { Store, createStore, compose, applyMiddleware } from 'redux';

import rootSaga from './store/rootSaga';
import { IApplicationState, rootReducer } from './store';

export const persistConfig = {
  storage,
  key: 'goomer-mobile',
  blacklist: [
    'theme',
    'thirky',
    'menus',
    'scroll',
    'coupons',
    'scheduling',
    'fingerPrint',
    'geolocation',
    'establishment'
  ]
};

const sagaMiddleware = createSagaMiddleware();

const configureStore = (): Store<IApplicationState> => {
  const isClient = typeof window !== 'undefined';

  if (!isClient) {
    const store = createStore(rootReducer(), applyMiddleware(sagaMiddleware));

    return store;
  }

  const composeEnhancer: typeof compose =
    (typeof window !== 'undefined' ? (window as any) : {}).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store =
    process.env.NEXT_PUBLIC_ENV === 'production'
      ? createStore(persistReducer(persistConfig, rootReducer()), applyMiddleware(sagaMiddleware))
      : createStore(persistReducer(persistConfig, rootReducer()), composeEnhancer(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(rootSaga);

  return store;
};

const store = configureStore();
const persistor = persistStore(store);

export { store, persistor };
