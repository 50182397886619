import { Reducer } from 'redux';
import { Action } from 'typesafe-actions';

import { Thirky } from '~/interfaces/general';

import { ThirkyActionTypes, ThirkyState } from './types';

const initialState: ThirkyState = {
  hasError: false,
  data: undefined,
  isLoading: false
};

interface ThirkyActionType extends Action {
  type: string;
  payload: Thirky;
}

const ThirkyReducer: Reducer<ThirkyState, ThirkyActionType> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ThirkyActionTypes.GET_THIRKY_SEARCH_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true
      };

    case ThirkyActionTypes.GET_THIRKY_SEARCH_SUCCESS:
      return {
        ...state,
        data: payload,
        hasError: false,
        isLoading: false
      };

    case ThirkyActionTypes.GET_THIRKY_SEARCH_ERROR:
      return {
        ...state,
        hasError: true,
        data: undefined,
        isLoading: false
      };

    default:
      return state;
  }
};

export default ThirkyReducer;
