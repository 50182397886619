import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $imageUrl?: string; $isSingle?: boolean; $hasAction?: boolean }>`
  ${({ theme, $imageUrl, $isSingle, $hasAction }) => css`
    position: relative;

    margin: 0;
    width: 20rem;
    height: 10.625rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 0.586rem;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${$imageUrl});
    background-color: ${theme.brandColors.primary};

    ${$hasAction &&
    css`
      cursor: pointer;
    `}

    ${$isSingle &&
    css`
      ${isMobile &&
      css`
        width: 100%;
      `}
    `}
  `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 0.67rem;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  background: linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, #000000 100%);
`;

export const Info = styled.div`
  ${({ theme }) => css`
    > h3 {
      margin: 0;

      font-weight: 800;
      font-size: 1rem;
      line-height: 1.714rem;
      color: #fff;
    }

    > p {
      margin: 0;

      font-weight: 500;
      font-size: 0.85rem;
      line-height: 1.125rem;
      color: ${theme.isDarkMode ? theme.colors.gray500 : theme.colors.gray300};
    }
  `}
`;

export const Action = styled.div`
  ${({ theme }) => css`
    margin-left: 0.5rem;
    width: 2.86rem;
    height: 2.86rem;
    min-width: 2.86rem;
    min-height: 2.86rem;
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.backgroundColor};

    > svg {
      color: ${theme.brandColors.primary};
    }
  `}
`;
