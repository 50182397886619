import { useCallback } from 'react';

import { convertToCurrency } from '~/utils';
import { CouponErrorType, DeliveryWay } from '~/interfaces/general';
import { CouponErrorMessageEnum, DeliveryWayEnum, PrettyOriginEnum } from '~/interfaces/enums';

import useTranslator from '../useTranslator';

export interface GetCouponErrorMessageProps {
  type: CouponErrorType;
  info: {
    code?: string;
    time?: string[];
    quantity?: string;
    orderMinValue?: number;
    origins?: DeliveryWay[];
    currentOrigin?: DeliveryWay;
  };
}

interface UseGetCouponErrorMessageReturnProps {
  displayErrorMessage: ({ type, info }: GetCouponErrorMessageProps) => string;
}

export default function useGetCouponErrorMessage(): UseGetCouponErrorMessageReturnProps {
  const { getTranslation } = useTranslator();

  const displayErrorMessage = useCallback(
    ({ type, info }: GetCouponErrorMessageProps): string => {
      const originMessage: () => string = () => {
        if (!info.currentOrigin || !info.origins) return '';

        if (info.origins?.length === 1 && info.origins.includes(DeliveryWayEnum.onsite)) {
          return getTranslation('couponMessage.useInOnsiteOrder');
        }

        if (info.origins?.length === 1) {
          return getTranslation(PrettyOriginEnum[info?.origins[0]]).toLowerCase();
        }

        const message = info.origins
          .map((origin) => getTranslation(PrettyOriginEnum[origin]).toLocaleLowerCase())
          .join(` ${getTranslation('general.and')} `);

        return message;
      };

      const errorMessages: { [key in CouponErrorMessageEnum]?: () => string } = {
        [CouponErrorMessageEnum.sold_out]: () =>
          getTranslation('couponMessage.couponWasExpired', { infoCode: info.code || '' }),
        [CouponErrorMessageEnum.not_enabled]: () => getTranslation('couponMessage.couponDisabledByStore'),
        [CouponErrorMessageEnum.not_in_weekdays]: () => getTranslation('couponMessage.invalidCouponWeekday'),
        [CouponErrorMessageEnum.not_in_order_origin]: () =>
          getTranslation('couponMessage.couponOnlyValidFor', { formattedMessage: originMessage() }),
        [CouponErrorMessageEnum.not_in_available_period]: () =>
          getTranslation('couponMessage.couponWasExpired', { infoCode: info.code || '' }),
        [CouponErrorMessageEnum.not_found]: () => getTranslation('couponMessage.couponNotFound'),
        [CouponErrorMessageEnum.not_valid_order_value]: () =>
          getTranslation('couponMessage.couponNeedOrderValueOver', {
            orderValue: convertToCurrency(Number(info.orderMinValue))
          }),
        [CouponErrorMessageEnum.not_valid_order_minimum_value]: () =>
          getTranslation('couponMessage.couponNeedOrderValueOver', {
            orderValue: convertToCurrency(Number(info.orderMinValue))
          }),
        [CouponErrorMessageEnum.not_in_defined_hours]: () => {
          if (info?.time) {
            const [initDate, finishDate] = info.time;
            return getTranslation('couponMessage.couponValidBetween', { initDate, finishDate });
          }
          return '';
        }
      };

      return errorMessages[type]?.() || '';
    },
    [getTranslation]
  );

  return { displayErrorMessage };
}
