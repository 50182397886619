import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 1.5rem 1rem;

    background-color: ${theme.colors.gray200};
  `};
`;

export const InputContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;

    label {
      margin-bottom: 0.25rem;

      float: left;
      font-weight: 600;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.textColor};
    }

    .city-list {
      border-radius: 0.25rem;
      border: 1px solid ${theme.colors.placeholderGray};
    }

    .input-error {
      float: left;
      margin-top: 4px;

      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      color: ${theme.colors.error};
    }
  `}
`;

export const InputWrapper = styled.div<{ isGoomerColor?: boolean }>`
  ${({ theme, isGoomerColor }) => css`
    width: 100%;
    padding: 8px 16px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid ${theme.colors.placeholderGray};
    background-color: ${theme.colors.backgroundColor};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    input {
      color: ${theme.colors.darkGray} !important;

      &::placeholder {
        color: ${theme.colors.placeholderGray};
      }
    }

    > p,
    input {
      border: 0;

      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      line-height: 150%;
      color: ${theme.colors.placeholderGray};
    }

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: ${isGoomerColor
          ? theme.colors.primary
          : css`
              ${theme.brandColors.primary};
            `};
      }
    }
  `};
`;

export const CityOption = styled.p`
  width: 100%;
  padding: 0 16px;
  min-height: 36px;

  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  font-style: normal;
  color: ${({ theme }) => theme.colors.textColor};
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;

export const NoCepWrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  text-align: left;

  span {
    cursor: pointer;
    font-weight: 600;
    line-height: 150%;
    font-size: 0.75rem;
    text-decoration: underline;
    color: ${({ theme }) => theme.brandColors.primary};
  }
`;
