import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { validatePhone } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';

export default function useValidatePhoneNumber() {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const isPhoneFromBrazil = useMemo(() => {
    if (!settings) {
      return false;
    }

    const { mm_timezone, mm_currency, mm_whatsapp_phone_number, mm_international_phone_enabled } = settings;

    const isInternationalPhoneEnabled = !!mm_international_phone_enabled;
    const isABrazilianTimezone = ['BRT', 'AMT'].includes(mm_timezone?.code);
    const isABrazilianCurrency = mm_currency?.code === 'BRL';
    const isABrazilianPhone = mm_whatsapp_phone_number ? String(mm_whatsapp_phone_number).includes('+55') : true;

    if (isInternationalPhoneEnabled || !isABrazilianTimezone || !isABrazilianCurrency || !isABrazilianPhone)
      return false;

    return true;
  }, [settings]);

  const isPhoneFilled = (phoneNumber: string) => {
    const phoneNumbers = phoneNumber.replace(/\D/g, '');

    if (phoneNumbers.length >= 9) return true;

    return false;
  };

  const isPhoneValid = (phoneNumber: string) => {
    return isPhoneFromBrazil ? validatePhone({ phoneNumber, onlyMobile: true }) : isPhoneFilled(phoneNumber);
  };

  return {
    isPhoneValid,
    isPhoneFilled,
    isPhoneFromBrazil
  };
}
