import React, { useState, useEffect, useCallback } from 'react';

import { useTranslator } from '~/hooks';

import { Container, Error } from '../FormInput/styles';
import { FormInput, FormInputProps } from '../FormInput';

interface InputMaskProps extends Omit<FormInputProps, 'onChange'> {
  resetValue?: boolean;
  errorMessage: string;
  autoComplete?: string;
  toMask: (value: string) => string;
  validate: (value: string) => boolean;
  errorPosition?: Record<string, number | string>;
  onChange: (value: string, isValid: boolean) => void;
}

const InputMask: React.FC<InputMaskProps> = ({
  toMask,
  validate,
  onChange,
  errorMessage,
  defaultValue,
  errorPosition,
  resetValue = false,
  ...inputProps
}) => {
  const [value, setValue] = useState('');
  const [shouldDisplayError, setShouldDisplayError] = useState<boolean>(false);

  const { getTranslation } = useTranslator();

  useEffect(() => {
    if (resetValue) {
      setValue('');
    }
  }, [resetValue]);

  useEffect(() => {
    setValue(toMask(defaultValue || ''));
  }, [defaultValue, toMask]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const pureValue = value.replace(/\D/g, '');

    const isValid = !pureValue || validate(value);
    const maskedValue = toMask(pureValue);
    setValue(maskedValue);
    onChange(maskedValue, isValid);
    setShouldDisplayError(false);
  };

  const handleBlur = useCallback(() => {
    const isValid = !value || validate(value);

    if (!isValid) {
      setShouldDisplayError(true);
    }
  }, [value, validate]);

  return (
    <Container className="sentry-mask">
      <FormInput type="text" value={value} onChange={handleChange} onBlur={handleBlur} {...inputProps} />

      <Error style={errorPosition} showError={shouldDisplayError}>
        {errorMessage || getTranslation('general.invalidValue')}
      </Error>
    </Container>
  );
};

export default InputMask;
