import { Reducer } from 'redux';

import { LocalOrdersActionTypes, localOrderState } from './types';

const initialState = {
  hash: '',
  mode: '',
  date: null,
  number: '',
  store_id: 0,
  isLoadingInfo: false,
  qrCodeInfo: undefined,
  isOperatorMode: false,
  order: {
    id: '',
    payment: '',
    tabTableCode: '',
    createdAt: new Date()
  }
};

const LocalOrdersReducer: Reducer<localOrderState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LocalOrdersActionTypes.ADD_CHECK_IN:
      return {
        ...state,
        ...payload
      };

    case LocalOrdersActionTypes.ADD_ORDER:
      return {
        ...state,
        order: payload
      };

    case LocalOrdersActionTypes.SET_IS_OPERATOR_MODE:
      return {
        ...state,
        isOperatorMode: payload
      };

    case LocalOrdersActionTypes.GET_TAB_INFO:
      return {
        ...state,
        isLoadingInfo: true
      };

    case LocalOrdersActionTypes.ADD_TAB_INFO:
      return {
        ...state,
        qrCodeInfo: payload,
        isLoadingInfo: false
      };

    case LocalOrdersActionTypes.CLEAN_CHECK_IN:
      return initialState;

    case LocalOrdersActionTypes.CLEAN_TAB_INFO:
      return {
        ...state,
        isLoadingInfo: false,
        qrCodeInfo: undefined
      };

    default:
      return state;
  }
};

export default LocalOrdersReducer;
