import hexToRgba from 'hex-to-rgba';
import styled, { css } from 'styled-components';

export const Content = styled.div<{ $iconTop?: boolean }>`
  ${({ theme, $iconTop }) => css`
    padding: 0.625rem;
    border-radius: 0.313rem;
    border: 1px dashed ${theme.colors.warning};

    display: flex;
    align-items: ${$iconTop ? 'flex-start' : 'center'};
    background-color: ${hexToRgba(theme.colors.auxiliaryCheese300, 0.1)};
  `}
`;

export const SvgWrapper = styled.div`
  height: 1.5rem;
  min-width: 1.5rem;
  margin-right: 0.5rem;
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.warning};
`;

export const SectionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.strong`
  font-weight: 700;
  font-size: 0.75rem;
  color: ${({ theme }) => (theme.isDarkMode ? theme.colors.textColor : theme.colors.darkGray)};
`;

export const SectionDescription = styled.div<{ isDarkText?: boolean; hasTitle?: boolean }>`
  ${({ theme, hasTitle, isDarkText }) => css`
    > p {
      ${hasTitle &&
      css`
        margin-top: 0.25rem;
      `}

      text-align: left;
      line-height: 140%;
      font-size: 0.75rem;
      color: ${isDarkText ? theme.colors.textColor : theme.colors.mediumGray};

      strong {
        font-weight: 700;
        font-size: 0.75rem;
      }
    }
  `}
`;
