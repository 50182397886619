import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Link from 'next/link';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { IApplicationState } from '~/redux-tools/store';
import { Footer } from '~/components/FloatingFooter/styles';
import { ChangeOrderClient } from '~/components/pages/MyTab';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { setOrigin } from '~/redux-tools/store/coupons/actions';
import DocumentInput from '~/components/pages/Order/DocumentInput';
import { compareStrings, groupCartItems, isRequiredField } from '~/utils';
import { removePaymentPreference } from '~/redux-tools/store/user/actions';
import { CheckoutContextProps, FieldOptionsEnum } from '~/interfaces/general';
import { LoyaltyValidOrderConfirmationCard } from '~/components/Loyalty/components';
import {
  OrderModeEnum,
  DeliveryWayEnum,
  LocalOrdersEnum,
  OperationModesEnum,
  PossibleStatusEnum,
  PaymentStepProcessEnum
} from '~/interfaces/enums';
import {
  useTabInfo,
  useCheckout,
  useScrollTo,
  useTranslator,
  useFingerPrint,
  useLoyaltyProgram,
  useVisibilityObserver,
  useUserAuthenticationStatus
} from '~/hooks';
import {
  Input,
  Button,
  UserInfo,
  NewWaves,
  ItemEdit,
  InputName,
  CheckOrder,
  InputPhone,
  OptionHeader,
  PaymentOption,
  SummaryFooter,
  FooterBackdrop,
  CheckoutCoupon,
  HeaderSecondary,
  ConfirmCvvModal,
  PaymentStepModal,
  ClientChangeModal,
  CardFeedbackModal,
  QrCodeScannerModal,
  OrderFullAgeCheckbox
} from '~/components';

import * as S from './styles';

const QUERY_SELECTOR_NAME_OBSERVER = '#order-total-price';

export const CheckoutContext = React.createContext({} as CheckoutContextProps);

const LocalOrder: React.FC = () => {
  const history = useRouter();
  const dispatch = useDispatch();

  const hasShownError = useRef(false);

  const cart = useSelector((state: IApplicationState) => state.cart);
  const user = useSelector((state: IApplicationState) => state.user.data);
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { isScriptLoaded } = useSelector((state: IApplicationState) => state.fingerPrint);
  const isOpen = useSelector((state: IApplicationState) => state.establishment.open.isOpen);
  const { settings, isMPTokenValid } = useSelector((state: IApplicationState) => state.establishment);
  const { hash, mode, number, isOperatorMode } = useSelector((state: IApplicationState) => state.localOrders);
  const {
    existCoupons,
    data: coupon,
    error: couponError,
    loading: couponLoading
  } = useSelector((state: IApplicationState) => state.coupons);

  const [isTotalValueElementHidden, setIsTotalValueElementHidden] = useState<boolean>(false);

  const { states, context, actions } = useCheckout();

  useVisibilityObserver({
    querySelectorName: QUERY_SELECTOR_NAME_OBSERVER,
    onHidden: (state: boolean) => setIsTotalValueElementHidden(state)
  });

  const { handleSubmit, handleSendOrder, setPaymentOption } = actions.order;

  useScrollTo({ x: 0, y: 0 });
  useFingerPrint({ isScriptLoaded });
  const tabInfo = useTabInfo();
  const { getTranslation } = useTranslator();
  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const { hasLoyaltyProgramAccess, isLoyaltyProgramEnabled, isLoyaltyTemporarilyBlocked, shouldDisplayValidOrderCard } =
    useLoyaltyProgram();

  const isAbrahaoStore = useMemo(() => settings?.is_abrahao || false, [settings?.is_abrahao]);

  const paymentPreference = useMemo(
    () => user?.paymentPreference?.find((payment) => payment.establishment === settings?.name),
    [settings, user]
  );

  const isGuestCheck = useMemo(() => mode === LocalOrdersEnum.guestCheck, [mode]);

  const wasNumberCompletelyType: (phone: string) => boolean = (phone) => phone.replace(/\D/g, '').length >= 10;

  const handlePhoneChange: (phone: string, isValid: boolean) => void = useCallback(
    (phone, isValid) => {
      actions.user.setClientPhone(phone);
      actions.validations.setIsClientPhoneValid(isValid);
    },
    [actions.user, actions.validations]
  );

  const handleNameChange: (phone: string, isValid: boolean) => void = useCallback(
    (name, isValid) => {
      actions.user.setName(name);
      actions.validations.setIsNameValid(isValid);
    },
    [actions.user, actions.validations]
  );

  const showField: (field: FieldOptionsEnum) => boolean = useCallback((field) => {
    if (field === FieldOptionsEnum.noRequired) return false;

    return true;
  }, []);

  const isCouponApplied: boolean = useMemo(() => {
    return !couponError && !!coupon.selectedCoupon;
  }, [coupon.selectedCoupon, couponError]);

  const gaTrackAddMore: () => void = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.addMoreItems, {
      // eslint-disable-next-line camelcase
      establishment_id: settings?.store_code
    });
  }, [settings?.store_code]);

  const isUnableToConfirmCvv: boolean = useMemo(
    () => couponLoading || states.order.cvvValue.length < 3,
    [couponLoading, states.order.cvvValue.length]
  );

  const renderName: () => JSX.Element = useCallback(() => {
    const isNameRequired = isRequiredField(isOperatorMode ? FieldOptionsEnum.optional : settings?.mm_ask_for_name);

    const handleIsDone: () => boolean = () => !!states.user.name.trim() && states.validations.isNameValid;

    return (
      <S.OptionContainer>
        <OptionHeader
          subtitle={false}
          isDone={handleIsDone()}
          isRequired={isNameRequired}
          title={getTranslation('localOrder.localOrderTitle', {
            clientName: isOperatorMode ? getTranslation('localOrder.ofClient') : getTranslation('localOrder.lastName')
          })}
        />

        <S.OptionSpacing>
          <InputName
            data-test="input-name"
            required={isNameRequired}
            defaultValue={states.user.name}
            allowJustFirstName={isOperatorMode}
            placeholder={!isOperatorMode ? getTranslation('localOrder.howToCallYou') : ''}
            onChange={(value: string, isValid: boolean): void => handleNameChange(value, isValid)}
            errorMessage={isOperatorMode ? getTranslation('localOrder.enterClientName') : undefined}
          />
        </S.OptionSpacing>
      </S.OptionContainer>
    );
  }, [
    getTranslation,
    isOperatorMode,
    handleNameChange,
    states.user.name,
    settings?.mm_ask_for_name,
    states.validations.isNameValid
  ]);

  const renderTable: () => JSX.Element = useCallback(() => {
    const isTableFilled = states.order.table.length > 0 && Number(states.order.table) > 0;

    return (
      <S.OptionContainer>
        <OptionHeader
          isRequired
          subtitle={false}
          isDone={isTableFilled}
          title={
            isOperatorMode ? getTranslation('localOrder.tableNumber') : getTranslation('localOrder.whichIsYourTable')
          }
        />

        <S.OptionSpacing>
          <Input
            name="table"
            type="number"
            maxLength={9}
            defaultValue=""
            id="input-table"
            data-test="input-table"
            onChange={(value): void => actions.order.setTable(value as string)}
            info={!isOperatorMode ? getTranslation('localOrder.waiterToLocateYourTable') : ''}
          />
        </S.OptionSpacing>
      </S.OptionContainer>
    );
  }, [states.order.table, isOperatorMode, getTranslation, actions.order]);

  const renderPhone: () => JSX.Element = useCallback(() => {
    const isPhoneFilled = wasNumberCompletelyType(states.user.clientPhone);
    const isPhoneRequired = isRequiredField(isOperatorMode ? FieldOptionsEnum.optional : settings?.mm_ask_for_phone);

    const handleIsDone = (): boolean => isPhoneFilled && states.validations.isClientPhoneValid;

    return (
      <S.OptionContainer>
        <OptionHeader
          isDone={handleIsDone()}
          isRequired={isPhoneRequired}
          title={getTranslation('user.phoneNumber')}
          subtitle={`(${getTranslation('localOrder.ifWeNeedTalkToYou')})`}
        />

        <S.OptionSpacing>
          <InputPhone
            data-test="input-phone"
            required={isPhoneRequired}
            placeholder="(00) 00000-0000"
            defaultValue={states.user.clientPhone}
            onChange={(phoneValue: string, isValid: boolean): void => handlePhoneChange(phoneValue, isValid)}
          />
        </S.OptionSpacing>
      </S.OptionContainer>
    );
  }, [
    isOperatorMode,
    getTranslation,
    handlePhoneChange,
    states.user.clientPhone,
    settings?.mm_ask_for_phone,
    states.validations.isClientPhoneValid
  ]);

  const renderUserInfo: () => JSX.Element = useCallback(() => {
    return (
      <S.OptionContainer>
        <OptionHeader title={getTranslation('user.contactInfo')} isRequired isDone subtitle={false} />

        <UserInfo
          name={states.user.name}
          phone={states.user.clientPhone}
          switchAccountButtonReturnPage="localOrder"
          displaySwitchAccountButton={hasLoyaltyProgramAccess && !isLoyaltyProgramEnabled}
        />
      </S.OptionContainer>
    );
  }, [getTranslation, hasLoyaltyProgramAccess, isLoyaltyProgramEnabled, states.user.clientPhone, states.user.name]);

  const cartItemList = useMemo(() => groupCartItems(cart.products), [cart.products]);

  useEffect(() => {
    if (!mode || !hash) {
      if (!hasShownError.current) {
        toast.error(getTranslation('localOrder.errorToGetCartData'));

        hasShownError.current = true;
      }
    }
  }, [cart, dispatch, history, mode, hash, getTranslation]);

  useEffect(() => {
    if (paymentPreference && mode !== LocalOrdersEnum.balcony) {
      dispatch(removePaymentPreference());
      setPaymentOption(undefined);
      return;
    }

    if (!paymentPreference?.category) {
      return;
    }

    const invalidPaymentList = [PaymentCategoryEnum.pix];

    if (invalidPaymentList.includes(paymentPreference.category)) {
      dispatch(removePaymentPreference());
      setPaymentOption(undefined);
      return;
    }
  }, [dispatch, mode, isMPTokenValid, paymentPreference, setPaymentOption]);

  useEffect(() => {
    dispatch(setOrigin(DeliveryWayEnum.onsite));
  }, [dispatch]);

  useEffect(() => {
    actions.order.setDeliveryOption(DeliveryWayEnum.onsite);
  }, [actions.order, isOperatorMode]);

  if (!settings) {
    return <></>;
  }

  return (
    <>
      <S.Container>
        <HeaderSecondary
          title={
            isOperatorMode
              ? getTranslation('localOrder.orderOf', { modeAndNumber: `${getTranslation(mode)} ${number}` })
              : getTranslation('localOrder.myOrder')
          }
        />

        <FooterBackdrop />

        <div className="container">
          <S.TabWrapper>
            {isAbrahaoStore && mode !== LocalOrdersEnum.guestCheck && (
              <ChangeOrderClient isOnCart isLoadingTable={false} />
            )}
          </S.TabWrapper>

          <CheckOrder.CheckOrder
            isCheckout
            total={cart.total}
            loyaltyDiscount={cart?.values?.loyalty}
            coupon={isCouponApplied ? coupon.selectedCoupon : undefined}
          >
            {cartItemList.map((comboItem, index) => (
              <CheckOrder.CheckOrderItem
                key={index}
                getTranslation={getTranslation}
                combo={comboItem.comboId ? comboItem : undefined}
                item={comboItem.productId ? comboItem.items[0] : undefined}
              />
            ))}

            <Link href="/menu">
              <S.AddMoreItems onClick={gaTrackAddMore} data-test="btn-add-more-items">
                <Button>{getTranslation('order.addMoreItem')}</Button>
              </S.AddMoreItems>
            </Link>
          </CheckOrder.CheckOrder>
        </div>

        <NewWaves backgroundColor={theme.colors.gray200} />

        <div className="content-wrap">
          <div className="container">
            <OrderFullAgeCheckbox
              isFullAgeTermAccept={states.validations.isFullAgeTermAccept}
              setIsFullAgeTermAccept={(status): void => actions.validations.setIsFullAgeTermAccept(status)}
            />

            {mode === OperationModesEnum.kiosk && !!existCoupons && settings?.mm_coupon_enabled && <CheckoutCoupon />}

            {!!settings?.id && (
              <>
                {isUserAuthenticated && !settings.is_abrahao && renderUserInfo()}

                {(!isUserAuthenticated || settings.is_abrahao) && (
                  <>
                    {showField(settings?.mm_ask_for_name || FieldOptionsEnum.required) && renderName()}

                    {showField(settings?.mm_ask_for_phone || FieldOptionsEnum.required) && renderPhone()}
                  </>
                )}
              </>
            )}

            {isGuestCheck && renderTable()}

            {shouldDisplayValidOrderCard && !isLoyaltyTemporarilyBlocked && (
              <LoyaltyValidOrderConfirmationCard isLocalOrder />
            )}

            {compareStrings(mode, OperationModesEnum.kiosk) && (
              <CheckoutContext.Provider value={context}>
                <PaymentOption
                  title={getTranslation('localOrder.howYouWillPay')}
                  isModalOpen={states.modals.showPaymentOptionModal}
                />

                {mode === OperationModesEnum.kiosk && settings?.mm_receipt_ask_for_cpf && <DocumentInput />}

                <CardFeedbackModal
                  isShow={states.modals.showPaymentFeedbackModal}
                  errorType={states.others.errorType}
                  onClose={(): void => actions.modals.setShowPaymentFeedbackModal(false)}
                />

                <PaymentStepModal
                  step={states.modals.processingModalType}
                  isModalVisible={states.modals.showProcessingPaymentModal}
                  onClose={(): void => actions.modals.setShowProcessingPaymentModal(false)}
                />

                <ConfirmCvvModal
                  showCvvModal={states.modals.showCvvModal}
                  isUnableToConfirmCvv={isUnableToConfirmCvv}
                  onClose={(): void => {
                    actions.modals.setShowCvvModal(false);
                    actions.others.setIsLoading(false);
                  }}
                  onConfirm={(): void => {
                    actions.others.setIsLoading(false);

                    actions.modals.setProcessingModalType(PaymentStepProcessEnum.paying);
                    actions.modals.setShowProcessingPaymentModal(true);
                    actions.modals.setCvvModalConfirmed(true);
                  }}
                  actions={{
                    handleConfirmAction: handleSendOrder,
                    setCvvValue: actions.order.setCvvValue,
                    setIsLoading: actions.others.setIsLoading,
                    setShowCvvModal: actions.modals.setShowCvvModal,
                    setPaymentOption: actions.order.setPaymentOption,
                    setShowPaymentFeedbackModal: actions.modals.setShowPaymentFeedbackModal
                  }}
                />
              </CheckoutContext.Provider>
            )}
          </div>
        </div>

        <Footer className={!isOpen ? 'closed' : ''} showDynamicInfo={isTotalValueElementHidden}>
          <SummaryFooter
            values={cart.values}
            isVisible={isTotalValueElementHidden}
            couponSegment={coupon.selectedCoupon?.segment}
          />

          {!isOpen && (
            <>
              <h2>{getTranslation('floatingFooter.currentlyClosed')}</h2>
              <span>{getTranslation('floatingFooter.notAcceptingNewOrders')}</span>
            </>
          )}

          <Button
            isGhost={false}
            data-test="btn-send-order"
            isLoading={states.others.isLoading}
            onClick={(): void => handleSubmit()}
            isDisabled={
              !hash ||
              couponLoading ||
              states.others.isLoading ||
              states.order.isZeroedOrder ||
              states.order.pendentField !== PossibleStatusEnum.default
            }
          >
            {states.others.isLoading
              ? `${getTranslation('localOrder.makingOrder')}...`
              : getTranslation(states.order.pendentField)}
          </Button>
        </Footer>

        <ClientChangeModal
          isShow={states.modals.showClientChangeModal}
          onClose={(): void => actions.modals.setShowClientChangeModal(false)}
          onConfirm={(): void => {
            actions.validations.setIsClientConfirmed(true);
            actions.validations.setIsWaitingRevalidation(true);
          }}
        />

        <ItemEdit item={states.others.editItem} onClose={(): void => actions.others.setEditItem(null)} />
      </S.Container>

      {!!states.modals.shouldDisplayQrCodeScannerModal && (
        <QrCodeScannerModal
          onConfirm={(): void => actions.validations.setIsWaitingRevalidation(true)}
          onClose={(): void => actions.modals.setShouldDisplayQrCodeScannerModal(false)}
        />
      )}
    </>
  );
};

export default LocalOrder;
