/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > .info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .title {
        margin: 0 0 6px;

        display: flex;
        align-items: center;

        > .icon {
          margin-right: 8px;

          display: flex;
          align-items: center;

          > svg {
            color: ${theme.colors.textColor};
          }
        }

        > p {
          margin: 0;

          font-weight: 800;
          font-size: 1rem;
          line-height: 100%;
          text-align: left;
          color: ${theme.colors.textColor};
        }
      }

      .quantity {
        margin: 0 0 0 28px;

        font-weight: 500;
        font-size: 0.857rem;
        line-height: 120%;
        text-align: left;
        color: ${theme.colors.gray500};
      }
    }

    > .action {
      cursor: pointer;
      font-weight: 700;
      font-size: 0.857rem;
      line-height: 150%;
      text-decoration-line: underline;
      color: ${theme.brandColors.primary};
    }
  `}
`;
