import styled, { css } from 'styled-components';
import { Container } from 'components/Checkout/CheckoutSelect';

const modifiers = {
  appliedCoupon: css`
    margin-bottom: 0;

    flex-direction: column;
    align-items: flex-start;

    .name {
      margin-bottom: 5px;
    }
  `
};

export const CouponInfo = styled.div`
  ${({ theme }) => css`
    gap: 0.5rem;
    display: flex;
    flex-direction: column;

    .info {
      font-weight: 500;
      line-height: 150%;
      font-size: 0.75rem;
      color: ${theme.colors.mediumGray};
    }
  `}
`;

export const LoyaltyContainer = styled(Container)`
  pointer-events: none;

  .name {
    text-transform: uppercase;
  }
`;

export const LoyaltyIconWrapper = styled.div`
  padding-top: 0.2rem;

  > svg {
    path {
      fill: ${({ theme }) => theme.colors.mediumGray};
    }
  }
`;

interface CouponInfoWrapProps {
  isCouponApplied: boolean;
}

export const CouponInfoWrap = styled.div<CouponInfoWrapProps>`
  ${({ theme, isCouponApplied }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      font-weight: 600;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.textColor};
    }

    .discount {
      padding: 0.25rem;
      border-radius: 0.25rem;

      font-weight: 700;
      line-height: 150%;
      font-size: 0.75rem;
      background: ${theme.colors.success};
      color: ${theme.colors.backgroundColor};
    }

    .loyalty-description {
      font-weight: 500;
      font-size: 0.75rem;
      color: ${theme.colors.mediumGray};
    }

    ${isCouponApplied && modifiers.appliedCoupon};
  `}
`;

export const ModalContent = styled.div`
  padding: 1rem 1rem 0;

  gap: 1rem;
  display: flex;
  flex-direction: column;
`;
