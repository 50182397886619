import React from 'react';

import { Container } from './styles';

const TitleDivider: React.FC = ({ children }) => (
  <Container>
    {children && (
      <div className="info">
        <strong className="title">{children}</strong>
      </div>
    )}
  </Container>
);

export default TitleDivider;
