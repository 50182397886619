import { AnyAction } from 'redux';
import { all, takeLatest, put } from 'redux-saga/effects';

import { fetchMenus } from '~/services';
import { removeProductTags } from '~/utils';
import { Highlight, Menu } from '~/interfaces';
import { FetchMenusResponse } from '~/services/fetchMenus';
import { OldProduct } from '~/hooks/useParserFetchMenusResponse';

import { MenuActionTypes } from './types';
import { fetchMenusError, fetchMenusSuccess } from './actions';

export function* fetchMenusRequest({ payload }: AnyAction): Generator {
  const { url, parserFetchMenusResponse } = payload as {
    url: string;
    parserFetchMenusResponse: (oldResponse: { products: OldProduct[] }) => FetchMenusResponse;
  };

  try {
    const response = (yield fetchMenus({ url, parserFetchMenusResponse })) as FetchMenusResponse;

    const formattedMenuList: Menu[] = response.menus?.map((menu) => ({
      ...menu,
      groups: menu.groups?.map((group) => ({
        ...group,
        products: group.products?.map((product) => ({
          ...product,
          name: removeProductTags(product.name)
        }))
      }))
    }));

    const formattedHighlightList: Highlight[] = response.highlights?.map((highlight) => ({
      ...highlight,
      product: highlight.product
        ? {
            ...highlight.product,
            name: removeProductTags(highlight.product.name)
          }
        : undefined
    }));

    yield put(
      fetchMenusSuccess({
        menus: formattedMenuList,
        highlights: formattedHighlightList
      })
    );
  } catch (error) {
    console.error('Ocorreu um erro ao buscar o cardápio da loja.', (error as Error)?.message);

    yield put(fetchMenusError());
  }
}

export default all([takeLatest(MenuActionTypes.FETCH_MENUS_REQUEST, fetchMenusRequest)]);
