import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { FeaturesEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';

import featuresName from './featureList';

export interface UseHasFeatureAccessProps {
  featureId: FeaturesEnum;
}

const currentBetaFeaturesList = (process.env.NEXT_PUBLIC_CURRENT_BETA_FEATURES || []) as FeaturesEnum[];

export default function useHasFeatureAccess({ featureId }: UseHasFeatureAccessProps): boolean[] {
  const isThisFeatureEnabled = useSelector((state: IApplicationState) => {
    const settings = state.establishment?.settings;

    if (!settings) return false;

    return !!settings[featuresName[featureId]];
  });

  const [hasFeatureAccess, setHasFeatureAccess] = useState(false);

  const isBetaFeature = useMemo(() => currentBetaFeaturesList.includes(featureId), [featureId]);

  useEffect(() => {
    if (isBetaFeature) {
      return setHasFeatureAccess(isThisFeatureEnabled);
    }

    return setHasFeatureAccess(true);
  }, [featureId, isBetaFeature, isThisFeatureEnabled]);

  return [hasFeatureAccess];
}
