import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';

import { OptionListItemProps } from '~/interfaces/general';

import { WaiterActionTypes } from './types';

export interface SetCallWaiterProps {
  aId?: string;
  table: number;
  optionList: OptionListItemProps[];
  callback: (isOk: boolean) => void;
}

export const setCallWaiter = ({ aId, table, callback, optionList }: SetCallWaiterProps): AnyAction => {
  return action(WaiterActionTypes.SET_CALL_WAITER, { aId, table, callback, optionList });
};

export const callWaiterSuccess = (): AnyAction => {
  return action(WaiterActionTypes.CALL_WAITER_SUCCESS);
};

export const callWaiterError = (): AnyAction => {
  return action(WaiterActionTypes.CALL_WAITER_ERROR);
};
