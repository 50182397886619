import { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { truncateAmount } from '~/utils';
import { isTakeaway } from '~/utils/orderDeliveryTypes';
import { IApplicationState } from '~/redux-tools/store';
import useCheckoutSubmit from '~/hooks/useCheckout/hooks/useCheckoutSubmit';
import { removeMPPaymentMethods } from '~/redux-tools/store/establishment/actions';
import { fetchCouponRequest, setOrigin } from '~/redux-tools/store/coupons/actions';
import useCheckoutIsZeroedOrder from '~/hooks/useCheckout/hooks/useCheckoutIsZeroedOrder';
import useCheckoutValidateFields from '~/hooks/useCheckout/hooks/useCheckoutValidateFields';
import { useFetchEstablishmentRequest, useGetCouponErrorMessage, useSlug, useTranslator } from '~/hooks';
import { addCheckoutCoupon, addCheckoutData, setErrorCheckoutData } from '~/redux-tools/store/checkout/actions';
import { CheckoutContextProps, DefaultPage, CartProduct, PaymentMethod, PaymentReceipt } from '~/interfaces/general';
import {
  QueryParam,
  ErrorsEnum,
  OrderWayEnum,
  DeliveryWayEnum,
  PaymentStepProcessEnum,
  SelectedPaymentModeEnum
} from '~/interfaces/enums';

import useCheckoutCartInfo from './hooks/useCheckoutCartInfo';
import useValidatePhoneNumber from '../useValidatePhoneNumber';

export default function useCheckout(token?: string) {
  const slug = useSlug();
  const history = useRouter();
  const dispatch = useDispatch();
  const isFirstLoaded = useRef(false);
  const isCouponFetched = useRef(false);

  const cart = useSelector((state: IApplicationState) => state.cart);
  const user = useSelector((state: IApplicationState) => state.user.data);
  const checkout = useSelector((state: IApplicationState) => state.checkout);
  const { selectedCoupon } = useSelector((state: IApplicationState) => state.coupons.data);
  const { origin, existCoupons, error: couponError } = useSelector((state: IApplicationState) => state.coupons);
  const { settings, isMPTokenValid } = useSelector((state: IApplicationState) => state.establishment);
  const address = useSelector((state: IApplicationState) =>
    state.user.data.addresses?.find((userAddress) => userAddress.favorite === true)
  );

  const { getTranslation } = useTranslator();
  const { isPhoneValid } = useValidatePhoneNumber();
  const { displayErrorMessage } = useGetCouponErrorMessage();

  // Modals
  const [showCvvModal, setShowCvvModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [cvvModalConfirmed, setCvvModalConfirmed] = useState(false);
  const [showSchedulingModal, setShowSchedulingModal] = useState(false);
  const [showClientChangeModal, setShowClientChangeModal] = useState(false);
  const [showPaymentOptionModal, setShowPaymentOptionModal] = useState(false);
  const [showPaymentReceiptModal, setShowPaymentReceiptModal] = useState(false);
  const [showPaymentFeedbackModal, setShowPaymentFeedbackModal] = useState(false);
  const [showProcessingPaymentModal, setShowProcessingPaymentModal] = useState(false);
  const [showLatLngConfirmationModal, setShowLatLngConfirmationModal] = useState(false);
  const [defaultModalPage, setDefaultModalPage] = useState<DefaultPage | undefined>(undefined);
  const [hasSinglePaymentValueInputError, setHasSinglePaymentValueInputError] = useState(false);
  const [processingModalType, setProcessingModalType] = useState(PaymentStepProcessEnum.sending);
  const [shouldDisplayQrCodeScannerModal, setShouldDisplayQrCodeScannerModal] = useState<boolean>(false);

  // UserInfo
  const [cpf, setCpf] = useState(checkout.cpf);
  const [addressSelected, setAddressSelected] = useState(!!address);
  const [name, setName] = useState(checkout.name || user?.name || '');
  const [clientPhone, setClientPhone] = useState(checkout.clientPhone || user?.phone || '');

  // Validations
  const [isCpfValid, setIsCpfValid] = useState(true);
  const [isClientConfirmed, setIsClientConfirmed] = useState(false);
  const [isNameValid, setIsNameValid] = useState(!!checkout.name || !!user?.name);
  const [isFullAgeTermAccept, setIsFullAgeTermAccept] = useState(checkout.isFullAgeTermAccept || false);
  const [isClientPhoneValid, setIsClientPhoneValid] = useState(!!clientPhone && isPhoneValid(clientPhone));

  const [isWaitingRevalidation, setIsWaitingRevalidation] = useState<boolean>(false);

  // OrderInfo
  const [table, setTable] = useState('');
  const [cvvValue, setCvvValue] = useState('');
  const [isOrderSent, setIsOrderSent] = useState(false);
  const [moneyChange, setMoneyChange] = useState(false);
  const [moneyChangeValue, setMoneyChangeValue] = useState(0);
  const [deliveryRate, setDeliveryRate] = useState<number | false>(false);
  const [deliveryOption, setDeliveryOption] = useState<DeliveryWayEnum | undefined>(checkout?.deliveryOption);
  const [schedulingDate, setSchedulingDate] = useState<Date | undefined>(
    (checkout?.schedulingDate && new Date(checkout?.schedulingDate)) || undefined
  );

  const [paymentOption, setPaymentOption] = useState<PaymentMethod | undefined>(
    checkout.paymentOption ||
      (user?.paymentPreference && Array.isArray(user.paymentPreference)
        ? user?.paymentPreference?.find((payment) => payment.establishment === settings?.name)
        : undefined)
  );

  // My Tab
  const [hasPaymentError, setHasPaymentError] = useState(false);
  const [singlePaymentValues, setSinglePaymentValues] = useState(0);
  const [numberPeopleToDivision, setNumberPeopleToDivision] = useState(1);
  const [paymentReceipt, setPaymentReceipt] = useState<PaymentReceipt | undefined>();
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(SelectedPaymentModeEnum.notSelected);

  // Others
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentOrigin, setCurrentOrigin] = useState(origin);
  const [editItem, setEditItem] = useState<CartProduct | null>(null);
  const [errorType, setErrorType] = useState<ErrorsEnum | undefined>();

  useFetchEstablishmentRequest({});

  useEffect(() => {
    function checkMPToken() {
      if (!isMPTokenValid) {
        dispatch(removeMPPaymentMethods());
      }
    }

    checkMPToken();
  }, [dispatch, isMPTokenValid]);

  useEffect(() => {
    if (history.pathname.includes('myTabOnlinePayment')) {
      return;
    }

    if (cart.products.length === 0 && !isOrderSent) {
      history.push(`/menu?${QueryParam.bag}=empty`);
    }
  }, [cart.products.length, history, isOrderSent, slug]);

  useEffect(() => {
    dispatch(
      addCheckoutData({
        cpf,
        name,
        clientPhone,
        paymentOption,
        deliveryOption,
        loading: isLoading,
        isFullAgeTermAccept,
        address: cart.address,
        schedulingDate: schedulingDate?.toISOString()
      })
    );
  }, [
    cpf,
    name,
    address,
    dispatch,
    isLoading,
    clientPhone,
    cart.address,
    paymentOption,
    selectedCoupon,
    deliveryOption,
    schedulingDate,
    addressSelected,
    isFullAgeTermAccept,
    checkout.selectedCoupon
  ]);

  useEffect(() => {
    if (selectedCoupon) {
      dispatch(addCheckoutCoupon(selectedCoupon));

      isCouponFetched.current = true;
    }
  }, [dispatch, selectedCoupon]);

  useEffect(() => {
    dispatch(setErrorCheckoutData(false));
  }, [dispatch]);

  useEffect(() => {
    if (isFirstLoaded.current) {
      return;
    }

    if (checkout.deliveryOption) {
      dispatch(setOrigin(checkout.deliveryOption));
    }

    if (checkout.paymentOption?.category === PaymentCategoryEnum.nupay) {
      dispatch(
        addCheckoutData({
          ...checkout,
          paymentOption: undefined
        })
      );
    }

    isFirstLoaded.current = true;
  }, [checkout, checkout.deliveryOption, dispatch]);

  useEffect(() => {
    if (!existCoupons) {
      return;
    }

    if (couponError && couponError === getTranslation('couponMessage.couponNotFound')) {
      dispatch(addCheckoutCoupon(undefined));

      return;
    }

    if (isCouponFetched.current) {
      return;
    }

    if (!selectedCoupon && !!checkout.selectedCoupon && !couponError) {
      const { code } = checkout.selectedCoupon;

      dispatch(
        fetchCouponRequest({
          displayErrorMessage,
          couponCode: code || '',
          origin: checkout?.deliveryOption
        })
      );

      isCouponFetched.current = true;
    }
  }, [
    origin,
    dispatch,
    couponError,
    existCoupons,
    selectedCoupon,
    getTranslation,
    displayErrorMessage,
    checkout.selectedCoupon,
    checkout.deliveryOption
  ]);

  const takeaway = useMemo(() => {
    return {
      takeaway_discount:
        settings?.mm_takeaway_enabled && settings.mm_takeaway_discount_enabled && settings.mm_takeaway_discount
    };
  }, [settings]);

  const takeawayDiscount = useMemo(() => {
    if (takeaway?.takeaway_discount) {
      return {
        label: `${takeaway.takeaway_discount}%`,
        value: truncateAmount(Number(takeaway.takeaway_discount) / 100)
      };
    }

    const MULTIPLE = 0;

    return {
      label: '',
      value: MULTIPLE
    };
  }, [takeaway]);

  const handleCpfChange = useCallback((cpf: string, isValid: boolean) => {
    setCpf(cpf);
    setIsCpfValid(isValid);
  }, []);

  const userInfo = useMemo(
    () => ({ cpf, name, isNameValid, clientPhone, addressSelected, isClientPhoneValid }),
    [cpf, name, clientPhone, addressSelected, isClientPhoneValid, isNameValid]
  );

  const { isSomeInputInvalid, isDeliveryAndMinimumValueIsNotReached } = useCheckoutValidateFields({
    userInfo,
    orderInfo: {
      paymentOption,
      deliveryOption,
      schedulingDate
    },
    validations: {
      isCpfValid,
      isNameValid,
      isClientPhoneValid,
      isFullAgeTermAccept
    }
  });

  const { pendentField, handleSubmit, handlePayment, handleSendOrder, handleNextUserInteraction } = useCheckoutSubmit({
    userInfo,
    newToken: token,
    isClientConfirmed,
    isLoading: isLoading,
    isWaitingRevalidation,
    disabled: isSomeInputInvalid,
    cvvModalConfirmed: cvvModalConfirmed,
    deliveryMinimumValueError: !!isDeliveryAndMinimumValueIsNotReached,
    orderInfo: {
      table,
      moneyChange,
      deliveryRate,
      paymentOption,
      schedulingDate,
      deliveryOption,
      moneyChangeValue,
      orderWay: origin === DeliveryWayEnum.onsite ? OrderWayEnum.NaLoja : OrderWayEnum.GoomerGo
    },
    actions: {
      setErrorType,
      setIsLoading,
      setIsOrderSent,
      setShowCvvModal,
      setPaymentReceipt,
      setShowSignInModal,
      setHasPaymentError,
      setShowAddressModal,
      setCvvModalConfirmed,
      setIsClientConfirmed,
      setProcessingModalType,
      setShowSchedulingModal,
      setShowClientChangeModal,
      setIsWaitingRevalidation,
      setShowPaymentOptionModal,
      setShowPaymentReceiptModal,
      setShowPaymentFeedbackModal,
      setShowProcessingPaymentModal,
      setShowLatLngConfirmationModal,
      setShouldDisplayQrCodeScannerModal
    }
  });

  const { isZeroedOrder } = useCheckoutIsZeroedOrder({ isTakeaway: isTakeaway(deliveryOption) });
  const { cartInfo } = useCheckoutCartInfo({
    isTakeaway: isTakeaway(deliveryOption)
  });

  const { values } = cartInfo();

  const isLoyaltyDiscountHigherThanSubtotal: boolean = useMemo(() => {
    if (values.loyalty && values.loyalty > values.subtotal) {
      return true;
    }

    return false;
  }, [values.loyalty, values.subtotal]);

  const handleFormatPaymentOption = () => {
    if (typeof paymentOption === 'string') {
      return { type: '', flag: '', cardType: '', category: '' };
    }

    return {
      type: paymentOption?.type || '',
      flag: paymentOption?.flag || '',
      document: paymentOption?.document || '',
      cardType: paymentOption?.cardType || '',
      category: paymentOption?.category || '',
      cardDescription: paymentOption?.cardDescription || ''
    };
  };

  return {
    states: {
      user: {
        cpf,
        name,
        clientPhone,
        addressSelected
      },
      others: {
        editItem,
        isLoading,
        errorType,
        currentOrigin
      },
      myTab: {
        paymentReceipt,
        hasPaymentError,
        singlePaymentValues,
        numberPeopleToDivision
      },
      validations: {
        isCpfValid,
        isNameValid,
        isClientConfirmed,
        isClientPhoneValid,
        isFullAgeTermAccept,
        isWaitingRevalidation
      },
      order: {
        table,
        cvvValue,
        isOrderSent,
        moneyChange,
        pendentField,
        deliveryRate,
        isZeroedOrder,
        paymentOption,
        schedulingDate,
        deliveryOption,
        moneyChangeValue,
        isLoyaltyDiscountHigherThanSubtotal
      },
      modals: {
        showCvvModal,
        showSignInModal,
        defaultModalPage,
        cvvModalConfirmed,
        processingModalType,
        showClientChangeModal,
        showPaymentOptionModal,
        showPaymentReceiptModal,
        showPaymentFeedbackModal,
        showProcessingPaymentModal,
        showLatLngConfirmationModal,
        shouldDisplayQrCodeScannerModal
      }
    },
    actions: {
      user: {
        setCpf,
        setName,
        setClientPhone,
        setAddressSelected
      },
      others: {
        setEditItem,
        setIsLoading,
        setErrorType,
        setCurrentOrigin
      },
      validations: {
        setIsCpfValid,
        setIsNameValid,
        setIsClientConfirmed,
        setIsClientPhoneValid,
        setIsFullAgeTermAccept,
        setIsWaitingRevalidation
      },
      myTab: {
        handlePayment,
        setPaymentReceipt,
        setHasPaymentError,
        selectedPaymentMode,
        singlePaymentValues,
        setSelectedPaymentMode,
        setNumberPeopleToDivision
      },
      order: {
        setTable,
        setCvvValue,
        handleSubmit,
        setIsOrderSent,
        setMoneyChange,
        handleSendOrder,
        setDeliveryRate,
        setPaymentOption,
        setSchedulingDate,
        setDeliveryOption,
        setMoneyChangeValue,
        handleNextUserInteraction
      },
      modals: {
        setShowCvvModal,
        setShowSignInModal,
        setDefaultModalPage,
        setCvvModalConfirmed,
        setProcessingModalType,
        setShowClientChangeModal,
        setShowPaymentOptionModal,
        setShowPaymentReceiptModal,
        setShowPaymentFeedbackModal,
        setShowProcessingPaymentModal,
        setShowLatLngConfirmationModal,
        setShouldDisplayQrCodeScannerModal
      }
    },
    context: {
      cpf: cpf,
      isCpfValid: isCpfValid,
      discountType: 'percent',
      moneyChange: moneyChange,
      discount: takeawayDiscount,
      defaultPage: defaultModalPage,
      deliveryOption: deliveryOption,
      schedulingDate: schedulingDate,
      showAddressModal: showAddressModal,
      moneyChangeValue: moneyChangeValue,
      singlePaymentValue: singlePaymentValues,
      showSchedulingModal: showSchedulingModal,
      selectedPaymentMode: selectedPaymentMode,
      paymentOption: handleFormatPaymentOption(),
      showPaymentOptionModal: showPaymentOptionModal,
      numberPeopleToDivision: numberPeopleToDivision,
      showPaymentReceiptModal: showPaymentReceiptModal,
      setMoneyChange: (value) => setMoneyChange(value),
      setPaymentOption: (value) => setPaymentOption(value),
      setDefaultPage: (value) => setDefaultModalPage(value),
      setDeliveryOption: (value) => setDeliveryOption(value),
      setSchedulingDate: (value) => setSchedulingDate(value),
      setAddressSelected: (value) => setAddressSelected(value),
      showLatLngConfirmationModal: showLatLngConfirmationModal,
      setMoneyChangeValue: (value) => setMoneyChangeValue(value),
      setShowAddressModal: (value) => setShowAddressModal(value),
      setCpf: (value, isValid) => handleCpfChange(value, isValid),
      setSinglePaymentValue: (value) => setSinglePaymentValues(value),
      shouldDisplayQrCodeScannerModal: shouldDisplayQrCodeScannerModal,
      hasSinglePaymentValueInputError: hasSinglePaymentValueInputError,
      setSelectedPaymentMode: (value) => setSelectedPaymentMode(value),
      setShowSchedulingModal: (value) => setShowSchedulingModal(value),
      setShowPaymentOptionModal: (value) => setShowPaymentOptionModal(value),
      setNumberPeopleToDivision: (value) => setNumberPeopleToDivision(value),
      setShowPaymentReceiptModal: (value) => setShowPaymentReceiptModal(value),
      setShowLatLngConfirmationModal: (value) => setShowLatLngConfirmationModal(value),
      setShouldDisplayQrCodeScannerModal: (value) => setShouldDisplayQrCodeScannerModal(value),
      setHasSinglePaymentValueInputError: (value) => setHasSinglePaymentValueInputError(value)
    } as CheckoutContextProps
  };
}
