import React, { useMemo } from 'react';

import { useTranslator } from '~/hooks';
import { Accordion } from '~/components';

import * as S from './styles';

interface CardErrorListItem {
  title: string;
  content: string;
}

const ErrorList = (): JSX.Element => {
  const { getTranslation } = useTranslator();

  const infoList = useMemo(() => {
    const errorList = getTranslation('payment.cardErrorList', {
      returnObjects: true
    });

    if (Array.isArray(errorList)) {
      return errorList as unknown as CardErrorListItem[];
    }

    return [];
  }, [getTranslation]);

  return (
    <S.Container>
      {infoList?.map((info, index) => (
        <Accordion key={index} {...info} />
      ))}
    </S.Container>
  );
};

export default ErrorList;
