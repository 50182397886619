import { action } from 'typesafe-actions';

import { ICoordinates } from '~/interfaces/general';

import { GeolocationActionTypes } from './types';

export const addUserLocation = (location: ICoordinates) => {
  return action(GeolocationActionTypes.ADD_GEOLOCATION, location);
};

export const setIsGoogleMapsApiLoaded = (isloaded: boolean) => {
  return action(GeolocationActionTypes.SET_IS_GOOGLE_MAPS_API_LOADED, isloaded);
};
