import { IEstablishmentSettings } from '~/interfaces/general';
import { FeaturesEnum, FeaturesNamedEnum } from '~/interfaces/enums';

type FeaturesName = Record<number, keyof IEstablishmentSettings>;

const featuresName: FeaturesName = {
  [FeaturesEnum.GNLGuestCheck]: FeaturesNamedEnum.GnlTabOperation,
  [FeaturesEnum.UserSignUp]: FeaturesNamedEnum.CustomerSignUp,
  [FeaturesEnum.Pwa]: FeaturesNamedEnum.Pwa,
  [FeaturesEnum.LoyaltyProgram]: FeaturesNamedEnum.LoyaltyProgram,
  [FeaturesEnum.QrCodeLoyaltyProgram]: FeaturesNamedEnum.QrCodeLoyaltyProgram,
  [FeaturesEnum.QrCodeOnlinePayment]: FeaturesNamedEnum.QrCodeOnlinePayment,
  [FeaturesEnum.ManualPix]: FeaturesNamedEnum.ManualPix,
  [FeaturesEnum.MercadoPago]: FeaturesNamedEnum.MercadoPago,
  [FeaturesEnum.TableOnlinePayment]: FeaturesNamedEnum.TableOnlinePayment,
  [FeaturesEnum.NewDeliveryMenu]: FeaturesNamedEnum.NewDeliveryMenu
};

export default featuresName;
