import { action } from 'typesafe-actions';

import { Highlight, Menu } from '~/interfaces';
import { FetchMenusResponse } from '~/services/fetchMenus';
import { OldProduct } from '~/hooks/useParserFetchMenusResponse';

import { MenuActionTypes } from './types';

export interface FetchMenusRequestProps {
  url: string;
  parserFetchMenusResponse: (oldResponse: { products: OldProduct[] }) => FetchMenusResponse;
}

export const fetchMenusRequest = ({
  url,
  parserFetchMenusResponse
}: FetchMenusRequestProps): { type: string; payload: FetchMenusRequestProps } =>
  action(MenuActionTypes.FETCH_MENUS_REQUEST, { url, parserFetchMenusResponse });

interface FetchMenusSuccessPayoad {
  menus: Menu[];
  highlights: Highlight[];
}

export const fetchMenusSuccess = (
  payload: FetchMenusSuccessPayoad
): { type: string; payload: FetchMenusSuccessPayoad } => action(MenuActionTypes.FETCH_MENUS_SUCCESS, payload);

export const fetchMenusError = (): { type: string } => action(MenuActionTypes.FETCH_MENUS_ERROR);

export const selectCurrentMenu = ({ menu }: { menu: Menu }) => action(MenuActionTypes.SELECT_CURRENT_MENU, menu);
