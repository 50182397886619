import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 800px;
  margin-top: 1.5rem;

  input {
    width: 100%;
    margin: 0.25rem 0 0;

    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }

  > div {
    margin: 0;
  }
`;

export const Title = styled.p`
  font-weight: bold;
  line-height: 150%;
  font-size: 0.875rem;

  span {
    font-size: 0.75rem;
  }
`;
