import styled, { css } from 'styled-components';
import Button from '~/components/Button/Button';

export const Container = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.25rem;
  background: ${({ theme }) => (theme.isDarkMode ? theme.colors.backgroundColor : theme.colors.gray100)};
`;

export const Quantity = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;

export const ActionButton = styled(Button)<{ changeBackgroundColor?: boolean }>`
  ${({ changeBackgroundColor }) => css`
    padding: 0.5rem 0.7rem;

    background-color: ${({ theme }) => theme.colors.gray200};

    ${changeBackgroundColor &&
    css`
      background-color: ${({ theme }) => (theme.isDarkMode ? theme.colors.gray200 : theme.colors.gray100)};
    `}
  `};
`;
