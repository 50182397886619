import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 100dvh;
    overflow-y: auto;

    display: flex;
    flex-direction: column;

    background-color: ${theme.isDarkMode ? theme.colors.gray200 : theme.colors.backgroundColor};
  `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
  height: fit-content;
  padding: 1.5rem 1rem 0;

  display: flex;
  flex-direction: column;
`;

export const Greeting = styled.div`
  margin-bottom: 1.5rem;

  ${({ theme }) => css`
    > span {
      font-weight: 600;
      line-height: 150%;
      font-size: 0.87rem;
      color: ${theme.colors.gray500};
    }

    > h2 {
      font-weight: 600;
      line-height: 150%;
      font-size: 1.12rem;
      color: ${theme.colors.textColor};
    }
  `}
`;

export const Menu = styled.div`
  height: fit-content;

  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .list {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
  }
`;

export const Footer = styled.div`
  width: 100%;
  padding: 1.5rem 1rem 4.62rem;

  gap: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const InfoMessage = styled.div`
  ${({ theme }) => css`
    padding: 0;
    margin: 0;

    text-align: center;

    span {
      font-size: 0.75rem;
      color: ${theme.colors.gray500};
    }
  `};
`;
