import { useCallback } from 'react';
import br from 'date-fns/locale/pt-BR';
import { format, isToday } from 'date-fns';

import useTranslator from '../useTranslator';

const SUNDAY = 0;
const SATURDAY = 6;

export interface FormatDayTextProps {
  date: Date;
  isAbbreviated?: boolean;
  withTodayLabel?: boolean;
}

interface UseGetDayTextReturnProps {
  formatDayText: ({ date, isAbbreviated, withTodayLabel }: FormatDayTextProps) => string;
}

export default function useGetDayText(): UseGetDayTextReturnProps {
  const { getTranslation } = useTranslator();

  const formatDayText = useCallback(
    ({ date, isAbbreviated = false, withTodayLabel = false }: FormatDayTextProps): string => {
      if (withTodayLabel && isToday(date)) return getTranslation('general.today');

      const dayText = format(date, 'cccc', { locale: br }).replace(getTranslation('general.daySuffix'), '');
      const dayTextCapitalized = dayText.charAt(0).toUpperCase() + dayText.slice(1);

      if (isAbbreviated) {
        return dayTextCapitalized.slice(0, 3);
      }

      const daySuffix = date.getDay() > SUNDAY && date.getDay() < SATURDAY ? getTranslation('general.daySuffix') : '';

      return `${dayTextCapitalized}${daySuffix}`;
    },
    [getTranslation]
  );

  return { formatDayText };
}
