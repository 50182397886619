import React, { useContext, useEffect, useMemo } from 'react';
import { RiTimeLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { AccountVerificationContext } from '~/components/AccountVerification';

import * as S from './styles';

const SECONDS_IN_ONE_MINUTE = 60;
const MILLISECONDS_IN_ONE_SECOND = 1000;

let countdownTimeout: NodeJS.Timeout;

const EmailCountdown = (): JSX.Element => {
  const {
    emailRetries,
    countdownTime,
    setEmailRetries,
    setCountdownTime,
    isCountdownRunning,
    isCountdownExpired,
    setIsCountdownRunning,
    setCountdownIsExpired
  } = useContext(AccountVerificationContext);
  const { getTranslation } = useTranslator();

  const minutes = useMemo(
    () => String(Math.floor(countdownTime / SECONDS_IN_ONE_MINUTE)).padStart(2, '0'),
    [countdownTime]
  );

  const seconds = useMemo(() => String(countdownTime % SECONDS_IN_ONE_MINUTE).padStart(2, '0'), [countdownTime]);

  useEffect(() => {
    if (!isCountdownExpired && isCountdownRunning && countdownTime > 0) {
      countdownTimeout = setTimeout(() => {
        setCountdownTime(countdownTime - 1);
      }, MILLISECONDS_IN_ONE_SECOND);
    } else if (!isCountdownExpired && isCountdownRunning && countdownTime === 0) {
      setCountdownIsExpired(true);
      setIsCountdownRunning(false);
      setEmailRetries(emailRetries + 1);
    }

    return () => {
      clearTimeout(countdownTimeout);
    };
  }, [
    isCountdownExpired,
    setCountdownIsExpired,
    countdownTime,
    setCountdownTime,
    isCountdownRunning,
    setIsCountdownRunning,
    setEmailRetries,
    emailRetries
  ]);

  return (
    <S.Container shouldDisplay={!isCountdownExpired}>
      <RiTimeLine size={20} />

      <span>
        {getTranslation('accountVerification.resendNewCode')}
        <strong>
          {minutes}:{seconds}
        </strong>
      </span>
    </S.Container>
  );
};

export default EmailCountdown;
