import React from 'react';

const GoogleButtonIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="4" fill="white" />
    <g clipPath="url(#clip0_1462_9457)">
      <path
        d="M16.3883 20.1375C16.7782 18.9579 17.5307 17.9315 18.5384 17.2048C19.5461 16.4782 20.7576 16.0883 22 16.0908C23.4083 16.0908 24.6817 16.5908 25.6817 17.4092L28.5917 14.5C26.8183 12.9542 24.5458 12 22 12C18.0583 12 14.665 14.2483 13.0333 17.5417L16.3883 20.1375Z"
        fill="#EA4335"
      />
      <path
        d="M25.3667 27.0108C24.4583 27.5967 23.305 27.9092 22 27.9092C20.7625 27.9117 19.5557 27.525 18.5502 26.8036C17.5447 26.0823 16.7916 25.063 16.3975 23.89L13.0308 26.4458C13.8569 28.1178 15.1352 29.5245 16.7207 30.5063C18.3061 31.4881 20.1352 32.0056 22 32C24.4442 32 26.7792 31.1308 28.5283 29.5L25.3675 27.0108H25.3667Z"
        fill="#34A853"
      />
      <path
        d="M28.5283 29.5C30.3575 27.7933 31.545 25.2533 31.545 22C31.545 21.4083 31.4542 20.7725 31.3183 20.1817H22V24.0458H27.3633C27.0992 25.345 26.3883 26.3508 25.3675 27.0108L28.5283 29.5Z"
        fill="#4285F4"
      />
      <path
        d="M16.3975 23.89C16.1936 23.2807 16.09 22.6424 16.0908 22C16.0908 21.3483 16.195 20.7225 16.3883 20.1375L13.0333 17.5417C12.347 18.9272 11.9931 20.4538 12 22C12 23.6 12.3708 25.1083 13.0308 26.4458L16.3975 23.89Z"
        fill="#FBBC05"
      />
    </g>
    <defs>
      <clipPath id="clip0_1462_9457">
        <rect width="20" height="20" fill="white" transform="translate(12 12)" />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleButtonIcon;
