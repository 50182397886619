import { QueryParam } from '~/interfaces/enums';

const handlePageRefresh = (): void => {
  const urlParams = new URLSearchParams(window.location.search);

  const isRefresh = urlParams.get(QueryParam.refresh) === 'true';

  if (isRefresh) {
    const url = window.location.href;
    const [urlWithoutParams] = url.split('?');

    window.location.assign(urlWithoutParams);
  }
};

export default handlePageRefresh;
