export enum PixelEventsEnum {
  Purchase = 'Purchase',
  AddToCart = 'AddToCart',
  ViewContent = 'ViewContent',
  InitiateCheckout = 'InitiateCheckout'
}

export default function reactPixelTrackEvent(trackName: string, props: any): void {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.track(trackName, props);
    });
}
