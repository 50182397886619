import { Reducer } from 'redux';

import { PaymentsState, PaymentsActionTypes } from './types';

const initialState = {
  isGooglePayReady: false
};

const PaymentsReducer: Reducer<PaymentsState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PaymentsActionTypes.SET_IS_GOOGLE_PAY_READY:
      return {
        ...state,
        isGooglePayReady: payload
      };

    default:
      return state;
  }
};

export default PaymentsReducer;
