import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';
import useUserAuthenticationStatus from '~/hooks/useUserAuthenticationStatus';

import { UseLoyaltyProgramHooksProps } from '..';
import useLoyaltyDisplayMenuInfo from './useLoyaltyDisplayMenuInfo';

export type UseLoyaltyValidOrderProps = Pick<
  UseLoyaltyProgramHooksProps,
  'isValidLoyaltyOrder' | 'shouldDisplayValidOrderCard'
>;

export default function useLoyaltyValidOrder(): UseLoyaltyValidOrderProps {
  const { total } = useSelector((state: IApplicationState) => state.cart);
  const { customerLoyaltyProgram } = useSelector((state: IApplicationState) => state.user.data);
  const { loyaltyProgram: establishmentLoyaltyProgram } = useSelector(
    (state: IApplicationState) => state.establishment
  );

  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const { shouldDisplayLoyaltyProgramMenuInfo } = useLoyaltyDisplayMenuInfo();

  const shouldDisplayValidOrderCard: boolean = useMemo(() => {
    return (
      isUserAuthenticated &&
      !!establishmentLoyaltyProgram &&
      shouldDisplayLoyaltyProgramMenuInfo &&
      !customerLoyaltyProgram?.reward_granted_at &&
      !customerLoyaltyProgram?.program_fulfilled_at
    );
  }, [customerLoyaltyProgram, establishmentLoyaltyProgram, isUserAuthenticated, shouldDisplayLoyaltyProgramMenuInfo]);

  const isValidLoyaltyOrder: boolean = useMemo(() => {
    return (
      !!establishmentLoyaltyProgram &&
      shouldDisplayValidOrderCard &&
      total >= establishmentLoyaltyProgram.minimumOrderValue
    );
  }, [total, establishmentLoyaltyProgram, shouldDisplayValidOrderCard]);

  return { isValidLoyaltyOrder, shouldDisplayValidOrderCard };
}
