import { MountedOrder, TabDetails, TabPaymentInfo } from '~/interfaces/general';

export interface IMyTabState {
  readonly loading: boolean;
  readonly date?: string;
  readonly orders: MountedOrder[];
  readonly tabDetails: TabDetails;
  readonly currentPayment?: TabPaymentInfo;
}

export const MyTabActionTypes = {
  ADD_ORDER: '@@myTab/ADD_ORDER',
  CLEAN_TAB: '@@myTab/CLEAN_TAB',
  ADD_PAYMENT: '@@myTab/ADD_PAYMENT',
  CLEAN_PAYMENT: '@@myTab/CLEAN_PAYMENT',

  FETCH_TAB_DETAILS_REQUEST: '@@myTab/FETCH_TAB_DETAILS_REQUEST',
  FETCH_TAB_DETAILS_SUCCESS: '@@myTab/FETCH_TAB_DETAILS_SUCCESS',
  FETCH_TAB_DETAILS_ERROR: '@@myTab/FETCH_TAB_DETAILS_ERROR',

  RESET_MY_TAB: '@@myTab/RESET_MY_TAB'
};
