import React, { InputHTMLAttributes, useCallback, useEffect, useState } from 'react';

import * as S from './styles';

export interface CheckboxWithLabelProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  label: React.ReactNode;
  isWithoutLabel?: boolean;
  handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

const CheckboxWithLabel = ({
  label,
  checked,
  handleChange,
  isWithoutLabel = false,
  ...rest
}: CheckboxWithLabelProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(!!event.target.checked);

      handleChange(event);
    },
    [handleChange]
  );

  return (
    <S.CheckboxContainer $isWithoutLabel={isWithoutLabel}>
      <input id="checkbox" type="checkbox" checked={isChecked} onChange={handleOnChange} {...rest} />

      {!isWithoutLabel && <label htmlFor="checkbox">{label}</label>}
    </S.CheckboxContainer>
  );
};

export default CheckboxWithLabel;
