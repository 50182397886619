export default function phoneMask(phone: string) {
  const removedCountryCode = phone.replace('+55', '');
  const cleanNoNumbersCharacters = removedCountryCode.replace(/\D/g, '');

  if (cleanNoNumbersCharacters.length <= 6) return cleanNoNumbersCharacters.replace(/(\d{2})(\d)/, '($1) $2');

  if (cleanNoNumbersCharacters.length <= 9) return cleanNoNumbersCharacters.replace(/(\d{2})(\d{4})(\d)/, '($1) $2-$3');

  return cleanNoNumbersCharacters.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3').substring(0, 15);
}
