import { addDays, addHours } from 'date-fns';
import { SchedulingConfig, SchedulingConfigTypeEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { getHoursDifference } from '..';

export interface OpenClose {
  open: string;
  close: string;
}

export interface HandleWithDaysProps {
  now: Date;
  weekday: number;
  minLimit: number;
  maxLimit: number;
  isNextWeek: boolean;
  currentWeekday: number;
  operatingTimesOnWeekday: OpenClose[];
  typeMinLimit?: SchedulingConfigTypeEnum;
}

const WEEKDAYS = 7;

const dayConfigTypeList = [SchedulingConfigTypeEnum.days, SchedulingConfigTypeEnum.runningDays];

function handleWithDays({
  now,
  weekday,
  maxLimit,
  isNextWeek,
  minLimit = 0,
  currentWeekday,
  operatingTimesOnWeekday,
  typeMinLimit = SchedulingConfigTypeEnum.days
}: HandleWithDaysProps): false | Date {
  let currentDate = now;

  let daysToAdd = weekday - currentWeekday;

  if (daysToAdd > maxLimit) {
    return false;
  }

  if (daysToAdd < 0) {
    daysToAdd = WEEKDAYS + daysToAdd;

    const daysMinLimit = dayConfigTypeList.includes(typeMinLimit) ? minLimit : 0;

    const isOnInvalidDateRange = daysToAdd > maxLimit || daysToAdd < daysMinLimit;

    if (isOnInvalidDateRange && !isNextWeek) {
      return false;
    }
  }

  if (dayConfigTypeList.includes(typeMinLimit) && daysToAdd < minLimit) {
    return false;
  }

  if (typeMinLimit === SchedulingConfigTypeEnum.hours) {
    const differencesToCloseFromNowList = operatingTimesOnWeekday.map((operatingTime) => {
      return getHoursDifference({
        time: operatingTime.close,
        daysDifference: daysToAdd
      });
    });

    const differencesToOpenFromNowList = operatingTimesOnWeekday
      .map((operatingTime) => {
        return getHoursDifference({ time: operatingTime.open, daysDifference: daysToAdd });
      })
      .filter((operatingTime) => operatingTime > 0);

    if (differencesToOpenFromNowList.length === 0 && differencesToCloseFromNowList.length === 0) {
      return false;
    }

    const availablePeriod = (): number => {
      if (differencesToOpenFromNowList.length === 0) return differencesToCloseFromNowList[0];

      return differencesToOpenFromNowList[0];
    };

    currentDate = addHours(currentDate, availablePeriod() + minLimit);

    const existAvailablePeriodList = differencesToCloseFromNowList.filter((operatingTime) => {
      if (isNextWeek) {
        return true;
      }

      return operatingTime >= minLimit;
    });

    if (existAvailablePeriodList.length === 0) {
      return false;
    }

    return currentDate;
  }

  return addDays(currentDate, daysToAdd);
}

export interface GetDateByWeekdayProps {
  weekday: number;
  isNextWeek?: boolean;
  configs?: SchedulingConfig;
  operatingTimesOnWeekday: OpenClose[];
}

export default function getDateByWeekday({
  configs,
  weekday,
  isNextWeek = false,
  operatingTimesOnWeekday
}: GetDateByWeekdayProps): false | Date {
  const now = new Date();

  const currentWeekday = Number(now.getDay());

  const maxLimit = configs?.max?.value || 0;
  const minLimit = (!isNextWeek && configs?.min?.value) || 0;

  if (!configs) return false;

  return handleWithDays({
    now,
    weekday,
    minLimit,
    maxLimit,
    isNextWeek,
    currentWeekday,
    operatingTimesOnWeekday,
    typeMinLimit: configs.min.type
  });
}
