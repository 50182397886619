import styled from 'styled-components';

export const Container = styled.div`
  z-index: 10;
  left: 50%;
  bottom: 100px;
  position: fixed;
  transform: translateX(-50%);

  width: 100%;
  height: 36px;
  max-width: 800px;
  padding: 8px 16px;

  background-color: ${({ theme }) => (theme.isDarkMode ? theme.colors.gray200 : theme.colors.gray100)};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.gray600};

  span {
    font-weight: 500;
  }

  strong {
    font-weight: 600;
  }

  span,
  strong {
    font-size: 0.75rem;
  }

  svg {
    margin-right: 5px;

    path {
      fill: ${({ theme }) => theme.colors.warning};
    }
  }
`;

export const ProgressBarWrapper = styled.div`
  margin-top: 6px;
`;
