import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import Wink from '~/assets/Wink';
import { useTranslator } from '~/hooks';
import NewWaves from '~/components/NewWaves';
import { IApplicationState } from '~/redux-tools/store';
import GoomerExperience from '~/components/GoomerExperience';

import ChangeOrderClient from '../ChangeOrderClient';

import * as S from './styles';

interface EmptyProps {
  isTicket?: boolean;
  onTabChange?: () => void;
  isOnlinePayment?: boolean;
}

const Empty: React.FC<EmptyProps> = ({ isTicket, isOnlinePayment, onTabChange }) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { getTranslation } = useTranslator();

  const isAbrahaoStore: boolean = useMemo(() => !!settings?.is_abrahao, [settings?.is_abrahao]);

  const whichInfoShouldRender = useMemo(() => {
    if (isAbrahaoStore) {
      return (
        <>
          <ChangeOrderClient displaySimpleLayout onScannerClose={onTabChange} />

          <p className="title">{getTranslation('myTab.notOrderedYet')}</p>

          <p className="message">{getTranslation('myTab.anotherLookInMenu')}</p>
        </>
      );
    }

    return (
      <>
        <div className="image">
          <Wink brandColor={theme.brandColors.primary} />
        </div>

        <p className="title">{getTranslation('myTab.noOrderSoFar')}</p>

        <p className="message">{getTranslation('myTab.itemsWillAppearHere')}</p>
      </>
    );
  }, [getTranslation, isAbrahaoStore, onTabChange, theme.brandColors.primary]);

  return (
    <S.Wrapper>
      <S.Container $isTicket={isTicket}>{whichInfoShouldRender}</S.Container>

      {!isOnlinePayment && (
        <>
          <NewWaves />

          <S.Footer>
            <GoomerExperience />
          </S.Footer>
        </>
      )}
    </S.Wrapper>
  );
};

export default Empty;
