import { useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useTranslator } from '~/hooks';
import Modal from '~/components/Modal/Modal';
import Button from '~/components/Button/Button';
import { OptionPricingTypeEnum } from '~/interfaces';
import { IApplicationState } from '~/redux-tools/store';
import { ProductContext } from '~/pages/detail/[productId]';

import * as S from './styles';

interface HowItWorksModalProps {
  isShow: boolean;
  onClose: () => void;
  pricingType: OptionPricingTypeEnum;
}

const HowItWorksModal = ({ isShow, onClose, pricingType }: HowItWorksModalProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();
  const { setSavedPricingType } = useContext(ProductContext);

  const whichInfoShouldRender = useMemo(() => {
    if (pricingType === OptionPricingTypeEnum.Bigger) {
      return {
        imageUrl: '/assets/biggestValue.svg',
        title: getTranslation('howItWorks.biggestValue'),
        description: <div dangerouslySetInnerHTML={{ __html: getTranslation('howItWorks.biggestValueDescription') }} />,
        example: (
          <div
            dangerouslySetInnerHTML={{
              __html: getTranslation('howItWorks.howItWorksExample', {
                finalValue: '150',
                firstValue: '100',
                secondValue: '150'
              })
            }}
          />
        )
      };
    }

    return {
      imageUrl: '/assets/mediumValue.svg',
      title: getTranslation('howItWorks.mediumValue'),
      description: <div dangerouslySetInnerHTML={{ __html: getTranslation('howItWorks.mediumValueDescription') }} />,
      example: (
        <div
          dangerouslySetInnerHTML={{
            __html: getTranslation('howItWorks.howItWorksExample', {
              finalValue: '150',
              firstValue: '100',
              secondValue: '200'
            })
          }}
        />
      )
    };
  }, [getTranslation, pricingType]);

  const { title, example, imageUrl, description } = whichInfoShouldRender;

  return (
    <Modal
      isSmall
      isGhostFooter
      isShow={isShow}
      hideCloseButton
      onClose={onClose}
      containerBackgroundColor={theme.isDarkMode ? theme.colors.gray200 : theme.colors.backgroundColor}
    >
      <S.ModalWrapper>
        <div>
          <div>
            <LazyLoadImage src={imageUrl} width={339} height={178} />
          </div>

          <S.Title>{title}</S.Title>

          <S.Description>{description}</S.Description>

          <S.Divider />

          <S.Example>{example}</S.Example>
        </div>

        <S.Footer>
          <Button
            isGhost
            onClick={(): void => {
              setSavedPricingType(OptionPricingTypeEnum.Individual);
              onClose();
            }}
          >
            {getTranslation('general.okayGotIt')}
          </Button>
        </S.Footer>
      </S.ModalWrapper>
    </Modal>
  );
};

export default HowItWorksModal;
