import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  margin-bottom: auto;
  padding: 1.5rem 1rem 1rem;
`;

export const StyledField = styled.div<{ isWithoutMargin?: boolean }>`
  ${({ theme, isWithoutMargin }) => css`
    margin-bottom: ${isWithoutMargin ? 0 : '1.25rem'};

    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.25rem;

      text-align: left;
      font-weight: bold;
      line-height: 150%;
      font-size: 0.87rem;
      color: ${theme.colors.textColor};
    }

    input {
      width: 100%;
    }
  `}
`;

export const GoomerWrapper = styled.div`
  width: 100%;
  padding: 1.5rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    padding: 0.5rem 1rem;

    box-shadow: 0px -1px 0px ${theme.colors.gray300};

    background-color: ${theme.colors.backgroundColor};
  `}
`;
