import styled from 'styled-components';

export const Container = styled.div<{ isHidden?: boolean }>`
  position: relative;

  margin-top: 1rem;
  padding: 0.5rem 1rem 0;

  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  display: ${({ isHidden = false }) => (isHidden ? 'none' : 'flex')};

  background-color: ${({ theme }) => theme.colors.backgroundColor};

  ::before {
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;

    --radius: 1.5rem;
    --spacing: 3.5rem;
    --mask-calculation: calc(2 * var(--radius));
    --gradient: radial-gradient(#000 70%, transparent 72%);

    width: 5rem;

    aspect-ratio: 1;
    background: ${({ theme }) => theme.colors.gray500};
    mask: calc(var(--spacing) + var(--radius)) 0 var(--gradient) no-repeat,
      0 calc(var(--spacing) + var(--radius)) var(--gradient) no-repeat,
      radial-gradient(var(--spacing) at 0 0, transparent 99%, #000 101%) var(--radius) var(--radius) no-repeat,
      conic-gradient(
        at calc(var(--spacing) + var(--mask-calculation)) calc(var(--spacing) + var(--mask-calculation)),
        #000 75%,
        transparent 0
      );
  }
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.gray600};

  > strong {
    font-weight: 700;
  }
`;
