import styled, { css } from 'styled-components';

export const SummaryInfoWrapper = styled.div`
  width: 100%;
`;

export const TotalInfoWrapper = styled.div`
  width: 100%;

  margin-bottom: 1.09rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  .total {
    > span {
      font-weight: 600;
      font-size: 0.87rem;
      line-height: 1.31rem;
    }
  }
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;

  > p {
    font-weight: 600;
    font-size: 0.87rem;
    line-height: 1.31rem;

    color: ${({ theme }) => theme.colors.gray500};
  }
`;

export const SummaryTitleWrapper = styled.div`
  margin: 0.5rem 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-weight: 800;
    font-size: 1.12rem;
    line-height: 1.68rem;
    color: ${({ theme }) => theme.colors.gray700};
  }

  > svg {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
`;

export const SummaryInfo = styled.div`
  width: 100%;
  margin: 1rem 0;

  gap: 0.7rem;
  display: flex;
  flex-direction: column;
`;

export const SummaryEachItem = styled.div<{ isCoupon: boolean }>`
  ${({ theme, isCoupon }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 500;
    font-size: 0.87rem;
    line-height: 1.05rem;
    color: ${theme.colors.gray500};

    ${isCoupon &&
    css`
      color: ${theme.colors.success};
    `}
  `}
`;

export const TotalValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TotalValueText = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.68rem;
`;
