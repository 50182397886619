import React from 'react';

const SuccessFeedback: React.FC = () => (
  <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.1"
      d="M70 140C108.66 140 140 108.66 140 70C140 31.3401 108.66 0 70 0C31.3401 0 0 31.3401 0 70C0 108.66 31.3401 140 70 140Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M69.9999 126.949C101.452 126.949 126.949 101.452 126.949 69.9999C126.949 38.5478 101.452 13.0508 69.9999 13.0508C38.5478 13.0508 13.0508 38.5478 13.0508 69.9999C13.0508 101.452 38.5478 126.949 69.9999 126.949Z"
      fill="white"
    />
    <path
      d="M70.0001 117.458C96.2102 117.458 117.458 96.2102 117.458 70.0001C117.458 43.79 96.2102 22.5425 70.0001 22.5425C43.79 22.5425 22.5425 43.79 22.5425 70.0001C22.5425 96.2102 43.79 117.458 70.0001 117.458Z"
      fill="white"
    />
    <path
      d="M100.879 50.3274C100.477 49.9445 99.9993 49.6405 99.4726 49.4331C98.9458 49.2257 98.3809 49.1189 97.8103 49.1189C97.2397 49.1189 96.6747 49.2257 96.148 49.4331C95.6213 49.6405 95.1432 49.9445 94.7414 50.3274L62.5399 80.807L49.0109 67.9778C48.5937 67.5969 48.1012 67.2973 47.5615 67.0963C47.0219 66.8953 46.4456 66.7967 45.8656 66.8062C45.2856 66.8156 44.7133 66.933 44.1813 67.1516C43.6493 67.3701 43.1681 67.6856 42.7651 68.08C42.3621 68.4743 42.0452 68.9399 41.8325 69.45C41.6198 69.9601 41.5155 70.5048 41.5256 71.0531C41.5356 71.6013 41.6598 72.1423 41.891 72.6451C42.1222 73.148 42.4559 73.6029 42.8731 73.9838L59.471 89.6731C59.8728 90.056 60.3509 90.36 60.8776 90.5674C61.4043 90.7748 61.9693 90.8816 62.5399 90.8816C63.1105 90.8816 63.6754 90.7748 64.2021 90.5674C64.7289 90.36 65.2069 90.056 65.6087 89.6731L100.879 56.3335C101.318 55.9509 101.668 55.4865 101.908 54.9697C102.147 54.4528 102.271 53.8947 102.271 53.3305C102.271 52.7662 102.147 52.2081 101.908 51.6912C101.668 51.1744 101.318 50.71 100.879 50.3274Z"
      fill="#00B4A4"
    />
  </svg>
);

export default SuccessFeedback;
