import { DiscountTypeEnum } from '~/interfaces/enums';
import { ICoupon } from '~/interfaces/general';

export type DiscountType = 'money' | 'percent';

interface GetTotalValue {
  tax?: number;
  haveTax?: boolean;
  subtotal: number;
  discount?: number;
  discountType?: DiscountType;
  taxType?: DiscountType;
  haveDiscount?: boolean;
  coupon?: ICoupon;
}

interface HandleDiscountType {
  type?: string | 'percent';
  discount: number;
}

export function handleDiscountType({ discount, type = 'percent' }: HandleDiscountType) {
  if (type === 'percent') return discount / 100;

  return discount;
}

interface handleCouponDiscountProps {
  subtotal: number;
  coupon: ICoupon;
}

export function handleCouponDiscount({ coupon, subtotal }: handleCouponDiscountProps) {
  if (coupon.type === DiscountTypeEnum.percent) {
    const discountInPercentage = subtotal * (Number(coupon.value) / 100);
    const discountMaxValue = Number(coupon.discount_max_value);

    const discount = discountInPercentage >= discountMaxValue ? discountMaxValue : discountInPercentage;

    const finalValue = subtotal - discount;

    return {
      discount,
      finalValue
    };
  }

  return {
    discount: Number(coupon.value),
    finalValue: subtotal - Number(coupon.value)
  };
}

export function getTotalValue({
  subtotal,
  discount = 0,
  tax = 0,
  haveDiscount = false,
  haveTax = false,
  discountType = 'percent',
  taxType = 'money',
  coupon
}: GetTotalValue) {
  let total = subtotal;

  if (haveDiscount && discountType === 'percent') {
    total = subtotal - subtotal * handleDiscountType({ discount, type: discountType });
  }

  if (haveDiscount && discountType === 'money') {
    total = subtotal - handleDiscountType({ discount, type: discountType });
  }

  if (haveTax && taxType === 'percent') {
    total = subtotal + subtotal * handleDiscountType({ discount: tax, type: taxType });
  }

  if (haveTax && taxType === 'money') {
    total = subtotal + tax;
  }

  if (haveDiscount && haveTax) {
    const parsedDiscount = handleDiscountType({ discount, type: discountType });
    const subtotalWithTaxes = subtotal + tax;

    total = subtotalWithTaxes - subtotalWithTaxes * parsedDiscount;
  }

  if (!coupon) return total;

  return handleCouponDiscount({ coupon, subtotal: total }).finalValue;
}
