import styled, { css } from 'styled-components';

export const Container = styled.div<{ isContentVisible: boolean }>`
  position: relative;

  padding: 0;
  width: 100%;
  height: initial;
  max-width: 100%;
  margin-top: 1rem;

  opacity: 1;
  transition: 0.5s;

  pointer-events: initial;

  ${({ isContentVisible }) =>
    !isContentVisible &&
    css`
      height: 0;
      margin: 0;

      opacity: 0;
      transition: 0.5s;

      pointer-events: none;
    `}
`;

export const NubankContent = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.colors.gray500};
`;

export const NubankItem = styled.div`
  display: flex;

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 50%;
    max-width: 2.5rem;
    max-height: 2.5rem;
    min-height: 2.5rem;
    margin-right: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => theme.colors.lightestGray};

    > svg {
      path {
        fill: ${({ theme }) => theme.colors.gray500};
      }
    }
  }

  .description {
    display: flex;
    align-items: center;

    text-align: left;
    font-weight: 500;
    line-height: 150%;
    font-size: 0.875rem;
  }
`;

export const SimpleContent = styled.div`
  ul {
    li {
      margin-bottom: 20px;

      display: flex;
      align-items: flex-start;

      .description {
        max-width: 60%;

        font-size: 12px;
        font-weight: 500;
        text-align: left;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.gray500};
      }

      .number {
        min-width: 24px;
        min-height: 24px;
        border-radius: 50%;
        margin-right: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 16px;
        font-weight: bold;
        line-height: 120%;
        text-align: center;
        color: ${({ theme }) => theme.colors.darkGray};
        background: ${({ theme }) => theme.colors.lightGray};
      }

      > svg {
        margin-left: auto;

        height: 70px;
      }
    }
  }
`;
