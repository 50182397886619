import styled from 'styled-components';

export const Header = styled.div`
  padding: 1.5rem 0 0;

  > h3 {
    margin: 0;

    font-weight: 800;
    text-align: center;
    font-size: 1.12rem;
    line-height: 1.68rem;
  }
`;

export const Menu = styled.div`
  padding: 1rem 1rem 0;

  gap: 1rem;
  display: flex;
  flex-direction: column;
`;
