import GoogleAnalytics, { gaErrors } from '..';

interface ITrackError {
  establishment_id?: number;
  error_message: string;
  error_extra_message?: string;
  browser: string;
  phone_model: string;
}
export interface IOrderError extends ITrackError {
  order_way: 'GoomerGo' | 'NaLoja';
  url?: string;
  value: number;
}
interface DeliveryFeeError extends ITrackError {
  address: string;
}

export type TypeError = DeliveryFeeError | IOrderError | ITrackError;

export function trackError(event: gaErrors, error: TypeError) {
  if (!error.error_message) {
    error.error_message = 'Erro não identificado';
  }

  GoogleAnalytics.trackEvent(event, error);
}
