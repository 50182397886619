import { RiAddLine, RiSubtractLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';

import * as S from './styles';

interface CounterProps {
  value: number;
  disableAddItem?: boolean;
  handleAddItem: () => void;
  handleSubtractItem: () => void;
  disabledSubtractItem?: boolean;
}

const Counter = ({
  value,
  handleAddItem,
  disableAddItem,
  handleSubtractItem,
  disabledSubtractItem
}: CounterProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  return (
    <S.Container>
      <S.ActionButton disabled={disabledSubtractItem} onClick={handleSubtractItem} data-test="btn-subtract-item">
        <RiSubtractLine size={24} aria-label={getTranslation('label.subtractSignal')} />
      </S.ActionButton>

      <S.CounterLabel>{value}</S.CounterLabel>

      <S.ActionButton disabled={disableAddItem} onClick={handleAddItem} data-test="btn-sum-item">
        <RiAddLine size={24} aria-label={getTranslation('label.addSignal')} />
      </S.ActionButton>
    </S.Container>
  );
};

export default Counter;
