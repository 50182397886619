/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';

interface ContainerProps {
  onClick?: () => void;
}

export const Container = styled.a<ContainerProps>`
  position: fixed;
  left: 50%;
  z-index: 10;
  bottom: 50px;
  transform: translateX(-50%);

  width: 100%;
  height: 50px;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 1rem;

  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.brandColors.actionBackground};

  @media screen and (min-width: 720px) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .cart-info {
    width: 100%;
    min-height: 50px;

    display: none;
    align-items: center;
    justify-content: space-between;

    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    &.show {
      display: flex;

      opacity: 1;
      visibility: visible;
    }

    > .items {
      width: 33%;
      height: 20px;

      display: flex;
      align-items: center;

      svg {
        path {
          fill: ${({ theme }) => theme.brandColors.actionText};
        }
      }

      .number {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-left: 0.5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: bold;
        line-height: 1rem;
        font-size: 0.75rem;
        background: ${({ theme }) => theme.brandColors.actionText};
        color: ${({ theme }) => theme.brandColors.actionBackground};
      }
    }

    .description {
      width: 33%;

      font-size: 1rem;
      font-weight: 600;
      text-align: center;
      color: ${({ theme }) => theme.brandColors.actionText};
    }

    .price {
      width: 33%;

      font-size: 18px;
      font-weight: bold;
      text-align: right;
      color: ${({ theme }) => theme.brandColors.actionText};
    }
  }

  .flash-message {
    width: 100%;
    min-height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: 0.5s;

    &.hide {
      margin-top: -50px;

      opacity: 0;
      visibility: 0;
    }

    &.removed {
      display: none;
    }

    .description {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: ${({ theme }) => theme.brandColors.actionText};
    }
  }
`;
