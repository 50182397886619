import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 1.5rem 1rem;
    margin-bottom: auto;

    .option {
      margin: 0;
      padding: 0;
      width: 100%;
      border: none;

      display: flex;
      align-items: flex-start;
      flex-direction: row-reverse;

      .info {
        max-width: 100%;
        margin: 0 0 0 1rem;

        text-align: left;
        color: ${theme.colors.gray500};
      }

      .action {
        margin-top: 4px;
      }
    }
  `};
`;

export const GoomerWrapper = styled.div`
  width: 100%;
  padding: 1.5rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;
