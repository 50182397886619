import { useMemo } from 'react';

import { FaStar } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { ProgressBar } from '~/components';
import { convertToCurrency } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import { useLoyaltyProgram, useTranslator } from '~/hooks';

import * as S from './styles';

const LoyaltyValidOrderMenuCard = (): JSX.Element => {
  const { total } = useSelector((state: IApplicationState) => state.cart);
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { loyaltyProgram: establishmentLoyaltyProgram } = useSelector(
    (state: IApplicationState) => state.establishment
  );

  const { getTranslation } = useTranslator();
  const { isValidLoyaltyOrder, remainingAmountToFulfillTheProgram } = useLoyaltyProgram();

  const loyaltyMessage: JSX.Element = useMemo(() => {
    if (isValidLoyaltyOrder) {
      return <span>{getTranslation('menu.validInLoyaltyProgram')}</span>;
    }

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: getTranslation('menu.missingValueInLoyaltyProgram', {
            value: convertToCurrency(remainingAmountToFulfillTheProgram)
          })
        }}
      />
    );
  }, [getTranslation, isValidLoyaltyOrder, remainingAmountToFulfillTheProgram]);

  const minimumOrderValue = useMemo(
    () => establishmentLoyaltyProgram?.minimumOrderValue || 0,
    [establishmentLoyaltyProgram?.minimumOrderValue]
  );

  const minimumOrderPercentage: number = useMemo(() => (total * 100) / minimumOrderValue, [minimumOrderValue, total]);

  const progressPercentage: number = useMemo(
    () => (minimumOrderPercentage > 100 ? 100 : minimumOrderPercentage),
    [minimumOrderPercentage]
  );

  return (
    <S.Container>
      <S.Content>
        <FaStar /> {loyaltyMessage}
      </S.Content>

      <S.ProgressBarWrapper>
        <ProgressBar barHeight={2} color={theme.colors.warning} progressPercentage={progressPercentage} />
      </S.ProgressBarWrapper>
    </S.Container>
  );
};

export default LoyaltyValidOrderMenuCard;
