import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '~/components';
import { useTranslator } from '~/hooks';
import TermsText from '~/components/Terms/TermText';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { ModalCloseButtonTypesEnum } from '~/interfaces/enums';

import * as S from './styles';

export interface TermsProps {
  action: string;
}

const Terms: (props: TermsProps) => JSX.Element = ({ action }) => {
  const { getTranslation } = useTranslator();

  const [shouldDisplayTerms, setShouldDisplayTerms] = useState<boolean>(false);

  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const showTermsActions = useCallback(() => {
    setShouldDisplayTerms(true);
    GoogleAnalytics.trackEvent(gaEvents.ggoSignupOpenTerm);
  }, []);

  return (
    <S.Container>
      <p>
        {getTranslation('terms.termsAgreement', {
          action: action
        })}{' '}
        <button type="button" onClick={showTermsActions}>
          {getTranslation('terms.serviceAndUsageTerms')}
        </button>{' '}
        {getTranslation('terms.goomerAndRestaurant')}
      </p>

      <Modal
        isPageLike
        isShow={shouldDisplayTerms}
        headerTitle={getTranslation('terms.termsTitle')}
        footerTitle={getTranslation('terms.termsAccept')}
        onClose={(): void => setShouldDisplayTerms(false)}
        onFooterClick={(): void => setShouldDisplayTerms(false)}
        closeButton={{ color: theme.colors.pureTextColor, type: ModalCloseButtonTypesEnum.times }}
      >
        <TermsText />
      </Modal>
    </S.Container>
  );
};

export default Terms;
