import { CartItem, CartProduct } from '~/interfaces/general';

export default function groupCartItems(productList: CartProduct[]): CartItem[] {
  const comboList: CartItem[] = [];

  productList.forEach((product) => {
    if (!product.comboId) {
      const newItem: CartItem = {
        items: [product],
        productId: product.id,
        comment: product.comment,
        quantity: product.quantity,
        subtotal: product.subtotal,
        name: product.product.name || product.customName || ''
      };

      comboList.push(newItem);
      return;
    }

    const currentCombo = comboList.find((item) => item.comboId === product.comboId);

    if (currentCombo) {
      const comboIndex = comboList.findIndex((item) => item.comboId === product.comboId);
      comboList[comboIndex] = {
        ...currentCombo,
        items: [...currentCombo.items, product],
        subtotal: currentCombo.subtotal + product.subtotal
      };

      return;
    }

    const newCombo = {
      items: [product],
      comboId: product.comboId,
      comment: product.comment,
      quantity: product.quantity,
      subtotal: product.subtotal,
      name: product.product.name || ''
    };

    comboList.push(newCombo);
    return;
  });

  return comboList;
}
