import React, { useCallback } from 'react';

import { useTranslator } from '~/hooks';

import { getHoursAndMinutesFromLabel } from '../../utils';

import * as S from './styles';

export interface RadioHourProps {
  id: string;
  name: string;
  label: string;
  value: string;
  checked?: boolean;
  setChecked?: (value: { hour: number; minutes: number }) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const RadioHour = ({ id, name, label, checked, setChecked, ...rest }: RadioHourProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const hourAndMinutes = getHoursAndMinutesFromLabel(event.target.value);

      if (!!hourAndMinutes && setChecked) {
        setChecked(hourAndMinutes);
      }
    },
    [setChecked]
  );

  return (
    <S.RadioHourContainer htmlFor={id} $isChecked={!!checked}>
      <S.Label>{label}</S.Label>

      <S.CustomRadio>
        <input id={id} type="radio" defaultChecked={checked} name={name} {...rest} onChange={onChange} />

        <span className="mark" aria-label={getTranslation('label.radioHour', { label })} />
      </S.CustomRadio>
    </S.RadioHourContainer>
  );
};

export interface RadioGroupProps {
  data: RadioHourProps[];
  setChecked: (value: { hour: number; minutes: number }) => void;
}

const RadioGroup: ({ data, setChecked }: RadioGroupProps) => JSX.Element = ({ data, setChecked }) => {
  return (
    <S.RadioGroupContainer data-test="scheduling-hour-list" role="radiogroup">
      {data.map(({ id, ...rest }, index) => (
        <RadioHour key={id} id={id} {...rest} checked={index === 0} setChecked={setChecked} />
      ))}
    </S.RadioGroupContainer>
  );
};

export default RadioGroup;
