const PixIcon = ({
  width = '18',
  height = '18',
  color = '#485460'
}: {
  width: string;
  height: string;
  color?: string;
}): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.59083 13.5441L6.59225 13.5427L9.11661 11.0047L11.6415 13.5296C11.9693 13.8574 12.3569 14.1105 12.7786 14.2786L10.3931 16.6641L10.3931 16.6641L10.3895 16.6677C9.62547 17.4422 8.37575 17.4474 7.59244 16.6641L5.28317 14.3548C5.77196 14.1904 6.21945 13.9155 6.59083 13.5441ZM8.94176 10.8299C8.94166 10.8298 8.94156 10.8297 8.94145 10.8296L8.94176 10.8299Z"
        strokeWidth="1.5"
      />
      <path
        d="M6.59083 4.47048L6.59088 4.47042L6.58308 4.46285C6.20876 4.09922 5.7637 3.82684 5.28051 3.66242L7.60695 1.33598L7.60697 1.33599L7.61055 1.33236C8.37455 0.55789 9.62428 0.552666 10.4076 1.33598L12.8041 3.73246C12.3774 3.9003 11.9865 4.15442 11.656 4.48499L9.13066 7.01031L6.59083 4.47048Z"
        strokeWidth="1.5"
      />
      <path
        d="M16.664 7.60687L16.664 7.60688L16.6676 7.61046C17.4441 8.37645 17.4441 9.62341 16.6676 10.3894L16.6676 10.3894L16.664 10.393L14.8369 12.2201H14.0298C13.7425 12.2201 13.4564 12.1054 13.2537 11.9027L10.5572 9.20615C10.4846 9.1318 10.4071 9.0645 10.3257 9.00422C10.411 8.94235 10.4921 8.87329 10.5682 8.79719L10.5697 8.79576L13.2481 6.10288C13.467 5.89571 13.7469 5.77976 14.0298 5.77976H14.8369L16.664 7.60687ZM7.90867 8.99515C7.82249 9.05691 7.74059 9.12609 7.66402 9.20266L7.6626 9.20408L4.96968 11.9115C4.75076 12.1187 4.47087 12.2346 4.1879 12.2346H3.16308L1.33598 10.4075L1.33599 10.4075L1.33236 10.4039C0.55789 9.6399 0.552666 8.39018 1.33598 7.60687L3.16308 5.77976H4.1879C4.47522 5.77976 4.7613 5.89447 4.96402 6.09719L4.96545 6.09861L7.67854 8.79719C7.6788 8.79746 7.67907 8.79772 7.67933 8.79798C7.75151 8.87008 7.82823 8.93584 7.90867 8.99515Z"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PixIcon;
