import React, { useMemo, useEffect, useCallback, useState } from 'react';

import FadeIn from 'react-fade-in';
import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { LocalStorageThirkyEnum } from '~/interfaces/enums';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { ThirkyMedia, ThirkyMediaType, ThirkyStatusEnum } from '~/interfaces/general';

import Button from '../Button/Button';

import * as S from './styles';

export interface ThirkyAdsProps {
  elementId: string;
  mediaType: ThirkyMediaType;
  mediaHeight?: (mediaHeight: number) => void;
  hasMediaToDisplay?: (hasMediaToDisplay: boolean) => void;
}

const ThirkyAds: React.FC<ThirkyAdsProps> = ({ elementId, mediaType, mediaHeight, hasMediaToDisplay }) => {
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const { data, hasError, isLoading } = useSelector((state: IApplicationState) => state.thirky);

  const { getTranslation } = useTranslator();

  const [isPreRollVisible, setIsPreRollVisible] = useState<boolean>(
    localStorage.getItem(LocalStorageThirkyEnum.preRollVisibility) === 'true'
  );

  const mediaToDisplay: ThirkyMedia | undefined = useMemo(
    () => data?.midias?.find((media) => media.type === mediaType),
    [data?.midias, mediaType]
  );

  const hasAdsToDisplay: boolean = useMemo(
    () => data?.status === ThirkyStatusEnum.WithAds && !!mediaToDisplay,
    [data?.status, mediaToDisplay]
  );

  const handleOnClick: (url?: string | null) => void = (url) => {
    if (!url) return;

    if (mediaType === 'highlight') {
      GoogleAnalytics.trackEvent(gaEvents.highlightThirkyOnClick, {
        storeId: settings?.id,
        phpId: settings?.store_code
      });
    }

    if (mediaType === 'preRoll') {
      GoogleAnalytics.trackEvent(gaEvents.preRollThirkyOnClick, {
        storeId: settings?.id,
        phpId: settings?.store_code
      });
    }

    return url && window.open(url, '_blank');
  };

  const setPreRollVisibility: (isVisible: boolean) => void = useCallback((isVisible) => {
    setIsPreRollVisible(isVisible);

    localStorage.setItem(LocalStorageThirkyEnum.preRollVisibility, String(isVisible));
  }, []);

  useEffect(() => hasMediaToDisplay?.(!!mediaToDisplay), [hasMediaToDisplay, mediaToDisplay]);

  useEffect(() => {
    const element: HTMLElement | null = typeof document !== 'undefined' ? document.getElementById(elementId) : null;

    mediaHeight?.(element?.offsetHeight || 0);
  }, [elementId, mediaHeight, mediaToDisplay, data]);

  useEffect(() => {
    const onLoadThirkyGaEvents = {
      preRoll: gaEvents.preRollThirkyOnLoad,
      highlight: gaEvents.highlightThirkyOnLoad
    };

    if (hasAdsToDisplay && (mediaType === 'highlight' || mediaType === 'preRoll')) {
      GoogleAnalytics.trackEvent(onLoadThirkyGaEvents[mediaType], {
        thirkyData: data,
        storeId: settings?.id,
        phpId: settings?.store_code
      });
    }
  }, [data, hasAdsToDisplay, mediaType, settings?.id, settings?.store_code]);

  const shouldHideThirkyAds: boolean = useMemo(
    () => !settings?.mm_store_enable_thirky_ads || isLoading || hasError || !hasAdsToDisplay || !mediaToDisplay,
    [hasAdsToDisplay, hasError, isLoading, mediaToDisplay, settings?.mm_store_enable_thirky_ads]
  );

  useEffect(() => {
    if (!shouldHideThirkyAds && mediaType === 'preRoll') {
      const bodyElement: HTMLCollectionOf<HTMLBodyElement> | undefined =
        typeof document !== 'undefined' ? document.getElementsByTagName('body') : undefined;

      if (bodyElement) {
        bodyElement[0]?.setAttribute('style', isPreRollVisible ? 'overflow: hidden' : '');
      }
    }
  }, [isPreRollVisible, mediaType, shouldHideThirkyAds]);

  if (shouldHideThirkyAds) return <></>;

  if (mediaType === 'highlight' && !!mediaToDisplay?.cover)
    return (
      <FadeIn>
        <S.HighlightContainer
          id={elementId}
          $hasCta={!!mediaToDisplay?.url_cta}
          onClick={(): void => handleOnClick(mediaToDisplay?.url_cta)}
        >
          <img alt="Imagem de propaganda" src={mediaToDisplay?.cover} />
        </S.HighlightContainer>
      </FadeIn>
    );

  if (mediaType === 'preRoll' && !!mediaToDisplay?.cover)
    return (
      <S.PreRollContainer id={elementId} $isVisible={isPreRollVisible} $hasCta={!!mediaToDisplay?.url_cta}>
        <img
          alt="Imagem de propaganda"
          src={mediaToDisplay?.cover}
          onClick={(): void => handleOnClick(mediaToDisplay?.url_cta)}
        />

        <Button onClick={(): void => setPreRollVisibility(false)}>{getTranslation('general.close')}</Button>
      </S.PreRollContainer>
    );

  return <></>;
};

export default ThirkyAds;
