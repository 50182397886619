import { useEffect, useState } from 'react';

export default function useDetectAdBlock(): boolean {
  const [isAdBlockDetected, setIsAdBlockDetected] = useState(false);

  useEffect(() => {
    const url = 'https://www3.doubleclick.net';

    fetch(url, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store'
    }).catch(() => {
      setIsAdBlockDetected(true);
    });
  }, []);

  return isAdBlockDetected;
}
