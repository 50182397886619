import styled from 'styled-components';

export const ModalWrapper = styled.div`
  margin: 0;
  padding: 1.5rem 1rem 0.75rem;
`;

export const Title = styled.p`
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 130%;
`;

export const TabWrapper = styled.div`
  margin: 1.5rem 0;
`;
