/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 2rem;
  padding: 0.5rem 1rem 0;

  ${({ theme }) => css`
    > .content {
      border-radius: 5px;
      padding: 0.75rem 1rem;
      box-sizing: border-box;
      border: 1px solid ${theme.colors.lightGray};

      display: flex;
      align-items: center;

      background: ${theme.colors.backgroundColor};

      > .info {
        margin-right: auto;

        > p {
          padding: 0;
          margin: 0;

          display: flex;
          align-items: center;

          font-style: normal;
          line-height: 150%;

          &.name {
            font-weight: 500;
            font-size: 0.875rem;

            color: ${theme.colors.mediumGray};
          }

          &.phone {
            font-size: 1rem;
            font-weight: 600;

            color: ${theme.colors.darkGray};
          }
        }
      }

      > .switch-account {
        width: auto;
        margin-left: auto;

        span {
          font-weight: 500;
          line-height: 150%;
          font-size: 0.75rem;
        }
      }
    }
  `}
`;

export const EditWrapper = styled.div`
  ${({ theme }) => css`
    svg {
      path {
        fill: ${theme.colors.mediumGray};
      }
    }
  `}
`;
