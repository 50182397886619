import styled, { css, DefaultTheme } from 'styled-components';

const containerModifiers = {
  isScrolled: () => css`
    position: sticky;
    top: 0;
    left: 0;
    z-index: 19;
  `
};

export const Container = styled.nav<{ isScrolled: boolean }>`
  ${({ theme, isScrolled }) => css`
    position: relative;

    width: 100%;
    height: 52px;
    margin: 0;
    margin-top: 8px;
    border: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: initial;

    white-space: nowrap;
    background-color: ${theme.colors.gray200};

    > div {
      padding-right: 22px;
      margin-left: 8px;

      display: flex;

      overflow-x: auto;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    ${isScrolled && containerModifiers.isScrolled()}
  `}
`;

const categoryModifiers = {
  isActive: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.brandColors.actionBackground};

    color: ${theme.brandColors.actionText};
    background-color: ${theme.brandColors.actionBackground};
  `
};

export const Category = styled.div<{ $active: boolean }>`
  ${({ theme, $active }) => css`
    margin: 10px 4px;
    padding: 4px 12px;
    border-radius: 6px;
    border: 1px solid ${theme.brandColors.primary};

    font-weight: 500;
    background-color: ${theme.colors.gray200};
    color: ${theme.brandColors.primary};

    &:first-child {
      margin-left: 5px;
    }

    > div {
      min-width: max-content;

      display: inline-block;

      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    ${$active && categoryModifiers.isActive(theme)}
  `}
`;

export const Search = styled(Category)`
  ${({ theme }) => css`
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    border: none;
    margin-right: 0;
    margin-left: 10px !important;
    padding: 7px !important;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.backgroundColor};

    svg {
      min-width: 16px !important;
    }
  `}
`;
