import { useMemo } from 'react';

import Link from 'next/link';
import { RiArrowRightSLine } from 'react-icons/ri';

import * as S from './styles';

interface CardNavigatorProps {
  url?: string;
  title: string;
  onClick?: () => void;
  description?: string;
  icon?: React.ReactNode;
}

const CardNavigator = ({ url, icon, title, onClick, description }: CardNavigatorProps): JSX.Element => {
  const renderContent = useMemo(
    () => (
      <S.Container onClick={onClick}>
        {icon && <S.IconWrapper aria-label="Ícone do card">{icon}</S.IconWrapper>}

        <S.Content>
          <S.Title>{title}</S.Title>

          {description && <S.Description>{description}</S.Description>}
        </S.Content>

        <S.IconWrapper iconSize="large">
          <RiArrowRightSLine />
        </S.IconWrapper>
      </S.Container>
    ),
    [description, icon, onClick, title]
  );

  if (url) {
    return <Link href={url}>{renderContent}</Link>;
  }

  return renderContent;
};

export default CardNavigator;
