import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import Option from '~/components/Option/Option';
import { IApplicationState } from '~/redux-tools/store';
import OptionHeader from '~/components/Option/OptionHeader';

import * as S from './styles';

interface OrderFullAgeProps {
  isFullAgeTermAccept: boolean;
  setIsFullAgeTermAccept: (status: boolean) => void;
}

const OrderFullAge: (props: OrderFullAgeProps) => JSX.Element = ({ isFullAgeTermAccept, setIsFullAgeTermAccept }) => {
  const cart = useSelector((state: IApplicationState) => state.cart);

  const { getTranslation } = useTranslator();

  const fullAgeProduct = useMemo(
    () => cart.products.find((product) => product.product.limit_age === true),
    [cart.products]
  );

  const handleCheckFullAge = useCallback(
    ({ check }: { check: boolean }) => {
      setIsFullAgeTermAccept(check);
    },
    [setIsFullAgeTermAccept]
  );

  if (!fullAgeProduct) {
    return <></>;
  }

  return (
    <S.Container>
      <OptionHeader
        noPadding
        isRequired
        subtitle={false}
        title={getTranslation('fullAge.over18')}
        isDone={fullAgeProduct && isFullAgeTermAccept}
      />

      <S.OptionWrapper>
        <Option
          id={0}
          price=""
          isAvailable
          type="check"
          groupName="fullAge"
          optionValue="fullAge"
          title={getTranslation('fullAge.declare')}
          radioCheck={[{ id: 'fullAge', value: 'fullAge' }]}
          info={getTranslation('fullAge.prohibitedForMinors')}
          onChange={(event): void => handleCheckFullAge(event as { check: boolean })}
        />
      </S.OptionWrapper>
    </S.Container>
  );
};

export default OrderFullAge;
