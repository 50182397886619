import styled, { css } from 'styled-components';

export const Container = styled.div<{ isSimpleCard?: boolean }>`
  ${({ theme, isSimpleCard }) => css`
    padding: 0.625rem;
    border-radius: 0.313rem;

    ${!isSimpleCard &&
    css`
      margin-top: 0.625rem;
    `}

    display: flex;
    align-items: center;

    text-align: left;
    line-height: 140%;
    font-size: 0.75rem;
    background-color: ${theme.isDarkMode ? theme.colors.gray400 : theme.colors.lightestGray};

    cursor: pointer;

    strong {
      font-weight: bold;
      font-size: 0.75rem;
    }

    span {
      font-weight: 500;
      line-height: 140%;
      font-size: 0.75rem;
    }
  `}
`;

export const SvgWrapper = styled.div`
  ${({ theme }) => css`
    height: 1.5rem;
    min-width: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 0.25rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.success};
  `};
`;
