import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  opacity: 0;
  z-index: 105;
  background: #000;
  pointer-events: none;
`;
