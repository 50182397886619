export default function cardNumberMask(value: string): string {
  if (value.length === 15) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{6})(\d)/, '$1 $2')
      .replace(/(\d{5})\d*$/, '$1');
  }

  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})\d*$/, '$1');
}
