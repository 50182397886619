import React from 'react';

const AngleDown = () => {
  return (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6633 0.325865C11.45 0.11715 11.1615 0 10.8607 0C10.56 0 10.2715 0.11715 10.0582 0.325865L5.97154 4.29283L1.94179 0.325865C1.72851 0.11715 1.43999 0 1.13926 0C0.838521 0 0.550005 0.11715 0.336721 0.325865C0.230026 0.430041 0.145339 0.553981 0.0875465 0.690538C0.0297541 0.827095 0 0.973565 0 1.1215C0 1.26943 0.0297541 1.4159 0.0875465 1.55246C0.145339 1.68902 0.230026 1.81296 0.336721 1.91713L5.16331 6.66853C5.26914 6.77356 5.39504 6.85693 5.53376 6.91382C5.67248 6.97071 5.82127 7 5.97154 7C6.12182 7 6.2706 6.97071 6.40932 6.91382C6.54804 6.85693 6.67394 6.77356 6.77977 6.66853L11.6633 1.91713C11.77 1.81296 11.8547 1.68902 11.9125 1.55246C11.9702 1.4159 12 1.26943 12 1.1215C12 0.973565 11.9702 0.827095 11.9125 0.690538C11.8547 0.553981 11.77 0.430041 11.6633 0.325865Z"
        fill="#30333C"
      />
    </svg>
  );
};

export default AngleDown;
