const CHROME_SUPPORTED_VERSION = 60;

function getChromeVersion(): number {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : CHROME_SUPPORTED_VERSION;
}

export default function isThisChromeVersionSupported(): boolean {
  return getChromeVersion() >= CHROME_SUPPORTED_VERSION;
}
