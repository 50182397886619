import { all, put, select, takeLatest } from 'redux-saga/effects';

import { api } from '~/services/api';
import { CouponsActionTypes } from '~/redux-tools/store/coupons/types';
import { IApplicationState, IReducerAction } from '~/redux-tools/store';
import { CouponErrorType, DeliveryWay, ICoupon } from '~/interfaces/general';
import { CouponErrorMessageEnum, SegmentTypeEnum } from '~/interfaces/enums';
import {
  cleanCoupon,
  fetchCouponError,
  couponErrorUpdate,
  fetchCouponSuccess,
  validateCouponError,
  FetchCouponRequestProps,
  fetchExistentCouponsError,
  ValidateCouponRequestProps,
  fetchExistentCouponsSuccess
} from '~/redux-tools/store/coupons/actions';
import { GetCouponErrorMessageProps } from '~/hooks/useGetCouponErrorMessage';

interface ValidateCouponSagaProps {
  origin?: DeliveryWay;
  orderValue: number;
  storeId: number;
  coupon: ICoupon;
  afterValidation?: () => void;
  afterSuccess?: () => void;
  displayErrorMessage: ({ type, info }: GetCouponErrorMessageProps) => string;
}

function* validateCouponSaga({
  coupon,
  orderValue,
  origin,
  storeId,
  afterSuccess,
  afterValidation,
  displayErrorMessage
}: ValidateCouponSagaProps) {
  const iana: string = yield select((state: IApplicationState) => state.establishment.settings?.mm_timezone.iana) ||
    'America/Sao_Paulo';

  const isFirstOrderDiscount = coupon.segment === SegmentTypeEnum.first_order;

  const body: {
    storeId: number;
    code?: string;
    segment?: SegmentTypeEnum;
    order: {
      total_value: number;
      delivery_way?: DeliveryWay;
    };
  } = {
    storeId,
    code: isFirstOrderDiscount ? undefined : coupon.code,
    segment: isFirstOrderDiscount ? coupon.segment : undefined,
    order: {
      total_value: orderValue,
      delivery_way: origin
    }
  };

  try {
    const { data } = yield api.post(`coupons/validate?tz=${iana || 'America/Sao_Paulo'}`, body);

    if (data.reason) {
      const error = data.reason as CouponErrorType;

      const validationResult = displayErrorMessage({
        type: CouponErrorMessageEnum[error],
        info: {
          code: coupon.segment === SegmentTypeEnum.first_order ? 'Desconto Primeiro Pedido' : coupon.code,
          orderMinValue: coupon.order_min_value,
          currentOrigin: origin,
          time: [coupon.hours_from, coupon.hours_to],
          origins: coupon.order_origin
        }
      });

      yield put(couponErrorUpdate(validationResult));
      if (!validationResult.includes('expirado')) {
        yield put(fetchCouponSuccess(coupon));
      }

      return !!afterValidation && afterValidation();
    }

    yield put(couponErrorUpdate(undefined));
    yield put(fetchCouponSuccess(coupon));
    return !!afterSuccess && afterSuccess();
  } catch (error) {
    return console.error((error as Error)?.message);
  }
}

export function* fetchCoupon({ payload }: IReducerAction<FetchCouponRequestProps>) {
  const { couponCode, origin, afterAction, displayErrorMessage } = payload;

  const storeId: number = yield select((state: IApplicationState) => state.establishment.settings?.id);
  const orderValue: number = yield select((state: IApplicationState) => state.cart.total);

  try {
    const { data: coupon } = yield api.get(`coupons?code=${payload.couponCode.toUpperCase()}&storeId=${storeId}`);

    yield validateCouponSaga({
      coupon,
      origin,
      storeId,
      orderValue,
      displayErrorMessage,
      afterSuccess: afterAction?.onClose
    });
  } catch (err) {
    yield put(cleanCoupon());

    const mockErr = 'not_found' as CouponErrorType;

    const errorMessage = displayErrorMessage({
      type: CouponErrorMessageEnum[mockErr],
      info: {
        code: couponCode
      }
    });

    yield put(fetchCouponError(errorMessage));
  } finally {
    if (afterAction?.setShowError) {
      afterAction.setShowError();
    }
  }
}

export function* validateCoupon({ payload }: IReducerAction<ValidateCouponRequestProps>) {
  const { coupon, origin, afterAction, displayErrorMessage } = payload;

  const storeId: number = yield select((state: IApplicationState) => state.establishment.settings?.id);
  const orderValue: number = yield select((state: IApplicationState) => state.cart.total);

  try {
    yield validateCouponSaga({
      coupon,
      origin,
      storeId,
      orderValue,
      displayErrorMessage,
      afterValidation: afterAction?.afterValidation
    });
  } catch (error) {
    yield put(cleanCoupon());

    const mockErr = 'not_found' as CouponErrorType;

    const errorMessage = displayErrorMessage({
      type: CouponErrorMessageEnum[mockErr],
      info: {
        code: coupon.segment === SegmentTypeEnum.first_order ? 'Desconto Primeiro Pedido' : coupon.code
      }
    });

    yield put(validateCouponError(errorMessage));
  }
}

export function* fetchExistentCoupons() {
  const storeId: number = yield select((state: IApplicationState) => state.establishment.settings?.id);

  try {
    const { data } = yield api.get(`coupons/availability?storeId=${storeId}`);

    yield put(fetchExistentCouponsSuccess(data.has_available));
  } catch (error) {
    yield put(fetchExistentCouponsError((error as Error)?.message));
  }
}

export default all([
  takeLatest(CouponsActionTypes.FETCH_COUPON_REQUEST, fetchCoupon),
  takeLatest(CouponsActionTypes.FETCH_EXISTENT_COUPONS_REQUEST, fetchExistentCoupons),
  takeLatest(CouponsActionTypes.VALIDATE_COUPON_REQUEST, validateCoupon)
]);
