import React from 'react';

const MobilePhoneIcon = () => {
  return (
    <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.6915 21H1.498C0.67375 21 0 20.3263 0 19.502V1.498C0 0.67375 0.67375 0 1.498 0H9.68975C10.514 0 11.1878 0.67375 11.1878 1.498V19.5002C11.1895 20.3263 10.5158 21 9.6915 21Z"
        fill="url(#paint0_linear)"
      />
      <path
        opacity="0.2"
        d="M9.6915 0.525C10.2288 0.525 10.6645 0.9625 10.6645 1.498V19.5002C10.6645 20.0375 10.227 20.4732 9.6915 20.4732H1.498C0.96075 20.4732 0.525 20.0357 0.525 19.5002V1.498C0.525 0.96075 0.9625 0.525 1.498 0.525H9.6915ZM9.6915 0H1.498C0.67375 0 0 0.67375 0 1.498V19.5002C0 20.3245 0.67375 20.9982 1.498 20.9982H9.68975C10.514 20.9982 11.1878 20.3245 11.1878 19.5002V1.498C11.1895 0.67375 10.5158 0 9.6915 0Z"
        fill="#EEEEEE"
      />
      <path
        d="M9.54977 18.2053H1.64327C1.51202 18.2053 1.40527 18.0985 1.40527 17.9673V3.03451C1.40527 2.90326 1.51202 2.79651 1.64327 2.79651H9.54977C9.68102 2.79651 9.78777 2.90326 9.78777 3.03451V17.9673C9.78777 18.0968 9.68102 18.2053 9.54977 18.2053Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M7.26787 1.86377H3.92188C3.86386 1.86377 3.80822 1.84072 3.7672 1.7997C3.72617 1.75868 3.70313 1.70304 3.70312 1.64502C3.70313 1.587 3.72617 1.53136 3.7672 1.49034C3.80822 1.44932 3.86386 1.42627 3.92188 1.42627H7.26787C7.32589 1.42627 7.38153 1.44932 7.42256 1.49034C7.46358 1.53136 7.48662 1.587 7.48662 1.64502C7.48662 1.70304 7.46358 1.75868 7.42256 1.7997C7.38153 1.84072 7.32589 1.86377 7.26787 1.86377Z"
        fill="#212121"
      />
      <path
        d="M7.26787 19.5737H3.92188C3.86386 19.5737 3.80822 19.5507 3.7672 19.5097C3.72617 19.4686 3.70313 19.413 3.70312 19.355C3.70313 19.297 3.72617 19.2413 3.7672 19.2003C3.80822 19.1593 3.86386 19.1362 3.92188 19.1362H7.26787C7.38862 19.1362 7.48662 19.2342 7.48662 19.355C7.48662 19.474 7.38862 19.5737 7.26787 19.5737Z"
        fill="black"
      />
      <path
        d="M8.98814 4.89654H2.22789C2.16139 4.89654 2.10889 4.84404 2.10889 4.77754V3.62779C2.10889 3.56129 2.16139 3.50879 2.22789 3.50879H8.98639C9.05289 3.50879 9.10539 3.56129 9.10539 3.62779V4.77754C9.10714 4.84229 9.05289 4.89654 8.98814 4.89654Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.10623 14H2.47973C2.37762 14 2.27969 13.9595 2.20749 13.8873C2.13529 13.8151 2.09473 13.7171 2.09473 13.615V12.9885C2.09473 12.7768 2.26623 12.6035 2.47973 12.6035H3.10623C3.31798 12.6035 3.49123 12.775 3.49123 12.9885V13.615C3.49123 13.8285 3.31973 14 3.10623 14Z"
        fill="#159E5C"
      />
      <path
        d="M3.10623 16.1H2.47973C2.37762 16.1 2.27969 16.0594 2.20749 15.9872C2.13529 15.915 2.09473 15.8171 2.09473 15.715V15.0885C2.09473 14.8767 2.26623 14.7035 2.47973 14.7035H3.10623C3.31798 14.7035 3.49123 14.875 3.49123 15.0885V15.715C3.49123 15.9285 3.31973 16.1 3.10623 16.1Z"
        fill="#FB5352"
      />
      <path
        d="M4.97 16.1H4.3435C4.24139 16.1 4.14346 16.0594 4.07126 15.9872C3.99906 15.915 3.9585 15.8171 3.9585 15.715V15.0885C3.9585 14.8767 4.13 14.7035 4.3435 14.7035H4.97C5.18175 14.7035 5.355 14.875 5.355 15.0885V15.715C5.355 15.8171 5.31443 15.915 5.24223 15.9872C5.17003 16.0594 5.0721 16.1 4.97 16.1Z"
        fill="#4587F5"
      />
      <path
        d="M6.88825 16.1H6.26C6.20944 16.1 6.15938 16.09 6.11267 16.0707C6.06596 16.0513 6.02351 16.023 5.98776 15.9872C5.95201 15.9515 5.92365 15.909 5.90431 15.8623C5.88496 15.8156 5.875 15.7656 5.875 15.715V15.0885C5.875 14.8767 6.0465 14.7035 6.26 14.7035H6.8865C7.09825 14.7035 7.2715 14.875 7.2715 15.0885V15.715C7.27325 15.9285 7.1 16.1 6.88825 16.1Z"
        fill="#159E5C"
      />
      <path
        d="M8.69558 16.1H8.06908C7.96697 16.1 7.86905 16.0594 7.79685 15.9872C7.72464 15.915 7.68408 15.8171 7.68408 15.715V15.0885C7.68408 14.8767 7.85558 14.7035 8.06908 14.7035H8.69558C8.90733 14.7035 9.08058 14.875 9.08058 15.0885V15.715C9.08058 15.8171 9.04002 15.915 8.96782 15.9872C8.89562 16.0594 8.79769 16.1 8.69558 16.1Z"
        fill="#FECD41"
      />
      <path
        d="M4.97 14H4.3435C4.24139 14 4.14346 13.9595 4.07126 13.8873C3.99906 13.8151 3.9585 13.7171 3.9585 13.615V12.9885C3.9585 12.7768 4.13 12.6035 4.3435 12.6035H4.97C5.18175 12.6035 5.355 12.775 5.355 12.9885V13.615C5.355 13.7171 5.31443 13.8151 5.24223 13.8873C5.17003 13.9595 5.0721 14 4.97 14Z"
        fill="#FECD41"
      />
      <path
        d="M6.88825 14H6.26C6.20944 14 6.15938 13.9901 6.11267 13.9707C6.06596 13.9514 6.02351 13.923 5.98776 13.8873C5.95201 13.8515 5.92365 13.8091 5.90431 13.7623C5.88496 13.7156 5.875 13.6656 5.875 13.615V12.9885C5.875 12.7768 6.0465 12.6035 6.26 12.6035H6.8865C7.09825 12.6035 7.2715 12.775 7.2715 12.9885V13.615C7.27325 13.8285 7.1 14 6.88825 14Z"
        fill="#FF6D00"
      />
      <path
        d="M8.69558 14H8.06908C7.96697 14 7.86905 13.9595 7.79685 13.8873C7.72464 13.8151 7.68408 13.7171 7.68408 13.615V12.9885C7.68408 12.7768 7.85558 12.6035 8.06908 12.6035H8.69558C8.90733 12.6035 9.08058 12.775 9.08058 12.9885V13.615C9.08058 13.7171 9.04002 13.8151 8.96782 13.8873C8.89562 13.9595 8.79769 14 8.69558 14Z"
        fill="#DE493C"
      />
      <path
        d="M5.59463 17.2375C5.73988 17.2375 5.85713 17.3548 5.85713 17.5C5.85713 17.6453 5.73988 17.7625 5.59463 17.7625C5.44938 17.7625 5.33213 17.6453 5.33213 17.5C5.33213 17.3548 5.44938 17.2375 5.59463 17.2375ZM5.59463 17.15C5.40213 17.15 5.24463 17.3075 5.24463 17.5C5.24463 17.6925 5.40213 17.85 5.59463 17.85C5.78713 17.85 5.94463 17.6925 5.94463 17.5C5.94463 17.3075 5.78713 17.15 5.59463 17.15Z"
        fill="white"
      />
      <path
        d="M8.24612 17.2375C8.27237 17.2375 8.29512 17.2603 8.29512 17.2865V17.7135C8.29512 17.7398 8.27237 17.7625 8.24612 17.7625H7.81912C7.79287 17.7625 7.77012 17.7398 7.77012 17.7135V17.2865C7.77012 17.2603 7.79287 17.2375 7.81912 17.2375H8.24612ZM8.24612 17.15H7.81912C7.74387 17.15 7.68262 17.2113 7.68262 17.2865V17.7135C7.68262 17.7888 7.74387 17.85 7.81912 17.85H8.24612C8.32137 17.85 8.38262 17.7888 8.38262 17.7135V17.2865C8.38262 17.2113 8.32137 17.15 8.24612 17.15Z"
        fill="white"
      />
      <path
        d="M3.40367 17.276V17.724L2.95742 17.5L3.40367 17.276ZM3.46492 17.15C3.46142 17.15 3.45792 17.15 3.45442 17.1535L2.80692 17.479C2.78767 17.4878 2.78767 17.514 2.80692 17.5245L3.45442 17.85C3.45792 17.8518 3.46142 17.8535 3.46492 17.8535C3.47892 17.8535 3.48942 17.843 3.48942 17.829V17.1745C3.49117 17.1605 3.47892 17.15 3.46492 17.15Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="5.59475" y1="21" x2="5.59475" y2="0" gradientUnits="userSpaceOnUse">
          <stop offset="0.003" stopColor="#212121" />
          <stop offset="1" stopColor="#4D4D4D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="5.596"
          y1="18.2053"
          x2="5.596"
          y2="2.79546"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.009" stopColor="#9E9E9E" />
          <stop offset="0.999" stopColor="#616161" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MobilePhoneIcon;
