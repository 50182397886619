import React, { useEffect, useState } from 'react';

interface NewWavesProps {
  marginBottom?: string;
  backgroundColor?: string;
}

import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

import * as S from './styles';

const NewWaves = ({ marginBottom, backgroundColor }: NewWavesProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const [backgroundUrl, setBackgroundUrl] = useState('');

  useEffect(() => {
    if (window) {
      const url = `${window.location.origin}/assets/new-waves${theme.isDarkMode ? '-dark' : ''}.svg`;

      setBackgroundUrl(url);
    }
  }, [theme.isDarkMode]);

  return (
    <S.Container $marginBottom={marginBottom} $backgroundColor={backgroundColor}>
      <S.Waves backgroundUrl={backgroundUrl} />
    </S.Container>
  );
};

export default NewWaves;
