import React, { useRef, useMemo, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

import { Container, Input } from './styles';

interface InputCurrencyProps {
  value: string;
  dataTest?: string;
  required: boolean;
  hasError?: boolean;
  onBlur?: () => void;
  onChange: (params: { value: number; maskedValue: string }) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const InputCurrency: React.FC<InputCurrencyProps> = ({ onBlur, hasError, dataTest, onChange }) => {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const code = useMemo(() => {
    return settings?.mm_currency?.code || 'BRL';
  }, [settings]);

  const locale = useMemo(() => {
    if (code === 'BRL') return 'pt-BR';
    if (code === 'JPY') return 'ja-JP';
    if (code === 'CAD') return 'en-CA';
    if (code === 'AUD') return 'en-AU';
    if (code === 'GPB') return 'fr-CH';
    if (code === 'ARS') return 'es-AR';

    return 'en-US';
  }, [code]);

  const [currencyConfig] = useState({
    locale,
    formats: {
      number: {
        BRL: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        },
        USD: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        },
        AUD: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        },
        CAD: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        },
        ARS: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        },
        GBP: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        },
        EUR: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        },
        CNY: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        },
        CHF: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        },
        JPY: {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }
      }
    }
  });

  const symbol = useMemo(() => {
    return settings?.mm_currency?.symbol || 'R$';
  }, [settings]);

  const handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: number, maskedValue: string): void => {
    onChange({ value, maskedValue });
  };

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      const maskedValue = `${symbol} 0,00`;
      const value = 0;
      onChange({ value, maskedValue });
      firstUpdate.current = false;
    }
  }, [onChange, symbol]);

  return (
    <Container hasError={hasError}>
      <div className="symbol">
        <span>{settings?.mm_currency?.symbol}</span>
      </div>

      <Input
        maxLength={10}
        currency={code}
        onBlur={onBlur}
        inputMode="numeric"
        data-test={dataTest}
        config={currencyConfig}
        onChange={handleChange}
      />
    </Container>
  );
};

export default React.memo(InputCurrency);
