import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';

import { useSelector } from 'react-redux';
import { RiErrorWarningLine } from 'react-icons/ri';

import { FeaturesEnum } from '~/interfaces/enums';
import { CartOptional } from '~/interfaces/general';
import { handleOptionalsElementType } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import { useHasFeatureAccess, useTranslator } from '~/hooks';
import { Option, OptionGroup, OptionPricingTypeEnum } from '~/interfaces';
import { ProductContext, RequiredGroup } from '~/pages/detail/[productId]';

import OptionHeader from '../OptionHeader';
import { OptionListOnChangeProps } from '../OptionList';
import OptionComponent, { RadioCheckProps } from '../Option';

import { Container, HowItWorksWrapper, HowItWorksAnchor, Info } from './styles';

interface OptionGroupProps extends OptionGroup {
  type?: string;
  isViewMode?: boolean;
  parentFlowId?: string;
  selectedPriceFlowIdList: string[];
  selectedOptionals: CartOptional[];
  handleIsDoneStep: (props: RequiredGroup) => void;
  onChange: (props: OptionListOnChangeProps) => void;
}

const OptionGroupComponent: React.FC<OptionGroupProps> = ({
  id,
  type,
  repeat,
  options,
  min = 0,
  onChange,
  max = 99,
  required,
  name = '',
  parentFlowId,
  pricing_type,
  handleIsDoneStep,
  selectedOptionals,
  isViewMode = false,
  selectedPriceFlowIdList
}) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const isAbrahaoStore = useMemo(() => settings?.is_abrahao || false, [settings?.is_abrahao]);
  const [hasNewDeliveryAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.NewDeliveryMenu });

  const { getTranslation } = useTranslator();
  const { setSavedPricingType, setShouldDisplayHowItWorksModal } = useContext(ProductContext);

  const handleSelectedOptionalsNumber = (): number => {
    const groupSelectedOptionalsList = selectedOptionals
      ? selectedOptionals?.filter((selectedOptional) => {
          const matchSelectedOptionalList: Option[] = [];

          options.forEach((option) => {
            if (option.id === selectedOptional.id) {
              matchSelectedOptionalList.push(option);
            }
          });

          return matchSelectedOptionalList.length > 0;
        })
      : [];

    const DEFAULT_QUANTITY = 1;
    const numberOfChosenOptionals =
      groupSelectedOptionalsList.length +
      groupSelectedOptionalsList.reduce((prevValue, optional) => prevValue + (optional.quantity - DEFAULT_QUANTITY), 0);

    return numberOfChosenOptionals;
  };

  const [chosenOptionals, setChosenOptionals] = useState(handleSelectedOptionalsNumber());
  const [radioCheckedList, setRadioCheckedList] = useState<RadioCheckProps[]>(
    selectedOptionals.map((optional) => ({
      id: optional.id,
      value: optional.name,
      quantity: optional.quantity,
      elementFlowId: optional.elementFlowId
    }))
  );

  const specs = { min, max, required, repeat: repeat ? repeat > 0 : false };

  const isAvailable = useMemo(() => chosenOptionals < max, [chosenOptionals, max]);
  const isDone = useMemo(() => chosenOptionals >= min, [chosenOptionals, min]);

  const isGroupVisible = useMemo(() => {
    if (!parentFlowId) {
      return true;
    }

    if (selectedPriceFlowIdList?.includes(parentFlowId)) {
      return true;
    }

    const selectedOptionalIdList = selectedOptionals.map(({ elementFlowId }) => elementFlowId);
    return selectedOptionalIdList.includes(parentFlowId);
  }, [parentFlowId, selectedOptionals, selectedPriceFlowIdList]);

  const groupPricingType = useMemo(() => pricing_type, [pricing_type]);

  const shouldDisplayHowItWorksInfo =
    (groupPricingType === OptionPricingTypeEnum.Bigger || groupPricingType === OptionPricingTypeEnum.Medium) &&
    max < 99;

  const whichPricingTypeText = useMemo(
    () =>
      groupPricingType === OptionPricingTypeEnum.Bigger
        ? getTranslation('option.biggestValue')
        : getTranslation('option.mediumValue'),
    [getTranslation, groupPricingType]
  );

  useEffect(() => {
    if (required) {
      handleIsDoneStep({ id, name, parentFlowId, isDone: chosenOptionals >= min || !isGroupVisible });
    }
  }, [chosenOptionals, isGroupVisible, min, max, name]); //eslint-disable-line

  const getElementFlowId = useCallback(
    (optionId: number) => {
      const elementId = `${String(id)}-${optionId}`;

      if (!parentFlowId) {
        return elementId;
      }

      return `${parentFlowId}|${elementId}`;
    },
    [id, parentFlowId]
  );

  const getGroupChildrenFlow = useCallback((): CartOptional[] => {
    if (!parentFlowId) {
      return [];
    }

    return selectedOptionals.filter(({ elementFlowId }) => elementFlowId?.startsWith(parentFlowId));
  }, [parentFlowId, selectedOptionals]);

  const handleAddOptional = ({ edit, type: typeOptional, option: changedOption }: OptionListOnChangeProps): void => {
    const option = {
      ...changedOption,
      optionGroupId: id,
      elementFlowId: getElementFlowId(changedOption.id),
      optionGroupName: isAbrahaoStore || hasNewDeliveryAccess ? name : undefined
    };

    if (handleOptionalsElementType(specs) === 'radio') {
      setRadioCheckedList([
        {
          id: option.id,
          value: option.name,
          quantity: option.quantity,
          elementFlowId: getElementFlowId(changedOption.id)
        }
      ]);

      if (chosenOptionals > 0) {
        return options.forEach((optionItem) => {
          if (optionItem.id !== option.id) {
            const elementInFlowId = getElementFlowId(optionItem.id);
            const itemToRemove = selectedOptionals.find(
              (selectedOptional) => selectedOptional.elementFlowId === elementInFlowId
            );

            if (itemToRemove) {
              return onChange({ option, type: 'toggle', pricingType: pricing_type, removeOption: itemToRemove });
            }

            return false;
          }

          return false;
        });
      }
    }

    if ((typeOptional === 'add' || typeOptional === 'toggle') && chosenOptionals < max) {
      setChosenOptionals(chosenOptionals + 1);
    }

    if (typeOptional === 'remove') {
      setChosenOptionals(chosenOptionals - 1);
    }

    if (typeOptional === 'edit') {
      if (edit === 'add') {
        setChosenOptionals(chosenOptionals + 1);
      }

      if (edit === 'remove') {
        setChosenOptionals(chosenOptionals - 1);
      }
    }

    if (typeOptional === 'toggle') {
      return onChange({ option, type: 'add' });
    }

    return onChange({ option, type: typeOptional, pricingType: pricing_type });
  };

  useEffect(() => {
    if (!isGroupVisible) {
      setChosenOptionals(0);
      setRadioCheckedList([]);

      const activeFlowChildren = getGroupChildrenFlow();
      activeFlowChildren.map((option) => onChange({ option, type: 'remove', pricingType: pricing_type }));
    }
  }, [getGroupChildrenFlow, isGroupVisible, onChange, parentFlowId, pricing_type]);

  if (!isGroupVisible) {
    return <></>;
  }

  return (
    <Container data-test="optional-items-list" key={`${parentFlowId}|${id}`}>
      <OptionHeader
        isBold
        noPadding
        specs={specs}
        isDone={isDone}
        isRequired={required}
        isSimple={isViewMode}
        title={type === 'simple' || !name ? 'Opcionais' : name}
      />

      {shouldDisplayHowItWorksInfo && (
        <HowItWorksWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RiErrorWarningLine size={20} color={theme.colors.gray500} />
          </div>

          <Info>
            {getTranslation('option.priceIsCalculatedBy')}
            <strong>{whichPricingTypeText}</strong>.{' '}
            <HowItWorksAnchor
              onClick={(): void => {
                setShouldDisplayHowItWorksModal(true);
                setSavedPricingType(groupPricingType && groupPricingType);
              }}
            >
              {getTranslation('general.howDoesItWork')}
            </HowItWorksAnchor>
          </Info>
        </HowItWorksWrapper>
      )}

      {options.map((option) => (
        <OptionComponent
          {...option}
          specs={specs}
          key={option.id}
          repeat={repeat}
          title={option.name}
          elementId={option.id}
          groupName={option.name}
          isViewMode={isViewMode}
          optionValue={option.name}
          isAvailable={isAvailable}
          description={option.name}
          info={option.description}
          radioCheck={radioCheckedList}
          pricingType={groupPricingType}
          imageUrl={option.image?.medium}
          onChangeOptional={handleAddOptional}
          type={handleOptionalsElementType(specs)}
          elementFlowId={getElementFlowId(option.id)}
        />
      ))}
    </Container>
  );
};

export default OptionGroupComponent;
