import styled, { css } from 'styled-components';

const containerModifier = {
  isFixedPosition: () => css`
    bottom: 50px;
    position: fixed;

    margin: 0;
    padding: 12px 16px;
  `
};

export const Container = styled.section<{ isFixedPosition?: boolean; hasMarginBottom: boolean }>`
  ${({ theme, isFixedPosition, hasMarginBottom }) => css`
    width: 100%;
    padding: 16px;
    max-width: 800px;
    margin-bottom: ${hasMarginBottom ? 8 : 0}px;

    background: ${theme.colors.backgroundColor};

    ${isFixedPosition && containerModifier.isFixedPosition()};
  `}
`;

export const Header = styled.header`
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: auto;
    height: auto;

    span {
      font-size: 12px;
      font-weight: 700;
    }
  }
`;

export const BaseTitle = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-right: 10px;

    display: flex;
    align-items: center;
  }

  > p {
    font-size: 1rem;
    font-weight: 800;
    line-height: 100%;
  }
`;
