/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { lighten } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

export const modifiers = {
  errorMessage: css`
    bottom: -8px;

    opacity: 1;
    visibility: visible;
  `,
  disabled: (theme: DefaultTheme) => css`
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray300};

    background-color: ${theme.colors.gray300};
    color: ${theme.isDarkMode ? theme.colors.darkGray : theme.colors.gray500};
  `,
  inputError: (theme: DefaultTheme) => css`
    margin-bottom: 5px;
    border: 1px solid ${theme.colors.error};

    color: ${theme.colors.error};

    &::placeholder {
      color: ${lighten(0.15, theme.colors.error)};
    }

    &:focus {
      border: 1px solid ${theme.colors.error};
    }
  `
};

export const Container = styled.div`
  position: relative;
`;

export const Input = styled.input<any>`
  ${({ theme, error, disabled, isGoomerColor }) => css`
    width: 100%;
    height: 40px;
    padding: 0 16px;
    margin-bottom: 0;
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray300};

    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    background-color: ${theme.colors.backgroundColor};

    cursor: text;
    transition: 0.3s;

    &:focus {
      border: 1px solid
        ${isGoomerColor
          ? theme.colors.primary
          : css`
              ${theme.brandColors.primary}
            `};
    }

    &::placeholder {
      color: ${theme.colors.placeholderGray};
    }

    ${disabled && modifiers.disabled(theme)}
    ${!!error && modifiers.inputError(theme)}
  `}
`;

export const Info = styled.span`
  ${({ theme }) => css`
    margin-top: 4px;

    display: block;

    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: ${theme.colors.mediumGray};
  `}
`;

export const ErrorMessage = styled.div<{ showError?: boolean }>`
  ${({ theme, showError = true }) => css`
    display: block;

    opacity: 0;
    font-weight: 500;
    transition: 0.5s;
    text-align: left;
    line-height: 150%;
    font-size: 0.75rem;
    visibility: hidden;
    color: ${theme.colors.error};

    ${showError && modifiers.errorMessage}
  `}
`;
