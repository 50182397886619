import { useCallback } from 'react';

import { fetchApiGoHealth, fetchApiGoomerAlert, fetchAzionNetinfo } from '~/services';

export default function useAzionNetInfo() {
  const globalWindow =
    typeof window !== 'undefined'
      ? (window as any)
      : { location: { pathname: '', host: '', href: '', assign: () => {} } };

  const getSlug = useCallback(() => {
    const currentPathname: string = globalWindow.location.pathname;
    const host: string = globalWindow.location.host;

    const hostWithoutWWW = host.replace('www.', '');
    const urlParts = hostWithoutWWW.split('.');

    const splitPathname = currentPathname.split('/');

    const isLegacyURL = urlParts[0] === 'goomer' && urlParts.length === 2 && splitPathname.length >= 2;

    if (isLegacyURL) {
      const slug = splitPathname[1];

      return slug;
    }

    if (urlParts.length >= 2) {
      return urlParts[0];
    }

    return '';
  }, [globalWindow.location.host, globalWindow.location.pathname]);

  const handleAzionNetInfo: () => Promise<void> = useCallback(async () => {
    try {
      const status: number = await fetchApiGoHealth();

      if (status !== 200) {
        const nowInMilliseconds: number = new Date().getTime();

        const data = await fetchAzionNetinfo(nowInMilliseconds);

        await fetchApiGoomerAlert(getSlug(), nowInMilliseconds, data);
      }
    } catch (error) {
      const errorMessage = '#26 Erro ao verificar informações da Azion';
      const errorDetail = `${errorMessage}, Erro: ${(error as Error)?.message}`;

      console.error(errorDetail);
    }
  }, [getSlug]);

  return {
    handleAzionNetInfo
  };
}
