import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    height: 3.75rem;
    padding: 0 1rem;
    border-radius: 0.375rem;
    border: 1px solid ${theme.colors.lightGray};

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: ${theme.colors.backgroundColor};

    & + & {
      margin-top: 0.5rem;
    }

    button {
      height: 3.75rem;
      border: transparent;

      background-color: transparent;

      .icon-wrapper {
        width: 2.5rem;
        height: 2.5rem;
        min-width: 2.5rem;
        min-height: 2.5rem;
        margin-right: 0.5rem;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      img,
      svg {
        path {
          fill: ${theme.colors.mediumGray};
        }
      }

      .mt-6 {
        > svg {
          margin-top: 0.5rem;
        }
      }

      .sup {
        width: 100%;
        margin: 0.3125rem auto -0.3125rem;

        line-height: 1;
        text-align: left;
        font-size: 0.75rem;
        color: ${theme.colors.mediumGray};
      }
    }

    > .action {
      flex: 1;
      display: flex;
      align-items: center;

      .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > strong {
          span {
            margin: 0 0 0 0.25rem;
            border-radius: 1.5rem;
            padding: 0.25rem 0.375rem;

            display: inline-block;

            font-weight: 600;
            line-height: 100%;
            font-size: 0.75rem;
            color: ${theme.isLightBrandColor ? theme.brandColors.primary : theme.colors.backgroundColor};
          }
        }
      }

      strong,
      span {
        font-weight: 600;
        line-height: 150%;
        font-size: 0.875rem;
        color: ${theme.colors.textColor};
      }

      strong {
        margin-right: 0.75rem;

        display: inline-block;

        text-align: left;
      }
    }

    .tip-action {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 150%;
      color: ${theme.brandColors.primary};

      .link-like {
        font-weight: 600;
        line-height: 150%;
        font-size: 0.75rem;
        text-decoration: underline;
        color: ${theme.brandColors.primary};
      }
    }
  `}
`;
