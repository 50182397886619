import React from 'react';

import { useTranslator } from '~/hooks';

import * as S from './styles';

interface ListItemProps {
  supText?: string;
  dataTest?: string;
  category?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  onActionClick?: () => void;
  shouldDisplayNewTag?: boolean;
  action?: React.ReactNode | string;
  description?: React.ReactNode | string;
}

const ListItem: React.FC<ListItemProps> = ({
  icon,
  action,
  onClick,
  supText,
  dataTest,
  category,
  description,
  onActionClick,
  shouldDisplayNewTag
}) => {
  const { getTranslation } = useTranslator();

  return (
    <S.Container>
      <button type="button" className="action" onClick={onClick} data-test={dataTest}>
        {!!icon && <div className="icon-wrapper">{icon}</div>}

        <div className="wrap">
          <div hidden={!supText} className="sup">
            {supText}
          </div>

          {!!category && (
            <strong>
              {category} {shouldDisplayNewTag && <span>{getTranslation('general.new')}</span>}
            </strong>
          )}

          {!!description && typeof description === 'string' ? <span>{description}</span> : description}
        </div>
      </button>

      {!!action && (
        <button className="tip-action" type="button" onClick={onActionClick || onClick}>
          {typeof action === 'string' ? action : <div className="mt-6">{action}</div>}
        </button>
      )}
    </S.Container>
  );
};

export default ListItem;
