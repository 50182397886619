import {
  useAddressList,
  useAddressIsStatic,
  useAddresHandleSave,
  useAddressHandleDelete,
  useAddressSelectOnCart,
  useAddressRequiredFields,
  useAddressInitialFormFields,
  useAddressIsNeighborhoodTax
} from './hooks';

export default function useAddress() {
  const { addressList } = useAddressList();
  const { isStatic } = useAddressIsStatic();
  const { handleSave } = useAddresHandleSave();
  const { handleDelete } = useAddressHandleDelete();
  const { isNeighborhoodTax } = useAddressIsNeighborhoodTax();
  const { handleSelectAddressOnCart } = useAddressSelectOnCart();
  const { hasInitialFieldsFilled } = useAddressInitialFormFields();
  const { hasAllRequiredFieldsFilled } = useAddressRequiredFields();

  return {
    isStatic,
    handleSave,
    addressList,
    handleDelete,
    isNeighborhoodTax,
    hasInitialFieldsFilled,
    handleSelectAddressOnCart,
    hasAllRequiredFieldsFilled
  };
}
