interface GetGeolocationDistanceProps {
  latitude1: number;
  longitude1: number;
  latitude2: number;
  longitude2: number;
  unit?: 'KM' | 'Milles' | 'Nautical';
}

export default function getGeolocationDistance({
  latitude1,
  longitude1,
  latitude2,
  longitude2,
  unit = 'KM'
}: GetGeolocationDistanceProps) {
  if (latitude1 === latitude2 && longitude1 === longitude2) {
    return 0;
  }

  const radiusLatitude1 = (Math.PI * latitude1) / 180;
  const radiusLatitude2 = (Math.PI * latitude2) / 180;

  const theta = longitude1 - longitude2;

  const radiusTheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radiusLatitude1) * Math.sin(radiusLatitude2) +
    Math.cos(radiusLatitude1) * Math.cos(radiusLatitude2) * Math.cos(radiusTheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  if (unit === 'Milles') {
    return dist;
  }

  if (unit === 'Nautical') {
    return (dist *= 0.8684);
  }

  return (dist *= 1.609344);
}
