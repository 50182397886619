import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  width: 100%;

  ${media.greaterThan('medium')`
    max-width: 800px;
    margin: 0 auto;
  `}
`;
