import React from 'react';

import { useSelector } from 'react-redux';

import { Button } from '~/components';
import { CardPayment } from '~/assets';
import { useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { ModalCloseButtonTypesEnum } from '~/interfaces/enums';

import * as S from './styles';

export interface TabPaymentPromoModalProps {
  isShow: boolean;
  onClose: () => void;
}

const TabPaymentPromoModal = ({ isShow, onClose }: TabPaymentPromoModalProps): JSX.Element => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  return (
    <S.Container
      isSmall
      isShow={isShow}
      onClose={onClose}
      closeButton={{ color: theme.colors.textColor, type: ModalCloseButtonTypesEnum.times }}
    >
      <S.Content>
        <div className="promo-image">
          <CardPayment brandColor={theme.brandColors.primary} />
        </div>

        <div className="new-tag">
          <p>{getTranslation('paymentPromoModal.news')}</p>
        </div>

        <div className="title">{getTranslation('paymentPromoModal.payBillFaster')}</div>

        <div className="desc">{getTranslation('paymentPromoModal.everyoneAtTheTable')}</div>

        <Button onClick={onClose} isGhost={false}>
          {getTranslation('general.okayGotIt')}
        </Button>
      </S.Content>
    </S.Container>
  );
};

export default TabPaymentPromoModal;
