import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { Button, Modal } from '~/components';
import { ICoupon } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';

import * as S from './styles';

export interface FirstOrderRulesModalProps {
  coupon: ICoupon;
  isOpen: boolean;
  onClose: () => void;
  handleSignIn: () => void;
}

const FirstOrderRulesModal: React.FC<FirstOrderRulesModalProps> = ({ coupon, isOpen, onClose, handleSignIn }) => {
  const { getTranslation } = useTranslator();

  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  return (
    <S.Container>
      <Modal
        isPageLike
        isShow={isOpen}
        onClose={onClose}
        isWithoutPadding
        aria-label={getTranslation('general.firstOrder')}
        headerTitle={getTranslation('firstOrder.firstOrderDiscount')}
      >
        <S.Content>
          <S.SectionTitle>
            {getTranslation('firstOrder.getFirstOrderDiscount', {
              discountPercentage: coupon.type === 'absolute' ? convertToCurrency(coupon.value) : `${coupon.value}%`
            })}
            {settings?.name}!
          </S.SectionTitle>

          <S.RulesWrapper>
            <S.Section>
              <S.Subtitle>{getTranslation('general.rules')}</S.Subtitle>

              <S.RulesList>
                <S.RulesItem>{getTranslation('firstOrder.discountInFinalOrder')}</S.RulesItem>

                <S.RulesItem>{getTranslation('firstOrder.firstOrderDiscountNotCumulative')}</S.RulesItem>

                <S.RulesItem>{getTranslation('firstOrder.shouldBeLoggedIn')}</S.RulesItem>

                <S.RulesItem>
                  {getTranslation('firstOrder.validForOrders', {
                    minimumValue: convertToCurrency(coupon.order_min_value)
                  })}
                </S.RulesItem>
              </S.RulesList>
            </S.Section>
          </S.RulesWrapper>

          <S.ButtonsWrapper>
            <Button onClick={handleSignIn} isGhost={false}>
              {getTranslation('firstOrder.registerNow')}
            </Button>

            <Button onClick={onClose} isGhost>
              {getTranslation('general.backToMenu')}
            </Button>
          </S.ButtonsWrapper>
        </S.Content>
      </Modal>
    </S.Container>
  );
};

export default FirstOrderRulesModal;
