import { action } from 'typesafe-actions';

import { ErrorLogEntry } from '~/interfaces/general';

import { ErrorLogActionTypes } from './types';

export const addError = (logEntry: ErrorLogEntry) => {
  return action(ErrorLogActionTypes.ADD_ERROR, logEntry);
};

export const cleanLog = () => {
  return action(ErrorLogActionTypes.CLEAN_LOG);
};

export const sendLog = (payload: ErrorLogEntry) => {
  return action(ErrorLogActionTypes.SEND_LOG_REQUEST, payload);
};

export const sendLogSuccess = () => {
  return action(ErrorLogActionTypes.SEND_LOG_SUCCESS);
};

export const sendLogError = () => {
  return action(ErrorLogActionTypes.SEND_LOG_ERROR);
};
