import { DeliveryWayEnum } from '~/interfaces/enums';
import { Address, ICoupon, PaymentMethod } from '~/interfaces/general';

export interface CheckoutState {
  readonly cpf: string;
  readonly name: string;
  readonly error?: boolean;
  readonly loading: boolean;
  readonly address?: Address;
  readonly clientPhone: string;
  readonly schedulingDate?: string;
  readonly selectedCoupon?: ICoupon;
  readonly isFullAgeTermAccept?: boolean;
  readonly paymentOption?: PaymentMethod;
  readonly deliveryOption?: DeliveryWayEnum;
}

export const CheckoutActionTypes = {
  ADD_CHECKOUT_DATA: '@@checkout/ADD_CHECKOUT_DATA',
  ADD_CHECKOUT_COUPON: '@@checkout/ADD_CHECKOUT_COUPON',
  REMOVE_CHECKOUT_DATA: '@@checkout/REMOVE_CHECKOUT_DATA',
  SET_ERROR_CHECKOUT_DATA: '@@checkout/SET_ERROR_CHECKOUT_DATA'
};
