import React, { useRef, useEffect } from 'react';

import { useField } from '@unform/core';

import { useTranslator } from '~/hooks';
import { Input } from '~/components/Input/Input/styles';

import * as S from './styles';

export interface FormInputProps {
  id?: string;
  name: string;
  type?: string;
  value?: string;
  isValid?: boolean;
  required?: boolean;
  inputMode?: string;
  showError?: boolean;
  onBlur?: () => void;
  placeholder?: string;
  defaultValue?: string;
  errorMessage?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FormInput: React.FC<FormInputProps> = ({ name, isValid, errorMessage, ...rest }) => {
  const inputRef = useRef(null) as any;

  const { getTranslation } = useTranslator();
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      path: 'value',
      name: fieldName,
      ref: inputRef.current
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <S.Container withError={isValid !== undefined && !isValid} className="sentry-mask">
      <Input ref={inputRef} id={fieldName} max={255} maxLength={255} {...rest} />

      {isValid !== undefined && !isValid && (
        <S.Error showError={!!errorMessage}>{errorMessage || getTranslation('general.invalidField')}</S.Error>
      )}
    </S.Container>
  );
};

export default React.memo(FormInput);
