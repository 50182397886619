import { api } from '~/services/api';
import { Menu, Highlight } from '~/interfaces';
import { OldProduct } from '~/hooks/useParserFetchMenusResponse';

export interface FetchMenusResponse {
  menus: Menu[];
  highlights: Highlight[];
}

interface FetchMenusProps {
  url: string;
  parserFetchMenusResponse: (oldResponse: { products: OldProduct[] }) => FetchMenusResponse;
}

export default async function fetchMenus({
  url,
  parserFetchMenusResponse
}: FetchMenusProps): Promise<FetchMenusResponse> {
  try {
    const response = await api.get(url);

    //Trocar checagem pela setting que indica novo menu
    if (response.data.products) {
      return parserFetchMenusResponse(response.data as { products: OldProduct[] });
    }

    return response.data as FetchMenusResponse;
  } catch (error) {
    throw new Error((error as Error)?.message);
  }
}
