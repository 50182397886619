import { DefaultTheme } from 'styled-components';

export interface BrandColors {
  primary: string;
  originalPrimary: string;
}

export interface IThemeState {
  readonly message: string;
  readonly loading: boolean;
  readonly theme: DefaultTheme;
}

export const ThemeActionTypes = {
  THEME_FETCH_REQUEST: '@@theme/THEME_FETCH_REQUEST',
  THEME_FETCH_SUCCESS: '@@theme/THEME_FETCH_SUCCESS',
  THEME_FETCH_ERROR: '@@theme/THEME_FETCH_ERROR',
  SET_IS_DARK_MODE: '@@theme/SET_IS_DARK_MODE'
};
