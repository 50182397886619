import { toast, Slide } from 'react-toastify';

import { StatusFooter } from '~/components';
import { ToastIdEnum } from '~/interfaces/enums';
import { StatusFooterProps } from '~/components/StatusFooter';

interface ShowToastProps extends StatusFooterProps {
  toastId?: string;
  closeOnClick?: boolean;
  type: 'error' | 'success';
}

export default function showToast({ text, icon, type, toastId, closeOnClick = false }: ShowToastProps) {
  return toast[type](<StatusFooter text={text} icon={icon} />, {
    draggable: true,
    autoClose: 3000,
    transition: Slide,
    closeButton: false,
    pauseOnHover: true,
    progress: undefined,
    hideProgressBar: true,
    position: 'bottom-center',
    className: 'Toastify__pix',
    closeOnClick: closeOnClick,
    toastId: toastId || ToastIdEnum.avoidDuplicating
  });
}
