import React, { useRef, useCallback, useContext, useMemo } from 'react';

import { useTranslator } from '~/hooks';
import { EmailVerificationStatusEnum } from '~/interfaces/enums';

import { AccountVerificationContext } from '../../AccountVerification';

import * as S from './styles';

export interface EmailVerificationInputProps {
  isDisabled?: boolean;
  onChange: (code: string) => void;
  status: EmailVerificationStatusEnum;
}

const EmailVerificationInput = ({
  onChange,
  isDisabled = false,
  status = EmailVerificationStatusEnum.default
}: EmailVerificationInputProps): JSX.Element => {
  const inputRef = useRef<any>();

  const { getTranslation } = useTranslator();
  const { setValidationCodeStatus } = useContext(AccountVerificationContext);

  const isWithDifferentStatus = useMemo(() => {
    return status !== EmailVerificationStatusEnum.default;
  }, [status]);

  const isValidStatus = useMemo(() => {
    return status === EmailVerificationStatusEnum.success;
  }, [status]);

  const clearInputs = useCallback(() => {
    if (status === EmailVerificationStatusEnum.error) {
      if (inputRef.current) {
        inputRef.current.state.input[0] = '';
        inputRef.current.state.input[1] = '';
        inputRef.current.state.input[2] = '';
        inputRef.current.state.input[3] = '';
        inputRef.current.state.input[4] = '';

        inputRef.current.textInput[0].focus();
      }

      setValidationCodeStatus(EmailVerificationStatusEnum.default);
    }
  }, [status, setValidationCodeStatus]);

  return (
    <S.Container onFocus={clearInputs} status={status}>
      <S.Input
        fields={5}
        type="number"
        ref={inputRef}
        status={status}
        name="email-code"
        onChange={onChange}
        inputMode="numeric"
        disabled={isDisabled}
      />

      {isWithDifferentStatus && (
        <span>
          {getTranslation('accountVerification.checkCode', {
            check: isValidStatus
              ? getTranslation('general.confirmed')
              : getTranslation('accountVerification.invalidCode')
          })}
        </span>
      )}
    </S.Container>
  );
};

export default EmailVerificationInput;
