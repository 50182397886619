import { useCallback, useMemo } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { browserName, mobileModel } from 'react-device-detect';

import { formatCep } from '~/utils';
import useSlug from '~/hooks/useSlug';
import useTranslator from '~/hooks/useTranslator';
import { IApplicationState } from '~/redux-tools/store';
import { DeliveryPricingEnum } from '~/interfaces/enums';
import { addUserInfo } from '~/redux-tools/store/user/actions';
import GoogleAnalytics, { gaErrors, gaEvents } from '~/utils/analytics';
import { Address, DeliveryFeeNeighborhood } from '~/interfaces/general';
import { removeUserAddressOnCart, setDeliveryFee, setUserAddressOnCart } from '~/redux-tools/store/cart/actions';

import useAddressIsStatic from './useAddressIsStatic';
import useAddressIsNeighborhoodTax from './useAddressIsNeighborhoodTax';

const COMPLETE_CEP_NUMBERS = 9;

export default function useAddressSelectOnCart() {
  const dispatch = useDispatch();
  const slug = useSlug();

  const userInfo = useSelector((state: IApplicationState) => state.user.data);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { isStatic } = useAddressIsStatic();
  const { getTranslation } = useTranslator();
  const { isNeighborhoodTax } = useAddressIsNeighborhoodTax();

  const gaTrackErrorDeliveryFee: ({
    error,
    extra,
    address
  }: {
    error: string;
    extra?: string;
    address: Address;
  }) => void = useCallback(
    ({ error, extra, address }) => {
      GoogleAnalytics.trackError(gaErrors.deliveryFeeError, {
        browser: browserName,
        error_message: error,
        phone_model: mobileModel,
        error_extra_message: extra,
        address: JSON.stringify(address),
        establishment_id: settings?.store_code
      });
    },
    [settings?.store_code]
  );

  // static retro compatibility code
  const convertOldWaitingTime: (time?: string) => {
    from: string;
    to?: string;
  } | null = useCallback((time) => {
    if (!time) {
      return null;
    }

    const formattedData = time.replace('min', '').trim().split('-');
    if (formattedData.length === 2) {
      return {
        to: formattedData[1],
        from: formattedData[0]
      };
    }
    if (formattedData.length === 1) {
      return {
        from: formattedData[0]
      };
    }
    return null;
  }, []);

  const getStaticDeliveryInfo: () => {
    pricing: string;
    time: {
      from: number;
      to: number;
    };
    delivery_time_enabled: boolean;
  } = useCallback(() => {
    let pricing: string = DeliveryPricingEnum.ask;
    const time = {
      to: 0,
      from: 0
    };
    let delivery_time_enabled = false;

    const isDeliveryFeeEnabled = settings?.mm_delivery_fee_enabled;

    const deliveryFee: number = settings?.mm_delivery_fee ? Number(settings.mm_delivery_fee) : 0;
    const deliveryTime =
      typeof settings?.mm_delivery_average_time === 'string'
        ? convertOldWaitingTime(settings.mm_delivery_average_time)
        : settings?.mm_delivery_average_time;

    if (isDeliveryFeeEnabled) {
      pricing =
        deliveryFee === 0
          ? DeliveryPricingEnum.free
          : deliveryFee > 0
          ? deliveryFee.toString()
          : DeliveryPricingEnum.ask;
    } else {
      pricing = DeliveryPricingEnum.ask;
    }

    if (deliveryTime) {
      if ('from' in deliveryTime) {
        time.from = deliveryTime.from ? Number(deliveryTime.from) : 0;
      }
      if ('to' in deliveryTime) {
        time.to = deliveryTime.to ? Number(deliveryTime.to) : 0;
      }
    }

    delivery_time_enabled = time.from > 0 || time.to > 0;

    return {
      time,
      pricing,
      delivery_time_enabled
    };
  }, [settings, convertOldWaitingTime]);

  const compareStoreAddress: (destination: Address) => boolean = useCallback(
    (destination) => {
      const storeAddress = {
        cep: settings?.address?.cep,
        city: settings?.address?.city,
        state: settings?.address?.state,
        number: settings?.address?.number,
        street: settings?.address?.street,
        neighborhood: settings?.address?.neighborhood
      };

      const destinationAddress = {
        city: destination.city,
        state: destination.state,
        number: destination.number,
        street: destination.street,
        cep: destination.cep?.replace('.', ''),
        neighborhood: destination.neighborhood
      };

      return JSON.stringify(destinationAddress) === JSON.stringify(storeAddress);
    },
    [settings]
  );

  const getDeliveryFee: (address: Address, afterGet: (success: boolean) => void) => void = useCallback(
    (address, afterGet) => {
      async function fetchDelivery(query: string, afterAction?: () => void) {
        try {
          const { data } = await axios.get(`${process.env.NEXT_PUBLIC_DELIVERY_FEE}?${query}`);

          if (data.length === 0) {
            throw new Error('Erro ao buscar taxa de entrega!');
          }

          afterAction?.();

          dispatch(setDeliveryFee(data));

          afterGet(true);
        } catch (error) {
          toast.error(getTranslation('errorMessage.searchingDeliveryFee'));

          const errorMessage = '#07 busca de cep em nossa API falhou';
          const errorDetail = `${errorMessage}, Erro: ${(error as Error)?.message}`;

          gaTrackErrorDeliveryFee({ address, error: errorDetail });

          afterGet(false);
        }
      }

      async function fetchAddressInfo() {
        try {
          const fullAddress = {
            google: true,
            id: address.id,
            cep: address.cep,
            city: address.city,
            state: address.state,
            number: address.number,
            street: address.street,
            reference: address.reference,
            complement: address.complement,
            neighborhood: address.neighborhood
          };

          const setSelectedAddressOnCart = () => {
            if (userInfo.addresses && userInfo.addresses.length > 0) {
              const addresses = userInfo.addresses.map((address) => {
                if (address.id === fullAddress.id) {
                  return fullAddress;
                }

                return address;
              });

              dispatch(addUserInfo({ addresses }));
              dispatch(setUserAddressOnCart(fullAddress));
            }
          };

          const id = settings?.id ? `storeId=${settings.id}` : `storeId=${settings?.store_code}`;
          const provider = 'provider=googlemaps';
          const zipcodeCheck = address.cep ? ` - ${address.cep?.replace('.', '')}` : '';
          const neighborhood = !address.cep ? `${address.neighborhood} - ` : '';
          const destinationQuery = encodeURIComponent(
            `${address.street}, ${address.number} - ${neighborhood}${address.city}, ${address.state}${zipcodeCheck}`
          );
          const destination = `destination=${destinationQuery}`;
          const deliveryQuery = `${provider}&${id}&${destination}`;

          await fetchDelivery(deliveryQuery, setSelectedAddressOnCart);
        } catch (error) {
          toast.error((error as Error)?.message);

          const errorMessage = '#06 busca de cep em nossa API falhou';
          const errorDetail = `${errorMessage}, Erro: ${(error as Error)?.message}`;

          gaTrackErrorDeliveryFee({ address, error: errorDetail });
        }
      }

      if (address && (settings?.id || settings?.store_code)) {
        const id = settings.id ? `storeId=${settings.id}` : `storeId=${settings.store_code}`;

        if (isStatic) {
          // static FEE
          if (settings.mm_delivery_zone_options_static && 'pricing' in settings.mm_delivery_zone_options_static) {
            dispatch(setDeliveryFee(settings.mm_delivery_zone_options_static));

            afterGet(true);
          } else {
            dispatch(setDeliveryFee(getStaticDeliveryInfo()));

            afterGet(true);
          }
        } else {
          // dynamic FEE
          const sameAddressAsStore = compareStoreAddress(address);

          const dynamicOptions = settings.mm_delivery_zone_options_dynamic || [];
          const closestDeliveryFee = dynamicOptions.find((item) => item.status);

          if (sameAddressAsStore && closestDeliveryFee) {
            dispatch(setDeliveryFee(closestDeliveryFee));

            afterGet(true);
          } else if (!address.latitude || !address.longitude) {
            // handle address input on the old form
            if (address.cep) {
              if (formatCep(address.cep).length >= COMPLETE_CEP_NUMBERS) {
                fetchAddressInfo();
              }
            } else {
              fetchAddressInfo();
            }
          } else {
            // handle address input on the new form
            const provider = 'provider=googlemaps';
            const zipcodeCheck = address.cep ? ` - ${address.cep?.replace('.', '')}` : '';
            const neighborhood = !address.cep ? `${address.neighborhood} - ` : '';
            const destinationQuery = encodeURIComponent(
              `${address.street}, ${address.number} - ${neighborhood}${address.city}, ${address.state}${zipcodeCheck}`
            );
            const destination = `destination=${destinationQuery}`;
            const query = `${provider}&${id}&${destination}`;

            fetchDelivery(query);
          }
        }
      }
    },
    [
      dispatch,
      isStatic,
      settings?.id,
      getTranslation,
      userInfo.addresses,
      settings?.store_code,
      compareStoreAddress,
      getStaticDeliveryInfo,
      gaTrackErrorDeliveryFee,
      settings?.mm_delivery_zone_options_static,
      settings?.mm_delivery_zone_options_dynamic
    ]
  );

  const neighborhoodTaxeList = useMemo(() => {
    if (settings?.mm_delivery_zone_options_neighborhood) {
      return settings.mm_delivery_zone_options_neighborhood;
    }

    return [];
  }, [settings]);

  const getDeliveryFeeNeighborhood: (selectedAddress: Address, afterGet: (success: boolean) => void) => void =
    useCallback(
      (selectedAddress, afterGet) => {
        if (selectedAddress && settings) {
          try {
            const tax: DeliveryFeeNeighborhood | undefined = neighborhoodTaxeList.find(
              (item: DeliveryFeeNeighborhood) => item.neighborhood_id === selectedAddress.neighborhood_id && item.status
            );

            if (tax) {
              dispatch(setDeliveryFee(tax));
            } else {
              dispatch(
                setDeliveryFee({
                  time: { to: 0, from: 0 },
                  delivery_time_enabled: false,
                  pricing: DeliveryPricingEnum.outside
                })
              );
            }

            afterGet(true);
          } catch (error) {
            dispatch(removeUserAddressOnCart());

            toast.error((error as Error)?.message);

            const errorMessage = '#14 busca de cep por bairro em nossa API falhou';
            const errorDetail = `${errorMessage}, Error: ${(error as Error)?.message}`;

            gaTrackErrorDeliveryFee({
              error: errorDetail,
              address: selectedAddress
            });

            afterGet(false);
          }
        }
      },
      [settings, neighborhoodTaxeList, dispatch, gaTrackErrorDeliveryFee]
    );

  const handleSelectAddressOnCart: (address: Address, afterAction?: (success: boolean) => void) => void = useCallback(
    (address, afterAction) => {
      const afterGet = (success: boolean) => {
        afterAction?.(success);
      };

      if (isNeighborhoodTax) {
        dispatch(setUserAddressOnCart(address));

        getDeliveryFeeNeighborhood(address, afterGet);
      } else {
        GoogleAnalytics.trackEvent(gaEvents.checkoutAddressConfirmed);

        getDeliveryFee(address, (success: boolean) => {
          if (success) {
            dispatch(setUserAddressOnCart(address));
          } else {
            dispatch(removeUserAddressOnCart());
          }

          afterGet(success);
        });
      }

      return;
    },
    [dispatch, getDeliveryFee, getDeliveryFeeNeighborhood, isNeighborhoodTax]
  );

  return { handleSelectAddressOnCart };
}
