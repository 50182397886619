import styled, { css } from 'styled-components';
import { motion, MotionProps } from 'framer-motion';

export const Container = styled(motion.div).attrs((props: MotionProps) => ({
  ...props,
  exit: { opacity: 0 },
  animate: { opacity: 1 },
  initial: { opacity: 0 }
}))`
  width: 100%;

  background: ${({ theme }) => theme.colors.backgroundColor};

  .content-wrap {
    position: relative;

    padding-top: 2rem;
    padding-bottom: 6rem;
    min-height: calc(100vh - 300px);

    background: ${({ theme }) => theme.colors.lightestGray};

    > .container {
      margin: 0 auto;
      max-width: 800px;
    }

    .terms {
      max-width: 800px;
      margin: 0 auto;

      padding: 0;
      padding-bottom: 2rem;
    }
  }

  > .container {
    padding: 0.5rem 0 0;

    margin: 0 auto;
    max-width: 800px;
  }
`;

export const INeedChange = styled.button`
  padding: 7px 10px;
  border-radius: 4px;
  margin: 0 16px 0 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};

  color: ${({ theme }) => theme.brandColors.primary};
  background-color: ${({ theme }) => theme.colors.backgroundColor};

  &.active {
    border: 1px solid ${({ theme }) => theme.colors.lightGray};

    background-color: ${({ theme }) => theme.brandColors.primary};
    color: ${({ theme }) => (theme.isLightBrandColor ? theme.brandColors.primary : theme.colors.backgroundColor)};
  }
`;

export const RequiredAmountContainer = styled.div`
  padding: 18px 42px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.gray100};

  > span {
    text-align: center;
  }
`;

export const AddMoreItems = styled.a`
  margin: 1rem 0 0;

  display: block;
`;

export const OptionContainer = styled.div`
  margin-bottom: 2rem;

  overflow-x: hidden;
`;

export const OptionSpacing = styled.div`
  margin: 6px 16px 0;
`;

export const OptionInfo = styled.div`
  padding: 0 16px;
  margin-top: 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;

  color: ${({ theme }) => theme.colors.mediumGray};
`;

interface ButtonContentProps {
  isLoading: boolean;
}

const modifiers = {
  isLoading: () => css`
    .content {
      transform: translateY(50px);

      animation: contentLeaving 0.5s ease-in-out;
    }

    .loading {
      transform: translateX(-50%) translateY(0);

      animation: contentEntering 1s ease-in-out;
    }
  `
};

export const ButtonWrapper = styled.div`
  width: 100%;

  > button {
    > span {
      width: 100%;
      padding: 0 1rem;
    }
  }
`;

export const ButtonContent = styled.div<ButtonContentProps>`
  ${({ theme, isLoading }) => css`
    position: relative;

    width: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .loading {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-50px);

      height: 24px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: 2s;

      svg {
        path {
          fill: ${theme.brandColors.actionText};
        }
      }
    }

    .content {
      transform: translateY(0);

      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        path {
          fill: ${theme.brandColors.actionText};
        }
      }
    }

    ${isLoading && modifiers.isLoading}

    @keyframes contentLeaving {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-4px);
      }

      100% {
        transform: translateY(50px);
      }
    }

    @keyframes contentEntering {
      0% {
        transform: translateX(-50%) translateY(-50px);
      }

      75% {
        transform: translateX(-50%) translateY(4px);
      }

      100% {
        transform: translateX(-50%) translateY(0);
      }
    }
  `}
`;
