import { useState, useEffect, useCallback } from 'react';

import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { showToast } from '~/utils';
import { PixPayment } from '~/components';
import { IApplicationState } from '~/redux-tools/store';
import { IntegratedPixTabPayment } from '~/interfaces/general';
import { ToastIdEnum, ToastTypeEnum } from '~/interfaces/enums';
import { useCountdownDateComparison, useTranslator } from '~/hooks';
import { cleanTabPayment } from '~/redux-tools/store/myTab/actions';
import { cleanPixTabPayment } from '~/redux-tools/store/pixPayment/actions';

import * as S from './styles';

export interface PixPaymentModalProps {
  loading: boolean;
  isOpenPixModal: boolean;
  shouldDisplayPixImage?: boolean;
  tabPayment?: IntegratedPixTabPayment;
}

const PixPaymentModal: (props: PixPaymentModalProps) => JSX.Element = ({
  loading,
  tabPayment,
  isOpenPixModal,
  shouldDisplayPixImage = true
}) => {
  const dispatch = useDispatch();

  const [isPixExpired, setIsPixExpired] = useState(false);
  const [isQrCodeVisible, setIsQrCodeVisible] = useState(false);
  const [isPaymentCancelled, setIsPaymentCancelled] = useState(false);

  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const {
    resetCountdown,
    formattedCountdown,
    countdownExpired: isCountdownExpired
  } = useCountdownDateComparison(tabPayment?.expiration);
  const { getTranslation } = useTranslator();

  useEffect(() => {
    if (isCountdownExpired && tabPayment?.expiration) {
      setIsPixExpired(true);
      setIsPaymentCancelled(true);

      showToast({
        type: ToastTypeEnum.error,
        toastId: ToastIdEnum.cancel,
        icon: <AiOutlineCloseCircle size={20} />,
        text: `${getTranslation('payment.pix.codeExpired')}.`
      });
    }
  }, [dispatch, getTranslation, isCountdownExpired, tabPayment?.expiration]);

  const cancelPayment = useCallback(() => {
    dispatch(cleanTabPayment());
    dispatch(cleanPixTabPayment());

    setIsPixExpired(false);
    setIsQrCodeVisible(false);
    setIsPaymentCancelled(false);

    resetCountdown();
  }, [dispatch, resetCountdown]);

  if (!tabPayment) {
    return <></>;
  }

  return (
    <S.Container
      isPageLike
      hideCloseButton
      isShow={isOpenPixModal}
      onClose={(): void => cancelPayment()}
      containerBackgroundColor={theme.colors.gray300}
      titleBackgroundColor={theme.colors.backgroundColor}
      headerTitle={getTranslation('payment.pix.pixPayment')}
    >
      <PixPayment
        isTabPayment
        showCancelButton
        loading={loading}
        handleCancel={cancelPayment}
        isCancelled={isPaymentCancelled}
        pixKey={tabPayment?.pixKey || ''}
        isQrCodeVisible={isQrCodeVisible}
        qrCodePixKey={tabPayment.qrCodePixKey}
        countdown={formattedCountdown() || ''}
        setIsQrCodeVisible={setIsQrCodeVisible}
        shouldDisplayPixImage={shouldDisplayPixImage}
        title={!isPixExpired ? getTranslation('myTab.waitingForPayment') : getTranslation('payment.pix.expiredPixCode')}
        text={
          !isPixExpired ? (
            <div dangerouslySetInnerHTML={{ __html: getTranslation('myTab.usePixCodeInYourApp') }} />
          ) : (
            getTranslation('myTab.backToAccountTryAgain')
          )
        }
      />
    </S.Container>
  );
};

export { PixPaymentModal };
