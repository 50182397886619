const LactoseFreeIcon = ({ fill = '#575F72' }: { fill?: string }): JSX.Element => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.4225 10.0481L7.65293 7.27859L8.33774 6.59378C8.83174 6.0998 9.0631 5.40949 8.97126 4.72294L9.12287 4.57133C9.41894 4.7752 9.82847 4.74558 10.0916 4.48249C10.2352 4.33885 10.3143 4.1479 10.3143 3.94475C10.3143 3.74164 10.2352 3.55069 10.0916 3.40705L8.59289 1.90834C8.29638 1.61183 7.81398 1.61187 7.51749 1.90834C7.25444 2.17143 7.22482 2.58097 7.42865 2.87707L7.27704 3.02868C6.59048 2.93675 5.9002 3.16822 5.4062 3.6622L4.7214 4.34701L1.95188 1.57752C1.84852 1.47416 1.68091 1.47416 1.57753 1.57752C1.47416 1.68088 1.47416 1.84849 1.57753 1.95186L4.34705 4.72139L1.97109 7.0974C1.763 7.30549 1.64837 7.58218 1.64837 7.87648C1.64837 8.17077 1.76296 8.44747 1.97109 8.65558L3.34441 10.0289C3.55252 10.237 3.82922 10.3516 4.12351 10.3516C4.41782 10.3516 4.6945 10.237 4.90261 10.0289L7.27859 7.65292L10.0481 10.4224C10.0998 10.4742 10.1675 10.5 10.2353 10.5C10.303 10.5 10.3708 10.4741 10.4225 10.4225C10.5258 10.3191 10.5258 10.1515 10.4225 10.0481ZM4.50861 9.68837C4.3998 9.79718 4.25513 9.8571 4.10122 9.8571C3.94733 9.8571 3.80266 9.79718 3.69384 9.68837L2.31161 8.30613C2.2028 8.19731 2.14288 8.05264 2.14288 7.89874C2.14288 7.74483 2.2028 7.60016 2.31161 7.49136L4.70303 5.09996L6.90002 7.29697L4.50861 9.68837ZM5.10002 4.74533L7.25466 6.89996L7.9306 6.22397C8.32823 5.82634 8.4998 5.25972 8.3895 4.70829C8.37237 4.62263 8.39918 4.53407 8.46097 4.4723L8.88155 4.05172C8.9836 3.9497 9.14904 3.9497 9.25106 4.05172L9.33931 4.13996C9.42821 4.22885 9.5729 4.22887 9.66181 4.13995C9.70489 4.0969 9.72859 4.03964 9.72859 3.97872C9.72859 3.91784 9.70489 3.86058 9.66181 3.8175L8.18247 2.33817C8.1394 2.29509 8.08214 2.27139 8.02124 2.27139C7.96034 2.27139 7.90308 2.29509 7.86 2.33817C7.77111 2.42709 7.77111 2.57176 7.86002 2.66067L7.94825 2.74891C8.05029 2.85096 8.05029 3.01639 7.94825 3.11842L7.52766 3.539C7.46587 3.60079 7.37728 3.62754 7.29167 3.61047C7.18161 3.58845 7.07093 3.57768 6.96112 3.57768C6.52077 3.57768 6.09426 3.75111 5.77598 4.06938L5.10002 4.74533Z"
      />
    </svg>
  );
};

export default LactoseFreeIcon;
