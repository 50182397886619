import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const List = styled.ul`
  width: 100%;
`;

export const DropdownItem = styled.li<{ $isVisible: boolean }>`
  ${({ theme, $isVisible }) => css`
    max-height: 50px;

    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${theme.colors.gray300};

    transition: 0.3s;
    overflow: hidden;

    ${!$isVisible &&
    css`
      padding: 0;
      margin: 0;
      max-height: 0;
      border: none;

      transition: 0.3s;
    `}
  `}
`;

export const PaidAlertContainer = styled.div`
  width: 100%;
  padding: 0;
  margin: 12px 0 0;
`;
