import { action } from 'typesafe-actions';

import { BillQueryProps } from '~/services/getBill';
import { TabDetails, TabPaymentInfo } from '~/interfaces/general';

import { MyTabActionTypes } from './types';

export interface FetchTabDetailsProps {
  refresh?: boolean;
  request: BillQueryProps;
  callback?: (tab: TabDetails) => void;
}

export const addOrder = (order: any) => {
  return action(MyTabActionTypes.ADD_ORDER, order);
};

export const cleanTab = () => {
  return action(MyTabActionTypes.CLEAN_TAB);
};

export const addTabPayment = (payment: TabPaymentInfo) => {
  return action(MyTabActionTypes.ADD_PAYMENT, payment);
};

export const cleanTabPayment = () => {
  return action(MyTabActionTypes.CLEAN_PAYMENT);
};

export const fetchTabDetailsRequest = (payload: FetchTabDetailsProps) => {
  return action(MyTabActionTypes.FETCH_TAB_DETAILS_REQUEST, payload);
};

export const fetchTabDetailsSuccess = (payload: TabDetails) => {
  return action(MyTabActionTypes.FETCH_TAB_DETAILS_SUCCESS, payload);
};

export const fetchTabDetailsError = () => {
  return action(MyTabActionTypes.FETCH_TAB_DETAILS_ERROR);
};

export const resetMyTab = () => {
  return action(MyTabActionTypes.RESET_MY_TAB);
};
