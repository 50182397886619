import React, { useEffect, useMemo, useState } from 'react';

import br from 'date-fns/locale/pt-BR';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { format, addDays } from 'date-fns';
import { RiStarLine } from 'react-icons/ri';
import { useModalContext } from 'contexts/modal';

import { convertToCurrency } from '~/utils';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { LoyaltyRulesModal, CompleteProgramModal } from '~/components';
import { useLoyaltyProgram, useTranslator, useUserAuthenticationStatus } from '~/hooks';

import * as S from './styles';

export interface LoyaltyProps {
  userOrders: number;
  discountValue: number;
  programOrders: number;
  isLoyaltyProgramCompleted: boolean;
}

const Loyalty = ({
  userOrders,
  discountValue,
  programOrders,
  isLoyaltyProgramCompleted = false
}: LoyaltyProps): JSX.Element => {
  const history = useRouter();

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const { loyaltyProgram } = useSelector((state: IApplicationState) => state.establishment);
  const { customerLoyaltyProgram } = useSelector((state: IApplicationState) => state.user.data);

  const { getTranslation } = useTranslator();
  const { modalHasPreviewed, setModalHasPreviewed } = useModalContext();

  const width =
    (typeof document !== 'undefined' && document.getElementById('loyalty-info-container')?.offsetWidth) || 50;
  const loyaltyItems = Array.from(Array(programOrders).keys());

  const [loyaltyRulesModalIsOpen, setLoyaltyRulesModalIsOpen] = useState(false);
  const [completeProgramModalIsOpen, setCompleteProgramModalIsOpen] = useState(false);

  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const { readableChannelNames, isLoyaltyTemporarilyBlocked } = useLoyaltyProgram();

  const expirationDate: string = useMemo(() => {
    const parameterDate = customerLoyaltyProgram?.program_fulfilled_at || customerLoyaltyProgram?.first_order_at;

    if (parameterDate) {
      return format(addDays(new Date(parameterDate), loyaltyProgram?.daysForFulfilling || 0), 'dd/MM/yyyy', {
        locale: br
      });
    }

    return `${loyaltyProgram?.daysForFulfilling || 0} ${getTranslation('general.days')}`;
  }, [
    getTranslation,
    loyaltyProgram?.daysForFulfilling,
    customerLoyaltyProgram?.first_order_at,
    customerLoyaltyProgram?.program_fulfilled_at
  ]);

  const gaTrackEvent = (gaEvent: gaEvents): void => {
    return GoogleAnalytics.trackEvent(gaEvent, {
      // eslint-disable-next-line camelcase
      establishment_id: settings?.id
    });
  };

  const handleChangeModalVisibility = (): void => {
    setLoyaltyRulesModalIsOpen(!loyaltyRulesModalIsOpen);
  };

  const handleSignIn = (): void => {
    gaTrackEvent(gaEvents.loyaltySignIn);

    history.push('/auth');
  };

  useEffect(() => {
    if (programOrders === userOrders && !modalHasPreviewed) {
      setModalHasPreviewed(true);
      setCompleteProgramModalIsOpen(true);
    }
  }, [isLoyaltyProgramCompleted, modalHasPreviewed, programOrders, setModalHasPreviewed, userOrders]);

  return (
    <React.Fragment>
      {!isLoyaltyProgramCompleted ? (
        <>
          <S.BannerContainer
            aria-label="Abrir fidelidade"
            onClick={(): void => {
              gaTrackEvent(gaEvents.menuLoyaltyInfo);
              handleChangeModalVisibility();
            }}
          >
            <S.TopContainer>
              <S.SvgWrapper>
                <RiStarLine color={theme.colors.backgroundColor} size={16} />
              </S.SvgWrapper>

              <S.Content id="loyalty-info-container">
                {isUserAuthenticated ? (
                  <div>
                    <p>
                      {getTranslation('loyalty.orderAndEarn', {
                        programOrders,
                        discountValue: convertToCurrency(discountValue)
                      })}
                    </p>

                    <S.BottomContainer>
                      {loyaltyItems.map((_, index) => (
                        <S.ProgramItem
                          width={width / programOrders}
                          key={`loyalty-item-${index}`}
                          isDone={userOrders - index > 0}
                        ></S.ProgramItem>
                      ))}
                    </S.BottomContainer>
                  </div>
                ) : (
                  <div>
                    <span>{getTranslation('loyalty.andParticipateOf')}</span>
                    <p>{getTranslation('loyalty.loyaltyProgram')}!</p>
                  </div>
                )}
              </S.Content>
            </S.TopContainer>
          </S.BannerContainer>

          {loyaltyProgram && (
            <LoyaltyRulesModal
              handleSignIn={handleSignIn}
              expirationDate={expirationDate}
              isOpen={loyaltyRulesModalIsOpen}
              description={loyaltyProgram.description}
              channelNames={readableChannelNames || ''}
              isAuthenticated={isUserAuthenticated || false}
              dueDate={loyaltyProgram.daysForFulfilling || 0}
              orderIntervalHours={loyaltyProgram.orderIntervalHours || 1}
              onClose={(): void => {
                gaTrackEvent(gaEvents.loyaltyBackMenu);
                handleChangeModalVisibility();
              }}
            />
          )}
        </>
      ) : (
        <CompleteProgramModal
          discountValue={discountValue}
          expirationDate={expirationDate}
          onClose={(): void => setCompleteProgramModalIsOpen(false)}
          isOpen={completeProgramModalIsOpen && !isLoyaltyTemporarilyBlocked}
        />
      )}
    </React.Fragment>
  );
};

export default Loyalty;
