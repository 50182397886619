import { AnyAction } from 'redux';
import { all, takeLatest, put } from 'redux-saga/effects';

import { sendErrorLog } from '~/services';

import { ErrorLogActionTypes } from './types';
import { sendLogError, sendLogSuccess } from './actions';

export function* sendErrorLogRequest({ payload }: AnyAction) {
  try {
    yield sendErrorLog(payload);

    yield put(sendLogSuccess());
  } catch (error) {
    console.error((error as Error)?.message);

    yield put(sendLogError());
  }
}

export default all([takeLatest(ErrorLogActionTypes.SEND_LOG_REQUEST, sendErrorLogRequest)]);
