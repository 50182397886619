import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';

import QrCodeScanner from 'qr-scanner';
import { isMobile } from 'react-device-detect';
import { RiCameraLine, RiCameraSwitchLine } from 'react-icons/ri';
import { useLocalStorage } from '@goomerdev/goomer-toolbox/src/hooks';

import { useTranslator } from '~/hooks';
import { LocalOrdersEnum } from '~/interfaces/enums';

import GoomerExperience from '../GoomerExperience';

import * as S from './styles';

enum SecondaryCameraKeywordsListEnum {
  tele = 'tele',
  zoom = 'zoom',
  wide = 'wide',
  zoom_2x = '2x',
  zoom_3x = '3x',
  zoom_5x = '5x',
  ultra = 'ultra',
  macro = 'macro',
  zoom_10x = '10x',
  zoom_05x = '0.5x',
  zoom_06x = '0.6x',
  telephoto = 'telephoto'
}

enum BackCameraKeywordsEnum {
  back = 'back',
  rear = 'rear',
  traseira = 'traseira',
  environment = 'environment'
}

enum FrontCameraKeywordsEnum {
  user = 'user',
  front = 'front',
  selfie = 'selfie',
  frontal = 'frontal'
}

interface QrCodeReaderProps {
  onClose: () => void;
  qrCodeMode: LocalOrdersEnum;
  onConfirm?: (scanResult: string) => void;
}

const QrCodeReader = ({ onClose, onConfirm, qrCodeMode }: QrCodeReaderProps): JSX.Element => {
  const scannerRef = useRef<QrCodeScanner | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [scanRegionHeight, setScanRegionHeight] = useState<number>(0);
  const [isChangingCamera, setIsChangingCamera] = useState<boolean>(false);
  const [camerasList, setCamerasList] = useState<QrCodeScanner.Camera[]>([]);
  const [currentSelectedCamera, setCurrentSelectedCamera] = useState<QrCodeScanner.Camera>();

  const { setLocalStorageValue } = useLocalStorage({ key: 'qrCodeHashReader' });
  const { getTranslation } = useTranslator();

  const onScanSuccess = useCallback(
    (result: QrCodeScanner.ScanResult): void => {
      if (qrCodeMode === LocalOrdersEnum.guestCheck) {
        setLocalStorageValue(JSON.stringify({ date: new Date(), result: String(result?.data) }));
      }

      scannerRef?.current?.stop();

      onClose();
      onConfirm?.(String(result?.data));
    },
    [onClose, onConfirm, qrCodeMode, setLocalStorageValue]
  );

  const onScanFail = useCallback((error: string | Error) => {
    console.error('Erro ao escanear QR Code:', `"${error}"`);

    const height: number = document.getElementsByClassName('scan-region-highlight')[0]?.clientHeight || 0;
    setScanRegionHeight(height);
  }, []);

  const hasCamera: Promise<boolean> = useMemo(async () => QrCodeScanner.hasCamera(), []);

  const camerasByLabelList = useCallback(
    (isCurrentBackCamera: boolean) => {
      return camerasList.find((camera) => {
        const label = camera.label.toLowerCase();
        return isCurrentBackCamera
          ? Object.values(FrontCameraKeywordsEnum).some((frontCamera) => label.includes(frontCamera))
          : Object.values(BackCameraKeywordsEnum).some((backCamera) => label.includes(backCamera));
      });
    },
    [camerasList]
  );

  const handleChangeCamera = useCallback(async () => {
    if (isChangingCamera || !scannerRef.current || camerasList.length < 2) {
      return;
    }

    try {
      setIsChangingCamera(true);

      scannerRef.current.stop();

      // Pequeno delay para garantir que o scanner foi completamente parado
      await new Promise((resolve) => setTimeout(resolve, 300));

      const currentCamera = currentSelectedCamera;
      const currentLabel = currentCamera?.label.toLowerCase() || '';

      // Verifica se a câmera atual é traseira baseado em todas as possíveis palavras-chave
      const isCurrentBack = Object.values(BackCameraKeywordsEnum).some((keyword) => currentLabel.includes(keyword));

      const desiredFacingMode = isCurrentBack ? 'user' : 'environment';

      try {
        // Primeiro tenta usar facingMode diretamente
        await scannerRef.current.setCamera(desiredFacingMode);

        const newCamera = camerasByLabelList(isCurrentBack);

        if (newCamera) {
          setCurrentSelectedCamera(newCamera);
        }

        await scannerRef.current.start();
      } catch (cameraError) {
        console.error('Erro ao trocar de câmera, tentando por ID:', cameraError);

        // Fallback: tenta encontrar a câmera pelo label
        const nextCamera = camerasByLabelList(isCurrentBack);

        if (nextCamera) {
          await scannerRef.current.setCamera(nextCamera.id);
          setCurrentSelectedCamera(nextCamera);
        } else {
          // Se não encontrou câmera pelo label, tenta usar a próxima na lista
          const currentIndex = camerasList.findIndex((camera) => camera.id === currentCamera?.id);
          const nextIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % camerasList.length;
          const fallbackCamera = camerasList[nextIndex];

          await scannerRef.current.setCamera(fallbackCamera.id);
          setCurrentSelectedCamera(fallbackCamera);
        }

        await scannerRef.current.start();
      }
    } catch (error) {
      console.error('Erro geral na troca de câmera:', error);
      try {
        if (scannerRef.current) {
          await scannerRef.current.start();
        }
      } catch (restartError) {
        console.error('Erro ao reiniciar scanner:', restartError);
      }
    } finally {
      setIsChangingCamera(false);
    }
  }, [isChangingCamera, camerasList, currentSelectedCamera, camerasByLabelList]);

  const filterMainCameras = useCallback((cameras: QrCodeScanner.Camera[]): QrCodeScanner.Camera[] => {
    if (!cameras || cameras.length === 0) return [];

    // Palavras-chave que indicam câmeras principais
    const mainCameraKeywordsList = {
      back: Object.values(BackCameraKeywordsEnum),
      front: Object.values(FrontCameraKeywordsEnum)
    };

    // Primeiro, filtra câmeras que parecem ser secundárias
    const filteredCamerasList = cameras.filter((camera) => {
      const label = camera.label.toLowerCase();

      const isSecondaryCamera = Object.values(SecondaryCameraKeywordsListEnum).some((keyword) =>
        label.includes(keyword)
      );

      return !isSecondaryCamera;
    });

    if (filteredCamerasList.length > 2) {
      const mainCamerasList: QrCodeScanner.Camera[] = [];

      const backCamera = filteredCamerasList.find((camera) => {
        const label = camera.label.toLowerCase();
        return mainCameraKeywordsList.back.some((keyword) => label.includes(keyword));
      });

      const frontCamera = filteredCamerasList.find((camera) => {
        const label = camera.label.toLowerCase();
        return mainCameraKeywordsList.front.some((keyword) => label.includes(keyword));
      });

      if (backCamera) mainCamerasList.push(backCamera);
      if (frontCamera) mainCamerasList.push(frontCamera);

      if (mainCamerasList.length > 0) {
        return mainCamerasList;
      }
    }

    return filteredCamerasList;
  }, []);

  useEffect(() => {
    const initQrCodeScannerInstance = async (): Promise<void> => {
      if (videoRef.current && !scannerRef.current && (await hasCamera)) {
        const scanner = new QrCodeScanner(videoRef.current, onScanSuccess, {
          onDecodeError: onScanFail,
          highlightScanRegion: true,
          highlightCodeOutline: false,
          returnDetailedScanResult: true
        });

        const allCamerasList = await QrCodeScanner.listCameras(true);

        const filteredCamerasList = filterMainCameras(allCamerasList);

        setCamerasList(filteredCamerasList);

        const backCamera = filteredCamerasList.find((camera) => {
          const label = camera.label.toLowerCase();
          return Object.values(BackCameraKeywordsEnum).some((keyword) => label.includes(keyword));
        });

        if (backCamera) {
          setCurrentSelectedCamera(backCamera);
          await scanner.setCamera(backCamera.id);
        }

        await scanner.start();

        scannerRef.current = scanner;
      }
    };

    initQrCodeScannerInstance();

    return () => {
      scannerRef.current?.stop();
    };
  }, [filterMainCameras, hasCamera, onScanFail, onScanSuccess]);

  return (
    <S.Wrapper $scanRegionHeight={scanRegionHeight}>
      <video className="qr-video" ref={videoRef} />

      <div className="goomer-overlay">
        {isMobile && (
          <>
            <div className="header">
              <RiCameraLine size={32} />

              <h1
                dangerouslySetInnerHTML={{
                  __html: getTranslation('qrCodeScanner.aimYourPhoneCamera', { qrCodeMode: getTranslation(qrCodeMode) })
                }}
              />
            </div>

            <GoomerExperience />
          </>
        )}
      </div>

      {camerasList.length > 1 && (
        <div
          className={`camera-switch-icon ${isChangingCamera ? 'disabled' : ''}`}
          onClick={isChangingCamera ? undefined : async (): Promise<void> => handleChangeCamera()}
        >
          <RiCameraSwitchLine size={isMobile ? 40 : 48} />
        </div>
      )}
    </S.Wrapper>
  );
};

export default QrCodeReader;
