import { api } from '~/services/api';
import { Address } from '~/interfaces/general';
import getFormattedBirthdayApi from '~/utils/getFormattedBirthdayApi';

interface FetchCustomerInfoParams {
  token: string;
  storeId: number;
}

export async function fetchCustomerInfo({ token, storeId }: FetchCustomerInfoParams): Promise<any> {
  try {
    const response = await api.get(`/stores/${storeId}/customer`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    throw new Error((error as Error)?.message);
  }
}

interface UpdateCustomerInfoParams {
  name: string;
  optIn: boolean;
  storeId: number;
  birthday?: string;
}

export async function updateCustomerInfo({ name, optIn, storeId, birthday }: UpdateCustomerInfoParams): Promise<any> {
  const formatedBirthday = getFormattedBirthdayApi(birthday || '', '/', '-');

  const body = {
    name: name,
    opt_in: optIn,
    birthday: formatedBirthday
  };

  try {
    const response = await api.put(`/stores/${storeId}/customer`, body);

    return response.data;
  } catch (error) {
    throw new Error((error as Error)?.message);
  }
}

interface AddCustomerAddressParams {
  storeId: number;
  address: Address;
}

export async function addCustomerAddress({ storeId, address }: AddCustomerAddressParams): Promise<any> {
  try {
    const response = await api.post(`/stores/${storeId}/customer/addresses`, { ...address });

    return response.data;
  } catch (error) {
    throw new Error((error as Error)?.message);
  }
}

interface UpdateCustomerAddressParams {
  storeId: number;
  address: Address;
}

export async function updateCustomerAddress({ storeId, address }: UpdateCustomerAddressParams): Promise<any> {
  try {
    const response = await api.put(`/stores/${storeId}/customer/addresses/${address.id}`, { ...address });

    return response.data;
  } catch (error) {
    throw new Error((error as Error)?.message);
  }
}

interface DeleteCustomerAddressParams {
  storeId: number;
  addressId: string;
}

export async function deleteCustomerAddress({ storeId, addressId }: DeleteCustomerAddressParams): Promise<any> {
  try {
    const response = await api.delete(`/stores/${storeId}/customer/addresses/${addressId}`);

    return response.data;
  } catch (error) {
    throw new Error((error as Error)?.message);
  }
}
