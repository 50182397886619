import { NextRouter } from 'next/router';

import GoogleAnalytics, { gaErrors } from '../analytics';
import { TypeError } from '../analytics/utils/trackError';

interface INotFoundArguments {
  history: NextRouter;
  gaTrack: TypeError;
}

export const goToNotFoundPage = ({ history, gaTrack }: INotFoundArguments) => {
  GoogleAnalytics.trackError(gaErrors.notFoundError, gaTrack);

  setTimeout(() => {
    history?.push('/404');
  }, 100);
};
