import React from 'react';

import * as S from './styles';

const SERVICE_TERMS = process.env.NEXT_PUBLIC_SERVICE_TERMS || '';

const TermsText = (): JSX.Element => {
  return (
    <S.TermsTextContainer>
      <iframe src={SERVICE_TERMS} frameBorder="0" allowFullScreen />
    </S.TermsTextContainer>
  );
};

export default TermsText;
