import { useCallback } from 'react';

import { removeNoNumbers } from '~/utils';
import { DeliveryPricingEnum } from '~/interfaces/enums';
import { IDeliveryAverageTime } from '~/interfaces/general';
import { MountBadgeReturnProps } from '~/hooks/useMountBadge';
import { useConvertToCurrencyOrFree, useMountBadge, useTranslator } from '~/hooks';

interface GetInfosProps {
  deliveryFee: string;
  takeawayTime: string;
  takeawayDiscount: string;
  isDeliveryFeeEnabled: boolean;
  isFreeDeliveryEnabled: boolean;
  isTakeawayDiscountEnabled: boolean;
  hideDeliveryAndTakeawayTime?: boolean;
  deliveryAverageTime: IDeliveryAverageTime;
}

interface OptionItems {
  name?: string;
  info?: string;
  value?: string;
  description: string;
  'data-test'?: string;
  badge?: MountBadgeReturnProps;
}

type OptionLabel = Record<string, OptionItems>;

const useGetPaymentInfo = () => {
  const { mountBadge } = useMountBadge();
  const { getTranslation } = useTranslator();
  const { convertToCurrencyOrFree } = useConvertToCurrencyOrFree();

  const formatAverageTime: (deliveryAverageTime: IDeliveryAverageTime) => string = useCallback(
    (deliveryAverageTime) => {
      if (!deliveryAverageTime?.from && !deliveryAverageTime?.to) {
        return '';
      }

      let range = '';

      if (deliveryAverageTime?.to) {
        range = `${deliveryAverageTime.to}`;
      }

      if (deliveryAverageTime?.from) {
        const timeFrom = deliveryAverageTime?.from;

        if (timeFrom !== deliveryAverageTime?.to && timeFrom !== 0) {
          range = `${deliveryAverageTime.from}-${range}`;
        }
      }

      return `(${range}${getTranslation('fee.minute')})`.trim();
    },
    [getTranslation]
  );

  const getInfos: (props: GetInfosProps) => OptionLabel = useCallback(
    ({
      deliveryFee,
      takeawayTime,
      takeawayDiscount,
      deliveryAverageTime,
      isDeliveryFeeEnabled,
      isFreeDeliveryEnabled,
      isTakeawayDiscountEnabled,
      hideDeliveryAndTakeawayTime = false
    }) => {
      const isFree = isFreeDeliveryEnabled || deliveryFee === DeliveryPricingEnum.free || deliveryFee === '0';
      const isOutrange = deliveryFee === DeliveryPricingEnum.outside || deliveryFee === DeliveryPricingEnum.notFound;

      const hasTakeawayTime = takeawayTime.length > 0 && Number(takeawayTime) > 0;
      const takeawayTimeDescription =
        !hideDeliveryAndTakeawayTime && hasTakeawayTime
          ? `(${removeNoNumbers(takeawayTime)}${getTranslation('fee.minute')})`
          : '';

      const deliveryTimeDescription = `${hideDeliveryAndTakeawayTime ? '' : formatAverageTime(deliveryAverageTime)}`;

      const getDeliveryBadge = (): MountBadgeReturnProps | undefined => {
        if (isFree) {
          return { type: 'success', message: getTranslation('fee.free') };
        }

        if (isDeliveryFeeEnabled) {
          if (!isNaN(Number(deliveryFee))) {
            return { type: 'default', message: convertToCurrencyOrFree(Number(deliveryFee)) };
          }

          if (!isOutrange) {
            return { type: 'default', message: getTranslation('fee.toBeAgreed') };
          }
        }

        return undefined;
      };

      return {
        mm_payment_cash_enabled: { description: getTranslation('payment.money') },
        mm_payment_debit_enabled: { description: getTranslation('payment.debit') },
        mm_payment_credit_enabled: { description: getTranslation('payment.credit') },
        mm_payment_mpago_qrcode_enabled: { description: getTranslation('payment.mercadoPago') },
        mm_takeaway_enabled: {
          name: 'takeaway',
          'data-test': 'opt-takeaway',
          info: takeawayTimeDescription,
          description: `${getTranslation('fee.localPickUp')}`,
          badge: isTakeawayDiscountEnabled
            ? mountBadge({ value: '??', option: 'retirada', discount: Number(takeawayDiscount) })
            : undefined
        },
        mm_delivery_enabled: {
          name: 'delivery',
          badge: getDeliveryBadge(),
          'data-test': 'opt-delivery',
          info: deliveryTimeDescription,
          description: `${getTranslation('address.delivery')}`
        }
      };
    },
    [convertToCurrencyOrFree, formatAverageTime, getTranslation, mountBadge]
  );

  return { getInfos };
};

export default useGetPaymentInfo;
