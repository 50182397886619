import { store } from '~/redux-tools';

interface IntlCurrencyProps {
  code: string;
  locale: string;
  value: number | string;
}

function intlCurrency({ code, value, locale }: IntlCurrencyProps) {
  const numberValue = Number(value);

  const parsedValue = new Intl.NumberFormat(locale, { currency: code, style: 'currency' }).format(numberValue);

  return parsedValue;
}

function changeDotToComma(value: number) {
  return Number(value)
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export default function convertToCurrency(value: number) {
  const symbol = store.getState().establishment.settings?.mm_currency?.symbol;
  const code = store.getState().establishment.settings?.mm_currency?.code || '';
  const locale = store.getState().establishment.settings?.mm_currency?.locale || '';

  if (symbol && locale && code) {
    const parsedValue = intlCurrency({ code, value, locale });

    return parsedValue;
  }

  return `R$ ${changeDotToComma(value)}`;
}
