import React, { useEffect, useMemo, useRef } from 'react';

import Head from 'next/head';
import { Provider } from 'react-redux';
import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';

import { store } from '~/redux-tools';
import GlobalStyles from '~/styles/global';
import { GoogleTagManager, Application } from '~/components';
import { IEstablishmentSettings } from '~/interfaces/general';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { useAzionNetInfo, useHasGoomerDomain, usePwaInstallPrompt } from '~/hooks';

const SITE_URL = process.env.NEXT_PUBLIC_PUBLIC_URL;

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const isFirstRender = useRef(true);

  usePwaInstallPrompt({});

  const { handleAzionNetInfo } = useAzionNetInfo();
  const hasGoomerDomain: boolean = useHasGoomerDomain();

  const url = SITE_URL && !!pageProps?.slug ? SITE_URL.replace('slug', pageProps?.slug) : '';

  const establishment: IEstablishmentSettings = useMemo(() => pageProps?.settings, [pageProps.settings]);

  const address = useMemo(() => (establishment?.address?.city ? establishment?.address?.city : ''), [establishment]);

  const title = useMemo(() => {
    if (typeof window !== 'undefined' && window.location.href.includes('clube')) {
      return 'Goomer - Clube de Vantagens';
    }

    if (typeof window !== 'undefined' && window.location.href.includes('checkout')) {
      return 'Goomer - Checkout';
    }

    return establishment
      ? `${establishment?.name} - Cardápio e Delivery ${address ? `em ${address}` : ''}`
      : 'Cardápio e Delivery';
  }, [address, establishment]);

  const description = useMemo(
    () =>
      establishment
        ? `Faça seu pedido online no ${establishment?.name}, ${establishment?.specialty_name} ${
            address ? `em ${address}. Acesse e peça agora pelo delivery Goomer!` : ''
          }`
        : 'Confira nosso cardápio digital online e faça seu pedido. É fácil e super interativo!',
    [establishment, address]
  );

  const logo = useMemo(() => {
    if (establishment?.mm_resized_logos?.logo_300x300?.url) {
      return establishment?.mm_resized_logos?.logo_300x300.url;
    }

    return '/assets/goomergo-meta.png';
  }, [establishment]);

  const logo180 = useMemo(() => {
    if (establishment?.mm_resized_logos?.logo_180x180?.url) {
      return establishment?.mm_resized_logos?.logo_180x180.url;
    }

    return '/img/favicon.png';
  }, [establishment]);

  useEffect(() => {
    if (isFirstRender.current) {
      if (process.env.NEXT_PUBLIC_ENV === 'production') {
        handleAzionNetInfo();
      }

      if (!hasGoomerDomain) {
        GoogleAnalytics.trackEvent(gaEvents.establishmentOwnDomain, {
          establishment_id: establishment?.id,
          hostName: typeof window !== 'undefined' ? window.location.hostname : ''
        });
      }
    }

    isFirstRender.current = false;
  }, [establishment?.id, hasGoomerDomain, handleAzionNetInfo]);

  return (
    <Provider store={store}>
      <Application>
        <Head>
          <title>{title}</title>

          <link rel="shortcut icon" href={logo180} />
          <link sizes="180x180" rel="apple-touch-icon" href={logo180} />
          <link rel="manifest" href="/manifest.json" />

          <link href="https://fonts.googleapis.com/css?family=Manrope:500,600,700,800&display=swap" rel="stylesheet" />

          <meta name="next-head-count" content="5" />

          <meta name="google" content="notranslate" />
          <meta name="viewport" content="width=device-width, user-scalable=no" />

          <meta name="theme-color" content={establishment?.mm_main_color || '#4a62d2'} />
          <meta name="apple-mobile-web-app-status-bar-style" content={establishment?.mm_main_color || '#4a62d2'}></meta>
          <meta name="msapplication-navbutton-color" content={establishment?.mm_main_color || '#4a62d2'}></meta>

          <meta name="mobile-web-app-capable" content="yes" />
          <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
          <meta httpEquiv="Pragma" content="no-cache" />
          <meta httpEquiv="Expires" content="0" />

          <meta name="description" content={description} />
          <meta name="facebook-domain-verification" content={establishment?.mm_facebook_pixel_dns_code} />

          <meta name="robots" content="index, follow" />
          <meta property="og:site_name" content={title} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" itemProp="image" content={logo} />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={url} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
          <meta property="twitter:image" content={logo} />

          <GoogleTagManager />

          <script src="https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js"></script>

          <link itemProp="thumbnailUrl" href={logo} />
          <span itemProp="thumbnail" itemScope itemType="http://schema.org/ImageObject">
            <link itemProp="url" href={logo} />
          </span>
        </Head>

        <GlobalStyles />

        <Component {...pageProps} />

        <ToastContainer autoClose={3000} />
      </Application>
    </Provider>
  );
};

export default App;
