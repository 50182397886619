import { RiCheckboxCircleLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';

import * as S from './styles';

const PaymentStatusTag: () => JSX.Element = () => {
  const { getTranslation } = useTranslator();

  return (
    <S.Container>
      <RiCheckboxCircleLine size={20} />

      <span>{getTranslation('myTab.accountPaid')}</span>
    </S.Container>
  );
};

export { PaymentStatusTag };
