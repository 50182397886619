export default function removeSpecialCharacters(input: string): string {
  const specialCharacterList = ['>', '<', '&', '"', "'"];

  let result = input;

  specialCharacterList.forEach((char) => {
    result = result.replace(new RegExp(char, 'g'), '');
  });

  return result;
}
