import { useSelector } from 'react-redux';

import { ProductType } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';

export default function useCheckProvider() {
  const { mode, is_abrahao } = useSelector((state: IApplicationState) => state.localOrders);

  const isAbrahao = !!is_abrahao;
  const provider: ProductType = !mode ? ProductType.ggo : ProductType.gnl;

  return { provider, isAbrahao };
}
