import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { FeaturesEnum } from '~/interfaces/enums';
import { useMenuGroups } from '~/hooks/useMenu/hooks';
import { IApplicationState } from '~/redux-tools/store';
import useHasFeatureAccess from '~/hooks/useHasFeatureAccess';
import {
  Address,
  CartValues,
  CartProduct,
  CartOptional,
  CartProductItem,
  DeliveryFeeStatic,
  CartOptionalGroup,
  DeliveryFeeDynamic,
  HandlePageReloadProps,
  DeliveryFeeNeighborhood
} from '~/interfaces/general';

interface UseCheckoutCartInfo {
  isTakeaway: boolean;
}

export default function useCheckoutCartInfo({ isTakeaway }: UseCheckoutCartInfo) {
  const cart = useSelector((state: IApplicationState) => state.cart);

  const { getGroupByProductId } = useMenuGroups();
  const [hasNewDeliveryAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.NewDeliveryMenu });

  const cartInfo: () => {
    address?: Address;
    total: number;
    lastUpdate?: Date;
    values: CartValues;
    order_version: number;
    establishment: string;
    products: CartProduct[];
    hasNewProductOnBag: boolean;
    preventPageReload?: HandlePageReloadProps;
    deliveryFee?: DeliveryFeeDynamic | DeliveryFeeStatic | DeliveryFeeNeighborhood;
  } = useCallback(() => {
    let cartProductList = cart.products;

    if (hasNewDeliveryAccess) {
      const mountProductOptionalList = (optionalTreeList: CartOptionalGroup[]): CartOptional[] => {
        const formatGroup: (groupItem: CartOptionalGroup) => CartOptional[] = (groupItem) => {
          return groupItem.options.map((optionalItem) => {
            let flowOptionals: CartOptional[][] = [];

            if (!!optionalItem.attributes && optionalItem.attributes.length > 0) {
              flowOptionals = optionalItem.attributes.map((optionalAttributes) => formatGroup(optionalAttributes));
            }

            return {
              ...optionalItem,
              attributes: undefined,
              optionals: flowOptionals.flat()
            };
          });
        };

        const optionalGroupList = optionalTreeList.map((group) => formatGroup(group));

        return optionalGroupList.flat();
      };

      const productsWithOptionalTree = cart.products.map((product) => {
        return {
          ...product,
          optionals: mountProductOptionalList(product.optionalTree || [])
        };
      });

      cartProductList = productsWithOptionalTree;
    }

    if (isTakeaway) {
      return {
        ...cart,
        address: undefined,
        deliveryFee: undefined,
        products: cartProductList
      };
    }

    return {
      ...cart,
      products: cartProductList,
      values: {
        ...cart.values,
        takeaway_discount: 0
      }
    };
  }, [cart, hasNewDeliveryAccess, isTakeaway]);

  const cartProducts = useMemo<CartProductItem[]>(
    () =>
      cart?.products?.map((item) => {
        const group = item.product ? getGroupByProductId(Number(item.product.id)) : undefined;

        return {
          id: item.product.id,
          quantity: item.quantity,
          name: item.product.name,
          price: item.product.price,
          category: group?.name || '',
          category_id: group?.id || 0,
          limit_age: item.product.limit_age,
          description: item.product.description,
          productReferenceID: item.productReferenceID
        };
      }),
    [cart?.products, getGroupByProductId]
  );

  const numberOfCartItems = useCallback(() => {
    let numItems = 0;

    cart?.products?.forEach((product) => {
      numItems += product.quantity;
    });

    return numItems;
  }, [cart?.products]);

  const productsIdsAndNames = useCallback(() => {
    const ids: any = [];
    const names: any = [];

    cart.products.forEach((product) => {
      ids.push(product.code);
      names.push(product.product.name);
    });

    return [ids, names];
  }, [cart?.products]);

  return { cartInfo, cartProducts, numberOfCartItems, productsIdsAndNames };
}
