export default function getHoursAndMinutesFromLabel(label: string):
  | {
      hour: number;
      minutes: number;
    }
  | false {
  const parsedLabelList = label.split(':');

  if (!parsedLabelList || parsedLabelList.length !== 2) return false;

  const [hour, minutes] = parsedLabelList;

  return { hour: Number(hour), minutes: Number(minutes) };
}
