import styled, { css } from 'styled-components';

import { Field } from '../../styles';

export const Container = styled.div`
  ${({ theme }) => css`
    .terms {
      margin-top: 12px;

      > div:first-child {
        padding: 0 !important;
      }
    }

    h4 {
      margin-bottom: 0.5rem;

      font-weight: 800;
      font-size: 1.5rem;
      line-height: 150%;
      color: ${theme.colors.textColor};
    }

    h5 {
      margin-bottom: 1.5rem;

      font-weight: 500;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.gray500};
    }
  `};
`;

export const StyledField = styled(Field)`
  margin-bottom: 1.5rem;
`;

export const Info = styled.div`
  ${({ theme }) => css`
    margin-top: 0.25rem;

    text-align: left;
    font-weight: 600;
    line-height: 150%;
    font-size: 0.75rem;

    color: ${theme.colors.gray500};
  `};
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    text-align: left;

    color: ${theme.colors.error};
  `};
`;
