import { NextRouter } from 'next/router';
import { browserName, mobileModel } from 'react-device-detect';

import { goToNotFoundPage } from '~/utils/goToNotFoundPage';
import convertParamsToObject from '~/utils/convertQueryParamsToObj';

interface HandleRedirectWithRetryProps {
  id?: number;
  errorInfo: string;
  errorMenu: string;
  history: NextRouter;
}

export default function handleRedirectWithRetry({
  id,
  history,
  errorInfo,
  errorMenu
}: HandleRedirectWithRetryProps): void {
  const params = convertParamsToObject(window.location.search);
  const isRetryAlreadyTriggered = Boolean(params.retry);

  if (!isRetryAlreadyTriggered) {
    const { href } = window.location;
    const location = href.substr(-1) === '/' ? href.substring(0, href.length - 1) : href;
    const retryParam =
      !!params && Object.keys(params).filter((param) => param).length >= 1 ? '&retry=true' : '?retry=true';

    window.location.assign(`${location}${retryParam || ''}`);
  } else {
    console.error(errorMenu);

    goToNotFoundPage({
      history,
      gaTrack: {
        error_extra_message: 'retry has already been triggered',
        error_message: `INFO: "${errorInfo}"\nMENU: "${errorMenu}"`,
        establishment_id: id,
        browser: browserName,
        phone_model: mobileModel
      }
    });
  }
}
