import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';

import { toast } from 'react-toastify';
import { RiLockLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { InfoCVVModal, Button } from '~/components';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { addUserCard } from '~/redux-tools/store/user/actions';
import { CardTypeEnum, ICard, ICardForm } from '~/interfaces/general';
import useCheckoutUserInfo from '~/hooks/useCheckout/hooks/useCheckoutUserInfo';
import { useFormatCreditCard, useGetOnlinePaymentToken, useHandlePaymentTexts, useTranslator } from '~/hooks';
import {
  InputRow,
  InputText,
  InputGroup,
  InputEmail,
  InputCardDue,
  InputCardCvv,
  InputCPForCNPJ,
  InputCardNumber
} from '~/components/pages/CardEntry';

import * as S from './styled';

interface IProps {
  isShow: boolean;
  isMyTab?: boolean;
  isNewCard?: boolean;
  isTunaPaymentEnabled: boolean;
  onClose: (newCard?: ICard) => any;
  initialData?: Omit<ICard, 'token'>;
}
const CardEntry: React.FC<IProps> = ({ isShow, onClose, isNewCard, initialData, isTunaPaymentEnabled }) => {
  const dispatch = useDispatch();
  const formRef = useRef(null) as any;
  const [data, setData] = useState<any>({});

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { getTranslation } = useTranslator();
  const { formatCreditCard } = useFormatCreditCard();
  const { handlePaymentTexts } = useHandlePaymentTexts();
  const { getOnlinePaymentToken } = useGetOnlinePaymentToken();
  const { isAuthenticated, authenticationToken } = useCheckoutUserInfo();

  useEffect(() => {
    const data = initialData
      ? {
          cvv: undefined,
          due: { isValid: true, value: initialData.due },
          name: { isValid: true, value: initialData.name },
          email: { isValid: true, value: initialData.email },
          number: { isValid: true, value: initialData.number },
          document: { isValid: true, value: initialData.document }
        }
      : {};
    setData(data);
  }, [initialData]);

  const validateForm = useCallback(() => {
    const { cvv, due, name, email, number, document } = data;

    return number?.isValid && due?.isValid && cvv?.isValid && name?.isValid && document?.isValid && email?.isValid;
  }, [data]);

  const handleSubmit = useCallback(
    async (values: ICardForm) => {
      if (!validateForm()) {
        toast.error(getTranslation('cardEntry.invalidData'));
        return;
      }

      const action = (currentCard: ICard) => {
        dispatch(
          addUserCard({
            ...currentCard,
            cardNumber: currentCard.number,
            establishment: settings?.name || ''
          })
        );
        onClose(isNewCard ? currentCard : undefined);
      };

      const cardInfos = {
        ...values,
        number: values.number || data.number.value
      };

      await getOnlinePaymentToken({
        action,
        card: cardInfos,
        isAuthenticated,
        authenticationToken,
        isTunaPaymentEnabled
      });

      GoogleAnalytics.trackEvent(gaEvents.saveCreditCard);
    },
    [
      data,
      onClose,
      dispatch,
      isNewCard,
      validateForm,
      settings?.name,
      getTranslation,
      isAuthenticated,
      authenticationToken,
      isTunaPaymentEnabled,
      getOnlinePaymentToken
    ]
  );

  const handleChangeField = useCallback(
    (fieldName: string) => {
      return (value: any, isValid: boolean) => {
        setData({ ...data, [fieldName]: { value, isValid } });
      };
    },
    [data]
  );

  const [showCvvHelper, setShowCvvHelper] = useState(false);

  const formattedCardType = useMemo(() => {
    switch (initialData?.type) {
      case 'mm_credit':
        return getTranslation('cardEntry.credit');
      case 'mm_debit':
        return getTranslation('cardEntry.debit');
      default:
        return getTranslation('cardEntry.credit');
    }
  }, [getTranslation, initialData?.type]);

  const headerTitleText = useMemo(
    () =>
      initialData
        ? getTranslation('cardEntry.editCard', { formattedCardType: formattedCardType })
        : getTranslation('cardEntry.newCard'),
    [initialData, getTranslation, formattedCardType]
  );

  const handleCardFlagDescription = useCallback(
    (type: CardTypeEnum | PaymentCategoryEnum, flag?: string) => {
      return `${handlePaymentTexts(type)} ${flag || ''}`;
    },
    [handlePaymentTexts]
  );

  return (
    <S.StyledModal
      isPageLike
      isShow={isShow}
      onClose={() => onClose()}
      headerTitle={headerTitleText}
      buttonSvgColor={theme.colors.pureTextColor}
    >
      {!isTunaPaymentEnabled && (
        <S.Flags>
          {!initialData && (
            <div className="by">
              <p>{getTranslation('cardEntry.safePayment')} </p>
              <LazyLoadImage src="/assets/checkout/mp-logo.svg" width={85} height={23} />
            </div>
          )}
          <LazyLoadImage src="/assets/checkout/payment-ways.svg" width={120} height={15} />
        </S.Flags>
      )}

      <S.FormContainer onSubmit={handleSubmit} ref={formRef}>
        <S.Content>
          {isTunaPaymentEnabled && (
            <S.ProtectAreaWrapper>
              <RiLockLine size={20} />

              <S.ProtectAreaText>{getTranslation('cardEntry.protectedArea')}</S.ProtectAreaText>
            </S.ProtectAreaWrapper>
          )}

          <InputRow className="sentry-mask">
            <InputGroup
              label={getTranslation('payment.cardNumber')}
              helper={<LazyLoadImage width={16} height={16} src="/assets/locker.svg" />}
            >
              {initialData ? (
                <big>
                  {formatCreditCard({
                    card: initialData.number,
                    isCreditCardNumberHidden: isTunaPaymentEnabled,
                    flag: handleCardFlagDescription(initialData.type, initialData.mpMethodId)
                  })}
                </big>
              ) : (
                <InputCardNumber dataTest="input-card-number" onChange={handleChangeField('number')} />
              )}
            </InputGroup>
          </InputRow>

          <InputRow className="sentry-mask">
            <InputGroup label={getTranslation('payment.expiryDate')}>
              <InputCardDue
                dataTest="input-card-validity"
                defaultValue={initialData?.due}
                onChange={handleChangeField('due')}
              />
            </InputGroup>

            <InputGroup
              label={getTranslation('payment.securityCardCode')}
              helper={
                <LazyLoadImage
                  width={16}
                  height={16}
                  src="/assets/checkout/question-circle.svg"
                  onClick={(): void => setShowCvvHelper(true)}
                />
              }
            >
              <InputCardCvv data-test="input-card-cvv" onChange={handleChangeField('cvv')} />
            </InputGroup>
          </InputRow>

          <InputRow className="sentry-mask">
            <InputGroup label={getTranslation('payment.cardholderName')}>
              <InputText
                textOnly
                name="name"
                dataTest="input-holder-name"
                defaultValue={initialData?.name}
                placeholder={getTranslation('general.insertYourName')}
                onChange={(value) => handleChangeField('name')(value, !!value?.length)}
              />
            </InputGroup>
          </InputRow>

          <InputRow className="sentry-mask">
            <InputGroup label={getTranslation('payment.cardholderDocument')}>
              <InputCPForCNPJ
                dataTest="input-cpf-cnpj"
                defaultValue={initialData?.document}
                onChange={handleChangeField('document')}
              />
            </InputGroup>
          </InputRow>

          <InputRow className="sentry-mask">
            <InputGroup label={getTranslation('general.email')}>
              <InputEmail
                defaultValue={initialData?.email}
                onChange={(value, isValid): void => handleChangeField('email')(value, !!value?.length && isValid)}
              />
            </InputGroup>
          </InputRow>
        </S.Content>

        <S.Footer>
          <Button type="submit" disabled={!validateForm()} isGhost={false} data-test="btn-save">
            {getTranslation('payment.useCard')}
          </Button>
        </S.Footer>
      </S.FormContainer>

      <InfoCVVModal isShow={showCvvHelper} onClose={(): void => setShowCvvHelper(false)} />
    </S.StyledModal>
  );
};

export default CardEntry;
