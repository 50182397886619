import React, { useRef, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { RiErrorWarningLine } from 'react-icons/ri';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useTranslator } from '~/hooks';
import { QueryParam } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';

import { Container } from './styles';

export interface SimpleFlashMessageProps {
  hideTimeout?: number;
  isReturningUser?: boolean;
  positionFromBottom?: string;
  type: QueryParam.bag | QueryParam.welcome;
}

const SimpleFlashMessage: (props: SimpleFlashMessageProps) => JSX.Element = ({
  hideTimeout = 2000,
  type = QueryParam.bag,
  isReturningUser = false,
  positionFromBottom = '0'
}) => {
  const flashRef = useRef<HTMLDivElement>(null);

  const { name: username } = useSelector((state: IApplicationState) => state.user.data);

  const { getTranslation } = useTranslator();

  const firstName = useMemo(() => username?.split(' ')[0], [username]);

  useEffect(() => {
    setTimeout(() => {
      return flashRef.current?.classList.add('hide');
    }, hideTimeout);
  }, [hideTimeout]);

  const renderContent = {
    bag: (
      <>
        <RiErrorWarningLine size={24} />

        <figcaption className="description">{getTranslation('flashMessage.emptyBag')}</figcaption>
      </>
    ),
    welcome: (
      <>
        <LazyLoadImage
          src="/assets/emojis/waving-hand.svg"
          width={21}
          height={21}
          role="img"
          aria-label="Acenando com a mão"
          className="emoji"
        />

        <figcaption className="message">
          <strong>
            {getTranslation('general.greeting')}
            {`${isReturningUser ? ` ${getTranslation('general.again')}` : ''}`},
          </strong>
          <br />
          {firstName}!
        </figcaption>
      </>
    )
  };

  return (
    <Container ref={flashRef} type={type} positionFromBottom={positionFromBottom}>
      {renderContent[type]}
    </Container>
  );
};

export default SimpleFlashMessage;
