import styled, { css } from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const Container = Modal
  ? styled(Modal)`
      z-index: 1003;

      ${({ theme }) => css`
        .container {
          position: relative;

          height: 100vh;
          padding-bottom: 0;

          background: ${theme.colors.gray200};

          span {
            text-decoration: none;
          }

          > .title {
            border-bottom: 1px solid ${theme.colors.gray300};

            background: ${theme.colors.backgroundColor};
          }

          .content {
            padding-bottom: 4px;

            display: flex;
            flex-direction: column;

            > div {
              width: 100%;
            }
          }
        }
      `}
    `
  : styled.div<ModalProps>``;
