export enum gaEvents {
  orderStatusStartConversation = 'order_status_start_conversation',
  orderStatusReportAProblem = 'order_status_report_a_problem',
  orderStatusTimeline = 'order_status_timeline',

  sendOrderWhatsapp = 'send_order_wtp',
  resendOrderWhatsapp = 'resend_order_wtp',
  addToCart = 'add_to_cart',
  viewContent = 'view_content',
  goomerGoMenu = 'goomer_go_menu',
  goomerInStoreMenu = 'goomer_naloja_menu',
  about = 'about',
  search = 'search',
  acceptedSuggestion = 'accepted_suggestion',
  deniedSuggestion = 'denied_suggestion',

  ggoSignupConfirmCod = 'ggo_signup_confirm_code',
  ggoSignupOrder = 'ggo_signup_order',
  ggoSignupAcceptedTerm = 'ggo_signup_accepted_term',
  ggoSignupNotReceivedCode = 'ggo_signup_not_received_code',
  ggoSignupOpenTerm = 'ggo_signup_open_term',
  ggoSignupContinueWithoutVerification = 'ggo_signup_continue_without_verification',

  ggoPwaComeFromWhats = 'ggo_pwa_link_whats',
  ggoPwaInstall = 'ggo_pwa_add',
  ggoPwaNotInstall = 'ggo_pwa_not_add',
  ggoPwaOrder = 'ggo_pwa_order',
  ggoPwaIsRunningOnPwaMode = 'ggo_pwa_access',

  notFoundPageView = 'view_404_page',

  signInEnter = 'sign_in_enter',
  signInOut = 'sign_in_out',
  signInDone = 'sign_in_done',
  signInTypeEmail = 'sign_in_type_email',
  signInTypeGoogle = 'sign_in_type_google',
  signInTypeOut = 'sign_in_type_out',
  signInEmailOut = 'sign_in_email_out',
  signInEmailContinue = 'sign_in_email_continue',
  signInEmailCodeResend = 'sign_in_email_code_resend',
  signInEmailCodeOut = 'sign_in_email_code_out',
  signInEmailCodeDone = 'sign_in_email_code_done',
  signInEmailFill = 'sign_in_email_fill',
  signInCheckout = 'sign_in_checkout',
  toolbarSignIn = 'toolbar_sign_in',
  toolbarProfile = 'toolbar_profile',
  profileSignOut = 'profile_sign_out',
  googleAccount = 'google_account',

  orderFromDashboard = 'btn_order_experiment',

  gbotAccess = 'goomer_bot_access',

  menuLoyaltyInfo = 'menu_loyalty_info',
  loyaltySignIn = 'loyalty_sign_in',
  loyaltyBackMenu = 'loyalty_back_menu',

  initiateCheckout = 'initiate_checkout',
  checkoutBackHeader = 'checkout_back_header',
  addMoreItems = 'add_more_items',
  checkoutDeliveryWaySelected = 'checkout_delivery_way_selected',
  checkoutAddressConfirmed = 'checkout_address_confirmed',
  checkoutNameFilled = 'checkout_name_filled',
  checkoutPhoneFilled = 'checkout_phone_filled',
  checkoutPaymentSelected = 'checkout_payment_selected',
  purchase = 'purchase',

  crmWhatsappViewMenu = 'crm_whatsapp_view_menu',
  crmWhatsappOrderMenu = 'crm_whatsapp_order_menu',

  discountFirstInfo = 'discount_first_info',
  discountFirstSignIn = 'discount_first_sign_in',

  pixOrderRefusedForTheMinimumPrice = 'pix_order_refused_for_the_minimum_price',

  establishmentOwnDomain = 'establishment_own_domain',

  googlePayError = 'google_pay_error',
  googlePaySuccess = 'google_pay_success',
  sendWhatsAppCreditOrder = 'send_wtp_credit_order',
  sendWhatsAppGooglePayOrder = 'send_wtp_google_pay_order',
  sendOrderStatusCreditOrder = 'send_order_status_credit_order',
  sendOrderStatusGooglePayOrder = 'send_order_status_google_pay_order',
  sendOrderStatusIntegratedPixOrder = 'send_order_status_integrated_pix_order',

  whatsAppBackMenu = 'wtp_back_menu',
  saveCreditCard = 'save_credit_card',
  whatsAppShowQrCode = 'wtp_show_qr_code',
  selectedApplePay = 'selected_apple_pay',
  whatsAppCopyPixCode = 'wtp_copy_pix_code',
  selectedGooglePay = 'selected_google_pay',
  openPaymentMethods = 'open_payment_methods',
  sendWhatsAppPixOrder = 'send_wtp_pix_order',
  selectedCreditCard = 'selected_credit_card',
  changePaymentMethod = 'change_payment_method',
  startConversationPix = 'start_conversation_pix',
  whatsAppCancelPixOrder = 'wtp_cancel_pix_order',
  clickGooglePayButton = 'click_google_pay_button',
  selectedIntegratedPix = 'selected_integrated_pix',
  tabPaymentShowQrCode = 'tab_payment_show_qr_code',
  tabPaymentCopyPixCode = 'tab_payment_copy_pix_code',
  orderStatusShowQrCode = 'order_status_show_qr_code',
  startConversationCredit = 'start_conversation_credit',
  orderStatusCopyPixCode = 'order_status_copy_pix_code',
  confirmCvvAndSendOrder = 'confirm_cvv_and_send_order',
  reviewCreditCardDetails = 'review_credit_card_details',
  orderStatusCancelPixOrder = 'order_status_cancel_pix_order',
  creditOrderCanceledByStore = 'credit_order_canceled_by_store',
  clickTryAgainWithGooglePay = 'click_try_again_with_google_pay',
  addNewCreditCardLoggedArea = 'add_new_credit_card_logged_area',
  addNewCreditCardMethodsList = 'add_new_credit_card_methods_list',
  sendWhatsAppIntegratedPixOrder = 'send_wtp_integrated_pix_order',
  integratedPixOrderCanceledByStore = 'integrated_pix_order_canceled_by_store',
  changeGooglePayPaymentMethodOnCheckout = 'change_gpay_payment_method_on_checkout',
  changeGooglePayPaymentMethodOnSendOrder = 'change_gpay_payment_method_on_send_order',

  qrcodeVisualizationError = 'qrcode_visualization_error',

  pagtoCreditAdblockTrue = 'pagto_credit_adblock_true',

  highlightThirkyOnLoad = 'highlight_thirky_on_load',
  highlightThirkyOnClick = 'highlight_thirky_on_click',
  preRollThirkyOnLoad = 'preRoll_thirky_on_load',
  preRollThirkyOnClick = 'pre_roll_thirky_on_click'
}

export enum gaErrors {
  notFoundError = '404_error',
  orderError = 'order_error',
  deliveryFeeError = 'delivery_fee_error'
}

/**
 * All events listed in this enum will only be sent to the store's G-ID
 * This check is performed in the 'trackEvent' function
 * (src/utils/analytics/index.tsx)
 */
export enum StoreGaEventsEnum {
  ViewItem = 'view_item',
  AddToCart = 'add_to_cart',
  BeginCheckout = 'begin_checkout',
  Purchase = 'purchase'
}
