import React, { useMemo } from 'react';

import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';
import { RiSmartphoneLine, RiPercentLine, RiBankCardLine, RiShieldCheckLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { PaymentFlagEnum } from '~/interfaces/enums';
import { PaymentMethod } from '~/interfaces/general';
import {
  SmartphoneQrcodeIcon,
  SmartphoneSuccessIcon,
  SmartphoneMoneyWhatsIcon,
  SmartphoneMoneyCreditIcon
} from '~/assets';

import * as S from './styles';

interface HowItWorksProps {
  isContentVisible?: boolean;
  method: PaymentMethod | undefined;
}

const data: Record<
  string,
  {
    icon: JSX.Element;
    description: string;
  }[]
> = {
  mpQrCode: [
    {
      icon: <SmartphoneQrcodeIcon />,
      description: '$t(payment.howItWorks.mpQrCode.description)'
    },
    {
      icon: <SmartphoneSuccessIcon />,
      description: '$t(payment.howItWorks.success)'
    }
  ],
  mpPaymentLink: [
    {
      icon: <SmartphoneMoneyWhatsIcon />,
      description: '$t(payment.howItWorks.mpPaymentLink.description)'
    },
    {
      icon: <SmartphoneMoneyCreditIcon />,
      description: '$t(payment.howItWorks.mpPaymentLink.secure)'
    },
    {
      icon: <SmartphoneSuccessIcon />,
      description: '$t(payment.howItWorks.success)'
    }
  ],
  nupay: [
    {
      icon: <RiSmartphoneLine size={20} />,
      description: '$t(payment.howItWorks.nupay.finishedInApp)'
    },
    {
      icon: <RiPercentLine size={20} />,
      description: '$t(payment.howItWorks.nupay.noInterest)'
    },
    {
      icon: <RiBankCardLine size={20} />,
      description: '$t(payment.howItWorks.nupay.noCardInfoNeeded)'
    },
    {
      icon: <RiShieldCheckLine size={20} />,
      description: '$t(payment.howItWorks.nupay.secure)'
    }
  ]
};

const HowItWorks: React.FC<HowItWorksProps> = ({ method, isContentVisible = false }) => {
  const { getTranslation } = useTranslator();

  const infoList: {
    icon: JSX.Element;
    description: string;
  }[] = useMemo(() => {
    if (method?.flag === getTranslation(PaymentFlagEnum.Nubank) || method?.category === PaymentCategoryEnum.nupay)
      return data.nupay;

    if (method?.flag === getTranslation(PaymentFlagEnum.OnlinePaymentLink)) return data.mpPaymentLink;

    if (method?.flag === getTranslation(PaymentFlagEnum.MercadoPago) && method.category === PaymentCategoryEnum.mPagoQr)
      return data.mpQrCode;

    return data.mpQrCode;
  }, [getTranslation, method]);

  return (
    <S.Container isContentVisible={isContentVisible}>
      {method?.flag === 'Nubank' ? (
        <S.NubankContent>
          {infoList.map((item, index) => (
            <S.NubankItem key={index}>
              <div className="icon">{item.icon}</div>

              <div className="description">{getTranslation(item.description)}</div>
            </S.NubankItem>
          ))}
        </S.NubankContent>
      ) : (
        <S.SimpleContent>
          <ul>
            {infoList.map((item, index) => (
              <li key={index}>
                <small className="number">{index + 1}</small>

                <p className="description">{getTranslation(item.description)}</p>

                {item.icon}
              </li>
            ))}
          </ul>
        </S.SimpleContent>
      )}
    </S.Container>
  );
};

export default HowItWorks;
