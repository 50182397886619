import React, { useMemo, useState, useEffect, useCallback, useRef, createContext } from 'react';

import { NextPage } from 'next';
import currency from 'currency.js';
import FadeIn from 'react-fade-in';
import { FaBan } from 'react-icons/fa';
import { useRouter } from 'next/router';
import ShowMoreText from 'react-show-more-text';
import { useSelector, useDispatch } from 'react-redux';
import { browserName, mobileModel } from 'react-device-detect';
import { useLocalStorage } from '@goomerdev/goomer-toolbox/src/hooks';

import GoogleAnalytics from '~/utils/analytics';
import { fetchProductOptionals } from '~/services';
import { IApplicationState } from '~/redux-tools/store';
import { goToNotFoundPage } from '~/utils/goToNotFoundPage';
import { PixelEventsEnum } from '~/utils/reactPixelTrackEvent';
import HowItWorksModal from '~/components/Modal/HowItWorksModal';
import { StoreGaEventsEnum } from '~/utils/analytics/utils/events';
import { fetchMenusRequest } from '~/redux-tools/store/menus/actions';
import { OptionListOnChangeProps } from '~/components/Option/OptionList';
import { FetchProductOptionalsResponse } from '~/services/fetchProductOptionals';
import { useMenuGroups, useMenuProducts, useMenuUrl } from '~/hooks/useMenu/hooks';
import { Hour, Product, OptionPricingTypeEnum, OptionGroup, PricePromotion } from '~/interfaces';
import { PriceInfo, LocalOrder, ReferenceId, CartProduct, CartOptional, CustomPriceInfo } from '~/interfaces/general';
import {
  FeaturesEnum,
  ProductAction,
  LanguageCodeEnum,
  LocalStorageEnum,
  MenuSessionStorageEnum,
  ProductHighlightTagTypeEnum
} from '~/interfaces/enums';
import {
  sanitizeHTML,
  generateUniqueId,
  reactPixelTrackEvent,
  calculateOptionalGroupPrice,
  isProductOrCategoryAvailable
} from '~/utils';
import {
  addProductToCart,
  editProductToCart,
  setHasNewProductOnBag,
  setEstablishmentOnCart
} from '~/redux-tools/store/cart/actions';
import {
  useSlug,
  useScrollTo,
  useTranslator,
  useCheckProvider,
  useFormattedPrice,
  usePromotionalPrice,
  useHasFeatureAccess,
  useEstablishmentStatus,
  useParserFetchMenusResponse,
  useFetchEstablishmentRequest
} from '~/hooks';
import {
  Comment,
  NewWaves,
  OptionList,
  ProductTags,
  ProductImage,
  TitleDivider,
  OptionalPrice,
  FloatingFooter,
  HeaderSecondary,
  SuggestionModal,
  PromotionalPrice,
  ProductHighlightTag
} from '~/components';

import SuggestionDetail from '../suggestionDetail';

import * as S from './styles';

export interface RequiredGroup {
  name: string;
  isDone: boolean;
  id: number | string;
  parentFlowId?: string;
}

interface DetailProps {
  cartId?: string;
  suggestionId?: number;
  action?: ProductAction;
  productReferenceID?: ReferenceId;
}

interface ProductContextProps {
  shouldDisplayHowItWorksModal: boolean;
  savedPricingType: OptionPricingTypeEnum;
  setShouldDisplayHowItWorksModal: (value: boolean) => void;
  setSavedPricingType: (value: OptionPricingTypeEnum) => void;
}

export const ProductContext = createContext<ProductContextProps>({} as ProductContextProps);

const Detail: NextPage<DetailProps> = ({ suggestionId, productReferenceID }) => {
  const history = useRouter();
  const dispatch = useDispatch();
  const isCalled = useRef(false);
  const isFirstRender = useRef(true);

  const cart = useSelector((state: IApplicationState) => state.cart);
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const localOrder = useSelector((state: IApplicationState) => state.localOrders.mode);
  const cartProductsList = useSelector((state: IApplicationState) => state.cart.products);
  const localOrders: LocalOrder = useSelector((state: IApplicationState) => state.localOrders);
  const { error, settings, isViewMode, open: isOpen } = useSelector((state: IApplicationState) => state.establishment);

  const [customName, setCustomName] = useState('');
  const [comment, setComment] = useState<string>('');
  const [optionalsPrice, setOptionalsPrice] = useState(0);
  const [action, setAction] = useState(ProductAction.default);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAbleToProceed, setIsAbleToProceed] = useState(false);
  const [isLoadingOptionals, setIsLoadingOptionals] = useState(true);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const [comboToEditList, setComboToEditList] = useState<CartProduct[]>([]);
  const [optionals, setOptionals] = useState<FetchProductOptionalsResponse>();
  const [requiredGroupsList, setRequiredGroupsList] = useState<RequiredGroup[]>([]);
  const [isAvailableInThisDateTime, setIsAvailableInThisDateTime] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState<PriceInfo>({ price: 0, code: '' });
  const [selectedOptionalsList, setSelectedOptionalsList] = useState<CartOptional[]>([]);
  const [cartProductToEdit, setCartProductToEdit] = useState<CartProduct | undefined>(undefined);
  const [selectedSuggestionId, setSelectedSuggestionId] = useState<number | undefined>(undefined);
  const [shouldDisplayHowItWorksModal, setShouldDisplayHowItWorksModal] = useState<boolean>(false);
  const [savedPricingType, setSavedPricingType] = useState<OptionPricingTypeEnum>(OptionPricingTypeEnum.Individual);

  const isCombo = useMemo(() => !!product?.is_combo, [product?.is_combo]);
  const isAvailable = useMemo(() => !!product?.is_available, [product?.is_available]);

  const slug = useSlug();
  useScrollTo({ x: 0, y: 0 });
  const { provider } = useCheckProvider();

  const menuUrl = useMenuUrl();
  useFetchEstablishmentRequest({});
  const { allProducts } = useMenuProducts();
  const { getTranslation } = useTranslator();
  const { getGroupByProductId } = useMenuGroups();
  const { isAbleToOrder } = useEstablishmentStatus({});
  const { parserFetchMenusResponse } = useParserFetchMenusResponse();
  const [hasNewDeliveryAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.NewDeliveryMenu });
  const { localStorageValue: selectedLanguage } = useLocalStorage({ key: LocalStorageEnum.selectedLanguage });
  const availablePromotionalPriceList = usePromotionalPrice(product?.prices ?? []);
  const formattedPrice = useFormattedPrice({
    isCombo,
    isAvailable,
    price: product?.price,
    priceList: product?.prices
  });

  const isAbrahaoStore = useMemo(() => settings?.is_abrahao, [settings?.is_abrahao]);
  const productId = useMemo(() => history.query.productId, [history.query.productId]);
  const isLocalOrder: boolean = useMemo(() => localOrders.mode.length > 0, [localOrders]);

  const isPricelessProductEnabled = useMemo(
    () => settings?.mm_priceless_product_enabled || false,
    [settings?.mm_priceless_product_enabled]
  );

  const productPromotionalPrice = useMemo(() => {
    if (!availablePromotionalPriceList || availablePromotionalPriceList.length === 0 || !product) {
      return;
    }

    const promotionPrice = availablePromotionalPriceList?.find(
      (productPrice) => !!productPrice.promotion && productPrice.id === selectedPrice.id
    );

    return promotionPrice?.promotion;
  }, [availablePromotionalPriceList, product, selectedPrice.id]);

  const isSinglePrice = useMemo(() => {
    if (!product) {
      return true;
    }

    if (product?.prices.length === 1) {
      const priceName = product.prices[0].name.trim();

      // When translating products with single price, the price has no translation option in the dashboard and always come in pt-BR, so we force this check to true
      return priceName === '' || priceName === product.name || selectedLanguage !== LanguageCodeEnum.ptBr;
    }

    return false;
  }, [product, selectedLanguage]);

  const hasMoreThanOnePrice = useMemo(() => {
    if (product?.min_price_flag || (product && product?.prices.length > 1)) return true;

    return false;
  }, [product]);

  const isObservationsEnabled = useMemo(() => {
    if (!settings) return true;

    if (isLocalOrder && settings.mm_in_store_order_show_observations) return true;

    if (!isLocalOrder && settings.mm_order_show_observations) return true;

    return false;
  }, [isLocalOrder, settings]);

  const selectedPriceFlowIdList = useMemo(() => {
    if ((!isCombo && !selectedPrice?.id) || !product || product?.prices.length === 0) {
      return [];
    }

    if (!isCombo) {
      return [`${product?.id}-${selectedPrice?.id}`];
    }

    return product.prices.map(({ id: priceId }) => `${product?.id}-${priceId}`);
  }, [isCombo, product, selectedPrice?.id]);

  const selectedPriceFlowList: OptionGroup[] = useMemo(() => {
    if (!isCombo && (!selectedPrice.id || product?.prices.length === 0)) {
      return [];
    }

    const priceFlowList: OptionGroup[] = [];

    product?.prices.forEach(({ flow_ids, id: priceId }) => {
      flow_ids.forEach((flowId) => {
        const flowOptions = optionals?.flow_list.find(({ id }) => id === flowId);

        if (flowOptions) {
          priceFlowList.push({ ...flowOptions, parentFlowId: `${product?.id}-${priceId}` });
        }
      });
    });

    return priceFlowList;
  }, [isCombo, optionals?.flow_list, product?.id, product?.prices, selectedPrice.id]);

  const fullOptionalList: OptionGroup[] = useMemo(() => {
    const optionalList = optionals?.option_groups || [];

    if (isCombo && product?.prices) {
      const comboOptionalList = optionalList.map((optional) => {
        const comboOptionList = optional.options.map((option) => ({
          ...option,
          comboPrice: option.price * product?.prices.length
        }));

        return {
          ...optional,
          options: comboOptionList
        };
      });

      return [...selectedPriceFlowList, ...comboOptionalList];
    }

    return [...selectedPriceFlowList, ...optionalList];
  }, [isCombo, optionals?.option_groups, product?.prices, selectedPriceFlowList]);

  const redirectToCheckout = useCallback(() => {
    if (localOrder) {
      history.push('/localOrder');
    } else {
      history.push('/order');
    }
  }, [history, localOrder]);

  const redirectToMenu = useCallback(() => {
    history.push('/menu');
  }, [history]);

  const handleCloseSuggestionModal = useCallback((): void => {
    setIsModalVisible(false);

    setTimeout(() => {
      redirectToMenu();
    }, 100);
  }, [redirectToMenu]);

  const hasValidHours = useCallback((hours?: Hour[]) => {
    if (!hours || hours?.length === 0) return false;

    return true;
  }, []);

  const hasAvailabilityNow = useCallback(
    (hours: Hour[]) => !!hours?.map((hour) => isProductOrCategoryAvailable(hour)).find((hour) => !!hour),
    []
  );

  const group = useMemo(() => getGroupByProductId(product?.id), [getGroupByProductId, product?.id]);

  const productQuantityOnCart: number = useMemo(
    () =>
      cart?.products
        ?.filter((productCart) => productCart.product.id === product?.id)
        .map((productOnCart) => productOnCart.quantity)
        .reduce((previousQuantity, currentQuantity) => previousQuantity + currentQuantity, 0),
    [cart?.products, product?.id]
  );

  const checkOptionGroupByElementId = useCallback((currentOption: CartOptional, newOption: CartOptional) => {
    const newIdToCheck = newOption.elementFlowId?.slice(0, -String(newOption.id).length);
    const currentIdToCheck = currentOption.elementFlowId?.slice(0, -String(currentOption.id).length);

    return newIdToCheck === currentIdToCheck;
  }, []);

  const filterGroupOptions = useCallback(
    (fullOptionalList: CartOptional[], option: CartOptional) => {
      return fullOptionalList.filter((currentOption) => {
        if (!currentOption.elementFlowId || !option.elementFlowId) {
          return false;
        }

        return checkOptionGroupByElementId(currentOption, option);
      });
    },
    [checkOptionGroupByElementId]
  );

  const handleClick = useCallback(
    (quantity: number) => {
      if (!product) {
        return;
      }

      const comboId = isCombo ? generateUniqueId() : undefined;

      const mountProductInfo: (
        productPrice: PriceInfo | undefined,
        cartOptionalList: CartOptional[],
        optionalsValue: number,
        cartProductEditing: CartProduct | undefined,
        promotionalPrice?: PricePromotion
      ) => void = (productPrice, cartOptionalList, optionalsValue, cartProductEditing, promotionalPrice) => {
        if (!productPrice) {
          return;
        }

        const hashToUseOnCart = promotionalPrice?.hash ?? productPrice.hash;
        const priceToUseOnCart = promotionalPrice?.price ?? productPrice.price;
        const id = cartProductEditing ? cartProductEditing.id : generateUniqueId();

        const productPriceName = productPrice.name ? productPrice.name : customName;
        const productCustomName = productPriceName !== product.name ? productPriceName : undefined;

        const prod: CartProduct = {
          id,
          comboId,
          comment,
          quantity,
          hash: hashToUseOnCart,
          code: productPrice.code,
          customName: productCustomName,
          selectedPriceId: productPrice.id,
          productReferenceID: productReferenceID || null,
          subtotal: quantity * (priceToUseOnCart + optionalsValue),
          optionals: cartOptionalList.map((optional) => ({ ...optional, value: optional.price })),
          product: {
            id: product.id,
            quantity: quantity,
            name: product.name,
            price: priceToUseOnCart,
            category_id: group?.id || 0,
            category: group?.name || '',
            limit_age: product.limit_age,
            description: product.description
          }
        };

        reactPixelTrackEvent(PixelEventsEnum.AddToCart, {
          currency: 'BRL',
          value: prod.subtotal,
          content_category: group,
          content_name: prod.product.name
        });

        if (cartProductEditing) {
          dispatch(editProductToCart(prod));
        } else {
          dispatch(addProductToCart(prod));
        }
      };

      if (!isCombo && !!selectedPrice) {
        mountProductInfo(
          selectedPrice,
          selectedOptionalsList,
          optionalsPrice,
          cartProductToEdit,
          productPromotionalPrice
        );
      }

      if (isCombo) {
        const comboProductLength = product.prices.length;

        product.prices.map((price) => {
          const productFlowOptionalId = `${product?.id}-${price?.id}`;

          let productOptionalsPrice = 0;
          const productOptionalList: CartOptional[] = [];

          const optionalWithSinglePriceList = selectedOptionalsList.map((option) => {
            if (!option.isComboOptional) {
              return option;
            }

            return {
              ...option,
              price: option.price / comboProductLength
            };
          });

          optionalWithSinglePriceList.forEach((option) => {
            if (option.elementFlowId?.includes(productFlowOptionalId) || option.isComboOptional) {
              const currentGroupOptionList = filterGroupOptions(productOptionalList, option);

              const productGroupPrice = calculateOptionalGroupPrice({
                pricingType: option.pricingType,
                currentTotal: productOptionalsPrice,
                currentOptions: currentGroupOptionList,
                newOptions: [...currentGroupOptionList, option]
              });

              productOptionalsPrice = productGroupPrice.total;
              productOptionalList.push(option);
            }
          });

          const comboItemToEdit = comboToEditList.find((comboItem) => comboItem.selectedPriceId === price?.id);

          const availablePricePromotion = availablePromotionalPriceList.find(
            ({ id, promotion }) => !!promotion && price.id === id
          )?.promotion;

          mountProductInfo(price, productOptionalList, productOptionalsPrice, comboItemToEdit, availablePricePromotion);
        });
      }

      if (cart.establishment === '') {
        dispatch(setEstablishmentOnCart(slug));
      }

      dispatch(setHasNewProductOnBag(true));

      const hasSuggestions = product.suggestions && product.suggestions.length > 0;

      if (action === ProductAction.edit) {
        redirectToCheckout();
      } else if (!hasSuggestions) {
        redirectToMenu();
      } else {
        setIsModalVisible(true);
      }
    },
    [
      slug,
      group,
      action,
      product,
      isCombo,
      comment,
      dispatch,
      customName,
      selectedPrice,
      redirectToMenu,
      optionalsPrice,
      comboToEditList,
      cartProductToEdit,
      filterGroupOptions,
      redirectToCheckout,
      productReferenceID,
      cart.establishment,
      selectedOptionalsList,
      productPromotionalPrice,
      availablePromotionalPriceList
    ]
  );

  const createOptionWithSubtotal = (option: CartOptional, subtotal: number): CartOptional & { subtotal: number } => ({
    ...option,
    subtotal
  });

  const handleToggleOptionals = useCallback(
    ({ type, option, removeOption }: OptionListOnChangeProps) => {
      setSelectedOptionalsList((currentOptionalList) => {
        let groupSubtotalPrice = 0;

        function updateSelectedOptions(
          selectedOptionalsList: CartOptional[],
          currentGroupOptionList: CartOptional[]
        ): CartOptional[] {
          return selectedOptionalsList.map((selectedItem) => {
            const currentItem = currentGroupOptionList.find(
              (current) => current.elementFlowId === selectedItem.elementFlowId
            );

            return currentItem ? { ...currentItem } : selectedItem;
          });
        }

        const currentGroupOptionList = filterGroupOptions(currentOptionalList, option);

        const getFilteredOptionalList = (optionElementId?: string) => {
          return currentOptionalList.filter((optional) => optional.elementFlowId !== optionElementId);
        };

        const getGroupPrice = (currentTotal: number, newOptions: CartOptional[]) => {
          const prices = calculateOptionalGroupPrice({
            newOptions,
            currentTotal,
            pricingType: option.pricingType,
            currentOptions: currentGroupOptionList
          });

          groupSubtotalPrice = prices.group;

          return prices;
        };

        if (type === 'add') {
          setOptionalsPrice((currentTotal) => {
            const prices = getGroupPrice(currentTotal, [...currentGroupOptionList, option]);

            return prices.total;
          });

          const updatedGroupOptionWithSubtotalList = currentGroupOptionList
            .filter(({ elementFlowId }) => elementFlowId !== option.elementFlowId)
            .map((item) => ({
              ...item,
              subtotal: groupSubtotalPrice
            }));

          const optionWithSubtotal = createOptionWithSubtotal(option, groupSubtotalPrice);

          return [
            ...updateSelectedOptions(selectedOptionalsList, updatedGroupOptionWithSubtotalList),
            optionWithSubtotal
          ];
        }

        if (type === 'remove') {
          const removeGroupOptionList = currentGroupOptionList.filter(
            ({ elementFlowId }) => elementFlowId !== option.elementFlowId
          );

          setOptionalsPrice((currentTotal) => {
            const prices = getGroupPrice(currentTotal, removeGroupOptionList);

            return prices.total;
          });

          const updatedRemoveGroupOptionWithSubtotalList = removeGroupOptionList.map((item) => ({
            ...item,
            subtotal: groupSubtotalPrice
          }));

          setSelectedOptionalsList((currentList) =>
            currentList
              .filter((item) => item.elementFlowId !== option.elementFlowId)
              .map((item) => {
                const updatedItem = updatedRemoveGroupOptionWithSubtotalList.find(
                  (updated) => updated.elementFlowId === item.elementFlowId
                );
                return updatedItem || item;
              })
          );

          return getFilteredOptionalList(option.elementFlowId);
        }

        if (type === 'toggle' && removeOption) {
          const toggleGroupOptionList = currentGroupOptionList.filter(
            ({ elementFlowId }) => elementFlowId !== removeOption.elementFlowId
          );

          setOptionalsPrice((currentTotal) => {
            const prices = getGroupPrice(currentTotal, [...toggleGroupOptionList, option]);

            return prices.total;
          });

          const optionWithSubtotal = createOptionWithSubtotal(option, groupSubtotalPrice);

          const filteredOptionalsList = getFilteredOptionalList(removeOption?.elementFlowId);

          return [...filteredOptionalsList, optionWithSubtotal];
        }

        if (type === 'edit') {
          const editGroupOptionList = currentGroupOptionList.filter(
            ({ elementFlowId }) => elementFlowId !== option.elementFlowId
          );

          setOptionalsPrice((currentTotal) => {
            const prices = getGroupPrice(currentTotal, [...editGroupOptionList, option]);

            return prices.total;
          });

          const groupOptionWithSubtotalList = editGroupOptionList.map((item) => ({
            ...item,
            subtotal: groupSubtotalPrice
          }));

          const filteredOptionalList = getFilteredOptionalList(option.elementFlowId);

          const updatedOptionalList = filteredOptionalList.map((item) => {
            const replacementItem = groupOptionWithSubtotalList.find(
              (groupItem) => groupItem.elementFlowId === item.elementFlowId
            );

            return replacementItem || item;
          });

          const optionWithSubtotal = createOptionWithSubtotal(option, groupSubtotalPrice);

          return [...updatedOptionalList, optionWithSubtotal];
        }

        return currentOptionalList;
      });
    },
    [filterGroupOptions, selectedOptionalsList]
  );

  const handleCustomPriceChange = useCallback((customItem: CustomPriceInfo) => {
    const { id, code, hash, name, price } = customItem;

    setCustomName(name);
    setSelectedPrice({ id, code, hash, price });
  }, []);

  const handleIsDoneStep = useCallback(({ id, name, isDone, parentFlowId }: RequiredGroup): void => {
    setRequiredGroupsList((currentGroupList) => {
      const filteredGroupsList = currentGroupList.filter(
        (group: RequiredGroup) => group.name !== name || group.id !== id || group.parentFlowId !== parentFlowId
      );

      if (filteredGroupsList) {
        return [...filteredGroupsList, { id, name, isDone, parentFlowId }];
      }

      return [...currentGroupList, { id, name, isDone, parentFlowId }];
    });
  }, []);

  const handleWhereShouldToRedirect = useCallback((): void => {
    const lastProductSelectedSessionData = sessionStorage.getItem(
      MenuSessionStorageEnum.lastProductSelectedSessionData
    );

    const getSessionStorageProductInfo = lastProductSelectedSessionData
      ? JSON.parse(lastProductSelectedSessionData)
      : undefined;

    const lastSelectedCategoryIdSessionData = sessionStorage.getItem(
      MenuSessionStorageEnum.lastSelectedCategoryIdSessionData
    );

    const getSessionStorageCategoryInfo = lastSelectedCategoryIdSessionData
      ? JSON.parse(lastSelectedCategoryIdSessionData)
      : undefined;

    if (getSessionStorageProductInfo && getSessionStorageCategoryInfo) {
      history.push(`/category/${getSessionStorageCategoryInfo.id}`);
      return;
    }

    if (group) {
      sessionStorage.setItem(
        MenuSessionStorageEnum.lastSelectedCategoryIdSessionData,
        JSON.stringify({ id: group?.id, categoryName: group?.name })
      );
    }

    history.push('/menu');
    return;
  }, [group, history]);

  const currentProductPrice = useMemo(() => {
    if (!isCombo) {
      return (productPromotionalPrice?.price ?? selectedPrice.price) + optionalsPrice;
    }

    return (formattedPrice?.price || 0) + optionalsPrice;
  }, [formattedPrice, isCombo, optionalsPrice, productPromotionalPrice?.price, selectedPrice]);

  useEffect(() => {
    if (!!history.query && !!history.query.edit) {
      const productEditId = history.query.edit;

      setAction(ProductAction.edit);

      const editedProduct = cartProductsList.find((item) => item.id === productEditId);

      if (editedProduct) {
        setCartProductToEdit(editedProduct);

        if (editedProduct?.comboId) {
          const comboProductList = cartProductsList.filter((item) => item.comboId === editedProduct.comboId);

          setComboToEditList(comboProductList);

          const comboOptionalPriceList = comboProductList.map((item) => {
            return currency(item.subtotal)
              .divide(item.product.quantity || 1)
              .subtract(item.product.price).value;
          });

          const optionalsPrice = comboOptionalPriceList.reduce((prev, curr) => prev + curr, 0);

          setOptionalsPrice(optionalsPrice);
          return;
        }

        const optionalsPrice = currency(editedProduct.subtotal)
          .divide(editedProduct.product.quantity || 1)
          .subtract(editedProduct.product.price).value;

        setOptionalsPrice(optionalsPrice);
      }
    }
  }, [cartProductsList, history.query]);

  useEffect(() => {
    if (comboToEditList.length > 0) {
      setComment(comboToEditList[0].comment || '');

      const editOptionalList: CartOptional[] = [];
      const comboOptionalList: CartOptional[] = [];

      comboToEditList.forEach((comboItem) => {
        comboItem.optionals?.forEach((optionalItem) => {
          if (optionalItem.isComboOptional) {
            const comboOptionalIndex = comboOptionalList.findIndex(
              (item) => item.elementFlowId === optionalItem.elementFlowId
            );

            if (comboOptionalIndex >= 0) {
              comboOptionalList[comboOptionalIndex] = {
                ...comboOptionalList[comboOptionalIndex],
                price: comboOptionalList[comboOptionalIndex].price + optionalItem.price
              };

              return;
            }

            comboOptionalList.push(optionalItem);
            return;
          }

          editOptionalList.push(optionalItem);
        });
      });

      setSelectedOptionalsList([...editOptionalList, ...comboOptionalList]);
      return;
    }

    if (cartProductToEdit) {
      setComment(cartProductToEdit.comment || '');
      setSelectedOptionalsList(cartProductToEdit.optionals || []);
    }
  }, [cartProductToEdit, comboToEditList]);

  useEffect(() => {
    const productDetails = allProducts.find(
      (productDetail) => Number(productDetail.id) === Number(productReferenceID || productId)
    );

    if (suggestionId) {
      const selectedSuggestion = productDetails?.suggestions?.find(({ id }) => suggestionId === id);

      if (selectedSuggestion) {
        return setProduct(selectedSuggestion);
      }
    }

    return setProduct(productDetails);
  }, [productId, allProducts, productReferenceID, suggestionId]);

  useEffect(() => {
    if (error.length > 0) {
      goToNotFoundPage({
        history,
        gaTrack: {
          browser: browserName,
          error_message: error,
          phone_model: mobileModel,
          establishment_id: settings?.id
        }
      });
    }
  }, [error, history, settings, slug]);

  useEffect(() => {
    if (product) {
      reactPixelTrackEvent(PixelEventsEnum.ViewContent, {
        currency: 'BRL',
        content_name: product.name,
        content_category: group?.name,
        value: product.price || product.prices[0].price
      });
    }
  }, [group?.name, product]);

  useEffect(() => {
    if (!product) {
      return;
    }

    const handleProductAvailability: () => void = () => {
      const isValidHoursProduct = hasValidHours(product?.available_hours);
      const isValidHoursCategory = true; // hasValidHours(product?.group_hours);

      const isProductAvailable = !!(isValidHoursProduct && hasAvailabilityNow(product.available_hours));
      const isCategoryAvailable = true; // !!(isValidHoursCategory && hasAvailabilityNow(product.group_hours));

      if (isValidHoursProduct && isValidHoursCategory) {
        return setIsAvailableInThisDateTime(isCategoryAvailable && isProductAvailable);
      }

      if (isValidHoursProduct) {
        return setIsAvailableInThisDateTime(isProductAvailable);
      }

      if (isValidHoursCategory) {
        return setIsAvailableInThisDateTime(isCategoryAvailable);
      }

      return setIsAvailableInThisDateTime(true);
    };

    handleProductAvailability();
  }, [hasAvailabilityNow, hasValidHours, product]);

  useEffect(() => {
    const isTheApiCallNeeded = allProducts.length === 0 && !isCalled.current;

    if (isTheApiCallNeeded && menuUrl) {
      dispatch(fetchMenusRequest({ url: menuUrl, parserFetchMenusResponse }));

      isCalled.current = true;
    }
  }, [dispatch, menuUrl, allProducts.length, parserFetchMenusResponse]);

  useEffect(() => {
    if (allProducts.length > 0 && product && product.id && product.version) {
      fetchProductOptionals({
        slug,
        product,
        provider,
        localOrder,
        setOptionals,
        getTranslation,
        selectedLanguage,
        setIsLoadingOptionals,
        abrahaoStoreId: settings?.mm_a_id,
        menuVersion: settings?.mm_menu_version,
        isNewMenu: isAbrahaoStore || hasNewDeliveryAccess
      });
    }
  }, [
    slug,
    product,
    settings,
    provider,
    localOrder,
    allProducts,
    getTranslation,
    isAbrahaoStore,
    selectedLanguage,
    hasNewDeliveryAccess
  ]);

  useEffect(() => {
    const handleAddInitialRequiredGroup = (): void => {
      const mountRequiredGroupsList: RequiredGroup[] = [];

      if (optionals) {
        optionals.option_groups.forEach((optional) => {
          if (optional.required) {
            mountRequiredGroupsList.push({ isDone: false, id: optional.id, name: optional.name });
          }
        });
      }

      setRequiredGroupsList((currentList) => {
        if (currentList.length > 0) return currentList;
        return mountRequiredGroupsList;
      });
    };

    if (selectedOptionalsList.length === 0) {
      handleAddInitialRequiredGroup();
    }
  }, [optionals, selectedOptionalsList]);

  useEffect(() => {
    const handleIfIsAbleToProceed = (): void => {
      const currentPrice = (isCombo ? formattedPrice?.price || 0 : selectedPrice.price) + optionalsPrice;

      if (isOpen.isOpen || isAbleToOrder) {
        if (isLoadingOptionals) return setIsAbleToProceed(false);

        if (!isPricelessProductEnabled && currentPrice === 0) return setIsAbleToProceed(false);

        if (product && product?.prices.length > 1 && !isCombo) {
          if (!customName) return setIsAbleToProceed(false);
        }

        const pendingStepsList = requiredGroupsList.filter((group) => !group.isDone);

        if (pendingStepsList.length > 0) return setIsAbleToProceed(false);

        return setIsAbleToProceed(true);
      }

      return setIsAbleToProceed(false);
    };

    handleIfIsAbleToProceed();
  }, [
    isOpen,
    product,
    isCombo,
    settings,
    customName,
    isAbleToOrder,
    selectedPrice,
    optionalsPrice,
    isLoadingOptionals,
    requiredGroupsList,
    selectedOptionalsList,
    formattedPrice?.price,
    isPricelessProductEnabled
  ]);

  useEffect(() => {
    if (product && product.prices.length === 1) {
      const priceInfo = product.prices[0];

      if (priceInfo) {
        const hasSamePriceName = priceInfo.name === product.name;
        const priceName = !hasSamePriceName ? priceInfo.name : '';

        const productInfo: CustomPriceInfo = {
          name: priceName,
          id: priceInfo?.id,
          code: priceInfo.code,
          hash: priceInfo.hash,
          price: priceInfo.price
        };

        return handleCustomPriceChange(productInfo);
      }
    }
  }, [product, selectedSuggestionId, handleCustomPriceChange, productReferenceID, selectedLanguage]);

  useEffect(() => {
    if (!!isFirstRender.current && !!product) {
      GoogleAnalytics.trackEvent(StoreGaEventsEnum.ViewItem, {
        value: product.price,
        currency: settings?.mm_currency?.code || 'BRL',
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            item_category: group?.name,
            item_description: product.description
          }
        ]
      });

      isFirstRender.current = false;
    }
  }, [group?.name, selectedPrice, product, settings?.mm_currency?.code, settings?.mm_currency.label]);

  const counterValue: { minValue?: number; maxValue?: number } | undefined = useMemo(() => {
    if (!product) return undefined;

    const MIN: number | undefined = product?.min;
    const MAX: number | undefined = product?.max;

    if (!MIN && !MAX) return undefined;

    if (MAX && productQuantityOnCart > 0) {
      return {
        minValue: MIN,
        maxValue: productQuantityOnCart >= MAX ? 0 : MAX - productQuantityOnCart
      };
    }

    if (MIN || MAX) {
      return { minValue: MIN, maxValue: MAX };
    }

    return undefined;
  }, [product, productQuantityOnCart]);

  if (selectedSuggestionId) {
    return <SuggestionDetail suggestionId={selectedSuggestionId} productReferenceID={product?.id || 0} />;
  }

  return (
    <ProductContext.Provider
      value={{
        savedPricingType,
        setSavedPricingType,
        shouldDisplayHowItWorksModal,
        setShouldDisplayHowItWorksModal
      }}
    >
      <S.Container>
        <HeaderSecondary
          isSuggestion={!!suggestionId}
          changeRedirect={(): void => handleWhereShouldToRedirect()}
          title={suggestionId ? getTranslation('detail.suggestionForYou') : getTranslation('detail.productDetail')}
        />

        <S.DetailBody>
          <S.ProductInfo>
            {product?.images && product?.images.length > 0 && (
              <ProductImage name={product?.name} imageList={product?.images} description={product?.description} />
            )}

            <S.ProductHighlightWrapper>
              {!isAvailable && <ProductHighlightTag type={ProductHighlightTagTypeEnum.unavailable} />}

              {product?.is_cart_blocked && <ProductHighlightTag type={ProductHighlightTagTypeEnum.viewOnly} />}

              {isAvailable && product?.visual_flags?.is_recommended && (
                <ProductHighlightTag type={ProductHighlightTagTypeEnum.recommended} />
              )}
            </S.ProductHighlightWrapper>

            <S.ProductHeader>
              <S.ProductName>{product?.name}</S.ProductName>

              {product?.visual_flags && (
                <ProductTags ofLegalAge={product?.limit_age} visualFlags={product?.visual_flags} />
              )}
            </S.ProductHeader>

            <ShowMoreText
              lines={3}
              keepNewLines
              expanded={false}
              className="content-css"
              truncatedEndingComponent={'... '}
              key={`product-detail-${productId}`}
              anchorClass="show-more-less-clickable"
              less={getTranslation('general.showLess')}
              more={getTranslation('general.showMore')}
            >
              {sanitizeHTML(product?.description)}
            </ShowMoreText>

            {(!!formattedPrice?.price || !!formattedPrice?.promotion) && (
              <S.PriceWrapper data-test="pricing">
                {!isCombo && hasMoreThanOnePrice && formattedPrice?.price !== 0 && (
                  <S.StartingAtText>{getTranslation('product.startingAt')}</S.StartingAtText>
                )}

                {formattedPrice?.promotion ? (
                  <PromotionalPrice oldPrice={formattedPrice.basePrice} promotional={formattedPrice.promotion} />
                ) : (
                  <S.PriceText>{formattedPrice?.formatted}</S.PriceText>
                )}
              </S.PriceWrapper>
            )}
          </S.ProductInfo>

          <NewWaves marginBottom="2.28rem" />

          <S.ContentWrapper>
            {product?.limit_age && (
              <S.FullAgeTag>
                <FaBan size={18} color={theme.isDarkMode ? theme.colors.textColor : theme.colors.backgroundColor} />

                <S.FullAgeTagText>{getTranslation('detail.underEighteen')}</S.FullAgeTagText>
              </S.FullAgeTag>
            )}

            {product && product.prices.length > 0 && !isSinglePrice && !isCombo && (
              <OptionalPrice
                prices={product.prices}
                availablePromotions={availablePromotionalPriceList}
                isViewMode={isViewMode || !isAvailableInThisDateTime}
                onChange={(customItem: CustomPriceInfo): void => handleCustomPriceChange(customItem)}
                defaultPrice={{
                  code: cartProductToEdit?.code || '',
                  hash: cartProductToEdit?.hash || '',
                  id: cartProductToEdit?.selectedPriceId,
                  name: cartProductToEdit?.customName || '',
                  price: cartProductToEdit?.product?.price || 0
                }}
              />
            )}

            {!!optionals && fullOptionalList.length > 0 && (
              <FadeIn>
                <OptionList
                  optionals={fullOptionalList}
                  flowList={optionals.flow_list}
                  onChange={handleToggleOptionals}
                  handleIsDoneStep={handleIsDoneStep}
                  selectedOptionals={selectedOptionalsList}
                  selectedPriceFlowIdList={selectedPriceFlowIdList}
                  isViewMode={isViewMode || !isAvailableInThisDateTime}
                />
              </FadeIn>
            )}

            {!isViewMode && isAvailableInThisDateTime && isObservationsEnabled && (
              <>
                <TitleDivider>{getTranslation('general.observations')}</TitleDivider>

                <Comment data-test="input-observation" onChange={setComment} comment={comment} />
              </>
            )}
          </S.ContentWrapper>
        </S.DetailBody>

        {!isViewMode && !product?.is_cart_blocked && isAvailable && (
          <>
            <FloatingFooter
              onClick={handleClick}
              isLoading={!optionals}
              counter={counterValue}
              trackingData={{ product }}
              price={currentProductPrice}
              isDisabled={!isAbleToProceed}
              isProductAvailable={isAvailableInThisDateTime}
              initialCount={cartProductToEdit?.quantity || counterValue?.minValue}
            />

            {isAvailableInThisDateTime && (
              <SuggestionModal
                isModalVisible={isModalVisible}
                suggestions={product?.suggestions}
                closeModal={handleCloseSuggestionModal}
                trackingData={{ associatedProduct: product }}
                acceptSuggestion={(suggestedProductId: number): void => {
                  setIsModalVisible(false);
                  setSelectedSuggestionId(suggestedProductId);
                }}
              />
            )}
          </>
        )}

        <HowItWorksModal
          pricingType={savedPricingType}
          isShow={shouldDisplayHowItWorksModal}
          onClose={(): void => setShouldDisplayHowItWorksModal(false)}
        />
      </S.Container>
    </ProductContext.Provider>
  );
};

export default Detail;
