import styled, { css } from 'styled-components';

export const Container = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 99999;

  width: 100%;
  height: 100vh;
  overflow: hidden;
  min-height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  background: ${({ theme }) => (theme.isDarkMode ? theme.colors.backgroundColor : theme.colors.textColor)};

  opacity: 0;
  transition: 0.3s;
  pointer-events: none;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      overflow: scroll;

      opacity: 1;
      transition: 0.3s;
      pointer-events: initial;
    `}

  > .image-header {
    width: 100%;

    padding: 1rem;
    height: 3.25rem;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (min-width: 720px) {
      max-width: 31.25rem;
    }
  }

  > .image {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      height: auto;
      max-width: 100%;
      max-height: 25rem;

      @media screen and (min-width: 720px) {
        max-width: 31.25rem;
      }
    }
  }

  > .info {
    margin: 0;
    width: 100%;
    padding: 1rem;

    @media screen and (min-width: 720px) {
      max-width: 31.25rem;
    }

    > .name {
      margin: 0;
      padding: 0;
      margin-bottom: 0.75rem;

      color: #fff;
      font-size: 1rem;
      font-weight: bold;
      line-height: 120%;
      font-style: normal;
    }

    > .description {
      margin: 0;
      padding: 0;

      color: #fff;
      font-weight: 500;
      line-height: 150%;
      font-size: 0.87rem;
      font-style: normal;
    }
  }
`;
