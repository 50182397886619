import { formatDistanceToNowStrict } from 'date-fns';

export default function getIsOutdated({ date, minutes }: { date: Date | null | undefined; minutes: number }): boolean {
  if (!date || date === null) return false;

  const distance: string = formatDistanceToNowStrict(new Date(date), { unit: 'minute' });
  const removedCharacters: string = distance.replace(/[A-Za-z]/g, '');
  const distanceInMinutes = Number(removedCharacters);

  const isOutdatedOrder: boolean = distanceInMinutes > minutes;

  return isOutdatedOrder;
}
