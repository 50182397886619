export enum QRCodeModeEnum {
  table = 'mm_table',
  balcony = 'mm_kiosk',
  guestCheck = 'mm_tab',
  display = 'mm_display',
  delivery = 'mm_delivery'
}

export enum LocalOrdersEnum {
  table = 'general.table',
  guestCheck = 'general.tab',
  balcony = 'general.balcony'
}

export enum ReverseLocalOrdersEnum {
  'general.tab' = 'mm_tab',
  'general.table' = 'mm_table',
  'general.balcony' = 'mm_kiosk'
}

export enum MercadoPagoErrorCodes {
  email = 2060
}

export enum InStoreEnum {
  table = 'mm_table',
  balcony = 'mm_kiosk',
  guestCheck = 'mm_tab'
}

export enum PaymentTypeEnum {
  onDelivery = 'paid_on_delivery',
  onApp = 'paid_on_app',
  onAttendant = 'paid_to_attendant'
}

export enum PaymentStepProcessEnum {
  sending = 'sending',
  paying = 'paying',
  success = 'success',
  sendingToCheckout = 'sendingToCheckout'
}

export enum EditTypeEnum {
  icon = 'icon',
  text = 'text',
  action = 'action'
}

export enum DeliveryWayEnum {
  delivery = 'delivery',
  takeaway = 'takeaway',
  onsite = 'onsite'
}

export enum DeliveryPricingEnum {
  fee = 'fee',
  free = 'free',
  ask = 'ask',
  outside = 'outside',
  notFound = 'notfound'
}

export enum DeliveryZoneTypeEnum {
  static = 'static',
  dynamic = 'dynamic',
  neighborhood = 'neighborhood'
}

export enum CouponPageEnum {
  new = 'new',
  edit = 'edit',
  list = 'list'
}

export enum AddressPageModeEnum {
  list = 'list',
  form = 'form',
  noCepForm = 'noCepForm',
  noStreetByCepForm = 'noStreetByCepForm'
}

export enum DiscountTypeEnum {
  percent = 'percentage',
  absolute = 'absolute'
}

export enum PrettyOriginEnum {
  takeaway = 'general.takeaway',
  delivery = 'address.delivery',
  onsite = 'general.onTheBalcony'
}

export enum CouponErrorMessageEnum {
  not_in_defined_hours = 'not_in_defined_hours',
  not_enabled = 'not_enabled',
  not_in_order_origin = 'not_in_order_origin',
  not_in_available_period = 'not_in_available_period',
  not_in_weekdays = 'not_in_weekdays',
  sold_out = 'sold_out',
  not_found = 'not_found',
  not_valid_order_minimum_value = 'not_valid_order_minimum_value',
  not_valid_order_value = 'not_valid_order_value'
}

export enum CheckoutErrorMessageEnum {
  rejected_order_by_mp = 'rejected_order_by_mp',
  wrong_phone_pattern = 'wrong_phone_pattern',
  invalid_loyalty_discount = 'invalid_loyalty_discount',
  order_validation_failed = 'order_validation_failed'
}

export enum AbrahaoErrorMessageEnum {
  TableNotFound = 'abrahao_table_not_found',
  UnableToSendOrderWithClosedBill = 'abrahao_unable_to_send_order_with_closed_bill'
}

export enum EstablishmentStatusEnum {
  open = 'open',
  closed = 'closed',
  scheduling = 'scheduling'
}

export enum PrettyEstablishmentStatusEnum {
  open = 'general.open',
  closed = 'general.closed',
  scheduling = 'general.scheduling'
}

export enum APIErrorCodes {
  STORE_ONLY_ACCEPT_SCHEDULED_ERROR = 601008,
  EXCEEDED_EMAIL_REQUESTS = 1100001,
  START_BILL_STORE_NOT_INTEGRATED = 1102001,
  GET_BILL_STORE_NOT_INTEGRATED = 1103001
}

export enum TimelineStatusEnum {
  current = 'current',
  waiting = 'pending',
  finished = 'finished'
}

export enum TimelineStepEnum {
  waiting_payment = 'waiting_payment',
  abandoned = 'abandoned',
  waiting = 'pending',
  accepted = 'accepted',
  cooking = 'preparing',
  delivery = 'delivering',
  finished = 'finished',
  cancelled = 'canceled',
  payment_failed = 'payment_failed'
}

export enum PaymentMethodUsedEnum {
  nupay = 'payment.byNubank',
  applePay = 'payment.byApplePay',
  googlePay = 'payment.byGooglePay',
  integratedPix = 'payment.byIntegratedPix'
}

export type TimelineStep =
  | ''
  | 'accepted'
  | 'canceled'
  | 'delivering'
  | 'finished'
  | 'pending'
  | 'preparing'
  | 'waiting_payment';

export enum FeedbackStatusEnum {
  default = 'default',
  orderSuccess = 'order-success',
  orderSuccessAbrahao = 'order-success-abrahao',
  orderFailure = 'order-failure',
  qrcode = 'qrcode',
  orderFromDashboard = 'order-from-dashboard',
  operatorOrderSuccess = 'operator-order-success',
  qrcodeDenied = 'qrcode-denied'
}

export enum FeaturesEnum {
  GNLGuestCheck = 38,
  UserSignUp = 39,
  Pwa = 45,
  LoyaltyProgram = 57,
  FirstOrderDiscount = 62,
  QrCodeLoyaltyProgram = 65,
  QrCodeOnlinePayment = 69,
  ManualPix = 72,
  MercadoPago = 73,
  TableOnlinePayment = 77,
  NewDeliveryMenu = 98
}

export enum FeaturesNamedEnum {
  GnlTabOperation = 'mm_feature_gnl_tab_operation_enabled',
  CustomerSignUp = 'mm_feature_customer_sign_up_enabled',
  Pwa = 'mm_feature_pwa_enabled',
  LoyaltyProgram = 'mm_feature_loyalty_program_enabled',
  QrCodeLoyaltyProgram = 'mm_feature_loyalty_program_qrcode_enabled',
  QrCodeOnlinePayment = 'mm_feature_qrcode_online_payment_enabled',
  ManualPix = 'mm_feature_legacy_payment_manual_pix_enabled',
  MercadoPago = 'mm_feature_legacy_payment_mpago_enabled',
  TableOnlinePayment = 'mm_feature_table_online_payment_enabled',
  NewDeliveryMenu = 'mm_feature_abrahao_menu_enabled'
}

export enum EmailVerificationStatusEnum {
  error = 'error',
  success = 'success',
  default = 'default'
}

export enum InfoRequestModes {
  domain = 'domain',
  slug = 'slug'
}

export enum ModalCloseButtonTypesEnum {
  arrow = 'arrow',
  times = 'times'
}

export enum SelectedPaymentModeEnum {
  notSelected = 'not selected',
  accountDivision = 'account division',
  singleValue = 'single value',
  payFull = 'pay full'
}

export enum OrderWayEnum {
  GoomerGo = 'GoomerGo',
  NaLoja = 'NaLoja'
}

export enum PossibleStatusEnum {
  name = 'possibleStatus.chooseName',
  hash = 'possibleStatus.reReadQrCode',
  phone = 'possibleStatus.contactNumber',
  payment = 'possibleStatus.choosePaymentMethod',
  guestCheck = 'possibleStatus.tableNumber',
  scheduling = 'possibleStatus.chooseScheduleDate',
  address = 'possibleStatus.deliveryAddress',
  cpf = 'possibleStatus.insertYourDocument',
  default = 'possibleStatus.makeOrder'
}

export enum OperationModesEnum {
  table = 'general.table',
  kiosk = 'general.balcony',
  guestCheck = 'general.tab'
}

export enum ProductType {
  ggo = 'ggo',
  gnl = 'gnl'
}

export enum ProductAction {
  edit = 'edit',
  default = 'default'
}

export enum ProductHighlightTagTypeEnum {
  viewOnly = 'viewOnly',
  recommended = 'recommended',
  unavailable = 'unavailable'
}

export enum ErrorsEnum {
  email = 'email',
  rejected = 'rejected'
}

export enum TabStatusEnum {
  Starting = 'STARTING',
  InProcess = 'IN_PROCESS',
  Processed = 'PROCESSED',
  Error = 'ERROR',
  Local = 'LOCAL',
  Consuming = 'CONSUMING',
  Available = 'AVAILABLE'
}

export enum TunaResponseCode {
  Ok = 1,
  NotOk = -1
}

export enum TunaPaymentMode {
  card = 'card',
  pix = 'pix',
  nupay = 'nupay',
  googlePay = 'google_pay'
}

export enum TableManagerPaymentEnum {
  Cash = 'cash',
  Credit = 'credit',
  Debit = 'debit',
  Voucher = 'voucher',
  Pix = 'pix'
}

export enum WelcomeEnum {
  first = 'first',
  return = 'return'
}

export enum MenuLabelEnum {
  menu = 'navigation.menu',
  search = 'navigation.search',
  orderStatus = 'navigation.order',
  signin = 'navigation.signIn',
  waiter = 'navigation.waiter',
  profile = 'navigation.profile',
  myTab = 'navigation.bill',
  more = 'navigation.more'
}

export enum LoginOptionEnum {
  phone = 'phone_number',
  email = 'email',
  google = 'google_token'
}

export enum LoginTypeEnum {
  google = 'google',
  email = 'email'
}

export enum QueryParam {
  utmSource = 'utm_source',
  utmOrigin = 'utm_origin',
  utmMedium = 'utm_medium',
  utmCampaign = 'utm_campaign',
  utmId = 'utm_id',
  type = 'type',
  local = 'local',
  code = 'code',
  phone = 'phone',
  loginType = 'type',
  operationId = 'operation_id',
  coupon = 'cupom',
  bag = 'bag',
  welcome = 'welcome',
  hash = 'hash',
  panelOrder = 'panel_order',
  refresh = 'refresh',
  previousPage = 'previous_page',
  orderId = 'orderId',
  operator = 'operator',
  editProfile = 'edit_profile',
  walletToken = 'token',
  isAbrahao = 'is_abrahao',
  methodType = 'methodType'
}

export enum ToastTypeEnum {
  info = 'info',
  error = 'error',
  ghost = 'ghost',
  blank = 'blank',
  success = 'success',
  warning = 'warning'
}

export enum ToastIdEnum {
  cancel = 'toastCancelId',
  email = 'toastEmailId',
  getBillError = 'toastGetBillError',
  avoidDuplicating = 'avoid-duplicating',
  socketDisconnect = 'socket-disconnected'
}

export enum CreditCardTunaErrorCodes {
  securityCode = 'E302',
  cardDataMissing = 'Card data missing',
  invalidExpirationDate = 'Expiration date is invalid',
  invalidCardNumberCode = 'E301',
  invalidCardNumberMessage = 'Card number is invalid'
}

export enum LocalStorageFingerPrintEnum {
  isCalledFingerPrintScript = '@checkout/isCalledFingerPrintScript'
}

export enum CookieNameEnum {
  userToken = 'userToken',
  checkoutOrderData = 'send_order_data',
  tokenGlobalTag = 'isUserAlreadyUsingGlobalToken',
  onlinePaymentMethodToken = 'onlinePaymentMethodToken',
  googlePayOnlinePaymentMethodToken = 'googlePayOnlinePaymentMethodToken'
}

export enum TunaSessionStorageEnum {
  TokenizatorSessionData = '@tuna/tokenizatorSessionData'
}

export enum MenuSessionStorageEnum {
  lastSelectedMenuIdSessionData = '@menu/lastSelectedMenuIdSessionData',
  lastSelectedCategoryIdSessionData = '@menu/lastSelectedCategoryIdSessionData',
  lastProductSelectedSessionData = '@menu/lastProductSelectedSessionData'
}

export enum LoggedAreaMenuItemEnum {
  default = 'loggedArea.default',
  personalData = 'loggedArea.personalData',
  account = 'loggedArea.account',
  notifications = 'loggedArea.notifications',
  cards = 'loggedArea.cards',
  addresses = 'loggedArea.addresses'
}

export enum ChatbotAttendantModeEnum {
  basic = 'basic',
  orderStatus = 'order-status'
}

export enum SegmentTypeEnum {
  all = 'all',
  first_order = 'first_order'
}

export enum LocalStorageEnum {
  addressValidated = 'addressValidated',
  isDarkMode = 'isDarkMode',
  selectedLanguage = 'selectedLanguage'
}

export enum LocalStorageModalEnum {
  pwaBanner = 'pwa-banner',
  tablePaymentPromo = 'table-payment-promo'
}

export enum TableManagerTabAliasModeEnum {
  Name = 'client_name',
  Tab = 'tab_code'
}

export enum TableManagerStatusEnum {
  Available = 'available',
  Busy = 'busy',
  PartialPayment = 'partial_payment',
  Finished = 'finished'
}

export enum PaymentFlagEnum {
  Nubank = 'methods.nupay',
  ApplePay = 'methods.applePay',
  GooglePay = 'methods.googlePay',
  MercadoPago = 'payment.mercadoPago',
  MercadoPagoQrCode = 'payment.mercadoPagoQrCode',
  OnlinePaymentLink = 'payment.onlinePaymentLink'
}

export enum LocalStorageThirkyEnum {
  preRollVisibility = 'thirky-pre-roll-visibility'
}

export enum CameraPermissionErrorEnum {
  NotAllowed = 'NotAllowedError',
  NotFound = 'NotFoundError',
  DevicesNotFound = 'DevicesNotFoundError',
  NotReadable = 'NotReadableError',
  TrackStart = 'TrackStartError',
  Default = 'DefaultError'
}

export enum WaiterOptionTypeEnum {
  Quantity = 'quantity',
  Option = 'option'
}

export enum OrderModeEnum {
  Table = 'table',
  Tab = 'tab'
}

export enum FeedbackModalTypeEnum {
  Info = 'info',
  Error = 'error',
  Success = 'success'
}

export enum LanguageCodeEnum {
  es = 'es',
  enUs = 'en-US',
  ptBr = 'pt-BR'
}
