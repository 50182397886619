import hexToRgba from 'hex-to-rgba';
import styled, { css } from 'styled-components';

export const HighlightContainer = styled.div<{ $hasCta: boolean }>`
  ${({ $hasCta }) => css`
    line-height: 0;
    text-align: center;

    transition: all 0.4s;

    img {
      width: 100%;
      max-width: 450px; // exception in px because it is a Thirky technical specification
      max-height: 300px; // exception in px because it is a Thirky technical specification
      border-radius: 0.38rem;

      ${$hasCta &&
      css`
        cursor: pointer;
      `}
    }
  `};
`;

export const PreRollContainer = styled.div<{ $hasCta: boolean; $isVisible: boolean }>`
  ${({ theme, $hasCta, $isVisible }) => css`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    position: fixed;

    padding: 0.75rem;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    background-color: ${hexToRgba(theme.colors.pureTextColor, 0.7)};

    transition: all 0.4s;
    opacity: ${$isVisible ? 1 : 0};
    visibility: ${$isVisible ? 'visible' : 'hidden'};

    img {
      width: 100%;
      max-width: 450px; // exception in px because it is a Thirky technical specification
      max-height: 650px; // exception in px because it is a Thirky technical specification
      border-radius: 0.5rem;

      ${$hasCta &&
      css`
        cursor: pointer;
      `}
    }

    button {
      height: 1.19rem;
      margin-top: 0.75rem;
      width: min-content;
      border-color: transparent;

      span {
        font-weight: 700;
        font-size: 0.875rem; // exception in px because it is a Thirky technical specification
        color: ${theme.colors.backgroundColor};
      }
    }
  `};
`;
