import { action } from 'typesafe-actions';

import { CancelOrderProps, IntegratedPixTabPayment } from '~/interfaces/general';

import { PixPaymentActionTypes } from './types';

interface SendEmailProps {
  email: string;
  sendEmail?: boolean;
  orderId?: number | string;
}

export const addOrderTime = (time: Date | string) => {
  return action(PixPaymentActionTypes.ADD_ORDER_TIME, time);
};

export const sendEmailRequest = (data: SendEmailProps) => {
  return action(PixPaymentActionTypes.SEND_EMAIL_REQUEST, data);
};

export const sendEmailSuccess = (data: Omit<SendEmailProps, 'orderId'>) => {
  return action(PixPaymentActionTypes.SEND_EMAIL_SUCCESS, data);
};

export const sendEmailError = (error: string) => {
  return action(PixPaymentActionTypes.SEND_EMAIL_ERROR, { error });
};

export const cleanOrderTime = () => {
  return action(PixPaymentActionTypes.CLEAN_ORDER_TIME);
};

// TODO: fix typing
export const addPixOrder = (order: any) => {
  return action(PixPaymentActionTypes.ADD_PIX_ORDER, order);
};

export const cleanPixOrder = () => {
  return action(PixPaymentActionTypes.CLEAN_PIX_ORDER);
};

export const cleanAllPix = () => {
  return action(PixPaymentActionTypes.CLEAN_ALL_PIX);
};

export const cancelPixOrderRequest = (data: CancelOrderProps) => {
  return action(PixPaymentActionTypes.CANCEL_PIX_ORDER_REQUEST, data);
};

export const cancelPixOrderSuccess = () => {
  return action(PixPaymentActionTypes.CANCEL_PIX_ORDER_SUCCESS);
};

export const cancelPixOrderError = (error: string) => {
  return action(PixPaymentActionTypes.CANCEL_PIX_ORDER_ERROR, error);
};

export const addPixTabPayment = (payment: IntegratedPixTabPayment) => {
  return action(PixPaymentActionTypes.ADD_PIX_TAB_PAYMENT, payment);
};

export const cleanPixTabPayment = () => {
  return action(PixPaymentActionTypes.CLEAN_PIX_TAB_PAYMENT);
};
