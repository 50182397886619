import { TunaPaymentPayload } from '~/interfaces/general';
import { api } from '~/services/api';

interface SendPaymentProps {
  payload: TunaPaymentPayload;
}

export default async function sendPayment({ payload }: SendPaymentProps) {
  const apiPath = `${process.env.NEXT_PUBLIC_API_PAYMENT}/checkout/${payload.payment.mode}`;

  try {
    const response = await api.post(apiPath, { ...payload });

    if (response.data.message === 'Payment not succeeded') {
      throw new Error(`${response.data.message}`);
    }

    if (response.status !== 200) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return response.data;
  } catch (error) {
    throw new Error(`Payment POST error: ${error}`);
  }
}
