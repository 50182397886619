import { Reducer } from 'redux';

import { IGeolocationState, GeolocationActionTypes } from './types';

const initialState = {
  coordinates: {
    latitude: 0,
    longitude: 0
  },
  isGoogleMapsApiLoaded: false
};

const GeolocationReducer: Reducer<IGeolocationState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GeolocationActionTypes.ADD_GEOLOCATION:
      return {
        ...state,
        coordinates: {
          latitude: payload.latitude,
          longitude: payload.longitude
        }
      };

    case GeolocationActionTypes.SET_IS_GOOGLE_MAPS_API_LOADED:
      return {
        ...state,
        isGoogleMapsApiLoaded: payload
      };

    default:
      return state;
  }
};

export default GeolocationReducer;
