import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 6px 12px;
    border-radius: 1000px;

    display: flex;
    align-items: center;

    background-color: ${theme.colors.success400};

    svg {
      margin-right: 6px;

      color: ${theme.colors.success700};
    }

    span {
      font-weight: 700;
      line-height: 100%;
      font-size: 0.857rem;
      color: ${theme.colors.success700};
    }
  `}
`;
