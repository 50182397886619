export type BrazilDocumentType = 'CNPJ' | 'CPF';

interface IFormMP {
  email?: string;
  docNumber: string;
  installments?: any;
  cardNumber: string;
  securityCode: string;
  description?: string;
  cardholderName: string;
  cardExpirationYear: string;
  docType: BrazilDocumentType;
  cardExpirationMonth: string;
  transaction_amount?: number;
}

type ResponseHandler = (status: number, response: any) => void;
export interface IMercadoPagoSDK {
  tokenId?: string;
  clearSession: () => void;
  getPaymentMethods: () => any[];
  getIdentificationTypes: () => any;
  setPublishableKey: (publicKey: string) => void;
  createToken: (data: IFormMP, responseHandler: ResponseHandler) => any;
  getInstallments: (paymentMethodId: string | { bin: any }, responseHandler: ResponseHandler) => any[];
  getPaymentMethod: (paymentMethodId: string | { bin: any }, responseHandler: ResponseHandler) => any[];
}

export const MercadoPago = (typeof window !== 'undefined' ? (window as any) : {}).Mercadopago as IMercadoPagoSDK;

export const getValidLengthCards = () =>
  MercadoPago.getPaymentMethods()?.flatMap(
    (paymentMethod) =>
      paymentMethod.settings.map((setting: any) => ({
        cardLength: setting.bin.card_number.length,
        cvvLength: setting.bin.security_code.length
      })) as { cardLength: number; cvvLength: number }
  ) || [];

const MercadoPagoInit = (key: string) => {
  if (MercadoPago) {
    MercadoPago.setPublishableKey(key);
  }

  return MercadoPago;
};

export default MercadoPagoInit;
