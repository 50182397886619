import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Overview = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 16px;
    margin: 0 0 8px;
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray300};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background: ${theme.colors.backgroundColor};

    .item {
      width: 100%;
      margin: 0 0 4px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > p {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: ${theme.colors.gray500};
      }

      &.total {
        > p {
          font-weight: 700;
          color: ${theme.colors.textColor};
        }
      }

      &.paid {
        > p {
          font-weight: 800;
          color: ${theme.colors.success};
        }
      }

      &.remaining {
        > p {
          color: ${theme.colors.error};
        }
      }

      &:last-of-type {
        margin: 0;
      }
    }
  `}
`;

export const Info = styled.div<{ $hasError: boolean }>`
  ${({ theme, $hasError }) => css`
    position: relative;

    padding: 0 0 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .animation {
      width: 73px;
      height: 73px;
      margin: -12px 0 0 -2px;

      &.error {
        width: 48px;
        height: 48px;
        margin: 0 0 8px;

        > svg {
          width: 48px;
          height: 48px;
        }
      }
    }

    h3 {
      margin-bottom: 8px;

      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      color: ${theme.colors.textColor};
    }

    p,
    span {
      margin: 0;
      padding: 0;

      font-weight: 500;
      font-size: 0.857rem;
      line-height: 150%;
      text-align: center;
      color: ${theme.colors.gray500};

      > span {
        white-space: nowrap;
      }
    }

    ${$hasError &&
    css`
      padding: 0;

      .animation {
        width: 48px;
        height: 48px;
        margin: 0 0 8px;

        > svg {
          width: 48px;
          height: 48px;
        }
      }
    `}
  `};
`;

export const Tag = styled.span`
  ${({ theme }) => css`
    margin: 24px 0 0;
    padding: 4px 12px;
    width: fit-content;
    border-radius: 100px;

    display: block;

    font-weight: 700;
    font-size: 1rem;
    line-height: 100%;
    color: ${theme.colors.gray600};
    background-color: ${theme.colors.gray200};
  `}
`;
