const nameRegex = /^(([a-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšž ,.'-]{2,40}))$/giu;
const numberOnNameRegex = /^(([0-9a-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšž ,.'-]{1,40}))$/giu;

interface ValidateNameProps {
  value: string;
  isWithNumber?: boolean;
  allowJustFirstName?: boolean;
}

export default function validateName({ value, isWithNumber = false, allowJustFirstName = false }: ValidateNameProps) {
  const name = value.trim().split(' ');

  if (name.length === 1 && !allowJustFirstName) {
    return false;
  }

  const validNamePieces = name.filter((word) => !!word.match(isWithNumber ? numberOnNameRegex : nameRegex)).length;

  const isValid = validNamePieces === name.length;

  return isValid;
}
