import * as S from './styles';

interface ProgressBarProps {
  color?: string;
  barHeight: number;
  progressPercentage: number;
}

const ProgressBar: (props: ProgressBarProps) => JSX.Element = ({ color, barHeight, progressPercentage, ...rest }) => {
  return (
    <S.Container height={barHeight} {...rest}>
      <S.ProgressBar color={color} width={progressPercentage} />
    </S.Container>
  );
};

export default ProgressBar;
