import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';
import { dateTime } from '~/utils';

const orderStatusDurationHours = process.env.NEXT_PUBLIC_STATUS_DURATION_HOURS || '24';

export interface UseHasOrderStatusProps {
  hasOrderStatus: boolean;
  inProgress: boolean;
}

export default function useHasOrderStatus(): UseHasOrderStatusProps {
  const dispatch = useDispatch();

  const { settings } = useSelector((state: IApplicationState) => state?.establishment);
  const { order: orderStatus, inProgress } = useSelector((state: IApplicationState) => state?.orderStatus);

  const [hasOrderStatus, setHasOrderStatus] = useState(false);

  useEffect(() => {
    if (orderStatus?.orderID) {
      const isTheSameEstablishment =
        settings &&
        orderStatus?.establishment?.id &&
        [settings.id, settings?.store_code].includes(orderStatus?.establishment?.id);

      const timeDiff = dateTime.millisecondsToHours(Date.now() - new Date(orderStatus.date).getTime());
      const expiredStatus = Number(timeDiff) >= Number(orderStatusDurationHours);

      if ((!!settings?.id && !!settings?.store_code && !isTheSameEstablishment) || expiredStatus) {
        return setHasOrderStatus(false);
      }

      return setHasOrderStatus(true);
    }

    return setHasOrderStatus(false);
  }, [dispatch, orderStatus, settings]);

  return { hasOrderStatus, inProgress };
}
