import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: ${theme.colors.backgroundColor};

    > svg {
      margin-right: 8px;
    }

    @media screen and (min-width: 720px) {
      font-size: 16px;
    }
  `}
`;
