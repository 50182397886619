import { useMemo, useState, useCallback } from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { RiAddLine } from 'react-icons/ri';
import ShowMoreText from 'react-show-more-text';

import { sanitizeHTML } from '~/utils';
import { useTranslator } from '~/hooks';
import { Price, Product } from '~/interfaces';
import { useMenuMenus } from '~/hooks/useMenu/hooks';
import { IApplicationState } from '~/redux-tools/store';
import useFormattedPrice from '~/hooks/useFormattedPrice';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { ProductTags, ProductHighlightTag, PromotionalPrice } from '~/components';
import { MenuSessionStorageEnum, ProductHighlightTagTypeEnum } from '~/interfaces/enums';

import MenuIcon from '../MenuList/MenuIcon';

import * as S from './styles';

const CUSTOM_ICON_KEYWORD = 'uploads';

const SHOW_MORE_ANCHOR_CLASS = 'show-more-less-clickable';

export interface ProductItemProps {
  prices?: Price[];
  product: Product;
  categoryId?: number;
  isDetailed?: boolean;
  categoryName?: string;
  isSuggestion?: boolean;
  isHorizontalLayout?: boolean;
  suggestionAddButton?: boolean;
  suggestionAddButtonAction?: () => void;
}

const ProductCard: React.FC<ProductItemProps> = ({
  product,
  categoryId,
  categoryName,
  isDetailed = false,
  isSuggestion = false,
  suggestionAddButtonAction,
  isHorizontalLayout = false,
  suggestionAddButton = false,
  ...rest
}) => {
  const history = useRouter();

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { getTranslation } = useTranslator();

  const [hasErrorLoadingIcon, setHasErrorLoadingIcon] = useState<boolean>(false);

  const {
    id,
    name,
    price,
    limit_age,
    description,
    visual_flags,
    min_price_flag,
    images: imageList,
    prices: priceList,
    is_combo: isCombo,
    is_available: isAvailable
  } = product;

  const { selectedMenu } = useMenuMenus();
  const formattedPrice = useFormattedPrice({ price, priceList, isCombo, isAvailable });

  const isMultipleMenusLayoutEnabled = useMemo(
    () => !!settings?.mm_multiple_menus_layout_enabled,
    [settings?.mm_multiple_menus_layout_enabled]
  );

  const isDetailedView = useMemo(
    () => !isMultipleMenusLayoutEnabled || isDetailed,
    [isDetailed, isMultipleMenusLayoutEnabled]
  );

  const hasVisibleTags = useMemo(() => Object.values(visual_flags).some((value) => !!value), [visual_flags]);

  const hasPriceToShow = useMemo(
    () => !isAvailable || (!!formattedPrice?.price && formattedPrice?.price > 0),
    [formattedPrice?.price, isAvailable]
  );

  const shouldDisplayHighlightOnImage = useMemo(() => {
    const isMultipleMenusAndNotDetailed = isMultipleMenusLayoutEnabled && !isDetailedView;
    return isMultipleMenusAndNotDetailed && ((isAvailable && visual_flags?.is_recommended) || !isAvailable);
  }, [isAvailable, visual_flags?.is_recommended, isMultipleMenusLayoutEnabled, isDetailedView]);

  const handleItemClick = useCallback(
    (event: React.MouseEvent) => {
      const clickedElement = event.target as HTMLElement;
      const className = String(clickedElement?.className || '');
      const hasClickedOnShowMore = className.includes(SHOW_MORE_ANCHOR_CLASS);

      if (!hasClickedOnShowMore) {
        if (isSuggestion) {
          return suggestionAddButtonAction?.();
        }

        if (isAvailable) {
          if (categoryId && isDetailed) {
            sessionStorage.setItem(
              MenuSessionStorageEnum.lastSelectedCategoryIdSessionData,
              JSON.stringify({ id: categoryId })
            );
          }

          sessionStorage.setItem(MenuSessionStorageEnum.lastProductSelectedSessionData, JSON.stringify({ id }));

          GoogleAnalytics.trackEvent(gaEvents.viewContent, {
            content_name: product.name,
            content_category: categoryName || '',
            establishment_id: settings?.store_code,
            currency: settings?.mm_currency?.code || 'BRL',
            value: product.price || product.prices[0]?.price
          });

          history.push(`/detail/${id}`);
          return;
        }
      }
    },
    [
      id,
      history,
      isDetailed,
      categoryId,
      isAvailable,
      categoryName,
      isSuggestion,
      product.name,
      product.price,
      product.prices,
      settings?.store_code,
      suggestionAddButtonAction,
      settings?.mm_currency?.code
    ]
  );

  const onIconError = useCallback(() => setHasErrorLoadingIcon(true), []);

  const hasMoreThanOnePrice = useMemo(() => {
    if (!isCombo && (min_price_flag || priceList.length > 1)) return true;

    return false;
  }, [isCombo, min_price_flag, priceList.length]);

  const renderPlaceholderIcon = useMemo(() => {
    if (hasErrorLoadingIcon) {
      return <></>;
    }

    return (
      <div>
        {selectedMenu?.icon_url && (
          <MenuIcon
            alt={name}
            sizeRem={2.5}
            iconUrl={selectedMenu?.icon_url}
            isCustomIcon={selectedMenu?.icon_url.includes(CUSTOM_ICON_KEYWORD)}
            color={theme.isDarkMode ? theme.colors.darkGray : theme.colors.gray400}
          />
        )}
      </div>
    );
  }, [
    name,
    theme.isDarkMode,
    hasErrorLoadingIcon,
    theme.colors.gray400,
    theme.colors.darkGray,
    selectedMenu?.icon_url
  ]);

  return (
    <S.Container
      {...rest}
      $isDetailed={isDetailed}
      onClick={handleItemClick}
      $isDisabled={!isAvailable}
      id="product-card-container"
      $isSuggestion={isSuggestion}
      $isMultipleCardsLayout={!isDetailedView}
      $isHorizontalLayout={isHorizontalLayout}
      aria-label={`${getTranslation('product.card')}: ${name}`}
    >
      {(imageList.length > 0 || !isDetailedView) && (
        <S.ImageContainer
          $isDetailed={isDetailed}
          $hasImage={imageList.length > 0}
          $isHorizontalLayout={isHorizontalLayout}
          $isMultipleCardsLayout={!isDetailedView}
        >
          {imageList.length > 0 ? (
            <S.Image
              alt={name}
              $isDetailed={isDetailed}
              src={imageList[0]?.medium}
              $isMultipleCardsLayout={!isDetailedView}
            />
          ) : (
            renderPlaceholderIcon
          )}

          {shouldDisplayHighlightOnImage && (
            <ProductHighlightTag
              isAbsolute
              isFixedToTop={isHorizontalLayout}
              type={!isAvailable ? ProductHighlightTagTypeEnum.unavailable : ProductHighlightTagTypeEnum.recommended}
            />
          )}
        </S.ImageContainer>
      )}

      <S.Content $isDetailed={isDetailed}>
        {!isAvailable && isDetailedView && (
          <ProductHighlightTag isFixedToTop={isHorizontalLayout} type={ProductHighlightTagTypeEnum.unavailable} />
        )}

        {isAvailable && visual_flags?.is_recommended && isDetailedView && (
          <ProductHighlightTag type={ProductHighlightTagTypeEnum.recommended} />
        )}

        <S.ProductName>{name}</S.ProductName>

        {hasVisibleTags && (
          <S.ProductTagsWrapper>
            <ProductTags ofLegalAge={limit_age} visualFlags={visual_flags} />
          </S.ProductTagsWrapper>
        )}

        {description && (!isMultipleMenusLayoutEnabled || isDetailed) && (
          <S.DescriptionWrapper>
            <S.ShortDescription>
              <ShowMoreText
                lines={3}
                keepNewLines
                expanded={false}
                key={`description-detail-${id}`}
                truncatedEndingComponent={'... '}
                anchorClass={SHOW_MORE_ANCHOR_CLASS}
                more={getTranslation('general.showMore')}
                less={getTranslation('general.showLess')}
              >
                {sanitizeHTML(description)}
              </ShowMoreText>
            </S.ShortDescription>
          </S.DescriptionWrapper>
        )}

        <S.FooterWrapper $isHorizontalLayout={isHorizontalLayout}>
          {(hasPriceToShow || formattedPrice?.promotion) && (
            <S.PriceWrapper $hasMarginTop={isHorizontalLayout || isDetailedView}>
              {hasMoreThanOnePrice && formattedPrice?.price !== 0 && (
                <S.PriceText>{getTranslation('product.startingAt')} </S.PriceText>
              )}

              {formattedPrice?.promotion ? (
                <PromotionalPrice promotional={formattedPrice.promotion} oldPrice={formattedPrice.basePrice} />
              ) : (
                <>{hasPriceToShow && <S.Price>{formattedPrice?.formatted}</S.Price>}</>
              )}
            </S.PriceWrapper>
          )}

          {suggestionAddButton && (
            <div data-test={`btn-accept-suggestion-${id}`}>
              <S.FastAddButton onClick={(): void => suggestionAddButtonAction?.()}>
                <RiAddLine size={24} color={theme.brandColors.actionText} />
              </S.FastAddButton>
            </div>
          )}
        </S.FooterWrapper>
      </S.Content>
    </S.Container>
  );
};

export default ProductCard;
