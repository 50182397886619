import { differenceInSeconds } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';
import { fetchCustomerInfoRequest } from '~/redux-tools/store/user/actions';

import { UseLoyaltyProgramHooksProps } from '..';

const ONE_HOUR_IN_SECONDS = 3600;
const ONE_SECOND_IN_MILLISECONDS = 1000;

let loyaltyCountdown: NodeJS.Timeout;

export type UseLoyaltyIntervalBetweenOrdersProps = Pick<
  UseLoyaltyProgramHooksProps,
  'countdownInSecondsToUnblockLoyalty' | 'isLoyaltyTemporarilyBlocked'
>;

export default function useLoyaltyIntervalBetweenOrders(): UseLoyaltyIntervalBetweenOrdersProps {
  const dispatch = useDispatch();

  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const { customerLoyaltyProgram } = useSelector((state: IApplicationState) => state.user.data);
  const authenticationToken = useSelector((state: IApplicationState) => state.user.data.authenticationToken);
  const { loyaltyProgram: establishmentLoyaltyProgram } = useSelector(
    (state: IApplicationState) => state.establishment
  );

  const [isLoyaltyTemporarilyBlocked, setIsLoyaltyTemporarilyBlocked] = useState<boolean>(false);
  const [countdownInSecondsToUnblockLoyalty, setCountdownInSecondsToUnblockLoyalty] = useState<number>(0);

  const now: Date = useMemo(() => new Date(), []);

  const orderIntervalInSeconds: number = useMemo(
    () => ONE_HOUR_IN_SECONDS * (establishmentLoyaltyProgram?.orderIntervalHours || 1),
    [establishmentLoyaltyProgram?.orderIntervalHours]
  );

  const differenceBetweenLastOrderInSeconds: number = useMemo(
    () =>
      customerLoyaltyProgram?.last_order_at
        ? differenceInSeconds(now, new Date(customerLoyaltyProgram?.last_order_at))
        : 0,
    [customerLoyaltyProgram?.last_order_at, now]
  );

  const countdownInSeconds: number = useMemo(
    () => orderIntervalInSeconds - differenceBetweenLastOrderInSeconds,
    [differenceBetweenLastOrderInSeconds, orderIntervalInSeconds]
  );

  useEffect(() => {
    const hasLastOrderAtDate = !!customerLoyaltyProgram?.last_order_at;

    if (!hasLastOrderAtDate) {
      setIsLoyaltyTemporarilyBlocked(false);
      return;
    }

    setCountdownInSecondsToUnblockLoyalty(countdownInSeconds);

    const isLastOrderStillWithinTheOrderInterval =
      differenceBetweenLastOrderInSeconds > 0 && differenceBetweenLastOrderInSeconds <= orderIntervalInSeconds;

    setIsLoyaltyTemporarilyBlocked(isLastOrderStillWithinTheOrderInterval);
  }, [
    countdownInSeconds,
    orderIntervalInSeconds,
    differenceBetweenLastOrderInSeconds,
    customerLoyaltyProgram?.last_order_at
  ]);

  useEffect(() => {
    if (isLoyaltyTemporarilyBlocked) {
      if (countdownInSecondsToUnblockLoyalty > 0) {
        loyaltyCountdown = setTimeout(() => {
          setCountdownInSecondsToUnblockLoyalty(countdownInSecondsToUnblockLoyalty - 1);
        }, ONE_SECOND_IN_MILLISECONDS);
      } else {
        dispatch(
          fetchCustomerInfoRequest({
            storeId: settings?.id || 0,
            token: authenticationToken || ''
          })
        );

        setIsLoyaltyTemporarilyBlocked(false);
      }
    }

    return () => {
      clearTimeout(loyaltyCountdown);
    };
  }, [
    dispatch,
    settings?.id,
    countdownInSeconds,
    authenticationToken,
    isLoyaltyTemporarilyBlocked,
    countdownInSecondsToUnblockLoyalty
  ]);

  return { isLoyaltyTemporarilyBlocked, countdownInSecondsToUnblockLoyalty };
}
