import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { Button, Modal } from '~/components';
import { IApplicationState } from '~/redux-tools/store';
import { ChangeOrderClient } from '~/components/pages/MyTab';

import * as S from './styles';

interface ClientChangeModalProps {
  isShow: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ClientChangeModal = ({ isShow, onClose, onConfirm }: ClientChangeModalProps): JSX.Element => {
  const { mode: localOrderMode } = useSelector((state: IApplicationState) => state.localOrders);

  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);

  const { getTranslation } = useTranslator();

  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  return (
    <Modal isShow={isShow} onClose={onClose} isSmall hideCloseButton isWithoutPadding>
      <S.ModalWrapper>
        <S.Title>
          {getTranslation('localOrder.orderWithYourCurrentType', {
            orderType: getTranslation(localOrderMode).toLowerCase()
          })}
        </S.Title>

        <S.TabWrapper>
          {isShow && (
            <ChangeOrderClient
              displaySimpleLayout
              isLoadingTable={isLoadingTable}
              setIsLoadingTable={setIsLoadingTable}
            />
          )}
        </S.TabWrapper>

        <Button isDisabled={isLoadingTable} isGhost={false} onClick={handleConfirm}>
          {getTranslation('general.confirm')}
        </Button>
      </S.ModalWrapper>
    </Modal>
  );
};

export default ClientChangeModal;
