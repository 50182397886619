import { Reducer } from 'redux';

import { MountedNupayOrder } from '~/interfaces/general';

import { NupayState, NupayStateActionTypes } from './types';

const initialState = {
  isNupayLoading: false,
  order: {} as MountedNupayOrder,
  timeToExpireNupayOrder: undefined
};

const nupayReducer: Reducer<NupayState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NupayStateActionTypes.ADD_NUPAY_ORDER:
      return {
        ...state,
        order: payload
      };

    case NupayStateActionTypes.CLEAN_NUPAY_ORDER:
      return {
        ...state,
        isNupayLoading: false,
        order: {} as MountedNupayOrder,
        timeToExpireNupayOrder: undefined
      };

    case NupayStateActionTypes.ADD_NUPAY_TIME_TO_EXPIRE_ORDER:
      return {
        ...state,
        timeToExpireNupayOrder: payload
      };

    case NupayStateActionTypes.CLEAN_NUPAY_TIME_TO_EXPIRE_ORDER:
      return {
        ...state,
        timeToExpireNupayOrder: undefined
      };

    case NupayStateActionTypes.CANCEL_NUPAY_ORDER_REQUEST:
      return {
        ...state,
        isNupayLoading: true
      };

    default:
      return state;
  }
};

export default nupayReducer;
