import { CartOptional } from '~/interfaces/general';
import { OptionPricingTypeEnum } from '~/interfaces';

import currency from 'currency.js';
import truncateAmount from '../truncateAmount';

export interface OptionalGroupPriceProps {
  currentTotal: number;
  newOptions: CartOptional[];
  currentOptions: CartOptional[];
  pricingType?: OptionPricingTypeEnum;
}

export interface OptionalGroupPrice {
  total: number;
  group: number;
}

export default function calculateOptionalGroupPrice({
  newOptions,
  pricingType,
  currentTotal,
  currentOptions
}: OptionalGroupPriceProps): OptionalGroupPrice {
  let newValue = 0;
  let currentValue = 0;

  // const ignoreCent = (value: number) => (value <= 0.01 ? 0 : value);

  const calculateOptionValue = (optionList: CartOptional[]) => {
    let optionQuantity = 0;

    const priceList = optionList.map(({ price, quantity }) => {
      optionQuantity = currency(optionQuantity).add(quantity).value;
      return currency(price).multiply(quantity).value;
    });

    const unitPriceList = optionList.map(({ price }) => price);

    return { priceList, unitPriceList, quantity: optionQuantity };
  };

  const newOptionValue = calculateOptionValue(newOptions);
  const currentOptionValue = calculateOptionValue(currentOptions);

  const newSum = newOptionValue.priceList.reduce((current, value) => currency(current).add(value).value, 0);
  const currentSum = currentOptionValue.priceList.reduce((current, value) => currency(current).add(value).value, 0);

  if (!pricingType || pricingType === OptionPricingTypeEnum.Individual) {
    newValue = newSum;
    currentValue = currentSum;
  }

  if (pricingType === OptionPricingTypeEnum.Medium) {
    newValue = newSum ? truncateAmount(newSum / newOptionValue.quantity) : newSum;
    currentValue = currentSum ? truncateAmount(currentSum / currentOptionValue.quantity) : currentSum;
  }

  if (pricingType === OptionPricingTypeEnum.Bigger) {
    newValue = newOptionValue.priceList.length > 0 ? Math.max(...newOptionValue.unitPriceList) : 0;
    currentValue = currentOptionValue.priceList.length > 0 ? Math.max(...currentOptionValue.unitPriceList) : 0;
  }

  const total = currentTotal - currentValue + newValue;

  return {
    total,
    group: newValue
  };
}
