import React from 'react';

const NoAddressFound = () => {
  return (
    <svg width="83" height="100" viewBox="0 0 83 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M70.7895 70.7105L41.5 100L12.2105 70.7105C6.41766 64.9176 2.47271 57.537 0.874495 49.5021C-0.723717 41.4671 0.096594 33.1387 3.23169 25.57C6.36679 18.0012 11.6759 11.5321 18.4876 6.98072C25.2993 2.42931 33.3076 0 41.5 0C49.6923 0 57.7007 2.42931 64.5124 6.98072C71.3241 11.5321 76.6332 18.0012 79.7683 25.57C82.9034 33.1387 83.7237 41.4671 82.1255 49.5021C80.5273 57.537 76.5823 64.9176 70.7895 70.7105ZM41.5 59.8305C46.3825 59.8305 51.065 57.8909 54.5175 54.4385C57.9699 50.986 59.9095 46.3035 59.9095 41.421C59.9095 36.5385 57.9699 31.856 54.5175 28.4035C51.065 24.9511 46.3825 23.0115 41.5 23.0115C36.6175 23.0115 31.935 24.9511 28.4825 28.4035C25.0301 31.856 23.0905 36.5385 23.0905 41.421C23.0905 46.3035 25.0301 50.986 28.4825 54.4385C31.935 57.8909 36.6175 59.8305 41.5 59.8305ZM41.5 50.6257C39.0587 50.6257 36.7175 49.656 34.9913 47.9297C33.265 46.2035 32.2952 43.8622 32.2952 41.421C32.2952 38.9797 33.265 36.6385 34.9913 34.9123C36.7175 33.186 39.0587 32.2162 41.5 32.2162C43.9412 32.2162 46.2825 33.186 48.0087 34.9123C49.735 36.6385 50.7047 38.9797 50.7047 41.421C50.7047 43.8622 49.735 46.2035 48.0087 47.9297C46.2825 49.656 43.9412 50.6257 41.5 50.6257Z"
        fill="#7F8F9F"
      />
    </svg>
  );
};

export default NoAddressFound;
