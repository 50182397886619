import React from 'react';

interface TimesIconProps {
  color?: string;
}

const TimesIcon = ({ color = '#485460' }: TimesIconProps) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        d="M7.40969 6.99524L11.7062 2.70877C11.8943 2.52063 12 2.26544 12 1.99936C12 1.73328 11.8943 1.47809 11.7062 1.28994C11.518 1.10179 11.2628 0.996094 10.9967 0.996094C10.7307 0.996094 10.4755 1.10179 10.2873 1.28994L6.00085 5.5864L1.71439 1.28994C1.52624 1.10179 1.27105 0.996094 1.00497 0.996094C0.738888 0.996094 0.483703 1.10179 0.295555 1.28994C0.107406 1.47809 0.0017052 1.73328 0.00170519 1.99936C0.00170519 2.26544 0.107406 2.52063 0.295555 2.70877L4.59201 6.99524L0.295555 11.2817C0.201903 11.3746 0.127571 11.4851 0.0768438 11.6069C0.026117 11.7286 0 11.8592 0 11.9911C0 12.123 0.026117 12.2536 0.0768438 12.3754C0.127571 12.4971 0.201903 12.6077 0.295555 12.7005C0.388441 12.7942 0.498951 12.8685 0.62071 12.9193C0.742469 12.97 0.873067 12.9961 1.00497 12.9961C1.13687 12.9961 1.26747 12.97 1.38923 12.9193C1.51099 12.8685 1.6215 12.7942 1.71439 12.7005L6.00085 8.40408L10.2873 12.7005C10.3802 12.7942 10.4907 12.8685 10.6125 12.9193C10.7342 12.97 10.8648 12.9961 10.9967 12.9961C11.1286 12.9961 11.2592 12.97 11.381 12.9193C11.5028 12.8685 11.6133 12.7942 11.7062 12.7005C11.7998 12.6077 11.8741 12.4971 11.9249 12.3754C11.9756 12.2536 12.0017 12.123 12.0017 11.9911C12.0017 11.8592 11.9756 11.7286 11.9249 11.6069C11.8741 11.4851 11.7998 11.3746 11.7062 11.2817L7.40969 6.99524Z"
      />
    </svg>
  );
};

export default TimesIcon;
