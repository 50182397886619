import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

const HOME = process.env.NEXT_PUBLIC_HOME_URL || '';
const SUBSCRIPTION = process.env.NEXT_PUBLIC_SUBSCRIPTION_URL || '';

const globalWindow =
  typeof window !== 'undefined'
    ? (window as any)
    : { location: { pathname: '', host: '', href: '', assign: () => {} } };

export default function useSlug(): string {
  const slug = useSelector((state: IApplicationState) => state.establishment.settings?.slug);

  if (slug) return slug;

  const links: Record<string, string | undefined> = {
    '/': HOME,
    '/cadastro': SUBSCRIPTION
  };

  const currentPathname: string = globalWindow.location.pathname;
  const host: string = globalWindow.location.host;

  const hostWithoutWWW = host.replace('www.', '');
  const urlParts = hostWithoutWWW.split('.');

  const splitPathname = currentPathname.split('/');

  const isLegacyURL = urlParts[0] === 'goomer' && urlParts.length === 2 && splitPathname.length >= 2;

  if (isLegacyURL) {
    const slug = splitPathname[1];

    return slug;
  }

  if (urlParts.length >= 2) {
    return urlParts[0];
  }

  globalWindow.location.assign(links[currentPathname] || HOME);

  return '';
}
