import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

export default function useCheckoutUserInfo() {
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const user = useSelector((state: IApplicationState) => state.user.data);

  const userPaymentPreference = useMemo(
    () => user?.paymentPreference?.find((payment) => payment.establishment === settings?.name),
    [settings, user]
  );

  const userCreditCard = useMemo(
    () => user.cards?.find((card) => card.number === userPaymentPreference?.cardNumber),
    [user, userPaymentPreference]
  );

  return {
    userCreditCard,
    userPaymentPreference,
    isAuthenticated: user.isAuthenticated,
    authenticationToken: user.authenticationToken
  };
}
