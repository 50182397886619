import styled, { css } from 'styled-components';

export const Container = styled.div<{ height: number }>`
  ${({ theme, height }) => css`
    width: 100%;
    height: ${`${height}px`};
    border-radius: 60px;

    background-color: ${theme.colors.gray400};
  `}
`;

export const ProgressBar = styled.div<{ width: number; color?: string }>`
  ${({ theme, width, color }) => css`
    width: ${`${width}%`};
    height: 100%;
    border-radius: 60px;

    background-color: ${color ? color : theme.brandColors.primary};
  `}
`;
