import { useCallback } from 'react';
import { WeekdaysShortTextEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { ReferenceId } from '~/interfaces/general';
import { Group, Price, Product } from '~/interfaces';
import { FetchMenusResponse } from '~/services/fetchMenus';

import useTranslator from '../useTranslator';

interface UseParserFetchMenusResponseReturnProps {
  parserFetchMenusResponse: (oldResponse: { products: OldProduct[] }) => FetchMenusResponse;
}

interface OldProductPrice {
  id: number;
  name: string;
  price: number;
  hash?: string;
  code?: ReferenceId;
}

interface OldCategoryOrProductHours {
  open: string;
  close: string;
  enable: 0 | 1;
  to: WeekdaysShortTextEnum;
  from: WeekdaysShortTextEnum;
}

export interface OldProduct {
  id: number;
  name: string;
  version: string;
  group_id: number;
  group_name: string;
  limit_age: boolean;
  min_price?: number;
  price_tag?: number;
  description: string;
  unavailable?: boolean;
  is_available: boolean;
  min_price_flag: boolean;
  prices: OldProductPrice[];
  suggestions?: OldProduct[];
  hours: OldCategoryOrProductHours[];
  group_hours: OldCategoryOrProductHours[];
  images: {
    small: string;
    medium: string;
    large: string;
  };
}

export default function useParserFetchMenusResponse(): UseParserFetchMenusResponseReturnProps {
  const { getTranslation } = useTranslator();

  const parserFetchMenusResponse = useCallback(
    (oldResponse: { products: OldProduct[] }): FetchMenusResponse => {
      const groupList: Group[] = [];

      oldResponse.products?.map((product) => {
        const existingGroup = groupList.find((group) => group.id === product.group_id);

        if (!existingGroup) {
          const oldProductList: OldProduct[] = oldResponse.products.filter(
            (oldProduct) => oldProduct.group_id === product.group_id
          );

          const newProductList: Product[] = oldProductList?.map((newProduct) => ({
            id: newProduct.id,
            is_available: true,
            name: newProduct.name,
            version: newProduct.version,
            limit_age: newProduct.limit_age,
            available_hours: newProduct.hours,
            description: newProduct.description,
            min_price_flag: newProduct.min_price_flag,
            images: newProduct.images ? [newProduct.images] : [],
            price: newProduct.price_tag || newProduct.min_price || 0,
            visual_flags: {
              is_vegan: false,
              spiciness_level: 0,
              is_vegetarian: false,
              is_recommended: false,
              is_gluten_free: false,
              is_lactose_free: false
            },
            prices: newProduct.prices.reduce((previusPrice, currentPrice) => {
              previusPrice.push({
                flow_ids: [],
                id: currentPrice.id,
                promotion: undefined,
                hash: currentPrice.hash,
                name: currentPrice.name,
                price: currentPrice.price,
                code: currentPrice.code ? String(currentPrice.code) : undefined
              });

              return previusPrice;
            }, new Array<Price>()),
            suggestions: newProduct.suggestions
              ? newProduct.suggestions.reduce((previusSuggestion, currentSuggestion) => {
                  previusSuggestion.push({
                    is_available: true,
                    id: currentSuggestion.id,
                    name: currentSuggestion.name,
                    version: currentSuggestion.version,
                    limit_age: currentSuggestion.limit_age,
                    price: currentSuggestion.min_price || 0,
                    available_hours: currentSuggestion.hours,
                    description: currentSuggestion.description,
                    min_price_flag: currentSuggestion.min_price_flag,
                    images: currentSuggestion.images ? [currentSuggestion.images] : [],
                    visual_flags: {
                      is_vegan: false,
                      spiciness_level: 0,
                      is_vegetarian: false,
                      is_recommended: false,
                      is_gluten_free: false,
                      is_lactose_free: false
                    },
                    prices: currentSuggestion.prices.reduce((previusPrice, currentPrice) => {
                      previusPrice.push({
                        flow_ids: [],
                        id: currentPrice.id,
                        promotion: undefined,
                        name: currentPrice.name,
                        hash: currentPrice.hash,
                        price: currentPrice.price,
                        code: currentPrice.code ? String(currentPrice.code) : undefined
                      });

                      return previusPrice;
                    }, new Array<Price>())
                  });

                  return previusSuggestion;
                }, new Array<Omit<Product, 'suggestions'>>())
              : undefined
          }));

          groupList.push({
            is_available: true,
            id: product.group_id,
            products: newProductList,
            name: product.group_name,
            available_hours: product.group_hours
          });
        }
      });

      const newResponse: FetchMenusResponse = {
        highlights: [],
        menus: [
          {
            id: 1,
            icon_url: '',
            is_main: true,
            groups: groupList,
            available_hours: [],
            name: getTranslation('general.principalMenu')
          }
        ]
      };

      return newResponse;
    },
    [getTranslation]
  );

  return { parserFetchMenusResponse };
}
