import styled, { css } from 'styled-components';

const containerModifiers = {
  isScrolled: () => css`
    top: 0;
    left: 0;
    z-index: 10;
    position: fixed;

    height: 72px;

    .content {
      justify-content: flex-start;

      h1 {
        font-size: 14px;
        line-height: 16px;
      }

      img {
        display: none;
      }
    }
  `
};

export const ContainerDesktop = styled.div<{ isScrolled: boolean }>`
  ${({ theme, isScrolled }) => css`
    width: 100%;
    height: 72px;
    padding: 8px 32px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: ${theme.colors.gray200};

    select {
      width: 100%;
      max-width: 752px;
      padding: 8px 16px;
      border: 1px solid ${theme.colors.mediumGray};
      border-radius: 4px;

      background: ${theme.colors.backgroundColor};
    }

    ${isScrolled && containerModifiers.isScrolled()}
  `}
`;

export const Search = styled.div`
  ${({ theme }) => css`
    width: 40px;
    height: 39px;
    margin-right: 8px;
    border-radius: 6px;
    padding: 7px 13px !important;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.backgroundColor};

    cursor: pointer;
  `}
`;
