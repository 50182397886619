import React from 'react';

const BrazilFlagIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <circle id="border" cx="17" cy="17" r="16.5" fill="#6DA544" stroke="#DDE2EB" />
      <path d="M30.5999 16.9967L16.9999 25.8L3.3999 16.9967L16.9999 8.19995L30.5999 16.9967Z" fill="#FFDA44" />
      <path
        fill="#0052B4"
        d="M22.7175 16.9967C22.7163 15.8537 22.3797 14.7368 21.7503 13.787C21.121 12.8373 20.2271 12.0974 19.1817 11.6609C18.1363 11.2244 16.9863 11.1109 15.877 11.3346C14.7677 11.5584 13.749 12.1094 12.9496 12.9181C12.1502 13.7267 11.606 14.7567 11.3858 15.8778C11.1656 16.9989 11.2793 18.1608 11.7124 19.2167C12.1456 20.2725 12.8788 21.1749 13.8194 21.8098C14.76 22.4447 15.8658 22.7835 16.997 22.7835C17.749 22.7844 18.4938 22.6352 19.1886 22.3446C19.8835 22.054 20.5147 21.6277 21.0462 21.0901C21.5776 20.5525 21.9988 19.9142 22.2856 19.2117C22.5725 18.5093 22.7192 17.7565 22.7175 16.9967Z"
      />
      <path
        fill="white"
        d="M16.9078 14.6146C15.1748 14.2835 13.3893 14.3655 11.6933 14.8541C11.5088 15.3168 11.386 15.8022 11.3281 16.2975C13.0597 15.7667 14.8927 15.6667 16.6708 16.0062C18.8399 16.3016 20.8595 17.288 22.4362 18.822C22.5837 18.3822 22.6762 17.9256 22.7116 17.4627C21.1309 15.9067 19.0954 14.9079 16.9078 14.6146Z"
      />
    </svg>
  );
};

export default BrazilFlagIcon;
