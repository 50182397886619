import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { getTermIntoString } from '@goomerdev/goomer-toolbox/src/utils';

import { useGetWhatsMessage } from '~/hooks';
import { CartState } from '~/redux-tools/store/cart';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { generateWhatsappLink, removeNoNumbers } from '~/utils';
import useCheckoutCartInfo from '~/hooks/useCheckout/hooks/useCheckoutCartInfo';
import { IUser, IOrder, DeliveryWay, serializeOrderForGA, OrderPayload } from '~/interfaces/general';

interface SendWhatsProps {
  cart?: CartState;
  currentUser: IUser;
  withPWAInfo?: boolean;
  customMessage?: string;
  mpPaymentLink?: string;
  isShortMessage?: boolean;
  order_id?: number | string;
  orders_count?: number | string;
}

interface UseCheckoutSendWhatsappProps {
  order: IOrder;
  deliveryWay: DeliveryWay;
  orderPayload: OrderPayload;
}

let whatsappUrl = '';

export default function useCheckoutSendWhatsapp({ order, deliveryWay, orderPayload }: UseCheckoutSendWhatsappProps) {
  const user = useSelector((state: IApplicationState) => state.user);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const nupayDocument = user.data?.paymentPreference && user.data?.paymentPreference[0]?.document;

  const isTakeaway = useMemo(() => {
    return (
      getTermIntoString({ term: 'retir', word: order?.deliveryOption || '' }) ||
      getTermIntoString({ term: 'takeaway', word: order?.deliveryOption || '' })
    );
  }, [order?.deliveryOption]);

  const { getWhatsMessage } = useGetWhatsMessage();
  const { cartInfo } = useCheckoutCartInfo({ isTakeaway });

  if (!order || !deliveryWay || !orderPayload) {
    return { sendToWhatsapp: () => {} };
  }

  function sendToWhatsapp({
    cart,
    order_id,
    currentUser,
    orders_count,
    customMessage,
    mpPaymentLink,
    isShortMessage,
    withPWAInfo = true
  }: SendWhatsProps) {
    const message =
      settings &&
      getWhatsMessage({
        settings,
        withPWAInfo,
        customMessage,
        nupayDocument,
        isShortMessage,
        client: currentUser,
        linkURL: mpPaymentLink,
        deliveryWay: deliveryWay,
        cart: cart || cartInfo(),
        coupon: orderPayload.coupon,
        orderID: String(orders_count),
        moneyChangeValue: order.moneyChangeValue,
        paymentOption: order.paymentOption || '',
        schedule: {
          is_scheduled: orderPayload.is_scheduled,
          scheduled_to: orderPayload.scheduled_to_local_timezone
        }
      });

    GoogleAnalytics.trackEvent(gaEvents.sendOrderWhatsapp, serializeOrderForGA(orderPayload));

    const whatsFormattedPhone = (() => {
      const phone = settings?.mm_whatsapp_phone_number || '';

      if (!settings) return `55${removeNoNumbers(phone)}`;

      const mm_phone = settings.mm_whatsapp_phone_number;

      return removeNoNumbers(mm_phone);
    })();

    whatsappUrl = generateWhatsappLink({
      message: String(message),
      phone: whatsFormattedPhone
    });

    if (message?.includes('undefined')) {
      throw new Error(
        `#02 ID do Pedido indefinido, Message: ${`orderResponse.orders_count - ${orders_count} -- orderID - ${order_id}`}`
      );
    }

    setTimeout(() => {
      window.open(whatsappUrl, '_self');
    }, 1000);

    setTimeout(() => {
      const str = navigator.userAgent;
      const i = str.indexOf('Instagram');

      if (i !== -1) {
        return window.close();
      }

      if (isMobile) {
        return window.location.reload();
      }
    }, 2000);
  }

  return { sendToWhatsapp };
}
