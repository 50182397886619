import { toast } from 'react-toastify';
import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from '@goomerdev/goomer-toolbox/src/hooks';

import { IApplicationState } from '~/redux-tools/store';
import { getTabInfo } from '~/redux-tools/store/localOrders/actions';
import { QrCodeHashReaderResult, TabInfo } from '~/interfaces/general';

import useTranslator from '../useTranslator';

export default function useTabInfo(): TabInfo | undefined {
  const dispatch = useDispatch();

  const { qrCodeInfo, isLoadingInfo } = useSelector((state: IApplicationState) => state.localOrders);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const { getTranslation } = useTranslator();
  const { localStorageValue } = useLocalStorage({ key: 'qrCodeHashReader' });

  const qrCodeHashReader: QrCodeHashReaderResult = useMemo(
    () => JSON.parse(localStorageValue || '{}') as QrCodeHashReaderResult,
    [localStorageValue]
  );

  const currentTabInfo: TabInfo | undefined = useMemo(() => {
    if (!qrCodeInfo || isLoadingInfo || !qrCodeHashReader?.result) {
      return;
    }

    return qrCodeInfo;
  }, [isLoadingInfo, qrCodeHashReader?.result, qrCodeInfo]);

  useEffect(() => {
    if (!settings || !qrCodeHashReader?.result) {
      return;
    }

    if (qrCodeInfo?.text === qrCodeHashReader?.result) {
      return;
    }

    if (qrCodeHashReader?.result) {
      dispatch(
        getTabInfo({
          aId: settings?.mm_a_id,
          hash: qrCodeHashReader.result,
          callback: (isOk) => {
            if (!isOk) {
              toast.error(getTranslation('errorMessage.tabInfoError'));
              return;
            }
          }
        })
      );
    }
  }, [dispatch, getTranslation, qrCodeHashReader, qrCodeInfo, settings]);

  return currentTabInfo;
}
