import { action } from 'typesafe-actions';

import { Thirky } from '~/interfaces/general';

import { ThirkyActionTypes } from './types';

export interface GetThirkySearchRequestProps {
  userId: string;
}

export const getThirkySearchRequest = ({ userId }: GetThirkySearchRequestProps) =>
  action(ThirkyActionTypes.GET_THIRKY_SEARCH_REQUEST, { userId });

export const getThirkySearchSuccess = (thirky: Thirky) => action(ThirkyActionTypes.GET_THIRKY_SEARCH_SUCCESS, thirky);

export const getThirkySearchError = () => action(ThirkyActionTypes.GET_THIRKY_SEARCH_ERROR);
