import React, { useCallback, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import Modal from '~/components/Modal/Modal';
import LoyaltyStar from '~/assets/LoyaltyStar';
import Button from '~/components/Button/Button';
import { IApplicationState } from '~/redux-tools/store';
import { RiCoupon2Line, RiDeleteBinLine } from 'react-icons/ri';
import { cleanCoupon } from '~/redux-tools/store/coupons/actions';
import { DiscountTypeEnum, SegmentTypeEnum } from '~/interfaces/enums';
import { Container, Content, IconWrapper } from '~/components/Checkout/CheckoutSelect';

import * as S from './styles';

export interface CouponProps {
  onClick: () => void;
  isLoyaltyCoupon?: boolean;
}

const Coupon = ({ onClick, isLoyaltyCoupon = false }: CouponProps): JSX.Element => {
  const dispatch = useDispatch();

  const { coupon } = useSelector((state: IApplicationState) => state.cart.values);
  const { data, error } = useSelector((state: IApplicationState) => state.coupons);

  const [shouldDisplayDeleteModal, setShouldDisplayDeleteModal] = useState(false);

  const { getTranslation } = useTranslator();

  const { selectedCoupon } = data;

  const discountText: string = useMemo(() => {
    if (!selectedCoupon) return '';

    if (selectedCoupon.type === DiscountTypeEnum.percent) {
      return getTranslation('discount.percentApplied', {
        value: selectedCoupon.value,
        text: !error ? ` ${getTranslation('$t(discount.applied, lowercase)')}` : ''
      });
    }

    const [value, cents] = convertToCurrency(Number(selectedCoupon.value)).replace(/\s/g, '').split(',');

    const formattedValue = `${value}${Number(cents) > 0 ? `,${cents}` : ''}`;

    return getTranslation('discount.discountApplied', {
      value: formattedValue,
      text: !error ? ` ${getTranslation('$t(discount.applied, lowercase)')}` : ''
    });
  }, [error, getTranslation, selectedCoupon]);

  const isTheMaxDiscountReached = useMemo(
    () =>
      selectedCoupon?.type === DiscountTypeEnum.percent &&
      Number(coupon) === Number(selectedCoupon?.discount_max_value),
    [coupon, selectedCoupon]
  );

  const handleCouponClick = useCallback(() => {
    if (selectedCoupon?.segment === SegmentTypeEnum.first_order) {
      return;
    }

    return onClick();
  }, [onClick, selectedCoupon?.segment]);

  const handleCleanCoupon = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();

    setShouldDisplayDeleteModal(true);
  }, []);

  const renderCoupon = useMemo(() => {
    if (isLoyaltyCoupon) {
      return (
        <S.LoyaltyContainer>
          <S.LoyaltyIconWrapper>
            <LoyaltyStar />
          </S.LoyaltyIconWrapper>

          <S.CouponInfoWrap isCouponApplied>
            <strong className="name">{getTranslation('discount.couponApplied')}</strong>

            <p className="loyalty-description">{getTranslation('discount.loyaltyDiscount')}</p>
          </S.CouponInfoWrap>
        </S.LoyaltyContainer>
      );
    }

    return (
      <>
        <Container
          onClick={handleCouponClick}
          data-test="btn-insert-coupon"
          aria-label={getTranslation('discount.couponAriaLabel')}
          isFirstOrderDiscount={selectedCoupon?.segment === SegmentTypeEnum.first_order}
        >
          <RiCoupon2Line size={20} />

          <Content>
            {selectedCoupon ? (
              <S.CouponInfo>
                <S.CouponInfoWrap isCouponApplied={!error && !isTheMaxDiscountReached}>
                  <strong className="name">
                    {selectedCoupon.segment === SegmentTypeEnum.first_order
                      ? getTranslation('general.firstOrder')
                      : selectedCoupon.code}
                  </strong>

                  <span className="discount">{discountText}</span>
                </S.CouponInfoWrap>

                {!!error && <p className="info">{error}</p>}

                {isTheMaxDiscountReached && (
                  <p className="info">
                    {getTranslation('discount.discountMaxValue', {
                      value: convertToCurrency(Number(selectedCoupon.discount_max_value))
                    })}
                  </p>
                )}
              </S.CouponInfo>
            ) : (
              <span className="link-like">{getTranslation('discount.insertCoupon')}</span>
            )}
          </Content>

          {selectedCoupon && selectedCoupon.segment !== SegmentTypeEnum.first_order && (
            <IconWrapper $isDelete onClick={handleCleanCoupon}>
              <RiDeleteBinLine size={24} />
            </IconWrapper>
          )}
        </Container>

        <Modal
          isSmall
          isShow={shouldDisplayDeleteModal}
          headerTitle={getTranslation('discount.deleteCoupon')}
          onClose={(): void => setShouldDisplayDeleteModal(false)}
        >
          <S.ModalContent>
            <Button
              aria-label={getTranslation('general.cancel')}
              onClick={(): void => setShouldDisplayDeleteModal(false)}
            >
              {getTranslation('general.cancel')}
            </Button>

            <Button
              isDanger
              isGhost={false}
              aria-label={getTranslation('general.remove')}
              onClick={(): void => {
                dispatch(cleanCoupon());
                setShouldDisplayDeleteModal(false);
              }}
            >
              {`${getTranslation('general.yesRemove')} ${getTranslation('general.coupon').toLowerCase()}`}
            </Button>
          </S.ModalContent>
        </Modal>
      </>
    );
  }, [
    error,
    dispatch,
    discountText,
    getTranslation,
    selectedCoupon,
    isLoyaltyCoupon,
    handleCouponClick,
    handleCleanCoupon,
    isTheMaxDiscountReached,
    shouldDisplayDeleteModal
  ]);

  return renderCoupon;
};

export default Coupon;
