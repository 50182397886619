import React, { useMemo, useState, useCallback } from 'react';

import { format } from 'date-fns';
import br from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { generateWhatsappLink } from '~/utils';
import { DeliveryWayEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { cleanLastOrder } from '~/redux-tools/store/lastOrder/actions';
import { MountedOrder, serializeOrderForGA } from '~/interfaces/general';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';
import { useTranslator, useGetWhatsMessage, useShowOrdersCount, useGetPaymentMethodDescription } from '~/hooks';
import {
  Button,
  NewWaves,
  OrderInfo,
  CheckOrder,
  OrderSummary,
  PaymentChosen,
  AddressDeliveryInfo
} from '~/components';

import * as S from './styles';

interface LastOrderProps {
  isVisible?: boolean;
  isDetailView?: boolean;
  orderDetails?: MountedOrder;
  onChange?: (value: boolean) => void;
}

const LastOrder: React.FC<LastOrderProps> = ({
  onChange,
  orderDetails,
  isDetailView,
  isVisible = false
}): JSX.Element => {
  const dispatch = useDispatch();

  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const lastOrder = useSelector((state: IApplicationState) => state.lastOrder.order);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const phone = useSelector((state: IApplicationState) => state.establishment.settings?.mm_whatsapp_phone_number);

  const orderToUse = useMemo(() => orderDetails || lastOrder, [orderDetails, lastOrder]);

  const { user, date, cart, order, linkURL, address, orderCount } = orderToUse;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getTranslation } = useTranslator();
  const { getWhatsMessage } = useGetWhatsMessage();
  const { getPaymentMethodDescription } = useGetPaymentMethodDescription();
  const { showOrdersCount: shouldDisplayOrdersCount } = useShowOrdersCount();

  const removingNonNumberCharacters = useCallback((value: string) => value.replace(/\D/g, ''), []);

  const id = useMemo(() => orderCount && String(orderCount).padStart(4, '0'), [orderCount]);

  const whatsFormattedPhone = useMemo(() => {
    if (!settings) return `55${removingNonNumberCharacters(phone || '')}`;

    const mmPhone = settings.mm_whatsapp_phone_number;

    return removingNonNumberCharacters(mmPhone);
  }, [removingNonNumberCharacters, settings, phone]);

  const createdAt = useMemo(() => date && format(new Date(date), 'dd/MM/yyyy - HH:mm', { locale: br }), [date]);

  const handleCloseModal = useCallback(() => {
    if (!isDetailView) {
      dispatch(cleanLastOrder());
      GoogleAnalytics.trackEvent(gaEvents.whatsAppBackMenu);
    }

    onChange?.(false);
  }, [dispatch, isDetailView, onChange]);

  const handleSendOrder = useCallback(() => {
    setIsLoading(true);

    const scheduledTo = order.scheduled_to_local_timezone ? new Date(order.scheduled_to_local_timezone) : undefined;

    try {
      const message = settings
        ? getWhatsMessage({
            cart,
            settings,
            client: user,
            coupon: order.coupon,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            linkURL: linkURL || '',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            orderID: String(id) || '',
            deliveryWay: order.delivery_way,
            paymentOption: order.payment_type || '',
            moneyChangeValue: order.payment_money_change,
            schedule: {
              // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
              scheduled_to: scheduledTo,
              // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
              is_scheduled: order.is_scheduled
            }
          })
        : '';

      const whatsappUrl = generateWhatsappLink({ message, phone: whatsFormattedPhone });

      GoogleAnalytics.trackEvent(gaEvents.resendOrderWhatsapp, serializeOrderForGA(order));

      setTimeout(() => {
        window.open(whatsappUrl, '_self');
      }, 1000);

      setTimeout(() => {
        dispatch(cleanLastOrder());

        const userAgent = navigator.userAgent;
        const index = userAgent.indexOf('Instagram');
        if (index !== -1) {
          window.close();
        }
      }, 2000);
    } catch (error) {
      toast.error(getTranslation('errors.orderError04'));
    } finally {
      setIsLoading(false);
    }
  }, [order, settings, cart, user, linkURL, id, whatsFormattedPhone, dispatch, getTranslation, getWhatsMessage]);

  return (
    <S.Container
      isPageLike
      isShow={isVisible}
      aria-hidden={!isVisible}
      onClose={handleCloseModal}
      aria-label="last-order-modal"
      hideCloseButton={!isDetailView}
      headerTitle={getTranslation('order.lastOrder')}
    >
      <S.Wrapper>
        <S.Content>
          {!isDetailView && <p className="description">{getTranslation('order.checkOrderDetails')}</p>}

          <S.OrderDetails>
            {id && shouldDisplayOrdersCount && (
              <span className="order-id">
                {getTranslation('general.order')} #{id}
              </span>
            )}

            {createdAt && <span className="subtext">{createdAt}</span>}
          </S.OrderDetails>

          {isDetailView && (
            <S.InfoWrapper>
              <OrderInfo user={user} />
            </S.InfoWrapper>
          )}

          {order?.delivery_way === DeliveryWayEnum.delivery && (
            <S.Address>
              <AddressDeliveryInfo address={address} />
            </S.Address>
          )}

          <CheckOrder.CheckOrder noActions hideTotal hideZeroedOrderMessage total={cart?.total || 0}>
            {cart?.products.map((item, index) => (
              <CheckOrder.CheckOrderItem
                noActions
                fullDivider
                key={index}
                item={item}
                getTranslation={getTranslation}
              />
            ))}
          </CheckOrder.CheckOrder>

          <S.SummaryWrapper>
            <OrderSummary values={cart.values} />
          </S.SummaryWrapper>

          {order?.payment_type && (
            <S.PaymentWrapper>
              <PaymentChosen
                hideAction
                onClick={(): void => {}}
                paymentMethod={order.payment_type}
                title={order.payment_type.type || ''}
                isPix={order.payment_type.category === PaymentCategoryEnum.pix}
                moneyChange={order.payment_money_change ? order.payment_money_change : 0}
                isCashPayment={order.payment_type?.category === PaymentCategoryEnum.cash}
                description={getPaymentMethodDescription({ paymentMethod: order.payment_type })}
              />
            </S.PaymentWrapper>
          )}
        </S.Content>
      </S.Wrapper>

      <NewWaves backgroundColor={theme.colors.gray200} />

      {isDetailView ? (
        <S.Footer>
          <Button data-test="btn-close-details" onClick={handleCloseModal} isGhost>
            {getTranslation('general.close')}
          </Button>
        </S.Footer>
      ) : (
        <S.Footer>
          <p className="question">{getTranslation('order.problemSendingOrder')}</p>

          <Button isGhost={false} onClick={handleSendOrder} isLoading={isLoading}>
            {isLoading ? `${getTranslation('general.sending')}...` : getTranslation('general.tryAgain')}
          </Button>

          <Button data-test="btn-back-menu" onClick={handleCloseModal}>
            {getTranslation('navigation.returnToMenu')}
          </Button>
        </S.Footer>
      )}
    </S.Container>
  );
};

export default LastOrder;
