import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

export default function useAddressIsNewDashboard() {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const isNewDashboard: boolean = useMemo(() => !!settings, [settings]);

  return { isNewDashboard };
}
