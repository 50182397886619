import hexToRgba from 'hex-to-rgba';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $scanRegionHeight: number }>`
  ${({ theme, $scanRegionHeight }) => css`
    height: inherit;

    display: flex;
    place-content: center;

    line-height: 0;
    overflow: hidden;
    z-index: 1900;

    .qr-video {
      min-height: 100dvh;
    }

    .scan-region-highlight {
      border-radius: 1rem;
      outline: ${hexToRgba(theme.colors.pureTextColor, 0.25)} solid 50vmax;
    }

    .scan-region-highlight-svg {
      display: none;
    }

    .goomer-overlay {
      position: absolute;
      top: 0;
      padding: 1.5rem;

      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .header {
        height: 50%;
        padding-bottom: calc(${$scanRegionHeight / 2}px + 2.5rem);

        align-content: flex-end;

        > h1 {
          margin-top: 0.5rem;

          font-size: 1rem;
          font-weight: 600;
          line-height: 1.4rem;
          color: ${theme.colors.backgroundColor};

          > strong {
            color: ${theme.colors.backgroundColor};
          }
        }

        > svg {
          fill: ${theme.colors.backgroundColor};
        }
      }

      > .goomer-experience {
        height: 50%;
        width: 100%;

        align-items: end;
        justify-content: center;

        > p {
          color: ${theme.colors.backgroundColor};
        }

        > svg {
          > path {
            fill: ${theme.colors.backgroundColor};
          }
        }
      }
    }

      .camera-switch-icon {
      padding: 1rem;

      left: 50%;
      bottom: 10vh;
      position: fixed;
      transform: translateX(-50%);

      cursor: pointer;

      > svg {
        fill: ${theme.colors.backgroundColor};
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
  `};
`;
