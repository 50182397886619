import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { RiArrowLeftLine, RiCloseLine } from 'react-icons/ri';

import { Button } from '~/components';
import { CloseIcon, TimesIcon } from '~/assets';
import { IApplicationState } from '~/redux-tools/store';
import { ModalCloseButtonTypesEnum } from '~/interfaces/enums';
import isThisChromeVersionSupported from '~/utils/isThisChromeVersionSupported/index';

import * as S from './styles';

export interface ModalProps {
  isShow: boolean;
  height?: string;
  isSmall?: boolean;
  className?: string;
  isDrawer?: boolean;
  footerTitle?: string;
  isPageLike?: boolean;
  headerTitle?: string;
  isGhostFooter?: boolean;
  buttonSvgColor?: string;
  ignoreOverflow?: boolean;
  hideCloseButton?: boolean;
  isContentVisible?: boolean;
  onFooterClick?: () => void;
  customHeader?: JSX.Element;
  isWithoutPadding?: boolean;
  isContentFullPage?: boolean;
  disableFooterButton?: boolean;
  titleBackgroundColor?: string;
  headerChildren?: React.ReactNode;
  isFooterWithGoomerColor?: boolean;
  containerBackgroundColor?: string;
  onClose: (buttonClick: boolean) => void;
  closeButton?: {
    type: ModalCloseButtonTypesEnum;
    color: string;
  };
}

const Modal: React.FC<ModalProps> = ({
  isShow,
  onClose,
  children,
  className,
  closeButton,
  headerTitle,
  footerTitle,
  customHeader,
  onFooterClick,
  headerChildren,
  buttonSvgColor,
  height = '80vh',
  isSmall = false,
  isDrawer = true,
  isPageLike = false,
  titleBackgroundColor,
  isGhostFooter = false,
  ignoreOverflow = false,
  isContentVisible = true,
  hideCloseButton = false,
  containerBackgroundColor,
  isWithoutPadding = false,
  isContentFullPage = false,
  disableFooterButton = false,
  isFooterWithGoomerColor = false,
  ...rest
}) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const [closeButtonProps, setCloseButtonProps] = useState<
    | {
        color: string;
        type: ModalCloseButtonTypesEnum;
      }
    | undefined
  >(closeButton);

  useEffect(() => {
    if (!closeButton) {
      setCloseButtonProps({
        type: ModalCloseButtonTypesEnum.arrow,
        color: headerChildren && isDrawer ? theme.colors.backgroundColor : theme.colors.textColor
      });
    }
  }, [closeButton, headerChildren, isDrawer, theme.colors.backgroundColor, theme.colors.textColor]);

  function toggleOverflowOnBody(type: 'add' | 'remove'): void {
    const body = typeof document !== 'undefined' && document.querySelector('body');
    const html = typeof document !== 'undefined' && document.querySelector('html');

    if (body && html) {
      const addOverflowClassOnBody = (): void => body?.classList[type]('overflow');
      const addOverflowClassOnHtml = (): void => html?.classList[type]('overflow');

      addOverflowClassOnBody();
      addOverflowClassOnHtml();
    }
  }

  if (!ignoreOverflow) {
    if (isShow) {
      toggleOverflowOnBody('add');
    } else {
      toggleOverflowOnBody('remove');
    }
  }

  useEffect(() => {
    if (!isContentVisible && isThisChromeVersionSupported()) {
      const content = document.querySelector('#content');

      if (typeof content?.scrollTo === 'function') return content.scrollTo(0, 0);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [isContentVisible]);

  const renderCloseIcon = useMemo(() => {
    if (hideCloseButton) return;

    if (!isPageLike) {
      return (
        <S.SmallClose>
          <RiCloseLine onClick={(): void => onClose(true)} size={24} />
        </S.SmallClose>
      );
    }

    let closeIcon = <CloseIcon height={12} width={20} color={buttonSvgColor || closeButtonProps?.color} />;

    if (isPageLike) {
      closeIcon = <RiArrowLeftLine size={24} color={buttonSvgColor || closeButtonProps?.color} />;
    }

    return (
      closeButtonProps?.type === ModalCloseButtonTypesEnum.arrow && (
        <Button
          isSimple
          Icon={closeIcon}
          data-test="btn-close-modal"
          onClick={(): void => onClose(true)}
          className={isPageLike ? '' : 'go-back'}
          style={{
            width: 'auto',
            position: 'absolute',
            paddingLeft: '0.5rem'
          }}
        />
      )
    );
  }, [hideCloseButton, buttonSvgColor, closeButtonProps?.color, closeButtonProps?.type, isPageLike, onClose]);

  const headerWithImage = useMemo(
    () => (
      <S.HeaderWithImage isPageLike={isPageLike}>
        <div className="top-header">
          {renderCloseIcon}

          <div className="title">
            {headerTitle && (
              <div className="title-wrapper">
                <h3>{headerTitle}</h3>
              </div>
            )}

            {!!headerChildren && headerChildren}
          </div>
        </div>
      </S.HeaderWithImage>
    ),
    [headerChildren, headerTitle, isPageLike, renderCloseIcon]
  );

  const standardHeader = useMemo(() => {
    if (customHeader || !headerTitle) {
      return <></>;
    }

    return (
      <S.Header $isNotPage={!isPageLike}>
        {renderCloseIcon}

        {headerTitle && <h3 className="title">{headerTitle}</h3>}
      </S.Header>
    );
  }, [customHeader, headerTitle, isPageLike, renderCloseIcon]);

  const renderDrawer = useMemo(
    () => (
      <S.ContainerDrawer
        height={height}
        isShow={isShow}
        isSmall={isSmall}
        isPageLike={isPageLike}
        isWithoutPadding={isWithoutPadding}
        className={`container ${className || ''}`}
        titleBackgroundColor={titleBackgroundColor}
        onClick={(event): void => event.stopPropagation()}
        containerBackgroundColor={containerBackgroundColor}
      >
        {headerChildren ? headerWithImage : standardHeader}

        {closeButtonProps?.type === ModalCloseButtonTypesEnum.times && (
          <Button
            className="times-btn"
            data-test="btn-close-modal"
            onClick={(): void => onClose(true)}
            Icon={<TimesIcon color={buttonSvgColor || closeButtonProps?.color || theme.colors.textColor} />}
          />
        )}

        {customHeader}

        <S.ContentDrawer
          id="content"
          isSmall={isSmall}
          className="content"
          isPageLike={isPageLike}
          isContentFullPage={isContentFullPage}
        >
          {children}
        </S.ContentDrawer>

        {footerTitle && (
          <footer>
            <Button
              isGhost={isGhostFooter}
              onClick={onFooterClick}
              isDisabled={disableFooterButton}
              isGoomerColor={isFooterWithGoomerColor}
            >
              {footerTitle}
            </Button>
          </footer>
        )}
      </S.ContainerDrawer>
    ),
    [
      height,
      isShow,
      onClose,
      isSmall,
      children,
      className,
      isPageLike,
      footerTitle,
      customHeader,
      isGhostFooter,
      onFooterClick,
      buttonSvgColor,
      headerChildren,
      standardHeader,
      headerWithImage,
      isWithoutPadding,
      isContentFullPage,
      disableFooterButton,
      titleBackgroundColor,
      closeButtonProps?.type,
      theme.colors.textColor,
      isFooterWithGoomerColor,
      closeButtonProps?.color,
      containerBackgroundColor
    ]
  );

  const renderModal = useMemo(
    () => (
      <S.ContainerModal className={`container-modal ${className || ''}`}>
        <Button
          className="close-btn"
          data-test="btn-close-modal"
          onClick={(): void => onClose(true)}
          Icon={<TimesIcon color={buttonSvgColor || closeButtonProps?.color || theme.colors.textColor} />}
        />

        <S.ContentModal>{children}</S.ContentModal>
      </S.ContainerModal>
    ),
    [buttonSvgColor, children, className, closeButtonProps?.color, onClose, theme.colors.textColor]
  );

  return (
    <S.Background {...rest} isShow={isShow} isSmall={isSmall} onClick={(): void => onClose(false)}>
      {isDrawer ? renderDrawer : renderModal}
    </S.Background>
  );
};

export default Modal;
export * from './components';
