import { useEffect, useMemo, useState } from 'react';

import { useTranslator } from '~/hooks';
import InputPhone from '~/components/Input/InputPhone';
import { OptionSpacing } from '~/pages/localOrder/styles';
import OptionHeader from '~/components/Option/OptionHeader';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';

import * as S from './styles';

interface OrderPhoneProps {
  clientPhone: string;
  isClientPhoneValid: boolean;
  setClientPhone: (phone: string) => void;
  setIsClientPhoneValid: (status: boolean) => void;
}

const OrderPhone: (props: OrderPhoneProps) => JSX.Element = ({
  clientPhone,
  setClientPhone,
  isClientPhoneValid,
  setIsClientPhoneValid
}) => {
  const [isEventSent, setIsEventSent] = useState<boolean>(false);

  const { getTranslation } = useTranslator();

  const isPhoneFilled = useMemo(() => clientPhone.replace(/\D/g, '').length >= 10, [clientPhone]);

  const isDone = useMemo(() => {
    return isPhoneFilled && isClientPhoneValid;
  }, [isClientPhoneValid, isPhoneFilled]);

  useEffect(() => {
    if (isDone && !isEventSent) {
      setIsEventSent(true);
      GoogleAnalytics.trackEvent(gaEvents.checkoutPhoneFilled);
    }
  }, [isEventSent, isDone, isPhoneFilled]);

  const handlePhoneChange = (phone: string, isValid: boolean): void => {
    setClientPhone(phone);
    setIsClientPhoneValid(isValid);
  };

  return (
    <S.Container>
      <OptionHeader isForm isRequired isDone={isDone} subtitle={false} title={getTranslation('user.phoneNumber')} />

      <OptionSpacing>
        <InputPhone
          required
          data-test="input-phone"
          defaultValue={clientPhone}
          placeholder="(00) 00000-0000"
          onChange={(phoneValue: string, isValid: boolean): void => handlePhoneChange(phoneValue, isValid)}
        />
      </OptionSpacing>

      <S.Info>{getTranslation('user.phoneInfo')}</S.Info>
    </S.Container>
  );
};

export default OrderPhone;
