import React, { useCallback } from 'react';
import { RiMore2Line, RiPencilLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { EditTypeEnum } from '~/interfaces/enums';

import { Container } from './styles';

export interface ButtonResumeProps {
  id?: string;
  isPix?: boolean;
  noFill?: boolean;
  disabled?: boolean;
  onClick: () => void;
  hideAction?: boolean;
  haveContent: boolean;
  editType?: EditTypeEnum;
  editAction?: () => void;
  isDeleteAction?: boolean;
}

const ButtonResume: React.FC<ButtonResumeProps> = ({
  onClick,
  children,
  editAction,
  haveContent,
  isPix = false,
  isDeleteAction,
  noFill = false,
  hideAction = false,
  editType = EditTypeEnum.icon,
  ...rest
}) => {
  const { getTranslation } = useTranslator();

  const onClickAction = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!editAction) {
        return onClick();
      }

      return editAction();
    },
    [editAction, onClick]
  );

  const renderEdit = (): JSX.Element => {
    if (editType === EditTypeEnum.text && isDeleteAction) {
      return (
        <span className="edit remove" onClick={onClickAction}>
          {getTranslation('general.remove')}
        </span>
      );
    }

    if (editType === EditTypeEnum.text) {
      return <span className="edit">{getTranslation('general.change')}</span>;
    }

    if (editType === EditTypeEnum.action) {
      return <RiMore2Line size={20} className="action" onClick={onClickAction} />;
    }

    return <RiPencilLine size={24} className="action" onClick={onClickAction} />;
  };

  return (
    <Container
      isPix={isPix}
      noFill={noFill}
      onClick={onClick}
      haveContent={haveContent}
      isDeleteAction={isDeleteAction}
      {...rest}
    >
      <div className="left">{!!children && <div className="info">{children}</div>}</div>

      {haveContent && !hideAction && !isPix && renderEdit()}
    </Container>
  );
};

export default ButtonResume;
