import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { getTermIntoString } from '@goomerdev/goomer-toolbox/src/utils';

import { OptionHeader } from '~/components';
import Option from '~/components/Option/Option';
import { CheckoutContext } from '~/pages/order';
import { useEstablishmentStatus } from '~/hooks';
import { DeliveryWay } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { MountBadgeReturnProps } from '~/hooks/useMountBadge';
import { DeliveryWayEnum, EstablishmentStatusEnum } from '~/interfaces/enums';

import useGetPaymentInfo from './useGetPaymentInfo';

import * as S from './styles';

export interface CheckoutRadioOptions {
  key: string;
  value: boolean;
}

interface CheckoutGroupOption {
  name: string;
  info?: string;
  value: string;
  show?: boolean;
  groupName: string;
  description?: string;
  badge?: MountBadgeReturnProps;
}

export interface CheckoutOptionProps {
  type: string;
  title: string;
  index?: number | string;
  options?: CheckoutRadioOptions[];
  action?: (value: DeliveryWay) => void;
}

const CheckoutOption: (props: CheckoutOptionProps) => JSX.Element = ({ type, index, title, action, options }) => {
  const cartTotal = useSelector((state: IApplicationState) => state.cart.total);
  const { deliveryFee } = useSelector((state: IApplicationState) => state.cart);
  const info = useSelector((state: IApplicationState) => state.establishment.settings);
  const { deliveryList, takeawayList } = useSelector((state: IApplicationState) => state.scheduling);
  const isOpen = useSelector(({ establishment }: IApplicationState) =>
    establishment?.open ? establishment.open?.isOpen : false
  );

  const [checkoutGroupOptionList, setCheckoutGroupOptionList] = useState<CheckoutGroupOption[]>();

  const { getInfos } = useGetPaymentInfo();
  const { status } = useEstablishmentStatus({});
  const { paymentOption, deliveryOption, schedulingDate, setDeliveryOption } = useContext(CheckoutContext);

  const isAbleToShowOption = useCallback(
    (option: CheckoutRadioOptions) => {
      if (status === EstablishmentStatusEnum.scheduling && !!option.value) {
        if (option.key.includes(DeliveryWayEnum.delivery)) {
          return deliveryList.length > 0;
        }

        if (option.key.includes(DeliveryWayEnum.takeaway)) {
          return takeawayList.length > 0 && info?.mm_takeaway_enabled;
        }
      }

      return option.value;
    },
    [deliveryList.length, info?.mm_takeaway_enabled, status, takeawayList.length]
  );

  useEffect(() => {
    function mountGroupOptions(): void {
      if (options) {
        const shouldHideTime =
          deliveryFee &&
          (('hide_time' in deliveryFee && !!deliveryFee.hide_time) ||
            ('delivery_time_enabled' in deliveryFee && !deliveryFee.delivery_time_enabled));

        const mountedOptionList = options.map((option) => ({
          value: '',
          groupName: type,
          name: option.key,
          show: isAbleToShowOption(option),
          ...getInfos({
            takeawayTime: info?.mm_takeaway_time || '',
            takeawayDiscount: info?.mm_takeaway_discount || '0',
            deliveryFee: deliveryFee ? deliveryFee?.pricing : '',
            isDeliveryFeeEnabled: typeof deliveryFee === 'object' || false,
            isTakeawayDiscountEnabled: info?.mm_takeaway_discount_enabled || false,
            isFreeDeliveryEnabled: info?.mm_free_delivery_enabled
              ? cartTotal > Number(info.mm_free_delivery_minimum_value)
              : false,
            hideDeliveryAndTakeawayTime:
              !!info?.mm_order_scheduling_only || (!!info?.mm_order_scheduling_enabled && !isOpen) || !!schedulingDate,
            deliveryAverageTime:
              !shouldHideTime && deliveryFee?.time
                ? typeof deliveryFee?.time === 'number'
                  ? { to: 0, from: deliveryFee?.time }
                  : deliveryFee?.time
                : { to: 0, from: 0 }
          })[option.key]
        }));

        setCheckoutGroupOptionList(mountedOptionList);
      }
    }

    mountGroupOptions();
  }, [options, type, info, cartTotal, deliveryFee, isOpen, schedulingDate, isAbleToShowOption, getInfos]);

  const handleChangeOption = useCallback(
    (value?: string) => {
      if (!value) return;

      if (action) {
        action(value as DeliveryWay);
      }

      setDeliveryOption(value as DeliveryWayEnum);
    },
    [action, setDeliveryOption]
  );

  useEffect(() => {
    if (checkoutGroupOptionList && checkoutGroupOptionList.length === 1) {
      const [uniqueItem] = checkoutGroupOptionList;

      handleChangeOption(uniqueItem.name);
    }
  }, [checkoutGroupOptionList, handleChangeOption]);

  const isMinimumValueReached = useCallback(
    (currentValue: number) => {
      if (!info?.mm_delivery_minimum_value_enabled) return true;

      if (info?.mm_delivery_minimum_value) {
        return currentValue >= Number(info.mm_delivery_minimum_value);
      }

      return true;
    },
    [info]
  );

  const currentSelectedName = useMemo(() => {
    if (type === 'payment') return String(paymentOption) || '';

    return deliveryOption || '';
  }, [type, deliveryOption, paymentOption]);

  const showMinimumValueWarn = useCallback(
    (itemName: string) => {
      if (!getTermIntoString({ word: itemName || '', term: 'delivery' })) return false;
      if (!getTermIntoString({ term: 'delivery', word: deliveryOption || '' })) return false;
      if (isMinimumValueReached(cartTotal)) return false;
      return true;
    },
    [cartTotal, deliveryOption, isMinimumValueReached]
  );

  return (
    <S.Container key={index}>
      <OptionHeader
        isRequired
        title={title}
        subtitle={false}
        isDone={!!deliveryOption && deliveryOption?.length > 0 && !showMinimumValueWarn('Entrega')}
      />

      <S.OptionList>
        {checkoutGroupOptionList?.map((item, index) => {
          if (!item.show) return <></>;

          return (
            <S.OptionWrapper key={index}>
              <Option
                {...item}
                isSimple
                isAvailable
                type="radio"
                price={item.value}
                isSmallDescription
                optionValue={item.name}
                title={item.description || ''}
                id={Number(Math.random().toFixed(5))}
                radioCheck={[{ id: currentSelectedName, value: currentSelectedName }]}
                onChange={(changedValues: { optionValue?: string }): void =>
                  handleChangeOption(changedValues.optionValue)
                }
              >
                {item.badge && (
                  <S.DiscountTag $isSuccess={item.badge.type === 'success'}>{item.badge.message}</S.DiscountTag>
                )}
              </Option>
            </S.OptionWrapper>
          );
        })}
      </S.OptionList>
    </S.Container>
  );
};

export default CheckoutOption;
