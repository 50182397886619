import React, { useCallback, useState, useEffect } from 'react';

import { useTranslator } from '~/hooks';

import { FormInput } from '../FormInput';

interface InputEmailProps {
  dataTest?: string;
  defaultValue?: string;
  onChange: (value: string, isValid: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const InputEmail: React.FC<InputEmailProps> = ({ dataTest, onChange, defaultValue }) => {
  const [currentValue, setCurrentValue] = useState(defaultValue || '');
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  const { getTranslation } = useTranslator();

  useEffect(() => {
    setCurrentValue(defaultValue || '');
  }, [defaultValue]);

  const handleValidate = useCallback((value: string) => {
    const emailRegexp = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g);

    return emailRegexp.test(value);
  }, []);

  const handleBlur = useCallback(() => {
    const isValidValue = handleValidate(currentValue || '');

    setIsValid(isValidValue);
  }, [currentValue, handleValidate]);

  const handleOnChange = useCallback(
    (value: string) => {
      setCurrentValue(value);

      const isValidValue = handleValidate(value);
      setIsValid(isValidValue);

      onChange(value, isValidValue);
    },
    [handleValidate, onChange]
  );

  return (
    <FormInput
      required
      name="email"
      isValid={isValid}
      onBlur={handleBlur}
      value={currentValue}
      data-test={dataTest}
      placeholder={getTranslation('general.emailExample')}
      errorMessage={getTranslation('general.invalidEmail')}
      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => handleOnChange(event.target.value)}
    />
  );
};

export default React.memo(InputEmail);
