import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';
import { ChatbotAttendantModeEnum } from '~/interfaces/enums';

export default function useChatbot() {
  const { chatbot, settings } = useSelector((state: IApplicationState) => state.establishment);

  const isChatbotEnabled = !!settings?.mm_feature_chatbot_enabled;

  const isChatbotOrderStatusEnabled =
    isChatbotEnabled && chatbot?.settings?.assistant_mode === ChatbotAttendantModeEnum.orderStatus;

  return { isChatbotEnabled, isChatbotOrderStatusEnabled };
}
