import { convertToCurrency } from '~/utils';
import { CartState } from '~/redux-tools/store/cart';
import { DeliveryPricingEnum } from '~/interfaces/enums';
import { CartProduct, CartOptional, IDeliveryInfo } from '~/interfaces/general';

const optionalFormatter: (
  optional: CartOptional,
  showPrices: boolean,
  showBarCodes: boolean,
  productQuantity: number
) => string = (optional, showPrices, showBarCodes, productQuantity) => {
  const quantityPart = ` - ${Number(optional.quantity) * Number(productQuantity)}x`;

  const namePart = optional.name.replace(/\r?\n|\r/g, '');

  const pricePart =
    showPrices && optional.price > 0
      ? `*${convertToCurrency(optional.price * optional.quantity * Number(productQuantity))}*`
      : '';

  const barCodePart = showBarCodes && !!optional.code && optional.code !== null ? `_cód._ _${optional.code}_` : '';

  return `${quantityPart} ${namePart} ${pricePart} ${barCodePart}`;
};

const commentFormatter: (comment: string) => string = (comment) => {
  return comment
    .split('\n')
    .map((x) => {
      return `${x.trim()}`;
    })
    .join('\n');
};

const productTitle: (
  { code, product, quantity, customName }: CartProduct,
  showPrices: boolean,
  showBarCodes: boolean
) => string = ({ code, product, quantity, customName }, showPrices, showBarCodes) => {
  const namePart = customName ? `${(product.name || '').trim()} - ${customName.trim()}` : (product.name || '').trim();

  const pricePart = `${showPrices ? ` ${convertToCurrency((product.price || 0) * quantity)}` : ''}`;
  const barCodePart = `${showBarCodes && code ? `_cód. ${code}_` : ''}`;

  return `*${quantity}x ${namePart}${pricePart}* ${barCodePart}`;
};

const listOptionals: (
  optionals: CartOptional[],
  showPrices: boolean,
  showBarCodes: boolean,
  productQuantity: number
) => string = (optionals, showPrices, showBarCodes, productQuantity) =>
  optionals.map((opt) => optionalFormatter(opt, showPrices, showBarCodes, productQuantity)).join('\n');

export const productFormatter: (product: CartProduct, showPrices: boolean, showBarCodes: boolean) => string = (
  product,
  showPrices,
  showBarCodes
) => {
  const productPart = productTitle(product, showPrices, showBarCodes);

  const optionalsPart = product?.optionals?.length
    ? `\n${listOptionals(product?.optionals, showPrices, showBarCodes, product.quantity)}`
    : '';

  const commentPart = product.comment ? `\n  _Obs: ${commentFormatter(product.comment)}_` : '';

  return `${productPart} ${optionalsPart} ${commentPart}`;
};

export const printOrder: (products: CartProduct[], showPrices: boolean, showBarCodes: boolean) => string = (
  products,
  showPrices,
  showBarCodes
) => products.map((eachProduct) => productFormatter(eachProduct, showPrices, showBarCodes)).join('\n\n');

interface PrintTotalProps {
  cart: CartState;
  separator: string;
  hasCoupon: boolean;
  showPrices: boolean;
  isFreeDelivery: boolean;
  deliveryInfo?: IDeliveryInfo;
}

export const printTotal: ({ cart, hasCoupon, separator, deliveryInfo, isFreeDelivery }: PrintTotalProps) => {
  message: string;
  subtotal: number;
  taxMessage?: string;
} = ({ cart, hasCoupon, separator, deliveryInfo, isFreeDelivery }) => {
  if (!deliveryInfo)
    return {
      message: '',
      subtotal: cart.total
    };

  // Gets delivery fee
  let deliveryFee = deliveryInfo.delivery_fee;

  if (deliveryInfo.delivery_fee !== undefined) {
    if (deliveryInfo.delivery_fee === DeliveryPricingEnum.free || deliveryInfo.delivery_fee === 0 || isFreeDelivery) {
      deliveryFee = 'Grátis';
    } else if (deliveryInfo.delivery_fee === DeliveryPricingEnum.ask) {
      deliveryFee = 'A combinar';
    } else if (!isNaN(Number(deliveryInfo.delivery_fee))) {
      deliveryFee = convertToCurrency(Number(deliveryInfo.delivery_fee));
    }
  }

  const loyaltyValue = cart.values.loyalty || 0;
  const shouldAddPlusSign: boolean = typeof deliveryInfo.delivery_fee === 'number' || isFreeDelivery;

  const taxPart = `*Taxa de entrega*: ${shouldAddPlusSign ? `+${String(deliveryFee)}` : String(deliveryFee)}\n`;

  const deliveryValue =
    isFreeDelivery || isNaN(Number(deliveryInfo.delivery_fee)) ? 0 : Number(deliveryInfo.delivery_fee);
  const pureTotal = cart.total - loyaltyValue + deliveryValue;

  const loyaltyDiscount = loyaltyValue > 0 ? `*Desconto Fidelidade:* -${convertToCurrency(loyaltyValue)}\n` : '';

  // Joins delivery fee, loyalty discount and price
  if (deliveryInfo.delivery_fee !== undefined && !isNaN(Number(deliveryInfo.delivery_fee)) && !isFreeDelivery) {
    const subtotal = `*Subtotal:* ${convertToCurrency(cart.total)}\n`;

    const subtotalPart = `${subtotal}${loyaltyDiscount}`;
    const totalPart = `*Total: ${convertToCurrency(pureTotal)}*`;

    if (hasCoupon) {
      return {
        taxMessage: taxPart,
        subtotal: pureTotal,
        message: `${subtotalPart}`
      };
    }

    return {
      subtotal: pureTotal,
      message: `${subtotalPart}${taxPart}${totalPart}\n\n${separator}`
    };
  }

  // Joins price and custom delivery fee
  if (isNaN(Number(deliveryInfo.delivery_fee)) || isFreeDelivery) {
    const totalPart = `*Total: ${convertToCurrency(pureTotal)}*`;
    const subtotalPart = `*Subtotal:* ${convertToCurrency(cart.total)}\n${loyaltyDiscount}`;

    if (hasCoupon) {
      return {
        subtotal: pureTotal,
        message: `${subtotalPart}${taxPart}`
      };
    }

    if (loyaltyValue > 0) {
      return {
        subtotal: pureTotal,
        message: `${subtotalPart}${taxPart}${totalPart}\n\n${separator}`
      };
    }

    return {
      subtotal: pureTotal,
      message: `${taxPart}${totalPart}\n\n${separator}`
    };
  }

  if (hasCoupon) {
    return {
      subtotal: cart.total,
      message: `*Subtotal:* ${convertToCurrency(cart.total)}\n`
    };
  }

  // Shows only price if delivery is free
  return {
    subtotal: cart.total,
    message: `*Total: ${convertToCurrency(cart.total)}*\n\n${separator}`
  };
};

export const printCpfOnBill: (cpf: string) => string = (cpf) => (cpf ? `*CPF:* ${cpf}` : '');
