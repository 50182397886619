import styled from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';

export const Container = Modal
  ? styled(Modal)`
      .container {
        position: relative;

        height: 100vh;

        padding-bottom: 0;

        .content {
          height: calc(100% - 4rem);

          display: flex;
          flex-direction: column;
        }
      }
    `
  : styled.div<ModalProps>``;

export const TopInfo = styled.div`
  position: relative;

  padding: 1rem;
  height: fit-content;

  display: flex;
  flex-direction: column;

  h5 {
    margin-bottom: 0.75rem;

    font-size: 1rem;
    text-align: left;
    line-height: 120%;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.textColor};
  }

  .tips {
    margin-top: 0.75rem;

    text-align: left;
    font-weight: 500;
    line-height: 150%;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.gray500};
  }
`;

export const Tag = styled.span`
  height: 22px;
  padding: 0 0.5rem;
  width: fit-content;
  border-radius: 1.5rem;

  display: block;

  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.backgroundColor};
  background-color: ${({ theme }) => theme.colors.gray500};
`;

export const ErrorListWrapper = styled.div`
  padding-bottom: 9rem;

  flex: 1;

  transition: 0.3s;
  background-color: ${({ theme }) => theme.colors.gray200};
`;

export const Actions = styled.div`
  bottom: 0;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);

  width: 100%;
  padding: 1rem;
  max-width: 800px;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);

  background: ${({ theme }) => theme.colors.lightestGray};

  button {
    & + button {
      margin-top: 0.75rem;
    }
  }
`;
