import { Reducer } from 'redux';

import { CheckoutState, CheckoutActionTypes } from './types';

const initialState: CheckoutState = {
  cpf: '',
  name: '',
  error: false,
  loading: false,
  clientPhone: '',
  address: undefined,
  paymentOption: undefined,
  schedulingDate: undefined,
  selectedCoupon: undefined,
  deliveryOption: undefined,
  isFullAgeTermAccept: undefined
};

const CheckoutReducer: Reducer<CheckoutState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CheckoutActionTypes.ADD_CHECKOUT_DATA:
      return {
        ...state,
        ...payload
      };

    case CheckoutActionTypes.ADD_CHECKOUT_COUPON:
      return {
        ...state,
        selectedCoupon: payload
      };

    case CheckoutActionTypes.SET_ERROR_CHECKOUT_DATA:
      return {
        ...state,
        error: payload
      };

    case CheckoutActionTypes.REMOVE_CHECKOUT_DATA:
      return initialState;

    default:
      return state;
  }
};

export default CheckoutReducer;
