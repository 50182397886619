import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { isPossiblePhoneNumber as isPossibleMobileNumber } from 'libphonenumber-js/mobile';

export interface ValidatePhoneProps {
  phoneNumber: string;
  onlyMobile?: boolean;
}

export default function validatePhone({ phoneNumber, onlyMobile = false }: ValidatePhoneProps): boolean {
  const cleanPhone = `${phoneNumber.replace('+55', '').replace(/\D/g, '')}`;

  const isValidLength = cleanPhone.length >= 10;

  if (!isValidLength) {
    return false;
  }

  const preventSpamNumberRegex = /^(.)\1*$/g; // ex. 777777777
  const normalNumberRegex = /^$|^[1-9]{2}9[1-9][0-9]{3}[0-9]{3}(?:\d{1})?$/g;

  if (
    !cleanPhone.match(normalNumberRegex) ||
    !!cleanPhone.match(preventSpamNumberRegex) ||
    !!cleanPhone.substring(2).match(preventSpamNumberRegex)
  ) {
    return false;
  }

  if (onlyMobile) {
    return isPossibleMobileNumber(cleanPhone, 'BR');
  }

  const phoneWithCountryCode = `+55${phoneNumber.replace(/\D/g, '')}`;

  return isPossiblePhoneNumber(phoneWithCountryCode, 'BR');
}
