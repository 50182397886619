import React, { useCallback, useState, useEffect, useRef } from 'react';

import { Price } from '~/interfaces';
import { useTranslator } from '~/hooks';
import { CustomPriceInfo } from '~/interfaces/general';

import OptionHeader from '../OptionHeader';
import Option, { OptionOnChange } from '../Option';

import { Container } from './styles';

interface OptionalPriceProps {
  prices: Price[];
  isViewMode?: boolean;
  availablePromotions: Price[];
  defaultPrice: CustomPriceInfo;
  onChange: (customItem: CustomPriceInfo) => void;
}

const OptionalPrice: React.FC<OptionalPriceProps> = ({
  prices,
  onChange,
  isViewMode,
  defaultPrice,
  availablePromotions
}) => {
  const isFirstRender = useRef(true);

  const { getTranslation } = useTranslator();

  const [selectedPrice, setSelectedPrice] = useState<CustomPriceInfo>({
    id: defaultPrice.id,
    price: defaultPrice.price,
    code: defaultPrice.code || '',
    name: defaultPrice.name || '',
    hash: defaultPrice.hash || ''
  });

  const handleChange = useCallback(
    (optionProps: OptionOnChange, id: number | undefined, name: string): void => {
      setSelectedPrice({ ...optionProps, id, name, price: Number(optionProps.value) });
      onChange({ ...optionProps, id, name, price: Number(optionProps.value) });
    },
    [onChange]
  );

  const getPricePromotion = useCallback(
    (price: Price) => availablePromotions.find(({ id, promotion }) => !!promotion && price.id === id),
    [availablePromotions]
  );

  useEffect(() => {
    if (isFirstRender.current && defaultPrice.name !== '') {
      setSelectedPrice({
        id: defaultPrice.id,
        price: defaultPrice.price,
        name: defaultPrice.name || '',
        hash: defaultPrice.hash || '',
        code: defaultPrice.code || ''
      });

      isFirstRender.current = false;
    }
  }, [isFirstRender, defaultPrice]);

  useEffect(() => {
    if (selectedPrice.name !== '') {
      onChange(selectedPrice);
    }
  }, [selectedPrice]); //eslint-disable-line

  // TODO mudar implementacao!!!!
  useEffect(() => {
    if (prices.length === 1) {
      handleChange(
        {
          groupName: 'pricing',
          hash: prices[0].hash,
          code: prices[0].code,
          value: prices[0].price,
          optionValue: prices[0].name
        },
        prices[0].id,
        prices[0].name
      );
    }
  }, []); //eslint-disable-line

  return (
    <Container>
      <OptionHeader
        isBold
        noPadding
        isRequired
        subtitle={false}
        isSimple={isViewMode}
        specs={{ min: 0, max: 1 }}
        isDone={!!selectedPrice.name}
        title={getTranslation('general.chooseOneOption')}
      />

      {prices.map((price, index) => (
        <Option
          isSimple
          id={index}
          key={index}
          isAvailable
          type="radio"
          hash={price.hash}
          code={price.code}
          title={price.name}
          price={price.price}
          groupName="pricing"
          isViewMode={isViewMode}
          promotion={getPricePromotion(price)?.promotion}
          optionValue={price.id ? price.id.toString() : price.name}
          onChange={(optionProps): void => handleChange(optionProps, price.id, price.name)}
          radioCheck={[{ id: price.id, value: selectedPrice?.id?.toString() || selectedPrice.name }]}
        />
      ))}
    </Container>
  );
};

export default OptionalPrice;
