import { useCallback } from 'react';

import useTranslator from '../useTranslator';

interface UseConvertStringArrayReturnProps {
  convertStringArrayIntoReadableString: (list: string[]) => string;
}

export default function useConvertStringArray(): UseConvertStringArrayReturnProps {
  const { getTranslation } = useTranslator();

  const convertStringArrayIntoReadableString = useCallback(
    (list: string[]): string => {
      if (list.length === 0) return '';

      if (list.length === 1) return list[0];

      const commaSeparatedString: string = list.join(', ');
      const lastCommaIndex: number = commaSeparatedString.lastIndexOf(',');

      const stringWithoutLastItem = commaSeparatedString.slice(0, lastCommaIndex);
      return `${stringWithoutLastItem} ${getTranslation('general.and')} ${list[list.length - 1]}`;
    },
    [getTranslation]
  );

  return { convertStringArrayIntoReadableString };
}
