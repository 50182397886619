import React from 'react';

import { RiRestaurantLine } from 'react-icons/ri';

import TextWithIcon from '../TextWithIcon';
import * as S from './styles';

export interface StoreCategoryProps {
  category: string;
}

const StoreCategory: React.FC<StoreCategoryProps> = ({ category }) => {
  return (
    <S.Container>
      <TextWithIcon icon={<RiRestaurantLine size={20} />} text={category} />
    </S.Container>
  );
};

export default StoreCategory;
