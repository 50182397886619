import React from 'react';

const UsaFlagIcon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <circle id="border" cx="17" cy="17" r="16.5" fill="#D80027" stroke="#DDE2EB" />
      <mask id="mask0_1504_14276" maskUnits="userSpaceOnUse" x="1" y="1" width="32" height="32">
        <path
          d="M1 17C1 13.8355 1.93839 10.7421 3.69649 8.11088C5.4546 5.47969 7.95343 3.42894 10.877 2.21794C13.8007 1.00694 17.0178 0.690078 20.1215 1.30744C23.2251 1.9248 26.076 3.44864 28.3137 5.68628C30.5513 7.92392 32.0752 10.7748 32.6925 13.8785C33.3099 16.9822 32.9931 20.1993 31.7821 23.1229C30.5711 26.0465 28.5203 28.5454 25.8891 30.3035C23.2579 32.0616 20.1645 33 17 33C14.8986 33.001 12.8176 32.5878 10.8759 31.7841C8.93426 30.9803 7.17003 29.8018 5.68409 28.3159C4.19816 26.8299 3.01966 25.0657 2.21594 23.1241C1.41221 21.1824 0.999024 19.1014 1 17Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1504_14276)">
        <path d="M33.6841 -0.175903H9.59478H6.03759H-0.771729V3.3813H33.6841V-0.175903Z" fill="#F0F0F0" />
        <path d="M33.6841 6.43262H9.59478H6.03759H-0.771729V9.99727H33.6841V6.43262Z" fill="#F0F0F0" />
        <path d="M33.6841 13.0483H9.59478H6.03759H-0.771729V16.6055H33.6841V13.0483Z" fill="#F0F0F0" />
        <path d="M33.6841 19.6642H9.59478H6.03759H-0.771729V23.2214H33.6841V19.6642Z" fill="#F0F0F0" />
        <path d="M33.6841 26.2725H9.59478H6.03759H-0.771729V29.8371H33.6841V26.2725Z" fill="#F0F0F0" />
        <path d="M17.6097 -1.31433H-0.771729V17.0671H17.6097V-1.31433Z" fill="#0052B4" />
      </g>
    </svg>
  );
};

export default UsaFlagIcon;
