import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 2rem;

  overflow-x: hidden;

  > div {
    margin: 0;
    padding: 0 1rem;
  }

  input {
    width: 100%;
    margin: 0.25rem 0 0;
  }
`;
