interface HandleAcrossTheWeekProps {
  to: number;
  from: number;
  weekday: number;
}

export default function handleAcrossTheWeek({ to, from, weekday }: HandleAcrossTheWeekProps): boolean {
  const newToValue = to + 7;

  return (weekday < newToValue && weekday >= from) || weekday <= to;
}
