import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import HighFive from '~/assets/HighFive';
import NewWaves from '~/components/NewWaves';
import { IApplicationState } from '~/redux-tools/store';

import * as S from './styles';

export interface TabClosedProps {
  onDetailsClick: () => void;
}

const TabClosed: React.FC<TabClosedProps> = ({ onDetailsClick }) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  return (
    <S.Container>
      <S.Content>
        <div className="image">
          <HighFive brandColor={theme.brandColors.primary} />
        </div>

        <p className="title">{getTranslation('myTab.billWasPaid')}</p>

        <p className="action" onClick={onDetailsClick}>
          {getTranslation('general.seeDetails')}
        </p>
      </S.Content>

      <S.WavesWrapper>
        <NewWaves />
      </S.WavesWrapper>
    </S.Container>
  );
};

export { TabClosed };
