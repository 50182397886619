import styled, { css } from 'styled-components';
import IntlCurrencyInput from 'react-intl-currency-input';

export const Container = styled.div<{ hasError?: boolean }>`
  ${({ theme, hasError }) => css`
    padding: 0;
    height: 36px;
    margin: 12px 16px;
    width: calc(100% - 32px);

    display: flex;

    .symbol {
      height: 100%;
      padding: 7px 10px;
      border-radius: 3px 0px 0px 3px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: ${({ theme }) => theme.colors.lightGray};

      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.darkGray};
      }
    }

    ${!!hasError && `border: 1px solid ${theme.colors.error}`}
  `}
`;

export const Input = styled(IntlCurrencyInput)`
  height: 100%;
  padding: 0 16px;
  min-width: 200px;
  border-radius: 0 4px 4px 0;
  border-left: 1px solid transparent;
  border-top: 1px solid ${({ theme }) => theme.colors.placeholderGray};
  border-right: 1px solid ${({ theme }) => theme.colors.placeholderGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.placeholderGray};

  display: flex;
  justify-content: space-between;

  cursor: text;

  &:focus {
    border: 1px solid ${({ theme }) => theme.brandColors.primary};
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.gray400};
    `}
`;

export const InputErrorMessage = styled.div<{ showError?: boolean }>`
  margin: -8px 24px 12px;

  display: ${({ showError = true }) => (showError ? 'inline-block' : 'none')};

  color: ${({ theme }) => theme.colors.error};
`;
