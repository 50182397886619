import { useCallback } from 'react';

import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { PaymentTypeEnum } from '~/interfaces/enums';

import useTranslator from '../useTranslator';

export interface UseHandlePaymentTextsProps {
  handlePaymentTexts: (flag: string) => string;
}

export default function useHandlePaymentTexts(): UseHandlePaymentTextsProps {
  const { getTranslation } = useTranslator();

  const handlePaymentTexts = useCallback(
    (flag: string) => {
      const translationKey = (): string => {
        switch (flag.toLowerCase()) {
          case PaymentCategoryEnum.cash:
            return 'payment.methods.cash';

          case PaymentCategoryEnum.credit:
          case PaymentCategoryEnum.tunaCheckout:
            return 'payment.methods.credit';

          case PaymentCategoryEnum.debit:
            return 'payment.methods.debit';

          case PaymentCategoryEnum.mPagoLink:
            return 'payment.methods.mPagoLink';

          case PaymentCategoryEnum.vrPagueLink:
            return 'payment.methods.vrPagueLink';

          case PaymentCategoryEnum.mPagoQr:
            return 'payment.methods.mPagoQr';

          case PaymentCategoryEnum.mPagoCheckout:
            return 'payment.methods.mPagoCheckout';

          case PaymentCategoryEnum.voucher:
            return 'payment.methods.voucher';

          case PaymentTypeEnum.onDelivery:
            return 'payment.methods.onDelivery';

          case PaymentTypeEnum.onApp:
            return 'payment.methods.onApp';

          case PaymentTypeEnum.onAttendant:
            return 'payment.methods.onAttendant';

          case PaymentCategoryEnum.nupay:
            return 'payment.methods.nupay';

          case PaymentCategoryEnum.pix:
          case PaymentCategoryEnum.integratedPix:
            return 'payment.methods.pix';

          case PaymentCategoryEnum.googlePay:
            return 'payment.methods.googlePay';

          case PaymentCategoryEnum.applePay:
            return 'payment.methods.applePay';

          default:
            return flag;
        }
      };

      return getTranslation(translationKey());
    },
    [getTranslation]
  );

  return { handlePaymentTexts };
}
