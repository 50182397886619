import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useSystemInfo } from '@goomerdev/goomer-toolbox/src/hooks';
import { PaymentCategoryEnum, PaymentTypeEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { Tuna } from '~/services';
import { useHasFeatureAccess } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { FeaturesEnum, LocalOrdersEnum, TunaResponseCode } from '~/interfaces/enums';
import { CardTypeEnum, ICard, ICardFlags, TunaSavedCard } from '~/interfaces/general';
import { clearCardData, addUserCard, AddUserCard } from '~/redux-tools/store/user/actions';

export default function usePaymentMethods() {
  const dispatch = useDispatch();
  const systemInfo = useSystemInfo();

  const origin = useSelector((state: IApplicationState) => state.localOrders.mode);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { orderFromDashboard } = useSelector((state: IApplicationState) => state.user);
  const localOrders = useSelector((state: IApplicationState) => state.localOrders.mode);
  const { isGooglePayReady } = useSelector((state: IApplicationState) => state.payments);

  const [hasManualPixAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.ManualPix });
  const [hasMercadoPagoAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.MercadoPago });
  const [hasQrCodeOnlinePaymentAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.QrCodeOnlinePayment });

  const isAppleSystem: boolean = systemInfo.OS === 'ios'; // This verification has change when Apple Pay will be implemented

  const isQrCodeOrder: boolean = useMemo(
    () => !!origin && !!hasQrCodeOnlinePaymentAccess,
    [hasQrCodeOnlinePaymentAccess, origin]
  );

  const { cards, authenticationToken } = useSelector((state: IApplicationState) => state.user.data);

  const [isTunaStarted, setIsTunaStarted] = useState(false);
  const [isTunaLoading, setIsTunaLoading] = useState(false);

  const cash: boolean = isQrCodeOrder
    ? !!settings?.mm_payment_qrcode_cash_enabled
    : !!settings?.mm_payment_cash_enabled;

  const debit: boolean = isQrCodeOrder
    ? !!settings?.mm_payment_qrcode_debit_enabled
    : !!settings?.mm_payment_debit_enabled;

  const credit: boolean = isQrCodeOrder
    ? !!settings?.mm_payment_qrcode_credit_enabled
    : !!settings?.mm_payment_credit_enabled;

  const voucher: boolean = isQrCodeOrder
    ? !!settings?.mm_payment_qrcode_voucher_enabled
    : !!settings?.mm_payment_voucher_enabled;

  const vrPagueLink = !!settings?.mm_payment_vrpague_link_enabled;
  const mercadoPagoLink = !!settings?.mm_payment_mpago_link_enabled;
  const mercadoPagoQRCode = !!settings?.mm_payment_mpago_qrcode_enabled;

  const debitFlags: ICardFlags[] = useMemo(
    () => (settings?.mm_payment_debit_enabled ? settings?.mm_payment_debit_flags : []),
    [settings?.mm_payment_debit_enabled, settings?.mm_payment_debit_flags]
  );
  const creditFlags: ICardFlags[] = useMemo(
    () => (settings?.mm_payment_credit_enabled ? settings?.mm_payment_credit_flags : []),
    [settings?.mm_payment_credit_enabled, settings?.mm_payment_credit_flags]
  );
  const voucherFlags: ICardFlags[] = useMemo(
    () => (settings?.mm_payment_voucher_enabled ? settings.mm_payment_voucher_flags : []),
    [settings?.mm_payment_voucher_enabled, settings?.mm_payment_voucher_flags]
  );

  const integratedPix: boolean = useMemo(() => {
    if (orderFromDashboard) {
      return false;
    }

    if (isQrCodeOrder) {
      return !!settings?.mm_payment_qrcode_tuna_pix_checkout_enabled;
    }

    return !!settings?.mm_payment_tuna_pix_checkout_enabled;
  }, [
    isQrCodeOrder,
    orderFromDashboard,
    settings?.mm_payment_qrcode_tuna_pix_checkout_enabled,
    settings?.mm_payment_tuna_pix_checkout_enabled
  ]);

  const tunaCreditCardCheckout: boolean = useMemo(() => {
    if (orderFromDashboard) {
      return false;
    }

    if (isQrCodeOrder) {
      return !!settings?.mm_payment_qrcode_tuna_credit_card_checkout_enabled;
    }

    return !!settings?.mm_payment_tuna_credit_card_checkout_enabled;
  }, [
    isQrCodeOrder,
    orderFromDashboard,
    settings?.mm_payment_qrcode_tuna_credit_card_checkout_enabled,
    settings?.mm_payment_tuna_credit_card_checkout_enabled
  ]);

  const isNupayCheckout: boolean = useMemo(() => {
    if (orderFromDashboard) {
      return false;
    }

    return isQrCodeOrder
      ? !!settings?.mm_payment_qrcode_nupay_checkout_enabled
      : !!settings?.mm_payment_nupay_checkout_enabled;
  }, [
    isQrCodeOrder,
    orderFromDashboard,
    settings?.mm_payment_nupay_checkout_enabled,
    settings?.mm_payment_qrcode_nupay_checkout_enabled
  ]);

  const isApplePayEnabled: boolean = useMemo(() => {
    if (orderFromDashboard || !isAppleSystem) {
      return false;
    }

    return isQrCodeOrder
      ? !!settings?.mm_payment_qrcode_tuna_credit_card_checkout_enabled &&
          !!settings?.mm_feature_apple_pay_payment_method_enabled
      : !!settings?.mm_payment_tuna_credit_card_checkout_enabled &&
          !!settings?.mm_feature_apple_pay_payment_method_enabled;
  }, [
    isAppleSystem,
    isQrCodeOrder,
    orderFromDashboard,
    settings?.mm_feature_apple_pay_payment_method_enabled,
    settings?.mm_payment_tuna_credit_card_checkout_enabled,
    settings?.mm_payment_qrcode_tuna_credit_card_checkout_enabled
  ]);

  const isGooglePayEnabled: boolean = useMemo(() => {
    if (orderFromDashboard || !isGooglePayReady) {
      return false;
    }

    return isQrCodeOrder
      ? !!settings?.mm_payment_qrcode_tuna_credit_card_checkout_enabled &&
          !!settings?.mm_feature_google_pay_payment_method_enabled
      : !!settings?.mm_payment_tuna_credit_card_checkout_enabled &&
          !!settings?.mm_feature_google_pay_payment_method_enabled;
  }, [
    isQrCodeOrder,
    isGooglePayReady,
    orderFromDashboard,
    settings?.mm_feature_google_pay_payment_method_enabled,
    settings?.mm_payment_qrcode_tuna_credit_card_checkout_enabled,
    settings?.mm_payment_tuna_credit_card_checkout_enabled
  ]);

  const pix: boolean = useMemo(
    () => hasManualPixAccess && !!settings?.mm_payment_pix_enabled,
    [hasManualPixAccess, settings?.mm_payment_pix_enabled]
  );

  const mercadoPagoCheckout: boolean = useMemo(() => {
    if (!hasMercadoPagoAccess) {
      return false;
    }

    if (isQrCodeOrder) {
      return !!settings?.mm_payment_qrcode_mpago_checkout_enabled;
    }

    return !!settings?.mm_payment_mpago_checkout_enabled;
  }, [
    hasMercadoPagoAccess,
    isQrCodeOrder,
    settings?.mm_payment_mpago_checkout_enabled,
    settings?.mm_payment_qrcode_mpago_checkout_enabled
  ]);

  const setTunaSavedCards: () => Promise<void> = useCallback(async () => {
    if (!tunaCreditCardCheckout) {
      return;
    }

    const tuna = await Tuna(authenticationToken);
    const listResponse = await tuna.tokenizator().list();
    const existSavedCards =
      listResponse.code === TunaResponseCode.Ok && listResponse.tokens && listResponse.tokens.length > 0;

    setIsTunaLoading(false);

    if (existSavedCards) {
      listResponse.tokens.forEach((savedCard: TunaSavedCard) => {
        const newCard = {
          token: savedCard.token,
          type: CardTypeEnum.credit,
          mpMethodId: savedCard.brand,
          email: savedCard.data.email,
          name: savedCard.cardHolderName,
          document: savedCard.data.document,
          number: savedCard.maskedNumber.trim(),
          flagDescription: PaymentCategoryEnum.tunaCheckout,
          due: `${savedCard.expirationMonth}/${savedCard.expirationYear}`
        };

        dispatch(
          addUserCard({
            ...newCard,
            cardNumber: newCard.number,
            establishment: settings?.name || ''
          } as AddUserCard)
        );
      });
    }
  }, [tunaCreditCardCheckout, setIsTunaLoading, dispatch, settings, authenticationToken]);

  useEffect(() => {
    async function startTuna() {
      if (!tunaCreditCardCheckout || isTunaStarted) {
        return;
      }

      setIsTunaStarted(true);
      setIsTunaLoading(true);
      dispatch(clearCardData());
      await setTunaSavedCards();
    }
    startTuna();
  }, [dispatch, isTunaStarted, setTunaSavedCards, tunaCreditCardCheckout]);

  const isPixEnabled = useMemo(() => {
    return (!isQrCodeOrder || origin !== LocalOrdersEnum.balcony) && pix;
  }, [isQrCodeOrder, origin, pix]);

  const handlePaymentTextCategory = useMemo(() => {
    if (localOrders.length > 0) {
      return PaymentTypeEnum.onAttendant;
    }

    return PaymentTypeEnum.onDelivery;
  }, [localOrders]);

  const existInAppPayment: boolean = useMemo(() => {
    return (
      !!vrPagueLink ||
      !!isPixEnabled ||
      !!integratedPix ||
      !!isNupayCheckout ||
      !!mercadoPagoLink ||
      !!isApplePayEnabled ||
      !!isGooglePayEnabled ||
      !!mercadoPagoCheckout ||
      !!tunaCreditCardCheckout
    );
  }, [
    vrPagueLink,
    isPixEnabled,
    integratedPix,
    isNupayCheckout,
    mercadoPagoLink,
    isApplePayEnabled,
    isGooglePayEnabled,
    mercadoPagoCheckout,
    tunaCreditCardCheckout
  ]);

  const existPaymentOnDelivery: boolean = useMemo(() => {
    return (
      !!cash ||
      !!mercadoPagoQRCode ||
      !!credit ||
      creditFlags.length > 0 ||
      !!debit ||
      debitFlags.length > 0 ||
      !!voucher ||
      voucherFlags.length > 0
    );
  }, [cash, credit, creditFlags, debit, debitFlags, mercadoPagoQRCode, voucher, voucherFlags]);

  const cardsReversed: ICard[] = useMemo(() => {
    return cards?.slice(0).reverse() || [];
  }, [cards]);

  return {
    cash,
    debit,
    cards,
    credit,
    voucher,
    debitFlags,
    vrPagueLink,
    creditFlags,
    isPixEnabled,
    voucherFlags,
    isQrCodeOrder,
    isTunaLoading,
    cardsReversed,
    integratedPix,
    mercadoPagoLink,
    isNupayCheckout,
    mercadoPagoQRCode,
    existInAppPayment,
    isApplePayEnabled,
    isGooglePayEnabled,
    mercadoPagoCheckout,
    tunaCreditCardCheckout,
    existPaymentOnDelivery,
    handlePaymentTextCategory
  };
}
