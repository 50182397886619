import { useEffect } from 'react';

import isThisChromeVersionSupported from '~/utils/isThisChromeVersionSupported/index';

interface IUseScrollTo {
  x: number;
  y: number;
  timeout?: number;
}

const useScrollTo = ({ x, y, timeout = 50 }: IUseScrollTo) => {
  useEffect(() => {
    if (isThisChromeVersionSupported()) {
      setTimeout(() => window.scroll(x, y), timeout);
    }
  }, [x, y, timeout]);
};

export default useScrollTo;
