import React from 'react';

const CheckIcon = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.43982 9.7671L3.07793 6.29039C3.07775 6.29021 3.07757 6.29003 3.0774 6.28984C2.48174 5.67073 1.53415 5.67092 0.938695 6.29039C0.353768 6.89892 0.353768 7.85036 0.938695 8.45889L5.34867 13.0468C5.64851 13.3587 6.02541 13.5 6.41828 13.5C6.81535 13.5 7.18019 13.3566 7.49132 13.0653L7.50072 13.0565L7.50966 13.0472L17.0609 3.13366L17.0613 3.13324C17.6462 2.52471 17.6462 1.57327 17.0613 0.964745C16.4657 0.345146 15.5179 0.345085 14.9223 0.964562L14.9225 0.964293L15.2825 1.31124L14.9221 0.964745L6.43982 9.7671Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default CheckIcon;
