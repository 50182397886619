import styled, { css } from 'styled-components';
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';

export const Content = styled.div<{ $isListEmpty: boolean }>`
  ${({ theme, $isListEmpty }) => css`
    height: 100%;

    display: flex;
    flex-direction: column;

    > p {
      margin: 0;
      padding: 1.5rem 1rem;

      text-align: left;
      font-weight: 500;
      line-height: 150%;
      font-size: 0.75rem;
      color: ${theme.colors.mediumGray};
    }

    .swipeable-list {
      height: auto;

      flex: inherit;

      > div {
        &:not(:first-child) {
          margin-top: 0.5rem;
        }
      }
    }

    ${$isListEmpty &&
    css`
      margin: auto 0;
      height: fit-content;
    `}
  `};
`;

export const Footer = styled.div`
  padding: 1.5rem 1rem 1rem;

  button:first-of-type {
    margin-bottom: 12px;
  }
`;

export const ItemWrapper = styled.div`
  width: 100%;
  padding: 0 1rem;

  background: ${({ theme }) => theme.colors.gray200};
`;

export const RadioContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 1px solid ${theme.colors.gray300};

    gap: 0.5rem;
    display: flex;
    align-items: center;

    cursor: pointer;
    background-color: ${theme.colors.backgroundColor};

    .option {
      border: 0;
      padding: 0;
      width: 1.5rem;

      display: flex;
      align-items: center;
      justify-content: start;
      flex-direction: row-reverse;
    }

    .icon {
      display: flex;
      align-items: center;
    }
  `};
`;

export const AddressInformation = styled.div`
  ${({ theme }) => css`
    margin-right: auto;

    line-height: 120%;

    .content {
      margin-right: 1rem;

      display: flex;
      text-align: left;
      flex-direction: column;
    }

    strong,
    small {
      font-weight: 600;
      line-height: 150%;
      font-size: 0.875rem;
      color: ${theme.colors.mediumGray};
    }

    strong {
      color: ${theme.colors.textColor};
    }
  `}
`;

export const SwipeableCustomList = styled(SwipeableList)`
  height: min-content;
  flex: inherit !important;

  .swipeable-list-item__content {
    background: transparent;
  }
`;

export const LoaderWrapper = styled.div<{ isGoomerColor: boolean }>`
  ${({ theme, isGoomerColor }) => css`
    margin-bottom: 20px;

    svg {
      stroke: ${isGoomerColor
        ? theme.colors.primary
        : css`
            ${theme.brandColors.primary};
          `};
    }
  `};
`;

export const NoAddress = styled.div`
  ${({ theme }) => css`
    height: 100%;
    padding: 0 1rem;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin: 2rem 0 0;
      max-width: 18rem;

      font-weight: 600;
      line-height: 150%;
      text-align: center;
      font-size: 1.125rem;
      color: ${theme.colors.textColor};
    }

    p {
      max-width: 18.12rem;
      margin-top: 0.75rem;

      font-weight: 500;
      line-height: 140%;
      text-align: center;
      font-size: 0.875rem;
      color: ${theme.colors.gray500};
    }
  `}
`;
