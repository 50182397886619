import { Reducer } from 'redux';

import { PocketMountedOrder } from '~/interfaces';
import { MountedOrder } from '~/interfaces/general';

import { LastOrderActionTypes, ILastOrderState } from './types';

const initialState = {
  order: {} as MountedOrder,
  pocketOrder: {} as PocketMountedOrder
};

const LastOrdersReducer: Reducer<ILastOrderState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LastOrderActionTypes.ADD_LAST_ORDER:
      return {
        ...state,
        order: payload
      };

    case LastOrderActionTypes.ADD_LAST_POCKET_ORDER:
      return {
        ...state,
        pocketOrder: payload
      };

    case LastOrderActionTypes.CLEAN_LAST_ORDER:
      return {
        ...state,
        order: {} as MountedOrder,
        pocketOrder: {} as PocketMountedOrder
      };

    default:
      return state;
  }
};

export default LastOrdersReducer;
