import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

import useAddressIsNewDashboard from './useAddressIsNewDashboard';

export default function useAddressIsStatic() {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { isNewDashboard } = useAddressIsNewDashboard();

  const isStatic: boolean = useMemo(() => {
    if (isNewDashboard) {
      return settings?.mm_delivery_zone_type === 'static' || !settings?.mm_delivery_zone_type;
    }

    return false;
  }, [isNewDashboard, settings?.mm_delivery_zone_type]);

  return { isStatic };
}
