import { Button } from '~/components';
import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { ButtonProps } from '~/components/Button/Button';

import * as S from './styles';

interface ActionButtonProps extends ButtonProps {
  mode?: string;
  total?: number;
  hideButton?: boolean;
  buttonContent: React.ReactNode;
}

const ActionButton = ({ mode, total, hideButton, buttonContent, ...rest }: ActionButtonProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  return (
    <S.Container isFixedPosition>
      {typeof total !== 'undefined' && mode && (
        <S.TotalInfo $hideButton={hideButton}>
          <p>{getTranslation('myTab.subtotalOf', { mode: getTranslation(mode) })}:</p>

          <p>{convertToCurrency(total)}</p>
        </S.TotalInfo>
      )}

      {!hideButton && (
        <Button isGhost={false} {...rest}>
          {buttonContent}
        </Button>
      )}
    </S.Container>
  );
};

export { ActionButton };
