import styled, { css } from 'styled-components';

const getSize = (size?: number) => {
  return size ? `${size}rem` : '1rem';
};

export const Container = styled.div<{ $color?: string; $isCurrentMenuActive: boolean; $sizeRem?: number }>`
  ${({ theme, $color, $sizeRem, $isCurrentMenuActive }) => css`
    display: flex;

    img,
    svg {
      width: ${getSize($sizeRem)};
      height: ${getSize($sizeRem)};
      min-width: ${getSize($sizeRem)};
      min-height: ${getSize($sizeRem)};
      max-width: ${getSize($sizeRem)};
      max-height: ${getSize($sizeRem)};
    }

    svg {
      path {
        fill: ${$color || theme.colors.textColor};

        ${$isCurrentMenuActive &&
        css`
          fill: ${theme.brandColors.actionText};
        `}
      }

      &#sushi-icon {
        path {
          fill: transparent !important;
          stroke: ${theme.colors.textColor};

          ${$isCurrentMenuActive &&
          css`
            fill: transparent !important;
            stroke: ${theme.brandColors.actionText};
          `}
        }
      }
    }
  `}
`;
