import React, { useMemo, useCallback } from 'react';

import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { Button, Modal } from '~/components';
import { CartProduct } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';
import { removeComboToCart, removeProductToCart } from '~/redux-tools/store/cart/actions';

import * as S from './styles';

interface ItemEditProps {
  onClose: () => void;
  item: CartProduct | null;
}

const ItemEdit: React.FC<ItemEditProps> = ({ item, onClose }) => {
  const history = useRouter();
  const dispatch = useDispatch();
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { getTranslation } = useTranslator();

  const isObservationsEnabled = useMemo(() => settings?.mm_order_show_observations, [settings]);

  const navigateToAction = useCallback((): void => {
    history.push(`detail/${item?.product?.id ?? ''}?edit=${item?.id ?? ''}`);
  }, [history, item?.id, item?.product?.id]);

  const removeItem = (): void => {
    if (item) {
      if (item.comboId) {
        dispatch(removeComboToCart(item.comboId));
        onClose();

        return;
      }

      dispatch(removeProductToCart(item));
      onClose();
    }
  };

  return (
    <Modal isSmall isShow={!!item} onClose={onClose} headerTitle={`${item?.quantity}x ${item?.product?.name}`}>
      <S.Menu>
        <Button data-test="action-edit-item" onClick={navigateToAction}>
          {getTranslation('general.editItem')}
        </Button>

        {isObservationsEnabled && (
          <Button data-test="action-add-observation" onClick={navigateToAction}>
            {getTranslation('general.addObservation')}
          </Button>
        )}

        <Button isGhost={false} data-test="action-remove-item" onClick={(): void => removeItem()}>
          {getTranslation('general.removeItem')}
        </Button>
      </S.Menu>
    </Modal>
  );
};

export default ItemEdit;
