import { AnyAction } from 'redux';
import { all, takeLatest, put } from 'redux-saga/effects';

import { convertSettingsData } from '~/utils';
import { fetchEstablishmentInfo } from '~/services';
import { InfoRequestModes } from '~/interfaces/enums';
import { themeFetchSuccess } from '~/redux-tools/store/theme/actions';
import { FetchEstablishmentInfoResponse } from '~/services/fetchEstablishmentInfo';

import { EstablishmentActionTypes } from './types';
import {
  setChatbot,
  fetchEstablishmentError,
  setEstablishmentCoupons,
  setEstablishmentSettings,
  setEstablishmentLoyaltyProgram
} from './actions';

export function* fetchEstablishment({ payload }: AnyAction) {
  const { slug, provider, isAbrahao, afterAction, selectedLanguage } = payload;

  try {
    const mode =
      !!window.location.host && window.location.host.includes(process.env.NEXT_PUBLIC_COOKIE_DOMAIN || 'goomer')
        ? InfoRequestModes.slug
        : InfoRequestModes.domain;

    const infoParams = ((): string => {
      if (mode === InfoRequestModes.slug) return String(slug);

      return window.location.host.replace('www.', '');
    })();

    const response = (yield fetchEstablishmentInfo({
      mode,
      provider,
      isAbrahao,
      slug: infoParams,
      selectedLanguage
    })) as FetchEstablishmentInfoResponse;

    const { info, settings, loyaltyProgram } = response;

    yield put(setEstablishmentSettings(convertSettingsData({ ...settings })));
    yield put(setEstablishmentLoyaltyProgram(loyaltyProgram));
    yield put(setChatbot(info?.chatbot));
    yield put(setEstablishmentCoupons(info?.coupons));

    yield put(
      themeFetchSuccess({
        primary: settings?.mm_main_color,
        originalPrimary: settings?.mm_main_color
      })
    );

    if (afterAction) {
      afterAction(false);
    }
  } catch (error) {
    if (afterAction) {
      afterAction(true);
    }

    yield put(fetchEstablishmentError((error as Error)?.message));

    throw new Error(`Erro ao buscar informações da loja. Message: ${JSON.stringify((error as Error)?.message)}`);
  }
}

export default all([takeLatest(EstablishmentActionTypes.FETCH_ESTABLISHMENT_REQUEST, fetchEstablishment)]);
