import styled, { css, DefaultTheme } from 'styled-components';
import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input';

import { EmailVerificationStatusEnum } from '~/interfaces/enums';
import { EmailVerificationInputProps } from '~/components/Input/EmailVerificationInput';

type ContainerProps = Pick<EmailVerificationInputProps, 'status'>;

export const Container = styled.div<ContainerProps>`
  ${({ theme, status }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;

    > span {
      margin-top: 8px;

      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      text-align: center;
      color: ${status === EmailVerificationStatusEnum.success ? theme.colors.success : theme.colors.error};
    }
  `}
`;

const inputModifications = {
  [EmailVerificationStatusEnum.default]: (theme: DefaultTheme) => css`
    color: ${theme.colors.textColor};

    &:focus {
      border-color: ${theme.brandColors.primary};

      color: ${theme.brandColors.primary};
    }
  `,
  [EmailVerificationStatusEnum.error]: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.error};

    color: ${theme.colors.error};

    &:focus {
      border-color: ${theme.colors.error};

      color: ${theme.colors.error};
    }
  `,
  [EmailVerificationStatusEnum.success]: (theme: DefaultTheme) => css`
    border-color: ${theme.colors.success} !important;

    color: ${theme.colors.success} !important;

    &:focus {
      border-color: ${theme.colors.success};

      color: ${theme.colors.success};
    }
  `
};

type InputCodeProps = Pick<EmailVerificationInputProps, 'status'> & ReactCodeInputProps;

export const Input = styled(ReactCodeInput).attrs((props: InputCodeProps) => ({
  ...props,
  inputStyle: {
    width: 48,
    height: 48,
    borderRadius: '5px'
  }
}))`
  ${({ theme, status }) => css`
    input {
      margin-right: 12px;
      border: 1px solid ${theme.colors.placeholderGray};

      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }

      &[disabled] {
        background: transparent !important;

        ${inputModifications[status](theme)}
      }

      ${inputModifications[status](theme)}
    }
  `}
`;
