import styled, { css } from 'styled-components';

export const RadioGroupContainer = styled.div`
  padding: 0 1rem 0.5rem;

  width: 100%;
  overflow: auto;

  display: flex;
`;

export const RadioDayContainer = styled.label`
  ${({ theme }) => css`
    position: relative;

    height: 4rem;
    width: 4.5rem;
    min-width: 4.5rem;
    border-radius: 0.25rem;

    gap: 0.25rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    & + & {
      margin-left: 0.5rem;
    }

    input {
      position: absolute;

      opacity: 0;
      pointer-events: none;
    }

    .border {
      position: absolute;

      height: 4rem;
      width: 4.5rem;
      border-radius: 0.25rem;
      border: 1px solid ${theme.colors.mediumGray};

      background: transparent;
      pointer-events: none;
    }

    input:checked ~ span {
      color: ${theme.brandColors.primary};
    }

    input:checked ~ .border {
      border-color: ${theme.brandColors.primary};
    }
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    margin: 0;

    font-weight: 600;
    line-height: 150%;
    text-align: center;
    font-size: 0.875rem;
    text-transform: capitalize;
    color: ${theme.colors.mediumGray};
  `}
`;

export const Day = styled.span`
  ${({ theme }) => css`
    margin: 0;

    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
    color: ${theme.colors.mediumGray};
  `}
`;
