import { isThisChromeVersionSupported, scrollTo } from '~/utils';

interface ScrollToElementProps {
  isSmooth?: boolean;
  isRelative?: boolean;
  element: HTMLElement | string | null;
}

export default function scrollToElement({ element, isSmooth = true, isRelative = false }: ScrollToElementProps): void {
  if (!element) return;

  let theElement: HTMLElement | null;

  if (typeof element === 'string' && typeof document !== 'undefined') {
    theElement = document.querySelector(element);
  } else {
    theElement = element as HTMLElement;
  }

  if (isThisChromeVersionSupported()) {
    const scrollPadding = 16;
    let totalTopOffset = 0;
    let currentElement = theElement;

    while (currentElement) {
      totalTopOffset += currentElement.offsetTop;
      currentElement = currentElement.offsetParent as HTMLElement;
    }

    scrollTo({
      top: isRelative ? theElement?.offsetTop : totalTopOffset - scrollPadding,
      behavior: isSmooth ? 'smooth' : 'auto'
    });
  }
}
