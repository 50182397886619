import React from 'react';

const LoginIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40334 4.14497C7.71192 3.88784 8.17051 3.92956 8.42763 4.23814L11.1739 7.53403C11.3987 7.80373 11.3987 8.19546 11.1739 8.46516L8.42763 11.7611C8.17051 12.0696 7.71192 12.1113 7.40334 11.8542C7.09477 11.5971 7.05305 11.1385 7.31017 10.8299L9.06255 8.72687H0.727517C0.325855 8.72687 0.000244141 8.40126 0.000244141 7.9996C0.000244141 7.59793 0.325855 7.27232 0.727517 7.27232H9.06255L7.31017 5.16926C7.05305 4.86068 7.09477 4.40209 7.40334 4.14497Z"
        fill="#7F8F9F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69279 15.9918C6.36541 16.0191 6.03593 15.9781 5.72518 15.8716C5.41442 15.765 5.12915 15.5951 4.8874 15.3727C4.64564 15.1502 4.45266 14.88 4.32065 14.5792C4.18864 14.2784 4.12047 13.9534 4.12046 13.6249V12.1203C4.12046 11.7186 4.44607 11.393 4.84773 11.393C5.24939 11.393 5.575 11.7186 5.575 12.1203V13.6248C5.57501 13.7522 5.60144 13.8781 5.6526 13.9947C5.70377 14.1113 5.77857 14.2161 5.87228 14.3023C5.96599 14.3885 6.07657 14.4544 6.19702 14.4957C6.31748 14.537 6.44519 14.5528 6.57209 14.5423L13.1639 13.9935C13.394 13.9743 13.6086 13.8694 13.765 13.6994C13.9213 13.5295 14.0081 13.307 14.0082 13.0761M6.69279 15.9918L13.2846 15.443C13.8783 15.3936 14.4318 15.1228 14.8353 14.6844C15.2387 14.246 15.4627 13.6719 15.4627 13.0761L15.4627 2.92473C15.4627 2.32893 15.2387 1.75486 14.8353 1.31644C14.4319 0.878035 13.8784 0.607253 13.2847 0.557788L6.69289 0.00819628C6.3654 -0.0190793 6.03574 0.0218935 5.7249 0.128534C5.41406 0.235175 5.12873 0.405157 4.88696 0.627732C4.64519 0.850307 4.45223 1.12063 4.32029 1.42161C4.1884 1.72248 4.12035 2.04745 4.12046 2.37596L4.12046 3.88054C4.12046 4.2822 4.44607 4.60781 4.84773 4.60781C5.24939 4.60781 5.575 4.2822 5.575 3.88054V2.37596C5.57494 2.24858 5.60132 2.12225 5.65246 2.00558C5.7036 1.88892 5.7784 1.78414 5.87211 1.69786C5.96583 1.61159 6.07643 1.5457 6.19691 1.50436C6.31738 1.46304 6.44511 1.44715 6.57203 1.45771L13.1638 2.00731C13.394 2.02647 13.6086 2.13144 13.765 2.30138C13.9213 2.47131 14.0081 2.6938 14.0082 2.92473L14.0082 13.0761"
        fill="#7F8F9F"
      />
    </svg>
  );
};

export default LoginIcon;
