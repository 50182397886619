import React from 'react';

import { ReferenceId } from '~/interfaces/general';

import Detail from '../detail/[productId]';

interface SuggestionDetailProps {
  suggestionId: number;
  productReferenceID: ReferenceId;
}

const SuggestionDetail: React.FC<SuggestionDetailProps> = ({ productReferenceID, suggestionId }) => {
  return <Detail suggestionId={suggestionId} productReferenceID={productReferenceID} />;
};

export default SuggestionDetail;
