import { useState, useEffect, useMemo } from 'react';

import { CSSProperties } from 'styled-components';
import GooglePayButton, { ReadyToPayChangeResponse } from '@google-pay/button-react';

import { LanguageCodeEnum, LocalStorageEnum } from '~/interfaces/enums';

const GOOGLE_PAY_GATEWAY = process.env.NEXT_PUBLIC_GOOGLE_PAY_GATEWAY || '';
const GOOGLE_PAY_MERCHANT_ID = process.env.NEXT_PUBLIC_GOOGLE_PAY_MERCHANT_ID || '';
const GOOGLE_PAY_GATEWAY_MERCHANT_ID = process.env.NEXT_PUBLIC_GOOGLE_PAY_GATEWAY_MERCHANT_ID || '';
const GOOGLE_PAY_ENVIRONMENT = process.env.NEXT_PUBLIC_GOOGLE_PAY_ENVIRONMENT as google.payments.api.Environment;

interface GooglePayComponentProps {
  totalPrice: string;
  storeName?: string;
  merchantName: string;
  style?: CSSProperties;
  onConfirm: (token: google.payments.api.PaymentData) => void;
  onGooglePayReady: (result: ReadyToPayChangeResponse) => void;
}

const GooglePayCheckoutButton = ({
  style,
  onConfirm,
  totalPrice,
  merchantName,
  onGooglePayReady
}: GooglePayComponentProps): JSX.Element => {
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<LanguageCodeEnum>(LanguageCodeEnum.ptBr);

  const buttonLanguageCode = useMemo(
    () => ({
      [LanguageCodeEnum.es]: 'es',
      [LanguageCodeEnum.ptBr]: 'pt',
      [LanguageCodeEnum.enUs]: 'en'
    }),
    []
  );

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const localStorageSelectedLanguage = localStorage.getItem(LocalStorageEnum.selectedLanguage) as LanguageCodeEnum;

      if (localStorageSelectedLanguage) {
        setSelectedLanguageCode(localStorageSelectedLanguage);
      }
    }
  }, []);

  return (
    <GooglePayButton
      style={style}
      buttonSizeMode="fill"
      environment={GOOGLE_PAY_ENVIRONMENT}
      buttonLocale={buttonLanguageCode[selectedLanguageCode]}
      onReadyToPayChange={(result): void => onGooglePayReady(result)}
      onLoadPaymentData={(paymentData): void => onConfirm(paymentData)}
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['VISA', 'MASTERCARD', 'AMEX', 'DISCOVER', 'JCB']
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: GOOGLE_PAY_GATEWAY,
                gatewayMerchantId: GOOGLE_PAY_GATEWAY_MERCHANT_ID
              }
            }
          }
        ],
        merchantInfo: {
          merchantName: merchantName,
          merchantId: GOOGLE_PAY_MERCHANT_ID
        },
        transactionInfo: {
          countryCode: 'BR',
          currencyCode: 'BRL',
          totalPrice: totalPrice,
          totalPriceLabel: 'Total',
          totalPriceStatus: 'FINAL'
        }
      }}
    />
  );
};

export default GooglePayCheckoutButton;
