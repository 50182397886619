import { Reducer } from 'redux';
import theme from 'styles/theme';
import tinycolor from 'tinycolor2';
import { DefaultTheme } from 'styled-components';

import { IThemeState, ThemeActionTypes } from './types';

export const defaultTheme: DefaultTheme = {
  isDarkMode: false,
  isLightBrandColor: false,
  colors: theme.lightColors,
  brandColors: theme.brandColors
} as DefaultTheme;

const initialState: IThemeState = {
  message: '',
  loading: false,
  theme: defaultTheme
};

const ThemeReducer: Reducer<IThemeState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ThemeActionTypes.THEME_FETCH_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case ThemeActionTypes.THEME_FETCH_SUCCESS: {
      const color = tinycolor(payload.theme.primary);
      const isLight: boolean = color.isLight();

      const primaryBrandColor: string = ((): string => {
        if (!state.theme.isDarkMode) {
          return isLight ? theme.lightColors.textColor : (payload.theme.primary as string);
        } else {
          return isLight ? (payload.theme.primary as string) : theme.darkColors.textColor;
        }
      })();

      const brandTextColor: string = ((): string => {
        if (!state.theme.isDarkMode) {
          return isLight ? theme.lightColors.textColor : theme.lightColors.backgroundColor;
        } else {
          return isLight ? theme.darkColors.backgroundColor : (payload.theme.primary as string);
        }
      })();

      const brandBackgroundColor: string = ((): string => {
        return !state.theme.isDarkMode && isLight ? (payload.theme.primary as string) : primaryBrandColor;
      })();

      return {
        ...state,
        loading: false,
        theme: {
          ...state.theme,
          isLightBrandColor: isLight,
          brandColors: {
            ...state.theme.brandColors,
            actionText: brandTextColor,
            primary: primaryBrandColor,
            actionBackground: brandBackgroundColor,
            originalPrimary: payload.theme.primary
          }
        }
      };
    }

    case ThemeActionTypes.THEME_FETCH_ERROR: {
      const { message } = payload;

      return {
        ...state,
        message,
        loading: false,
        theme: {
          ...initialState.theme
        }
      };
    }

    case ThemeActionTypes.SET_IS_DARK_MODE: {
      const { isDarkMode } = payload;

      const primaryBrandColor: string = ((): string => {
        if (!isDarkMode) {
          return state.theme.isLightBrandColor ? theme.lightColors.textColor : state.theme.brandColors.originalPrimary;
        } else {
          return state.theme.isLightBrandColor ? state.theme.brandColors.originalPrimary : theme.darkColors.textColor;
        }
      })();

      const brandTextColor: string = ((): string => {
        if (!isDarkMode) {
          return state.theme.isLightBrandColor ? theme.lightColors.textColor : theme.lightColors.backgroundColor;
        } else {
          return state.theme.isLightBrandColor
            ? theme.darkColors.backgroundColor
            : state.theme.brandColors.originalPrimary;
        }
      })();

      const brandBackgroundColor: string = ((): string => {
        return !isDarkMode && state.theme.isLightBrandColor
          ? state.theme.brandColors.originalPrimary
          : primaryBrandColor;
      })();

      return {
        ...state,
        theme: {
          ...state.theme,
          isDarkMode: isDarkMode,
          colors: isDarkMode ? theme.darkColors : theme.lightColors,
          brandColors: {
            ...state.theme.brandColors,
            actionText: brandTextColor,
            primary: primaryBrandColor,
            actionBackground: brandBackgroundColor
          }
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default ThemeReducer;
