import styled, { css } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Container = styled.div<{
  $isDisabled?: boolean;
  $isDetailed?: boolean;
  $isSuggestion?: boolean;
  $isHorizontalLayout?: boolean;
  $isMultipleCardsLayout?: boolean;
}>`
  ${({ theme, $isDisabled, $isDetailed, $isSuggestion, $isHorizontalLayout, $isMultipleCardsLayout }) => css`
    width: 10.85rem;
    height: 16.5rem;
    min-width: 10.85rem;
    min-height: 16.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 4.69px 9.38px 0px #0000001a;

    display: flex;
    align-items: center;
    flex-direction: column;

    cursor: pointer;
    background-color: ${theme.colors.backgroundColor};

    ${$isDisabled &&
    css`
      opacity: 0.6;
      box-shadow: none;
      cursor: not-allowed;
    `}

    ${$isHorizontalLayout &&
    css`
      width: auto;
      height: auto;
      min-height: 8.37rem;

      flex-direction: row;
    `}

    ${!$isMultipleCardsLayout &&
    css`
      width: 100%;
      height: auto;
      padding: 1rem 0;
      min-height: auto;
      border-radius: 0;
      box-shadow: none;

      flex-direction: row-reverse;
    `}

    ${$isSuggestion &&
    !theme.isDarkMode &&
    css`
      background-color: ${theme.colors.gray100};
    `}

    ${$isDetailed &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
    `}
  `}
`;

export const ImageContainer = styled.div<{
  $hasImage: boolean;
  $isDetailed?: boolean;
  $isHorizontalLayout: boolean;
  $isMultipleCardsLayout: boolean;
}>`
  ${({ $hasImage, $isDetailed, $isHorizontalLayout, $isMultipleCardsLayout }) => css`
    position: relative;
    width: 100%;
    min-height: 7.5rem;
    max-height: 7.5rem;
    border-radius: 0.5rem 0.5rem 0 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => (theme.isDarkMode ? theme.colors.gray400 : theme.colors.gray200)};

    ${$isHorizontalLayout &&
    css`
      height: 100%;
      width: 9.75rem;
      max-height: none;
      min-width: 9.75rem;
      border-radius: 0.5rem 0 0 0.5rem;

      img {
        border-radius: 0.5rem 0 0 0.5rem;
      }
    `}

    ${!$hasImage &&
    !$isMultipleCardsLayout &&
    css`
      background-color: inherit;
    `}

    ${!$isMultipleCardsLayout &&
    css`
      margin: 0;
      width: 5.5rem;
      height: 5.5rem;
      min-width: 5.5rem;
      min-height: 5.5rem;
      margin: 0 1rem 0 0;
      border-radius: 0.5rem;

      ${$isDetailed &&
      css`
        margin: 0;
        width: 6.25rem;
        height: 6.25rem;
        min-width: 6.25rem;
        min-height: 6.25rem;
      `}
    `}
  `}
`;

export const Image = styled(LazyLoadImage)<{ $isDetailed?: boolean; $isMultipleCardsLayout: boolean }>`
  ${({ $isDetailed, $isMultipleCardsLayout }) => css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem 0.5rem 0 0;

    pointer-events: none;

    ${!$isMultipleCardsLayout &&
    css`
      width: 5.5rem;
      height: 5.5rem;
      border-radius: 0.5rem;

      ${$isDetailed &&
      css`
        width: 6.25rem;
        height: 6.25rem;
      `}
    `}
  `}
`;

export const Content = styled.div<{ $isDetailed?: boolean }>`
  ${({ $isDetailed }) => css`
    width: 100%;
    height: 100%;
    min-height: 4rem;
    padding: ${$isDetailed ? '0 1rem 0 0' : '0.857rem'};

    display: flex;
    flex-direction: column;
  `}
`;

export const ProductName = styled.h2`
  line-clamp: 2;
  font-weight: 800;
  overflow: hidden;
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`;

export const ProductTagsWrapper = styled.div`
  margin: 0.5rem 0 0;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  margin-top: 0.5rem;

  display: flex;
  flex-direction: column;
`;

export const DescriptionLine = styled.span`
  ${({ theme }) => css`
    font-weight: 500;
    line-height: 140%;
    font-size: 0.75rem;
    word-break: break-word;
    color: ${theme.colors.gray500};
  `}
`;

export const ShortDescription = styled.span`
  ${({ theme }) => css`
    p,
    span {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.225rem;

      color: ${theme.colors.gray500};
    }

    .show-more-less-clickable {
      width: fit-content;
      margin-top: 0.285rem;

      display: block;

      z-index: 9999;
      cursor: pointer;
      font-weight: 600;
      text-decoration: underline;
      color: ${({ theme }) => (theme.isLightBrandColor ? theme.colors.textColor : theme.brandColors.primary)};
    }
  `}
`;

export const PriceWrapper = styled.div<{ $hasMarginTop: boolean }>`
  ${({ $hasMarginTop }) => css`
    display: flex;
    flex-direction: column;

    ${$hasMarginTop &&
    css`
      margin-top: 1rem;
    `}
  `}
`;

export const Price = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 0.87rem;
`;

export const FooterWrapper = styled.div<{ $isHorizontalLayout: boolean }>`
  ${({ $isHorizontalLayout }) => css`
    margin-top: auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    ${$isHorizontalLayout &&
    css`
      align-items: flex-end;
    `}
  `}
`;

export const FastAddButton = styled.button`
  width: 2rem;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.brandColors.actionBackground};
`;

export const PriceText = styled.span`
  font-size: 0.75rem;
`;
