import React, { useMemo } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { KeyValueGeneric } from '@goomerdev/goomer-toolbox/src/interfaces';
import {
  RiCupLine,
  RiBeerLine,
  RiBowlLine,
  RiStarLine,
  RiCake3Line,
  RiGobletLine,
  RiDrinks2Line,
  RiGoblet2Line,
  RiRestaurantLine,
  RiDiscountPercentLine
} from 'react-icons/ri';

import { CandyIcon, FriedPastry, Hamburger, HotDrink, Pizza, Sushi } from './assets';

import * as S from './styles';

export enum MenuIconNameEnum {
  Drink = 'bebidas',
  Food = 'comidas',
  Highlight = 'destaques',
  Fondue = 'fondue',
  Hamburger = 'lanches',
  Offer = 'ofertas',
  Dessert = 'sobremesas',
  Sushi = 'sushi',
  Pizza = 'pizza',
  Wine = 'vinho',
  Glass = 'drink-taca',
  HotDrink = 'bebida-quente',
  Candy = 'brigadeiro',
  FriedPastry = 'pastel',
  Coffee = 'cafe',
  Water = 'copo-agua'
}

const iconByNameList: KeyValueGeneric<React.ReactNode> = {
  [MenuIconNameEnum.Pizza]: <Pizza />,
  [MenuIconNameEnum.Sushi]: <Sushi />,
  [MenuIconNameEnum.Candy]: <CandyIcon />,
  [MenuIconNameEnum.Coffee]: <RiCupLine />,
  [MenuIconNameEnum.Drink]: <RiBeerLine />,
  [MenuIconNameEnum.HotDrink]: <HotDrink />,
  [MenuIconNameEnum.Fondue]: <RiBowlLine />,
  [MenuIconNameEnum.Glass]: <RiGobletLine />,
  [MenuIconNameEnum.Wine]: <RiGoblet2Line />,
  [MenuIconNameEnum.Water]: <RiDrinks2Line />,
  [MenuIconNameEnum.Dessert]: <RiCake3Line />,
  [MenuIconNameEnum.Hamburger]: <Hamburger />,
  [MenuIconNameEnum.Highlight]: <RiStarLine />,
  [MenuIconNameEnum.Food]: <RiRestaurantLine />,
  [MenuIconNameEnum.FriedPastry]: <FriedPastry />,
  [MenuIconNameEnum.Offer]: <RiDiscountPercentLine />
};

export interface MenuIconProps {
  alt: string;
  color?: string;
  iconUrl?: string;
  sizeRem?: number;
  isCustomIcon: boolean;
  isCurrentMenuActive?: boolean;
}

const MenuIcon = ({
  alt,
  color,
  sizeRem,
  iconUrl,
  isCustomIcon,
  isCurrentMenuActive = false
}: MenuIconProps): JSX.Element => {
  const menuIconElement = useMemo(() => {
    const iconName = (iconUrl || '').split('/assets/menu/')[1]?.split('.svg')[0];

    if (!iconName || !iconByNameList[iconName]) return iconByNameList[MenuIconNameEnum.Food];

    return iconByNameList[iconName];
  }, [iconUrl]);

  return (
    <S.Container
      $color={color}
      $sizeRem={sizeRem}
      aria-label={`Ícone do menu de ${alt}`}
      $isCurrentMenuActive={isCurrentMenuActive}
    >
      {isCustomIcon ? <LazyLoadImage src={iconUrl} /> : menuIconElement}
    </S.Container>
  );
};

export default MenuIcon;
