import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  padding-bottom: 30px;
`;

export const HowItWorksWrapper = styled.div`
  padding: 0.5rem 0.75rem;
  margin: 0.75rem 0 0.5rem 0;

  display: flex;
  gap: 0.8rem;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border: 1px solid ${({ theme }) => theme.colors.gray300};
`;

export const Info = styled.div`
  font-size: 0.87rem;

  strong {
    font-weight: 800;
    font-size: 0.87rem;
  }
`;

export const HowItWorksAnchor = styled.a`
  font-weight: 800;
  font-size: 0.87rem;
  color: ${({ theme }) => theme.brandColors.primary};
`;
