import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Cookies from '~/utils/cookies';
import { CookieNameEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import { fetchCustomerInfoRequest } from '~/redux-tools/store/user/actions';

import useUserAuthenticationStatus from '../useUserAuthenticationStatus';
import useSlug from '../useSlug';
import useLogout from '../useLogout';

export default function useSyncAuthenticationToken(): void {
  const dispatch = useDispatch();

  const user = useSelector((state: IApplicationState) => state.user.data);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const [isInfoUpdated, setIsInfoUpdated] = useState<boolean>(false);

  const slug = useSlug();
  const { handleLogout } = useLogout();
  const { isUserAuthenticated } = useUserAuthenticationStatus();

  const isSettingsEmpty = useMemo(() => slug && !settings, [settings, slug]);

  const cookieToken = useMemo(() => Cookies.getCookie({ name: CookieNameEnum.userToken }), []);
  const hasCookieToken = useMemo(() => !!cookieToken, [cookieToken]);

  const globalTag = useMemo(() => Cookies.getCookie({ name: CookieNameEnum.tokenGlobalTag }), []);
  const hasGlobalTag = useMemo(() => globalTag === 'true', [globalTag]);

  const handleFetchCustomerInfo = useCallback(() => {
    if (hasCookieToken) {
      const token = (hasCookieToken ? cookieToken : user?.authenticationToken) || '';

      dispatch(
        fetchCustomerInfoRequest({
          storeId: settings?.id || 0,
          token: token || ''
        })
      );
    }
  }, [cookieToken, dispatch, hasCookieToken, settings?.id, user?.authenticationToken]);

  useEffect(() => {
    if (isInfoUpdated) {
      return;
    }

    const hasStoreId = !!settings?.id;
    if (!isSettingsEmpty && hasStoreId) {
      setIsInfoUpdated(true);

      if (hasGlobalTag && !hasCookieToken && isUserAuthenticated) {
        handleLogout();
        return;
      }

      if (hasGlobalTag || isUserAuthenticated) {
        handleFetchCustomerInfo();
        return;
      }

      return;
    }
  }, [
    handleFetchCustomerInfo,
    handleLogout,
    hasCookieToken,
    hasGlobalTag,
    isInfoUpdated,
    isSettingsEmpty,
    isUserAuthenticated,
    settings?.id
  ]);
}
