import styled from 'styled-components';

export const InputWithIconContainer = styled.div`
  position: relative;

  > span {
    position: absolute !important;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);

    width: 16px;
    height: 16px;
  }
`;
