import React from 'react';

import Link from 'next/link';
import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import { MenuLabelEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';

import * as S from './styles';

export interface NavigatorItem {
  count?: string;
  linkTo: string;
  dataTest?: string;
  icon: JSX.Element;
  gaEvent?: gaEvents;
  label: MenuLabelEnum;
}

interface NavigatorProps {
  active: string;
  showCount?: boolean;
  items: NavigatorItem[];
}

const Navigator: React.FC<NavigatorProps> = ({ items, active, showCount = false }) => {
  const { getTranslation } = useTranslator();

  const settings = useSelector((state: IApplicationState) => state.establishment.settings);

  const gaTrackToolbar = (gaEvent: gaEvents): void => {
    return GoogleAnalytics.trackEvent(gaEvent, {
      // eslint-disable-next-line camelcase
      establishment_id: settings?.id
    });
  };

  const handleNavigatorItemClick = (gaEvent: gaEvents | undefined): void => {
    if (gaEvent) {
      gaTrackToolbar(gaEvent);
    }
  };

  return (
    <S.Container className="navigator">
      {items.map(({ icon, count, label, linkTo, gaEvent, dataTest }) => (
        <S.NavigatorItemWrapper key={label} onClick={(): void => handleNavigatorItemClick(gaEvent)}>
          <Link href={`/${linkTo}`}>
            <S.NavigatorItem $active={linkTo === active} data-test={dataTest}>
              <div>{icon}</div>

              {showCount && count && (
                <div className="count">
                  <p>{count}</p>
                </div>
              )}

              <p>{getTranslation(label)}</p>
            </S.NavigatorItem>
          </Link>
        </S.NavigatorItemWrapper>
      ))}
    </S.Container>
  );
};

export default Navigator;
