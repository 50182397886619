import styled from 'styled-components';

import { ButtonResume } from '~/components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;

  .content {
    padding: 8px 16px 0;
  }
`;

export const ButtonResumeCustom = styled(ButtonResume)`
  min-height: 48px;
  border-radius: 5px;

  background: transparent;
`;
