import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { LoaderIcon } from '~/assets';
import { Product } from '~/interfaces';
import { Counter } from '~/components';
import { IApplicationState } from '~/redux-tools/store';
import { EstablishmentStatusEnum } from '~/interfaces/enums';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { useEstablishmentStatus, useTranslator } from '~/hooks';
import { convertToCurrency, requiredWarnScroll, showToast } from '~/utils';

import * as S from './styles';

interface FloatingFooterProps {
  price: number;
  isDisabled: boolean;
  isLoading?: boolean;
  isProductAvailable?: boolean;
  initialCount: number | undefined;
  onClick: (count: number) => void;
  trackingData: { product?: Product };
  counter?: {
    minValue?: number;
    maxValue?: number;
  };
}

const FloatingFooter: React.FC<FloatingFooterProps> = ({
  price,
  counter,
  onClick,
  isLoading,
  isDisabled,
  trackingData,
  initialCount = 1,
  isProductAvailable = true
}) => {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const [count, setCount] = useState<number>(initialCount);

  const { getTranslation } = useTranslator();
  const { isAbleToOrder } = useEstablishmentStatus({});

  useEffect(() => {
    setCount(initialCount);
  }, [initialCount]);

  const gaTrackAddToCart = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.addToCart, {
      value: price,
      // eslint-disable-next-line camelcase
      content_name: trackingData.product?.name,
      currency: settings?.mm_currency?.code || 'BRL'
      // content_category: trackingData.product?.group_name
    });
  }, [trackingData, price, settings]);

  const shouldDisableAddCounter: boolean = useMemo(
    () => (typeof counter?.maxValue === 'number' ? counter?.maxValue <= count : false),
    [count, counter?.maxValue]
  );

  const shouldDisableSubCounter: boolean = useMemo(
    () => (typeof counter?.minValue === 'number' ? counter?.minValue >= count : false) || count === 1,
    [count, counter?.minValue]
  );

  const handleSubmit: () => void = useCallback(() => {
    if (shouldDisableAddCounter && shouldDisableSubCounter && counter?.maxValue !== counter?.minValue) {
      showToast({
        type: 'error',
        closeOnClick: true,
        text: getTranslation('floatingFooter.quantityLimitOnProduct')
      });

      return;
    }

    if (isDisabled) {
      return requiredWarnScroll({ warningClass: '-warn', selector: '[data-done="false"]' });
    }

    gaTrackAddToCart();

    return onClick(count);
  }, [
    count,
    onClick,
    isDisabled,
    getTranslation,
    gaTrackAddToCart,
    counter?.maxValue,
    counter?.minValue,
    shouldDisableAddCounter,
    shouldDisableSubCounter
  ]);

  const renderFooterActions = useCallback(() => {
    if (!isAbleToOrder) {
      return (
        <>
          <h2>{getTranslation('floatingFooter.currentlyClosed')}</h2>

          <span>{getTranslation('floatingFooter.notAcceptingNewOrders')}</span>
        </>
      );
    }

    return (
      <>
        <Counter
          value={count}
          handleAddItem={(): void => setCount(count + 1)}
          disableAddItem={isDisabled || shouldDisableAddCounter}
          disabledSubtractItem={isDisabled || shouldDisableSubCounter}
          handleSubtractItem={(): void => setCount(count === 1 ? count : count - 1)}
        />

        <S.AddButton
          onClick={handleSubmit}
          style={{ marginLeft: '10px' }}
          data-test={isDisabled ? 'btn-add-item-disabled' : 'btn-add-item'}
          isDisabled={isDisabled || (shouldDisableAddCounter && shouldDisableSubCounter)}
        >
          <S.AddButtonText>{getTranslation('general.add')}</S.AddButtonText>

          {isLoading ? (
            <S.Loader>
              <LoaderIcon width={32} height={32} aria-label="Imagem de carregamento" />
            </S.Loader>
          ) : (
            <S.AddButtonText>{convertToCurrency(count * price)}</S.AddButtonText>
          )}
        </S.AddButton>
      </>
    );
  }, [
    price,
    count,
    isLoading,
    isDisabled,
    handleSubmit,
    isAbleToOrder,
    getTranslation,
    shouldDisableAddCounter,
    shouldDisableSubCounter
  ]);

  return (
    <S.Footer className={isAbleToOrder && isProductAvailable ? '' : EstablishmentStatusEnum.closed}>
      {isProductAvailable ? (
        renderFooterActions()
      ) : (
        <>
          <h2>{getTranslation('floatingFooter.unavailableProduct')}</h2>

          <span>{getTranslation('floatingFooter.unavailableDueToTheTime')}</span>
        </>
      )}
    </S.Footer>
  );
};

export default FloatingFooter;
