import React, { useRef, useState, useCallback, useEffect } from 'react';

import { isMobile } from 'react-device-detect';

import { useDesktopHorizontallScroll } from '~/hooks';

import * as S from './styles';

const CAROUSEL_SCROLL_CLASSNAME = 'carousel-scroll';

export interface CarouselProps {
  itemList: JSX.Element[];
}

const Carousel = ({ itemList }: CarouselProps): JSX.Element => {
  const isFirstRender = useRef(true);

  const [, setCarouselScrollPos] = useState<number>(0);
  const [carouselScrollWidth, setCarouselScrollWidth] = useState<number>(0);
  const [carouselVisibleWidth, setCarouselVisibleWidth] = useState<number>(0);

  useDesktopHorizontallScroll({ scrollClass: `.${CAROUSEL_SCROLL_CLASSNAME}` });

  const getCarouselScroll = useCallback((scrollParent: HTMLDivElement) => {
    setCarouselScrollPos(scrollParent.scrollLeft);
    setCarouselScrollWidth(scrollParent.scrollWidth);
    setCarouselVisibleWidth(scrollParent.clientWidth);
  }, []);

  const getElementOffsets = useCallback(
    (elementIndex: number) => {
      const elementWidth = (carouselScrollWidth - carouselVisibleWidth) / itemList.length;

      const offsetLeft = elementWidth * elementIndex;
      const offsetRight = elementWidth * (elementIndex + 1);

      return {
        left: offsetLeft,
        right: offsetRight
      };
    },
    [carouselScrollWidth, carouselVisibleWidth, itemList.length]
  );

  const isElementInView = useCallback((elementId: string) => {
    const element = document.getElementById(elementId);
    const boundingClientRect = element?.getBoundingClientRect();

    if (!boundingClientRect || !element) {
      return false;
    }

    const visibilityMargin = element?.clientWidth * 0.1;
    const leftLimit = boundingClientRect.left + visibilityMargin;
    const rightLimit = boundingClientRect.right - visibilityMargin;

    return leftLimit > 0 && rightLimit <= window.innerWidth;
  }, []);

  const scrollToItem = useCallback(
    (elementId: string, elementIndex: number) => {
      const element = document.getElementById(elementId);

      const parentScroll = element?.parentElement;

      if (!parentScroll) {
        return;
      }

      const offset = getElementOffsets(elementIndex);

      const scrollPoint = elementIndex <= itemList.length / 2 ? offset.left : offset.right;

      parentScroll.scrollTo({ left: scrollPoint, behavior: 'smooth' });
    },
    [getElementOffsets, itemList.length]
  );

  useEffect(() => {
    if (!isFirstRender.current) {
      return;
    }

    isFirstRender.current = false;

    if (itemList.length > 0) {
      const elementId = itemList[0].props.id;
      const element = document.getElementById(elementId);
      const parentScroll = element?.parentElement;

      getCarouselScroll(parentScroll as HTMLDivElement);
    }
  }, [getCarouselScroll, itemList]);

  return (
    <S.Container>
      <S.Scroll
        $isMobile={isMobile}
        className={CAROUSEL_SCROLL_CLASSNAME}
        onScroll={(event): void => getCarouselScroll(event.target as HTMLDivElement)}
      >
        {itemList.map((item) => item)}
      </S.Scroll>

      {isMobile && itemList.length > 1 && (
        <S.Navigation>
          {itemList.map((item, index) => (
            <S.NavigationItem
              key={item.props.id}
              $isActive={isElementInView(item.props.id)}
              onClick={(): void => scrollToItem(item.props.id, index)}
            />
          ))}
        </S.Navigation>
      )}
    </S.Container>
  );
};

export default Carousel;
