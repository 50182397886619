import styled from 'styled-components';

export const AnimationWrapper = styled.div`
  width: 6.25rem;
  height: 6.25rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;
