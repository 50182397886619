import { Reducer } from 'redux';

import { ErrorLogActionTypes, IErrorLogState } from './types';

const initialState = {
  log: undefined
};

const errorLogReducer: Reducer<IErrorLogState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ErrorLogActionTypes.ADD_ERROR:
      return {
        ...state,
        log: payload
      };

    case ErrorLogActionTypes.CLEAN_LOG:
      return {
        ...state,
        log: undefined
      };

    case ErrorLogActionTypes.SEND_LOG_SUCCESS:
      return {
        ...state,
        log: undefined
      };

    default:
      return state;
  }
};

export default errorLogReducer;
