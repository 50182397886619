import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Address } from '~/interfaces/general';
import { IApplicationState } from '~/redux-tools/store';

import useAddressIsNeighborhoodTax from './useAddressIsNeighborhoodTax';

export default function useAddressList() {
  const userInfo = useSelector((state: IApplicationState) => state.user.data);

  const { isNeighborhoodTax } = useAddressIsNeighborhoodTax();

  const addressList: Address[] = useMemo(() => {
    if (!userInfo.addresses) {
      return [];
    }

    return userInfo.addresses.filter((item) => {
      if (isNeighborhoodTax) {
        return !!item.neighborhood_id;
      }

      return !item.neighborhood_id;
    });
  }, [isNeighborhoodTax, userInfo.addresses]);

  return { addressList };
}
