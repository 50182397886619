import { Scheduling } from '@goomerdev/goomer-toolbox/src/enums';
export interface ISchedulingState {
  readonly selectedList: Scheduling[];
  readonly deliveryList: Scheduling[];
  readonly takeawayList: Scheduling[];
  readonly hasSchedulingList: boolean;
}

export const SchedulingTypes = {
  ADD_SELECTED_LIST: '@@scheduling/ADD_SELECTED_LIST',
  ADD_DELIVERY_LIST: '@@scheduling/ADD_DELIVERY_LIST',
  ADD_TAKEAWAY_LIST: '@@scheduling/ADD_TAKEAWAY_LIST',
  SET_HAS_SCHEDULING_LIST: '@@scheduling/SET_HAS_SCHEDULING_LIST'
};
