import React, { useCallback, useEffect, useMemo, useState } from 'react';

import FadeIn from 'react-fade-in';

import { useTranslator } from '~/hooks';
import { OfflineInfo } from '~/components';
import { PaymentStepProcessEnum } from '~/interfaces/enums';

import { Container, Loader } from './styles';

interface PaymentStepModalProps {
  onClose: () => void;
  isTabPayment?: boolean;
  isModalVisible: boolean;
  step: PaymentStepProcessEnum;
}

const PaymentStepModal: React.FC<PaymentStepModalProps> = ({
  onClose,
  isTabPayment,
  isModalVisible,
  step = PaymentStepProcessEnum.sending
}) => {
  const { getTranslation } = useTranslator();

  const message = useMemo(
    () =>
      getTranslation('payment.stepWarning', {
        step: isTabPayment
          ? getTranslation('$t(general.payment, lowercase)')
          : getTranslation('$t(order.sendOrder, lowercase)')
      }),
    [getTranslation, isTabPayment]
  );

  useEffect(() => {
    function preventPageRefresh(event: { preventDefault: () => void; returnValue: string }): string {
      if (event) {
        event.preventDefault();

        event.returnValue = message;
      }

      return message;
    }

    if (isModalVisible && step !== PaymentStepProcessEnum.sendingToCheckout) {
      window.onbeforeunload = preventPageRefresh;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.onbeforeunload = (): void => {};
    }
  }, [isModalVisible, message, step]);

  const [shouldDisplayOfflineInfo, setShouldDisplayOfflineInfo] = useState<boolean>(false);

  useEffect(() => {
    function handleWithDisconnect(): void {
      setShouldDisplayOfflineInfo(true);
    }

    function handleWithConnection(): void {
      setShouldDisplayOfflineInfo(false);
      onClose();
    }

    window.addEventListener('offline', handleWithDisconnect);
    window.addEventListener('online', handleWithConnection);

    return () => {
      window.removeEventListener('offline', handleWithDisconnect);
      window.removeEventListener('online', handleWithConnection);
    };
  }, [onClose]);

  const data = useMemo(() => {
    switch (step) {
      case PaymentStepProcessEnum.sending:
        return {
          text: `${getTranslation('order.sendingOrder')}...`
        };
      case PaymentStepProcessEnum.paying:
        return {
          text: `${getTranslation('order.payingOrder')}...`
        };
      case PaymentStepProcessEnum.sendingToCheckout:
        return {
          text: `${getTranslation('order.redirectingToSafeEnvironment')}...`
        };
      default:
        return {
          text: `${getTranslation('order.sendingOrder')}...`
        };
    }
  }, [getTranslation, step]);

  const handleOfflineInfoGoBack = useCallback(() => {
    setShouldDisplayOfflineInfo(false);
    onClose();
  }, [onClose]);

  return (
    <Container
      isPageLike
      headerTitle={getTranslation('order.sendOrder')}
      isShow={isModalVisible}
      isContentVisible
      onClose={(): void => onClose()}
    >
      {!shouldDisplayOfflineInfo && (
        <FadeIn>
          <div className="loader">
            <Loader src="/assets/checkout/load.svg" width={55} height={55} />
          </div>

          {data && <p className="text">{data.text}</p>}

          <p className="desc">{message}</p>
        </FadeIn>
      )}

      {shouldDisplayOfflineInfo && <OfflineInfo goBack={handleOfflineInfoGoBack} />}
    </Container>
  );
};

export default PaymentStepModal;
