import { action } from 'typesafe-actions';

import { LoginOptionEnum } from '~/interfaces/enums';
import { IUser, Address, PaymentMethod, ICard, UTMValues, RequestFunctionsProps } from '~/interfaces/general';

import { UserActionTypes } from './types';

export interface AddUserCard extends ICard {
  cardNumber: string;
  establishment: string;
}

export const addUserInfo = (userInfo: IUser) => {
  return action(UserActionTypes.ADD_USER, userInfo);
};

export const addUserPayment = (payment: PaymentMethod) => {
  return action(UserActionTypes.ADD_USER_PAYMENT, payment);
};

export const addUserCard = (card: AddUserCard) => {
  return action(UserActionTypes.ADD_USER_CARD, card);
};

export const clearCardData = () => {
  return action(UserActionTypes.CLEAR_CARD_DATA);
};

export const removeUserCard = (cardNumber: string, authenticationToken: string | undefined) => {
  return action(UserActionTypes.REMOVE_USER_CARD, { cardNumber, authenticationToken });
};

export const removeCardTokens = () => {
  return action(UserActionTypes.REMOVE_CARD_TOKENS);
};

export const removePaymentPreference = () => {
  return action(UserActionTypes.REMOVE_PAYMENT_PREFERENCE);
};

export const editPaymentPreferences = (paymentPreferences: PaymentMethod[]) => {
  return action(UserActionTypes.EDIT_PAYMENT_PREFERENCES, paymentPreferences);
};

export const setUTMvalues = (utm: UTMValues) => {
  return action(UserActionTypes.SET_UTM_VALUES, utm);
};

export const setOrderFromDashboard = (value: boolean) => {
  return action(UserActionTypes.SET_ORDER_FROM_DASHBOARD, value);
};

interface GetRegisteredUserRequestProps {
  mode: LoginOptionEnum;
  param: number | string;
  functions: RequestFunctionsProps;
  getTranslation: (key: string, interpolation?: Record<string, any>) => string;
}

export const getRegisteredUserRequest = ({
  mode,
  param,
  functions,
  getTranslation
}: GetRegisteredUserRequestProps): { type: string; payload: GetRegisteredUserRequestProps } =>
  action(UserActionTypes.GET_REGISTERED_USER_REQUEST, { mode, param, functions, getTranslation });

export const getRegisteredUserSuccess = () => action(UserActionTypes.GET_REGISTERED_USER_SUCCESS);

export const getRegisteredUserError = (error: string) => action(UserActionTypes.GET_REGISTERED_USER_ERROR, { error });

interface verifyEmailCodeRequestProps {
  code: string;
  name: string;
  token: string;
  phone: string;
  birthday?: string;
  isNewUser: boolean;
  functions: RequestFunctionsProps;
}

export const verifyEmailCodeRequest = ({
  code,
  name,
  token,
  phone,
  birthday,
  functions,
  isNewUser
}: verifyEmailCodeRequestProps) =>
  action(UserActionTypes.VERIFY_EMAIL_CODE_REQUEST, {
    code,
    name,
    token,
    phone,
    birthday,
    functions,
    isNewUser
  });

export const verifyEmailCodeSuccess = () => action(UserActionTypes.VERIFY_EMAIL_CODE_SUCCESS);

export const verifyEmailCodeError = (error: string) => action(UserActionTypes.VERIFY_EMAIL_CODE_ERROR, { error });

interface GenerateEmailVerificationCodeRequestProps {
  email: string;
  resend: boolean;
  utm?: UTMValues;
  functions: RequestFunctionsProps;
}

export const generateEmailVerificationCodeRequest = ({
  utm,
  email,
  resend,
  functions
}: GenerateEmailVerificationCodeRequestProps) =>
  action(UserActionTypes.GENERATE_EMAIL_CODE_REQUEST, { utm, email, resend, functions });

export const generateEmailVerificationCodeSuccess = () => action(UserActionTypes.GENERATE_EMAIL_CODE_SUCCESS);

export const generateEmailVerificationCodeError = (error: string) =>
  action(UserActionTypes.GENERATE_EMAIL_CODE_ERROR, { error });

interface SendGoogleRegistrationRequestProps {
  name?: string;
  phone?: string;
  birthday?: string;
  googleToken: string;
  functions: RequestFunctionsProps;
}

export const sendGoogleRegistrationRequest = ({
  name,
  phone,
  birthday,
  functions,
  googleToken
}: SendGoogleRegistrationRequestProps) =>
  action(UserActionTypes.GOOGLE_REGISTRATION_REQUEST, { name, phone, birthday, functions, googleToken });

export const sendGoogleRegistrationSuccess = () => action(UserActionTypes.GOOGLE_REGISTRATION_SUCCESS);

export const sendGoogleRegistrationError = (error: string) =>
  action(UserActionTypes.GOOGLE_REGISTRATION_ERROR, { error });

interface AuthenticateUserProps {
  email: string;
  token: string;
}

export const authenticateUser = (userInfo: AuthenticateUserProps) =>
  action(UserActionTypes.AUTHENTICATE_USER, userInfo);

export const cleanUserInfo = () => action(UserActionTypes.CLEAN_USER_INFO);

interface FetchCustomerInfoRequestProps {
  token: string;
  storeId: number;
}

export const fetchCustomerInfoRequest = ({ token, storeId }: FetchCustomerInfoRequestProps) =>
  action(UserActionTypes.FETCH_CUSTOMER_INFO_REQUEST, { token, storeId });

export const setCustomerInfo = (customer: IUser | undefined) => action(UserActionTypes.SET_CUSTOMER_INFO, customer);

export const fetchCustomerInfoError = (message: string) =>
  action(UserActionTypes.FETCH_CUSTOMER_INFO_ERROR, { message });

interface UpdateCustomerInfoRequestProps {
  name: string;
  optIn: boolean;
  storeId: number;
  birthday?: string;
  functions: RequestFunctionsProps;
}

export const updateCustomerInfoRequest = ({
  name,
  optIn,
  storeId,
  birthday,
  functions
}: UpdateCustomerInfoRequestProps) =>
  action(UserActionTypes.UPDATE_CUSTOMER_INFO_REQUEST, { name, optIn, storeId, birthday, functions });

export const updateCustomerInfoSuccess = (customer: IUser | undefined) =>
  action(UserActionTypes.UPDATE_CUSTOMER_INFO_SUCCESS, customer);

export const updateCustomerInfoError = (message: string) =>
  action(UserActionTypes.UPDATE_CUSTOMER_INFO_ERROR, { message });

interface CustomerAddressProps {
  storeId: number;
  isUserAuthenticated: boolean;
  functions: RequestFunctionsProps;
}
interface AddCustomerAddressProps extends CustomerAddressProps {
  address: Address;
}

export const addCustomerAddressRequest = ({
  address,
  storeId,
  functions,
  isUserAuthenticated
}: AddCustomerAddressProps) =>
  action(UserActionTypes.ADD_CUSTOMER_ADDRESS_REQUEST, { address, storeId, functions, isUserAuthenticated });

export const addCustomerAddressSuccess = (address: Address) =>
  action(UserActionTypes.ADD_CUSTOMER_ADDRESS_SUCCESS, { address });

export const addCustomerAddressError = () => action(UserActionTypes.ADD_CUSTOMER_ADDRESS_ERROR);

interface UpdateCustomerAddressProps extends CustomerAddressProps {
  address: Address;
}

export const updateCustomerAddressRequest = ({
  address,
  storeId,
  functions,
  isUserAuthenticated
}: UpdateCustomerAddressProps) =>
  action(UserActionTypes.UPDATE_CUSTOMER_ADDRESS_REQUEST, { address, storeId, functions, isUserAuthenticated });

export const updateCustomerAddressSuccess = (address: Address) =>
  action(UserActionTypes.UPDATE_CUSTOMER_ADDRESS_SUCCESS, { address });

export const updateCustomerAddressError = () => action(UserActionTypes.UPDATE_CUSTOMER_ADDRESS_ERROR);

interface DeleteCustomerAddressProps extends CustomerAddressProps {
  addressId: string;
}

export const deleteCustomerAddressRequest = ({
  storeId,
  functions,
  addressId,
  isUserAuthenticated
}: DeleteCustomerAddressProps) =>
  action(UserActionTypes.DELETE_CUSTOMER_ADDRESS_REQUEST, { storeId, functions, addressId, isUserAuthenticated });

export const deleteCustomerAddressSuccess = (addressId: string) =>
  action(UserActionTypes.DELETE_CUSTOMER_ADDRESS_SUCCESS, { addressId });

export const deleteCustomerAddressError = () => action(UserActionTypes.DELETE_CUSTOMER_ADDRESS_ERROR);

export const sanitizeUserAddresses = (addresses: Address[] | []) => {
  return action(UserActionTypes.SANITIZE_USER_ADDRESSES, addresses);
};
