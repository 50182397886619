import styled from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';
import { Error as InputError } from '~/components/pages/CardEntry/FormInput/styles';

export const Container = Modal
  ? styled(Modal).attrs((props: ModalProps) => props)`
      opacity: 1;

      .container {
        padding-bottom: 0;

        .title {
          width: 100%;
          padding: 0 1rem;

          text-align: left;
        }

        .content {
          margin-top: 1rem;
        }
      }
    `
  : styled.div<ModalProps>``;

export const Item = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

  display: flex;
  align-items: center;
  justify-content: flex-start;

  > p {
    padding: 1.125rem 1rem;

    display: flex;
    align-items: center;

    font-weight: 600;
    white-space: pre;
    line-height: 150%;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.mediumGray};

    span {
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

export const Error = styled(InputError)`
  left: 1rem;

  text-align: left;
`;
