import React, { useMemo, useCallback, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Image } from '~/interfaces';
import { useTranslator } from '~/hooks';
import { ImageModal } from '~/components';
import Carousel from '~/components/Carousel';
import MagnifierIcon from '~/assets/MagnifierIcon';

import * as S from './styles';

interface ImageModalProps {
  name?: string;
  imageList: Image[];
  description?: string;
}

const ProductImage: React.FC<ImageModalProps> = ({ name = '', imageList = [], description = '' }) => {
  const { getTranslation } = useTranslator();

  const [currentImage, setCurrentImage] = useState('');
  const [isShownImageModal, setIsShownImageModal] = useState(false);

  const handleSelectImage = useCallback((selectedImage: string) => {
    setIsShownImageModal(true);
    setCurrentImage(selectedImage);
  }, []);

  const imageElementList: JSX.Element[] = useMemo(() => {
    if (!imageList || imageList.length === 0) {
      return [];
    }

    return imageList.map((image: Image, index: number) => (
      <S.Container key={index} id={`product-image-${index}`} onClick={(): void => handleSelectImage(image.large)}>
        <LazyLoadImage
          width="100%"
          height={176}
          src={isMobile ? image.medium : image.large}
          alt={getTranslation('label.productImage', { name })}
        />
      </S.Container>
    ));
  }, [getTranslation, handleSelectImage, imageList, name]);

  const renderImageList = useMemo(() => {
    if (imageElementList.length === 0) {
      return <></>;
    }

    if (imageElementList.length === 1) {
      return <>{imageElementList[0]}</>;
    }

    return <Carousel itemList={imageElementList} />;
  }, [imageElementList]);

  return (
    <S.Wrapper $hasMultipleImages={imageElementList.length > 1}>
      {renderImageList}

      <div className="magnifier">
        <MagnifierIcon />
      </div>

      <ImageModal
        name={name}
        desc={description}
        image={currentImage}
        isShown={isShownImageModal}
        onClose={(): void => {
          setCurrentImage('');
          setIsShownImageModal(false);
        }}
      />
    </S.Wrapper>
  );
};

export default ProductImage;
