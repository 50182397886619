import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { IEstablishmentSettings } from '~/interfaces/general';

interface UseMyTabRedirectProps {
  mode: string;
  loading: boolean;
  settings?: IEstablishmentSettings;
}

function useMyTabRedirect({ settings, mode, loading }: UseMyTabRedirectProps) {
  const history = useRouter();

  useEffect(() => {
    function handleMyTabRedirect() {
      if (typeof window !== 'undefined' && !!settings && !loading) {
        const { search } = window.location;

        if (history.pathname === '/myTab' && !!settings?.mm_feature_table_online_payment_enabled) {
          return history.push('/myTabOnlinePayment', {
            search
          });
        }

        if (history.pathname === '/myTabOnlinePayment' && !settings?.mm_feature_table_online_payment_enabled) {
          return history.push('/myTab', {
            search
          });
        }
      }
    }

    handleMyTabRedirect();
  }, [history, history.pathname, loading, mode, settings]);
}

export default useMyTabRedirect;
