import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

export default function useIsAppEnabled() {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { hash } = useSelector((state: IApplicationState) => state.localOrders);

  function isGoomerGoEnabled() {
    if (!settings) return true;

    return settings.mm_enabled;
  }

  const isAppEnabled = !hash ? isGoomerGoEnabled() : true;

  return [isAppEnabled];
}
