import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';

import { phoneMask } from '~/utils';
import { useTranslator } from '~/hooks';
import { Input } from '~/components/Input/Input/styles';
import useValidatePhoneNumber from '~/hooks/useValidatePhoneNumber';

import { ErrorMessage } from '../Input/styles';

interface InputPhoneProps {
  id?: string;
  required?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  defaultValue?: string;
  isGoomerColor?: boolean;
  onChange: (phoneValue: string, isValid: boolean) => void;
}

const InputPhone: React.FC<InputPhoneProps> = ({
  onChange,
  required = false,
  defaultValue = '',
  isDisabled = false,
  id = 'input-phone',
  isGoomerColor = false,
  ...rest
}) => {
  const isFirstRender = useRef(true);

  const { getTranslation } = useTranslator();

  const [phone, setPhone] = useState<string>(defaultValue);
  const [withError, setWithError] = useState<string>('');

  const { isPhoneValid, isPhoneFilled, isPhoneFromBrazil } = useValidatePhoneNumber();

  const shouldDisplayErrorMessage: boolean = useMemo(
    () => !!withError && (required || phone.length > 0),
    [phone.length, required, withError]
  );

  const errorMessage: string = useMemo(
    () => (required ? getTranslation('general.enterYourPhoneNumber') : getTranslation('general.enterValidPhoneNumber')),
    [getTranslation, required]
  );

  const maskPhoneNumber: (phone: string) => string = useCallback(
    (phone) => (isPhoneFromBrazil ? phoneMask(phone) : phone),
    [isPhoneFromBrazil]
  );

  const handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback(
    (event) => {
      const incomingPhone: string = event.target.value;
      const maskedPhone: string = maskPhoneNumber(incomingPhone);
      const isValid: boolean = isPhoneValid(maskedPhone);

      setPhone(maskedPhone);
      onChange(maskedPhone, isValid);
    },
    [isPhoneValid, maskPhoneNumber, onChange]
  );

  const handleBlur: () => void = useCallback(() => {
    const maskedPhone: string = maskPhoneNumber(phone);

    setWithError(isPhoneValid(maskedPhone) ? '' : 'error');
  }, [isPhoneValid, maskPhoneNumber, phone]);

  useEffect(() => {
    if (isFirstRender.current && phone.length > 0) {
      handleBlur();
    }

    isFirstRender.current = false;
  }, [handleBlur, phone.length]);

  return (
    <>
      <Input
        id={id}
        max={50}
        type="text"
        maxLength={50}
        autoComplete="off"
        required={required}
        inputMode="decimal"
        onBlur={handleBlur}
        disabled={isDisabled}
        className="sentry-mask"
        onChange={handleChange}
        $data-done={isPhoneFilled}
        error={required && withError}
        isGoomerColor={isGoomerColor}
        value={maskPhoneNumber(phone)}
        {...rest}
      />

      {shouldDisplayErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default InputPhone;
