import styled, { css } from 'styled-components';

import Modal, { ModalProps } from '~/components/Modal/Modal';
import { ContentDrawer, ContainerDrawer } from '~/components/Modal/Modal/styles';

export const Container = Modal
  ? styled(Modal)`
      ${({ theme }) => css`
        ${ContainerDrawer} {
          padding: 0;
        }

        ${ContentDrawer} {
          padding: 1.5rem 0;

          height: 100%;

          background: ${theme.colors.lightestGray};
        }
      `}
    `
  : styled.div<ModalProps>``;

export const Content = styled.div`
  ${({ theme }) => css`
    height: 100%;
    padding: 1.5rem 0;

    background: ${theme.colors.lightestGray};
  `}
`;

export const DaysSchedule = styled.div``;

export const Bottom = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;

    width: 100%;
    padding: 0 1rem;
    height: 6.125rem;
    max-width: 800px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    background: ${theme.colors.backgroundColor};

    > .info {
      margin-bottom: 0.5rem;

      display: block;

      font-weight: 600;
      line-height: 150%;
      font-size: 0.75rem;
      color: ${theme.colors.mediumGray};
    }
  `}
`;
