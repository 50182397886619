import React, { useEffect, useState } from 'react';

import { RiArrowUpSLine, RiCloseLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import { convertToCurrency } from '~/utils';
import { CartValues } from '~/interfaces/general';
import { SegmentTypeEnum } from '~/interfaces/enums';

import * as S from './styles';

interface SummaryFooterProps {
  isVisible: boolean;
  values?: CartValues;
  couponSegment?: SegmentTypeEnum;
}

interface SummaryRenderProps {
  values?: CartValues;
  couponSegment?: SegmentTypeEnum;
  onAction: (value: boolean) => void;
}

const SummaryFooterTotal = ({ values, onAction }: SummaryRenderProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  return (
    <S.TotalInfoWrapper>
      <div className="total">
        <span>{getTranslation('general.total')}</span>
      </div>

      <S.ValueWrapper>
        <p>{convertToCurrency(values?.total || 0)}</p>
        <RiArrowUpSLine style={{ cursor: 'pointer' }} size={20} onClick={(): void => onAction(true)} />
      </S.ValueWrapper>
    </S.TotalInfoWrapper>
  );
};

const SummaryFooterInfo = ({ values, onAction, couponSegment }: SummaryRenderProps): JSX.Element => {
  const { getTranslation } = useTranslator();

  if (!values) {
    return <></>;
  }

  const { total, loyalty, subtotal, service_tax, delivery_fee, takeaway_discount, coupon: couponDiscount } = values;

  const allSummaryList = [
    {
      value: subtotal,
      isCoupon: false,
      label: '$t(general.subtotal)',
      shouldDisplay: subtotal && total != subtotal && subtotal > 0
    },

    /** cupom ou desconto de primeiro pedido */
    {
      isCoupon: true,
      value: -couponDiscount,
      shouldDisplay: couponDiscount && couponDiscount > 0,
      label: couponSegment === SegmentTypeEnum.first_order ? '$t(general.firstOrder)' : '$t(general.coupon)'
    },
    {
      isCoupon: true,
      value: subtotal - couponDiscount,
      shouldDisplay: subtotal && subtotal > 0 && couponDiscount && couponDiscount > 0,
      label:
        couponSegment === SegmentTypeEnum.first_order ? '$t(general.subtotalDiscount)' : '$t(general.subtotalCoupon)'
    },

    /** desconto fidelidade */
    {
      isCoupon: true,
      value: -(loyalty || 0),
      label: '$t(general.loyalty)',
      shouldDisplay: loyalty && loyalty > 0
    },
    {
      isCoupon: true,
      value: subtotal - (loyalty || 0),
      label: '$t(general.subtotalWithLoyaltyDiscount)',
      shouldDisplay: subtotal && subtotal > 0 && loyalty && loyalty > 0
    },

    /** desconto de retirada */
    {
      isCoupon: false,
      value: delivery_fee,
      label: '$t(general.deliveryTax)',
      shouldDisplay: delivery_fee && delivery_fee > 0
    },
    {
      isCoupon: false,
      value: -takeaway_discount,
      label: '$t(general.discountForTakeaway)',
      shouldDisplay: takeaway_discount && takeaway_discount > 0
    },

    /** taxa de serviço */
    {
      isCoupon: false,
      value: -service_tax,
      label: '$t(general.serviceTax)',
      shouldDisplay: service_tax && service_tax > 0
    }
  ];

  return (
    <S.SummaryInfoWrapper>
      <S.SummaryTitleWrapper>
        <span>{getTranslation('general.orderSummary')}</span>
        <RiCloseLine style={{ cursor: 'pointer' }} size={26} onClick={(): void => onAction(false)} />
      </S.SummaryTitleWrapper>

      <S.SummaryInfo>
        <>
          {allSummaryList.map((item, index) => {
            if (!item.shouldDisplay) return null;

            return (
              <S.SummaryEachItem isCoupon={item.isCoupon} key={index}>
                <div>{getTranslation(item.label)}</div>
                <div>{convertToCurrency(item.value)}</div>
              </S.SummaryEachItem>
            );
          })}

          <S.TotalValue>
            <S.TotalValueText className="text">{getTranslation('general.total')}</S.TotalValueText>
            <S.TotalValueText className="text">{convertToCurrency(total)}</S.TotalValueText>
          </S.TotalValue>
        </>
      </S.SummaryInfo>
    </S.SummaryInfoWrapper>
  );
};

const SummaryFooter: React.FC<SummaryFooterProps> = ({ values, isVisible, couponSegment }) => {
  const [shouldDisplayFullSummary, setShouldDisplayFullSummary] = useState<boolean>(false);

  useEffect(() => {
    function toggleBackdrop(type: 'add' | 'remove'): void {
      const body = typeof document !== 'undefined' && document.querySelector('body');

      if (body) {
        const toggleBackdropClassOnBody = (): void => body?.classList[type]('show-backdrop');
        toggleBackdropClassOnBody();
      }
    }

    toggleBackdrop(shouldDisplayFullSummary && isVisible ? 'add' : 'remove');
  }, [isVisible, shouldDisplayFullSummary]);

  if (!isVisible) {
    return <></>;
  }

  if (shouldDisplayFullSummary) {
    return <SummaryFooterInfo values={values} couponSegment={couponSegment} onAction={setShouldDisplayFullSummary} />;
  }

  return <SummaryFooterTotal values={values} onAction={setShouldDisplayFullSummary} />;
};

export default SummaryFooter;
