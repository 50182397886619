import styled from 'styled-components';

export const Container = styled.div``;

export const OrderHeader = styled.div`
  position: relative;

  padding: 20px 16px 10px;

  &::after {
    position: absolute;
    left: -16px;
    bottom: -1px;

    height: 1px;
    width: calc(100% + 32px);

    display: block;

    content: '';
    background: ${({ theme }) => theme.colors.lightGray};
  }

  > .info {
    margin-bottom: 6px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > strong {
      margin-bottom: 6px;

      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
    }

    > small {
      text-align: right;
      font-size: 12px;
      font-weight: 600;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.mediumGray};
    }
  }

  .name {
    margin-bottom: 4px;

    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    text-align: left;
  }

  .text {
    font-size: 12px;
    text-align: left;
    font-weight: 500;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.mediumGray};
  }
`;
