import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react';

import { Router, useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import { differenceInMilliseconds } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { useLocalStorage } from '@goomerdev/goomer-toolbox/src/hooks';

import '~/i18n';
import { AppContext } from '~/contexts';
import { IApplicationState } from '~/redux-tools/store';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { addUserInfo } from '~/redux-tools/store/user/actions';
import { sendLog } from '~/redux-tools/store/errorLog/actions';
import InstallPwaModal from '~/components/Modal/InstallPwaModal';
import { cleanCheckIn } from '~/redux-tools/store/localOrders/actions';
import { cleanTab, resetMyTab } from '~/redux-tools/store/myTab/actions';
import { IStoreClosed, QrCodeHashReaderResult } from '~/interfaces/general';
import { cleanCart, setCartValues } from '~/redux-tools/store/cart/actions';
import { setViewMode, setEstablishmentOpen } from '~/redux-tools/store/establishment/actions';
import { LocalOrdersEnum, LocalStorageModalEnum, QRCodeModeEnum, QueryParam } from '~/interfaces/enums';
import { dateTime, isOnPwa, validateName, truncateAmount, isEstablishmentOpen, getIsOutdated } from '~/utils';
import {
  useCheckCoupon,
  useThemeDetector,
  useRefreshStorage,
  useSchedulingInfo,
  useLoyaltyProgram,
  useLanguageDetector,
  useCheckPaymentMethod,
  useHandleWithLegacyURL,
  useSyncAuthenticationToken
} from '~/hooks';

const CHECKIN_DURATION_IN_HOURS = Number(process.env.NEXT_PUBLIC_CHECKIN_DURATION_HOURS) || 5;
const CHECKIN_DURATION_IN_MINUTES = CHECKIN_DURATION_IN_HOURS * 60;

const CART_TIME_LIMIT_IN_MINUTES = Number(process.env.NEXT_PUBLIC_CART_TIME_LIMIT_IN_MINUTES) || 30;

const TIME_TO_EXPIRE_QR_CODE_READER_RESULT_IN_MILLISECONDS = 2 * 3600000; /** 2 hours */

interface AppProps {
  children: React.ReactNode;
}

const App = ({ children }: AppProps): JSX.Element => {
  const history = useRouter();
  const sentLog = useRef(false);
  const dispatch = useDispatch();

  const cart = useSelector((state: IApplicationState) => state.cart);
  const user = useSelector((state: IApplicationState) => state.user.data);
  const { log } = useSelector((state: IApplicationState) => state.errorLog);
  const storeTheme = useSelector((state: IApplicationState) => state.theme.theme);
  const establishment = useSelector((state: IApplicationState) => state.establishment);
  const { tabDetails, date: myTabDate, orders: myTabOrders } = useSelector((state: IApplicationState) => state.myTab);
  const {
    mode,
    hash: localOrders,
    date: localOrdersDate
  } = useSelector((state: IApplicationState) => state.localOrders);

  const [showPwaBanner, setShowPwaBanner] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState(typeof navigator !== 'undefined' && navigator.onLine);

  useCheckCoupon();
  useThemeDetector();
  useSchedulingInfo();
  useLanguageDetector();
  useCheckPaymentMethod();
  useHandleWithLegacyURL({});
  useSyncAuthenticationToken();
  const { hasLoyaltyProgramAccess, loyaltyDiscountValueUnlocked } = useLoyaltyProgram();
  const { localStorageValue, setLocalStorageValue } = useLocalStorage({ key: 'qrCodeHashReader' });

  const qrCodeHashReader: QrCodeHashReaderResult = useMemo(
    () => JSON.parse(localStorageValue || '{}') as QrCodeHashReaderResult,
    [localStorageValue]
  );

  useEffect(() => {
    if (establishment?.settings?.is_abrahao && !!mode) {
      if (qrCodeHashReader.date) {
        if (mode === LocalOrdersEnum.guestCheck) {
          return setLocalStorageValue('{}');
        }

        const difference = differenceInMilliseconds(new Date(), new Date(qrCodeHashReader.date));
        if (difference > TIME_TO_EXPIRE_QR_CODE_READER_RESULT_IN_MILLISECONDS) {
          setLocalStorageValue('{}');
        }
      }
    }
  }, [establishment?.settings, mode, qrCodeHashReader, qrCodeHashReader.date, setLocalStorageValue]);

  useEffect(() => {
    if (user?.name?.trim()) {
      if (!validateName({ value: user.name })) {
        dispatch(addUserInfo({ name: '', isAuthenticated: false, authenticationToken: '' }));
      }
    }
  }, [dispatch, user.name]);

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        if (establishment?.settings) {
          ReactPixel.init(establishment?.settings.mm_facebook_pixel_id);
          ReactPixel.pageView();

          Router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView();
          });
        }
      });
  }, [establishment]);

  useEffect(() => {
    if (establishment.settings?.id) {
      GoogleAnalytics.setup({
        storeGoogleAnalyticsId: establishment.settings?.mm_google_analytics_enabled
          ? establishment.settings?.mm_google_analytics_id
          : undefined
      });
    }
  }, [
    establishment.settings?.id,
    establishment.settings?.mm_google_analytics_enabled,
    establishment.settings?.mm_google_analytics_id
  ]);

  useEffect(() => {
    const onlineListener = (): void => setIsOnline(true);
    const offlineListener = (): void => setIsOnline(false);

    window.addEventListener('online', onlineListener);
    window.addEventListener('offline', offlineListener);

    return (): void => {
      window.removeEventListener('online', onlineListener);
      window.removeEventListener('offline', offlineListener);
    };
  }, [isOnline]);

  if (isOnPwa()) {
    GoogleAnalytics.trackEvent(gaEvents.ggoPwaIsRunningOnPwaMode, {
      // eslint-disable-next-line camelcase
      establishment_id: establishment.settings?.store_code
    });
  }

  useEffect(() => {
    if (!isOnline) {
      sentLog.current = false;
    }
  }, [isOnline]);

  useEffect(() => {
    if (isOnline && log && !sentLog.current) {
      sentLog.current = true;
      dispatch(sendLog(log));
    }
  }, [log, isOnline, dispatch]);

  useEffect(() => {
    if (hasLoyaltyProgramAccess) {
      const newLoyaltyDiscountValue: number = loyaltyDiscountValueUnlocked || 0;

      const newCartValues: Record<string, number> = {
        loyalty: truncateAmount(newLoyaltyDiscountValue)
      };

      if (newLoyaltyDiscountValue > 0) {
        newCartValues.coupon = 0;
      }

      dispatch(setCartValues(newCartValues));
    }
  }, [dispatch, cart.total, hasLoyaltyProgramAccess, loyaltyDiscountValueUnlocked, user.customerLoyaltyProgram]);

  useRefreshStorage();

  useEffect(() => {
    const { settings } = establishment;

    if (settings?.id && myTabOrders.length > 0) {
      const isTheSameEstablishment =
        settings &&
        myTabOrders[0].establishment.id &&
        [settings.id, settings.store_code].includes(myTabOrders[0].establishment.id);

      if (!isTheSameEstablishment) {
        dispatch(cleanTab());
      }
    }

    const cleanMyTabInterval = setInterval(() => {
      if (myTabDate) {
        const timeDiff = dateTime.millisecondsToHours(Date.now() - new Date(myTabDate).getTime());

        if (Number(timeDiff) >= Number(CHECKIN_DURATION_IN_HOURS)) {
          dispatch(cleanTab());
        }
      }
    }, 5000);

    return (): void => {
      clearInterval(cleanMyTabInterval);
    };
  }, [dispatch, establishment, myTabDate, myTabOrders]);

  useEffect(() => {
    function handleCartPersist(): void {
      const isOutdatedCart: boolean = getIsOutdated({
        date: cart.lastUpdate,
        minutes: CART_TIME_LIMIT_IN_MINUTES
      });

      if (isOutdatedCart) {
        dispatch(cleanCart());
      }
    }

    function handleInStorePersist(): void {
      const isOutdatedLastOrder: boolean = getIsOutdated({
        date: localOrdersDate,
        minutes: CHECKIN_DURATION_IN_MINUTES
      });

      if (isOutdatedLastOrder) {
        dispatch(cleanCheckIn());
      }
    }

    handleCartPersist();
    handleInStorePersist();
  }, [dispatch, localOrdersDate, cart.lastUpdate]);

  useEffect(() => {
    function refreshMyTabPersist(): void {
      if (typeof tabDetails === 'undefined') {
        dispatch(resetMyTab());
      }
    }

    refreshMyTabPersist();
  }, [localOrdersDate, dispatch, tabDetails]);

  useEffect(() => {
    function handleViewMode() {
      const isInStoreViewMode = !establishment.settings?.mm_in_store_enabled || mode === QRCodeModeEnum.display;

      const isGoomerGoViewMode = !establishment.settings?.mm_whatsapp_order_enabled;

      if (mode === QRCodeModeEnum.display) {
        dispatch(cleanCart());
      }

      return localOrders ? dispatch(setViewMode(isInStoreViewMode)) : dispatch(setViewMode(isGoomerGoViewMode));
    }

    handleViewMode();
  }, [dispatch, establishment.settings, localOrders, mode]);

  const isStoreClosed = useCallback((info: IStoreClosed | boolean | undefined) => {
    if (info === undefined) return false;

    if (typeof info === 'object') return info.closed;

    return info;
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (establishment) {
        let result = isEstablishmentOpen({ isLocalOrders: !!localOrders, settings: establishment?.settings });

        const storeClosed = localOrders
          ? isStoreClosed(establishment.settings?.mm_in_store_store_closed)
          : isStoreClosed(establishment.settings?.mm_store_closed);

        if (storeClosed) {
          result = {
            isOpen: false
          };
        }

        if (
          result.isOpen !== undefined &&
          establishment.open !== undefined &&
          establishment.open.isOpen !== undefined &&
          result.isOpen !== establishment?.open?.isOpen
        ) {
          dispatch(setEstablishmentOpen(result));
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [establishment, dispatch, localOrders, isStoreClosed]);

  const pwaInstallConfigs = useMemo(() => {
    const page = history.pathname;

    const splashActions = {
      close: (): void => {
        setShowPwaBanner(false);
        GoogleAnalytics.trackEvent(gaEvents.ggoPwaNotInstall, {
          // eslint-disable-next-line camelcase
          establishment_id: establishment.settings?.store_code
        });
      },
      open: (): void => {
        if (typeof window !== 'undefined') {
          const urlParams = new URLSearchParams(window.location.search);
          const utmSource = urlParams.get(QueryParam.utmSource) || undefined;

          if (utmSource === 'crm-whatsapp') {
            const utmId = urlParams.get(QueryParam.utmId) || undefined;

            GoogleAnalytics.trackEvent(gaEvents.crmWhatsappViewMenu, {
              customerId: utmId,
              // eslint-disable-next-line camelcase
              establishment_id: establishment.settings?.store_code
            });
          }

          if (utmSource === 'whatsapp') {
            GoogleAnalytics.trackEvent(gaEvents.ggoPwaComeFromWhats, {
              // eslint-disable-next-line camelcase
              establishment_id: establishment.settings?.store_code
            });

            setShowPwaBanner(true);
          }
        }
      }
    };

    switch (page) {
      case '/splash':
        splashActions.open();
        return { isFullPage: true, onClose: splashActions.close };

      default:
        return { onClose: () => setShowPwaBanner(false) };
    }
  }, [establishment.settings?.store_code, history.pathname]);

  return (
    <ThemeProvider theme={storeTheme}>
      <AppContext>{children}</AppContext>

      <InstallPwaModal isShow={showPwaBanner} {...pwaInstallConfigs} />
    </ThemeProvider>
  );
};

export default App;
