import * as S from './styles';

export interface SectionWrapperProps {
  title?: string;
  className?: string;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  hasMarginBottom?: boolean;
  isFixedPosition?: boolean;
  children?: React.ReactNode;
}

const SectionWrapper: (props: SectionWrapperProps) => JSX.Element = ({
  icon,
  title,
  action,
  children,
  className,
  isFixedPosition,
  hasMarginBottom = true
}) => {
  return (
    <S.Container className={className} isFixedPosition={isFixedPosition} hasMarginBottom={hasMarginBottom}>
      {!!title && (
        <S.Header>
          <S.BaseTitle>
            {!!icon && <div className="icon">{icon}</div>}

            <p>{title}</p>
          </S.BaseTitle>

          {action && action}
        </S.Header>
      )}

      {children}
    </S.Container>
  );
};

export { SectionWrapper };
