import { api } from '~/services/api';
import { CallWaiterRequest } from '~/interfaces/general';

export default async function callWaiterRequest(payload: CallWaiterRequest, aId: string): Promise<any> {
  const apiPath = `${process.env.NEXT_PUBLIC_API_ABRAHAO}/${aId}/call-waiter`;

  try {
    const response = await api.post(apiPath, payload);

    return response;
  } catch (error) {
    throw new Error(`POST error: ${error}`);
  }
}
