import { Reducer } from 'redux';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { Tuna } from '~/services';

import { IUserState, UserActionTypes } from './types';

const initialState: IUserState = {
  utm: {},
  error: '',
  loading: false,
  orderFromDashboard: false,
  data: {
    cpf: '',
    name: '',
    cards: [],
    email: '',
    phone: '',
    optIn: true,
    birthday: '',
    addresses: [],
    paymentPreference: [],
    isAuthenticated: false,
    loginOptionType: undefined,
    authenticationToken: undefined,
    customerLoyaltyProgram: undefined
  }
};

const UserReducer: Reducer<IUserState> = (state = initialState, action) => {
  const { type, payload } = action;

  const handleRemoveOldPayment = () => {
    const { establishment } = payload;

    const { paymentPreference } = state.data;

    const actualEstablishmentPaymentMethod =
      !!paymentPreference && paymentPreference.find((payment) => payment.establishment === establishment);

    const removedOldPayment = paymentPreference
      ? paymentPreference.filter((payment) => payment.establishment !== establishment)
      : [];

    return { removedOldPayment, actualEstablishmentPaymentMethod };
  };

  switch (type) {
    case UserActionTypes.ADD_USER:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };

    case UserActionTypes.GET_REGISTERED_USER_REQUEST:
    case UserActionTypes.GENERATE_EMAIL_CODE_REQUEST:
    case UserActionTypes.VERIFY_EMAIL_CODE_REQUEST:
    case UserActionTypes.FETCH_CUSTOMER_INFO_REQUEST:
    case UserActionTypes.GOOGLE_REGISTRATION_REQUEST:
    case UserActionTypes.UPDATE_CUSTOMER_INFO_REQUEST:
    case UserActionTypes.ADD_CUSTOMER_ADDRESS_REQUEST:
    case UserActionTypes.UPDATE_CUSTOMER_ADDRESS_REQUEST:
    case UserActionTypes.DELETE_CUSTOMER_ADDRESS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case UserActionTypes.GET_REGISTERED_USER_SUCCESS:
    case UserActionTypes.GENERATE_EMAIL_CODE_SUCCESS:
    case UserActionTypes.VERIFY_EMAIL_CODE_SUCCESS:
    case UserActionTypes.FETCH_CUSTOMER_INFO_SUCCESS:
    case UserActionTypes.GOOGLE_REGISTRATION_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case UserActionTypes.GET_REGISTERED_USER_ERROR:
    case UserActionTypes.GENERATE_EMAIL_CODE_ERROR:
    case UserActionTypes.VERIFY_EMAIL_CODE_ERROR:
    case UserActionTypes.GOOGLE_REGISTRATION_ERROR:
    case UserActionTypes.UPDATE_CUSTOMER_INFO_ERROR:
    case UserActionTypes.ADD_CUSTOMER_ADDRESS_ERROR:
    case UserActionTypes.UPDATE_CUSTOMER_ADDRESS_ERROR:
    case UserActionTypes.DELETE_CUSTOMER_ADDRESS_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload
      };
    }

    case UserActionTypes.ADD_USER_PAYMENT: {
      const { removedOldPayment, actualEstablishmentPaymentMethod } = handleRemoveOldPayment();

      if (actualEstablishmentPaymentMethod && removedOldPayment) {
        return {
          ...state,
          data: {
            ...state.data,
            paymentPreference: [...removedOldPayment, payload]
          }
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          paymentPreference: [payload]
        }
      };
    }

    case UserActionTypes.ADD_USER_CARD: {
      const { cards = [] } = state.data;
      const newListCards = cards ? cards.filter((x) => x.number !== payload.number) : [];
      newListCards.push(payload);

      return {
        ...state,
        data: {
          ...state.data,
          cards: newListCards
        }
      };
    }

    case UserActionTypes.EDIT_PAYMENT_PREFERENCES: {
      return {
        ...state,
        data: {
          ...state.data,
          paymentPreference: payload
        }
      };
    }

    case UserActionTypes.REMOVE_USER_CARD: {
      const { cards, paymentPreference } = state.data;

      const filteredCards = cards ? cards.filter((card) => card.number !== payload.cardNumber) : [];

      const hasPaymentPreference = paymentPreference && paymentPreference.length > 0;

      const filteredPaymentPreference = hasPaymentPreference
        ? paymentPreference?.filter((p) => p.cardNumber !== payload.cardNumber)
        : [];

      const filteredTunaCard = cards
        ? cards.filter(
            (card) => card.number === payload.cardNumber && card.flagDescription === PaymentCategoryEnum.tunaCheckout
          )
        : [];

      if (filteredTunaCard && filteredTunaCard.length > 0) {
        Tuna?.(payload.authenticationToken)?.then((tuna) => {
          tuna?.tokenizator()?.delete(filteredTunaCard[0].token);
        });
      }

      return {
        ...state,
        data: {
          ...state.data,
          cards: filteredCards,
          paymentPreference: filteredPaymentPreference
        }
      };
    }

    case UserActionTypes.REMOVE_CARD_TOKENS: {
      const cardsWithoutToken = state?.data?.cards?.map((card) => ({
        ...card,
        token: ''
      }));

      const paymentPreferencesWithoutToken =
        state.data.paymentPreference &&
        state?.data?.paymentPreference?.map((payment) => ({
          ...payment,
          token: ''
        }));

      if (!paymentPreferencesWithoutToken) {
        return {
          ...state,
          data: {
            ...state.data,
            cards: cardsWithoutToken
          }
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          cards: cardsWithoutToken,
          paymentPreference: paymentPreferencesWithoutToken
        }
      };
    }

    case UserActionTypes.REMOVE_PAYMENT_PREFERENCE: {
      return {
        ...state,
        data: {
          ...state.data,
          paymentPreference: []
        }
      };
    }

    case UserActionTypes.CLEAR_CARD_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          cards: [],
          paymentPreference: []
        }
      };
    }

    case UserActionTypes.SET_CUSTOMER_INFO:
    case UserActionTypes.UPDATE_CUSTOMER_INFO_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        data: {
          ...state.data,
          ...payload
        }
      };
    }

    case UserActionTypes.FETCH_CUSTOMER_INFO_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
        data: {
          ...state.data,
          isAuthenticated: false,
          authenticationToken: undefined,
          customerLoyaltyProgram: undefined
        }
      };
    }

    case UserActionTypes.ADD_CUSTOMER_ADDRESS_SUCCESS: {
      const { addresses } = state.data;
      const { address: payloadAddress } = payload;

      const addressList = addresses || [];

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          addresses: [...addressList, payloadAddress]
        }
      };
    }

    case UserActionTypes.UPDATE_CUSTOMER_ADDRESS_SUCCESS: {
      const { addresses } = state.data;
      const { address: payloadAddress } = payload;

      const addressList = addresses ? addresses.filter((address) => address.id !== payloadAddress.id) : [];

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          addresses: [...addressList, payloadAddress]
        }
      };
    }

    case UserActionTypes.DELETE_CUSTOMER_ADDRESS_SUCCESS: {
      const { addresses } = state.data;
      const { addressId: payloadAddressId } = payload;

      const addressList = addresses ? addresses.filter((address) => address.id !== payloadAddressId) : [];

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          addresses: [...addressList]
        }
      };
    }

    case UserActionTypes.SANITIZE_USER_ADDRESSES: {
      return {
        ...state,
        data: {
          ...state.data,
          addresses: payload
        }
      };
    }

    case UserActionTypes.AUTHENTICATE_USER: {
      return {
        ...state,
        data: {
          ...state.data,
          email: payload.email,
          isAuthenticated: !!payload.token,
          authenticationToken: payload.token,
          loginOptionType: payload.loginOptionType
        }
      };
    }

    case UserActionTypes.SET_UTM_VALUES: {
      return {
        ...state,
        utm: payload
      };
    }

    case UserActionTypes.SET_ORDER_FROM_DASHBOARD: {
      return {
        ...state,
        orderFromDashboard: payload
      };
    }

    case UserActionTypes.CLEAN_USER_INFO: {
      return {
        ...state,
        ...initialState
      };
    }

    default:
      return state;
  }
};

export default UserReducer;
