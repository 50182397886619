import React from 'react';

const LittleMachineIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2452_6504)">
      <path
        d="M15.2013 13.5233C15.2013 13.9792 14.8236 14.3524 14.3622 14.3524H14.3202H5.80394H5.67809C5.21661 14.3524 4.83904 13.9792 4.83904 13.5233V3.65803C4.83904 3.20208 5.21661 2.82902 5.67809 2.82902H14.3622C14.8236 2.82902 15.2013 3.20208 15.2013 3.65803V13.5233ZM13.4392 16.7979C13.4392 17.0466 13.2715 17.2125 13.0197 17.2125H12.1807V15.2228H13.4392V16.7979ZM6.64299 16.7979V15.2228H10.5445V17.2125H7.06251C6.85275 17.2125 6.64299 17.0052 6.64299 16.7979ZM14.3622 2H5.67809C4.75514 2 4 2.74612 4 3.65803V13.5233C4 14.4353 4.75514 15.1813 5.67809 15.1813H5.80394V16.7565C5.80394 17.4612 6.34932 18 7.06251 18H13.0197C13.7329 18 14.2783 17.4612 14.2783 16.7565V15.1813H14.3202C15.2432 15.1813 15.9984 14.4353 15.9984 13.5233V3.65803C16.0403 2.74612 15.2851 2 14.3622 2Z"
        fill="#7F8F9F"
      />
      <path
        d="M13.3971 6.6013C13.3971 6.85001 13.2293 7.01579 12.9776 7.01579H7.10427C6.85256 7.01579 6.68475 6.85001 6.68475 6.6013V5.35778C6.68475 5.10907 6.85256 4.94327 7.10427 4.94327H12.9776C13.2293 4.94327 13.3971 5.10907 13.3971 5.35778V6.6013ZM12.9776 4.11426H7.10427C6.39108 4.11426 5.8457 4.65312 5.8457 5.35778V6.6013C5.8457 7.30594 6.39108 7.84484 7.10427 7.84484H12.9776C13.6908 7.84484 14.2361 7.30594 14.2361 6.6013V5.35778C14.2361 4.65312 13.6908 4.11426 12.9776 4.11426ZM7.10427 9.00544C6.68475 9.00544 6.34913 9.33704 6.34913 9.75154C6.34913 10.1661 6.68475 10.4977 7.10427 10.4977C7.52379 10.4977 7.85941 10.1661 7.85941 9.75154C7.85941 9.33704 7.52379 9.00544 7.10427 9.00544ZM10.0829 9.00544C9.66338 9.00544 9.32773 9.33704 9.32773 9.75154C9.32773 10.1661 9.66338 10.4977 10.0829 10.4977C10.5024 10.4977 10.838 10.1661 10.838 9.75154C10.838 9.33704 10.5024 9.00544 10.0829 9.00544ZM13.0615 9.00544C12.642 9.00544 12.3063 9.33704 12.3063 9.75154C12.3063 10.1661 12.642 10.4977 13.0615 10.4977C13.481 10.4977 13.8166 10.1661 13.8166 9.75154C13.8166 9.33704 13.481 9.00544 13.0615 9.00544ZM7.10427 11.7826C6.68475 11.7826 6.34913 12.1142 6.34913 12.5287C6.34913 12.9433 6.68475 13.2749 7.10427 13.2749C7.52379 13.2749 7.85941 12.9433 7.85941 12.5287C7.85941 12.1142 7.52379 11.7826 7.10427 11.7826ZM10.0829 11.7826C9.66338 11.7826 9.32773 12.1142 9.32773 12.5287C9.32773 12.9433 9.66338 13.2749 10.0829 13.2749C10.5024 13.2749 10.838 12.9433 10.838 12.5287C10.838 12.1142 10.5024 11.7826 10.0829 11.7826ZM13.0615 11.7826C12.642 11.7826 12.3063 12.1142 12.3063 12.5287C12.3063 12.9433 12.642 13.2749 13.0615 13.2749C13.481 13.2749 13.8166 12.9433 13.8166 12.5287C13.8166 12.1142 13.481 11.7826 13.0615 11.7826Z"
        fill="#7F8F9F"
      />
    </g>
    <defs>
      <clipPath id="clip0_2452_6504">
        <rect width="12" height="16" fill="white" transform="translate(4 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default LittleMachineIcon;
