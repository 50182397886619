import { all, put, takeLatest } from 'redux-saga/effects';

import { getBill } from '~/services';
import { TabDetails } from '~/interfaces/general';

import { fetchTabDetailsError, fetchTabDetailsRequest, fetchTabDetailsSuccess } from './actions';
import { MyTabActionTypes } from './types';

type FetchTabDetailsRequest = ReturnType<typeof fetchTabDetailsRequest>;

export function* fetchTabDetails({ payload }: FetchTabDetailsRequest) {
  const { callback, request } = payload;

  try {
    const tab: TabDetails = yield getBill(request);

    if (callback) {
      callback(tab);
    }
    yield put(fetchTabDetailsSuccess(tab));
  } catch (error) {
    yield put(fetchTabDetailsError());
  }
}

export default all([takeLatest(MyTabActionTypes.FETCH_TAB_DETAILS_REQUEST, fetchTabDetails)]);
