import { useCallback } from 'react';
import { getTermIntoString } from '@goomerdev/goomer-toolbox/src/utils';

import useTranslator from '../useTranslator';

export interface MountBadgeReturnProps {
  type: 'default' | 'success';
  message: string;
}

interface MountBadgeProps {
  value: boolean | string | undefined;
  option: string;
  discount: number | undefined;
}

interface UseMountBadgeReturnProps {
  mountBadge: ({ discount, option, value }: MountBadgeProps) => MountBadgeReturnProps;
}

export default function useMountBadge(): UseMountBadgeReturnProps {
  const { getTranslation } = useTranslator();

  const mountBadge = useCallback(
    ({ discount, option, value }: MountBadgeProps): MountBadgeReturnProps => {
      if (!value) {
        return { type: 'success', message: '' };
      }

      const isTake = getTermIntoString({ word: option || '', term: 'retir' });
      const isMercadoPago = getTermIntoString({ word: option || '', term: 'merc' });

      if (isTake) {
        return {
          type: 'success',
          message: getTranslation('general.earnDiscount', { discountPercentage: discount })
        };
      }

      if (isMercadoPago) {
        return {
          type: 'default',
          message: getTranslation('general.earnDiscount', { discountPercentage: discount })
        };
      }

      return { type: 'success', message: '' };
    },
    [getTranslation]
  );

  return { mountBadge };
}
