import { useEffect, useState } from 'react';

import { QueryParam } from '~/interfaces/enums';

export default function useWalletToken(): { walletToken: string | null; methodType: string | null } {
  const [methodType, setMethodType] = useState<string | null>(null);
  const [walletToken, setWalletToken] = useState<string | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);

      const methodType = urlParams.get(QueryParam.methodType);
      const walletToken = urlParams.get(QueryParam.walletToken);

      if (methodType) setMethodType(methodType);

      if (walletToken) setWalletToken(walletToken);
    }
  }, []);

  return { walletToken, methodType };
}
