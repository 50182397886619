import { AxiosResponse } from 'axios';

import { api } from '~/services/api';
import { TabInfo } from '~/interfaces/general';

export default async function getTabCodeInfo(tabHash: string, aId?: string): Promise<TabInfo> {
  const apiPath = `${process.env.NEXT_PUBLIC_API_ABRAHAO}/${aId}/cards?code=${tabHash}`;

  try {
    const tabInfo: AxiosResponse<TabInfo> = await api.get(apiPath);

    if (!tabInfo.data) {
      throw new Error('QR Code not found');
    }

    return tabInfo.data;
  } catch (error) {
    throw new Error(`POST error: ${error}`);
  }
}
