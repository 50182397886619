import { KeyValueGeneric } from '@goomerdev/goomer-toolbox/src/interfaces';

import { api } from '~/services/api';

export interface FeedbackPayload {
  name: string;
  note?: string;
  email: string;
  table_number: string;
  phone_number: string;
  rates: KeyValueGeneric<number>;
}

export default async function sendFeeback(storeId: string, review: FeedbackPayload): Promise<any> {
  const apiPath = `${process.env.NEXT_PUBLIC_API_ABRAHAO}/${storeId}/send-feedback`;

  try {
    const response = await api.post(apiPath, review);

    return response;
  } catch (error) {
    throw new Error(`Post error: ${error}`);
  }
}
