import styled, { css, DefaultTheme } from 'styled-components';

import { StoreStatusProps } from '~/components/StoreStatus';
import { EstablishmentStatusEnum } from '~/interfaces/enums';

const containerModifiers = {
  [EstablishmentStatusEnum.closed]: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.error} !important;

    svg,
    span {
      color: ${theme.colors.error} !important;
    }
  `,
  [EstablishmentStatusEnum.open]: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.success} !important;

    svg,
    span {
      color: ${theme.colors.success} !important;
    }
  `,
  [EstablishmentStatusEnum.scheduling]: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.auxiliaryCheese} !important;

    svg,
    span {
      color: ${theme.colors.auxiliaryCheese} !important;
    }
  `
};

export const Container = styled.div<Pick<StoreStatusProps, 'status'>>`
  ${({ theme, status }) => css`
    height: 1.87rem;
    padding: 0 0.75rem;
    border-radius: 1000px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    span {
      font-size: 0.75rem;
    }

    ${containerModifiers[status](theme)}
  `}
`;
