import React from 'react';

import * as S from './styles';

interface SwipeActionItemsProps {
  color: string;
  label?: string;
  icon: JSX.Element;
  side: 'left' | 'right';
}

const SwipeActionItems: (props: SwipeActionItemsProps) => JSX.Element = ({ side, icon, label, color }) => {
  return (
    <S.Container className={`${side === 'left' ? 'backgroundLeft' : ''}`} style={{ background: color }}>
      <div className="content">
        {icon}
        {label && <span>{label}</span>}
      </div>
    </S.Container>
  );
};

export default SwipeActionItems;
