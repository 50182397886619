/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${({ theme }) => theme.colors.lightestGray};

  > .info {
    width: 100%;
    margin: 0 auto;
    max-width: 800px;

    .title {
      font-weight: 800;
      font-size: 1.125rem;
      line-height: 1.688rem;
      letter-spacing: 0.01em;

      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;
