import React, { useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { format, isToday } from 'date-fns';

import { scrollToElement } from '~/utils';
import Modal from '~/components/Modal/Modal';
import Button from '~/components/Button/Button';
import { DeliveryWayEnum } from '~/interfaces/enums';
import { useGetDayText, useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { IEstablishmentSettings } from '~/interfaces/general';
import { RiCalendar2Line, RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import { Container, Content, IconWrapper } from '~/components/Checkout/CheckoutSelect';

import * as S from './styles';

export interface SelectSchedulingProps {
  schedule: Date | boolean;
  removeSchedule: () => void;
  toggleShowScheduling: () => void;
}

const deliveryOptionsList: string[] = ['mm_delivery_enabled', 'mm_takeaway_enabled'];

const SelectScheduling = ({
  removeSchedule,
  schedule = false,
  toggleShowScheduling
}: SelectSchedulingProps): JSX.Element => {
  const origin = useSelector((state: IApplicationState) => state.coupons?.origin);
  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { isOpen } = useSelector((state: IApplicationState) => state.establishment.open);

  const [shouldDisplayRemoveModal, setShouldDisplayRemoveModal] = useState<boolean>(false);

  const { formatDayText } = useGetDayText();
  const { getTranslation } = useTranslator();

  const scheduleText = useMemo(() => {
    if (!schedule) return '';

    const hour = format(schedule as Date, 'HH:mm');

    if (isToday(schedule as Date)) {
      return `${getTranslation('general.today')} • ${hour}`;
    }

    const day = format(schedule as Date, 'dd/MM');
    const dayText = formatDayText({ date: schedule as Date });

    return `${dayText} • ${day} • ${hour}`;
  }, [formatDayText, getTranslation, schedule]);

  const handleText = useMemo(() => {
    if (schedule) return scheduleText;

    if (settings) {
      const enabledDeliveryOptionList: string[] = deliveryOptionsList.filter(
        (deliveryOption) => !!settings[deliveryOption as keyof IEstablishmentSettings]
      );

      if (enabledDeliveryOptionList.length === 1) {
        return enabledDeliveryOptionList[0] === 'mm_delivery_enabled'
          ? getTranslation('scheduling.scheduleDelivery')
          : getTranslation('scheduling.scheduleLocal');
      }
    }

    if (origin === DeliveryWayEnum.delivery) return getTranslation('scheduling.scheduleDelivery');

    return getTranslation('scheduling.scheduleLocal');
  }, [getTranslation, origin, schedule, scheduleText, settings]);

  const handleConfirm = useCallback(
    (remove?: boolean) => {
      if (remove) {
        removeSchedule();
        setShouldDisplayRemoveModal(false);
      }

      setShouldDisplayRemoveModal(false);

      setTimeout(() => {
        scrollToElement({ isSmooth: false, element: '[data-name="scheduling-header"]' });
      }, 0);
    },
    [removeSchedule]
  );

  const handleRemoveScheduling = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setShouldDisplayRemoveModal(true);
  }, []);

  return (
    <S.PreContainer>
      <Container data-test="scheduling-option-card" onClick={toggleShowScheduling}>
        <IconWrapper>
          <RiCalendar2Line />
        </IconWrapper>

        <Content>
          <S.Text data-test="scheduling-option-text" isLinkLike={!schedule}>
            {handleText}
          </S.Text>

          {!isOpen && <S.Info>{getTranslation('scheduling.closedMessage')}</S.Info>}
        </Content>

        {!!schedule && (!!settings?.mm_order_scheduling_only || !isOpen) && (
          <IconWrapper
            $isAction
            aria-label={`${getTranslation('general.edit')} ${getTranslation('general.scheduling').toLowerCase()}`}
          >
            <RiPencilLine size={24} />
          </IconWrapper>
        )}

        {!!schedule && !settings?.mm_order_scheduling_only && isOpen && (
          <IconWrapper
            $isDelete
            onClick={handleRemoveScheduling}
            aria-label={`${getTranslation('general.remove')} ${getTranslation('general.scheduling').toLowerCase()}`}
          >
            <RiDeleteBinLine size={24} />
          </IconWrapper>
        )}
      </Container>

      <Modal
        isSmall
        isShow={shouldDisplayRemoveModal}
        headerTitle={getTranslation('scheduling.remove')}
        onClose={(): void => setShouldDisplayRemoveModal(false)}
      >
        <S.ModalContent>
          <Button aria-label={getTranslation('general.cancel')} onClick={(): void => handleConfirm()}>
            {getTranslation('general.cancel')}
          </Button>

          <Button
            isDanger
            isGhost={false}
            onClick={(): void => handleConfirm(true)}
            aria-label={getTranslation('general.yesRemove')}
          >
            {`${getTranslation('general.yesRemove')} ${getTranslation('general.scheduling').toLowerCase()}`}
          </Button>
        </S.ModalContent>
      </Modal>
    </S.PreContainer>
  );
};

export default SelectScheduling;
