import { Reducer } from 'redux';

import { TabStatusEnum } from '~/interfaces/enums';
import { CartProduct, MountedOrder, PaymentReceipt } from '~/interfaces/general';

import { MyTabActionTypes, IMyTabState } from './types';

const initialState = {
  loading: false,
  orders: [] as MountedOrder[],
  tabDetails: {
    isBillPaid: false,
    status: TabStatusEnum.Available,
    payments: [] as PaymentReceipt[],
    cart: {
      products: [] as CartProduct[],
      values: {
        paid: 0,
        total: 0,
        service: 0,
        subtotal: 0,
        serviceTax: 0
      }
    }
  }
};

const MyTabReducer: Reducer<IMyTabState> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MyTabActionTypes.ADD_ORDER:
      return {
        ...state,
        date: new Date().toISOString(),
        orders: [...state.orders, payload]
      };

    case MyTabActionTypes.CLEAN_TAB:
      return {
        ...state,
        orders: [],
        date: undefined
      };

    case MyTabActionTypes.ADD_PAYMENT:
      return {
        ...state,
        currentPayment: payload
      };

    case MyTabActionTypes.CLEAN_PAYMENT:
      return {
        ...state,
        currentPayment: undefined
      };

    case MyTabActionTypes.FETCH_TAB_DETAILS_REQUEST:
      const { refresh } = payload;
      return {
        ...state,
        loading: !refresh
      };

    case MyTabActionTypes.FETCH_TAB_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        tabDetails: payload
      };

    case MyTabActionTypes.FETCH_TAB_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        tabDetails: initialState.tabDetails
      };

    case MyTabActionTypes.RESET_MY_TAB:
      return initialState;

    default:
      return state;
  }
};

export default MyTabReducer;
