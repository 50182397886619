import { IconBaseProps } from 'react-icons';

import * as S from './styles';

export interface TabLinkButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  iconSize?: number;
  description?: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const TabLinkButton: (props: TabLinkButtonProps) => JSX.Element = ({
  title,
  iconSize,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  icon: Icon,
  description,
  ...rest
}) => {
  return (
    <S.Container {...rest}>
      <S.Wrapper>
        {Icon && <Icon size={iconSize || 20} aria-label="Ícone" />}

        <S.InfoWrapper>
          <p>{title}</p>

          {description && <span>{description}</span>}
        </S.InfoWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

export { TabLinkButton };
