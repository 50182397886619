import styled, { css } from 'styled-components';

import { ErrorMessage } from '~/components/Input/Input/styles';

export const Container = styled.div<{ withError?: boolean }>`
  position: relative;

  width: 100%;

  ${({ theme, withError }) =>
    withError &&
    css`
      input {
        border-color: ${theme.colors.error};
      }
    `}
`;

export const Error = styled(ErrorMessage).attrs((props: { showError?: boolean }) => props)`
  position: absolute;
  bottom: -14px;
  right: 0;

  margin: 0;

  font-size: 0.75rem;
  font-weight: 500;
`;
