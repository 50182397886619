import React, { createContext, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RiArrowLeftLine } from 'react-icons/ri';

import { IApplicationState } from '~/redux-tools/store';
import { AddressPageModeEnum } from '~/interfaces/enums';
import { Address as AddressInterface } from '~/interfaces/general';

import { AddressList as List, AddressForm as Form } from './components';
import * as S from './styles';

interface AddressContextProps {
  onClose?: () => void;
  isGoomerColor: boolean;
  closeButtonText?: string;
  selectedView: AddressPageModeEnum;
  selectedAddress: AddressInterface | undefined;
  setSelectedView: (selectedView: AddressPageModeEnum) => void;
  setSelectedAddress: (selectedAddress: AddressInterface | undefined) => void;
}

export const AddressContext = createContext<AddressContextProps>({} as AddressContextProps);

interface AddressListProps {
  title: string;
  onClose?: () => void;
  isGoomerColor?: boolean;
  closeButtonText?: string;
}

const AddressList: (props: AddressListProps) => JSX.Element = ({
  title,
  onClose,
  closeButtonText,
  isGoomerColor = false
}) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const cartAddress = useSelector((state: IApplicationState) => state.cart.address);

  const [selectedView, setSelectedView] = useState<AddressPageModeEnum>(AddressPageModeEnum.list);
  const [selectedAddress, setSelectedAddress] = useState<AddressInterface | undefined>(cartAddress);

  const handleOnClose = useCallback(() => {
    if (selectedView === AddressPageModeEnum.list) {
      onClose?.();
      return;
    }

    setSelectedView(AddressPageModeEnum.list);
  }, [onClose, selectedView]);

  return (
    <AddressContext.Provider
      value={{
        selectedView,
        isGoomerColor,
        closeButtonText,
        setSelectedView,
        selectedAddress,
        setSelectedAddress,
        onClose: handleOnClose
      }}
    >
      <S.Container>
        <S.Header>
          <S.ArrowWrapper data-test="btn-close" onClick={(): void => handleOnClose()}>
            <RiArrowLeftLine size={24} color={theme.colors.textColor} />
          </S.ArrowWrapper>

          <span>{title}</span>
        </S.Header>

        <S.Content>{selectedView === AddressPageModeEnum.list ? <List /> : <Form />}</S.Content>
      </S.Container>
    </AddressContext.Provider>
  );
};

export default AddressList;
