import { combineReducers, Action } from 'redux';
import { TypeConstant, PayloadAction } from 'typesafe-actions';

import { CartState, cartReducer } from './cart';
import { IUserState, userReducer } from './user';
import { MenusState, menusReducer } from './menus';
import { NupayState, nupayReducer } from './nupay';
import { IThemeState, themeReducer } from './theme';
import { IMyTabState, myTabReducer } from './myTab';
import { WaiterState, waiterReducer } from './waiter';
import { ReviewState, reviewReducer } from './review';
import { ThirkyState, thirkyReducer } from './thirky';
import { IScrollState, scrollReducer } from './scroll';
import { ICouponsState, couponsReducer } from './coupons';
import { PaymentsState, paymentsReducer } from './payments';
import { CheckoutState, checkoutReducer } from './checkout';
import { IErrorLogState, errorLogReducer } from './errorLog';
import { ILastOrderState, lastOrderReducer } from './lastOrder';
import { IPixPaymentState, pixPaymentReducer } from './pixPayment';
import { ISchedulingState, schedulingReducer } from './scheduling';
import { localOrdersReducer, localOrderState } from './localOrders';
import { IOrderStatusState, orderStatusReducer } from './orderStatus';
import { IGeolocationState, geolocationReducer } from './geolocation';
import { IFingerPrintState, fingerPrintReducer } from './fingerPrint';
import { IEstablishmentState, establishmentReducer } from './establishment';

export type IReducerAction<TPayload> = Action<TypeConstant> & PayloadAction<TypeConstant, TPayload>;

export const RESET_STORE = '@@global/RESET_STORE';

export interface IApplicationState {
  cart: CartState;
  user: IUserState;
  menus: MenusState;
  nupay: NupayState;
  theme: IThemeState;
  myTab: IMyTabState;
  review: ReviewState;
  waiter: WaiterState;
  thirky: ThirkyState;
  scroll: IScrollState;
  coupons: ICouponsState;
  checkout: CheckoutState;
  payments: PaymentsState;
  errorLog: IErrorLogState;
  lastOrder: ILastOrderState;
  localOrders: localOrderState;
  pixPayment: IPixPaymentState;
  scheduling: ISchedulingState;
  geolocation: IGeolocationState;
  orderStatus: IOrderStatusState;
  fingerPrint: IFingerPrintState;
  establishment: IEstablishmentState;
}

export const rootReducer = () =>
  combineReducers<IApplicationState>({
    user: userReducer,
    cart: cartReducer,
    menus: menusReducer,
    nupay: nupayReducer,
    myTab: myTabReducer,
    theme: themeReducer,
    thirky: thirkyReducer,
    scroll: scrollReducer,
    waiter: waiterReducer,
    review: reviewReducer,
    coupons: couponsReducer,
    errorLog: errorLogReducer,
    payments: paymentsReducer,
    checkout: checkoutReducer,
    lastOrder: lastOrderReducer,
    pixPayment: pixPaymentReducer,
    scheduling: schedulingReducer,
    fingerPrint: fingerPrintReducer,
    orderStatus: orderStatusReducer,
    localOrders: localOrdersReducer,
    geolocation: geolocationReducer,
    establishment: establishmentReducer
  });
