import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RiCloseLine } from 'react-icons/ri';

import { useTranslator } from '~/hooks';
import InstallPwaModal from '../Modal/InstallPwaModal';
import { IApplicationState } from '~/redux-tools/store';
import { LocalStorageModalEnum } from '~/interfaces/enums';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';

import * as S from './styles';

export interface PwaBannerProps {
  onHide: () => void;
  isBannerVisible: boolean;
}

const PwaBanner: React.FC<PwaBannerProps> = ({ isBannerVisible, onHide }) => {
  const establishment = useSelector((state: IApplicationState) => state.establishment);

  const [isShowPwaModal, setIsShowPwaModal] = useState(false);

  const { getTranslation } = useTranslator();

  const onBannerClick = useCallback(() => {
    setIsShowPwaModal(true);
    onHide();
  }, [onHide]);

  const setPwaViewed = useCallback(() => {
    GoogleAnalytics.trackEvent(gaEvents.ggoPwaNotInstall, {
      // eslint-disable-next-line camelcase
      establishment_id: establishment.settings?.store_code
    });

    if (localStorage) {
      localStorage.setItem(LocalStorageModalEnum.pwaBanner, 'viewed');
    }
  }, [establishment.settings?.store_code]);

  const onModalClose = useCallback(() => {
    setIsShowPwaModal(false);

    setPwaViewed();
  }, [setPwaViewed]);

  const onBannerClose = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      setPwaViewed();
      onHide();
    },
    [onHide, setPwaViewed]
  );

  return (
    <>
      <S.Container $isHidden={!isBannerVisible} onClick={onBannerClick}>
        <S.Content>
          <S.LogoContainer logoUrl={establishment.settings?.mm_logo_url} />

          <div className="info">
            <p>
              <strong>{getTranslation('pwaModal.bannerTitle')}</strong>
            </p>
            <p>{getTranslation('pwaModal.bannerMessage')}</p>
          </div>
        </S.Content>

        <RiCloseLine size={24} onClick={onBannerClose} />
      </S.Container>

      <InstallPwaModal isShow={isShowPwaModal} onClose={onModalClose} />
    </>
  );
};

export default PwaBanner;
