import React, { useCallback } from 'react';

import { useTranslator } from '~/hooks';
import { cpfMask, cnpjMask, validateCPF, validateCNPJ } from '~/utils';

import InputMask from '../InputMask';

interface InputCpfOrCnpjProps {
  dataTest?: string;
  defaultValue?: string;
  onChange: (value: string, isValid: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const InputCpfOrCnpj: React.FC<InputCpfOrCnpjProps> = ({ dataTest, onChange, defaultValue }) => {
  const { getTranslation } = useTranslator();

  const handleValidate = useCallback((value: string) => {
    const pureValue = value.replace(/\D/g, '');
    return !!pureValue && (validateCPF({ cpf: pureValue }) || validateCNPJ({ cnpj: pureValue }));
  }, []);

  const handleMask = useCallback((value: string) => (value.length <= 11 ? cpfMask(value) : cnpjMask(value)), []);

  return (
    <InputMask
      required
      name="document"
      inputMode="decimal"
      onChange={onChange}
      toMask={handleMask}
      autoComplete="name"
      data-test={dataTest}
      validate={handleValidate}
      defaultValue={defaultValue}
      placeholder={getTranslation('general.insertDocument')}
      errorMessage={getTranslation('payment.invalidNumber')}
    />
  );
};

export default React.memo(InputCpfOrCnpj);
