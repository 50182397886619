import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IApplicationState } from '~/redux-tools/store';

import { UseLoyaltyProgramHooksProps } from '..';

export type UseLoyaltyStatusProps = Pick<UseLoyaltyProgramHooksProps, 'isLoyaltyProgramEnabled'>;

export default function useLoyaltyStatus(): UseLoyaltyStatusProps {
  const { loyaltyProgram: establishmentLoyaltyProgram } = useSelector(
    (state: IApplicationState) => state.establishment
  );

  const isLoyaltyProgramEnabled: boolean = useMemo(
    () => !!establishmentLoyaltyProgram?.enabled,
    [establishmentLoyaltyProgram]
  );

  return { isLoyaltyProgramEnabled };
}
