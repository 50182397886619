import { MountedOrder } from '~/interfaces/general';

export interface IOrderStatusState {
  readonly order: MountedOrder;
  readonly inProgress: boolean;
}

export const OrderStatusActionTypes = {
  ADD_ORDER: '@@orderStatus/ADD_ORDER',
  CLEAN_ORDER: '@@orderStatus/CLEAN_ORDER',
  FINISH_ORDER: '@@orderStatus/FINISH_ORDER'
};
