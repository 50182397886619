/* eslint-disable @typescript-eslint/naming-convention */

import esTranslations from './es';
import ptBrTranslations from './pt-br';
import enUsTranslations from './en-us';

export default {
  es: esTranslations,
  'pt-BR': ptBrTranslations,
  'en-US': enUsTranslations
};

// https://support.mozilla.org/pt-BR/kb/abreviacao-de-localizacao
