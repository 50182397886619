import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    margin-bottom: 0.5rem;

    font-weight: 800;
    font-size: 1.5rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.textColor};
  }

  p {
    margin-bottom: 1.5rem;

    font-weight: 500;
    line-height: 150%;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray500};
  }
`;
