import React from 'react';

import { GoomerLogo } from '~/assets';
import { useTranslator } from '~/hooks';

import * as S from './styles';

const GoomerExperience = (): JSX.Element => {
  const { getTranslation } = useTranslator();

  return (
    <S.Container className="goomer-experience">
      <p>{getTranslation('general.anExperience')}</p>

      <GoomerLogo />
    </S.Container>
  );
};

export default GoomerExperience;
