import { useCallback } from 'react';

import useTranslator from '../useTranslator';

interface OptionalProps {
  min: number;
  max: number;
  repeat?: boolean;
}

interface UseOptionalsTextTypeReturnProps {
  handleOptionalsTextType: ({ max, min, repeat }: OptionalProps) => string;
}

export default function useOptionalsTextType(): UseOptionalsTextTypeReturnProps {
  const { getTranslation } = useTranslator();

  const handleOptionalsTextType = useCallback(
    ({ min, max }: OptionalProps): string => {
      if (max === 99) return '';

      if (min === 1 && max === 1) {
        return getTranslation('optionals.chooseMaxOption', { max });
      }

      if (min === 0 && max === 1) {
        return getTranslation('optionals.chooseUptoMaxOption', { max });
      }

      if (min === 0 && max > 1) {
        return getTranslation('optionals.chooseUptoMaxOptions', { max });
      }

      if (min > 1 && max > 1 && min === max) {
        return getTranslation('optionals.chooseMaxOptions', { max });
      }

      if (min > 0 && max > 1 && min !== max) {
        return getTranslation('optionals.chooseBetweenMinAndMax', { min, max });
      }

      return '';
    },
    [getTranslation]
  );

  return { handleOptionalsTextType };
}
